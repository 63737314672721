<template>
  <div>
    <div class="tab-pane fade show active" id="educationalbackground" role="tabpanel">
      <div class="card">
        <div class="card-header pb-0">
          <h5 class="card-title mb-0">Education</h5>
        </div>
        <div class="card-header">
          <div class="card-actions float-end">
            <button class="btn btn-primary text-white btn-add-educ" @click="addEducation">Add Education</button>
          </div>
        </div>
        <div class="card-body">
          <BaseTable :data="educations" :options="headers" :id="''">
            <template v-slot:action="{ row }">
              <div class="meatballs-menu">
                <div class="btn-group">
                  <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown"
                    aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="javascript:;" @click="editEducation(row.id)">Edit</a></li>
                    <li><a class="dropdown-item text-danger" href="javascript:;"
                        @click="destroyEducation(row.id)">Remove</a></li>
                  </ul>
                </div>
              </div>
            </template>
          </BaseTable>
        </div>
      </div>
    </div>
    <ModalEducation :modalActive="modalActive" :form="education" :errors="errors" :modalTitle="page.modalTitle"
      @close="toggleModal" @saveChanges="save" :buttonDisabled="page.isDisabled" />
    <Confirm :is-confirm="isConfirm" :confirm-text="`Are you sure you want to remove this item?`"
      @cancel="toggleConfirmation" @isDelete="processDelete" />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import educationRepo from '@/repositories/candidate/educations';
import ModalEducation from '@/views/candidate/settings/profile/ModalEducation.vue';


export default defineComponent({
  setup() {
    const { educations, education, getEducations, getEducation, insertEducation, updateEducation, deleteEducation, errors, status } = educationRepo();

    onMounted(async () => {
      await getEducations(page);
    })

    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      isLoading: true,
      modalTitle: '',
      idToDelete: '',
      isDisabled: false
    });

    const addEducation = () => {
      education.value = [];
      errors.value = [];
      page.modalTitle = 'Add Attainment';
      toggleModal();
    }

    const editEducation = (id) => {
      toggleModal();
      getEducation(id, page);
      page.modalTitle = 'Edit Attainment';
    }

    const headers = [
      {
        key: 'educational_level_display',
        label: 'Level of Education'
      },
      {
        key: 'field_of_study',
        label: 'Field of Study'
      },
      {
        key: 'school_name',
        label: 'School Name'
      },
      {
        key: 'school_year',
        label: 'School Year'
      },
      {
        key: 'action',
        label: 'Action',
        class: 'text-center w-10'
      }
    ];

    const modalActive = ref(false);
    const isConfirm = ref(false);

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    }

    const processDelete = async () => {
      await deleteEducation(page.idToDelete);
      await getEducations(page);
      toggleConfirmation();
    }

    const save = async () => {
      let formData = new FormData();
      formData.append('education_level', education.value.education_level ?? '');
      formData.append('field_of_study', education.value.field_of_study ?? '');
      formData.append('school_name', education.value.school_name ?? '');
      formData.append('school_address', education.value.school_address ?? '');
      formData.append('school_year_from', education.value.school_year_from ?? '');
      formData.append('school_year_to', education.value.school_year_to ?? '');
      formData.append('user_id', page.authuser.id);
      page.isDisabled = true;

      if (education.value.id) {
        formData.append('_method', 'PUT');
        await updateEducation(education.value.id, formData);
        if (status.value === 200) {
          getEducations(page);
          toggleModal();
        }
      } else {
        await insertEducation(formData);
        if (status.value === 200) {
          getEducations(page);
          toggleModal();
        }
      }
      page.isDisabled = false;
    }

    const toggleConfirmation = () => {
      isConfirm.value = !isConfirm.value;
    }

    const destroyEducation = (id) => {
      page.idToDelete = id;
      toggleConfirmation();
    }

    return {
      headers,
      page,
      errors,
      processDelete,
      modalActive,
      toggleModal,
      isConfirm,
      toggleConfirmation,
      save,
      educations,
      education,
      getEducations,
      getEducation,
      addEducation,
      editEducation,
      insertEducation,
      updateEducation,
      destroyEducation,
      deleteEducation,
      status
    }
  },
  components: {
    ModalEducation
  }
})
</script>
<style>
.meatballs-menu {
  float: none;
  text-align: center;
}
</style>