import axios from "axios";
import { ref, inject } from "vue";

export default function skillsRepo() {
    const errors = ref([]);
    const toast = inject('toast');
    const skills = ref([]);
    const skill = ref([]);
    const selectableSkills = ref([]);
    const status = ref('');

    const getSkills = async (page) => {
        let response = await axios.get(`admin/skills`);
        skills.value = response.data;
        selectableSkills.value = response.data;
        page.isLoading = false;
    }

    const getSkill = async (id) => {
        let response = await axios.get(`admin/skills/${id}`);
        skill.value = response.data.data;
    }

    const insertSkills = async (page, data) => {
        errors.value = [];
        try {
            let response = await axios.post(`admin/skills`, data);
            status.value = response.status;
            alertMessage(response.data.message);
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const updateSkills = async (data, id) => {
        try {
            let response = await axios.post(`admin/skills/${id}`, data);
            alertMessage(response.data.message);
            status.value = response.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const destroySkill = async (id) => {
        try {
            let response = await axios.delete(`admin/skills/${id}`);
            skills.value = response.data.skills;
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    return {
        toast,
        errors,
        status,
        skills,
        skill,
        insertSkills,
        getSkills,
        getSkill,
        updateSkills,
        destroySkill,
        selectableSkills
    }
}