<template>
    <div>
        <h3>Customized Pre-Screening Questions</h3>
        <div>
            <div class="row">
                <div class="col-7">
                    <BaseSelect
                        v-model="state.filterQuestionsBy"
                        label="Filter Questions"
                        id="filter-question"
                        :options="[
                            { name: 'All Questions', id: 1 },
                            { name: 'By Category', id: 2 },
                            { name: 'Custom Question', id: 3 }
                        ]"
                        :option-value="`name`"
                        :option-id="`id`"
                    />
                </div>
            </div>
        </div>
        <div class="mb-4">
            <div class="row" v-if="state.filterQuestionsBy == 2">
                <div class="col-7">
                    <BaseSelect
                        v-model="state.selectedCategory"
                        label="Select Category"
                        id="selected-category"
                        :options="question_categories"
                        :option-value="`category_title`"
                        :option-id="`id`"
                        @update:modelValue="selectQuestionCategory"
                    />
                </div>
                <div class="col-5"></div>
            </div>
            <div class="row" v-if="state.filterQuestionsBy == 1 || state.isQuestionCategoryActive">
                <div class="col-7">
                    <BaseSelect
                        v-model="state.selectedQuestion"
                        label="Available Questions"
                        id="selected-question"
                        :options="questions"
                        :option-value="`question`"
                        @change="selectedValue"
                    />
                </div>
                <div class="col-3">
                    <div class="form-group-label" v-show="state.isDealBreaker">
                        <BaseInput
                            v-model="state.preferredAsnwer"
                            type="number"
                            id="answer"
                            placeholder="Preferred Answer"
                        />
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group-label mt-33px">
                        <button class="btn btn-outline-primary" @click="addJobQuestion" :disabled="state.selectedQuestionText == ''">Add Question</button>
                    </div>
                </div>
            </div>
            <div class="row" v-if="state.filterQuestionsBy == 3">
                <div class="col-10">
                    <BaseInput
                        v-model="customQuestion"
                        type="text"
                        id="question"
                        label="Custom Question"
                        placeholder="Create Custom Question"
                        :class="{ 'is-invalid' : errors && errors.question }"
                        :errors="errors"
                    />
                </div>
                <div class="col-2">
                    <div class="form-group-label mt-33px">
                        <button class="btn btn-outline-primary" @click="saveJobQuestion">Save Question</button>
                    </div>
                </div>
            </div>
            <div class="list-questions mx-0">
                <div class="col-12">
                    <h4 class="your-questions">Your Pre-screening Questions</h4>
                    <ol class="list-group" v-if="screeningQuestions.length">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(question, index) in screeningQuestions" :key="index">
                            <span v-if="question.question_id == state.edited_question_id">
                                <input type="text" class="text-input form-control" v-model="state.edited_question" :modelValue="state.edited_question" style="width:400px;">
                                <input type="hidden" v-model="state.edited_question_id">
                            </span>
                            <span v-else>
                                {{ question.question }}
                            </span>
                            <span class="float-end">
                                <span v-if="question.question_id == state.edited_question_id">
                                    <a href="#!" @click="processUpdateQuestion" class="text-success">[Ok]</a>&nbsp;
                                    <a href="#!" @click="cancelEditQuestion" class="text-info">[Cancel]</a>&nbsp;
                                </span>
                                <span v-else>
                                    <a href="#!" @click="editQuestion(question.question, question.question_id)" class="text-success">[Edit]</a>&nbsp;
                                </span>
                                <a href="#!" @click="removeJobQuestion(index)" class="text-danger">[Remove]</a>
                            </span>
                            <p class="mb-1" v-if="question.answer"><b>Preferred Answer:</b> {{ question.answer }}</p>
                        </li>
                    </ol>
                    <div v-else>
                        <p>You have no pre-screening questions selected. Start adding questions now!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, onMounted, watchEffect, watch } from 'vue';
import questionRepo from '@/repositories/settings/question';

export default {
    props: {
        form: {
            type: Object,
            default: {}
        },
        errors: {
            type: Object,
            default: {}
        }
    },
    setup(props, {emit}) {
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            filterQuestionsBy: '',
            selectedQuestion: [],
            edited_question_id: '',
            edited_question: '',
            selectedQuestionArray: [],
            selectedQuestionText: '',
            preferredAsnwer: '',
            isDealBreaker: false,
            isQuestionCategoryActive: false
        });
        const { 
            questions,
            question,
            getQuestions,
            checkDealBreaker,
            resetQuestionaire,
            removeCurrentQuestions,
            addQuestion,
            status,
            errors,
            updateQuestion,
            question_categories,
            getQuestionCategories
        } = questionRepo();

        const screeningQuestions = ref([]);
        const customQuestion = ref('');

        const processUpdateQuestion = async () => {
            let formData = new FormData();
        
            formData.append("question", state.edited_question);
            formData.append("company_id", state.authuser.company_id);
            formData.append("user_id", state.authuser.id);
            formData.append("_method", "PUT");
            await updateQuestion(formData, state.edited_question_id);
            screeningQuestions.value.forEach(function (item, index) {
                if (item.question_id === state.edited_question_id) {
                    screeningQuestions.value[index].question = state.edited_question;
                }
            });
            cancelEditQuestion();
        }

        const cancelEditQuestion = () => {
            state.edited_question = '';
            state.edited_question_id = '';
        }

        const editQuestion = (question, question_id) => {
            state.edited_question = question;
            state.edited_question_id = question_id;
        }

        const removeJobQuestion = (index_id) => {
            state.selectedQuestionArray = [];
            state.edited_question = "";
            state.edited_question_id = "";
            screeningQuestions.value.splice(index_id, 1);
            screeningQuestions.value.forEach(function (item, index) {
                state.selectedQuestionArray.push(item.question_id);
            });
            resetQuestionaire(state, state.selectedQuestionArray);

            emit('emit-jobquestion', JSON.stringify(screeningQuestions.value));
        }

        const saveJobQuestion = async () => {
            let formData = new FormData();
            formData.append("question", customQuestion.value ?? "");
            formData.append("company_id", state.authuser.company_id);
            formData.append("user_id", state.authuser.id);
            await addQuestion(formData);
            if (status.value == 200 && !state.selectedQuestionArray.includes(question.value.id)) {
                screeningQuestions.value.push({
                    question_id: question.value.id,
                    question: customQuestion.value,
                    answer: ""
                });
                state.selectedQuestionArray.push(question.value.id);
                customQuestion.value = "";
                resetQuestionaire(state, state.selectedQuestionArray);

                emit('emit-jobquestion', JSON.stringify(screeningQuestions.value));
            }
        }

        const addJobQuestion = () => {
            if (!state.selectedQuestionArray.includes(state.selectedQuestion)) {
                screeningQuestions.value.push({
                    question_id: state.selectedQuestion,
                    question: state.selectedQuestionText,
                    answer: state.preferredAsnwer
                });
                state.selectedQuestionArray.push(state.selectedQuestion);
                state.selectedQuestion = "";
                state.preferredAsnwer = "";
                state.selectedQuestionText = "";
                state.isDealBreaker = false;
                resetQuestionaire(state, state.selectedQuestionArray);

                emit('emit-jobquestion', JSON.stringify(screeningQuestions.value));
            }
        }

        const selectedValue = (e) => {
            if(e.target.value) {
                checkDealBreaker(state, e.target.value);
                state.selectedQuestionText = e.target.options[e.target.options.selectedIndex].text;
            } else {
                state.selectedQuestionText = '';
            }
        }

        const selectQuestionCategory = async () => {
            await getQuestions(state);
            state.questions = questions.value;
            state.selectedQuestionArray = [];
            screeningQuestions.value = [];
            state.questions.forEach(item => {
                var loopvar = item;
                if(item.categories !== null && item.categories.length) {
                    item.categories.forEach(data => {
                        if(data.id == state.selectedCategory) {
                            screeningQuestions.value.push({
                                question_id: loopvar.id,
                                question: loopvar.question,
                                answer: ""
                            });
                            state.selectedQuestionArray.push(data.id);
                        }
                    });
                }
            });
            resetQuestionaire(state, state.selectedQuestionArray);
        }

        watchEffect(() => {
            if(props.form.id) {
                screeningQuestions.value = props.form.screening;
                emit('emit-jobquestion', JSON.stringify(screeningQuestions.value));
            }
        });

        onMounted(() => {
            getQuestions(state);
            getQuestionCategories(state);

        });

        watch(() => state.selectedCategory, () => {
            state.isQuestionCategoryActive = (state.selectedCategory != '');
        });

        return {
            state,
            questions,
            question,
            getQuestions,
            checkDealBreaker,
            resetQuestionaire,
            removeCurrentQuestions,
            addQuestion,
            status,
            errors,
            updateQuestion,
            question_categories,
            getQuestionCategories,
            screeningQuestions,
            processUpdateQuestion,
            cancelEditQuestion,
            editQuestion,
            removeJobQuestion,
            saveJobQuestion,
            addJobQuestion,
            selectedValue,
            selectQuestionCategory,
            customQuestion
        }
    }
}
</script>

<style scoped>
.mt-33px {
    margin-top: 33px !important;
}
</style>