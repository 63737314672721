<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">View Applicants</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="text-left mb-3"><button class="btn btn-info btn-md" @click="ManageCandidates">Manage Applicants</button></div>
                </div>
                <div class="row">
                    <div class="col-12 mb-4 custom-dialog" id="data-result-table">
                        <div class="mt-4 text-center" v-if="page.dataLoading">Loading ...</div>
                        <BaseTable :data="final_data" :options="headers" v-else>
                            <template v-slot:candidate_name="{ row }">
                                <div :class="!row.candidate_name ? 'text-danger': ''">{{ row.candidate_name ?? '[Deactivated Account]' }}</div>
                            </template>
                            <template v-slot:email="{ row }">
                                <div class="overflow-email">{{ row.email ?? '--' }}</div>
                            </template>
                            <template v-slot:action="{ row }">
                                <div v-if="row.candidate_name">
                                    <button class="btn btn-outline-success btn-md" @click="SendMessage(row.candidate_id)">Message</button>&nbsp;
                                    <button class="btn btn-outline-primary btn-md" @click="ViewProfile(row.candidate_id)">View Profile</button>
                                </div>
                            </template>
                        </BaseTable>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect, reactive } from 'vue';
import CandidateRepo from '@/repositories/employer/candidates';
import { useRouter } from 'vue-router';

export default defineComponent({
    props: ['data'],
    setup(props,{ emit }) {
        const page = reactive({
            candidate_id: '',
            dataLoading: true
        });
        const router = useRouter();
        const { candidate, getCandidate } = CandidateRepo();
        const result_data = ref([]);
        const final_data = ref([]);
        const headers = [
            {
                key: 'candidate_name',
                label: 'Candidate',
                class: ''
            },
            {
                key: 'email',
                label: 'Email',
                class: 'email-overflow'
            },
            {
                key: 'date_applied',
                label: 'Date Applied',
                class: 'narrow-header'
            },
            {
                key: 'status',
                label: 'Status',
                class: 'narrow-header'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const close = () => {
            emit('close');
        }

        const SendMessage = (id) => {
            router.push(`/employer/messages/${id}`);
        }

        const ViewProfile = (id) => {
            router.push(`/employer/applicant/${id}`);
        }

        const ManageCandidates = () => {
            router.push(`/employer/applicants`);
        }

        watchEffect(() => {
            page.dataLoading = true;
            final_data.value = [];
            result_data.value = props.data;
            result_data.value.forEach(async item => {
                page.candidate_id = item.candidate_id;
                await getCandidate(page);
                final_data.value.push({
                    candidate_id: candidate.value.id,
                    email: candidate.value.email,
                    candidate_name: candidate.value.name,
                    date_applied: item.date_applied,
                    status: item.status
                });
            });
            page.dataLoading = false;
        });

        return {
            page,
            close,
            result_data,
            final_data,
            headers,
            SendMessage,
            ViewProfile,
            ManageCandidates
        }
    }
})
</script>
<style>
#data-result-table #datatables-responsive thead tr th.narrow-column-reults {
    width: 1% !important;
}
#datatables-responsive.table.table-striped thead tr th.narrow-header {
    width: 5% !important;
}
#datatables-responsive.table.table-striped thead tr th.email-overflow {
    width: 0% !important;
}
.overflow-email {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
<style scoped>
.custom-dialog {
    background: #fafafa;
    padding-bottom: 15px;
    padding-top: 10px;
    border-radius: 5px;
    color: #094006;
}
</style>