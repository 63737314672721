<template>
    <div class="tab-pane fade show active" id="account" role="tabpanel">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-6">
                        <h5 class="card-title">Change Password</h5>
                        <BaseInput
                            v-model="validate.password"
                            label="New Password"
                            type="password"
                            :class="{ 'is-invalid' : errors && errors.empty_password }"
                            id="password-1"
                        />
                        <BaseInput
                            v-model="validate.password_confirmation"
                            label="Verify Password"
                            type="password"
                            :class="{ 'is-invalid' : errors && errors.password }"
                            id="password-2"
                            :errors="errors"
                        />
                        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid mb-3" v-if="errors && errors.password">{{ errors && errors.password[0] }}</label>
                        <div class="mt-3">
                            <button class="btn btn-primary" @click="saveUserPassword" v-if="!page.isProcessing">Update Password</button>
                            <button class="btn btn-primary" disabled v-else>Update Password</button>
                        </div>
                    </div>
                    <div class="col-6 pb-3">
                        <h5 class="card-title">Enable Two Factor Authentication</h5>
                        <div v-if="page.isTwoFactorEnabled == false">
                            <label class="form-label">You have not enabled 2 factor authentication.</label>
                            <BaseButton :is-saved="!modalActive" :btn-value="`Enable 2FA`" @btnClicked="enable2fa" />
                        </div>
                        <div v-else>
                            <h5>Your 2 factor authentication is enabled.</h5>
                            <div class="row mb-0">
                                <div class="col-12 mb-0">
                                    <p>Please scan the QR Code below into your phone's authenticator app.</p>
                                    <div class="row">
                                        <div class="col-6">
                                            <h5 class="mb-3">Recovery Codes</h5>
                                            <div v-for="code in codes" :key="code">
                                                {{ code }}<br>
                                            </div>
                                            <p class="mt-3 lh-1"><i><strong>Please store these recovery codes in a secure location.</strong></i></p>
                                        </div>
                                        <div class="col-6">
                                            <div class="mt-4" v-html="page.qrcode"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <BaseButton :is-saved="!isConfirm" :btn-value="`Disable 2FA`" @btnClicked="disable2fa" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalPassword
            :modalActive="modalActive"
            :errors="passwordErrors"
            :modalTitle="page.modalHeader"
            :passwordText="page.passwordText"
            @close="toggleModal"
            @saveChanges="twoFactorSavePassword"
            :disableButton="page.isProcessing"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to disable Two Factor Authentication?.`"
            @cancel="toggleConfirmation"
            @isDelete="confirmPasswordDisableAuth"
        />
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import userRepo from '@/repositories/settings/users';
import authRepo from '@/repositories/auth';
import ModalPassword from '@/views/employer/settings/config/ModalPassword.vue';

export default defineComponent({
    setup() {
        const { user, password, getUser, errors, codes, status, updatePassword } = userRepo();
        const { twoFAEnable, twoFADisable, confirmPassword, passwordErrors, twoFAChallenge } = authRepo();
        const modalActive = ref(false);

        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            qrcode: localStorage.getItem('auth-qrcode'),
            isTwoFactorEnabled: false,
            modalHeader: 'Confirm Password',
            isProcessing: false,
            isActionDisabled: false,
            isChangePassword: false,
            passwordText: 'Type in your password to proceed'
        });

        onMounted(() => {
            getUser(page);
            page.isTwoFactorEnabled = (page.authuser.two_factor_confirmed_at != null);
        })

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
            if(modalActive.value === false) {
                passwordErrors.value = '';
                status.value = 200;
            }
        }

        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
            if(isConfirm.value === false) {
                status.value = 200;
            }
        }

        const twoFactorSavePassword = async (data) => {
            page.isProcessing = true;
            if(!page.isChangePassword) {
                if(!page.isActionDisabled) {
                    await confirmPassword(page, data);
                    getUser(page);
                    if(passwordErrors.value == '') {
                        toggleModal();
                    }
                } else {
                    await confirmPassword(page, data, true);
                    getUser(page);
                    if(passwordErrors.value == '') {
                        await disableAuth();
                        modalActive.value = false;
                        page.isActionDisabled = false;
                    }
                }
            } else {
                await confirmPassword(page, data, true);
                if(passwordErrors.value == '') {
                    await updatePassword({...validate});
                    page.isChangePassword = false;
                    modalActive.value = false;
                    status.value = 200;
                    page.isActionDisabled = false;
                } else {
                    passwordErrors.value = "Hmm… That doesn’t seem correct. Please re-enter your old password";
                }
            }
            page.isProcessing = false;
        }

        const enable2fa = () => {
            status.value = 200;
            twoFAEnable(page);
            if(!page.authuser.two_factor_confirmed_at) {
                page.isChangePassword = false;
                page.passwordText = 'Type in your password to proceed';
                modalActive.value = true;
            }
        }

        const confirmPasswordDisableAuth = () => {
            toggleConfirmation();
            toggleModal();
            page.isActionDisabled = true;
        }

        const disable2fa = () => {
            toggleConfirmation();
        }

        const disableAuth = async () => {
            status.value = 200;
            await twoFADisable(page);
            getUser(page);
        }

        const validate = reactive({
            password: '',
            password_confirmation: '',
            id: page.authuser.id
        })

        const saveUserPassword = async () => {
            if(page.authuser.facebook_id == null && page.authuser.github_id == null && page.authuser.google_id == null && page.authuser.linkedin_id == null) {
                status.value = 200;
                if(validate.password && validate.password_confirmation) {
                    errors.value = '';
                    page.isChangePassword = true;
                    page.isActionDisabled = true;
                    page.passwordText = 'Please type your old password to proceed';
                    modalActive.value = true;
                } else {
                    errors.value = {
                        empty_password : [ true ],
                        password : [ 'Please enter both password fields.' ]
                    }
                }
            } else {
                await updatePassword({...validate});
            }
        }

        return {
            user,
            getUser,
            page,
            status,
            errors,
            twoFAEnable,
            twoFADisable,
            confirmPassword,
            passwordErrors,
            twoFAChallenge,
            modalActive,
            toggleModal,
            twoFactorSavePassword,
            isConfirm,
            toggleConfirmation,
            disableAuth,
            enable2fa,
            disable2fa,
            codes,
            password,
            updatePassword,
            validate,
            saveUserPassword,
            confirmPasswordDisableAuth
        }
    },
    components: {
        ModalPassword
    }
})
</script>
<style>
.is-invalid input.is-invalid.form-control {
    border-color: #d9534f !important;
}
</style>