import axios from "axios";
import { ref, inject } from "vue";

export default function trainingRepo() {

    const templates = ref([]);
    const trainings = ref([]);
    const training = ref([]);
    const training_sources = ref([]);
    const training_source = ref([]);
    const training_instructors = ref([]);
    const training_instructor = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const toast = inject('toast');
    const searchFilters = ref([]);
    const courseFiltered = ref([]);
    const saved_filters = ref([]);
    const photos = ref([]);

    const getTrainings = async (page) => {
        let response = await axios.get(`admin/trainings`);
        trainings.value = response.data.data;
        page.isLoading = false;
    }

    const getPremiumTrainings = async (page) => {
        let response = await axios.get(`admin/trainings/premium`);
        trainings.value = response.data.data;
        page.isLoading = false;
    }

    const getTraining = async (id) => {
        let response = await axios.get(`admin/trainings/${id}`);
        training.value = response.data.data;
    }

    const getPhotos = async (page) => {
        let response = await axios.get(`admin/trainings/photos`);
        photos.value = response.data.data;
        page.isLoading = false;
    }

    const insertTraining = async (page, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/trainings`, data);
            trainings.value = response.data.trainings;
            status.value = response.status;
            alertMessage(response.data.message);
            page.isLoading = false;
            return response.data
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateTraining = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/trainings/${id}`, data);
            trainings.value = response.data.data;
            status.value = response.status;
            alertMessage(response.data.message);
            return response.data
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const destroyTraining = async (page, id) => {
        try {
            let response = await axios.delete(`admin/trainings/${id}`);
            trainings.value = response.data.data;
            alertMessage(response.data.message);
            status.value = response.status;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const searchTrainings = async (page, data) => {
        let response = await axios.post(`admin/trainings/search`, data);
        trainings.value = response.data.trainings;
        page.isLoading = false;
    }

    const getCandidateTrainings = async (page) => {
        let response = await axios.get(`admin/trainings/candidate?user_id=${page.authuser.id}`);
        trainings.value = response.data.trainings;
        page.isLoading = false;
    }

    const unlinkImage = async (id) => {
        let response = await axios.get(`admin/trainings/${id}/image`);
        training.value = response.data.data;
        alertMessage(response.data.message);
        status.value = response.status;
    }

    const unlinkVideo = async (id) => {
        let response = await axios.get(`admin/trainings/${id}/video`);
        training.value = response.data.data;
        alertMessage(response.data.message);
        status.value = response.status;
    }

    const getSelectedTrainings = async (data) => {
        let response = await axios.post(`admin/trainings/selected`, data);
        trainings.value = response.data.trainings;
    }

    // Training Sources
    const getTrainingSource = async (id) => {
        let response = await axios.get(`admin/trainings/sources/${id}`);
        training_source.value = response.data.data;
    }

    const getTrainingSources = async () => {
        let response = await axios.get(`admin/trainings/sources`);
        training_sources.value = response.data.data;
    }

    const insertTrainingSource = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/trainings/sources`, data);
            status.value = response.status;
            training_source.value = response.data.training_source;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateTrainingSource = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/trainings/sources/${id}`, data);
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const deleteTrainingSource = async (id) => {
        errors.value = '';
        try {
            let response = await axios.delete(`admin/trainings/sources/${id}`);
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    // Training Instructors
    const getTrainingInstructors = async () => {
        let response = await axios.get(`admin/trainings/instructors`);
        training_instructors.value = response.data.data;
    }

    const insertTrainingInstructor = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/trainings/instructors`, data);
            training_instructors.value = response.data.training_instructors;
            training_instructor.value = response.data.training_instructor;
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    // LearningPlans
    const getPrivateLearningPlanTemplates = async () => {
        let response = await axios.get(`admin/trainings/templates`);
        templates.value = response.data.data;
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    /** Course Search Filter **/
    const getCourseSelectedFilter = async (params) => {
        let response = await axios.get(`admin/course/get-trainings-selected-filter`, {params: params});
        searchFilters.value = response.data;
    }

    const getCourseFilter = async (params, page) => {
        let response = await axios.get(`admin/course/get-trainings-filter?user_id=${page.authuser.id}`, {params: params});
        courseFiltered.value = response.data.trainings
        page.isLoading = false
    }

    /* Saving filters (all account types) */
    const getSavedFilters = async (page) => {
        let response = await axios.get(`admin/get-saved-filters?user_id=${page.authuser.id}`);
        saved_filters.value = response.data;
    }

    const deleteSavedFilter = async (page, filter_id) => {
        let response = await axios.get(`admin/delete-training-filter?filter_id=${filter_id}`);
        alertMessage(response.data.message);
        page.isfullScreenLoading = false;
    }

    const saveTrainingFilter = async (page, formData) => {
        let datas = {
            user_id: formData.user_id,
            filter_name: formData.filter_name,
            skill_type: formData.skill_type,
            course_type: formData.course_type,
            difficulty: formData.difficulty,
            source: formData.source,
            author: formData.author,
            tags: formData.tags,
            keyword: formData.keyword
        };
        let response = await axios.post(`admin/save-training-filter`, datas);
        alertMessage(response.data.message);
        page.isfullScreenLoading = false;
    }

    return {
        templates,
        training_sources,
        training_source,
        trainings,
        training,
        errors,
        message,
        status,
        training_instructors,
        training_instructor,
        searchFilters,
        getPrivateLearningPlanTemplates,
        getTrainings,
        getTraining,
        insertTraining,
        updateTraining,
        destroyTraining,
        unlinkImage,
        unlinkVideo,
        getTrainingSources,
        insertTrainingSource,
        getSelectedTrainings,
        searchTrainings,
        getCandidateTrainings,
        alertMessage,
        getPremiumTrainings,
        getTrainingInstructors,
        insertTrainingInstructor,
        getCourseFilter,
        courseFiltered,
        getCourseSelectedFilter,
        updateTrainingSource,
        getTrainingSource,
        deleteTrainingSource,
        getSavedFilters,
        saved_filters,
        saveTrainingFilter,
        deleteSavedFilter,
        getPhotos,
        photos
    }

}