<template>
    <div class="tab-pane fade show active" id="notification" role="tabpanel">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0">Reminder Settings</h5>
            </div>
            <div class="card-body pt-0">
                <div class="row">
                    <div class="col-12">
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.reminder_unstarted_courses"
                            placeholder="Remind me for any unstarted courses for the last 3 days."
                            id="reminder_unstarted_courses"
                        />
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.reminder_job_application_updates"
                            placeholder="Remind me if there are no recent updates regarding your applications for the last 5 days."
                            id="reminder_job_application_updates"
                        />
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.reminder_saved_jobs_unapplied"
                            placeholder="Remind me for any saved jobs that were unapplied for the last 5 days."
                            id="reminder_saved_jobs_unapplied"
                        />
                        <div class="form-group mb-3">
                            <BaseButton :btnValue="`Update Reminder Settings`" :is-saved="status === 200" @btnClicked="saveNotification" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue';
import candidateNotificationRepo from '@/repositories/candidate/notification';

export default defineComponent({
    setup() {
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser'))
        });
        const { notification_setting, getNotificationSetting, updateNotificationSetting, status } = candidateNotificationRepo();

        onMounted(() => {
            getNotificationSetting(page);
        });

        const saveNotification = async () => {
            status.value = '';
            let formData = new FormData();
            formData.append('reminder_unstarted_courses', notification_setting.value.reminder_unstarted_courses ?? false);
            formData.append('reminder_job_application_updates', notification_setting.value.reminder_job_application_updates ?? false);
            formData.append('reminder_saved_jobs_unapplied', notification_setting.value.reminder_saved_jobs_unapplied ?? false);
            formData.append('is_reminder_settings', true);
            formData.append('user_id', page.authuser.id);
            await updateNotificationSetting(formData);
        }

        return {
            page,
            status,
            notification_setting,
            getNotificationSetting,
            saveNotification,
            updateNotificationSetting
        }
    }
})
</script>