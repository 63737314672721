import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";

import settingsRoute from './settings/index';
import jobsRoute from './employers/jobs/index';
import adminRoute from './admin/index';
import messagesRoute from './messages/index';
import candidateRoute from './candidate/index';
import applicantRoute from './employers/applicants/index';
import employersRoute from './employers/index';
import interviewTrainingRoute from './interviewTrainings/index';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/employer/auth/Login.vue")
  },
  {
    path: '/forgot-password',
    name: "forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        let user = JSON.parse(localStorage.getItem('authuser'));
        let nxt = {};
        if(user.role.id === 1) {
          nxt = { name: "admin.dashboard" };
        } else if (user.role.id === 2) {
          nxt = { name: "employer.dashboard" }
        } else if (user.role.id === 4) {
          nxt = { name: "candidate.dashboard" }
        }
        return next(nxt);
      }
      next();
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import("@/views/helpCenter/index.vue"),
    beforeEnter: (to, from, next) => {
      if(!store.getters["auth/authenticated"]) {
        return next({ name: "register" });
      }
      next();
    }
  },
  {
    path: '/help/support',
    name: 'help.support',
    component: () => import("@/views/helpCenter/index.vue"),
    beforeEnter: (to, from, next) => {
      if(!store.getters["auth/authenticated"]) {
        return next({ name: "register" });
      }
      next();
    }
  },
  {
    path: '/help/feedback',
    name: 'help.feedback',
    component: () => import("@/views/helpCenter/index.vue"),
    beforeEnter: (to, from, next) => {
      if(!store.getters["auth/authenticated"]) {
        return next({ name: "register" });
      }
      next();
    }
  },
  {
    path: '/auth/google/callback',
    name: 'google.callback',
    component: () => import("@/views/GoogleCallback.vue"),
    beforeEnter: (to, from, next) => {
      next();
    }
  },
  {
    path: '/auth/github/callback',
    name: 'github.callback',
    component: () => import("@/views/GithubCallback.vue"),
    beforeEnter: (to, from, next) => {
      next();
    }
  },
  {
    path: '/auth/linkedin/callback',
    name: 'linkedin.callback',
    component: () => import("@/views/LinkedinCallback.vue"),
    beforeEnter: (to, from, next) => {
      next();
    }
  },
  {
    path: '/auth/facebook/callback',
    name: 'facebook.callback',
    component: () => import("@/views/FacebookCallback.vue"),
    beforeEnter: (to, from, next) => {
      next();
    }
  },
  ...adminRoute,
  ...employersRoute,
  ...candidateRoute,
  ...settingsRoute,
  ...jobsRoute,
  ...messagesRoute,
  ...applicantRoute,
  ...interviewTrainingRoute
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Begin Navigation Guard script
router.beforeEach((to, from, next) => {

  const config = {
    super_admin:  1, // Super Admin
    employer:     2, // Employer
    employer_hr:  3, // Employer-HR
    candidate:    4, // Candidate
  };

  // If logged in
  if(store.getters["auth/authenticated"] || store.getters["adminauth/authenticated"]) {
    let user = JSON.parse(localStorage.getItem('authuser'));
    let role_id = user.role.id;

    // Guards for Candidate
    if(role_id === config.candidate) {
      if(
          to.path.includes("/admin/") ||
          to.path.includes("/employer/") ||
          to.path.includes("/login") ||
          to.path.includes("/register")
      )
      return next({ name: "candidate.dashboard" });
    }
    // Guards for Employer
    if(role_id === config.employer || role_id === config.employer_hr) {
      if(
          to.path.includes("/admin/") ||
          to.path.includes("/candidate/") ||
          to.path.includes("/login") ||
          to.path.includes("/register")
      )
      return next({ name: "employer.dashboard" });
    }
    // Guards for Super Admin
    if(role_id === config.super_admin) {
      if(
          to.path.includes("/employer/") ||
          to.path.includes("/candidate/") ||
          to.path.includes("/login")
      )
      return next({ name: "admin.dashboard" });
    }
  }

  // If not logged in
  else {
    if( to.path.includes("/candidate") ) {
      if( !to.path.includes("/login") && !to.path.includes("/register") )
        return next({ name: "candidate.login" });
    }
    if( to.path.includes("/employer") ) {
      if( !to.path.includes("/login") && !to.path.includes("/register") )
        return next({ name: "login" });
    }
    if( to.path.includes("/admin") ) {
      if( !to.path.includes("/login") && !to.path.includes("/register") )
        return next({ name: "admin.login" });
    }
  }
  next();
});
// End Navigation Guard script

export default router
