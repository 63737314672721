import axios from "axios";
import { ref, inject } from "vue";

export default function departmentRepo() {

    const departments = ref([]);
    const department = ref([]);
    const employees = ref([]);
    const status = ref([]);
    const errors = ref([]);
    const toast = inject('toast');

    const getDepartments = async (page) => {
        let response = await axios.get(`employer/departments/show-departments?company_id=${page.authuser.company_id}`);
        departments.value = response.data;
        page.isLoading = false;
    }

    const getDepartment = async (id) => {
        let response = await axios.get(`employer/departments/get-department?department_id=${id}`);
        department.value = response.data.department;
    }

    const addDepartment = async (formData) => {
        let response = await axios.post(`employer/departments/add-department`, formData);
        alertMessage(response.data.message);
    }

    const updateDepartment = async (formData) => {
        let response = await axios.post(`employer/departments/update-department`, formData);
        alertMessage(response.data.message);
    }

    const processAssignEmployees = async (formData) => {
        let response = await axios.post(`employer/departments/assign-employees`, formData);
        alertMessage(response.data.message);
    }

    const destroyDepartment = async (page) => {
        let response = await axios.get(`employer/departments/delete-department?department_id=${page.idToDelete}`);
        alertMessage(response.data.message);
    }

    const alertMessage = (alert) => {
        toast.success(alert);
    }

    const getCompanyEmployees = async (page, department_id) => {
        let response = await axios.get(`employer/departments/get-company-employees?company_id=${page.authuser.company_id}&department_id=${department_id}`);
        employees.value = response.data.employees;
        page.isLoading = false;
    }

    const assignUserToDepartment = async (formData) => {
        let response = await axios.post(`employer/departments/assign-user`, formData);
        alertMessage(response.data.message);
    }

    return {
        status,
        errors,
        employees,
        department,
        departments,
        alertMessage,
        addDepartment,
        getDepartment,
        getDepartments,
        updateDepartment,
        destroyDepartment,
        getCompanyEmployees,
        processAssignEmployees,
        assignUserToDepartment
    }
}