<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between mb-3">
                <h1 class="h3 mb-3">Archive Jobs</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <BaseTable
                                :data="jobs"
                                :options="headers"
                            >
                                <template v-slot:job_order_number="{ row }">
                                    <div style="width:110px;">{{ row.job_order_number }}</div>
                                </template>
                                <template v-slot:action="{ row }" class="text-center">
                                    <div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="showDetails(row.id, row.candidate_applications.length, row.candidate_applications)">View</a></li>
                                                <li><a class="dropdown-item" href="javascript:;" @click="cloneJob(row)">Clone</a></li>
                                                <li :class="row.candidate_applications.length > 0 ? 'custom_tooltip' : ''" data-tooltip="This Job post cannot be deleted because it has active applicants."><a class="dropdown-item text-danger" :class="row.candidate_applications.length > 0 ? 'disabled' : ''" href="javascript:;" @click="deleteJob(row.id)">Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay position-fixed full-screen-loader text-primary" v-if="state.isfullScreenLoading || !state.isDataLoaded">
                <i class="fa fa-spin fa-spinner fa-3x"></i>
            </div>
            <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`View Job Details`">
                <div class="mt-3 mb-3 px-3">
                    <div class="row mt-4 mx-2">
                        <div class="col-12" v-if="job">
                            <span class="text-sm">POSITION TITLE</span>
                            <h3>{{ job.position_title }}</h3>
                            <hr />
                            <div class="mb-3 mt-3">
                                <div class="mb-1" v-if="job.language"><b>Language: </b> <span class="badge bg-success" style="margin-right:3px;" v-for="language in job.languages" v-bind:key="language">{{ language.name ?? '' }}</span></div>
                                <div class="mb-1"><b>Job Type:</b> <span class="text-info">{{ job.job_type }}</span></div>
                                <div class="mb-1"><b>Location:</b> <span class="text-info">{{ job.city }} {{ job.state }}</span></div>
                                <div class="mb-1" v-if="job.location_role_id"><b>Location Role:</b> <span class="text-info">{{ location_roles.find(x => x.id === job.location_role_id).name }}</span></div>
                                <div class="mb-1" v-if="job.schedules"><b>Schedules:</b> <span class="text-info">{{ getSchedules() }}</span></div>
                                <div class="mb-1" v-if="job.job_time_frame"><b>Time Frame:</b> <span class="text-info">{{ time_frames.find(x => x.id === job.job_time_frame).name }}</span></div>
                            </div>
                            <h5>Job Description</h5>
                            <div class="mb-3" v-html="job.job_description"></div>
                            <h5>Job Benefits</h5>
                            <div v-html="job.job_benefits" v-if="job.job_benefits"></div>
                            <div v-else>Not Listed.</div>
                        </div>
                    </div>
                </div>
            </slideout>
        </div>
    </main>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import jobRepo from '@/repositories/jobs';
import globalRepo from '@/repositories/global';

export default {
    setup(props) {
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isfullScreenLoading: false,
            applicationCount: 0,
            applicationList: [],
            isDataLoaded: false
        });
        const { location_roles, time_frames, schedules } = globalRepo();
        const { jobs, job, getJob, getArchiveJobs, saveJob } = jobRepo();
        const visible = ref(false);

        const headers = [
            {
                key: 'job_order_number',
                label: 'Job Posting ID',
                class: 'posting-id-header'
            },
            {
                key: 'position_title',
                label: 'Position Title',
                class: 'posting-title-header'
            },
            {
                key: 'job_type_display',
                label: 'Job Type',
                class: 'posting-type-header'
            },
            {
                key: 'created_at',
                label: 'Date Posted',
                class: 'date-posted-header'
            },
            {
                key: 'job_expiry',
                label: 'Date Expired',
                class: 'date-posted-header'
            },
            {
                key: 'num_of_candidates',
                label: 'Positions Open',
                class: 'text-center num-candidates-header'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const showDetails = async (id, applicationsCount, applicationsList) => {
            state.isfullScreenLoading = true;
            await getJob(id, state);
            state.applicationCount = applicationsCount;
            state.applicationList = applicationsList;
            state.isfullScreenLoading = false;
            visible.value = true;
        }

        const getSchedules = () => {
            if(job.value.schedules) {
                let results = job.value.schedules.split(',');
                let content = '';
                results.filter(result => {
                    content += `${schedules.find(x => x.id == result).name}, `;
                });

                return content.slice(0, -2);
            }
        }

        const cloneJob = async (data) => {
            let skillsNeededData = data.skills_needed_object.map((sk) => Object.assign({}, { "skill_name": sk.name, "years_experience": sk.yrs_exp }));
            Object.assign(data, {
                id: null,
                skills_needed: JSON.stringify(skillsNeededData),
                screening_questions: JSON.stringify(data.screening_questions)
            });

            await save(data, true);
        }

        const save = async (data, isCloned = false) => {
            if (!isCloned) {
                toggleModal();
            }

            let formData = new FormData();
            formData.append('position_title', data.position_title ?? '');
            formData.append('country_id', data.country_id ?? '');
            formData.append('language', data.language ?? '');
            formData.append('location_role_id', data.location_role_id ?? 1);
            formData.append('job_type', data.job_type ?? '');
            formData.append('num_of_candidates', data.num_of_candidates ?? '');
            formData.append('schedules', data.schedules ?? '');
            formData.append('job_time_frame', data.job_time_frame ?? 2);
            formData.append('compensation_type', data.compensation_type ?? '');
            formData.append('exact_amount', data.exact_amount ?? '');
            formData.append('min_amount', data.min_amount ?? '');
            formData.append('max_amount', data.max_amount ?? '');
            formData.append('rate', data.rate ?? '');
            formData.append('job_description', data.job_description ?? '');
            formData.append('job_benefits', data.job_benefits ?? '');
            formData.append('include_default_benefits', data.include_default_benefits ?? 0);
            formData.append('submit_resume', data.submit_resume ?? 'Optional');
            formData.append('status', 'Active');
            formData.append('keywords', data.keywords ?? '');
            formData.append('id', data.id ?? '');
            formData.append('company_id', state.authuser.company_id ?? '');
            formData.append('screening_questions', data.screening_questions ?? '');
            formData.append('city', data.city ?? '');
            formData.append('state', data.state ?? '');
            formData.append('category_id', data.category_id ?? '');
            formData.append('skills_needed', data.skills_needed ?? '');
            formData.append('is_job_clone', 1);

            state.isfullScreenLoading = true;
            await saveJob(formData);
            
            job.value = [];
            state.hasEditedFields = false;
            state.isfullScreenLoading = false;
        }

        const onClosing = (e) => {
            // close after 3 seconds
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                e.close = true;
            }, 500)
        }

        onMounted( async () => {
            await getArchiveJobs(state);
            state.isDataLoaded = true;
        });

        return {
            state,
            jobs,
            job,
            getJob,
            getArchiveJobs,
            saveJob,
            headers,
            showDetails,
            getSchedules,
            onClosing,
            visible,
            location_roles,
            time_frames,
            cloneJob
        }
    }
}
</script>