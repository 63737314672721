<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between mb-3">
                <h1 class="h3 mb-3">Manage Training Courses</h1>
                <div>
                    <button class="btn btn-primary add-trainingcourse" @click="createTraining">Add New Course</button>
                </div>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div v-else>
                <div v-if="false" class="row">
                    <div class="col-lg-2 mt-2">
                        <div class="form-group mb-3">
                            <label for="learning_plan_id" class="form-label">Select Learning Plan</label>
                            <select id="learning_plan_id" v-model="learning_plan_id" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                                <option value="">--</option>
                                <option v-for="template in templates" :key="template" :value="template.id">{{ template.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-2 mt-2">
                        <div class="form-group mb-3">
                            <label for="filter" class="form-label">Filter by</label>
                            <select v-model="filter" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                                <option value="">--</option>
                                <option v-for="filter in course_filters" :key="filter" :value="filter.id">{{ filter.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-8 mt-2">
                        <div class="form-group">
                            <label for="query_search" class="form-label">Search Courses</label>
                            <input
                                v-model="query_search"
                                class="form-control"
                                id="query_search"
                                placeholder="Search Courses by Title"
                                @input="searchCourse"
                                :disabled="isSearchDisabled"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <div class="card shadow ">
                            <div class="card-body">
                                <h5 class="filter-text-header"><i class="fa fa-sliders pe-2"></i>FILTERS</h5>
                                <hr style="opacity: 1;" />
                                <input class="form-control" type="search" placeholder="Search Course..." v-model="selected.keyword">
                                <button class="btn btn-light btn-sm w-100 mt-4" @click="clearFilter"> Clear Filter </button>
                                <h5 class="lead mt-3"><strong>Skill Type</strong></h5>
                                <div v-for="(type, i) in assigned_course_filters" :key="i" class="d-flex">
                                    <input type="checkbox" :value="type.name" :id="'skill_type'+i" v-model="selected.skill_type">
                                    <label :for="`skill_type${i}`" class="ml-7px text-sm">{{ type.name }}</label>
                                </div>

                                <h5 class="lead mt-3"><strong>Course Type</strong></h5>
                                <div class="d-flex">
                                    <input type="checkbox" value="Basic" id="basic" v-model="selected.course_type">
                                    <label for="basic" class="ml-7px text-sm">Basic</label>
                                </div>
                                <div class="d-flex">
                                    <input type="checkbox" value="Premium" id="premium" v-model="selected.course_type">
                                    <label for="premium" class="ml-7px text-sm">Premium</label>
                                </div>

                                <h5 class="lead mt-3"><strong>Difficulty</strong></h5>
                                <div v-for="(diff, i) in course_difficulty" :key="i" class="d-flex">
                                    <input type="checkbox" :value="diff.name" :id="'difficulty'+i" v-model="selected.difficulty">
                                    <label :for="`difficulty${i}`" class="ml-7px text-sm">{{ diff.name }}</label>
                                </div>

                                <h5 class="lead mt-3"><strong>Source</strong></h5>
                                <div v-for="(source, i) in searchFilters.sources" :key="i" class="d-flex">
                                    <input type="checkbox" :value="source.id" :id="'source'+i" v-model="selected.source">
                                    <label :for="`source${i}`" class="ml-7px text-sm">{{ source.name }}</label>
                                </div>

                                <h5 class="lead mt-3"><strong>Author</strong></h5>
                                <div v-for="(author, i) in searchFilters.author" :key="i" class="d-flex">
                                    <input type="checkbox" :value="author" :id="'author'+i" v-model="selected.author">
                                    <label :for="`author${i}`" class="ml-7px text-sm">{{ author }}</label>
                                </div>

                                <h5 class="lead mt-3"><strong>Tags</strong></h5>
                                <div v-for="(tag, i) in searchFilters.tags" :key="i" class="d-flex">
                                    <input type="checkbox" :value="tag" :id="'tag'+i" v-model="selected.tags">
                                    <label :for="`tag${i}`" class="ml-7px text-sm">{{ tag }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <div>
                            <div class="">
                                <span v-if="courseFiltered.length > 0" class="me-3">Results found {{courseFiltered.length}}</span>
                                <span v-for="(filter, i) in selected.skill_type" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                                    {{filter}}
                                </span>
                                <span v-for="(filter, i) in selected.difficulty" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                                    {{filter}}
                                </span>
                                <span v-for="(filter, i) in selected.source" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                                    {{sourceFilterData(filter)[0].name}}
                                </span>
                                <span v-for="(filter, i) in selected.author" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                                    {{filter}}
                                </span>
                                <span v-for="(filter, i) in selected.tags" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                                    {{filter}}
                                </span>
                            </div>
                        </div>
                        <div class="" v-if="courseFiltered.length">
                            <div class="course-card me-2 shadow mt-2" v-for="training in courseFiltered" :key="training.id">
                                <div class="d-flex">
                                    <div class="card card-link" >
                                        <div class="d-flex justify-content-between card-absolute">
                                            <div class="d-flex align-items-center">
                                            </div>
                                            <div class="card-btn-actions">
                                                <div class="dropdown position-relative">
                                                    <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-more-horizontal align-middle"
                                                        >
                                                            <circle cx="12" cy="12" r="1"></circle>
                                                            <circle cx="19" cy="12" r="1"></circle>
                                                            <circle cx="5" cy="12" r="1"></circle>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-end">
                                                        <a class="dropdown-item" href="javascript:;" @click="editTraining(training.id)">Edit</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a :href="training.link ?? training.video" target="_blank" class="card-link">
                                            <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                                            <div class="card-header card-header-items px-4 pt-4">
                                                <h5 class="card-title" v-text="training.title_limit"></h5>
                                            </div>
                                        </a>
                                        <div class="card-body training-card">
                                            <p class="card-text">
                                                {{ training.author }}<br>
                                                <b>Source: {{ training.source_name }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12">
                                <div class="text-center">
                                    <img src="/assets/images/empty.png" class="img-fluid img-30">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End col 10 -->
                </div>

            </div>
        </div>
        <ModalTraining
            :modalActive="modalActive"
            :form="training"
            :errors="errors"
            :hasErrors="page.formValidatorErrors"
            :modalTitle="page.modalHeader"
            :has-image="training.base_thumbnail !== undefined && training.base_thumbnail !== ''"
            @close="toggleModal"
            @saveChanges="save"
            @onDeleteBtn="deleteTraining"
            @open-current-modal="openModalTraining"
            @save-photo="savePhoto"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this training course?.`"
            @cancel="toggleConfirmation"
            @isDelete="destroy"
        />
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, watch } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import ModalTraining from '@/views/admin/trainings/ModalTraining.vue';
import globalRepo from '@/repositories/global';
import _debounce from 'lodash/debounce';

export default defineComponent({
    setup() {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Add New Training Course',
            imageFile: '',
            selectedImage: '',
            imageType: 0
        });

        const { 
            status,
            errors,
            templates,
            trainings,
            training,
            getTraining,
            insertTraining,
            updateTraining,
            destroyTraining,
            getSelectedTrainings,
            getPrivateLearningPlanTemplates,
            searchTrainings,
            getCourseSelectedFilter,
            getCourseFilter,
            searchFilters,
            courseFiltered
        } = trainingRepo();
        const { course_filters, assigned_course_filters, course_difficulty } = globalRepo();
        const isConfirm = ref(false);
        const modalActive = ref(false);
        const query_search = ref('');
        const isDisabled = ref(false);
        const isSearchDisabled = ref(false);

        const learning_plan_id = ref('');
        const filter = ref('');
        const selected = ref({
            skill_type: [],
            course_type: ['Basic', 'Premium'],
            difficulty: [],
            source: [],
            author: [],
            tags: [],
            keyword: ''
        })
        const clearFilter = () => {
            selected.value.skill_type = [];
            selected.value.course_type = [
                'Basic', 'Premium'
            ];
            selected.value.difficulty = [];
            selected.value.source = [];
            selected.value.author = [];
            selected.value.tags = [];
            selected.value.keyword = '';
        }
        const sourceFilterData = (id) => {
            return searchFilters.value.sources.filter(data => data.id == id)
        }

        watch(selected.value,
            async () => {
                var params = {
                    skill_type: selected.value.skill_type,
                    course_type: selected.value.course_type,
                    difficulty: selected.value.difficulty,
                    source: selected.value.source,
                    author: selected.value.author,
                    tags: selected.value.tags,
                    keyword: selected.value.keyword
                }
                await getCourseFilter(params, page)
            },
            { deep: true }
        );

        onMounted( async () => {
            getPrivateLearningPlanTemplates();
            await getCourseSelectedFilter();
            await getCourseFilter({}, page);
        });

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const toggleModal = async (evt) => {
            page.formValidatorErrors = {}
            if(evt !== undefined && evt === false) {
                page.isLoading = true;
                await getCourseFilter({}, page)
            }
            modalActive.value = !modalActive.value;
        }

        const createTraining = () => {
            toggleModal();
            errors.value = [];
            training.value = [];
            training.value.learning_plans = []
            training.value.tags_arr = []
            page.formValidatorErrors = {}
        }

        const editTraining = async (id) => {
            page.modalHeader = 'Update Training Course';
            toggleModal();
            errors.value = [];
            await getTraining(id);
        }

        const deleteTraining = () => {
            toggleConfirmation();
        }

        const save = async () => {
            let formData = new FormData();
            formData.append('title', training.value.title ?? '');
            formData.append('author', training.value.author ?? '');
            formData.append('link', training.value.link ?? '');
            formData.append('image', page.imageFile ?? '');
            formData.append('training_source_id', training.value.training_source_id ?? '');
            formData.append('content', training.value.content ?? '');
            formData.append('skill_type', training.value.skill_type ?? '');
            formData.append('learning_plan_ids', JSON.stringify(training.value.learning_plans));
            formData.append('tags', training.value.tags ?? '');
            formData.append('user_id', page.authuser.id ?? '');
            formData.append('difficulty', training.value.difficulty ?? null);
            formData.append('selected_image', page.selectedImage ?? '');

            let id = training.value.id;

            if(id) {
                formData.append('id', id ?? '');
                formData.append('_method', 'PUT');
                await updateTraining(formData, id);
                training.value = [];
            } else {
                await insertTraining(page, formData);
            }

            if(status.value == 200) {
                await getCourseFilter({}, page)
                toggleModal();

                if(filter.value) {
                    await filteredTrainings();
                } else {
                    await getCourseFilter({}, page)
                }

                await getPrivateLearningPlanTemplates();
            }
        }

        const destroy = async () => {
            page.isLoading = true;
            await destroyTraining(page, training.value.id);
            if(status.value === 200) {
                await getCourseFilter({}, page)
                toggleConfirmation();
            }
        }

        const filteredTrainings = async () => {
            query_search.value = '';
            let formData = new FormData();
            formData.append('learning_plan_id', learning_plan_id.value);
            formData.append('filter', filter.value);
            formData.append('user_id', page.authuser.id);
            formData.append('component', 'Courses');
            await getSelectedTrainings(formData);
        }

        const searchCourse = _debounce(function (event) {
            page.isLoading = true;
            let formData = new FormData();
            formData.append('query_search', event.target.value);
            formData.append('learning_plan_id', learning_plan_id.value);
            formData.append('filter', filter.value);
            formData.append('user_id', page.authuser.id);
            formData.append('component', 'Courses');

            searchTrainings(page, formData);
        }, 750);

        const openModalTraining = () => {
            modalActive.value = true;
        }

        const savePhoto = (evt) => {
            page.imageFile = evt.imageFile;
            page.imageType = evt.imageType;
            page.selectedImage = evt.imageSelected;
            modalActive.value = true;
        }

        return {
            page,
            errors,
            status,
            templates,
            toggleConfirmation,
            toggleModal,
            isConfirm,
            modalActive,
            trainings,
            training,
            getTraining,
            insertTraining,
            updateTraining,
            destroyTraining,
            createTraining,
            editTraining,
            deleteTraining,
            save,
            destroy,
            filteredTrainings,
            getSelectedTrainings,
            course_filters,
            isSearchDisabled,
            query_search,
            isDisabled,
            learning_plan_id,
            filter,
            searchCourse,
            assigned_course_filters,
            course_difficulty,
            selected,
            clearFilter,
            sourceFilterData,
            searchFilters,
            courseFiltered,
            openModalTraining,
            savePhoto
        }
    },
    components: {
        ModalTraining
    }
});
</script>

<style scoped>
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.feather { margin-bottom: 2px; }
.training-card {
    background-color: #fff;
    color: #333;
    border-top: 1px solid #eee;
}
.card-link {
    cursor: pointer;
    width: 240px;
}
.fa-training-icon {
    font-size: 20px;
    color: #fff;
}
.object-cover {
    object-fit: cover;
}
.course-img {
    height: 135px !important;
    width: 240px !important;
}
.has-premium {
    position: absolute;
    left: 10px;
    top: 10px;
    background: #efa600;
    color: #FFF;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 10px;
}
.course-card { display: inline-block; }
.shadow {
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.05)!important;
}
.card-header-items {
    height: 105px;
}
.ml-7px {
    margin-left: 7px;
}
.text-sm {
    font-size: 14px !important;
}
.filter-text-header {
    font-size: 17px;
    font-weight: 500;
}
</style>