import axios from "axios";
import { ref, inject } from "vue";

export default function jobRepo() {

    const jobs = ref([]);
    const job  = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const toast = inject('toast');
    const jobCategory = ref([]);
    const promotedJobs = ref([]);

    const getJobs = async (page) => {
        let response = await axios.get(`employer/jobs?company_id=${page.authuser.company_id}`);
        jobs.value = response.data.data;
        page.isLoading = false;
    }

    const getActiveJobs = async (page) => {
        let response = await axios.get(`employer/jobs/active?company_id=${page.authuser.company_id}`);
        jobs.value = response.data.data;
        page.isLoading = false;
    }

    const getArchiveJobs = async (page) => {
        let response = await axios.get(`employer/jobs/archive?company_id=${page.authuser.company_id}`);
        jobs.value = response.data.data;
        page.isLoading = false;
    }

    const getJobCategory = async () => {
        let response = await axios.get(`employer/jobs/job-category`);
        jobCategory.value = response.data.data;
    }

    const getJob = async (id, page) => {
        let response = await axios.get(`employer/jobs/${id}`);
        job.value = response.data.job;
        page.isLoading = false;
    }

    const saveJob = async (data) => {
        errors.value = {};
        try {
            let response = await axios.post(`employer/jobs`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alertMessage(response.data.message);
            message.value = response.data.message;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateJobStatus = async (id) => {
        let response = await axios.get(`employer/jobs/status/${id}`);
        status.value = response.status;
        alertMessage(response.data.message);
    }

    const validateJob = async (data) => {
        errors.value = {};
        try {
            let response = await axios.post(`employer/jobs/validate`, data);
            status.value = response.data.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const destroyJob = async (id) => {
        let response = await axios.delete(`employer/jobs/${id}`);
        alertMessage(response.data.message);
        message.value = response.data.message;
    }

    const alertMessage = (alert) => {
        toast.success(alert);
    }

    const searchJobtile = async (page, data) => {
        errors.value = {};
        try {
            let response = await axios.post(`employer/jobs/search`, data);
            jobs.value = response.data.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const searchByJobType = async (page, type) => {
        errors.value = {};
        try {
            let response = await axios.post(`employer/jobs/search`, {
                job_type: type,
                filter: 2,
                company_id: page.authuser.company_id ?? '',
                user_id: page.authuser.id
            });
            jobs.value = response.data.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const searchByCountry = async (page, country_id) => {
        errors.value = {};
        try {
            let response = await axios.post(`employer/jobs/search`, {
                country_id: country_id,
                filter: 3,
                company_id: page.authuser.company_id ?? '',
                user_id: page.authuser.id
            });
            jobs.value = response.data.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getPromotedJobs = async (page) => {
        let response = await axios.get(`employer/jobs/promoted-jobs?company_id=${page.authuser.company_id}`);
        promotedJobs.value = response.data.data;
        page.isLoading = false;
    }

    return {
        jobs,
        job,
        getJobs,
        getActiveJobs,
        getJob,
        saveJob,
        destroyJob,
        errors,
        message,
        status,
        validateJob,
        alertMessage,
        searchJobtile,
        searchByJobType,
        searchByCountry,
        jobCategory,
        getJobCategory,
        promotedJobs,
        getPromotedJobs,
        updateJobStatus,
        getArchiveJobs
    }
}