import axios from "axios";
import { ref, inject } from "vue";
import { useRouter } from 'vue-router';

export default function authRepo() {
    const toast = inject('toast');
    const router = useRouter();
    const errors = ref([]);
    const status = ref('');
    const message = ref('');
    const user = ref([]);
    const user_status = ref('');
    const passwordErrors = ref([]);

    const registerUser = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/auth/register`, data);
            if(localStorage.setItem('authuser', JSON.stringify(response.data.user))) {
                router.push({
                    name: 'employer.dashboard'
                });
            }
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }
    
    const loginUser = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/auth/login`, data);
            localStorage.setItem('authuser', JSON.stringify(response.data));
            router.push({
                name: 'employer.dashboard'
            });
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getUser = async () => {
        let response = await axios.get(`employer/authuser`);
        user.value = response.data;
    }

    const setUserStatus = async (user_id, status, withMessage = false) => {
        let response = await axios.get(`user/set-status/${status}/${user_id}`);
        user_status.value = status;
        if(withMessage){
            toast.success(response.data.message);
        }
    }

    const signOutUser = async (user, type) => {
        try {
            let response = await axios.post(`employer/auth/logout`, {
                user_id: user.id
            });
            
            if(response.data.status == 200) {
                axios.defaults.headers.common["Authorization"] = null;
                localStorage.removeItem('authuser');
                localStorage.removeItem('token');
                localStorage.removeItem('auth-qrcode');
                localStorage.removeItem('notification');
                window.location.href = '/'+type+'/login'
            }
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const twoFAEnable = async (page) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/two-factor-authentication`, {
                user_id: page.authuser.id
            });
            status.value = response.data.status;
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const confirmPassword = async (page, data, isActionDisabled = false) => {
        let formData = {
            user_id: page.authuser.id,
            password: data
        }
        if(isActionDisabled) {
            formData.is_action_disable = isActionDisabled;
        }
        let response = await axios.post(`employer/confirm-password`, formData);
        if(response.data.status === 422) {
            passwordErrors.value = response.data.message;
            status.value = response.data.status;
        } else {
            passwordErrors.value = '';
            if(!isActionDisabled) {
                localStorage.setItem('authuser', JSON.stringify(response.data.user));
                localStorage.setItem('auth-qrcode', response.data.qrcode);
                status.value = response.data.status;
                page.qrcode = response.data.qrcode;
                page.isTwoFactorEnabled = true;
            }
        }
    }

    const twoFADisable = async (page) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/two-factor-authentication/disable`, {
                user_id: page.authuser.id
            });
            status.value = response.data.status;
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
            localStorage.removeItem('auth-qrcode');
            page.isTwoFactorEnabled = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const twoFAChallenge = async (page, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/two-factor-authentication/challenge`, {
                user_id: page.authuser.id,
                code: data
            });
            status.value = response.data.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const resetPassword = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`reset-password`, data);
            status.value = response.data.status;
            message.value = response.data.message;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const recoverPassword = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`recover-password`, data);
            status.value = response.data.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    return {
        registerUser,
        loginUser,
        getUser,
        signOutUser,
        errors,
        status,
        user_status,
        user,
        twoFAEnable,
        passwordErrors,
        confirmPassword,
        twoFADisable,
        twoFAChallenge,
        resetPassword,
        recoverPassword,
        message,
        setUserStatus
    }
}