<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-lg`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-md-6">
                        <BaseSelect
                            v-model="form.education_level"
                            label="Level of Education"
                            id="education_level"
                            :options="education_levels"
                            :class="{ 'is-invalid' : errors.education_level }"
                            :errors="errors"
                            is-required
                        />
                        <BaseInput 
                            v-model="form.field_of_study"
                            label="Field of Study"
                            placeholder="Field of Study"
                            type="text"
                            id="field_of_study"
                            :class="{ 'is-invalid' : errors.field_of_study }"
                            v-if="form.education_level != 'high school'"
                            :errors="errors"
                        />
                        <BaseInput 
                            v-model="form.school_name"
                            label="School Name"
                            placeholder="School Name"
                            type="text"
                            id="school_name"
                            :class="{ 'is-invalid' : errors.school_name }"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-md-6">
                        <BaseInput 
                            v-model="form.school_address"
                            label="School Address"
                            placeholder="School Address"
                            type="text"
                            id="school_address"
                        />
                        <label for="time_period" class="form-label">School Year</label>
                        <div class="row">
                            <div class="col-6">
                                <Datepicker
                                    id="time_period"
                                    v-model="form.school_year_from" 
                                    format="yyyy"
                                    :enableTimePicker="false"
                                    :yearPicker="true"
                                    placeholder="From Year (eg. 2015)"
                                    :modelValue="form.school_year_from"
                                    :maxDate="new Date()"
                                    textInput
                                />
                            </div>
                            <div class="col-6">
                                <Datepicker
                                    id="time_period_to"
                                    v-model="form.school_year_to" 
                                    format="yyyy"
                                    :enableTimePicker="false"
                                    :yearPicker="true"
                                    placeholder="To Year (eg. 2019)"
                                    :modelValue="form.school_year_to ? form.school_year_to : ''"
                                    :disabled="!form.school_year_from"
                                    :minDate="new Date(month+' '+day+', '+form.school_year_from)"
                                    :maxDate="new Date()"
                                    textInput
                                />
                            </div>
                        </div>
                        <BaseInput 
                            v-model="form.id"
                            type="hidden"
                            id="id"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import globalRepo from '@/repositories/global';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        errors: {
            type: Object,
            default: {}
        }
    },
    setup(props, { emit }) {
        const { education_levels } = globalRepo();
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const datenow = new Date();
        const day = datenow.getDay();
        const month = months[datenow.getMonth()];

        const close = () => {
            emit('close')
        }
        
        const saveChanges = () => {
            emit('saveChanges');
        }

        return {
            day,
            month,
            close,
            saveChanges,
            education_levels
        }
    }
})
</script>