<template>
    <div>
        <div class="mt-3" v-if="suggested_trainings.length">
            <div class="course-card me-2" v-for="training in suggested_trainings" :key="training">
                <div class="card">
                    <div class="d-flex justify-content-between card-absolute">
                        <div
                        class="d-flex align-items-center">
                            <div
                            v-if="training.status == 'In Progress'"
                            class="badge my-2 bg-warning">In progress</div>
                        </div>
                        <div class="card-btn-actions">
                            <div class="dropdown position-relative">
                                <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-more-horizontal align-middle"
                                    >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>

                                <div class="dropdown-menu dropdown-menu-end">
                                    <a v-if="training.status == ''" class="dropdown-item" href="javascript:;"
                                    @click="insertTrainingCourse(training.id, training.training_id)">
                                        Enroll
                                    </a>
                                    <a class="dropdown-item" href="javascript:;" @click="unEnrollTraining(training.id)" v-if="training.status == 'Enrolled'">Unenroll</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a :href="training.link ?? training.video" target="_blank" class="card-link">
                        <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                        <div class="card-header card-header-items px-4 pt-4">
                            <h5 class="card-title" v-text="training.title_limit"></h5>
                        </div>
                    </a>
                    <div class="card-body training-card">
                        <div>{{ training.author }}</div>
                        <p class="card-text carousel-card-text">
                            <b>Source: {{ training.source_name }}</b>
                        </p>
                        <div class="mt-3" v-html="training.display_learning_plans"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5" v-else>
            <div class="text-center">
                <img src="/assets/images/empty.png" class="img-fluid img-30">
            </div>
        </div>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="page.confirmText"
            @cancel="toggleConfirmation"
            @isDelete="processComplete"
        />
        <Confirm
            :is-confirm="isUnenrollConfirm"
            :confirm-text="`Are you sure you want to unenroll this course?`"
            @cancel="toggleUnenrollConfirmation"
            @isDelete="processUnEnroll"
        />
    </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import trainingRepo from '@/repositories/candidate/trainings';

export default {
    props: {
        suggested_trainings: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        const { updateCourse, status, checkEnrolledTrainings, enrolledTrainings } = trainingRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            idToUpdate: '',
            confirmText: '',
            action: '',
            training_id: ''
        });
        const isConfirm = ref(false);
        const isUnenrollConfirm = ref(false);

        const insertTrainingCourse = (id, training_id) => {
            page.action = 1;
            page.idToUpdate = id;
            page.training_id = training_id;
            page.confirmText = 'Are you sure you want to start this course?';
            toggleConfirmation();
        }

        const updateTrainingCourse = (id) => {
            page.action = 3;
            page.idToUpdate = id;
            page.training_id = '';
            page.confirmText = 'Are you sure you want to complete this course?';
            toggleConfirmation();
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const processComplete = async () => {
            let formData = new FormData();
            formData.append('id', page.idToUpdate);
            formData.append('user_id', page.authuser.id);
            formData.append('action', page.action);
            if(page.training_id) {
                formData.append('training_id', page.training_id);
            }

            await updateCourse(formData);
            if(status.value === 200) {
                toggleConfirmation();
                await checkEnrolledTrainings(page)
                emit('refreshPage');
            }
        }

        const toggleUnenrollConfirmation = () => {
            isUnenrollConfirm.value = !isUnenrollConfirm.value;
        }

        const unEnrollTraining = (id) => {
            page.idToUpdate = id;
            toggleUnenrollConfirmation();
        }

        const processUnEnroll = async () => {
            let formData = new FormData();
            formData.append('training_id', page.idToUpdate);
            formData.append('user_id', page.authuser.id);
            formData.append('action', 4);

            await updateCourse(formData);
            if(status.value == 200) {
                toggleUnenrollConfirmation();
                await checkEnrolledTrainings(page);
            }
        }

        onMounted(async() => {
            document.getElementById("training-title-header").innerHTML = 'Training Courses';
            await checkEnrolledTrainings(page)
        });

        const trainingStatus = (suggested, enrolled) => {
            let found = enrolled.filter(data => data.training_id == suggested.training_id)
            if(found.length > 0){
                return {
                    status: true,
                    course_started: found[0].course_started,
                    course_completed: found[0].course_completed
                }
            }else{
                return { status: false }
            }
        }

        return {
            page,
            insertTrainingCourse,
            updateTrainingCourse,
            isConfirm,
            toggleConfirmation,
            processComplete,
            updateCourse,
            status,
            enrolledTrainings,
            trainingStatus,
            unEnrollTraining,
            toggleUnenrollConfirmation,
            isUnenrollConfirm,
            processUnEnroll
        }
    },
}
</script>
<style scoped>
.course-card { display: inline-block; width: 240px; }
.shadow {
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.05)!important;
}
.card-header-items {
    height: 105px;
}
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.feather {
    width: 24px !important;
    height: 24px !important;
    padding: 0 3px;
}
.training-card {
    height: 140px;
}
</style>