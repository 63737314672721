import axios from "axios";
import { ref, inject } from "vue";

export default function trainingRepo() {

    const toast = inject('toast');
    const subscription = ref([]);
    const invoices = ref([]);
    const payment = ref([]);
    const methods = ref([]);
    const company = ref([]);
    const billing = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const cancellations = ref([]);

    const insertBilling = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/billing`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const insertCustomer = async (page, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/billing/create-customer`, data);
            status.value = response.data.status;
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                page.paymentProcessing = false;
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const activeSubscription = async (page) => {
        let response = await axios.get(`employer/billing/active-subscription?user_id=${page.authuser.id}`);
        subscription.value = response.data.subscription;
        invoices.value = response.data.invoices.data;
        company.value = response.data.company;
        cancellations.value = response.data.subscription_cancellation;
        page.isLoading = false;
    }

    const listPaymentMethods = async (page) => {
        let response = await axios.get(`employer/billing/list-payment-method?user_id=${page.authuser.id}`);
        methods.value = response.data.methods;
        page.isLoading = false;
    }

    const unSubscribe = async (page) => {
        let response = await axios.get(`employer/billing/cancel-subscription?user_id=${page.authuser.id}`);
        status.value = response.status;
        localStorage.setItem('authuser', JSON.stringify(response.data.user));
        alertMessage(response.data.message);
    }

    const getPaymentIntent = async (page, id) => {
        let response = await axios.get(`employer/billing/get-payment/${id}?user_id=${page.authuser.id}`);
        payment.value = response.data.data;
        status.value = response.data.status;
    }

    const insertPaymentMethod = async (page, data) => {
        errors.value = '';
        page.paymentProcessing = true;
        try {
            let response = await axios.post(`employer/billing/insert-payment-method`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                page.paymentProcessing = false;
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
            if(e.response.status === 500) {
                page.paymentProcessing = false;
            }
        }
    }

    const processSavePaymentMethod = async (page, data) => {
        try {
            let response = await axios.post(`employer/billing/save-payment-method`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const createSubscription = async (data) => {
        try {
            let response = await axios.post(`employer/billing/create-subscription`, data);
            status.value = response.status;
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const purchaseJobPromotionBoost = async (data) => {
        try {
            let response = await axios.post(`employer/billing/purchase-job-promotion-boost`, data);
            status.value = response.data.status;
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const setDefaultPaymentMethod = async (page, id) => {
        let response = await axios.get(`employer/billing/set-default-payment-method/${id}?user_id=${page.authuser.id}`);
        methods.value = response.data.methods;
        status.value = response.data.status;
        alertMessage(response.data.message);
        localStorage.setItem('authuser', JSON.stringify(response.data.user));
    }

    const payBillingInvoice = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/billing/pay-invoice`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
            if(e.response.status === 500) {
                alertMessage(e.response.data.message, 500);
            }
        }
    }

    const getBillingDetails = async (page) => {
        let response = await axios.get(`employer/billing/get-billing-details?user_id=${page.authuser.id}`);
        billing.value = response.data.billing;
    }

    const detachPaymentMethod = async (id) => {
        let response = await axios.get(`employer/billing/detach-payment-method/${id}`);
        status.value = response.data.status;
    }

    const updateSubscription = async (page) => {
        let response = await axios.get(`employer/billing/update-subscription?user_id=${page.authuser.id}`);
        status.value = response.data.status;
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    return {
        toast,
        subscription,
        billing,
        invoices,
        payment,
        company,
        errors,
        message,
        status,
        methods,
        cancellations,
        insertBilling,
        insertCustomer,
        activeSubscription,
        listPaymentMethods,
        getPaymentIntent,
        insertPaymentMethod,
        setDefaultPaymentMethod,
        payBillingInvoice,
        getBillingDetails,
        detachPaymentMethod,
        updateSubscription,
        alertMessage,
        processSavePaymentMethod,
        createSubscription,
        unSubscribe,
        purchaseJobPromotionBoost
    }

}