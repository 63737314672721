import jobsRoute from '../candidate/jobs/index';
import trainingRoute from './trainings/index';

const routes = [
    {
        path: "/candidate/login",
        name: "candidate.login",
        component: () => import("@/views/candidate/auth/Login.vue")
    },
    {
        path: "/candidate/register",
        name: "candidate.register",
        component: () => import("@/views/candidate/auth/Register.vue")
    },
    {
        path: "/candidate/dashboard",
        name: "candidate.dashboard",
        component: () => import("@/views/candidate/Dashboard.vue")
    },
    {
        path: "/candidate/profile",
        name: "candidate.profile",
        component: () => import("@/views/candidate/Profile.vue")
    },
    {
        path: "/candidate/company/:company_id",
        name: "candidate.company",
        component: () => import("@/views/candidate/settings/profile/EmployerCompany.vue")
    },
    {
        path: "/candidate/employer-profile/:company_id",
        name: "candidate.profile.show",
        component: () => import("@/views/candidate/EmployerProfile.vue")
    },
    ...jobsRoute,
    ...trainingRoute
]

export default routes