import FeedbackManagement from '@/views/admin/feedback/Index';
import ViewFeedback from '@/views/admin/feedback/ViewFeedback';

const routes = [
    {
        path: '/admin/feedbacks/:id',
        name: 'admin.feedbacks',
        component: FeedbackManagement
    },
    {
        path: "/admin/feedback/:feedback_id",
        name: "admin.feedback",
        component: ViewFeedback
    }
]

export default routes