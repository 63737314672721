<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between">
                <h1 class="h3 mb-3">Manage Content Providers</h1>
                <div class="d-flex align-items-center">
                    <button class="btn btn-primary float-end btn-add-content" @click="addTrainingSource">Add a Content Provider</button>
                </div>
            </div>
            <PageLoader v-if="state.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-body" style="overflow-x: auto;">
                                <BaseTable
                                    :data="training_sources"
                                    :options="headers"
                                >
                                <template v-slot:counter="{ row }">
                                    <div class="text-center">
                                        {{ row.id }}
                                    </div>
                                </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="editSource(row.id)">Edit</a></li>
                                                <li><a class="dropdown-item text-danger" href="javascript:;" @click="deleteSources(row.id)">Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                                </BaseTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalSource
            :modalActive="modalActive"
            :modalTitle="state.modalHeader"
            :source_id="state.source_id"
            @close="modalActive = false"
            @saveChanges="refreshPage"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirmText="`Are you sure you want to delete this training source?`"
            @cancel="toggleConfirmation"
            @isDelete="destroyTrainingSource"
        />
    </main>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import ModalSource from '@/views/admin/trainings/modals/Index';

export default {
    components: {
        ModalSource
    },
    setup() {
        const state = reactive({
            modalHeader: 'Add Training Source',
            isLoading: false,
            source_id: 0,
            idToDelete: 0
        });
        const { training_sources, getTrainingSources, deleteTrainingSource, status } = trainingRepo();
        const modalActive = ref(false);
        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const headers = [
            {
                key: 'counter',
                label: '#',
                class: 'text-center w-3'
            },
            {
                key: 'name',
                label: 'Source Name'
            },
            {
                key: 'monthly',
                label: 'Monthly Price',
                class: 'text-center'
            },
            {
                key: 'yearly',
                label: 'Yearly Price',
                class: 'text-center'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            },
        ];

        const addTrainingSource = () => {
            modalActive.value = true;
        }

        const editSource = (id) => {
            modalActive.value = true;
            state.source_id = id;
        }

        const deleteSources = (id) => {
            state.idToDelete = id;
            toggleConfirmation();
        }

        const destroyTrainingSource = async () => {
            await deleteTrainingSource(state.idToDelete);
            if(status.value == 200) {
                getTrainingSources();
            }
            toggleConfirmation();
        }

        const refreshPage = async () => {
            modalActive.value = false;
            await getTrainingSources();
        }

        onMounted(() => {
            getTrainingSources();
        });

        return {
            state,
            training_sources,
            getTrainingSources,
            headers,
            editSource,
            deleteSources,
            modalActive,
            refreshPage,
            addTrainingSource,
            isConfirm,
            toggleConfirmation,
            destroyTrainingSource,
            status
        }
    },
}
</script>

<style>
.w-3 {
    width: 3%;
}
</style>