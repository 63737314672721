<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Manage Job Posting Questions</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" type="button" role="tab">Pre Screening Questions</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link btn-categories" type="button" role="tab" @click="goTo('/employer/settings/job/questionaire-categories')">Categories</button>
                        </li>
                    </ul>
                    <div class="card pt-3">
                        <div class="card-header">
                            <div class="float-start w-20" v-if="question_categories.length">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="filter-by">Filter by Category</span>
                                    </div>
                                    <select id="filter" class="form-select" aria-describedby="filter-by-cat" v-model="page.filterBy" @change="filterByCategory">
                                        <option value="">All Categories</option>
                                        <option v-for="cat in question_categories" v-bind:key="cat" :value="cat.id">{{ cat.category_title }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="card-actions float-end">
                                <button class="btn btn-primary text-white" @click="createQuestion">Add Pre-Screening Questions</button>
                            </div>
                        </div>
                        <div class="card-body pt-1">
                            <BaseTable
                                :data="questions"
                                :options="headers"
                            >
                            <template v-slot:question="{ row }">
                                <div v-html="linkify(row.question)"></div>
                            </template>
                            <template v-slot:category="{ row }">
                                <div v-if="row.categories.length > 0">
                                    <div style="margin-right: 5px;" class="badge bg-primary" v-for="item in row.categories" v-bind:key="item">{{ item.category_title }}</div>
                                </div>
                                <div v-else>
                                    <div class="badge bg-warning">Uncategorized</div>
                                </div>
                            </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li v-if="row.user_id !== 0"><a class="dropdown-item" href="javascript:;" @click="editQuestion(row.id)">Edit</a></li>
                                                <li v-if="row.user_id !== 0"><a class="dropdown-item text-danger" href="javascript:;" @click="deleteQuestion(row.id)">Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalQuestion
            :modalActive="modalActive"
            :form="question"
            :categories="question_categories"
            :errors="errors"
            :modalTitle="page.modalHeader"
            :buttonCallback="isSaveButtonDisabled"
            @close="toggleModal"
            @saveChanges="save"
        />
        <Confirm
            :is-button-disabled="isConfirmButtonDisabled"
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this questionaire?.`"
            @cancel="toggleConfirmation"
            @isDelete="removeQuestion"
        />
    </main>
</template>

<script>
import { useRouter } from 'vue-router'
import { defineComponent, ref, reactive, onMounted, inject } from 'vue';
import questionRepo from '@/repositories/settings/question';
import ModalQuestion from '@/views/employer/settings/jobs/ModalQuestion.vue';

export default defineComponent({
    setup() {
        const router = useRouter();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Create New Questions',
            idToDelete: '',
            filterBy: '',
            questions: []
        });

        const modalActive = ref(false);
        const isConfirm = ref(false);
        const isSaveButtonDisabled = ref(false);
        const isConfirmButtonDisabled = ref(false);

        const { questions, question, errors, getQuestions, getQuestion, addQuestion, updateQuestion, destroyQuestion, message, status, question_categories, getQuestionCategories } = questionRepo()

        const headers = [
            {
                key: 'question',
                label: 'Questionaire',
                class: 'w-50'
            },
            {
                key: 'creator',
                label: 'Creator',
                class: 'w-20'
            },
            {
                key: 'category',
                label: 'Category',
                class: 'w-20'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        onMounted( async () => {
            await getQuestions(page);
            await getQuestionCategories(page);
            page.questions = questions.value;
        });

        const createQuestion = () => {
            modalActive.value = true;
            errors.value = [];
        }

        const editQuestion = async (id) => {
            page.modalHeader = 'Update Job Questionaire';
            toggleModal();
            await getQuestion(id);
        }

        const deleteQuestion = (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
            question.value = [];
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const save = async (data) => {
            let formData = new FormData();
            formData.append('question', data.question);
            formData.append('category_ids', data.category_ids ?? '');
            formData.append('company_id', page.authuser.company_id);
            formData.append('user_id', page.authuser.id);
            isSaveButtonDisabled.value = true;
            if(question.value.id) {
                formData.append('_method', 'PUT');
                await updateQuestion(formData, question.value.id);
            } else {
                await addQuestion(formData);
            }
            isSaveButtonDisabled.value = false;
            if(status.value == 200) {
                question.value = [];
                page.filterBy = '';
                toggleModal();
                await getQuestions(page);
                page.questions = questions.value;
            }
        }

        const removeQuestion = async () => {
            isConfirmButtonDisabled.value = true;
            await destroyQuestion(page.idToDelete);
            toggleConfirmation();
            isConfirmButtonDisabled.value = false;
            getQuestions(page);
        }

        const linkify = (inputText) => {
            let replacedText, replacePattern1, replacePattern2, replacePattern3;
            replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
            replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
            replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
            replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
            replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
            return replacedText;
        }

        const goTo = (path) => {
            router.push(`${path}`);
        }

        const filterByCategory = () => {
            let filtered_data = [];
            page.questions.forEach(item => {
                var loopvar = item;
                if(item.categories !== null && item.categories.length) {
                    item.categories.forEach(i => {
                        if(i.id == page.filterBy) {
                            filtered_data.push(loopvar);
                        }
                    });
                }
            });

            if(page.filterBy) {
                if(filtered_data.length) {
                    questions.value = filtered_data;
                } else {
                    questions.value = [];
                }
            } else {
                questions.value = page.questions;
            }
        }

        return {
            headers,
            createQuestion,
            deleteQuestion,
            page,
            questions,
            question,
            question_categories,
            errors,
            getQuestions,
            getQuestion,
            addQuestion,
            editQuestion,
            updateQuestion,
            destroyQuestion,
            message,
            status,
            modalActive,
            toggleModal,
            save,
            isConfirm,
            toggleConfirmation,
            removeQuestion,
            linkify,
            goTo,
            filterByCategory,
            isSaveButtonDisabled,
            isConfirmButtonDisabled
        }
    },
    components: {
        ModalQuestion
    }
});
</script>
<style>
.meatballs-menu {
  float: none;
  text-align: center;
}
</style>
<style scoped>
#filter {
    width: 50%;
}
.nav-tabs .nav-link.active {
    background: #fff;
    border-bottom: transparent;
}
.nav-tabs {
    border-bottom: transparent;
}
.nav-tabs .nav-link {
    background: #f4f4f4;
    border-color: #eee;
}
</style>