<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-header px-0 pb-4">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="btn-group">
                                        <button class="btn btn-outline-primary" @click="changeTab('BrowsePlans')" :class="page.currentTab == 'BrowsePlans' ? 'active' : ''">Browse Premium Learning Plans</button>
                                        <button class="btn btn-outline-primary" @click="changeTab('SelectedPlans')" :class="page.currentTab == 'SelectedPlans' ? 'active' : ''">Your Lists ({{ page.selectedPlanCount }})</button>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <div class="browse-premium-plans-tab" v-if="page.currentTab == 'BrowsePlans'">
                            <PageLoader v-if="page.isLoading" />
                            <div v-else>
                                <div class="row" v-if="learning_plans.length">
                                    <div class="col-12" v-for="learning_plan in learning_plans" :key="learning_plan">
                                        <div class="card-wrap" v-if="learning_plan.trainings?.length">
                                            <div class="d-flex justify-content-between">
                                                <h3>{{ learning_plan.learning_plan_name }}</h3>
                                                <div class="btn-group">
                                                    <button :class="(JSON.parse(JSON.stringify(page.selectedPlanIds)).includes(''+learning_plan.learning_plan_id+'')) ? 'disabled' : ''" class="btn btn-success btn-sm" @click="selectPlan(learning_plan.learning_plan_id)"><i class="fa fa-check fa-fw"></i> {{ (JSON.parse(JSON.stringify(page.selectedPlanIds)).includes(''+learning_plan.learning_plan_id+'')) ? 'Added' : 'Add to list' }}</button>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="course-card me-2" v-for="training in learning_plan.trainings" :key="training">
                                                    <div class="card card-border">
                                                        <a :href="training.link" target="_blank" class="card-link">
                                                            <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                                                            <div class="card-header card-header-items px-4 pt-4">
                                                                <h5 class="card-title" v-text="training.title_limit"></h5>
                                                            </div>
                                                        </a>
                                                        <div class="card-body training-card">
                                                            <div>{{ training.author ?? training.company?.company_name }}</div>
                                                            <p class="card-text carousel-card-text">
                                                                <b>Source: {{ training.source?.name ?? 'Private Course' }}</b>
                                                            </p>
                                                            <div class="mt-3" v-html="training.display_learning_plans"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5" v-else>
                                    <div class="text-center">
                                        <img src="/assets/images/empty.png" class="img-fluid img-30">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="selected-premium-plans-tab" v-if="page.currentTab == 'SelectedPlans'">
                            <PageLoader v-if="page.isLoading" />
                            <div v-else>
                                <div class="row" v-if="premium_learning_plans.length">
                                    <div class="col-12" v-for="learning_plan in premium_learning_plans" :key="learning_plan">
                                        <div class="card-wrap">
                                            <div class="d-flex justify-content-between">
                                                <h3>{{ learning_plan.learning_plan_name }}</h3>
                                                <div class="meatballs-menu">
                                                    <div class="btn-group">
                                                        <button type="button" class="btn btn-light btn-sm rounded-circle" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                                        <ul class="dropdown-menu">
                                                            <li><a class="dropdown-item" href="javascript:;" @click="assignCustomLearningPlan(learning_plan.learning_plan_id)">Assign</a></li>
                                                            <li><a class="dropdown-item text-danger" href="javascript:;" @click="removeFromList(learning_plan.learning_plan_id)">Remove</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5>Course Progress</h5>
                                            <div class="progress course-progress ">
                                                <div class="progress-bar bg-success" role="progressbar" 
                                                :style="learningCourseProgress(learning_plan.trainings, learning_plan.learning_progress).cssCompleted" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning_plan.trainings, learning_plan.learning_progress).labelCompleted}} Complete
                                                </div>
                                                <div class="progress-bar bg-warning" role="progressbar" 
                                                :style="learningCourseProgress(learning_plan.trainings, learning_plan.learning_progress).cssProgress" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning_plan.trainings, learning_plan.learning_progress).labelProgress}} In Progress
                                                </div>
                                            </div>
                                            <div class="mt-4" v-if="learning_plan.trainings?.length">
                                                <div class="course-card me-2" v-for="training in learning_plan.trainings" :key="training">
                                                    <div class="card card-border">
                                                        <div class="d-flex justify-content-between card-absolute">
                                                            <div class="d-flex align-items-center">
                                                                <span class="badge bg-success text-white" v-if="trainingStatus(training.id, learning_plan.learning_progress)?.status == 'completed'">Completed</span>
                                                                <span class="badge bg-warning text-white" v-if="trainingStatus(training.id, learning_plan.learning_progress)?.status == 'progress'">In progress</span>
                                                            </div>
                                                            <div class="card-btn-actions">
                                                                <div class="dropdown position-relative">
                                                                    <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            class="feather feather-more-horizontal align-middle"
                                                                        >
                                                                            <circle cx="12" cy="12" r="1"></circle>
                                                                            <circle cx="19" cy="12" r="1"></circle>
                                                                            <circle cx="5" cy="12" r="1"></circle>
                                                                        </svg>
                                                                    </a>

                                                                    <div class="dropdown-menu dropdown-menu-end">
                                                                        <a class="dropdown-item" href="javascript:;" 
                                                                            @click="updateCourseStatus(learning_plan.learning_plan_id, training.id, 'completed')">
                                                                            Mark as Complete
                                                                        </a>
                                                                        <a class="dropdown-item" href="javascript:;" 
                                                                            @click="updateCourseStatus(learning_plan.learning_plan_id, training.id, 'progress')">
                                                                            Mark as In progress
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a :href="training.link" target="_blank" class="card-link">
                                                            <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                                                            <div class="card-header card-header-items px-4 pt-4">
                                                                <h5 class="card-title" v-text="training.title_limit"></h5>
                                                            </div>
                                                        </a>
                                                        <div class="card-body training-card">
                                                            <div>{{ training.author ?? training.company?.company_name }}</div>
                                                            <p class="card-text carousel-card-text">
                                                                <b>Source: {{ training.source?.name ?? 'Private Course' }}</b>
                                                            </p>
                                                            <div class="mt-3" v-html="training.display_learning_plans"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5" v-else>
                                    <div class="text-center">
                                        <img src="/assets/images/empty.png" class="img-fluid img-30">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalLearningPlan 
            :modalActive="modalActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @reloadPage="save"
        />
        <ModalAssignLearningPlan 
            :modalActive="modalLearningPlanActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            @close="toggleLearningPlanModal"
            @reloadPage="saveLearningPlan"
        />
        <ModalSubscription 
            :modalActive="subscriptionActive"
            :noHeaderBorder="true"
            :noBodyMargin="true"
            :userAccount="`Pro`"
            @close="toggleModalSubscription"
        />
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to remove this plan from your list?`"
            @cancel="toggleConfirmation"
            @isDelete="processRemoveFromList"
        />
    </div>
</template>

<script>
import { ref, reactive } from '@vue/reactivity';
import trainingRepo from '@/repositories/employer/trainings';
import ModalLearningPlan from '@/views/employer/trainings/modals/ModalLearningPlan.vue';
import ModalSubscription from '@/views/employer/ModalSubscription.vue';
import ModalAssignLearningPlan from '@/views/employer/trainings/modals/ModalAssignLearningPlan.vue';
import learningPlanRepo from '@/repositories/admin/learningplans';
import { onMounted, watchEffect } from '@vue/runtime-core';
import billingRepo from '@/repositories/employer/billing';

export default {
    setup() {
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Add Custom Learning Plan',
            idToDelete: '',
            currentTab: 'BrowsePlans',
            selectedPlanCount: 0,
            selectedPlanIds: ''
        });
        const { activeSubscription, subscription } = billingRepo();
        const { 
            premium_learning_plans, 
            getSelectedPremiumLearningPlans, 
            learning_plan, 
            getCustomLearningPlans, 
            getCustomLearningPlan, 
            destroyCustomLearningPlan, 
            status, 
            selectPremiumLearningPlan, 
            removePremiumLearningPlanFromList, 
            updateLearningPlanProgress
        } = trainingRepo();
        const { learning_plans, getPremiumLearningPlans } = learningPlanRepo();
        const modalActive = ref(false);
        const modalLearningPlanActive = ref(false);
        const isConfirm = ref(false);
        const subscriptionActive = ref(false);

        const is_free = () => {
            return subscription.value==null ? true : false;
        }
        const is_pro = () => {
            if(subscription.value != null) {
                return ( !is_free() && (subscription.value.stripe_price == process.env.VUE_APP_SUBSCRIPTION_TYPE_PRO_PRICE_YEAR || subscription.value.stripe_price == process.env.VUE_APP_SUBSCRIPTION_TYPE_PRO_PRICE_MONTH) );
            }
            return false;
        }

        const toggleModalSubscription = () => {
            subscriptionActive.value = !subscriptionActive.value;
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleLearningPlanModal = () => {
            modalLearningPlanActive.value = !modalLearningPlanActive.value;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const save = async () => {
            toggleModal();
            await getCustomLearningPlans(page);
        }

        const saveLearningPlan = () => {
            toggleLearningPlanModal();
        }

        const updateCustomLearningPlan = async (id) => {
            toggleModal();
            await getCustomLearningPlan(id);
            page.modalHeader = 'Update Custom Learning Plan';
        }

        const deleteCustomLearningPlan = (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const processDelete = async () => {
            await destroyCustomLearningPlan(page);
            await getCustomLearningPlans(page);
            if(status.value == 200) {
                toggleConfirmation();
            }
        }

        const assignCustomLearningPlan = async (id) => {
            await getCustomLearningPlan(id);
            page.modalHeader = 'Assign Premium Learning Plan to Employee(s)';
            toggleLearningPlanModal();
        }

        const refreshData = async () => {
            let rawPlanIds = [];
            await getSelectedPremiumLearningPlans(page);
            page.selectedPlanCount = premium_learning_plans.value.length ?? 0;
            if(page.selectedPlanCount > 0) {
                premium_learning_plans.value.forEach(item => {
                    rawPlanIds.push(item.learning_plan_id);
                });
            }
            page.selectedPlanIds = rawPlanIds;
            await getPremiumLearningPlans(page);
            await activeSubscription(page);
        }

        onMounted( async () => {
            await refreshData();
        });

        const updateCourseStatus = async (learningID, trainingID, type) => {
            let formData = new FormData();
            formData.append('user_id', page.authuser.id);
            formData.append('learning_id', learningID);
            formData.append('training_id', trainingID);
            formData.append('type', type);
            await updateLearningPlanProgress(formData)
            await refreshData();
        }

        const trainingStatus = (trainingID, learning_progress) => {
            const found = learning_progress.filter( data => data.training_id == trainingID)[0]
            return found
        }

        const courseProgress = (learningID) => {
            let totalCourse = 0
            let totalCompleted = 0
            learning_plans.value.map( data => {
                if (data.learning_plan_id == learningID){
                    let trainingCourse = data.completed_trainings ? JSON.parse(data.completed_trainings):[];
                    totalCompleted = trainingCourse.length
                    totalCourse = data.trainings.length
                }
            })
            const perc = ((totalCompleted/totalCourse) * 100).toFixed()
            return {
                css:'width:'+`${perc}`+'%;',
                label: `${perc}`+'%'
            }
        }

        const changeTab = async (tab) => {
            page.isLoading = true;
            page.currentTab = tab;
            await refreshData();
        }

        const selectPlan = async (id) => {
            page.isLoading = true;
            await selectPremiumLearningPlan(page, id);
            await refreshData();
        }

        const removeFromList = (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const processRemoveFromList = async () => {
            toggleConfirmation();
            page.isLoading = true;
            await removePremiumLearningPlanFromList(page);
            await refreshData();
        }

        watchEffect(()=> {
            page.selectedPlanCount = premium_learning_plans.value.length ?? 0;
        });

        const learningCourseProgress = (trainings, progress) => {
            let totalTrainings = trainings.length
            let completedCnt = 0
            let progressCnt = 0
            progress.map( data => {
                if(data.status == 'completed'){
                    completedCnt++
                }else if(data.status == 'progress')(
                    progressCnt++
                )
            })
            const completed = ((completedCnt/totalTrainings) * 100).toFixed(2)
            const inprogress = ((progressCnt/totalTrainings) * 100).toFixed(2)
            return {
                percProgress: ((progressCnt/totalTrainings) * 100).toFixed(2),
                percCompleted: ((completedCnt/totalTrainings) * 100).toFixed(2),
                cssCompleted: 'width:'+`${completed}`+'%;',
                labelCompleted: `${completed}`+'%',
                cssProgress: 'width:'+`${inprogress}`+'%;',
                labelProgress: `${inprogress}`+'%'
            }
        }

        return {
            page,
            modalActive,
            modalLearningPlanActive,
            isConfirm,
            toggleConfirmation,
            toggleModal,
            toggleLearningPlanModal,
            learning_plan,
            save,
            saveLearningPlan,
            updateCustomLearningPlan,
            deleteCustomLearningPlan,
            learning_plans,
            getCustomLearningPlans,
            getCustomLearningPlan,
            destroyCustomLearningPlan,
            assignCustomLearningPlan,
            processDelete,
            status,
            updateCourseStatus,
            trainingStatus,
            courseProgress,
            changeTab,
            premium_learning_plans,
            selectPlan,
            processRemoveFromList,
            removeFromList,
            toggleModalSubscription,
            subscriptionActive,
            learningCourseProgress
        }
    },
    components: {
        ModalLearningPlan,
        ModalAssignLearningPlan,
        ModalSubscription
    }
}
</script>

<style scoped>
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.feather {
    width: 24px !important;
    height: 24px !important;
    padding: 0 3px;
}
.card-wrap {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
}
.card-border {
    border: 1px solid #ccc !important;
}
.course-progress { 
    width: 100%; 
    border-radius: 10px!important;
    font-size: 11px!important;
    height: 25px;
}
.course-card { display: inline-block; width: 240px; }
.shadow {
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.05)!important;
}
.card-header-items {
    height: 105px;
}
.training-card {
    height: 140px;
}
</style>