import ConfigIndex from '@/views/employer/settings/config/Index';
import UserIndex from '@/views/employer/settings/users/Index';
import CandidateProfileSettingsIndex from '@/views/candidate/settings/profile/Index';
import CandidatePrivacySettingsIndex from '@/views/candidate/settings/privacy/Index';
import JobQuestionIndex from '@/views/employer/settings/jobs/QuestionIndex';
import JobQuestionCategoryIndex from '@/views/employer/settings/jobs/QuestionCategoryIndex';
import DepartmentIndex from '@/views/employer/settings/departments/Index';
import store from "@/store";

const authuser = JSON.parse(store.getters["auth/user"]);

const routes = [
    {
        path: '/employer/settings/config/company-tab',
        name: 'settings.config',
        component: ConfigIndex,
        beforeEnter: (to, from, next) => {
            let role_allowed = ['2','3'];
            if (role_allowed.includes(authuser.role_id)) {
                return next({
                    name: "employer.dashboard",
                    query: {
                        withError: true,
                        message: 'Sorry, you are not authorized to access that page.'
                    }
                });
            }
            next();
        }
          
    },
    {
        path: '/employer/settings/users',
        name: 'settings.users',
        component: UserIndex,
        beforeEnter: (to, from, next) => {
            if (authuser.role_id !== 2) {
                return next({
                    name: "employer.dashboard",
                    query: {
                        withError: true,
                        message: 'Sorry, you are not authorized to access that page.'
                    }
                });
            }
            next();
        }
    },
    {
        path: '/employer/settings/config',
        name: 'settings.users.edit',
        component: ConfigIndex,
        beforeEnter: (to, from, next) => {
            let role_allowed = ['2','3'];
            if (role_allowed.includes(authuser.role_id)) {
                return next({
                    name: "employer.dashboard",
                    query: {
                        withError: true,
                        message: 'Sorry, you are not authorized to access that page.'
                    }
                });
            }
            next();
        }
    },
    {
        path: '/employer/settings/config/billing',
        name: 'settings.users.billing',
        component: ConfigIndex,
        beforeEnter: (to, from, next) => {
            if (authuser.role_id !== 2) {
                return next({
                    name: "employer.dashboard",
                    query: {
                        withError: true,
                        message: 'Sorry, you are not authorized to access that page.'
                    }
                });
            }
            next();
        }
    },
    {
        path: '/employer/settings/job/questionaire',
        name: 'settings.jobs.questions',
        component: JobQuestionIndex,
        beforeEnter: (to, from, next) => {
            let role_allowed = ['2','3'];
            if (role_allowed.includes(authuser.role_id)) {
                return next({
                    name: "employer.dashboard",
                    query: {
                        withError: true,
                        message: 'Sorry, you are not authorized to access that page.'
                    }
                });
            }
            next();
        }
    },
    {
        path: '/employer/settings/job/questionaire-categories',
        name: 'settings.jobs.question-categories',
        component: JobQuestionCategoryIndex,
        beforeEnter: (to, from, next) => {
            let role_allowed = ['2','3'];
            if (role_allowed.includes(authuser.role_id)) {
                return next({
                    name: "employer.dashboard",
                    query: {
                        withError: true,
                        message: 'Sorry, you are not authorized to access that page.'
                    }
                });
            }
            next();
        }
    },
    {
        path: '/employer/settings/departments',
        name: 'settings.departments',
        component: DepartmentIndex,
        beforeEnter: (to, from, next) => {
            let role_allowed = ['2','3'];
            if (role_allowed.includes(authuser.role_id)) {
                return next({
                    name: "employer.dashboard",
                    query: {
                        withError: true,
                        message: 'Sorry, you are not authorized to access that page.'
                    }
                });
            }
            next();
        }
    },
    {
        path: '/candidate/settings/profile',
        name: 'candidate.settings.profile',
        component: CandidateProfileSettingsIndex
    },
    {
        path: '/candidate/settings/privacy',
        name: 'candidate.settings.privacy',
        component: CandidatePrivacySettingsIndex
    },
    {
        path: '/candidate/settings/profile/upload-resume',
        name: 'candidate.settings.upload-resume',
        component: CandidateProfileSettingsIndex
    }
]

export default routes