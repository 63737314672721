import axios from "axios";
import { ref, inject } from "vue";

export default function trainingRepo() {

    const toast = inject('toast');
    const companies = ref([]);
    const trainings = ref([]);
    const enrolledTrainings = ref([]);
    const users = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const suggestedCourses = ref([]);
    const urlRedirect = ref('');
    const billings = ref([]);
    const billing = ref([]);

    const getSuggestedTrainings = async (page) => {
        let response = await axios.get(`candidate/trainings/suggested?user_id=${page.authuser.id}`);
        trainings.value = response.data.data;
        suggestedCourses.value = response.data.data;
        page.isLoading = false;
    }

    const getSelectedSuggestedTrainings = async (data) => {
        let response = await axios.post(`candidate/trainings/selected`, data);
        trainings.value = response.data.data;
    }

    const getAssignedTrainings = async (page) => {
        let response = await axios.get(`candidate/trainings/assigned?user_id=${page.authuser.id}&company_id=${page.authuser.company_id}&associated_companies=${page.authuser.associated_companies}`);
        companies.value = response.data.companies;
        page.isLoading = false;
    }

    const getEnrolledTrainings = async (page) => {
        let response = await axios.get(`candidate/trainings?user_id=${page.authuser.id}`);
        trainings.value = response.data.data;
        page.isLoading = false;
    }

    const searchCandidateTrainings = async (page, data) => {
        let response = await axios.post(`candidate/trainings/search`, data);
        trainings.value = response.data.data;
        page.isLoading = false;
    }

    const updateCourse = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/trainings`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const storeOneTimePayment = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/trainings/onetime-payment`, data);
            status.value = response.status;
            urlRedirect.value = response.data.checkout.url;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateOneTimePayment = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/trainings/onetime-payment/${id}`, data);
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getBillings = async (state) => {
        let response = await axios.get(`candidate/trainings/billings?user_id=${state.user_id}`);
        billings.value = response.data.data;
        state.isLoading = false;
    }

    const getBilling = async (id) => {
        let response = await axios.get(`candidate/trainings/billings/${id}`);
        billing.value = response.data.data;
    }

    const createForwardEmailAccount = async (code) => {
        errors.value = '';
        try {
            let response = await axios.get(`candidate/trainings/forward-email/${code}`);
            status.value = response.status;
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    const checkEnrolledTrainings = async (page) => {
        let response = await axios.get(`candidate/trainings/enrolled/${page.authuser.id}`);
        enrolledTrainings.value = response.data;
        page.isLoading = false;
    }

    return {
        companies,
        trainings,
        users,
        errors,
        message,
        status,
        getSuggestedTrainings,
        getSelectedSuggestedTrainings,
        getAssignedTrainings,
        getEnrolledTrainings,
        searchCandidateTrainings,
        updateCourse,
        alertMessage,
        checkEnrolledTrainings,
        enrolledTrainings,
        suggestedCourses,
        storeOneTimePayment,
        updateOneTimePayment,
        getBillings,
        getBilling,
        urlRedirect,
        billings,
        billing,
        createForwardEmailAccount
    }

}