<template>
    <div>
        <h3>Other Benefits</h3>
        <div class="mb-3">
            <label class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" v-model="form.include_default_benefits" value="1" @change="toggleBenefits" />
                <span class="form-check-label">
                    Set the benefits to Company-Wide Employee Benefits
                </span>
            </label>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <BaseEditor
                    v-model="form.job_benefits"
                    id="job_benefits"
                    is-required
                    @editorContent="setBenefitsContent"
                />
            </div>
        </div>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to set benefits to your Company-Wide Employee Benefits? The current benefits will be deleted once saved.`"
            @cancel="toggleConfirmation"
            @isDelete="changeBenefits"
        />
    </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import userRepo from '@/repositories/settings/users';

export default {
    props: {
        form: {
            type: Object,
            default: {}
        },
        errors: {
            type: Object,
            default: {}
        }
    },
    setup(props, {emit}) {
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser'))
        });
        const { company, getUser } = userRepo();
        const isConfirm = ref(false);
        const selected = ref(false);

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
            if (isConfirm.value == false && selected.value == false) {
                props.form.include_default_benefits = false;
            }
        }

        const toggleBenefits = () => {
            if (props.form.include_default_benefits == 1) {
                if (props.form.job_benefits !== "" && props.form.job_benefits !== undefined) {
                    toggleConfirmation();
                } else {
                    props.form.job_benefits = company.value.default_benefits;
                    emit('set-benefit', company.value.default_benefits);
                }
            } else {
                props.form.job_benefits = "";
                emit('set-benefit', '');
            }
        }

        const changeBenefits = () => {
            props.form.job_benefits = company.value.default_benefits;
            selected.value = true;
            toggleConfirmation();
        }

        const setBenefitsContent = (evt) => {
            const benefit_content = evt ?? '';
            emit('set-benefit', benefit_content);
        }

        onMounted(() => {
            getUser(state);
        });

        return {
            company,
            getUser,
            isConfirm,
            toggleBenefits,
            changeBenefits,
            toggleConfirmation,
            setBenefitsContent
        }
    }
}
</script>