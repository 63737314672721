<template>
    <div>
        <Modal :modalActive="modalActive" :class="`modal-dialog modal-sm modal-dialog-centered`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <BaseInput
                            placeholder="Skill Name"
                            v-model="skill.name"
                            type="text"
                            id="name"
                            :class="{ 'is-invalid' : errors.name }"
                            :errors="errors"
                            is-required
                        />
                        <Datepicker
                            id="release_date"
                            v-model="skill.date_released"
                            format="MM/dd/yyyy"
                            :enableTimePicker="false"
                            :range="false"
                            placeholder="Release Date (MM/DD/YYYY)"
                            :modelValue="skill.date_released"
                            textInput
                            :maxDate="new Date()"
                        />
                        <BaseSelect
                            v-model="skill.status"
                            id="status"
                            :modelValue="skill.status"
                            :options="skillStatus"
                            :errors="errors"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Update</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, watchEffect } from 'vue';
import skillsRepo from '@/repositories/admin/skills';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const { updateSkills, skill, getSkill, status, errors } = skillsRepo();

        const skillStatus = [
            { id: 'active', name: 'Active' },
            { id: 'inactive', name: 'Inactive' }
        ];

        const saveChanges = async () => {
            let formData = new FormData();
            formData.append('id', props.form.id ?? '');
            formData.append('name', skill.value.name ?? '');
            formData.append('date_released', skill.value.date_released ?? '');
            formData.append('status', skill.value.status ?? '');
            formData.append('_method', 'PUT');

            await updateSkills(formData, props.form.id);
            if(status.value == 200) {
                emit('processed', 'updated');
            }
        }

        const close = () => {
            emit('close');
        }

        watchEffect( async () => {
            if(props.form.id) {
                await getSkill(props.form.id);
            }
        });

        return {
            saveChanges,
            close,
            skill,
            status,
            errors,
            skillStatus,
            getSkill
        }
    }
})
</script>