import axios from "axios";

export default {
    namespaced: true,
    state: {
        feedbacks: [],
        feedback: [],
        isLoading: false,
        feedbackReply: []
    },
    getters: {
        feedbackData(id, state){
            const data = state.feedbacks.filter(fb => fb.id == id)
            return data
        }
    },
    mutations: {
        getFeedback(state, data) {
            state.feedbacks = data
            state.isLoading = false
        },
        getFeedbackReply(state, data) {
            state.feedbackReply = data
        }
    },
    actions: {
        async getFeedback({state, commit}, id){
            state.isLoading = true
            if(id){
                state.feedback = state.feedbacks.filter(fb => fb.id == id)
            }else{
                await axios.get(`admin/feedback`).then(response => {
                    commit('getFeedback', response.data)
                })
            }
        },
        async updateFeedback({state, commit}, params){
            const {id, status} = params
            const data = {status: status}
            return await axios.put(`admin/feedback/update-status/${id}`, data).then(response => {
                return response.data
            })
        },
        async updateFeedbackLocation({state, commit}, params){
            const {id, location} = params
            const data = {location: location}
            return await axios.put(`admin/feedback/update-location/${id}`, data).then(response => {
                return response.data
            })
        },
        async getFeedbackReply({state, commit}, id){
            await axios.get(`admin/feedback/get-reply/${id}`).then(response => {
                commit('getFeedbackReply', response.data)
            })
        },
    }
}