import UserIndex from '@/views/admin/settings/users/Index';
import PaidUserIndex from '@/views/admin/settings/users/PaidUsers';
import ArchivedUserIndex from '@/views/admin/settings/users/ArchivedUsers';
import UserLogs from '@/views/admin/settings/users/UserLogs';
import UserAccounts from '@/views/admin/settings/users/Accounts';

const routes = [
    {
        path: '/admin/settings/users',
        name: 'admin.settings.users',
        component: UserIndex
    },
    {
        path: '/admin/settings/users/paid',
        name: 'admin.settings.users.paid',
        component: PaidUserIndex
    },
    {
        path: '/admin/settings/users/archived',
        name: 'admin.settings.users.archived',
        component: ArchivedUserIndex
    },
    {

        path: '/admin/settings/user-logs',
        name: 'admin.settings.user-logs',
        component: UserLogs
    },
    {

        path: '/admin/users/accounts/:id',
        name: 'admin.users.accounts',
        component: UserAccounts
    }
]

export default routes