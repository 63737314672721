<template>
    <div class="tab-pane fade show active" id="payment-method" role="tabpanel">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <h5 class="card-title mb-0">Manage Payment Methods</h5>
                    <div style="margin-right: 65px;">
                        <button class="btn btn-primary" @click="viewPaymentMethod">Add Payment Method</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <PageLoader v-if="page.isLoading" />
                <div v-else>
                    <BaseTable
                        :data="methods"
                        :options="headers"
                    >
                        <template v-slot:payment_name="{ row }">
                            <div class="d-flex">
                                <div>{{ row.payment_name }}</div> &nbsp;&nbsp;
                                <div v-if="row.id === default_method" class="text-success"><i class="fas fa-dot-circle"></i></div>
                            </div>
                        </template>
                        <template v-slot:default_payment="{ row }">
                            <div v-if="row.id === default_method">Yes</div>
                            <div v-else>No</div>
                        </template>
                        <template v-slot:account_number="{ row }">
                            <div v-if="row.type == 'Bank'" v-html="`********${row.last4}`"></div>
                            <div v-else v-html="`**** **** **** ${row.last4}`"></div>
                        </template>
                        <template v-slot:action="{ row }">
                            <div class="meatballs-menu">
                                <div class="btn-group">
                                    <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="javascript:;" @click="setDefault(row.id)">Set as Default</a></li>
                                        <li><a class="dropdown-item text-danger" href="javascript:;" @click="removePayment(row.id)">Remove Payment Method</a></li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </BaseTable>
                </div>
            </div>
        </div>
        <ModalBilling
            :modalActive="modalActive"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @setDefault="refreshPage"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="page.confirmText"
            @cancel="toggleConfirmation"
            @isDelete="processDelete"
        />
    </div>
</template>

<script>
import billingRepo from '@/repositories/employer/billing';
import { reactive, onMounted, ref, watchEffect } from '@vue/runtime-core';
import ModalBilling from '@/views/employer/settings/config/ModalBilling.vue';

export default {
    setup() {
        const { listPaymentMethods, methods, status, detachPaymentMethod, setDefaultPaymentMethod } = billingRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            default_payment_method: '',
            modalHeader: '',
            confirmText: '',
            idToUpdate: '',
            idToDelete: ''
        });
        const headers = [
            {
                key: 'payment_name',
                label: 'Payment Name'
            },
            {
                key: 'account_number',
                label: 'Account Number',
                class: 'text-center'
            },
            {
                key: 'account_name',
                label: 'Account Name',
                class: 'text-center'
            },
            {
                key: 'type',
                label: 'Payment Type',
                class: 'text-center'
            },
            {
                key: 'default_payment',
                label: 'Default Payment Method',
                class: 'text-center'
            },
            {
                key: 'action',
                label: '',
                class: 'text-center'
            }
        ];
        const modalActive = ref(false);
        const default_method = ref('');
        const isConfirm = ref(false);

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const viewPaymentMethod = () => {
            page.modalHeader = 'Add Payment Method';
            toggleModal();
        }

        const setDefault = async (id) => {
            toggleConfirmation();
            page.confirmText = 'Are you sure you want to set this method as your default payment method?';
            page.idToUpdate = id;
        }

        const removePayment = async (id) => {
            toggleConfirmation();
            page.confirmText = 'Are you sure you want to delete this payment method?';
            page.idToDelete = id;
        }

        const refreshPage = async () => {
            await listPaymentMethods(page);
        }

        const processDelete = async () => {
            if(page.idToUpdate) {
                await setDefaultPaymentMethod(page, page.idToUpdate);
                if(status.value == 200) {
                    page.default_payment_method = page.idToUpdate;
                    page.idToUpdate = '';
                }
            } else {
                await detachPaymentMethod(page.idToDelete);
                await listPaymentMethods(page);
                page.idToDelete = '';
            }
            toggleConfirmation();
        }

        onMounted( async () => {
            await listPaymentMethods(page);
        });

        watchEffect(() => {
            default_method.value = (page.default_payment_method !== '') ? page.default_payment_method : page.authuser.default_payment_method;
        });

        return {
            headers,
            page,
            listPaymentMethods,
            viewPaymentMethod,
            setDefault,
            removePayment,
            modalActive,
            isConfirm,
            toggleModal,
            toggleConfirmation,
            detachPaymentMethod,
            setDefaultPaymentMethod,
            refreshPage,
            processDelete,
            default_method,
            methods,
            status
        }
    },
    components: {
        ModalBilling
    }
}
</script>
<style scoped>
div#payment-method {
    padding-top: 10px;
}
</style>