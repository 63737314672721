<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex">
                <h1 class="h3 mb-3">Job Invitations</h1>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header pb-0" style="border-bottom-color: #e5e5e5;">
                            <div class="col-12 mb-5">
                                <div class="float-start">
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="filter-by">Show</span>
                                        </div>
                                        <select id="filter" class="form-select" aria-describedby="filter-by" v-model="page.viewOption" @change="ChangeView">
                                            <option value="/candidate/jobs/applications/">Applied Jobs</option>
                                            <option value="/candidate/jobs/applications/saved/">Saved Job Applications</option>
                                            <option value="/candidate/jobs/applications/invites/">Job Invitations</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <BaseTable
                                :data="invitationList"
                                :options="job_headers"
                                :id="''"
                            >
                                <template v-slot:position_title="{ row }">
                                    {{ row.invite_details?.jobdetails?.position_title }}
                                    <div v-html="row.invite_details?.jobdetails?.display_keywords"></div>
                                </template>
                                <template v-slot:company_name="{ row }">
                                    <div v-if="row.company_details?.employer?.is_profile_visible"><a :href="`/candidate/employer-profile/${row.invite_details?.jobdetails?.company_id}`" target="_blank">{{ row.company_details?.company_name }}</a></div>
                                    <div v-else>{{ row.company_details?.company_name }}</div>
                                </template>
                                <template v-slot:compensation="{ row }">
                                    <div v-html="row.invite_details?.jobdetails?.compensation"></div>
                                </template>
                                <template v-slot:location="{ row }">
                                    <div v-html="row.invite_details?.jobdetails?.location_role"></div>
                                </template>
                                <template v-slot:address="{ row }">
                                    <div v-show="row.invite_details?.jobdetails?.city && row.invite_details?.jobdetails?.state" v-html="row.invite_details?.jobdetails?.city+' '+row.invite_details?.jobdetails?.state"></div>
                                </template>
                                <template v-slot:action="{ row }">
                                    <div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="viewJob(row.invite_details.job_id, (row.application_details !== null && row.application_details.submitted == true))">View Details</a></li>
                                                <li>
                                                    <a class="dropdown-item text-danger" href="javascript:;" @click="declineInvitation(row.invite_details.id)">
                                                        <span v-if="row.application_details !== null && row.application_details.submitted == true">Decline</span>
                                                        <span v-else>Decline</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`Applying for a Job - ${job.position_title}`">
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin application-fixed">
                    <div class="col-12">
                        <div class="float-start">
                            <h2 style="margin-top: 20px;">{{ job.company ? job.company.company_name : '' }} is looking for:</h2>
                        </div>
                        <div class="float-end" v-if="!page.isApplied">
                            <button class="btn btn-success btn-lg" @click="applyToJob(job.id)">Accept Invitation</button>&nbsp;
                            <button class="btn btn-outline-danger btn-lg" @click="declineInvitation(page.invite_id)">Decline</button>&nbsp;
                        </div>
                        <div class="float-end" v-else>
                            <button class="btn btn-outline-secondary btn-lg" :disabled="page.isApplied">Applied</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin mt-7">
                    <div class="col-12">
                        <h3>{{ job.position_title }}</h3>
                        <div v-html="job.display_keywords"></div>
                        <div class="mb-3 mt-3">
                            <div><b>Job Type:</b> {{ job.job_type }}</div>
                            <div><b>Location:</b> {{ job.location }} {{ job.country }}</div>
                            <div v-if="job.language"><b>Language: </b> <span class="badge bg-success" style="margin-right:3px;" v-for="language in job.languages" v-bind:key="language">{{ language.name }}</span></div>
                            <div v-if="job.compensation_type == 1 && job.rate != null"><b>Compensation:</b> {{ `${job.min_amount} - ${job.max_amount}` }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                            <div v-if="job.compensation_type == 2 && job.rate != null"><b>Compensation:</b> {{ job.exact_amount }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                            <div v-if="job.location_role_id"><b>Location Role:</b> {{ location_roles.find(x => x.id === job.location_role_id)?.name }}</div>
                            <div v-if="job.schedules"><b>Schedules:</b> {{ getSchedules() }}</div>
                            <div v-if="job.job_time_frame"><b>Time Frame:</b> {{ time_frames.find(x => x.id === job.job_time_frame)?.name }}</div>
                        </div>
                        <h5>Job Description</h5>
                        <div class="mb-3" v-html="job.job_description"></div>
                        <h5>Job Benefits</h5>
                        <div v-html="job.job_benefits" v-if="job.job_benefits"></div>
                        <div v-else>Not Listed.</div>
                    </div>
                </div>
            </div>
        </slideout>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to decline this invitation?`"
            @cancel="toggleConfirmation"
            @isDelete="processDeclineInvitation"
        />
        <ModalApplication
            :modalActive="modalActive"
            :id="page.jobId"
            :form="application"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
        />
    </main>
</template>

<script>
import { useRouter } from 'vue-router'
import { defineComponent, reactive, onMounted, ref, computed } from 'vue';
import applicationRepo from '@/repositories/candidate/application';
import jobRepo from '@/repositories/candidate/jobs';
import globalRepo from '@/repositories/global';
import ModalApplication from '@/views/candidate/jobs/ModalApplication.vue';

export default defineComponent({
    setup() {
        const router = useRouter();
        const { job_types, compensation_types, location_roles, schedules, time_frames, rates, countries, getCountries, application_status } = globalRepo();
        const { applications, getApplicationInvites, application, getApplication, addApplication, declineApplicationInvite } = applicationRepo();
        const { jobs, job, getJob } = jobRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            jobId: '',
            idToDelete: '',
            viewOption: '/candidate/jobs/applications/invites/',
            searchValue: '',
            isApplied: false,
            invite_id: null
        });

        const visible = ref(false);
        const isConfirm = ref(false);
        const modalActive = ref(false);

        const job_headers = [
            { key: 'position_title', label: 'Position Title' },
            { key: 'company_name', label: 'Company Name' },
            { key: 'location', label: 'Location' },
            { key: 'compensation', label: 'Rates' },
            { key: 'address', label: 'Address' },
            { key: 'action', label: 'Action' }
        ]

        onMounted( async () => {
            await getApplicationInvites(page)
            page.isLoading = false;
        });

        const invitationList = computed(function(){
            return applications.value.filter(item => ( item.invite_details.jobdetails != null ));
        })

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const viewJob = async (id, applied = true, invite_id) => {
            await getJob(id);
            page.isApplied = applied;
            page.jobId = id;
            visible.value = true;
            page.invite_id = invite_id
        }

        const getSchedules = () => {
            if(job.value.schedules) {
                let results = job.value.schedules.split(',');
                let content = '';
                results.filter(result => {
                    content += `${schedules.find(x => x.id == result).name}, `;
                });

                return content.slice(0, -2);
            }
        }

        const declineInvitation = (id) => {
            page.idToDelete = id;
            visible.value = false;
            toggleConfirmation();
        }

        const processDeclineInvitation = async () => {
            toggleConfirmation();
            page.isLoading = true;
            await declineApplicationInvite(page.idToDelete);
            await getApplicationInvites(page);
        }

        const applyToJob = async (id) => {
            visible.value = false;
            await addApplication(page, id);
            await getApplication(page, id);
            page.modalHeader = `Apply to a Job - ${job.value.position_title}`
            setTimeout(() => {
                toggleModal();
            }, 500)
        }

        const onClosing = (e) => {
            // close after 3 seconds
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                e.resume = true
            }, 500)
        }

        const ChangeView = () => {
            router.push(`${page.viewOption}`);
        }

        return {
            page,
            job_headers,
            applications,
            getApplicationInvites,
            viewJob,
            job_types,
            compensation_types,
            location_roles,
            schedules,
            time_frames,
            rates,
            countries,
            getCountries,
            jobs,
            job,
            getJob,
            getSchedules,
            visible,
            application,
            getApplication,
            addApplication,
            isConfirm,
            modalActive,
            toggleModal,
            toggleConfirmation,
            declineInvitation,
            processDeclineInvitation,
            declineApplicationInvite,
            applyToJob,
            onClosing,
            ChangeView,
            invitationList,
            application_status
        }

    },
    components: {
        ModalApplication
    }
})
</script>

<style scoped>
.search-field {
    position:relative;
    margin-bottom: 15px;
}
.search-field i {
    right: 7px;
    top: 8px;
    font-size: 17px;
    position:absolute;
}
</style>