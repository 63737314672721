<template>
    <div>
        <h3>Additional Details</h3>
        <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
        <div class="row">
            <div class="col-6">
                <BaseCheckbox is-array label="Job Type" :isRequired="true">
                    <label class="form-check form-check-inline" v-for="job_type in job_types" :key="job_type.name">
                        <input class="form-check-input checkbox-jobtype" type="checkbox" v-model="state.job_type" :value="job_type.name" :class="{ 'is-invalid' : errors && errors.job_type }" />
                        <span class="form-check-label">
                            {{ job_type.name }}
                        </span>
                    </label>
                    <label class="error jquery-validation-error small form-text invalid-feedback show-error" v-if="errors && errors.job_type">{{ errors.job_type[0] }}</label>
                </BaseCheckbox>
                <BaseInput
                    v-model="form.num_of_candidates"
                    label="How many people do you want to hire for this job opening?"
                    type="number"
                    :class="{ 'is-invalid' : errors && errors.num_of_candidates }"
                    id="num_of_candidates"
                    :errors="errors"
                    is-required
                />
                <BaseSelect
                    v-model="state.job_time_frame"
                    label="How quickly do you need to hire?"
                    id="job_time_frame"
                    :options="time_frames"
                />
                <div class="form-group mb-3">
                    <BaseCheckbox
                        design="inline"
                        v-model="require_resume"
                        placeholder="Require candidates to include a resume"
                        id="require_resume" />
                </div>
            </div>
            <div class="col-6">
                <BaseCheckbox is-array label="What is the scheduling for this job?" :isRequired="true">
                    <label class="form-check" v-for="schedule in schedules" :key="schedule.id">
                        <input class="form-check-input checkbox-scheduling" type="checkbox" v-model="state.schedules" :value="schedule.id" :class="{ 'is-invalid' : errors && errors.schedules }" />
                        <span class="form-check-label">
                            {{ schedule.name }}
                        </span>
                    </label>
                    <label class="error jquery-validation-error small form-text invalid-feedback show-error" v-if="errors && errors.schedules">{{ errors.schedules[0] }}</label>
                </BaseCheckbox>
            </div>
        </div>
        <div class="row" v-if="state.isKeywordsActivated">
            <div class="col-12">
                <div class="form-group mb-3">
                    <label for="keywords">Keywords</label>
                    <div class="tag-input">
                        <div v-for="(tag, index) in keywords" :key="tag" class="tag-input__tag">
                            {{ tag }}
                            <span @click="removeTag(index)">x</span>
                        </div>
                        <input
                            type="text"
                            class="tag-input__text"
                            placeholder="Enter keywords"
                            @keydown.enter="addTag"
                            @keydown.delete="removeLastTag"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, watch, watchEffect } from 'vue';
import globalRepo from '@/repositories/global';

export default {
    props: {
        form: {
            type: Object,
            default: {}
        },
        errors: {
            type: Object,
            default: {}
        }
    },
    setup(props, {emit}) {
        const state = reactive({
            job_type: [],
            schedules: [],
            isKeywordsActivated: false,
            job_time_frame: ''
        });
        const { job_types, schedules, time_frames } = globalRepo();
        const require_resume = ref(false);

        watch(() => state.job_type, () => {
            emit('emit-jobtype', state.job_type);
        });

        watch(() => state.schedules, () => {
            emit('emit-schedules', state.schedules);
        });

        watch(() => state.job_time_frame, () => {
            emit('emit-time_frame', state.job_time_frame);
        });

        watchEffect(() => {
            if(props.form.id) {
                state.job_type = props.form.job_type_object;
                state.schedules = props.form.schedules_object;
                state.job_time_frame = props.form.job_time_frame;
                require_resume.value = (props.form.submit_resume == 'Yes');
            }  
        });

        return {
            state,
            job_types,
            schedules,
            time_frames,
            require_resume
        }
    }
}
</script>