<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12 mb-4 custom-dialog">
                        <div style="padding: 5px 10px;">
                            <p style="margin: 0; margin-bottom: 6px;font-size: 16px;">Please download this sample CSV file and follow the column order and format when uploading your file.</p>
                            <div style="margin-top: 0;">
                                <button class="btn btn-success" @click="downloadCsv">Download CSV File</button>
                            </div>
                        </div>
                    </div>
                    <p style="font-size: 12px; margin-bottom: 3px;">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-6">
                        <label for="document" class="form-label">Please upload your CSV file here: <span class="text-danger">*</span></label>
                        <input type="file" class="form-control" id="document" @change="onFileChange" enctype="multipart/form-data" :class="{ 'is-invalid' : errors && errors.document }" ref="fileInput" />
                        <label class="error jquery-validation-error small form-text invalid-feedback show" v-if="errors && errors.document">{{ errors.document[0] }}</label>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" :btn-value="`Send User Invites`" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, reactive, onUpdated, inject, ref } from 'vue';
import globalRepo from '@/repositories/global';

export default defineComponent({
    props: ['modalActive','form','modalTitle','errors'],
    setup(props, { emit }) {

        const { downloadCsvFile } = globalRepo();
        const csv_url = reactive({ url: '' });
        const fileInput = ref(null);
        const status = ref('');

        const close = () => {
            emit('close');
        }
        
        const saveChanges = () => {
            status.value = 200;
            emit('saveChanges');
        }

        onUpdated(() => {
            const fileInputModel = inject('fileInputModel');
            if(fileInputModel.value == '') {
                fileInput.value = '';
            }
        })

        const onFileChange = (e) => {
            let file = e.target.files[0];
            csv_url.url = URL.createObjectURL(file);
            props.form.document = file
        }

        const downloadCsv = () => {
            downloadCsvFile();
        }

        return {
            csv_url,
            close,
            saveChanges,
            onFileChange,
            downloadCsv,
            downloadCsvFile,
            fileInput,
            status
        }
    }
})
</script>
<style scoped>
.custom-dialog {
    background: #e7f9e7;
    padding-bottom: 15px;
    padding-top: 10px;
    border-radius: 5px;
    color: #094006;
    margin: 0px 10px;
    width: 98%;
}
</style>