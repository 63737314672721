<template>
    <div class="tab-pane fade show active" id="subscriptions" role="tabpanel">
        <div class="card">
            <div class="card-header pb-0">
                <h5 class="card-title mb-0">Research Help Subscription</h5>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <h4>Current Subscription: {{ state.authuser.research_help_subscription }}</h4>
                    <div class="d-flex align-items-center" v-if="state.authuser.subscription_plan_id">
                        <button class="btn btn-outline-danger" @click="cancelSubscription">Cancel Subscription</button>
                    </div>
                </div>
                <div>
                    <table class="table table-striped" style="width: 100%;">
                        <thead>
                            <tr>
                                <th>Position Title</th>
                                <th>Company</th>
                                <th>Date Applied</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in research" :key="item.id">
                                <td>{{ item.job?.position_title }}</td>
                                <td>{{ item.job?.company?.company_name }}</td>
                                <td>{{ item.date_applied_display }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header pb-0">
                <h5 class="card-title mb-0">Account Billings</h5>
            </div>
            <div class="card-body">
                <PageLoader v-if="state.isLoading" />
                <div class="card-body" v-else>
                    <table class="table table-striped" style="width: 100%;">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Provider</th>
                                <th>Amount</th>
                                <th>Billing Type</th>
                                <th>Status</th>
                                <th>Due Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="billings.length">
                            <tr v-for="billing in billings" :key="billing.id">
                                <td>{{ billing.created_at_display }}</td>
                                <td>{{ billing.provider?.name }}</td>
                                <td>{{ billing.amount_display }}</td>
                                <td>{{ billing.bill_type }}</td>
                                <td>{{ billing.status }}</td>
                                <td>{{ billing.due_date_display }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <td colspan="6" class="text-center">
                                <div>No data available</div>
                            </td>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, inject } from 'vue';
import trainingRepo from '@/repositories/candidate/trainings';
import billingRepo from '@/repositories/employer/billing';
import jobRepo from '@/repositories/candidate/jobs';

export default {
    setup() {
        const swal = inject('$swal');
        const state = reactive({
            isLoading: true,
            authuser: JSON.parse(localStorage.getItem('authuser')),
            user_id: JSON.parse(localStorage.getItem('authuser')).id
        });

        const { getBillings, billings } = trainingRepo();
        const { unSubscribe, status } = billingRepo();
        const { getPersonalResearchHelp, research } = jobRepo();

        const cancelSubscription = async () => {
            swal({
                title: 'Are you sure?',
                text: "You want to cancel your subscription?",
                icon: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, please'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    await unSubscribe(state);
                    if(status.value == 200) {
                        
                    }
                }
            });
        }

        onMounted( async () => {
            await getBillings(state);
            await getPersonalResearchHelp(state);
        })

        return {
            state,
            getBillings,
            billings,
            cancelSubscription,
            unSubscribe,
            getPersonalResearchHelp,
            research
        }
    },
}
</script>