import trainingRoute from './trainings/index';

const routes = [
    {
        path: "/employer/register",
        name: "register",
        component: () => import("@/views/employer/auth/Register.vue")
    },
    {
        path: "/employer/login",
        name: "login",
        component: () => import("@/views/employer/auth/Login.vue")
    },
    {
        path: "/employer/dashboard",
        name: "employer.dashboard",
        component: () => import("@/views/employer/Dashboard.vue")
    },
    {
        path: "/employer/profile",
        name: "employer.profile",
        component: () => import("@/views/employer/Profile.vue")
    },
    ...trainingRoute
];

export default routes