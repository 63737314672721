<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <div>
                        <h1 class="h3 mb-3">Notification &amp; Reminder Settings</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-xl-2">
                    <div class="card">
                        <div class="list-group list-group-flush" role="tablist">
                            <a class="list-group-item list-group-item-action" :class="{ 'active' : currentComponent == 'NotificationSettings' }" data-bs-toggle="list" href="javascript:;" @click="chooseTab('NotificationSettings')" role="tab">
                                Notification Settings
                            </a>
                            <a class="list-group-item list-group-item-action" :class="{ 'active' : currentComponent == 'ReminderSettings' }" data-bs-toggle="list" href="javascript:;" @click="chooseTab('ReminderSettings')" role="tab">
                                Reminder Settings
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-9 col-xl-10">
                    <div class="tab-content">
                        <component :is="currentComponent"></component>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue';
import NotificationSettings from '@/views/candidate/settings/privacy/tabs/Notification.vue';
import ReminderSettings from '@/views/candidate/settings/privacy/tabs/Reminder.vue';

export default defineComponent({
    setup() {
        const currentComponent = ref('NotificationSettings');
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true
        });

        onMounted(() => {
            currentComponent.value = 'NotificationSettings';
            page.isLoading = false;
        })

        const chooseTab = (tab) => {
            currentComponent.value = tab;
        }

        return {
            page,
            currentComponent,
            chooseTab
        }
    },
    components: {
        NotificationSettings,
        ReminderSettings
    }
})
</script>