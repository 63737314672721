import { createStore } from 'vuex'
import auth from "./auth";
import adminauth from "./adminauth";
import feedback from "./feedback";
import email from "./email";

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        
    },
    modules: {
        auth,
        adminauth,
        feedback,
        email
    }
})