<template>
    <div>
        <div class="form-group" :class="{ 'mb-3' : marginBottomOn }">
            <label :for="id" class="form-label" v-if="label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
            <editor
                v-model="editorData"
                :api-key="tinykey"
                :init="init"
                @blur="editorSetContent"
                :id="id"
            />
            <label class="error jquery-validation-error small form-text invalid-feedback is-invalid editor-show" v-if="errors && errors[id]">{{ errors[id][0] }}</label>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import config from '@/config.js';
import { ref, watch } from 'vue';

export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Array],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {},
            required: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        marginBottomOn: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const hasValue = ref([]);
        const tinykey = config.tinykey;
        const init = config.editor_init;
        const editorData = ref('');

        const editorSetContent = (evt) => {
            let id = evt.target.id;
            if(props.modelValue) {
                hasValue.value[id] = true;
            } else {
                hasValue.value[id] = false;
            }
            emit('editorContent', props.modelValue);
        }

        watch(() => props.modelValue, () => {
            editorData.value = props.modelValue;
        });

        return {
            tinykey,
            init,
            hasValue,
            editorSetContent
        }
    },
    components: {
        Editor
    }
}
</script>