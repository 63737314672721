<template>
    <InterviewTrainingComponent 
        :interviewTrainingLists="page.interviewTrainings"
        :userRole="page.userRole"
        :reloadData="page.reloadData"
        @createInterviewTraining="create"
        @editInterviewTraining="edit"
        @deleteInterviewTraining="remove"
    />
    
    <ModalInterviewTraining
        :modalActive="modalActive"
        :form="interviewTraining"
        :modalTitle="page.modalTitle"
        :has-image="interviewTraining.image !== undefined && interviewTraining.image !== ''"
        :errors="errors"
        @saveChanges="save"
        @close="toggleModal"
    />

    <Confirm 
        :is-confirm="isConfirmDelete"
        :confirm-text="`Are you sure you want to delete this Interview Training?`"
        @cancel="toggleConfirmationDelete"
        @isDelete="removeInterviewTraining"
    />
</template>

<script>
import InterviewTrainingComponent from '@/components/interviewTraining/index';
import ModalInterviewTraining from '@/views/admin/interviewTrainings/Modal/Index.vue';
import interviewTrainingRepo from '@/repositories/admin/interviewTrainings';

import { defineComponent, reactive, onMounted, ref, watchEffect } from 'vue';

export default defineComponent ({
    setup() {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            api_url: process.env.VUE_APP_API_URL,
            userRole: 'admin',
            selectedInterviewTraining: null,
            modalTitle: 'Add New Interview Training',
            isLoading: false,
            reloadData: false,
            interviewTrainings: []
        });

        const { 
            status, 
            errors, 
            interviewTraining,
            interviewTrainings,
            updateInterviewTraining,
            insertInterviewTraining,
            destroyInterviewTraining,
            searchCandidateInterviewTrainings
        } = interviewTrainingRepo();

        const isConfirm = ref(false);
        const isConfirmDelete = ref(false);
        const modalActive = ref(false);

        onMounted(async () => {
            await searchCandidateInterviewTrainings(page);
            page.interviewTrainings = interviewTrainings;
        });

        const create = async () => {
            interviewTraining.value = {}
            await toggleModal();
        }

        const edit = async (data) => {
            page.modalTitle = data.modalTitle;
            interviewTraining.value = data.selectedInterviewTraining;
            await toggleModal();
        }

        const toggleModal = async () => {
            errors.value = [];
            modalActive.value = !modalActive.value;
        }

        const close = async () => {
            await toggleModal();
        }
        
        const reloadData = async () => {
            page.reloadData = true;
        }

        const save = async () => {
            let formData = new FormData();
            formData.append('name', interviewTraining.value.name ?? '');
            formData.append('type', interviewTraining.value.type ?? '');
            formData.append('link', interviewTraining.value.link ?? '');
            formData.append('image', interviewTraining.value.image ?? null);
            formData.append('description', interviewTraining.value.description ?? '');
            formData.append('user_id', page.authuser.id ?? '');
            let id = interviewTraining.value.id;
            page.isLoading = true;

            if(id) {
                formData.append('id', id);
                formData.append('_method', 'PUT');
                await updateInterviewTraining(page, formData, id);
                interviewTraining.value = [];
            } else {
                await insertInterviewTraining(page, formData);
            }

            if(status.value == 200) {
                toggleModal();
                await searchCandidateInterviewTrainings(page);
                page.interviewTrainings = interviewTrainings.value;
            }
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const toggleConfirmationDelete = async () => {
            isConfirmDelete.value = !isConfirmDelete.value;
        }
        
        const removeInterviewTraining = async () => {
            page.isLoading = true;
            await destroyInterviewTraining(page, interviewTraining.value.id);
            if(status.value === 200) {
                await reloadData();
                await toggleConfirmationDelete();
                await searchCandidateInterviewTrainings(page);
                page.interviewTrainings = interviewTrainings.value;
            }
        }

        const remove = async (data) => {
            isConfirmDelete.value = true;
            interviewTraining.value = data.selectedInterviewTraining;
        }

        watchEffect(async () =>{
            page.interviewTrainings = interviewTrainings.value;
        });

        return {
            page,
            create,
            close, 
            modalActive,
            toggleModal,
            save,
            interviewTraining, 
            reloadData,
            edit,
            remove,
            isConfirm,
            toggleConfirmation,
            isConfirmDelete,
            toggleConfirmationDelete,
            removeInterviewTraining,
            interviewTrainings,
            status,
            errors
        }
    },
    components: {
        InterviewTrainingComponent, 
        ModalInterviewTraining
    }
});
</script>
