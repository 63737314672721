<template>
    <div>
        <div class="form-group" :class="{ 'mb-3' : marginBottomOn }" v-if="isArray">
            <label class="form-label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
            <div>
                <slot />
            </div>
        </div>
        <div class="form-group" :class="{ 'mb-3' : marginBottomOn }" v-if="design === 'inline' && !isArray">
            <div class="form-check form-check-inline inline-flex">
                <input 
                    v-bind="$attrs"
                    :id="id" 
                    class="form-check-input" 
                    type="checkbox" 
                    @change="updateInput"
                    :checked="modelValue"
                > &nbsp;&nbsp;
                <label :for="id" class="form-check-label" v-text="placeholder"></label>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Boolean],
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        design: {
            type: String,
            default: 'normal'
        },
        isArray: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        marginBottomOn: {
            type: Boolean,
            default: true
        },
        errors: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        const hasValue = ref([]);
        const updateInput = (event) => {
            let id = event.target.id;
            if(event.target.value) {
                hasValue.value[id] = true;
                props.errors[id] = null;
            } else {
                hasValue.value[id] = false;
            }
            emit('update:modelValue', event.target.checked);
        }

        return {
            updateInput,
            hasValue
        }
    }
})
</script>