<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex">
                <h1 class="h3 mb-3">Job Applications</h1>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header pb-0" style="border-bottom-color: #e5e5e5;">
                            <div class="col-12 mb-5">
                                <div class="float-start">
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="filter-by">Show</span>
                                        </div>
                                        <select id="filter" class="form-select" aria-describedby="filter-by" v-model="page.viewOption" @change="ChangeView">
                                            <option value="/candidate/jobs/applications">Applied Jobs</option>
                                            <option value="/candidate/jobs/applications/saved">Saved Job Applications</option>
                                            <option value="/candidate/jobs/applications/invites">Job Invitations</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <BaseTable
                                :data="jobs"
                                :options="job_headers"
                            >
                                <template v-slot:position_title="{ row }">
                                    <div style="width:200px;">{{ row.position_title }}</div>
                                    <div v-html="row.display_keywords"></div>
                                </template>
                                <template v-slot:company_name="{ row }">
                                    <div style="width:200px;" v-if="row.company?.employer?.is_profile_visible">
                                        <a :href="`/candidate/employer-profile/${row.company?.id}`" target="_blank">{{ row.company?.company_name }}</a>
                                    </div>
                                    <div style="width:200px;" v-else>{{ row.company?.company_name }}</div>
                                </template>
                                <template v-slot:action="{ row, i }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="applyJob(row.job_id, i)">View Details</a></li>
                                                <li><a class="dropdown-item text-danger" href="javascript:;" @click="removeSavedJob(row.job_id)">Remove</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`Applying for a Job - ${job.position_title}`">
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin application-fixed">
                    <div class="col-12">
                        <div class="float-end">
                            <button :class="`${ !page.hasApplied ? 'btn btn-primary btn-lg' : 'btn btn-outline-secondary btn-lg disabled' }`" @click="applyToJob(job.id)">{{ page.applyToThisJobLabel }}</button>&nbsp;
                            <button class="btn btn-outline-danger btn-lg" @click="removeSavedJob(job.id)">Unsave</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin mt-7">
                    <div class="col-12">
                        <h3>{{ job.position_title }} &nbsp; ({{ job.company ? job.company.company_name : '' }})</h3>
                        <div v-html="job.display_keywords"></div>
                        <div class="mb-3 mt-3">
                            <div><b>Job Type:</b> {{ job.job_type }}</div>
                            <div><b>Location:</b> {{ job.location }} {{ job.country }}</div>
                            <div v-if="job.language"><b>Language: </b> <span class="badge bg-success" style="margin-right:3px;" v-for="language in job.languages" v-bind:key="language">{{ language.name }}</span></div>
                            <div v-if="job.compensation_type == 1 && job.rate != null"><b>Compensation:</b> {{ `${job.min_amount} - ${job.max_amount}` }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                            <div v-if="job.compensation_type == 2 && job.rate != null"><b>Compensation:</b> {{ job.exact_amount }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                            <div v-if="job.location_role_id"><b>Location Role:</b> {{ location_roles.find(x => x.id === job.location_role_id)?.name }}</div>
                            <div v-if="job.schedules"><b>Schedules:</b> {{ getSchedules() }}</div>
                            <div v-if="job.job_time_frame"><b>Time Frame:</b> {{ time_frames.find(x => x.id === job.job_time_frame)?.name }}</div>
                        </div>
                        <h5>Job Description</h5>
                        <div class="mb-3" v-html="job.job_description"></div>
                        <h5>Job Benefits</h5>
                        <div v-html="job.job_benefits" v-if="job.job_benefits"></div>
                        <div v-else>Not Listed.</div>
                    </div>
                </div>
            </div>
        </slideout>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this saved job application?`"
            @cancel="toggleConfirmation"
            @isDelete="removeJob"
        />
        <ModalApplication
            :modalActive="modalActive"
            :id="page.jobId"
            :form="application"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
        />
    </main>
</template>

<script>
import { useRouter } from 'vue-router'
import { defineComponent, reactive, onMounted, ref, watchEffect } from 'vue';
import Confirm from '@/components/modules/Confirmation.vue';
import globalRepo from '@/repositories/global';
import jobRepo from '@/repositories/candidate/jobs';
import applicationRepo from '@/repositories/candidate/application';
import ModalApplication from '@/views/candidate/jobs/ModalApplication.vue';

export default defineComponent({
    setup() {
        const router = useRouter();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            idToDelete: '',
            jobId: '',
            jobCount: 1,
            nextIsDisabled: false,
            prevIsDisabled: true,
            tableIndex: 0,
            viewOption: '/candidate/jobs/applications/saved',
            searchValue: '',
            applyToThisJobLabel: 'Apply',
            hasApplied: false
        });

        const { job_types, compensation_types, location_roles, schedules, time_frames, rates, countries, getCountries } = globalRepo();
        const { jobs, job, getJob, jobsaves, unsavedJob } = jobRepo();
        const { application, getApplication, addApplication } = applicationRepo();

        const job_headers = [
            { key: 'position_title', label: 'Position Title' },
            { key: 'company_name', label: 'Company Name' },
            { key: 'location_role', label: 'Location' },
            { key: 'compensation', label: 'Rates' },
            { key: 'location', label: 'Address' },
            { key: 'action', label: 'Action', class: 'text-center' }
        ]

        const isConfirm = ref(false);
        const modalActive = ref(false);
        const visible = ref(false);

        onMounted(() => {
            jobsaves(page);
        });

        watchEffect(() => {
            if(page.tableIndex === 0) {
                page.prevIsDisabled = true;
            } else {
                page.prevIsDisabled = false;
            }

            if(page.tableIndex === (jobs.value.length-1)) {
                page.nextIsDisabled = true;
            } else {
                page.nextIsDisabled = false;
            }
        });

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const removeSavedJob = (id) => {
            page.idToDelete = id;
            visible.value = false;
            toggleConfirmation();
        }

        const removeJob = async (id) => {
            toggleConfirmation();
            await unsavedJob(page, page.idToDelete);
            jobsaves(page);
        }

        const applyJob = async (id, index) => {
            await getJob(id);
            setApplicant();
            page.jobId = id;
            page.tableIndex = index;
            visible.value = true;
        }

        const setApplicant = () => {
            let applicant_info = (job.value.candidate_applications && job.value.candidate_applications.length) ? job.value.candidate_applications.find(o => o.candidate_id === page.authuser.id) : {};
            page.hasApplied = applicant_info ? applicant_info.submitted : false;
            page.applyToThisJobLabel = (page.hasApplied == true) ? "Applied" : "Apply";
        }

        const onClosing = (e) => {
            // close after 3 seconds
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                e.resume = true
                page.hasApplied = false;
            }, 500)
        }

        const getSchedules = () => {
            if(job.value.schedules) {
                let results = job.value.schedules.split(',');
                let content = '';
                results.filter(result => {
                    content += `${schedules.find(x => x.id == result).name}, `;
                });

                return content.slice(0, -2);
            }
        }

        const previous = async (jobs) => {
            page.tableIndex = page.tableIndex-1;
            if(page.tableIndex !== -1) {
                let id = jobs[page.tableIndex].id;
                await getJob(id);
                page.jobId = id;
                visible.value = true;
            }
            page.jobCount--;

        }

        const next = async (jobs) => {
            page.tableIndex = page.tableIndex+1;
            if(page.tableIndex !== jobs.length) {
                let id = jobs[page.tableIndex].id;

                await getJob(id);
                page.jobId = id;
                visible.value = true;
            }
            page.jobCount++;
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const applyToJob = async (id) => {
            visible.value = false;
            await addApplication(page, id);
            await getApplication(page, id);
            page.modalHeader = `Apply to a Job - ${job.value.position_title}`
            setTimeout(() => {
                toggleModal();
            }, 500)
        }

        const ChangeView = () => {
            router.push(`${page.viewOption}`);
        }

        return {
            page,
            jobsaves,
            jobs,
            removeSavedJob,
            removeJob,
            isConfirm,
            toggleConfirmation,
            unsavedJob,
            applyJob,
            onClosing,
            getJob,
            job,
            visible,
            compensation_types,
            location_roles,
            getSchedules,
            schedules,
            time_frames,
            rates,
            job_types,
            countries,
            getCountries,
            previous,
            next,
            applyToJob,
            toggleModal,
            application,
            modalActive,
            job_headers,
            ChangeView
        }

    },
    components: {
        Confirm,
        ModalApplication
    }
})
</script>

<style>
.w-50 {
    width: 50%;
}
.search-field {
    position:relative;
    margin-bottom: 15px;
}
.search-field i {
    right: 7px;
    top: 8px;
    font-size: 17px;
    position:absolute;
}
</style>
