<template>
    <div>
        <div class="row">
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <h3>Your Courses</h3>
                        <input v-model="page.course_search" class="form-control form-control me-2 employee-search mt-4" type="search" placeholder="Search Course...">
                        <BaseTable
                            :data="ownCourse"
                            :options="headersEmployer"
                            class="mt-4"
                            :id="''"
                        >
                            <template v-slot:status="{ row }">
                                <span v-if="row.status == 'In Progress'" class="badge bg-warning text-white me-2">{{row.status}}</span>
                                <span v-else class="badge bg-success text-white me-2">{{row.status}}</span>
                            </template>
                        </BaseTable>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h3>Employees Courses & Learning Plans</h3>
                                <input v-model="page.employee_search" class="form-control form-control me-2 employee-search mt-3" type="search" placeholder="Search Employee..." >
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="meatballs-menu">
                                    <button type="button" class="btn btn-primary btn-md" data-bs-toggle="dropdown" aria-expanded="false" :disabled="page.isDisabled">Action</button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="javascript:;" @click="assignUsers()">Assign Courses</a></li>
                                        <li><a class="dropdown-item" href="javascript:;" @click="modalLessonActive = true">Assign Lesson Plans</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <BaseTable
                            :data="employeeCourseEnrolled"
                            :options="headers"
                            :tableClass="'table-employee-course mt-4'"
                        >
                            <template v-slot:checkbox="{ row }">
                                <div class="d-flex align-items-center" style="height: 50px;">
                                    <input type="checkbox" :id="`checkbox-${row.user_id}`" v-model="formTraining.selected" :value="row.user_id">
                                </div>
                            </template>
                            <template v-slot:fullname="{ row }">
                                <div class="d-flex align-items-center" style="height: 50px;">
                                    <label :for="`checkbox-${row.user_id}`">{{ row.fullname }}</label>
                                </div>
                            </template>
                            <template v-slot:course="{ row }">
                                <div class="card mb-0">
                                    <div v-if="row.courses.length > 0" class="accordion accordion-flush" v-bind:id="'flush-headingOne-course-'+row.user_id">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" v-bind:id="'flush-headingOne-course-'+row.user_id">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" v-bind:data-bs-target="'#flush-collapseOne-course-'+row.user_id" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    <span style="width:110px;"><strong>Enrolled Courses</strong></span> <span class="badge bg-secondary mx-2">{{row.courses.length}}</span>
                                                </button>
                                            </h2>
                                            <div v-bind:id="'flush-collapseOne-course-'+row.user_id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div v-for="(courseData, index) in row.courses" :key="courseData.id" class="accordion accordion-flush mt-2" v-bind:id="'accordionFlushExample-'+index+'-'+row.user_id">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" v-bind:id="'flush-headingOne-'+index+'-'+row.user_id">
                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" v-bind:data-bs-target="'#flush-collapseOne-'+index+'-'+row.user_id" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                <span class="course-title">{{courseData.course}}</span>
                                                                <span v-if="courseData.course_started == false" class="course-status">Assigned</span>
                                                                <div v-else-if="courseData.course_completed == false || courseData.course_completed == null" class="">
                                                                    <span class="badge bg-warning text-white me-2">In progress</span>
                                                                </div>
                                                                <span v-else>
                                                                    <span class="badge bg-success text-white me-2">Completed</span>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                        <div v-bind:id="'flush-collapseOne-'+index+'-'+row.user_id" class="accordion-collapse collapse" v-bind:aria-labelledby="'flush-headingOne-'+index+'-'+row.user_id" v-bind:data-bs-parent="'#accordionFlushExample-'+index+'-'+row.user_id">
                                                            <div class="accordion-body">
                                                                <div class="row" style="width: 220px;">
                                                                    <div class="col-4"><strong>Status</strong></div>
                                                                    <div v-if="courseData.course_started == false" class="col-8">Assigned</div>
                                                                    <div v-else-if="courseData.course_completed == false || courseData.course_completed == null" class="col-8">In Progress</div>
                                                                    <div v-else class="col-8">Completed</div>
                                                                </div>
                                                                <div class="row" style="width: 220px;">
                                                                    <div class="col-4"><strong>Started</strong></div>
                                                                    <div class="col-8">{{courseData.date_started_display}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="accordion-body" v-html="courseData.course_details"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="row.learning.length > 0" class="accordion accordion-flush mt-2" v-bind:id="'flush-headingOne-learning-'+row.user_id">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" v-bind:id="'flush-headingOne-learning-'+row.user_id">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" v-bind:data-bs-target="'#flush-collapseOne-learning-'+row.user_id" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    <span style="width:110px;"><strong>Learning Plans</strong></span><span class="badge bg-secondary mx-2">{{row.learning.length}}</span>
                                                </button>
                                            </h2>
                                            <div v-bind:id="'flush-collapseOne-learning-'+row.user_id" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">
                                                    <ul v-if="row.learning.length > 0" class="list-group">
                                                        <li class="list-group-item" v-for="(learning, i) in row.learning" :key="i">
                                                            {{learning.name}} {{  learning.id }}
                                                            <Popper style="float:right;" arrow hover :content="`${courseCompleted(learning).length} of ${learning.plan_count} course(s) completed`">
                                                                <button class="btn btn-primary btn-sm lp-progress" style="width: 110px;">{{percProgress(learning)}}% Progress</button>
                                                            </Popper>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </template>
                            <template v-slot:action="{ row }">
                                <div class="d-flex justify-content-center">
                                    <div class="meatballs-menu">
                                        <router-link class="btn btn-light btn-md rounded-circle btn-ellipsis" style="width:36px; height:36px;" :to="{ name: 'employer.messages_with_id', params: { user_id: row.user_id } }"><i class="fa fa-message"></i></router-link>
                                    </div>
                                </div>
                            </template>
                        </BaseTable>
                    </div>
                </div>
            </div>
        </div>
        <ModalTrainings
            :modalActive="modalActive"
            :form="formTraining"
            :errors="errors"
            :modalTitle="formLessons.title"
            @close="toggleModal"
            @saveChanges="assign"
        />
        <ModalLessons
            :modalActive="modalLessonActive"
            :form="formLessons"
            :errors="errors"
            :modalTitle="formLessons.title"
            @close="toggleModalLesson"
            :users="selectedUserInfo"
            @saveChanges="assignBulkEmployer"
        />
    </div>
</template>

<script>
import { onMounted, reactive, computed, ref, watch } from 'vue';
import trainingRepo from '@/repositories/employer/trainings';
import userRepo from '@/repositories/settings/users';
import ModalTrainings from '@/views/employer/settings/users/ModalTrainings.vue';
import ModalLessons from '@/views/employer/settings/users/ModalLessons.vue';

export default {
    components: {
        ModalTrainings,
        ModalLessons
    },
    props: {
        filterDatas: {
            type: [Array, Object],
            default: []
        }
    },
    setup(props) {
        const { training_users, getTrainingUsers, getTrainingEmployees, training_employees, learning_plans_employees } = trainingRepo();
        const { users, getUsers, assignTrainingCourseToUser, errors, status } = userRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            filter: props.filterDatas.filter,
            learning_plan_id: props.filterDatas.learning_plan_id,
            component: props.filterDatas.component,
            query_search: props.filterDatas.query_search,
            employee_search: '',
            course_search: '',
            isDisabled: true
        });
        const employees_with_courses = ref([]);
        const modalActive = ref(false);
        const modalLessonActive = ref(false);

        const headers = [
            {
                key: 'checkbox',
                label: '',
                class: 'text-center w-3 align-top'
            },
            {
                key: 'fullname',
                label: 'Full Name',
                class: 'fullname-header'
            },
            {
                key: 'course',
                label: 'Assigned',
                class: 'w-50'
            },
            {
                key: 'action',
                label: '',
                class: 'text-center'
            }
        ];
        const headersEmployer = [
            {
                key: 'course',
                label: 'Training Course'
            },
            {
                key: 'status',
                label: 'Status'
            },
            {
                key: 'date_started_display',
                label: 'Date Started'
            }
        ];

        onMounted( async () => {
            await getTrainingUsers(page);
            await getUsers(page);
            await getTrainingEmployees(page);
            if(users.value) {
                users.value.forEach((item) => {
                    if(item.id !== page.authuser.id) {
                        employees_with_courses.value.push({
                            user_id: item.id,
                            fullname: item.name,
                            course: '',
                            courses: item.ongoing_training_courses ?? ''
                        });
                    }
                });
            }

            console.log(learning_plans_employees.value);
        });

        const ownCourse = computed(function(){
            const res = training_users?.value?.filter(user => user.user_id == page.authuser.id)
            if(page.course_search){
                return res.filter(c => c.course.toLowerCase().includes(page.course_search.toLowerCase()) || c.course_details.toLowerCase().includes(page.course_search.toLowerCase()))
            }
            return res
        })

        const employeeCourseEnrolled = computed(function(){
            const employeeList = []
            training_employees.value.map( data => {
                if(employeeList.filter(list => list.user_id == data.user_id).length > 0){
                    const res = employeeList.filter(list => list.user_id == data.user_id)[0]
                    res.courses.push(data)
                } else {
                    const courses = []
                    courses.push(data)
                    employeeList.push({
                        user_id: data.user_id,
                        fullname: data.fullname,
                        course: '',
                        learning:[],
                        courses
                    })
                }
            })

            learning_plans_employees.value.map( data => {
                if(employeeList.filter(v => v.user_id == data.user_id).length > 0){
                    const res = employeeList.filter(v => v.user_id == data.user_id)[0]
                    res.learning.push(data)
                } else {
                    const learning = []
                    learning.push(data)
                    employeeList.push({
                        user_id: data.user_id,
                        fullname: data.fullname,
                        course: '',
                        courses:[],
                        learning
                    })
                }
            })

            if(page.employee_search){
                return employeeList.filter(c => c.fullname.toLowerCase().includes(page.employee_search.toLowerCase()))
            }else{
                return employeeList
            }

        });

        const courseCompleted = (learning) => {
            return learning.learning_progress.filter(data => data.status == 'completed')
        }

        const percProgress = (learning) => {
            const completed = courseCompleted(learning)
            return learning.plan_count ? ((completed.length / learning.plan_count) * 100).toFixed(0) : 0
        }

        const formTraining = reactive({
            training_ids: [],
            selected: [],
            allSelected: false,
            selectAllLabel: 'Select All Users',
        });

        const formLessons = reactive({
            title: 'Assign Learning Plans',
            fullname: '',
            lessons: []
        });

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
            if(modalActive.value == true) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        }

        const selectUser = (user_id, event) => {
            if(event.target.checked) {
                formTraining.selected.push(user_id);
            } else {

            }
        }

        const assignUsers = () => {
            formTraining.training_ids = [];
            formLessons.title = 'Assign Training Courses to User';
            toggleModal();
        }

        const assign = async () => {
            let formData = new FormData();
            formData.append('user_ids', formTraining.selected);
            formData.append('training_ids', formTraining.training_ids);
            formData.append('company_id', page.authuser.company_id);
            await assignTrainingCourseToUser(formData)

            if (status.value == 200) {
                page.isLoading = true;
                formTraining.training_ids = [];
                formTraining.selected = [];
                toggleModal();
                await getTrainingEmployees(page);
            }
        }

        const toggleModalLesson = () => {
            modalLessonActive.value = !modalLessonActive.value;
        }

        const selectedUserInfo = computed(() => {
            const user = []
            formTraining.selected.map((data) => {
                const info = users.value.filter(u => u.id == data)
                user.push({
                    id: info[0].id,
                    fname: info[0].fname+' '+info[0].lname
                });
            });
            return user;
        });

        const assignBulkEmployer = () => {
            toggleModalLesson();
            formTraining.selected = []
        }

        watch(() => formTraining.selected, () => {
            page.isDisabled = (formTraining.selected.length == 0);
        });

        return {
            page,
            headers,
            users,
            training_users,
            employees_with_courses,
            getTrainingUsers,
            headersEmployer,
            ownCourse,
            training_employees,
            employeeCourseEnrolled,
            percProgress,
            courseCompleted,
            assignUsers,
            formTraining,
            assignTrainingCourseToUser,
            modalActive,
            formLessons,
            assign,
            toggleModal,
            selectUser,
            modalLessonActive,
            errors,
            status,
            toggleModalLesson,
            selectedUserInfo,
            assignBulkEmployer
        }
    },
}
</script>
<style>
.fullname-header {
    width: 20%;
    vertical-align: text-top !important;
}
.course-title { width: 90%}
.course-status {
    font-size: 10px;
    padding: 3px;
    background: #7c7c7c;
    color: white;
    border-radius: 3px;
    right: 0;
    margin-right: 10px;
    width: 70px;
    text-align: center;
}
.employee-search { width: 300px!important; }
.lp-progress { border-radius: 10px; }
.w-3 {
    width: 3%;
}
</style>