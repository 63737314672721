<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between">
                <h1 class="h3 mb-3">{{ user.name }} Registered Accounts</h1>
                <div class="d-flex align-items-center">
                    <router-link :to="{ name: 'admin.settings.users' }" class="btn btn-primary btn-sm">Back to Users</router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <PageLoader v-if="state.isLoading" />
                        <div class="card-body" v-else>
                            <table class="table table-striped" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th class="text-center">Date</th>
                                        <th>Provider Name</th>
                                        <th>Amount</th>
                                        <th>Billing Type</th>
                                        <th>Status</th>
                                        <th>Due Date</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="billings.length">
                                    <tr v-for="billing in billings" :key="billing.id">
                                        <td class="text-center">{{ billing.created_at_display }}</td>
                                        <td>{{ billing?.provider.name }}</td>
                                        <td>{{ billing.amount_display }}</td>
                                        <td>{{ billing.bill_type }}</td>
                                        <td>{{ billing.status }}</td>
                                        <td>{{ billing.due_date_display }}</td>
                                        <td class="text-center">
                                            <button class="btn btn-success btn-sm" @click="editBilling(billing.id)">Edit Billing</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="7" class="text-center">Currently no registered accounts</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BillingModal
            :modalActive="modalActive"
            :modalTitle="state.modalHeader"
            :billing_id="state.billing_id"
            @close="modalActive = false"
            @refresh-page="refresh"
        />
    </main>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import userRepo from '@/repositories/settings/users';
import trainingRepo from '@/repositories/candidate/trainings';
import BillingModal from '@/views/admin/settings/users/modals/BillingModal';

export default {
    components: {
        BillingModal
    },
    setup() {
        const route = useRoute();
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            user_id: route.params.id,
            modalHeader: 'Update Billing',
            billing_id: 0
        });
        const { user, getUser } = userRepo();
        const { billings, getBillings } = trainingRepo();
        const modalActive = ref(false);

        const editBilling = (id) => {
            modalActive.value = true;
            state.billing_id = id;
        }

        const refresh = async () => {
            modalActive.value = false;
            await getBillings(state);
        }

        onMounted( async () => {
            await getUser(state, route.params.id);
            await getBillings(state);
        });

        return {
            state,
            user,
            getUser,
            billings,
            getBillings,
            modalActive,
            editBilling,
            refresh
        }
    },
}
</script>