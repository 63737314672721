<template>
  <main class="content">
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">Find Candidates</h1>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-xl-2">
          <div class="card shadow mt-0 position-relative" :class="(page.isLoading === true) ? 'disable-filters' : ''">
              <div class="card-body">
                  <h5><strong>FILTERS</strong></h5>
                  <button class="btn btn-light btn-sm w-100 mt-0" @click="clearFilter"> Clear Filter </button>

                  <div class="filter-section">
                    <h5 class="lead mt-3"><strong>Country</strong></h5>
                    <div v-for="(country, i) in countries" :key="i" class="checkbox-wrapper" :title="country.nicename">
                      <label><input type="checkbox" :value="country.nicename" :id="'country-'+i" v-model="filter.countries"> <span>{{ country.nicename }}</span> </label>
                    </div>
                  </div>

                  <div class="filter-section full-height" id="employment-status-filter">
                    <h5 class="lead mt-3"><strong>Employment Status</strong></h5>
                    <div v-for="(employment_status, i) in employment_statuses" :key="i" class="checkbox-wrapper" :title="employment_status.name">
                      <label><input type="checkbox" :value="employment_status.name" :id="'employment-status-'+i" v-model="filter.employment_status"> <span>{{ employment_status.name }}</span> </label>
                    </div>
                  </div>

                  <div class="filter-section full-height" id="hide-candidates-filter">
                    <h5 class="lead mt-3"><strong>Hide Candidates</strong></h5>
                    <div v-for="(candidate_filter, i) in candidate_filters" :key="i" class="checkbox-wrapper" :title="candidate_filter.name">
                      <label><input type="checkbox" :value="candidate_filter.name" :id="'candidate-filter-'+i" v-model="filter.hide_candidates"> <span>{{ candidate_filter.name }}</span> </label>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-md-9 col-lg-10 col-xl-10">
          <div class="tab-content">
            <div class="tab-pane fade show active" id="certifications" role="tabpanel">
              <div class="card">
                <div class="card-header pb-0" style="border-bottom-color: #e5e5e5;">
                  <div class="col-12">
                    <div class="float-start">
                      <!-- <div class="input-group">
                        <div class="input-group-append">
                          <span class="input-group-text" id="filter-by">View</span>
                        </div>
                        <select id="filter" class="form-select" aria-describedby="filter-by" v-model="page.viewOption" @change="ChangeView">
                          <option value="/employer/applicants/find/">All Candidates</option>
                          <option value="/employer/applicants/match/">Matched Candidates</option>
                          <option value="/employer/applicants/saved/">Saved Candidates</option>
                        </select>
                      </div> -->
                      <h4 class="mt-2">Matched Candidates</h4>
                    </div>
                    <div style="margin-left: 10px" class="float-end w-20 search-field">
                      <i class="fa fa-search"></i>
                      <input class="form-control" type="text" placeholder="Search" v-model="page.searchValue"
                        @input="processSearch">
                    </div>
                  </div>
                </div>
                <div class="card-body with-table">
                  <div v-if="page.isLoading" style="padding: 10% 0;">
                    <Loading />
                  </div>
                  <BootstrapBaseTable v-else :data="page.candidates" :options="headers"
                    :sortableColumns="page.sortableColumns" :isSortAscending="page.isSortAscending"
                    @navigateSort="triggerSort">
                    <template v-slot:save_column="{ row }">
                      <div class="save-candidate-button-wrapper"
                        style="width:35px;display:inline-block;position:relative;" v-if="!row.is_saved">
                        <button class="save-button table-btn btn text-primary rounded-circle" @click="SaveCandidate(row.id)">
                          <i class="far fa-bookmark"></i>
                          <div class="tooltip bg-dark text-light tooltip-style">Save Candidate</div>
                        </button>
                      </div>
                      <div class="save-candidate-button-wrapper"
                        style="width:35px;display: inline-block;position:relative;" v-else>
                        <button class="save-button table-btn btn text-primary rounded-circle" @click="UnsaveCandidate(row.id)">
                          <i class="fas fa-bookmark"></i>
                          <div class="tooltip bg-dark text-light tooltip-style tooltip-style-unsave">Unsave Candidate</div>
                        </button>
                      </div>
                    </template>
                    <template v-slot:display_photo="{ row }">
                      <div class="d-flex align-items-center save-candidate-button-wrapper">
                        <img :src="`${row.display_photo}`" width="35" height="35" class="rounded-circle" />
                        <div class="profile-lock" v-if="!row.is_profile_visible">
                          <i class="fa-solid fa-lock text-danger"></i>
                        </div>
                        <div class="tooltip bg-dark text-light tooltip-style-private" style="margin-top: 45px;" v-if="!row.is_profile_visible">This Profile is Private</div>
                      </div>
                    </template>
                    <template v-slot:fname="{ row }">
                      <div v-if="!row.is_profile_visible" class="d-flex">
                        <div class="save-candidate-button-wrapper">
                          {{ row.fname }}
                          <div class="tooltip bg-dark text-light tooltip-style-private">This Profile is Private</div>
                        </div>
                      </div>
                      <div v-else>
                        <a href="javascript:;" @click="ViewCandidate(row.id)">{{ row.fname }}</a>
                      </div>
                    </template>
                    <template v-slot:lname="{ row }">
                      <div class="d-flex">
                        <div class="save-candidate-button-wrapper">
                          {{ row.lname }}
                          <div class="tooltip bg-dark text-light tooltip-style-private" v-if="!row.is_profile_visible">This Profile is Private</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:skills="{ row }">
                      <div style="position:relative;width:205px;">
                        <div :class="{ 'skills-wrap': true, 'overflown': row.skills }" v-if="row.skills">
                          <span class="unhovered-skill" v-if="HasSearchedSkill(row.skills.skills_arr, page.searchValue)">
                            <span v-for="(item, index) in row.skills.skills_arr" v-bind:key="index">
                              <span
                                class="badge badge-primary bg-primary"
                                :class="{ 'highlight': (page.searchValue != '' && item.title.toLowerCase().includes(page.searchValue.toLowerCase())), 'hide': (page.searchValue != '' && !item.title.toLowerCase().includes(page.searchValue.toLowerCase())) }"
                              >
                                {{ item.title }}
                              </span>
                            </span>
                          </span>
                          <span class="unhovered-skill" v-else>
                            <span class="badge badge-primary bg-primary" v-for="(item, index) in row.skills.skills_arr" v-bind:key="index">{{ item.title }}</span>
                          </span>
                          <span class="hovered-skill">
                            <span class="badge badge-primary bg-primary" v-for="(item, index) in row.skills.skills_arr" v-bind:key="index">{{ item.title }}</span>
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-slot:action="{ row }">
                      <div class="align-right has-submenu">
                        <button class="table-btn btn btn-light rounded-circle btn-ellipsis" @click="openSubmenu('sub-menu-' + row.id)"
                          @blur="closeAllSubmenu('sub-menu-' + row.id)"><i class="fa fa-ellipsis-h"></i></button>
                        <div :id="`sub-menu-${row.id}`" class="submenu-buttons">
                          <button style="text-align:left;"
                            :disabled="row.invitations.length && row.invitations.find(o => o.company_id === page.authuser.company_id)"
                            class="btn" @click="ShowInviteOptions(row.id)"><i class="fas fa-user-plus"></i>
                            Invite</button><button style="text-align:left;" class="btn" @click="SendMessage(row.id)"><i
                              class="fas fa-envelope"></i> Message</button>
                        </div>
                      </div>
                    </template>
                  </BootstrapBaseTable>

                  <ModalInvite :modalActive="modalActive" :form="page" :errors="errors" @close="toggleModal"
                    @saveChanges="ProcessSendInvite" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`Quick View - Candidate Profile`">
      <div style="padding: 10% 0;" class="row no-margin application-fixed mt-3" v-if="page.isQuickViewLoading">
        <Loading />
      </div>
      <div class="row no-margin application-fixed mt-3" v-else>
        <div class="col-4">
          <div class="card">
            <div class="card-header display-photo">
              <img :src="page.profileData.display_photo" class="rounded-circle">
            </div>
            <div class="card-body display-primary-details">
              <h2>{{ page.profileData.name }}</h2>
              <p>{{ page.profileData.email }}</p>
              <p v-if="page.profileData.contact_number"><i class="fas fa-phone"></i> {{ page.profileData.contact_number
              }}</p>
              <p v-if="page.profileData.address"><i class="fas fa-map-marker-alt"></i> {{ page.profileData.address }}
              </p>
              <p>
                <button class="btn btn-success" @click="ViewFullProfile(page.profileData.id)" :disabled="!page.profileData.is_profile_visible">View Full Profile</button>
              </p>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="card">
            <div class="card-body" v-if="page.profileData.is_profile_visible">

              <div class="works cardlet" v-if="page.profileData.works.length">
                <h3><i class="fas fa-briefcase"></i> &nbsp;Work Experience</h3>
                <div>
                  <div class="works-item" v-for="(item, index) in page.profileData.works" :key="index">
                    <div class="row gaps">
                      <div class="col-4 label">Job Position</div>
                      <div class="col-8">
                        <div>{{ item.job_title }}</div>
                      </div>
                    </div>
                    <div class="row gaps">
                      <div class="col-4 label">Company Name</div>
                      <div class="col-8">
                        <div>{{ item.company }}</div>
                      </div>
                    </div>
                    <div class="row gaps">
                      <div class="col-4 label">Company Address</div>
                      <div class="col-8">
                        <div>{{ item.company_address }}</div>
                      </div>
                    </div>
                    <div class="row gaps">
                      <div class="col-4 label">Work Period</div>
                      <div class="col-8">
                        <div>{{ item.time_period }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="skills cardlet" v-if="page.profileData.skills">
                <h3><i class="fas fa-user-cog"></i> &nbsp;Skills Showcase</h3>
                <div>
                  <div class="skill-item" v-for="(item, index) in page.profileData.skills.skills_arr" :key="index">
                    <div>{{ item.title }}</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-body" v-else>
                <p class="text-center mt-4"><i class="fas fa-lock mx-2"></i>This profile is private.</p>
            </div>
          </div>
        </div>
      </div>
    </slideout>
  </main>
</template>

<script>
import { useRouter } from 'vue-router'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';
import BootstrapBaseTable from '@/components/modules/Datatable.vue';
import CandidateRepo from '@/repositories/employer/candidates.js';
import Loading from '@/components/modules/PageLoader.vue';
import ModalInvite from '@/views/employer/find/ModalInvite.vue';
import JobRepo from '@/repositories/jobs';
import _debounce from 'lodash/debounce';
import globalRepo from '@/repositories/global';

export default defineComponent({
  setup() {
    const router = useRouter();
    const visible = ref(false);
    const { jobs, getActiveJobs, errors } = JobRepo();
    const { employment_statuses, countries, candidate_filters, getCountries } = globalRepo();
    const { candidates, getMatchedCandidates, processSaveCandidate, processUnSaveCandidate, sendInvite, candidate, getCandidate } = CandidateRepo();
    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      isLoading: true,
      viewOption: '/employer/applicants/match/',
      searchValue: '',
      candidates: [],
      candidate_id: 0,
      job_id: 0,
      jobOptions: [],
      profileData: {},
      isQuickViewLoading: true,
      sortype: 'fname',
      isSortAscending: true,
      sortableColumns: ['fname', 'lname'],
      currentFilterBy: '',
      currentFilterValue: 0
    });
    const filter = ref({
      countries: [],
      employment_status: [],
      hide_candidates: []
    });

    const clearFilter = () => {
      filter.value.countries = [];
      filter.value.employment_status = [];
      filter.value.hide_candidates = [];
    };

    const headers = [
      {
        key: 'save_column',
        label: ''
      },
      {
        key: 'display_photo',
        label: ''
      },
      {
        key: 'fname',
        label: 'First Name',
        class: 'find-firstname-column'
      },
      {
        key: 'lname',
        label: 'Last Name'
      },
      {
        key: 'email',
        label: 'Email'
      },
      {
        key: 'country',
        label: 'Country',
        class: 'find-country-column',
      },
      {
        key: 'skills',
        label: 'Skills'
      },
      {
        key: 'action',
        label: 'Action'
      }
    ];

    watch(filter.value,
        async () => {
            let params = {
                country: filter.value.countries,
                employment_status: filter.value.employment_status,
                hide_candidates: filter.value.hide_candidates
            }
            page.isLoading = true;
            await getMatchedCandidates(page, params);
            page.candidates = candidates.value;
        },
        { deep: true }
    );

    onMounted(async () => {
      page.isLoading = true;
      await getMatchedCandidates(page);
      getActiveJobs(page);
      customSort(candidates.value, page.sortype, page.isSortAscending);
      page.candidates = candidates.value;
      page.jobOptions = jobs;
      getCountries();
    })

    const processSearch = async () => {
      searchCandidates();
    }

    const searchCandidates = _debounce(async function (event) {
      clearFilter();
      await getMatchedCandidates(page);
      page.candidates = candidates.value;
    }, 500);

    const SendMessage = (id) => {
      router.push(`/employer/messages/${id}`);
    }

    const ViewCandidate = async (id) => {
      visible.value = true;
      page.isQuickViewLoading = true;
      page.candidate_id = id;
      await getCandidate(page);
      page.profileData = candidate.value;
      setTimeout(() => {
        page.isQuickViewLoading = false
      }, 500);
    }

    const ViewFullProfile = (id) => {
      router.push(`/employer/applicant/${id}`);
    }

    const SaveCandidate = async (id) => {
      page.searchValue = '';
      page.isLoading = true;
      await processSaveCandidate(page, id);
      await getMatchedCandidates(page);
    }

    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    }

    const triggerSort = (index) => {
      page.sortype = index;
      toggleSortAscending();
      customSort(candidates.value, page.sortype, page.isSortAscending);
    }

    const toggleSortAscending = () => {
      page.isSortAscending = !page.isSortAscending;
    }

    const customSort = (data, sortColumn = 'fname', sortAscending = true) => {
      return (sortAscending) ? data.sort((a, b) => {
        let fa = a[sortColumn].toLowerCase(),
          fb = b[sortColumn].toLowerCase();
        if (fa < fb) { return -1; }
        if (fa > fb) { return 1; }
        return 0;
      }) : data.sort((a, b) => {
        let fa = a[sortColumn].toLowerCase(),
          fb = b[sortColumn].toLowerCase();
        if (fa > fb) { return -1; }
        if (fa < fb) { return 1; }
        return 0;
      });
    }

    const ShowInviteOptions = (id) => {
      page.candidate_id = id
      modalActive.value = true;
      errors.value = [];
    }

    const ProcessSendInvite = async () => {
      if (page.job_id == 0) {
        errors.value = {
          job: 'Please select a job.'
        }
      } else {
        errors.value = [];

        let userData = new FormData();
        userData.append('user_id', page.authuser.id ?? '');
        userData.append('candidate_id', page.candidate_id ?? '');
        userData.append('job_id', page.job_id ?? '');

        await sendInvite(userData);

        let params = {
            country: filter.value.countries,
            employment_status: filter.value.employment_status,
            hide_candidates: filter.value.hide_candidates
        }

        await getMatchedCandidates(page, params);
        page.job_id = 0
        toggleModal();
      }
    }

    const ChangeView = () => {
      router.push(`${page.viewOption}`);
    }

    const openSubmenu = (element) => {
      document.getElementById(element).classList.add('open-submenu');
    }

    const closeAllSubmenu = (element) => {
      // delay for short period of time to handle click button
      setTimeout(() => {
        document.getElementById(element).classList.remove('open-submenu');
      }, 200);
    }

    const HasSearchedSkill = (skills_arr, searchValue) => {
      let hasSearchedString = false;
      for (var i = 0; i < skills_arr.length; i++) {
        if (skills_arr[i].title.toLowerCase().includes(searchValue.toLowerCase())) {
          hasSearchedString = true;
        }
      }
      return hasSearchedString;
    }

    const UnsaveCandidate = async (id) => {
      page.isLoading = true;
      await processUnSaveCandidate(page, id);
      await getMatchedCandidates(page);
    }

    const updateFilter = async () => {
      page.isLoading = true;
      await getMatchedCandidates(page);
      // customSort(candidates.value, page.sortype, page.isSortAscending);
      page.candidates = candidates;
    }

    return {
      page,
      headers,
      candidates,
      getMatchedCandidates,
      processSaveCandidate,
      processUnSaveCandidate,
      SaveCandidate,
      processSearch,
      SendMessage,
      ViewCandidate,
      modalActive,
      toggleModal,
      ShowInviteOptions,
      ProcessSendInvite,
      jobs,
      getActiveJobs,
      errors,
      triggerSort,
      ChangeView,
      ViewFullProfile,
      candidate,
      getCandidate,
      visible,
      openSubmenu,
      closeAllSubmenu,
      HasSearchedSkill,
      UnsaveCandidate,
      employment_statuses,
      countries,
      updateFilter,
      candidate_filters,
      filter,
      clearFilter
    }
  },
  components: {
    BootstrapBaseTable,
    Loading,
    ModalInvite
  }
})
</script>

<style scoped>
.skill-tag {
  padding: 4px 5px;
  border-radius: 3px;
  background: #3f80ea;
  color: #FFF;
  margin: 0 2px 4px;
  font-size: 12px;
  display: inline-block;
}

.align-right {
  text-align: right;
}

.skills-wrap {
  max-width: 250px;
  width: 100%;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  border-color: #a3a3a3;
  color: #8e8e8e;
}

.search-field {
  position: relative;
  margin-bottom: 15px;
}

.search-field i {
  right: 7px;
  top: 8px;
  font-size: 17px;
  position: absolute;
}

.with-table {
  padding-top: 0;
}

.display-primary-details {
  text-align: center;
}

.card-header.display-photo {
  padding-bottom: 0;
  text-align: center;
  padding-top: 25px;
}

.works .gaps,
.certifications .gaps {
  margin: 7px 0px;
}

.works .label,
.certifications .label {
  font-size: 13px;
  font-weight: bold;
}

.works-item,
.certificate-item {
  padding: 10px 10px;
  background: #ededed;
  border-radius: 7px;
  margin-bottom: 10px;
}

.skill-item {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 8px;
  background: #3f80ea;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 7px;
}

.cardlet {
  margin: 15px 0 20px;
}

.cardlet h3 {
  margin-bottom: 15px;
}

.badge-primary {
  margin-right: 3px;
}

.has-submenu {
  position: relative;
}

.submenu-buttons {
  display: none;
  position: absolute;
  width: 120px;
  right: 0px;
  background: #FFF;
  z-index: 1;
  padding: 5px 5px;
  border: #bcbcbc solid 1px;
  margin-top: 4px;
  border-radius: 4px;
}

.submenu-buttons button {
  width: 100%;
  margin: 3px 0;
}

.open-submenu {
  display: block !important;
}

.skills-wrap {
  width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
}

.skills-wrap.overflown:hover {
  white-space: normal;
  position: absolute;
  background: #FFF;
  padding: 10px 10px;
  top: -35px;
  border: 1px solid #888;
  border-radius: 6px;
  box-shadow: 0px -1px 7px 2px #3e3e3e57;
  z-index: 9999;
  cursor: default;
}

.skills-wrap.overflown:hover {
  white-space: normal;
  position: absolute;
  background: #FFF;
  padding: 10px 10px;
  top: -35px;
  border: 1px solid #888;
  border-radius: 6px;
  box-shadow: 0px -1px 7px 2px #3e3e3e57;
  z-index: 9999;
  cursor: default;
}

.skills-wrap.overflown:hover .unhovered-skill {
  display: none;
}

.skills-wrap.overflown:hover .hovered-skill {
  display: block;
}

.hovered-skill {
  display: none;
}

.card-header.display-photo img {
  width: 65px;
  height: 65px;
}

.table-btn {
  padding: 0 0;
  width: 35px;
  height: 35px;
  outline: none !important;
  box-shadow: none;
}

.table-btn:active,
.table-btn:focus {
  box-shadow: 0px 0px 2px #969696 !important;
}

.save-button:hover {
  background: #e4e4e4;
}

.tooltip-style {
  padding: 2px 6px;
  top: 40px;
  transition: all 0.3s;
  width: 95px;
}

.save-candidate-button-wrapper:hover .tooltip {
  opacity: 1;
}

.tooltip-style-unsave {
  width: 110px;
}
.tooltip-style-private {
  width: 125px;
  padding: 2px 6px;
  transition: all 0.3s;
}

.btn-ellipsis {
  border: solid 2px #666;
  color: #3f80ea;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-ellipsis:hover {
  border: solid 2px #ccc;
  color: #2c5caa;
}

#datatables-responsive thead tr th:nth-child(1) {
  width: 6% !important;
}

#datatables-responsive thead tr th:nth-child(2) {
  width: 5% !important;
}

#datatables-responsive thead tr th:nth-child(3) {
  width: 17% !important;
}

#datatables-responsive thead tr th:nth-child(4) {
  width: 14% !important;
}

#datatables-responsive thead tr th:nth-child(5) {
  width: 17% !important;
}

#datatables-responsive thead tr th:nth-child(6) {
  width: 13% !important;
}

#datatables-responsive thead tr th:nth-child(7) {
  width: 22% !important;
}

#datatables-responsive thead tr th:nth-child(8) {
  width: 6% !important;
}
.table-wrapper #datatables-responsive thead tr th.find-firstname-column {
  width: 10% !important;
}
.table-wrapper #datatables-responsive thead tr th.find-country-column {
  width: 9% !important;
}
.filter-section {
  max-height: 175px;
  overflow: hidden;
  position:relative;
}
.filter-section.full-height {
  max-height: unset;
}
.filter-section-read-more {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  left: 0;
  padding-bottom: 5px;
}
.checkbox-wrapper span {
  display: inline-block;
  max-width: 150px;
  vertical-align: top;
  width: 100%;
  font-size: 0.825rem;
}
.disable-filters:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff87;
  z-index: 9;
}
.profile-lock {
  position: relative;
  top: -15px;
  left: -10px;
}
</style>