<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1>Plans <button class="btn btn-primary float-end btn-addplan" @click="addPlan">Add Plan</button></h1>
            <PageLoader v-if="page.isLoading" />
            <div v-else class="col-12">
                <div class="row" v-if="plansList.length">
                    <div v-for="(list,index) in plansList" class="card me-2 plan-box shadow-sm" style="width: 18rem;" :key="index">
                        <div class="card-body">
                            <div class="row mt-2">
                                <div class="col-8 edit-card" @click="editPlan(list.id)"><h5 class="card-title">{{list.name}}</h5></div>
                                <div class="col-4 text-end">
                                    <a href="#" class="card-link" @click="deleteTraining(list.id)"><i class="fa-solid fa-xmark red"></i></a>
                                </div>
                            </div>
                            <span class="badge rounded-pill bg-primary me-2 edit-card" @click="editPlan(list.id)">{{list.monthly_price}} / month</span>
                            <span class="badge rounded-pill bg-success edit-card" @click="editPlan(list.id)">{{list.yearly_price}} / year</span>

                            <div class="row mt-3 edit-card" @click="editPlan(list.id)">
                                <div class="col-12">
                                    <h5>Details</h5>
                                </div>
                            </div>
                            <ul class="list-group list-group-flush text-muted edit-card" @click="editPlan(list.id)">
                                <li class="list-group-item py-1 ps-0" v-for="(detail, index) in filterFeature(list.id, plansFeature)" :key="index">
                                    <div v-if="detail.plan_id == list.id">
                                        <span class="text-muted">{{detail.description}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div
                    class="card me-2 plan-box shadow-sm"
                    style="width: 18rem;">
                        <div class="card-body" @click="addPlan">
                            <div class="row text-center py-5">
                                <i class="fa-solid fa-plus"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalPlan
            :modalActive="modalActive"
            :form="plans"
            :features="features"
            :errors="errors"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @saveChanges="save"
            @addFeatureField="addFeatureField"
            @removeFeautureField="removeFeautureField"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this plan?`"
            @cancel="toggleConfirmation"
            @isDelete="removePlan"
        />
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue';
import ModalPlan from '@/views/admin/plans/ModalPlan.vue';
import planRepo from '@/repositories/admin/plans';

export default defineComponent({
    setup() {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            modalHeader: 'Add Plan',
            isLoading: true
        });
        const { getPlans, insertPlan, updatePlan, getPlanFeature, destroyPlan, destroyPlanFeature, plansFeature, plansList, status, errors } = planRepo();
        const modalActive = ref(false);
        const plans = ref([]);
        const features = ref([]);
        const isConfirm = ref(false);
        
        const toggleModal = (evt) => {
            modalActive.value = !modalActive.value;
        }
        const addFeatureField = (features) => {
            if(features.length < 5){
                features.push({description:''});
            }
        }
        const removeFeautureField = async (...args) => {
            const [index, features, removelist] = args
            plans.value.remove_feature = []
            plans.value.remove_feature = removelist
            features.splice(index,1)
        }
        const filterFeature = (plan_id, plansFeature) => {
            if(plansFeature.length > 0){
                return plansFeature.filter( object => object.plan_id == plan_id);
            }
        }

        const editPlan = (id) => {
            page.modalHeader = 'Update Plan';
            plans.value = plansList.value.filter(pl => pl.id == id)[0];

            if(plansFeature.value.length > 0){
                features.value = filterFeature(id, plansFeature.value);
            }else{
                features.value = [{description:''}];
            }
            toggleModal();
        }

        // Open Add Plan Modal
        const addPlan = () => {
            toggleModal();
            errors.value = [];
            plans.value = [];
            features.value = [{description:''}];
        }

        const save = async () => {
            const planDetails = JSON.stringify(features.value);

            let formData = new FormData();
            formData.append('name', plans.value.name ?? '');
            formData.append('monthly_price', plans.value.monthly_price ?? '');
            formData.append('yearly_price', plans.value.yearly_price ?? '');
            formData.append('yearly_payment_discount', plans.value.yearly_payment_discount ?? '');
            formData.append('status', 'active');
            formData.append('details', planDetails);

            let id = plans.value.id;
            if(id) {
                formData.append('id', id ?? '');
                formData.append('_method', 'PUT');
                const feature_id_remove = JSON.stringify(plans.value.remove_feature ?? [])
                formData.append('feature_id_remove', feature_id_remove);
                await updatePlan(formData, id);
            } else {
                await insertPlan(page, formData);
                features.value = [{description:''}];
            }

            if(status.value == 200) {
                page.isLoading = true;
                toggleModal();
                plans.value = [];
                await getPlans(page);
                await getPlanFeature(page);
                page.isLoading = false;
            }
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const deleteTraining = (id) => {
            plans.value.id = id;
            toggleConfirmation();
        }

        const removePlan = async () => {
            page.isLoading = true;
            await destroyPlan(page,plans.value.id);
            plans.value = [];
            getPlans(page);
            getPlanFeature(page);
            toggleConfirmation();
        }

        onMounted(() => {
            getPlans(page);
            getPlanFeature(page);
        });

        return {
            page,
            plans,
            status,
            errors,
            features,
            isConfirm,
            plansList,
            modalActive,
            plansFeature,
            save,
            addPlan,
            editPlan,
            removePlan,
            insertPlan,
            updatePlan,
            destroyPlan,
            toggleModal,
            filterFeature,
            deleteTraining,
            addFeatureField,
            toggleConfirmation,
            destroyPlanFeature,
            removeFeautureField
        }
    },
    components: {
        ModalPlan
    }
})
</script>
<style>
.edit-card { cursor: pointer; }
.red { color: red; }
.fa-plus { font-size: 40px; }
</style>