<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <h1 class="h3 mb-3">Private Training Courses</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-2 mt-3">
                    <div class="form-group mb-3">
                        <label for="learning_plan_id" class="form-label">Select Learning Plan</label>
                        <select id="learning_plan_id" v-model="learning_plan_id" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                            <option value="">--</option>
                            <option v-for="template in templates" :key="template" :value="template.id">{{ template.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-2 mt-3">
                    <div class="form-group mb-3">
                        <label for="filter" class="form-label">Filter by</label>
                        <select id="filter" v-model="filter" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                            <option value="">--</option>
                            <option v-for="filter in private_course_filters" :key="filter" :value="filter.id">{{ filter.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3 col-8">
                    <div class="mtc-4">
                        <button class="btn btn-outline-primary" @click="createCourse">Create Your Own Course</button>
                    </div>
                </div>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div v-else>
                <div class="row mt-5" v-if="private_plans.length">
                    <div class="col-lg-2 col-sm-4 col-xs-12" v-for="plan in private_plans" :key="plan">
                        <div class="card card-link">
                            <div v-if="plan.is_plan_private == true" class="d-flex justify-content-between card-absolute">
                                <div class="d-flex align-items-center">
                                    <span class="badge bg-primary text-white">Private</span>
                                </div>
                            </div>
                            <img :src="plan.thumbnail" class="card-img-top object-cover course-img">
                            <div class="plan-menu" @click="editCourse(plan.id)">
                                <i class="fas fa-pencil-alt fa-fw"></i>
                            </div>
                            <div class="card-body plan-card">
                                <h5 class="card-title" v-text="plan.title_limit"></h5>
                                <p class="card-text carousel-card-text">
                                    {{ plan.company?.company_name ?? plan.company_name }}<br>
                                    <b>Private Courses</b>
                                    <div class="mt-3" v-html="plan.display_learning_plans"></div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" v-else>
                    <div class="text-center">
                        <img src="/assets/images/empty.png" class="img-fluid img-30">
                    </div>
                </div>
            </div>
        </div>
        <ModalPrivate
            :modalActive="modalActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            :errors="errors"
            :modalActionIsCreate="page.modalActionIsCreate"
            :has-image="learning_plan.base_thumbnail !== undefined && learning_plan.base_thumbnail !== ''"
            @close="toggleModal"
            @saveChanges="save"
            @onDeleteBtn="deleteTraining"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this training course?.`"
            @cancel="toggleConfirmation"
            @isDelete="destroy"
        />
    </main>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import employerTrainingRepo from '@/repositories/employer/trainings';
import globalRepo from '@/repositories/global';
import ModalPrivate from '@/views/employer/trainings/modals/ModalPrivateLearningPlan.vue';

export default {
    setup() {
        const { templates, getPrivateLearningPlanTemplates } = trainingRepo();
        const {
            private_plans,
            getPrivateLearningPlans,
            getPrivateLearningPlan,
            insertPrivateLearningPlan,
            updatePrivateLearningPlan,
            getSelectedPrivateLearningPlans,
            destroyTraining,
            learning_plan,
            errors,
            status
        } = employerTrainingRepo();
        const { private_course_filters } = globalRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Add Private Course',
            modalActionIsCreate: false
        });
        const learning_plan_id = ref('');
        const filter = ref('');
        const isDisabled = ref(false);
        const modalActive = ref(false);
        const isConfirm = ref(false);

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const filteredTrainings = async () => {
            let formData = new FormData();
            formData.append('learning_plan_id', learning_plan_id.value);
            formData.append('filter', filter.value);
            formData.append('company_id', page.authuser.company_id);
            formData.append('user_id', page.authuser.id);
            await getSelectedPrivateLearningPlans(formData);
        }

        const createCourse = () => {
            page.modalActionIsCreate = true;
            learning_plan.value = [];
            learning_plan.value.image  = [];
            toggleModal();
        }

        const save = async () => {
            let formData = new FormData();
            formData.append('title', learning_plan.value.title ?? '');
            formData.append('skill_type', learning_plan.value.skill_type ?? '');
            formData.append('learning_plan_ids', learning_plan.value.learning_plan_ids ?? '');
            formData.append('tags', learning_plan.value.tags ?? '');
            formData.append('image', learning_plan.value.image ?? '');
            formData.append('video', learning_plan.value.video ?? '');
            formData.append('content', learning_plan.value.content ?? '');
            formData.append('company_id', page.authuser.company_id);
            formData.append('user_id', page.authuser.id);

            if(learning_plan.value.id) {
                formData.append('_method', 'PUT');
                await updatePrivateLearningPlan(formData, learning_plan.value.id);
            } else {
                await insertPrivateLearningPlan(formData);
            }
            if(status.value == 200) {
                toggleModal();
                getPrivateLearningPlans(page);
            }
        }

        const editCourse = (id) => {
            page.modalActionIsCreate = false;
            page.modalHeader = 'Edit Private Course';
            toggleModal();
            getPrivateLearningPlan(id);
        }

        const deleteTraining = () => {
            toggleConfirmation();
        }

        const destroy = async () => {
            page.isLoading = true;
            await destroyTraining(page, learning_plan.value.id);
            if(status.value == 200) {
                toggleConfirmation();
                toggleModal();
                await getPrivateLearningPlans(page);
            }
        }

        onMounted(() => {
            getPrivateLearningPlans(page);
            getPrivateLearningPlanTemplates();
        });

        return {
            page,
            private_plans,
            templates,
            getPrivateLearningPlanTemplates,
            learning_plan_id,
            filter,
            private_course_filters,
            filteredTrainings,
            getPrivateLearningPlans,
            getPrivateLearningPlan,
            isDisabled,
            createCourse,
            modalActive,
            toggleModal,
            learning_plan,
            save,
            errors,
            status,
            insertPrivateLearningPlan,
            updatePrivateLearningPlan,
            getSelectedPrivateLearningPlans,
            editCourse,
            deleteTraining,
            toggleConfirmation,
            isConfirm,
            destroy,
            destroyTraining
        }
    },
    components: {
        ModalPrivate
    }
}
</script>

<style scoped>
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.training-card {
    background-color: #fff;
    color: #333;
    border-top: 1px solid #eee;
    text-align: left;
}
.card-link {
    cursor: pointer;
}
.fa-training-icon {
    font-size: 20px;
    color: #fff;
}
.object-cover {
    object-fit: cover;
}
.course-img {
    height: 135px !important;
}
.card {
    box-shadow: none;
}
.card-title {
    font-size: 18px;
}
.btn-margin {
    margin: 0 5px;
}
.mtc-4 {
    margin-top: 46px;
    float: right;
}
.plan-menu {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #fff;
    border-radius: 100px;
    height: 25px;
    width: 25px;
    border: 1px solid #ccc;
    text-align: center;
}
</style>