<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Manage Users</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <!-- <div class="float-start">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="filter-by">Show</span>
                                    </div>
                                    <select id="filter" class="form-select" aria-describedby="filter-by" v-model="page.viewOption" @change="ChangeView">
                                        <option value="/admin/settings/users/">All Users</option>
                                        <option value="/admin/settings/users/paid/">Paid Users</option>
                                        <option value="/admin/settings/users/archived/">Archived Users</option>
                                    </select>
                                </div>
                            </div>
                            <div class="float-start" style="margin-left:10px;">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="filter-show">Filter Role</span>
                                    </div>
                                    <select id="filterby" class="form-select" aria-describedby="filter-show" v-model="page.filterBy" @change="changeFilter">
                                        <option value="1">All Roles</option>
                                        <option value="2">Employer</option>
                                        <option value="3">Candidate</option>
                                    </select>
                                </div>
                            </div>
                            <div class="float-start" style="margin-left:10px;">
                                <div class="form-group">
                                    <input
                                        v-model="page.querySearch"
                                        class="form-control"
                                        id="query_search"
                                        placeholder="Search Users"
                                        @input="searchUser"
                                        :disabled="isSearchDisabled"
                                    />
                                </div>
                            </div>
                            <div class="card-actions float-end mt-2 text-bold">
                                Total Records: <span style="color: #6e3cff;">{{ totalData ?? 0 }}</span>
                            </div>
                            <div class="card-actions float-end" v-if="page.authuser.company_id">
								<button class="btn btn-primary text-white" @click="importCSV">Invite Users in Bulk</button> &nbsp;
                                <button class="btn btn-primary text-white" @click="createUser">Invite New User</button>
                            </div> -->

                            <div class="row">
                                <!-- MOBILE RESPONSIVE -->
                                <div class="col-12 col-lg-4 col-md-4 mt-2">
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="filter-by" style="width: 82px;">Show</span>
                                        </div>
                                        <select id="filter" class="form-select user-type" aria-describedby="filter-by" v-model="page.viewOption" @change="ChangeView">
                                            <option value="/admin/settings/users/">All Users</option>
                                            <option value="/admin/settings/users/paid/">Paid Users</option>
                                            <option value="/admin/settings/users/archived/">Archived Users</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 col-md-4 mt-2">
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="filter-show">Filter Role</span>
                                        </div>
                                        <select id="filterby" class="form-select filter-role" aria-describedby="filter-show" v-model="page.filterBy" @change="changeFilter">
                                            <option value="1">All Roles</option>
                                            <option value="2">Employer</option>
                                            <option value="3">Employer-HR</option>
                                            <option value="4">Candidate</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 col-md-4 mt-2">
                                    <div class="form-group">
                                        <input
                                            v-model="page.querySearch"
                                            class="form-control"
                                            id="query_search"
                                            placeholder="Search Users"
                                            @input="searchUser"
                                            :disabled="isSearchDisabled"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row text-end">
                                <div class="card-actions float-end mt-2 text-bold">
                                    Total Records: <span style="color: #6e3cff;">{{ totalData ?? 0 }}</span>
                                </div>
                                <div class="card-actions float-end" v-if="page.authuser.company_id">
                                    <button class="btn btn-primary text-white" @click="importCSV">Invite Users in Bulk</button> &nbsp;
                                    <button class="btn btn-primary text-white" @click="createUser">Invite New User</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" :class="{ 'table-responsive' : users.length >= 5 }">
                            <BaseTable
                                :data="users"
                                :options="headers"
                                :sortableColumns="page.sortableColumns"
                                :isSortAscending="page.isSortAscending"
                                @navigateSort="triggerSort"
                                :id="''"
                            >
                                <template v-slot:counter="{ row }">
                                    <div class="text-center">
                                        {{ row.id }}
                                    </div>
                                </template>
                                <template v-slot:role="{ row }">
                                    {{ row.role_id === 3 ? 'Employer-HR' : row.role }}
                                </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="impersonate(row.id)">Impersonate</a></li>
                                                <li><a class="dropdown-item" href="javascript:;" @click="viewRegisteredAccount(row.id)">Registered Accounts</a></li>
                                                <div class="dropdown-divider"></div>
                                                <li :class="(row.subscriptions != null && row.subscriptions.length) ? 'custom_tooltip' : ''" :data-tooltip="row.subscriptions != null && row.subscriptions.length ? 'This account cannot be deactivated because it has active subscriptions.' : ''"><a :disabled="row.subscriptions != null && row.subscriptions.length" class="dropdown-item text-danger" href="javascript:;" @click="attemptDeleteAccount(row.id)">Deactivate Account</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                            <div class="d-flex justify-content-end" v-if="page.paginationActive">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                        <li class="paginate_button page-item previous" :class="{ 'disabled' : page.num === 1 }"><a href="javascript:;" class="page-link" @click="prevPage">Previous</a></li>
                                        <li class="paginate_button page-item" :class="{ 'active' : page.currentPage === n }" v-for="n in page.pages" :key="n"><a href="javascript:;" class="page-link" @click="changePage(n)">{{ n }}</a></li>
                                        <li class="paginate_button page-item next" :class="{ 'disabled' : page.num === page.pages }"><a href="javascript:;" class="page-link" @click="nextPage">Next</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to impersonate this user?.`"
            @cancel="toggleConfirmation"
            @isDelete="impersonateUser"
        />
        <Confirm
            :confirmTitle="`Deactivate Account`"
            :is-confirm="isConfirmAccountDeletion"
            :confirm-text="`This user will be archived and can no longer login using this account. Are you sure you want to deactivate this account?`"
            :confirmButton="`Confirm Account Deactivation`"
            @cancel="toggleDeleteConfirmation"
            @isDelete="wipeOutUser"
        />
    </main>
</template>

<script>
import { useRouter } from 'vue-router';
import PageLoader from '@/components/modules/PageLoader.vue';
import { defineComponent, reactive, onMounted, ref, watchEffect, inject } from 'vue';
import BootstrapBaseTable from '@/components/modules/Datatable.vue';
import userRepo from '@/repositories/admin/users';
import manageUserRepo from '@/repositories/settings/users';
import globalRepo from '@/repositories/global';
import _debounce from 'lodash/debounce';

export default defineComponent({
    setup() {
        const toast = inject('toast');
        const router = useRouter();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            token: localStorage.getItem('token'),
            isLoading: true,
            idToImpersonate: '',
            userType: '',
            pages: [],
            num: 1,
            offSet: 0,
            currentPage: 1,
            viewOption: '/admin/settings/users/',
            filterBy: 1,
            querySearch: '',
            paginationActive: true,
            idToDelete: '',
            sortableColumns: ['registered_date'],
            isSortAscending: true,
            sortype: 'registered_date'

        });

        const { user_log_type } = globalRepo();
        const { users, user, errors, totalData, getPaginateUsers, userImpersonate, logUserActivity, getSearchedUsers } = userRepo();
        const { destroyUser, message } = manageUserRepo();
        const isConfirm = ref(false);
        const isConfirmAccountDeletion = ref(false);
        const isSearchDisabled = ref(false)
        const headers = [
            {
                key: 'counter',
                label: 'ID Number',
                class: 'text-center'
            },
            {
                key: 'name',
                label: 'Fullname'
            },
            {
                key: 'email',
                label: 'Email'
            },
            {
                key: 'role',
                label: 'Role'
            },
            {
                key: 'company',
                label: 'Company'
            },
            {
                key: 'registered_date',
                label: 'Date Registered',
                class: 'registered'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }
        const toggleDeleteConfirmation = () => {
            isConfirmAccountDeletion.value = !isConfirmAccountDeletion.value;
        }

        const impersonate = (id, type) => {
            page.idToImpersonate = id;
            page.userType = type;
            toggleConfirmation();
        }

        const impersonateUser = async () => {
            await userImpersonate(page);
            let user = JSON.parse(localStorage.getItem('authuser'));
            let adminuser = JSON.parse(localStorage.getItem('adminauthuser'));
            let routeData = user.role_id == 2 ? `${process.env.VUE_APP_BASE_URL}/employer/dashboard` : `${process.env.VUE_APP_BASE_URL}/candidate/dashboard`;

            // log activity when impersonation starts
            let logdata = {
                admin_id: adminuser.id,
                user_id: user.id,
                type: user_log_type.start,
                currentPath: user.role_id == 2 ? `/employer/dashboard` : `/candidate/dashboard`
            }
            await logUserActivity(logdata);

            window.location.href = routeData;
        }

        const changePage = (pageNumber) => {
            page.offSet = pageNumber == 1 ? 0 * 10 : (pageNumber - 1) * 10;
            page.num = pageNumber;
            page.currentPage = pageNumber;
        }

        const prevPage = () => {
            page.num--;
            page.currentPage = page.num;
        }

        const nextPage = () => {
            page.num++;
            page.currentPage = page.num;
        }

        watchEffect(() => {
            page.offSet = page.num == 1 ? 0 * 10 : (page.num - 1) * 10;
            if(page.filterBy == 1 && page.querySearch == '') {
                getPaginateUsers(page);
            } else if(page.filterBy == 2 && page.querySearch == '') {
                getPaginateUsers(page, 'employer');
            } else if(page.filterBy == 3 && page.querySearch == '') {
                getPaginateUsers(page, 'employer_hr');
            } else if(page.filterBy == 4 && page.querySearch == '') {
                getPaginateUsers(page, 'candidate');
            }
        });

        onMounted( async () => {
            await getPaginateUsers(page);
            page.pages = Math.ceil(totalData.value / 10);
        });

        const ChangeView = () => {
            router.push(`${page.viewOption}`);
        }

        const searchUser = _debounce(async(event) => {
            if(event.target.value != ''){
                page.filterBy = 1;
                page.paginationActive = false;
                page.isLoading = true;
                await getSearchedUsers(page);
            } else {
                page.paginationActive = true;
                await getPaginateUsers(page);
                page.pages = Math.ceil(totalData.value / 10);
            }
        }, 700);

        const changeFilter = async () => {
            page.querySearch = '';
            page.isLoading = true;
            page.paginationActive = true;
            page.currentPage = page.num = 1;
            page.offSet = page.num == 1 ? 0 * 10 : (page.num - 1) * 10;
            if(page.filterBy == 1) {
                await getPaginateUsers(page);
            }
            if(page.filterBy == 2) {
                await getPaginateUsers(page, 'employer');
            }
            if(page.filterBy == 3) {
                await getPaginateUsers(page, 'employer_hr');
            }
            if(page.filterBy == 4) {
                await getPaginateUsers(page, 'candidate');
            }
            page.pages = Math.ceil(totalData.value / 10);
        }

        const attemptDeleteAccount = (id) => {
            page.idToDelete = id;
            toggleDeleteConfirmation();
        }

        const wipeOutUser = async () => {
            toggleDeleteConfirmation();
            page.isLoading = true;
            await destroyUser(page.idToDelete);
            toast.success(message.value);
            page.filterBy = 1;
            page.querySearch = '';
            await getPaginateUsers(page);
        }

        /** Sorting functions **/
        const toggleSortAscending = () => {
            page.isSortAscending = !page.isSortAscending;
        }

        const triggerSort = async (index) => {
            page.sortype = index;
            toggleSortAscending();
            customSort( users.value, page.sortype, page.isSortAscending );
        }

        const customSort = (data, sortColumn = 'registered_date', sortAscending = true) => {
            return (sortAscending) ? data.sort((a, b) => {
                let fa, fb;
                if(sortColumn != 'registered_date') {
                    fa = a.job[sortColumn].toLowerCase();
                    fb = b.job[sortColumn].toLowerCase();
                } else {
                    fa = a[sortColumn].toLowerCase();
                    fb = b[sortColumn].toLowerCase();
                }
                if (fa < fb) { return -1; }
                if (fa > fb) { return 1; }
                return 0;
            }) : data.sort((a, b) => {
                let fa, fb;
                if(sortColumn != 'registered_date') {
                    fa = a.job[sortColumn].toLowerCase();
                    fb = b.job[sortColumn].toLowerCase();
                } else {
                    fa = a[sortColumn].toLowerCase();
                    fb = b[sortColumn].toLowerCase();
                }
                if (fa > fb) { return -1; }
                if (fa < fb) { return 1; }
                return 0;
            });
        }

        const viewRegisteredAccount = (id) => {
            router.push({
                name: 'admin.users.accounts',
                params: {
                    id: id
                }
            });
        }

        return {
            headers,
            users,
            page,
            user,
            errors,
            totalData,
            impersonate,
            userImpersonate,
            toggleConfirmation,
            isConfirm,
            impersonateUser,
            getPaginateUsers,
            changePage,
            prevPage,
            nextPage,
            ChangeView,
            searchUser,
            changeFilter,
            wipeOutUser,
            toggleDeleteConfirmation,
            isConfirmAccountDeletion,
            attemptDeleteAccount,
            isSearchDisabled,
            toggleSortAscending,
            triggerSort,
            customSort,
            viewRegisteredAccount
        }
    },
    components: {
        PageLoader,
        BootstrapBaseTable
    }
});
</script>

<style scoped>
.w-3 {
    width: 3% !important;
}
.w-10 {
    width: 10% !important;
}
.custom_tooltip {
    border:none;
}
.custom_tooltip:before {
    left:13%;
}
</style>
