<template>
    <div>
        <Modal :modalActive="modalActive" :class="`modal-dialog modal-dialog-centered`" :bodyClass="`modal-scroll`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-md-12">
                        <BaseInput
                            v-model="form.title"
                            label="Course Title"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.title }"
                            id="title"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div v-if="isTrainingSourceOpen">
                            <BaseInput
                                v-model="source"
                                label="Source of Training"
                                type="text"
                                :class="{ 'is-invalid' : errors && errors.training_source_id }"
                                id="name"
                                :errors="source_errors"
                                is-required
                                placeholder="Enter Source of Training"
                                autofocus
                            />
                        </div>
                        <div v-else>
                            <BaseSelect
                                v-model="form.training_source_id"
                                label="Training Source"
                                id="training_source_id"
                                :options="training_sources"
                                :class="{ 'is-invalid' : errors.training_source_id }"
                                :errors="errors"
                                is-required
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div style="padding-top: 34px;">
                            <div v-if="isTrainingSourceOpen">
                                <button class="btn btn-primary btn-save-add-traingsource btn-save-traingcourse" @click="saveTrainingSource">Save Changes</button> &nbsp;
                                <button class="btn btn-danger" @click="cancel">Cancel</button>
                            </div>
                            <button class="btn btn-primary btn-addtrainingsource" @click="addTrainingSource" v-else>Add Training Source</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group mt-2">
                            <label class="form-label">Skill Type</label>
                            <div class="d-flex">
                                <BaseRadio
                                    v-model="form.skill_type"
                                    placeholder="Soft Skills"
                                />
                                <BaseRadio
                                    v-model="form.skill_type"
                                    placeholder="Hard Skills"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <BaseSelect
                                v-model="form.difficulty"
                                label="Difficulty"
                                id="difficulty"
                                :options="course_difficulty"
                                optionId="name"
                                :class="{ 'is-invalid' : errors.difficulty }"
                                :errors="errors"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div v-if="isTrainingInstructorOpen">
                            <BaseInput
                                v-model="instructor"
                                label="Instructor"
                                type="text"
                                :class="{ 'is-invalid' : errors && errors.author }"
                                id="name"
                                :errors="source_errors"
                                is-required
                                placeholder="Enter Instructor's Name"
                                autofocus
                            />
                        </div>
                        <div v-else>
                            <BaseSelect
                                v-model="form.author"
                                label="Instructor"
                                id="author"
                                :options="training_instructors"
                                optionId="name"
                                :class="{ 'is-invalid' : errors.author }"
                                :errors="errors"
                                is-required
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div style="padding-top: 34px;">
                            <div v-if="isTrainingInstructorOpen">
                                <button class="btn btn-primary btn-save-instructor" @click="saveTrainingInstructor">Save Changes</button> &nbsp;
                                <button class="btn btn-danger" @click="cancelInstructor">Cancel</button>
                            </div>
                            <button class="btn btn-primary btn-add-instructor" @click="addTrainingInstructor" v-else>Add Instructor</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <BaseInput
                            v-model="form.link"
                            label="Course Link"
                            type="url"
                            :class="{ 'is-invalid' : errors && errors.link }"
                            id="link"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="form-label learning-planmultiselect">Learning Plans</label>
                            <multiselect
                                v-model="form.learning_plans"
                                :options="templates"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                :class="{ 'is-invalid' : errors && errors.learning_plan_ids }"
                                placeholder="Select Learning Plans"
                                label="name"
                                track-by="id"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="form-label tags-multiselect">Tags</label>
                            <multiselect
                                v-model="tag_id"
                                :options="predefineTags"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                :class="{ 'is-invalid' : errors && errors.tags }"
                                placeholder="Enter Tags"
                                label="name"
                                track-by="id"
                                @tag="addTags"
                            />
                            <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors && errors.tags">{{ errors.tags[0] }}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div v-if="hasImage && hasNotDeleteImage">
                            <label class="form-label">Course Image</label>
                            <div class="input-group">
                                <input type="text" class="form-control" :value="form.base_thumbnail" readonly>
                                <button class="btn btn-outline-danger" type="button" @click="removeImage">Remove</button>
                            </div>
                        </div>
                        <div v-else>
                            <label class="form-label">Course Image</label>
                            <button class="btn btn-primary" @click="openFileManager">Upload Course Image</button>
                            <div v-if="imageFilename"><strong>{{ imageFilename }}</strong></div>
                            <div v-if="imgSelected"><strong>{{ imgSelected }}</strong></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <BaseEditor
                            label="Training Content"
                            v-model="form.content"
                            :class="{ 'is-invalid' : errors && errors.content }"
                            id="content"
                            :errors="errors"
                            @editorContent="setTrainingContent"
                            is-required
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" id="save-all" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this image?`"
            @cancel="toggleConfirmation"
            @isDelete="destroyImage"
        />
        <FileManagerModal 
            :modalActive="fileManagerModalActive"
            @save-photo="savePhoto"
            @close-modal="closeFileManager"
        />
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, watchEffect, computed } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import BaseCheckbox from '../../../components/modules/form/BaseCheckbox.vue';
import globalRepo from '@/repositories/global';
import FileManagerModal from '@/views/admin/trainings/modals/FileManagerModal.vue';

export default defineComponent({
    components: { BaseCheckbox, FileManagerModal },
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {}
        },
        hasImage: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            is_premium: false
        });

        const {
            templates,
            getPrivateLearningPlanTemplates,
            training_sources,
            training_source,
            getTrainingSources,
            insertTrainingSource,
            training_instructors,
            training_instructor,
            getTrainingInstructors,
            insertTrainingInstructor,
            unlinkImage,
            status,
            errors : source_errors
        } = trainingRepo();
        const { course_difficulty } = globalRepo();
        const isTrainingSourceOpen = ref(false);
        const isTrainingInstructorOpen = ref(false);
        const source = ref('');
        const instructor = ref('');
        const isConfirm = ref(false);
        const imageFile = ref('');
        const hasNotDeleteImage = ref(true);
        const learning_plan_ids = ref([]);
        const tag_id = ref([]);
        const predefineTags = ref([]);
        const courseType = ref(false);
        const fileManagerModalActive = ref(false);
        const imageFilename = ref('');
        const imgSelected = ref('');

        onMounted(() => {
            getTrainingSources();
            getTrainingInstructors();
            getPrivateLearningPlanTemplates();
        });

        watchEffect(() => {
            props.form.image = imageFile.value;
            tag_id.value = props.form.tags_arr ?? tag_id.value;
        });

        const close = () => {
            learning_plan_ids.value = [];
            emit('close', hasNotDeleteImage.value)
        }

        const saveChanges = () => {
            props.form.tags = ''
            if (tag_id.value.length > 0) {
                for (var i = 0; i < tag_id.value?.length; i++) {
                    if(tag_id.value[i].name !== null) {
                        props.form.tags += tag_id?.value[i].name.concat(',');
                    }
                }
            }
            imageFilename.value = '';
            imgSelected.value = '';
            emit('saveChanges');
        }

        const onDeleteBtn = () => {
            emit('onDeleteBtn');
        }

        const onFileImageChange = (file) => {
            imageFile.value = file;
        }

        const setTrainingContent = (evt) => {
            if(evt) {
                props.form.content = evt ?? '';
            }
        }

        const addTrainingSource = () => {
            isTrainingSourceOpen.value = true;
        }

        const addTrainingInstructor = () => {
            isTrainingInstructorOpen.value = true;
        }

        const cancel = () => {
            isTrainingSourceOpen.value = false;
            state.is_premium = false;
        }

        const cancelInstructor = () => {
            isTrainingInstructorOpen.value = false;
            state.is_premium = false;
        }

        const saveTrainingSource = async () => {
            let formData = new FormData();
            formData.append('name', source.value ?? '');

            await insertTrainingSource(formData);
            if(status.value === 200) {
                await getTrainingSources();
                props.form.training_source_id = training_source.value.id;
                cancel();
            }
        }

        const saveTrainingInstructor = async () => {
            let formData = new FormData();
            formData.append('name', instructor.value ?? '');

            await insertTrainingInstructor(formData);
            if(status.value === 200) {
                props.form.author = training_instructor.value.name;
                cancelInstructor();
            }
        }

        const removeImage = () => {
            isConfirm.value = true;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const destroyImage = async () => {
            await unlinkImage(props.form.id);
            if(status.value === 200) {
                hasNotDeleteImage.value = false;
                toggleConfirmation();
            }
        }

        const addLearningPlans = (evt) => {
            if(evt) {
                props.form.learning_plan_ids += `,${evt.id},`;
            }
        }

        const removeLearningPlans = (evt) => {
            if(evt) {
                let finalLearningPlans = props.form.learning_plan_ids.replace(`,${evt.id}`, '');
                props.form.learning_plan_ids = finalLearningPlans;
            }
        }

        const addTags = (newTag) => {
            props.form.tags = '';
            if(newTag) {
                const tag = {
                    name: newTag,
                    id: Math.floor((Math.random() * 10000000))
                }
                tag_id.value.push(tag);
            }
        }

        const removeTags = (evt) => {
            if(evt) {
                let finalTags = props.form.tag_id.replace(`,${evt.id}`, '');
                props.form.tag_id = finalTags;
            }
        }

        const countLearningPlans = (pass) => {
            if(typeof pass === 'undefined') {
                return 0
            } else {
                return pass
            }
        }

        const openFileManager = () => {
            fileManagerModalActive.value = true;
            emit('close', hasNotDeleteImage.value);
        }

        const closeFileManager = () => {
            fileManagerModalActive.value = false;
            emit('open-current-modal');
        }

        const savePhoto = (evt) => {
            let data = {
                imageFile: evt.imageFile,
                imageSelected: evt.imageSelected,
                imageType: evt.imageType
            }
            
            imageFilename.value = evt.imageFilename;
            imgSelected.value = evt.imageSelected;
            fileManagerModalActive.value = false;
            emit('save-photo', data);
        }

        return {
            state,
            templates,
            getPrivateLearningPlanTemplates,
            training_sources,
            training_source,
            source,
            status,
            source_errors,
            close,
            isConfirm,
            saveChanges,
            onDeleteBtn,
            onFileImageChange,
            setTrainingContent,
            isTrainingSourceOpen,
            getTrainingSources,
            addTrainingSource,
            cancel,
            saveTrainingSource,
            insertTrainingSource,
            removeImage,
            toggleConfirmation,
            destroyImage,
            hasNotDeleteImage,
            learning_plan_ids,
            addLearningPlans,
            removeLearningPlans,
            tag_id,
            addTags,
            removeTags,
            predefineTags,
            countLearningPlans,
            instructor,
            isTrainingInstructorOpen,
            training_instructors,
            training_instructor,
            getTrainingInstructors,
            insertTrainingInstructor,
            addTrainingInstructor,
            saveTrainingInstructor,
            cancelInstructor,
            courseType,
            course_difficulty,
            openFileManager,
            fileManagerModalActive,
            closeFileManager,
            savePhoto,
            imageFilename,
            imgSelected
        }
    }
})
</script>
<style>
.invalid-feedback {
    display: block !important;
}
.modal-scroll {
    height: 600px;
    overflow: auto;
}
</style>
<style scoped>
.modal-dialog {
    pointer-events: all !important;
}
</style>
