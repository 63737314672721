import axios from "axios";
import { ref, inject } from "vue";

export default function userRepo() {

    const toast = inject('toast');
    const users = ref([]);
    const user  = ref([]);
    const company  = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const codes = ref([]);
    const subscriptionType = ref('');
    const learningPlans = ref([]);
    const totalData = ref('');
    const importResults = ref([]);
    const current_courses = ref([]);
    const learning_plans_assigned = ref([]);
    const invited_users = ref([]);

    const getUsers = async (page) => {
        let company_id = page.authuser.company_id ?? 0;
        let response = await axios.get(`employer/users?company_id=${company_id}&user_id=${page.authuser.id}`);
        users.value = response.data.data;
        company.value = response.data.company;
        page.isLoading = false;
    }

    const getUser = async (page, id = null) => {
        let response;
        if(id) {
            response = await axios.get(`employer/users/${id}`);
            user.value = response.data.user;
        } else {
            response = await axios.get(`employer/users/${page.authuser.id}`);
            user.value = response.data.user;
            codes.value = response.data.codes;
            company.value = response.data.company;
            page.authuser = response.data.user
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        }
        page.isLoading = false;
    }

    const getUserFromCompany = async (id) => {
        let response = await axios.get(`employer/users/company/${id}`);
        user.value = response.data.user;
    }

    const getPaginateUsers = async (page) => {
        let formData = {
            user_id: page.authuser.id,
            length: 10,
            start: page.offSet,
            company_id: page.authuser.company_id ?? 0
        }
        let response = await axios.post(`employer/users/paginate`, formData);
        users.value = response.data.data;
        totalData.value = response.data.recordsTotal;
        page.isLoading = false;
    }

    const getCompanyUser = async (page, company_id) => {
        let response = await axios.get(`employer/users?company_id=${company_id}`);
        return response.data.data.length > 0 ? response.data.data : []
    }

    const getCompany = async (id) => {
        let response = await axios.get(`employer/company/${id}`);
        company.value = response.data.data;
    }

    const getUserEmployerCompany = async (page, employer_id) => {
        let response = await axios.get(`employer/users/${employer_id}`);
        user.value = response.data.user;
        codes.value = response.data.codes;
        company.value = response.data.company;
        page.isLoading = false;
    }

    const getUserCandidate = async (page) => {
        let response = await axios.get(`candidate/users/${page.authuser.id}`);
        user.value = response.data.user;
        codes.value = response.data.codes;
        company.value = response.data.company;
        current_courses.value = response.data.current_courses;
        learning_plans_assigned.value = response.data.learning_plans_assigned;
        page.isLoading = false;
    }

    const getInviteUsers = async (company_id) => {
        let response = await axios.get(`employer/users/invite?company_id=${company_id}`);
        invited_users.value = response.data.data;
    }

    const inviteUser = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/users/invite`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alertMessage(response.data.message, response.data.status);
            status.value = response.data.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const changeUserRole = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/users/change-role`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alertMessage(response.data.message, response.data.status);
            status.value = response.data.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateUser = async (data, page) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/users`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            status.value = response.data.status;
            alertMessage(response.data.message);
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
                console.log(errors.value);
            }
        }
    }

    const updatePassword = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/users/update-password`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateCompany = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/company`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            status.value = response.data.status;
            alertMessage(response.data.message);
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const destroyUser = async (id) => {
        let response = await axios.delete(`employer/users/${id}`);
        message.value = response.data.message;
    }

    const inviteUserViaCsv = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/users/invite-csv`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            importResults.value = response.data.import_results;
            status.value = response.data.status;
            alertMessage(response.data.message, response.data.status);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const assignTrainingCourseToUser = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/users/assignCourse`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alertMessage(response.data.message, response.data.status);
            status.value = response.data.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    const getEmployerSubscriptionType = async () => {
        let response = await axios.get(`employer/users/subscription-type`);
        subscriptionType.value = response.data.subscriptionType;
    }

    const processRemoveUserFromCompany = async (page) => {
        if(page.idToRemove) {
            let response = await axios.get(`employer/users/remove-from-company?user_id=${page.idToRemove}&company_id=${page.authuser.company_id}`);
            alertMessage(response.data.message, response.data.status);
        } else {
            alertMessage('User does not exist.', 404);
        }
    }

    const getEmployerLearningPlans = async (type) => {
        let response = await axios.get(`admin/learningplans/employer-account?account_type=${type}`);
        learningPlans.value = response.data.learning_plans;
    }

    return {
        users,
        user,
        getUsers,
        getUser,
        getUserCandidate,
        inviteUser,
        updateUser,
        updatePassword,
        updateCompany,
        destroyUser,
        inviteUserViaCsv,
        assignTrainingCourseToUser,
        getEmployerSubscriptionType,
        subscriptionType,
        errors,
        message,
        status,
        company,
        codes,
        alertMessage,
        getUserEmployerCompany,
        getCompanyUser,
        processRemoveUserFromCompany,
        changeUserRole,
        getEmployerLearningPlans,
        learningPlans,
        getPaginateUsers,
        totalData,
        importResults,
        current_courses,
        learning_plans_assigned,
        getInviteUsers,
        getUserFromCompany,
        invited_users,
        getCompany
    }
}