<template>
    <div class="modal-job-post-container">
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-xl job-opening-modal`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div id="smartwizard-arrows-primary" class="wizard wizard-primary mb-4 sw sw-theme-arrows sw-justified">
                    <ul class="nav">
                        <li class="nav-item" v-for="(tab, index) in tabs" :key="tab">
                            <a class="nav-link inactive" :class="getActive(index+1)" @click="currentTab(index+1)">
                                {{ tab.step }}<br />
                                <small>{{ tab.description }}</small>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <PageLoader v-if="state.isLoading" />
                        <div v-else class="tab-pane active" role="tabpanel" style="min-height: 500px;">
                            <Information 
                                v-show="state.activeTab == 1"
                                :form="form"
                                :errors="errors"
                                :promotedJobAvailable="promotedJobAvailable"
                                :promoteJobSwitch="promoteJobSwitch"
                                @toggle-subscription="toggleSubscription"
                                @emit-languages="setLanguages"
                                @emit-role_id="setLocationRoleId"
                                @emit-promote_job="setPromoteJob"
                                @handle-switch="handleSwitch"
                            />
                            <JobDetails 
                                v-show="state.activeTab == 2"
                                :form="form"
                                :errors="errors"
                                @emit-schedules="setJobSchedule"
                                @emit-jobtype="setJobType"
                                @emit-time_frame="setJobTimeFrame"
                            />
                            <Compensation
                                v-show="state.activeTab == 3"
                                :form="form"
                                :errors="errors"
                            />
                            <Description
                                v-show="state.activeTab == 4"
                                :form="form"
                                :errors="errors"
                                @set-description="setDescription"
                            />
                            <Skills 
                                v-show="state.activeTab == 5"
                                :form="form"
                                :errors="errors"
                                @emit-skillsneeded="setSkillNeeded"
                            />
                            <Benefits 
                                v-show="state.activeTab == 6"
                                :form="form"
                                :errors="errors"
                                @set-benefit="setBenifit"
                            />
                            <Questions 
                                v-show="state.activeTab == 7"
                                :form="form"
                                :errors="errors"
                                @emit-jobquestion="setJobQuestion"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <div class="mr-5">
                                <button class="btn btn-primary" :class="{ 'disabled' : isPrevDisable }" @click="prevTab" type="button">Previous</button>
                            </div>
                            <BaseButton :is-saved="status === 200" v-if="state.isLastStep" :btn-value="`Save Job Posting`" @btnClicked="submitJob" />
                            <button class="btn btn-primary" type="button" v-else @click="nextTab">Next</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { reactive, ref, watchEffect } from 'vue';
import jobRepo from '@/repositories/jobs';
import questionRepo from '@/repositories/settings/question';

import Information from '@/views/employer/jobs/components/Information.vue';
import JobDetails from '@/views/employer/jobs/components/JobDetails.vue';
import Compensation from '@/views/employer/jobs/components/Compensation.vue';
import Description from '@/views/employer/jobs/components/Description.vue';
import Skills from '@/views/employer/jobs/components/Skills.vue';
import Benefits from '@/views/employer/jobs/components/Benefits.vue';
import Questions from '@/views/employer/jobs/components/Questions.vue';

export default {
    components: {
        Information,
        JobDetails,
        Compensation,
        Description,
        Skills,
        Benefits,
        Questions
    },
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: ""
        },
        form: {
            type: Object,
            default: {}
        },
        promotedJobAvailable: {
            type: Number,
            default: 0
        },
        promoteJobSwitch: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem("authuser")),
            isLoading: false,
            activeTab: 1,
            data: {},
            languages: [],
            selectedQuestionArray: [],
            job_schedules: '',
            job_description: '',
            skills_needed: '',
            job_benefits: '',
            screening_questions: '',
            selectedQuestion: [],
            location_role_id: '',
            job_time_frame: '',
            promote_job_post: 0,
            hasActiveError: false
        });
        const { status, errors, jobCategory, getJobCategory, getJobs, jobs, saveJob } = jobRepo(); // validateJob
        const { removeCurrentQuestions } = questionRepo();
        const isPrevDisable = ref(false);
        const keywords = ref([]);
        const screeningQuestions = ref([]);

        const tabs = [
            {
                step: "Step 1",
                description: "Basic Information"
            },
            {
                step: "Step 2",
                description: "Additional Details"
            },
            {
                step: "Step 3",
                description: "Compensation"
            },
            {
                step: "Step 4",
                description: "Job Description"
            },
            {
                step: "Step 5",
                description: "Skills Needed"
            },
            {
                step: "Step 6",
                description: "Other Benefits"
            },
            {
                step: "Step 7",
                description: "Pre-screening"
            }
        ];

        const currentTab = (step) => {
            if (state.activeTab > 1 && state.activeTab > step) {
                state.activeTab = step;
            }
            state.isLastStep = (tabs.length == state.activeTab);
        }

        const getActive = (step) => {
            if (state.activeTab > 1 && state.activeTab > step) {
                return "done";
            }
            return (state.activeTab == step) ? "active" : "";
        }

        const prevTab = () => {
            let fields = document.querySelectorAll(".tab-pane.active input, .tab-pane.active select");
            if (state.activeTab != 1) {
                state.activeTab = state.activeTab - 1;
            }
            state.isLastStep = (tabs.length == state.activeTab);
            fields.forEach(function (item, index) {
                item.classList.remove("valid");
            });
        }

        const nextTab = () => {
            updateArray(props.form.id);
            let formData = {
                promote_job: state.promote_job_post ?? '',
                promote_days: state.promote_job_post ? props.form.promote_days : 0,
                position_title: props.form.position_title ?? '',
                country_id: props.form.country_id ?? '',
                language: state.languages ?? '',
                location_role_id: state.location_role_id ?? 1,
                job_type: state.job_type ?? '',
                num_of_candidates: props.form.num_of_candidates ?? '',
                schedules: state.job_schedules ?? '',
                job_time_frame: state.job_time_frame ?? 2,
                compensation_type: props.form.compensation_type ?? '',
                exact_amount: props.form.exact_amount ?? '',
                min_amount: props.form.min_amount ?? '',
                max_amount: props.form.max_amount ?? '',
                rate: props.form.rate ?? '',
                job_description: state.job_description ?? props.form.job_description,
                job_benefits: state.job_benefits ?? '',
                submit_resume: require_resume.value ? 'Yes' : 'Optional',
                status: props.form.status ?? '',
                job_expiry: props.form.job_expiry ?? '',
                keywords: keywords.value ?? '',
                include_default_benefits: props.form.include_default_benefits ?? 0,
                city: props.form.city ?? '',
                state: props.form.state ?? '',
                active_tab: state.activeTab,
                category_id: props.form.category_id ?? '',
                promote: state.promote_job ?? 0,
                promote_days: props.form.promote_days ?? 0,
                skills_needed: state.skills_needed ?? '',
                screening_questions: state.screening_questions ?? ''
            }
            
            validateJob(formData);
            if (status.value == 200) {
                if (tabs.length > state.activeTab) {
                    state.activeTab = state.activeTab + 1;
                }
                state.isLastStep = (tabs.length == state.activeTab);
            }
        }

        const submitJob = async () => {
            state.isLoading = true;
            state.isLastStep = false;

            let formData = new FormData();
            formData.append('promote_job', state.promote_job_post ?? '');
            formData.append('promote_days', state.promote_job_post ? props.form.promote_days : 0);
            formData.append('position_title', props.form.position_title ?? '');
            formData.append('country_id', props.form.country_id ?? '');
            formData.append('language', state.languages ?? '');
            formData.append('location_role_id', state.location_role_id ?? 1);
            formData.append('job_type', state.job_type ?? '');
            formData.append('num_of_candidates', props.form.num_of_candidates ?? '');
            formData.append('schedules', state.job_schedules ?? '');
            formData.append('job_time_frame', state.job_time_frame ?? 2);
            formData.append('compensation_type', props.form.compensation_type ?? '');
            formData.append('exact_amount', props.form.exact_amount ?? '');
            formData.append('min_amount', props.form.min_amount ?? '');
            formData.append('max_amount', props.form.max_amount ?? '');
            formData.append('rate', props.form.rate ?? '');
            formData.append('job_description', state.job_description ?? props.form.job_description);
            formData.append('job_benefits', state.job_benefits ?? '');
            formData.append('submit_resume', require_resume.value ? 'Yes' : 'Optional');
            formData.append('status', props.form.status ?? '');
            formData.append('job_expiry', props.form.job_expiry ?? '');
            formData.append('keywords', keywords.value ?? '');
            formData.append('include_default_benefits', props.form.include_default_benefits ?? 0);
            formData.append('city', props.form.city ?? '');
            formData.append('state', props.form.state ?? '');
            formData.append('active_tab', state.activeTab);
            formData.append('category_id', props.form.category_id ?? '');
            formData.append("promote", state.promote_job ?? 0);
            formData.append("promote_days", props.form.promote_days ?? 0);
            formData.append("skills_needed", state.skills_needed ?? '');
            formData.append("screening_questions", state.screening_questions ?? '');
            formData.append("company_id", state.authuser.company_id ?? '');
            formData.append("id", props.form.id ?? '');
            formData.append('is_job_clone', 2);

            await saveJob(formData);
            if(status.value == 200) {
                setTimeout(() => {
                    state.isLoading = false;
                    state.activeTab = 1;
                }, 800);
                emit('refresh-page');
            }
        }

        const toggleSubscription = () => {
            emit('toggleModalSubscription');
        }

        const updateArray = (id) => {
            if (id) {
                state.job_type = state.job_type?.length ? state.job_type : props.form.job_type_object;
                state.schedules = state.schedules?.length ? state.schedules : props.form.schedules_object;
                state.skills_needed = state.skills_needed?.length ? state.skills_needed : props.form.skills_needed_object;
                keywords.value = keywords.value?.length ? keywords.value : props.form.keywords_object;
                state.job_description = props.form.job_description;
                if ((state.activeTab + 1) == 6) {
                    screeningQuestions.value = props.form.screening;
                    state.selectedQuestionArray.push(state.selectedQuestion);
                    let screenings = props.form.screening;
                    screenings.filter(screening => {
                        state.selectedQuestionArray.push(screening.question_id);
                    });
                    removeCurrentQuestions({ question_ids: state.selectedQuestionArray, company_id: state.authuser.company_id });
                }
            }
        };

        const close = () => {
            emit('close');
        }

        const setLanguages = (evt) => {
            state.languages = evt;
        }

        const setLocationRoleId = (evt) => {
            state.location_role_id = evt;
        }

        const setJobType = (evt) => {
            state.job_type = evt;
        }

        const setJobSchedule = (evt) => {
            state.job_schedules = evt;
        }

        const setDescription = (evt) => {
            state.job_description = evt;
        }

        const setSkillNeeded = (evt) => {
            state.skills_needed = evt;
        }

        const setBenifit = (evt) => {
            state.job_benefits = evt;
        }

        const setJobQuestion = (evt) => {
            state.screening_questions = evt;
        }

        const setJobTimeFrame = (evt) => {
            state.job_time_frame = evt;
        }

        const handleSwitch = (evt) => {
            emit('emit-handleSwitch', evt);
        }

        const setPromoteJob = (evt) => {
            state.promote_job_post = evt;
        }

        const validateJob = (form) => {
            status.value = '';
            if(state.activeTab == 1) {
                errors.value = {
                    position_title: (form.position_title == '') ? ['The position title field is required.'] : '',
                    job_expiry: (form.job_expiry == '') ? ['The expiration date field is required.'] : '',
                    country_id: (form.country_id == '') ? ['The country field is required.'] : '',
                    status: (form.status == '') ? ['The status field is required.'] : ''
                }

                if(form.position_title != '' && form.job_expiry != '' && form.country_id != '' && form.status != '') {
                    status.value = 200;
                }
            }

            if(state.activeTab == 2) {
                errors.value = {
                    job_type: (form.job_type == '') ? ['The job type field is required.'] : '',
                    num_of_candidates: (form.num_of_candidates == '') ? ['The number of candidates field is required.'] : '',
                    schedules: (form.schedules == '') ? ['The schedules field is required.'] : ''
                }

                if(form.job_type != '' && form.num_of_candidates != '' && form.schedules != '') {
                    status.value = 200;
                }
            }

            if(state.activeTab == 3 || state.activeTab == 6 || state.activeTab == 7) {
                status.value = 200;
            }

            if(state.activeTab == 4) {
                errors.value = {
                    job_description: (form.job_description == '') ? ['The job description field is required.'] : ''
                }

                if(form.job_description != '') {
                    status.value = 200;
                }
            }

            if(state.activeTab == 5) {
                errors.value = {
                    skills_needed: (form.skills_needed == '') ? ['The skills needed field is required.'] : ''
                }

                if(form.skills_needed != '') {
                    status.value = 200;
                }
            }

        }

        watchEffect(() => {
            isPrevDisable.value = (state.activeTab == 1);
        });

        return {
            state,
            status,
            validateJob,
            errors,
            jobCategory,
            getJobCategory,
            getJobs,
            jobs,
            saveJob,
            currentTab,
            tabs,
            getActive,
            prevTab,
            nextTab,
            isPrevDisable,
            toggleSubscription,
            updateArray,
            removeCurrentQuestions,
            keywords,
            close,
            setLanguages,
            setJobType,
            setJobSchedule,
            setDescription,
            setSkillNeeded,
            setBenifit,
            setJobQuestion,
            submitJob,
            screeningQuestions,
            setLocationRoleId,
            setJobTimeFrame,
            handleSwitch,
            setPromoteJob
        }
    }
}
</script>

<style scoped>
.ml-5 {
    margin-left: 10px;
}
</style>