<template>
    <div class="form-group mb-3" :class="customClass">
        <label :for="id" class="form-label" v-if="label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
        <input
            v-bind="$attrs"
            class="form-control"
            :class="{ 'valid' : hasValue[id] }"
            :id="id"
            :placeholder="placeholder"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            @change="fieldChange"
        />
        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors && errors[id]">{{ errors[id][0] }}</label>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Array],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {},
            required: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        marginBottomOn: {
            type: Boolean,
            default: true
        },
        customClass:{
            type: String,
            default: ''
        }
    },
    setup(props) {
        const hasValue = ref([]);
        const fieldChange = (evt) => {
            let id = evt.target.id;
            if(evt.target.value) {
                hasValue.value[id] = true;
                props.errors[id] = null;
            } else {
                hasValue.value[id] = false;
            }
        }

        return {
            fieldChange,
            hasValue
        }
    }
})
</script>
<style>
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:#ced4da !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:#ced4da !important;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:#ced4da !important;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:#ced4da !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:#ced4da !important;
}
::placeholder { /* Most modern browsers support this now. */
   color:#ced4da !important;
}
</style>