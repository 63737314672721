<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-12">
                        <BaseInput 
                            v-model="form.name"
                            label="Learning Plan Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.name }"
                            id="name"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-label">Add Courses to this Learning Plan</label>
                            <multiselect 
                                v-model="training_ids" 
                                :options="trainings" 
                                :multiple="true" 
                                :taggable="true"
                                :close-on-select="false" 
                                :clear-on-select="false" 
                                :preserve-search="true" 
                                :hideSelected="true"
                                placeholder="Select Courses" 
                                label="title" 
                                track-by="id"
                                @remove="removeLearningPlan"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import employerTrainingRepo from '@/repositories/employer/trainings';
import learningPlanRepo from '@/repositories/admin/learningplans';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        learningPlanType: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const { trainings, getTrainings } = trainingRepo();
        const { removeCustomLearningPlan } = employerTrainingRepo();
        const { insertCustomLearningPlan, insertPremiumLearningPlan, updateLearningPlan, errors, status } = learningPlanRepo();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true
        });
        const training_ids = ref('');
        const plantype = ref('');

        const close = () => {
            training_ids.value = '';
            props.form.name = '';
            props.form.id = '';
            emit('close');
        }
        
        const saveChanges = async () => {
            let learning_plan_id = props.form.learning_plan_id;
            let trainings = training_ids.value;
            let training_array_ids = [];
            
            plantype.value = props.learningPlanType;

            if(trainings.length) {
                trainings.forEach(training => {
                    training_array_ids.push(training.id);
                });
            }

            let formData = new FormData();
            formData.append('name', props.form.name ?? '');
            formData.append('training_ids', training_array_ids);

            if(learning_plan_id) {
                formData.append('plan_id', learning_plan_id);
                await updateLearningPlan(formData);
                props.form.learning_plan_id = '';
            } else if(plantype.value == 'premium') {
                await insertPremiumLearningPlan(formData);
            } else if(plantype.value == 'custom') {
                await insertCustomLearningPlan(formData);
            }

            if(status.value == 200) {
                emit('reloadPage');
                training_ids.value = '';
                props.form.name = '';
                props.form.id = '';
            }
        }

        const removeLearningPlan = (training) => {
            let formData = new FormData();
            formData.append('training_id', training.id ?? '');
            formData.append('learning_plan_id', props.form.learning_plan_id);
            removeCustomLearningPlan(formData);
        }

        onMounted(() => {
            getTrainings(page);
        });

        watchEffect(() => {
            training_ids.value = props.form.training_ids ?? training_ids.value;
        });

        return {
            page,
            trainings,
            getTrainings,
            insertCustomLearningPlan,
            training_ids,
            errors,
            status,
            close,
            saveChanges,
            removeLearningPlan,
            removeCustomLearningPlan,
            updateLearningPlan
        }
    }
})
</script>