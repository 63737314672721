<template>
    <div>
        <footer class="footer">
            <ModalFeedback
            :modalActive="modalActive"
            :modalTitle="stateType.modalTitle"
            :modalForm="true"
            :modalType="stateType.type"
            @close="toggleFeedback"
            @saveChanges="save">
            </ModalFeedback>
            <div class="container-fluid">
                <div class="row text-muted">
                    <div class="col-6 text-start">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a class="text-muted" href="javascript:void(0);" @click="toggleFeedback('Support')">Support</a>
                            </li>
                            <li class="list-inline-item">
                                <router-link class="text-muted" target="_blank" :to="{ name: 'help' }">
                                    <span class="text-muted">Help Center</span>
                                </router-link>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" href="#" @click="toggleFeedback('Feedback')">Feedback</a>
                            </li>
                            <li class="list-inline-item">
                                <a :href="`${page.webpage_url}/privacy`" class="text-muted" target="_blank">Privacy</a>
                            </li>
                            <li class="list-inline-item">
                                <a :href="`${page.webpage_url}/terms-services`" class="text-muted" target="_blank">
                                    Terms of Service    
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 text-end">
                        <p class="mb-0">
                            &copy; 2022 - <a href="index.html" class="text-muted">Simphini</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, watchEffect, reactive } from 'vue';
import ModalFeedback from '@/views/ModalFeedback.vue';

export default defineComponent({
    setup() {
        const page = reactive({
            webpage_url: process.env.VUE_APP_PUBLIC_BASE_URL ?? "http://localhost:8081"
        });
        const modalActive = ref(false);
        const stateType = reactive({
            type: '',
            modalTitle: ''
        });

        const toggleFeedback = (toggleType) => {
            stateType.type = toggleType;
            stateType.modalTitle = toggleType + ' Form';
            modalActive.value = !modalActive.value
        }


        const close = () => {
        }
        const save = () => {
        }

        return {
            modalActive,
            close,
            save,
            toggleFeedback,
            stateType,
            page
        }
    },
    components: {
        ModalFeedback
    }
})
</script>
