<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-md modal-dialog-scrollable`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <BaseInput 
                                v-model="form.title"
                                label="Certificate Title"
                                type="text"
                                :class="{ 'is-invalid' : v$.title.$error && v$.title.$errors[0].$message }"
                                id="title"
                                :errors="errors"
                                is-required
                                placeholder="Your certificate title"
                                autofocus
                                :customClass="'input-group-lg'"
                            />
                        <BaseCheckbox
                            label="This certificate does not expire."
                            placeholder="This certificate does not expire."
                            id="no_expiry"
                            v-model="no_expiry"
                            :modelValue="no_expiry"
                            design="inline"
                            @change="expiryChange"
                        />
                        <div class="mb-3" v-if="no_expiry === false"> 
                            <!-- v-if="no_expiry === false" -->
                            <label for="time_period" class="form-label">Validity</label>
                            <Datepicker
                                id="time_period"
                                v-model="form.validity" 
                                format="MM/dd/yyyy"
                                :enableTimePicker="false"
                                :range="false"
                                placeholder="Date (MM/DD/YYYY)"
                                :modelValue="form.validity"
                                textInput
                            />
                        </div>
                        <div>
                            <BaseEditor
                                label="Description"
                                v-model="form.description"
                                id="description"
                                placeholder="Enter description"
                                @editorContent="setCertificateContent"
                                :errors="errors"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect, reactive, computed, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, helpers, maxLength } from '@vuelidate/validators'

export default defineComponent({
    props: ['modalActive','form','modalTitle'],
    setup(props, { emit }) {
        const no_expiry = ref(false);
        const errors = ref({});
        const state = reactive({
            title: "",
            description: ""
        });
        const rules = computed(() => {
            return {
                title: { 
                    required: helpers.withMessage("Certificate Title is required.", required),
                    maxLength: maxLength(255)
                },
                description: {
                    maxLength: maxLength(1000)
                }
            };
        });
        const v$ = useVuelidate(rules, state);
        watchEffect(() => {
            if(props.form.validity) {
                no_expiry.value = false;
                props.form.validity = new Date(props.form.validity)
            }
            props.form.description = props.form.description ?? '';
            props.form.title = props.form.title ?? '';
            state.title = props.form.title
            state.description = props.form.description
        })

        const close = () => {
            state.title = ''
            state.description = ''
            errors.value.title = null;
            errors.value.description = null;
            v$.value.$reset()
            emit('close')
        }
        
        const saveChanges = () => {
            v$.value.$validate();
            props.form.title = state.title
            props.form.description = state.description
            errorEvent()
            if (!v$.value.$error) {
                emit('saveChanges');
            }
        }

        const setCertificateContent = (evt) => {
            props.form.description = evt ?? '';
        }

        const expiryChange = (event) => {
            if(event.target.checked == true){
                props.form.validity = ''
            }
        }

        const errorEvent = () => {
            if (v$.value.title.$errors[0]) {
                errors.value.title = [v$.value.title.$errors[0].$message];
            }
            if (v$.value.description.$errors[0]) {
                errors.value.description = [v$.value.description.$errors[0].$message];
            }
        }
        return {
            state,
            v$,
            no_expiry,
            close,
            saveChanges,
            setCertificateContent,
            expiryChange,
            errors,
            errorEvent
        }
    }
})
</script>