<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12 mt-3">
                        <h5>Credit Card Information</h5>
                        <div id="card-element" class="form-control" style="height: 34px; padding: 8px;"></div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <BaseCheckbox 
                            design="inline"
                            v-model="default_payment_method"
                            placeholder="Set this as your default payment method"
                            id="default_payment_method"
                        />
                    </div>
                </div>
                <div class="row mt-3" v-if="payment_method === 'card' || payment_method === 'us_bank_account'">
                    <div class="col-12">
                        <button class="btn btn-primary btn-lg" disabled v-if="page.paymentProcessing"><i class="fa fa-spin fa-spinner"></i> Processing...</button>
                        <button class="btn btn-primary btn-lg" @click="addPaymentMethod" v-else>Save Changes</button>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import billingRepo from '@/repositories/employer/billing';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        methods: {
            type: Array,
            default: []
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        }
    },
    setup(props, { emit }) {
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            elements: null,
            errorMessage: '',
            default_payment_method: '',
            paymentProcessing: ref(false)
        });
        const { insertPaymentMethod, status, errors, setDefaultPaymentMethod, getBillingDetails, detachPaymentMethod, billing } = billingRepo();
        const payment_method = ref('card');
        const stripe = ref({});
        const cardElement = ref({});
        const default_payment_method = ref(false);        

        const close = () => {
            emit('close')
        }
        
        const addPaymentMethod = async () => {
            let formData = new FormData();

            const { paymentMethod, error } = await stripe.value.createPaymentMethod(
                'card', cardElement.value, {
                    billing_details: {
                        name: `${billing.value.fname} ${billing.value.lname}`,
                        email: billing.value.email,
                        address: {
                            line1: billing.value.address,
                            city: billing.value.city,
                            state: billing.value.state,
                            postal_code: billing.value.zip_code
                        }
                    }
                }
            );

            if(error === undefined) {
                page.paymentProcessing = true;
            }

            formData.append('payment_method_id', paymentMethod.id);
            formData.append('pm_type', paymentMethod.type);
            formData.append('pm_last_four', paymentMethod.card?.last4);

            formData.append('user_id', page.authuser.id);
            formData.append('fname', billing.fname);
            formData.append('lname', billing.lname);
            formData.append('email', billing.email);
            formData.append('default_payment_method', default_payment_method.value);

            await insertPaymentMethod(page, formData);
            if(status.value == 200) {
                emit('setDefault');
                page.paymentProcessing = false;
                close();
            }
        }

        onMounted( async () => {
            await getBillingDetails(page);
        });

        watchEffect( async () => {
            stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
            page.elements = stripe.value.elements();
            cardElement.value = page.elements.create('card', {
                hidePostalCode: true
            });
            cardElement.value.mount('#card-element');
        })

        return {
            page,
            close,
            addPaymentMethod,
            status,
            errors,
            payment_method,
            stripe,
            cardElement,
            insertPaymentMethod,
            default_payment_method,
            setDefaultPaymentMethod,
            getBillingDetails,
            billing,
            detachPaymentMethod
        }
    }
})
</script>

<style>
.inline-flex {
    display: flex !important;
    margin-right: 1rem !important;
    align-items: center;
}
</style>