<template>
    <div class="settings-panel" :class="{ 'is-open': isOpen }">
        <div class="settings-content">
            <div class="settings-title align-items-center">
                <button type="button" class="btn-close float-right js-settings-toggle" aria-label="Close" @click="closeReminder(0)"></button>
                <h4 class="mb-0 ms-2 d-inline-block">Reminders</h4>
                <div class="float-end">
                    <div class="dropdown position-relative">
                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                            <i class="fas fa-ellipsis-h"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-end">
                            <button class="dropdown-item" @click="readAllNotification">Mark all reminders as read</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings-body settings-body-notification">
                <div class="mb-3" v-if="reminders.length">
                    <div v-for="reminder in reminders" :key="reminder.id">
                        <router-link :to="{ name: reminder.link, query: { tab: 'learning-plan-assign' } }" class="list-group-item link-text" :class="{ 'link-unread' : reminder.status == 'Unread' }" @click="closeReminder(reminder.id)" style="margin: 5px 0;">
                            <div class="row g-0 align-items-center">
                                <div class="col-2 pl-10">
                                    <i :class="reminder.icon"></i>
                                </div>
                                <div class="col-10">
                                    <div class="text-dark"><b>{{ reminder.message_text }}</b></div>
                                    <div class="text-muted small mt-1">{{ reminder.message }}</div>
                                    <div class="text-muted small mt-1">{{ reminder.created_at_display }}</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div v-if="reminders.length" v-observe-visibility="handleScrolledToBottom"></div>
                    <div class="loading-animation" v-if="page.isLoading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-else>
                    <div class="py-5 text-center">
                        <h5>No reminders available</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, watch, reactive } from 'vue';
import notifRepo from '@/repositories/settings/notification';

export default defineComponent({
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const { reminders, getReminders, updateNotificationToRead } = notifRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            currentPage: 1,
            lastPage: 1,
            isLoading: false
        });

        const handleScrolledToBottom = async (isVisible) => {
            if(!isVisible) { return }
            if(page.currentPage >= page.lastPage) { return }

            page.isLoading = true;
            page.currentPage++;
            await getReminders(page);
        }

        const closeReminder = async (id) => {
            emit('close');
            if(id) {
                reminders.value = [];
                page.currentPage = 1;
                await updateNotificationToRead(page, 'Reminder', id);
            }
        }

        const readAllNotification = async () => {
            reminders.value = [];
            page.currentPage = 1;
            await updateNotificationToRead(page, 'Reminder');
        }

        watch(() => props.isOpen, async () => {
            reminders.value = [];
            if(props.isOpen == true) {
                await getReminders(page);
            }
        });

        return {
            closeReminder,
            page,
            reminders,
            getReminders,
            handleScrolledToBottom,
            readAllNotification
        }
    }
})
</script>

<style>
.is-open {
    right: 0px !important;
}
.loading-animation {
    text-align: center;
    padding: 10px;
}
.pl-10 {
    padding-left: 10px !important;
}
.link-unread {
    background: #e0eafc !important;
}
.link-text {
    text-decoration: none;
}
.employer-hr-reminder-item {
    margin-bottom: 9px;
}
</style>