<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Manage Users</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <input
                                            v-model="page.querySearch"
                                            class="form-control"
                                            id="query_search"
                                            placeholder="Search Users"
                                            @input="searchUser"
                                        />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="d-flex justify-content-end">
                                        <div class="card-actions mt-2 text-bold">
                                            {{ page.rightLabel }}: <span style="color: #6e3cff;">{{ page.archivedUsers.length ?? 0 }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <BaseTable
                                :data="page.archivedUsers"
                                :options="headers"
                            >
                                <template v-slot:counter="{ row }">
                                    <div class="text-center">
                                        {{ row.id }}
                                    </div>
                                </template>
                                <template v-slot:name="{ row }">
                                    <div style="width:200px;">
                                        {{ row.name }}
                                    </div>
                                </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item text-success" href="javascript:;" @click="attemptReactivateAccount(row.id)">Reactivate Account</a></li>
                                                <li v-if="row.role == 'Candidate'"><a class="dropdown-item text-danger" href="javascript:;" @click="attemptDeleteAccount(row.id)">Delete Permanently</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmDeletion
            :confirmTitle="`Delete Account Confirmation`"
            :is-confirm="isConfirm"
            :confirmButton="`Confirm Account Deletion`"
            :confirm-text="`Are you absolutely sure you want to delete this user? Once deleted, all of it's data cannot be recovered.`"
            @cancel="toggleConfirmation"
            @isDelete="permanentlyWipeOutUser"
        />
    </main>
</template>

<script>
import { useRouter } from 'vue-router';
import PageLoader from '@/components/modules/PageLoader.vue';
import { defineComponent, reactive, onMounted, ref, inject } from 'vue';
import BootstrapBaseTable from '@/components/modules/Datatable.vue';
import ConfirmDeletion from '@/components/modules/ConfirmationTextBox.vue';
import userRepo from '@/repositories/admin/users';
import _debounce from 'lodash/debounce';

export default defineComponent({
    setup() {
        const toast = inject('toast');
        const router = useRouter();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            token: localStorage.getItem('token'),
            isLoading: true,
            idToImpersonate: '',
            userType: '',
            viewOption: '/admin/settings/users/archived',
            archivedUsers: [],
            filterBy: 1,
            querySearch: '',
            rightLabel: 'Total Archived Users',
            idToDelete: ''
        });

        const { errors, message, archived_users, getArchivedUsers, permanentlyDestroyUser, reactivateUser } = userRepo();
        const isConfirm = ref(false);
        const headers = [
            {
                key: 'counter',
                label: 'ID Number',
                class: 'text-center'
            },
            {
                key: 'name',
                label: 'Fullname'
            },
            {
                key: 'email',
                label: 'Email'
            },
            {
                key: 'role',
                label: 'Role'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        onMounted( async () => {
            await getArchivedUsers(page);
            page.archivedUsers = archived_users.value;
        });

        const ChangeView = () => {
            router.push(`${page.viewOption}`);
        }

        const searchUser = _debounce(function (event) {
            page.archivedUsers = page.archivedUsers.filter(item => (item.name.toLowerCase().includes(event.target.value.toLowerCase())));

            // Try to search by email
            if(!page.archivedUsers.length) {
                page.archivedUsers = archived_users.value.filter(item => (item.email.toLowerCase().includes(event.target.value.toLowerCase())));
            }
            if(event.target.value != '') {
                page.rightLabel = 'Search Count';
            } else {
                page.rightLabel = 'Total Archived Users';
                page.archivedUsers = archived_users.value;
            }
        }, 700);

        const attemptDeleteAccount = (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const attemptReactivateAccount = async (id) => {
            page.isLoading = true;
            await reactivateUser(id);
            toast.success(message.value);
            page.querySearch = '';
            await getArchivedUsers(page);
            page.archivedUsers = archived_users.value;
        }

        const permanentlyWipeOutUser = async () => {
            toggleConfirmation();
            page.isLoading = true;
            await permanentlyDestroyUser(page.idToDelete);
            toast.success(message.value);
            page.querySearch = '';
            await getArchivedUsers(page);
            page.archivedUsers = archived_users.value;
        }

        return {
            headers,
            page,
            toggleConfirmation,
            isConfirm,
            ChangeView,
            searchUser,
            permanentlyWipeOutUser,
            attemptDeleteAccount,
            attemptReactivateAccount
        }
    },
    components: {
        PageLoader,
        BootstrapBaseTable,
        ConfirmDeletion
    }
});
</script>

<style scoped>
.w-3 {
    width: 3% !important;
}
.w-10 {
    width: 10% !important;
}
</style>