<template>
    <div>
        <transition name="fade">
            <div v-show="isConfirm" class="modal show confirm-modal" style="display: block">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="alert alert-primary alert-dismissible modal-content" role="alert">
                        <button type="button" class="btn-close" @click="cancel"></button>
                        <div class="alert-message">
                            <h4 class="alert-heading" v-text="confirmTitle"></h4>
                            <p v-text="confirmText"></p>
                            <hr />
                            <div class="btn-list" v-if="confirmType=='default'">
                                <button class="btn btn-danger mr-5" type="button" @click="confirmDelete" v-text="confirmButton" :disabled="isButtonDisabled"></button>
                                <button class="btn btn-light" type="button" @click="cancel">Cancel</button>
                            </div>
                            <div class="btn-list" v-if="confirmType=='subscription'">
                                <button class="btn btn-success mr-5" type="button" @click="confirmDelete" v-text="confirmButton" :disabled="isButtonDisabled"></button>
                                <button class="btn btn-light" type="button" @click="cancel">No, thanks</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div v-if="isConfirm" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import { defineComponent, watchEffect } from 'vue';

export default defineComponent({
    emits: ['isDelete','cancel'],
    props: {
        isConfirm: {
            type: Boolean,
            default: false
        },
        confirmText: {
            type: String,
            default: ''
        },
        confirmTitle: {
            type: String,
            default: 'Confirmation!'
        },
        confirmButton: {
            type: String,
            default: 'Confirm'
        },
        confirmType: {
            type: String,
            default: 'default'
        },
        isButtonDisabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const confirmDelete = () => {
            emit('isDelete');
        }

        const cancel = () => {
            emit('cancel');
        }

        watchEffect(() => {
            if(props.isConfirm) {
                document.addEventListener('keyup', function (evt) {
                    if (evt.keyCode === 27) {
                        cancel();
                    }
                });
            }
        });

        return {
            confirmDelete,
            cancel
        }
    },
})
</script>

<style scoped>
.confirm-modal {
    width: 100%;
    z-index: 2000 !important;
}
.mr-5 {
    margin-right: 5px;
}
.alert {
    border: solid 1px #ccc;
}
</style>
