<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <div>
                        <h1 class="h3 mb-3">Information when applying to {{ job.position_title }}</h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7 col-sm-12">
                    <div class="card">
                        <PageLoader v-if="state.isLoading" />
                        <div class="card-body" v-else>
                            <div v-if="is_notification">
                                <h4>{{ job.company?.company_name }} company background, culture, history and highlights.</h4>
                                <p class="mt-3">{{ job.company?.company_background_full }}</p>
                                <div class="my-5">
                                    <h4 class="mb-3">Some of the possible questions that {{ job.company?.company_name }} might ask during your interview.</h4>
                                    <p v-html="job.company_common_question"></p>
                                </div>
                                <div class="my-5">
                                    <h4 class="mb-3">Some of the possible questions that {{ job.company?.company_name }} might ask about the role of {{ job.position_title }} during your interview.</h4>
                                    <p v-html="job.company_relevant_question"></p>
                                </div>
                                <div class="my-5">
                                    <h4 class="mb-3">Some important aspects regarding {{ job.position_title }}.</h4>
                                    <p v-html="job.company_role_aspects"></p>
                                </div>
                            </div>
                            <div v-else>
                                <h4>We're still compiling the information for you. We will notify you when it's ready.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-5 col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label class="form-label">Do you know the interviewer's name?</label>
                                <input type="text" id="interviewer" v-model="state.interviewer" class="form-control">
                            </div>
                            <div class="form-group mt-3">
                                <button class="btn btn-primary">Generate Information</button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <ModalSubscription
            :modalActive="subscriptionActive"
            :application_id="state.application_id"
            :isBillingPage="true"
            :planToUpgrade="state.selectedPlan"
            @close="subscriptionActive == false"
            @render-research="renderResearch"
        />
    </main>
</template>

<script>
import { ref, reactive, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import jobRepo from '@/repositories/candidate/jobs';
import ModalSubscription from '@/views/candidate/jobs/applications/modals/ModalSubscription.vue';

export default {
    components: {
        ModalSubscription
    },
    setup(props) {
        const swal = inject('$swal');
        const route = useRoute();
        const { job, getJob, getResearchHelp, research, is_notification } = jobRepo();
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            selectedPlan: 'Premium',
            displayResearch: false,
            isLoading: true,
            jobId: 0,
            selectedPlan: '',
            interviewer: ''
        });
        const subscriptionActive = ref(false);
        const subscription_type  = ref('Premium');

        const renderResearch = (isSubscribe) => {
            subscriptionActive.value = false;
            if(isSubscribe) {
                swal({
                    title: "Gathering information...",
                    text: "One moment while we compile this information for you. We'll notify you when it's ready.",
                    imageUrl: `${process.env.VUE_APP_UPCLOUD_ASSETS}/images/loading.gif`,
                    imageWidth: 100,
                    imageHeight: 100
                })
            }
        }

        onMounted( async () => {
            await getJob(route.params.hashid);
            state.jobId = job.value.id;
            await getResearchHelp(state);
            if(!research.value && !is_notification.value) {
                state.isLoading = true;
                subscriptionActive.value = true;
            }
        });

        return {
            state,
            job,
            research,
            is_notification,
            getJob,
            getResearchHelp,
            subscriptionActive,
            subscription_type,
            renderResearch
        }
    }
}
</script>