<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between mb-3">
                <h1 class="h3 mb-3">Manage Learning Plans</h1>
            </div>
            <div class="row">
                <div class="col-lg-5 col-12 mb-4">
                    <div class="btn-group">
                        <button class="btn btn-outline-primary" @click="changeTab('Premium')" :class="page.currentTab == 'Premium' ? 'active' : ''">Premium Learning Plans</button>
                        <button class="btn btn-outline-primary" @click="changeTab('Custom')" :class="page.currentTab == 'Custom' ? 'active' : ''">Basic Learning Plans</button>
                    </div>
                </div>
            </div>
            <div class="premium-tab card pt-4" v-if="page.currentTab == 'Premium'">
                <PageLoader v-if="page.isLoading" />
                <div v-else>
                    <div class="text-left px-4 mb-4">
                        <h3 class="text-primary">Premium Learning Plans <button class="btn btn-primary float-end" @click="AddPremiumPlan">Add Premium Plan</button></h3>
                    </div>
                    <div class="plan-wrapper">
                        <div class="row" v-if="premium_learning_plans">
                            <div class="col-12" v-for="learning_plan in premium_learning_plans" :key="learning_plan">
                                <div class="card-wrap" v-if="learning_plan.trainings?.length > 0">
                                    <div class="d-flex justify-content-between">
                                        <h3 class="mb-0">{{ learning_plan.learning_plan_name }}</h3>
                                        <div class="btn-group">
                                            <button class="btn btn-success btn-sm" @click="EditLearningPlan(learning_plan.learning_plan_id)"><i class="fa fa-pen fa-fw"></i> Manage</button>&nbsp;
                                            <button class="btn btn-danger btn-sm" @click="removeLearningplan(learning_plan.learning_plan_id)"><i class="fa fa-trash fa-fw"></i> Delete</button> 
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <div class="course-card me-2" v-for="training in learning_plan.trainings" :key="training">
                                            <div class="card card-link card-border">
                                                <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                                                <div class="card-body training-card">
                                                    <h5 class="card-title" v-text="training.title_limit"></h5>
                                                    <p class="card-text carousel-card-text">
                                                        {{ training.author ?? training.company?.company_name }}<br>
                                                        <b>{{ training.source?.name ?? 'Private Course' }}</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12">
                                <div class="text-center">
                                    <img src="/assets/images/empty.png" class="img-fluid img-30">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-tab card pt-4" v-if="page.currentTab == 'Custom'">
                <PageLoader v-if="page.isLoading" />
                <div v-else>
                    <div class="text-left px-4 mb-4">
                        <h3 class="text-primary">Basic Learning Plans <button class="btn btn-primary float-end" @click="AddCustomPlan">Add Basic Plan</button></h3>
                    </div>
                    <div class="plan-wrapper">
                        <div class="row" v-if="custom_learning_plans">
                            <div class="col-12" v-for="learning_plan in custom_learning_plans" :key="learning_plan">
                                <div class="card-wrap" v-if="learning_plan.trainings?.length">
                                    <div class="d-flex justify-content-between">
                                        <h3 class="mb-0">{{ learning_plan.learning_plan_name }}</h3>
                                        <div class="btn-group">
                                            <button class="btn btn-success btn-sm" @click="EditLearningPlan(learning_plan.learning_plan_id)"><i class="fa fa-pen fa-fw"></i> Manage</button>&nbsp;
                                            <button class="btn btn-danger btn-sm" @click="removeLearningplan(learning_plan.learning_plan_id)"><i class="fa fa-trash fa-fw"></i> Delete</button> 
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <div class="course-card me-2" v-for="training in learning_plan.trainings" :key="training">
                                            <div class="card card-link card-border">
                                                <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                                                <div class="card-body training-card">
                                                    <h5 class="card-title" v-text="training.title_limit"></h5>
                                                    <p class="card-text carousel-card-text">
                                                        {{ training.author ?? training.company?.company_name }}<br>
                                                        <b>{{ training.source?.name ?? 'Private Course' }}</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12">
                                <div class="text-center">
                                    <img src="/assets/images/empty.png" class="img-fluid img-30">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalLearningPlan 
            :modalActive="modalActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            :modalButton="page.modalButtonText"
            :learningPlanType="page.learningPlanType"
            @close="toggleModal"
            @reloadPage="save"
        />
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this learning plan?`"
            @cancel="toggleConfirmation"
            @isDelete="processDeleteLearningPlan"
        />
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue';
import ModalLearningPlan from '@/views/admin/trainings/ModalLearningPlan.vue';
import learningPlanRepo from '@/repositories/admin/learningplans';

export default defineComponent({
    setup() {
        const { learning_plans, learning_plan, getCustomLearningPlans, getPremiumLearningPlans, deleteLearningPlan, getLearningPlan } = learningPlanRepo();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            currentTab: 'Premium',
            modalHeader: 'Add Premium Learning Plan',
            modalButtonText: 'Add Learning Plan',
            learningPlanType: 'premium',
            learningPlanId: ''
        });
        const modalActive = ref(false);
        const isConfirm =  ref(false);
        const premium_learning_plans = ref('');
        const custom_learning_plans = ref('');

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const changeTab = async (tab) => {
            page.isLoading = true;
            page.currentTab = tab;
            await reloadPlans();
        }

        const save = async () => {
            toggleModal();
            reloadPlans();
        }

        const AddPremiumPlan = () => {
            page.modalHeader = 'Add Premium Learning Plan';
            page.modalButtonText = 'Add Premium Learning Plan';
            page.learningPlanType = 'premium';
            toggleModal();
        }

        const AddCustomPlan = () => {
            page.modalHeader = 'Add Basic Learning Plan';
            page.modalButtonText = 'Add Basic Learning Plan';
            page.learningPlanType = 'custom';
            toggleModal();
        }

        const EditLearningPlan = async (id) => {
            page.modalHeader = 'Manage this Learning Plan';
            page.modalButtonText = 'Update Learning Plan';
            await getLearningPlan(id);
            toggleModal();
        }

        const removeLearningplan = (id) => {
            page.learningPlanId = id;
            toggleConfirmation();
        }

        const processDeleteLearningPlan = async () => {
            toggleConfirmation();
            await deleteLearningPlan(page.learningPlanId);
            await reloadPlans();
        }

        const reloadPlans = async () => {
            if(page.currentTab == 'Premium') {
                await getPremiumLearningPlans(page);
                premium_learning_plans.value = learning_plans.value;
                custom_learning_plans.value = '';
            }
            if(page.currentTab == 'Custom') {
                await getCustomLearningPlans(page);
                custom_learning_plans.value = learning_plans.value;
                premium_learning_plans.value = '';
            }
        }

        onMounted( async () => {
            await reloadPlans();
        });

        return {
            page,
            premium_learning_plans,
            custom_learning_plans,
            changeTab,
            toggleModal,
            modalActive,
            save,
            AddPremiumPlan,
            AddCustomPlan,
            learning_plan,
            isConfirm,
            toggleConfirmation,
            removeLearningplan,
            processDeleteLearningPlan,
            EditLearningPlan
        }
    },
    components: {
        ModalLearningPlan
    }
});
</script>
<style scoped>
.card-wrap {
    border: 1px solid #e5e5e5;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
    background: #fbfbfb;
    box-shadow: 1px 0px 10px 0px #6e6e6e42;
}
.card-border {
    border: 1px solid #ccc !important;
}
.training-card {
    border-radius: 5px;
    padding: 10px 10px;
}
.plan-wrapper {
    padding: 5px 23px 0;
}
h5.card-title {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.course-card { display: inline-block; }
.shadow {
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.05)!important;
}
.card-header-items {
    height: 105px;
}
.card-link {
    cursor: pointer;
    width: 240px;
}
</style>