<template>
    <div>
        <button v-if="isPressed" type="button" :class="className" disabled>
            <i class="fa fa-spinner fa-spin fa-fw"></i> &nbsp; Processing...
        </button>
        <button v-else type="button" @click="btnPressed" :class="className" v-html="btnValue" :disabled="isDisabled"></button>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';

export default {
    props: {
        className: {
            type: String,
            default: 'btn btn-primary'
        },
        btnValue: {
            type: String,
            default: 'Save Changes'
        },
        isSaved: {
            type: Boolean,
            default: false
        }, 
        defaultBtn: {
            type: Boolean,
            default: false
        }, 
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const isPressed = ref(props.defaultBtn);

        watchEffect(() => {
            if(props.isSaved) {
                isPressed.value = false;
            }
            if(props.defaultBtn) {
                isPressed.value = true;
            } else {
                isPressed.value = false;
            }
        })

        const btnPressed = () => {
            isPressed.value = true;
            emit('btnClicked');
        }

        return {
            isPressed,
            btnPressed
        }
    },
}
</script>
<style>
button.btn.disabled, button.btn:disabled {
    cursor: not-allowed !important;
    pointer-events: auto;
}
</style>