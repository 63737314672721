<template>
    <label class="form-check" v-if="design === 'normal'">
        <input class="form-check-input" type="radio" :value="placeholder" :checked="modelValue === placeholder" @change="updateInput" v-bind="$attrs" />
        <span class="form-check-label">
            {{ label }}
        </span>
    </label>
    <label class="form-check form-check-inline" v-else>
        <input class="form-check-input" type="radio" name="inline-radios-example" :value="placeholder" :checked="modelValue === placeholder" @change="updateInput" v-bind="$attrs" />
        <span class="form-check-label">
            {{ placeholder }}
        </span>
    </label>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: Object,
            default: {}
        },
        design: {
            type: String,
            default: 'inline'
        }
    },
    setup(props, { emit }) {
        const hasValue = ref([]);
        const updateInput = (event) => {
            let id = event.target.id;
            if(event.target.value) {
                hasValue.value[id] = true;
                props.errors[id] = null;
            } else {
                hasValue.value[id] = false;
            }
            emit('update:modelValue', event.target.value);
        }

        return {
            updateInput,
            hasValue
        }
    }
})
</script>