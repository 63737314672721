<template>
    <div class="tab-pane fade show active" id="account" role="tabpanel">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0">Personal Information</h5>
            </div>
            <div class="card-body">
                <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                <div class="row">
                    <div class="col-4">
                        <BaseInput 
                            v-model="request.fname"
                            label="First Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.fname }"
                            id="fname"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-4">
                        <BaseInput 
                            v-model="request.mname"
                            label="Middle Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.mname }"
                            id="mname"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-4">
                        <BaseInput 
                            v-model="request.lname"
                            label="Last Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.lname }"
                            id="lname"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <BaseInput 
                            v-model="request.email"
                            label="Email Address"
                            type="email"
                            :class="{ 'is-invalid' : errors && errors.email }"
                            id="email"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-4">
                        <BaseInput 
                            v-model="request.contact_number"
                            label="Contact Number"
                            type="text"
                            id="contact_number"
                        />
                    </div>
                    <div class="mb-3 col-md-4">
                        <label class="form-label">Gender</label>
                        <div>
                            <BaseRadio
                                placeholder="Male"
                                v-model="request.gender"
                            />
                            <BaseRadio
                                placeholder="Female"
                                v-model="request.gender"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <BaseInput 
                            v-model="request.address"
                            label="Address"
                            type="text"
                            id="address"
                            placeholder="House #, Street #"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <BaseInput 
                            v-model="request.city"
                            label="City"
                            type="text"
                            id="city"
                        />
                    </div>
                    <div class="col-md-4">
                        <BaseInput 
                            v-model="request.state"
                            label="State"
                            type="text"
                            id="state"
                        />
                    </div>
                    <div class="col-md-2">
                        <BaseInput 
                            v-model="request.zip_code"
                            label="Zip"
                            type="text"
                            id="zip_code"
                        />
                    </div>
                </div>
                <BaseButton :is-saved="status === 200" @btnClicked="saveUser" />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import resumeRepo from '@/repositories/candidate/resume';

export default defineComponent({
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const { updateUser, errors, status } = resumeRepo();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser'))
        });
        const request = reactive({
            fname: props.user?.name?.first ?? page.authuser.fname,
            mname: props.user?.name?.middle ?? page.authuser.mname,
            lname: props.user?.name?.last ?? page.authuser.lname,
            email: props.user?.emails !== null ? props.user?.emails[0] : page.authuser.email,
            gender: page.authuser.gender,
            contact_number: props.user?.phoneNumbers !== null ? props.user?.phoneNumbers[0] : page.authuser.contact_number,
            address: props.user?.location?.rawInput ?? page.authuser.address,
            city: props.user?.location?.city ?? page.authuser.city,
            state: props.user?.location?.state ?? page.authuser.state,
            zip_code: props.user?.location?.postalCode ?? page.authuser.zip_code
        });

        const saveUser = async () => {
            status.value = '';
            let formData = new FormData();
            formData.append('fname', request.fname ?? '');
            formData.append('mname', request.mname ?? '');
            formData.append('lname', request.lname ?? '');
            formData.append('email', request.email ?? '');
            formData.append('gender', request.gender ?? '');
            formData.append('contact_number', request.contact_number ?? '');
            formData.append('address', request.address ?? '');
            formData.append('city', request.city ?? '');
            formData.append('state', request.state ?? '');
            formData.append('zip_code', request.zip_code ?? '');
            formData.append('_method', 'PUT');
            await updateUser(formData, page.authuser.id);
        }

        return {
            page,
            errors,
            status,
            saveUser,
            request
        }
    }
})
</script>