<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-12">
                        <BaseInput 
                            v-model="form.question"
                            label="Question"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.question }"
                            id="question"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-12" v-if="categories.length">
                        <label for="categories" class="form-label mt-3 mb-1">Question Categories</label>
                        <multiselect
                            id="categories"
                            v-model="form.categories"
                            :options="categories"
                            placeholder="Enter a category"
                            label="category_title"
                            track-by="id"
                            tag-placeholder="Click to add category"
                            :multiple="true"
                            :taggable="false"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hideSelected="true"
                        ></multiselect>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {}
        },
        categories: {
            type: [Object, Array],
            default: []
        }
    },
    setup(props, { emit }) {

        const close = () => {
            emit('close')
        }
        
        const saveChanges = () => {
            let formData = {
                question: props.form.question ?? '',
                category_ids: (getCategoryIds(props.form.categories).join(',')) ?? null,
            }
            emit('saveChanges', formData);
        }

        const getCategoryIds = (arr) => {
            let a = [];
            if(arr) {
                arr.forEach(item => {
                    a.push(item.id)
                });
            }
            return a;
        }

        return {
            close,
            saveChanges
        }
    }
})
</script>