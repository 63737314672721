import axios from "axios";
import { ref, inject } from "vue";

export default function jobRepo() {

    const jobs = ref([]);
    const job  = ref([]);
    const research  = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const urlRedirect = ref('');
    const is_notification = ref(false);
    const toast = inject('toast');

    const getJobs = async (page) => {
        let response = await axios.get(`candidate/jobs?user_id=${page.authuser.id}`);
        jobs.value = [...response.data.promoted, ...response.data.nonpromoted];
        page.isLoading = false;
    }

    const getJob = async (id, candidate_id = "") => {
        if(candidate_id) {
            let response = await axios.get(`candidate/jobs/${id}?candidate_id=${candidate_id}`);
            job.value = response.data.job;
        } else {
            let response = await axios.get(`candidate/jobs/${id}`);
            job.value = response.data.job;
        }
    }

    const saveJob = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alertMessage(response.data.message);
            message.value = response.data.message;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const validateJob = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/validate`, data);
            status.value = response.data.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const destroyJob = async (id) => {
        let response = await axios.delete(`candidate/jobs/${id}`);
        alertMessage(response.data.message);
        message.value = response.data.message;
    }

    const alertMessage = (alert) => {
        toast.success(alert);
    }

    const searchJobtile = async (page, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/search`, data);
            jobs.value = response.data.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const searchByJobType = async (page, types) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/search`, {
                job_type: types,
                filter: parseInt(page.filter),
                country_id: parseInt(page.country_id),
                company_id: page.authuser.company_id,
                searchquery: page.searchquery,
                user_id: page.authuser.id
            });
            jobs.value = response.data.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const searchByCountry = async (page, country_id) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/search`, {
                country_id: country_id,
                filter: 3,
                company_id: page.authuser.company_id ?? '',
                user_id: page.authuser.id,
                searchquery: page.searchquery
            });
            jobs.value = response.data.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const searchByLocation = async (page, location_ids) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/search`, {
                location_role_id: location_ids,
                filter: parseInt(page.filter),
                country_id: parseInt(page.country_id),
                company_id: page.authuser.company_id ?? '',
                user_id: page.authuser.id,
                searchquery: page.searchquery
            });
            jobs.value = response.data.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const listedJob = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/listed`, data);

            alertMessage(response.data.message);
            message.value = response.data.message;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const jobsaves = async (page) => {
        let response = await axios.get(`candidate/jobs/listed?user_id=${page.authuser.id}`);
        jobs.value = response.data.data;
        job.value = (response.data.job !== undefined) ? response.data.job : [];
        page.isLoading = false;
    }

    const unsavedJob = async (page, id) => {
        let response = await axios.delete(`candidate/jobs/unsave?user_id=${page.authuser.id}&job_id=${id}`);
        job.value = response.data.job;
    }

    const getResearchHelp = async (page) => {
        let response = await axios.get(`candidate/jobs/research-help?user_id=${page.authuser.id}&job_id=${page.jobId}`);
        research.value = response.data.is_available;
        is_notification.value = response.data.is_notification_sent;
        localStorage.setItem('authuser', JSON.stringify(response.data.user));
        page.isLoading = false;
    }

    const storeSingleApplication = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/research-help`, data);
            status.value = response.status;
            urlRedirect.value = response.data.checkout.url;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateSingleResearchApplication = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/jobs/research-help-update`, data);
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getPersonalResearchHelp = async (page) => {
        let response = await axios.get(`candidate/jobs/research-help-personal?user_id=${page.authuser.id}`);
        research.value = response.data.data;
    }

    return {
        jobs,
        job,
        research,
        getJobs,
        getJob,
        saveJob,
        destroyJob,
        errors,
        message,
        status,
        validateJob,
        alertMessage,
        searchJobtile,
        searchByJobType,
        searchByCountry,
        listedJob,
        jobsaves,
        unsavedJob,
        searchByLocation,
        getResearchHelp,
        storeSingleApplication,
        urlRedirect,
        updateSingleResearchApplication,
        getPersonalResearchHelp,
        is_notification
    }
}