<template>
    <div>
        <h3>Basic Information</h3>
        <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
        <div class="row">
            <div class="col-6">
                <BaseSelect
                    v-model="form.category_id"
                    label="Job Category"
                    :class="{ 'is-invalid' : errors && errors.category_id }"
                    id="category_id"
                    :errors="errors"
                    :options="jobCategory"
                    :option-value="`category`"
                    :option-id="`id`"
                    :selected="1"
                />
            </div>
            <div class="col-6">
                <div class="d-flex align-items-center" style="height: 80px;">
                    <div class="form-check form-switch job-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="promote_job" v-model="state.promote_job" @click="handleSwitch" :disabled="!state.authuser?.company.account_type || state.authuser?.company.account_type == 'Basic'">
                        <div class="job-switch-label">
                            <label class="form-check-label promot-job-post" for="promote_job" @mouseover="promoteTooltip=true" @mouseleave="promoteTooltip=false" v-if="!state.authuser?.company.account_type || state.authuser?.company.account_type == 'Basic'">Promote job post</label>
                            <label class="form-check-label promot-job-post" for="promote_job" v-else>Promote job post</label>
                            <span class="tooltip-box" v-show="promoteTooltip" @mouseover="promoteTooltip=true" v-if="!state.authuser?.company.account_type || state.authuser?.company.account_type == 'Basic'">
                                <span class="no-decor" @click="openModalSubscription">Upgrade Account</span> to enable this feature.
                            </span>
                            <span class="tooltip-box" v-show="promoteTooltip" @mouseover="promoteTooltip=true" v-else>You have reached the limit for Premium.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <BaseInput
                    v-model="form.position_title"
                    label="Position Title"
                    type="text"
                    :class="{ 'is-invalid' : errors && errors.position_title }"
                    id="position_title"
                    :errors="errors"
                    is-required
                />
            </div>
            <div class="col-6">
                <div class="form-group mb-3">
                    <label for="job_expiry" class="form-label" :class="{ 'text-danger font-weight-bold' : form.is_expired }">Expiration Date <span class="text-danger">*</span></label>
                    <Datepicker
                        v-model="form.job_expiry"
                        :class="{ 'dp-is-invalid' : errors && errors.job_expiry }"
                        format="MM/dd/yyyy"
                        datePicker
                        :minDate="state.minimumDate"
                        :placeholder="`Date (MM/DD/YYYY)`"
                        textInput
                    />
                    <label class="error jquery-validation-error small form-text invalid-feedback show-error" v-if="errors && errors.job_expiry">{{ errors.job_expiry[0] }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <BaseInput
                    v-model="form.city"
                    label="City"
                    type="text"
                    id="city"
                />
            </div>
            <div class="col-6">
                <BaseInput
                    v-model="form.state"
                    label="State"
                    type="text"
                    id="state"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group mb-3" type="text">
                    <label for="city" class="form-label">Language</label>
                    <multiselect
                        id="language"
                        v-model="state.languages"
                        :options="languages"
                        placeholder="Ex. English"
                        label="name"
                        track-by="id"
                        tag-placeholder="Click to add language"
                        :multiple="true"
                        :taggable="false"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :hideSelected="true"
                    ></multiselect>
                </div>
            </div>
            <div class="col-6">
                <BaseSelect
                    v-model="form.country_id"
                    label="Country"
                    :class="{ 'is-invalid' : errors && errors.country_id }"
                    id="country_id"
                    :errors="errors"
                    is-required
                    :options="countries"
                    :option-value="`nicename`"
                    :option-id="`id`"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <BaseSelect
                    v-model="state.location_role_id"
                    label="Which option best describes this role's location?"
                    id="location_role_id"
                    :options="location_roles"
                />
            </div>
            <div class="col-6">
                <BaseSelect
                    v-model="form.status"
                    label="Status"
                    :class="{ 'is-invalid' : errors && errors.status }"
                    id="status"
                    :errors="errors"
                    is-required
                    :options="statuses"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, watch, watchEffect, onBeforeUpdate } from 'vue';
import moment from 'moment';
import globalRepo from '@/repositories/global';
import jobRepo from '@/repositories/jobs';
import jobPromotionBoostRepo from '@/repositories/employer/jobPromotionBoost';

export default {
    props: {
        form: {
            type: Object,
            default: {}
        },
        errors: {
            type: Object,
            default: {}
        },
        activatePromoteOption: {
            type: Boolean,
            default: false
        },
        subscriptionPlan: {
            type: String,
            default: ""
        },
        totalPromotedJob: {
            type: Number,
            default: 0
        },
        promotedJobAvailable: {
            type: Number,
            default: 0
        },
        promoteJobSwitch: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem("authuser")),
            isPromoteJob: false,
            minimumDate: new Date(),
            languages: [],
            maximumDate: '',
            location_role_id: '',
            promote_job: false
        });
        const { countries, getCountries, languages, getLanguages, location_roles, subscription_type } = globalRepo();
        const { jobCategory, getJobCategory } = jobRepo();
        const { currentJobPromotionBoost, getCurrentJobPromotionBoost } = jobPromotionBoostRepo();
        const promoteTooltip = ref(false);
        const modalHeaderContent = ref('');
        const jobPromotionBoostActive = ref(false);
        const statuses = [
            { id: "Active", name: "Active" },
            { id: "Inactive", name: "Inactive" }
        ];

        const openModalSubscription = () => {
            emit("toggle-subscription");
        }

        const handleSwitch = () => {
            state.isPromoteJob = props.form.promote_job;
            if (props.promotedJobAvailable > 0) {
                const currentDate = moment().format('YYYY-MM-DD')

                // Check first if there's available credits for this employer
                if(props.promotedJobAvailable > 0) {
                    return true;
                } else {
                    if (currentDate > currentJobPromotionBoost.value[0].promotion_end_date) {
                        modalHeaderContent.value = 'Your promoted jobs boost has expired.';
                        jobPromotionBoostActive.value = !jobPromotionBoostActive.value;
                    }
                }
            } else {
                if(state.authuser?.company.account_type != 'Basic') {
                    modalHeaderContent.value = 'Need to promote more jobs? Purchase 5 more below:';
                    jobPromotionBoostActive.value = !jobPromotionBoostActive.value;
                }
            }

            let responseInfo = {
                modalHeader: modalHeaderContent.value,
                promotionBoostActive: jobPromotionBoostActive.value
            }

            emit('handle-switch', responseInfo);
        }

        onMounted(() => {
            getCountries();
            getLanguages();
            getJobCategory();
            if(state.authuser.role_id == 2) {
                getCurrentJobPromotionBoost(state);
            }
        });

        watchEffect(() => {
            state.promote_job = props.form.id ? (props.form.promote == 1) : props.promoteJobSwitch;
            if(props.form.id) {
                state.languages = props.form.languages;
                state.location_role_id = props.form.location_role_id;
            } else {
                props.form.status = 'Active';
            }
        });

        onBeforeUpdate(() => {
            jobPromotionBoostActive.value = props.promoteJobSwitch;
        });

        watch(() => state.languages, () => {
            const arr_languages = [];
            state.languages.filter(item => {
                arr_languages.push(item.id);
            });
            
            emit('emit-languages', arr_languages);
        });

        watch(() => state.location_role_id, () => {
            emit('emit-role_id', state.location_role_id);
        });

        watch(() => state.promote_job, () => {
            emit('emit-promote_job', state.promote_job);
        });

        return {
            state,
            countries,
            getCountries,
            languages,
            getLanguages,
            location_roles,
            promoteTooltip,
            statuses,
            jobCategory,
            getJobCategory,
            currentJobPromotionBoost,
            getCurrentJobPromotionBoost,
            openModalSubscription,
            handleSwitch,
            subscription_type,
            modalHeaderContent,
            jobPromotionBoostActive
        }
    }
}
</script>

<style scoped>
.tooltip-box {
    z-index: 1;
    border-radius: 5px;;
    border: 1px solid var(--bc-medium);
    background-color: hsl(0,0%,100%);
    box-shadow: 0 1px 3px hsla(0,0%,0%,0.06), 0 2px 6px hsla(0, 0%, 0%, 0.06), 0 3px 8px hsla(0, 0%, 0%, 0.09);
    position: absolute;
    right: 322px;
    top: 80px;
    z-index: 100;
    cursor: pointer;
    padding: 7px;
    width: 280px;
    text-align: center;
    left: 565px;
}
.no-decor {
    text-decoration: none!important;
    color: #3f80ea;
}
.job-switch {
    display: flex;
    width: 100%;
    height: 5px;
}
.job-switch-label {
    margin-left: 15px;
    display: flex;
    height: 20px;
    align-items: center;
}
</style>