<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="d-grid gap-2 col-10 mx-auto">
                    <div class="d-flex align-items-center">
                        <div class="mb-3" style="width: 100%; height: 700px;">
                            <vue-plyr>
                                <video
                                    id="player"
                                    controls
                                    crossorigin
                                    playsinline
                                    data-poster=""
                                    @play="onPlay"
                                    @pause="onPause"
                                >
                                    <source
                                        size="1080"
                                        :src="videoplayer.video"
                                        type="video/mp4"
                                    />
                                </video>
                            </vue-plyr>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="d-grid gap-2 col-10 mx-auto">
                    <h1 class="h3 mb-1">{{ training.title }}</h1>
                    <p>{{ training.created_at_display }}</p>
                    <div v-html="training.content"></div>
                </div>
            </div>
        </div>
    </main>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import trainingRepo from '@/repositories/admin/trainings';

const route = useRoute();
const { getTraining, training } = trainingRepo();
const videoplayer = JSON.parse(localStorage.getItem('videoPlayer'));

const onPlay = () => {
    console.log('test');
}

const onPause = () => {
    console.log('test');
}

onMounted( async () => {
    await getTraining(route.params.id);
});
</script>