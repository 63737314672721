<template>
    <div class="message-box-wrapper">
        <div id="message-box" class="message-box p-4">
            <div v-if="messages.length > 0">
                <div class="message-item" v-for="(message, i) in messages" :key="i">
                    <div :class="`message-${message.target}`">
                        <div :class="`message ${message.target}`">
                            <div class="image">
                                <img :src="message.image" class="rounded-circle">
                            </div>
                            <div class="message-content">
                                <div class="message-options" v-if="message.target=='to'">
                                    <a href="javascript:;" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-h"></i></a>
                                    <ul class="dropdown-menu">
                                        <li><a @click="deleteMessage(i, message.message_id)" class="dropdown-item text-danger" href="javascript:;">Delete Message</a></li>
                                    </ul>
                                </div>
                                {{ message.text }}
                                <div class="timestamp">{{ message.timestamp }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="say-hello"><i class="fa-regular fa-face-smile"></i> Say Hello!</div>
            </div>
        </div>
        <div id="new-message-prompt" :class="reactive_data.hasNewMessage ? 'has-message' : 'hidden'">
            <a href="javascript:;" @click="setHideNewMessagePrompt">New message</a>
        </div>
        <div class="overlay position-fixed full-screen-loader text-primary" v-if="reactive_data.isLoading">
            <i class="fa fa-spin fa-spinner fa-3x"></i>
        </div>
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this message?`"
            @cancel="toggleConfirmation"
            @isDelete="processDeleteMessage"
        />
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import Pusher from 'pusher-js';
import globalRepo from '@/repositories/global';
import conversationsRepo from '@/repositories/conversations';
export default defineComponent({
    props: {
        messages: {
            required: true,
            type: Array
        }
    },
    setup(props) {
        const { requestDeleteMessage } = conversationsRepo();
        const { pusher_messaging_data } = globalRepo();
        const reactive_data = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            hasNewMessage: false,
            messageId: '',
            messageIndex: '',
            isLoading: false
        });
        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const scroll_bottom = () => {
            let objDiv = document.getElementById('message-box');
            objDiv.scrollTop = objDiv.scrollHeight;
        }

        const setHideNewMessagePrompt = () => {
            reactive_data.hasNewMessage = false;
            scroll_bottom();
        }

        onMounted(() => {
            // TODO::Use Pusher actual key when deployed to production
            const pusher = new Pusher( pusher_messaging_data.appkey , {
                cluster: pusher_messaging_data.cluster
            });
            const channel = pusher.subscribe(pusher_messaging_data.channel);
            channel.bind(pusher_messaging_data.broadcast, data => {
                let target_data = JSON.parse(data.message_data);
                if((target_data.sender_id != reactive_data.authuser.id) && (target_data.user_id == reactive_data.authuser.id)){
                    props.messages.push(target_data);
                    reactive_data.hasNewMessage = true;
                }
                scroll_bottom();
            });
            scroll_bottom();
        });

        const deleteMessage = (index, message_id) => {
            reactive_data.messageId = message_id;
            reactive_data.messageIndex = index;
            toggleConfirmation();
        }

        const processDeleteMessage = async () => {
            toggleConfirmation();
            reactive_data.isLoading = true;
            if(reactive_data.messageId) {
                await requestDeleteMessage(reactive_data, reactive_data.authuser.role_display.toLowerCase());
                props.messages.splice(reactive_data.messageIndex, 1);
            }
        }

        return {
            reactive_data,
            scroll_bottom,
            setHideNewMessagePrompt,
            deleteMessage,
            toggleConfirmation,
            isConfirm,
            processDeleteMessage
        }
    }
})
</script>

<style>
    .say-hello {
        text-align: center;
        font-size: 30px;
        color: #d0d0d0;
        padding-top: 12%;
    }
    .message-box {
        height: 350px;
        overflow-x: auto;
    }
    .message-box .message .image {
        width: 50px;
    }
    .message-box .message .image img {
        width: 100%;
        height:50px;
    }
    .message-box .message {
        border-radius: 7px;
        padding: 15px 15px;
        display: inline-block;
    }
    .message-box > div {
        display: inline-block;
        width: 100%;
    }
    .message-box .message-from {
        text-align: left;
    }
    .message-box .message-to {
        text-align: right;
    }
    .message.from, .message.to {
        width: 45%;
    }
    .message-box .message .image {
        display: inline-block;
        vertical-align: middle;
    }
    .message-box .message .message-content {
        display: inline-block;
        vertical-align: middle;
        width:auto;
        max-width: 80%;
        padding: 15px 15px 15px 20px;
        margin-left: 15px;
        border-radius: 5px;
    }
    .message-box .message.from .message-content {
        background: #f1f1f1;
    }
    .message-box .message.to .message-content {
        background: #d7e6ff;
        text-align: left;
        position:relative;
    }
    .timestamp {
        font-size: 10px;
        margin-top: 10px;
        font-style: italic;
        color: #3f80ea;
    }
    .message-box-wrapper {
        position: initial;
    }
    div#new-message-prompt.hidden {
        display:none;
    }
    div#new-message-prompt.has-message {
        position: absolute;
        bottom: 90px;
        left: 55%;
    }
    #new-message-prompt a {
        padding: 3px 20px;
        color: #FFF;
        background: red;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .message-options {
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 9px;
        width: 20px;
        height: 20px;
        background: #1f9bcf;
        text-align: center;
        border-radius: 50%;
    }
    .message-options > a, .message-options > a:hover {
        color: #fff;
        font-size: 12px;
    }
    .full-screen-loader {
        z-index: 1000;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #4d4d4d40;
        text-align: center;
        padding: 19%;
    }
</style>