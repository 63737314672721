<template>
    <div>
        <button class="btn btn-primary" @click="openDiscountedCourseModal">Discounted Courses</button>
        <ModalDiscountedCourse
            :modalActive="modalActive"
            :form="state.form"
            :modalTitle="`Register a group account with simphini`"
            @close="modalActive = false"
            @reloadPage="save"
        />
    </div>
</template>

<script>
import ModalDiscountedCourse from '@/views/candidate/trainings/modals/RegisterModal';
import { reactive, ref } from '@vue/reactivity';

export default {
    components: {
        ModalDiscountedCourse
    },
    setup() {
        const state = reactive({
            form: {}
        });
        const modalActive = ref(false);
        const openDiscountedCourseModal = () => {
            modalActive.value = true;
        }

        const save = () => {

        }

        return {
            state,
            modalActive,
            openDiscountedCourseModal,
            save
        }
    },
}
</script>