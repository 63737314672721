<template>
    <div class="tab-pane fade show active" id="account" role="tabpanel">
        <div class="card mb-3 card-profile-visibility">
            <div class="card-header">
                <h5 class="card-title mb-0">Profile Visibility</h5>
            </div>
            <div class="card-body pt-0">
                <div class="row">
                <div class="col-md-12">
                    <BaseCheckbox :marginBottomOn="false" :disabled="page.isUpdateProfileVisibilityProcessing" design="inline" v-model="user.is_profile_visible"
                    placeholder="Show my full profile in public" id="is_profile_visible" @update:modelValue="setVisibility" />
                    <i v-if="page.isUpdateProfileVisibilityProcessing" class="fas fa-spinner fa-spin"></i>
                </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0">Personal Information</h5>
            </div>
            <div class="card-body">
                <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                <div class="row">
                    <div class="col-md-8">
                        <BaseInput 
                            v-model="user.fname"
                            label="First Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.fname }"
                            id="fname"
                            :errors="errors"
                            is-required
                        />
                        <BaseInput 
                            v-model="user.mname"
                            label="Middle Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.mname }"
                            id="mname"
                            :errors="errors"
                            is-required
                        />
                        <BaseInput 
                            v-model="user.lname"
                            label="Last Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.lname }"
                            id="lname"
                            :errors="errors"
                            is-required
                        />
                        <BaseInput 
                            v-model="user.email"
                            label="Email Address"
                            type="email"
                            :class="{ 'is-invalid' : errors && errors.email }"
                            id="email"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-md-4">
                        <div class="text-center">
                            <img :alt="page.authuser.name" :src="photo_url.url ? photo_url.url : page.authuser.display_photo" class="rounded-circle img-responsive mt-2" width="128" height="128" />
                            <div class="mt-2">
                                <a href="javascript:;" class="btn btn-primary btn-upload" @click="$refs.fileInput.click()"><i class="fas fa-upload"></i> Upload</a>
                            </div>
                            <small>For best results, use an image at least 128px by 128px in .jpg format</small>
                            <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors && errors.photo">{{ errors.photo[0] }}</label>
                        </div>
                    </div>
                </div>
                <input style="display: none" ref="fileInput" type="file" @change="onFileChange" enctype="multipart/form-data">
                <div class="row">
                    <div class="mb-3 col-md-3">
                        <label class="form-label">Gender</label>
                        <div>
                            <BaseRadio
                                placeholder="Male"
                                v-model="user.gender"
                            />
                            <BaseRadio
                                placeholder="Female"
                                v-model="user.gender"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <BaseInput 
                            v-model="user.contact_number"
                            label="Contact Number"
                            type="text"
                            id="contact_number"
                        />
                    </div>
                    <div class="col-md-5">
                        <label for="birthdate" class="form-label">Birth Date</label>
                        <Datepicker
                            id="birthdate"
                            v-model="user.birthdate" 
                            format="MM/dd/yyyy"
                            :enableTimePicker="false"
                            :range="false"
                            placeholder="Select Birthdate (MM/DD/YYYY)"
                            :modelValue="user.birthdate"
                            textInput
                        />
                    </div>
                </div>
                <div class="mb-3">
                    <BaseInput 
                        v-model="user.address"
                        label="Address"
                        type="text"
                        id="address"
                        placeholder="House #, Street #"
                    />
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <BaseInput 
                            v-model="user.city"
                            label="City"
                            type="text"
                            id="city"
                        />
                    </div>
                    <div class="col-md-3">
                        <BaseInput 
                            v-model="user.state"
                            label="State"
                            type="text"
                            id="state"
                        />
                    </div>
                    <div class="col-md-3">
                        <BaseInput 
                            v-model="user.zip_code"
                            label="Zip"
                            type="text"
                            id="zip_code"
                        />
                    </div>
                    <div class="col-md-3">
                        <BaseSelect
                            v-model="user.country"
                            label="Country"
                            id="country"
                            :options="countries"
                            :option-value="`nicename`"
                            :option-id="`nicename`"
                        />
                    </div>
                </div>
                <BaseButton :is-saved="status === 200" @btnClicked="saveUser" />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue';
import userRepo from '@/repositories/settings/users';
import globalRepo from '@/repositories/global';

export default defineComponent({
    setup() {
        const { user, getUser, updateUser, errors, codes, status, updatePassword } = userRepo();
        const { countries, getCountries } = globalRepo();

        const photo_url = reactive({ url: '' });
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            photoUpload: '',
            modalHeader: 'Confirm Password',
            isUpdateProfileVisibilityProcessing: false
        });

        onMounted(() => { 
            getCountries();
            getUser(page);
            page.isTwoFactorEnabled = (page.authuser.two_factor_confirmed_at != null);
        })

        const saveUser = async () => {
            status.value = '';
            let formData = new FormData();
            formData.append('fname', user.value.fname ?? '');
            formData.append('mname', user.value.mname ?? '');
            formData.append('lname', user.value.lname ?? '');
            formData.append('email', user.value.email ?? '');
            formData.append('photo', page.photoUpload ?? '');
            formData.append('id', user.value.id);
            formData.append('gender', user.value.gender ?? '');
            formData.append('contact_number', user.value.contact_number ?? '');
            formData.append('address', user.value.address ?? '');
            formData.append('city', user.value.city ?? '');
            formData.append('state', user.value.state ?? '');
            formData.append('zip_code', user.value.zip_code ?? '');
            formData.append('birthdate', user.value.birthdate ?? '');
            formData.append('country', user.value.country ?? '');
            formData.append('id', user.value.id);

            await updateUser(formData);
            page.authuser = JSON.parse(localStorage.getItem('authuser'))
            document.getElementById('avatar').src = page.authuser.display_photo;
        }

        const onFileChange = (e) => {
            let file = e.target.files[0];
            photo_url.url = URL.createObjectURL(file);
            page.photoUpload = file
        }

        const setVisibility = async () => {
            page.isUpdateProfileVisibilityProcessing = true;
            let formData = new FormData();
            formData.append('is_profile_visible', user.value.is_profile_visible ?? false);
            formData.append('id', user.value.id);
            await updateUser(formData, page);
            page.isUpdateProfileVisibilityProcessing = false;
        }

        return {
            user,
            getUser,
            onFileChange,
            page,
            photo_url,
            status,
            errors,
            saveUser,
            countries,
            setVisibility
        }
    }
})
</script>
<style scoped>
.card-profile-visibility {

}
</style>