<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-sm`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label for="password">{{ passwordText }}</label>
                            <input type="password" id="password" v-model="password" class="form-control" :class="{ 'is-invalid' : errors && errors != '' }">
                            <label class="error jquery-validation-error small form-text invalid-feedback show" v-if="errors && errors != ''">{{ errors }}</label>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Confirm Password</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/modules/Modal.vue';
import { defineComponent, ref, onBeforeUpdate, onMounted, watchEffect } from 'vue';

export default defineComponent({
    props: ['modalActive','modalTitle','errors','disableButton','passwordText'],
    setup(props, { emit }) {

        const password = ref('');
        const passwordText = ref('Type in your password to proceed');

        const close = () => {
            emit('close')
        }

        onBeforeUpdate(() => {
            password.value = '';
        })

        onMounted(() => {
            passwordText.value = props.passwordText;
        });
        
        const saveChanges = () => {
            emit('saveChanges', password.value);
        }

        watchEffect(() => {
            passwordText.value = props.passwordText ?? passwordText.value;
        });

        return {
            password,
            close,
            saveChanges,
            passwordText
        }
    },
    components: {
        Modal
    }
})
</script>