<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-header px-0 pb-4">
                            <div class="d-flex justify-content-between">
                                <div><h3>Learning Plans</h3></div>
                            </div>
                            <div class="row mt-4"> 
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12  mb-3">
                                    <div class="btn-group">
                                        <button class="btn btn-outline-primary" @click="changeTab('Basic')" :class="page.currentTab == 'Basic' ? 'active' : ''">Basic</button>
                                        <button class="btn btn-outline-primary" @click="checkAccountPlan('Premium')" :class="page.currentTab == 'Premium' ? 'active' : ''">Premium</button>
                                        <button class="btn btn-outline-primary" @click="checkAccountPlan('Custom')" :class="page.currentTab == 'Custom' ? 'active' : ''">Custom</button>
                                    </div>
                                </div>
                            </div>
                            <router-link v-if="page.currentTab == 'Custom'" :to="{ path: '/employer/learning-plan/create'}" class="btn btn-primary float-end mb-3">Create Learning Plan</router-link>
                        </div>
                        <PageLoader v-if="page.isLoading" />
                        <div v-else>
                            {{learning_plans_list.length}} results found.
                            <div class="accordion" id="accordionExample" v-if="learning_plans_list.length > 0">
                                <div class="accordion-item" v-for="(learning, index) in learning_plans_list" :key="index">
                                    <h2 class="accordion-header" :id="`headingOne${learning.learning_plan_id}`">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne${learning.learning_plan_id}`" aria-expanded="true" :aria-controls="`collapseOne${learning.learning_plan_id}`">
                                        {{learning.learning_plan_name}}
                                    </button>
                                    </h2>
                                    <div :id="`collapseOne${learning.learning_plan_id}`" class="accordion-collapse collapse" :aria-labelledby="`headingOne${learning.learning_plan_id}`">
                                        <div class="accordion-body">
                                            <div class="w-100 text-end">
                                                <div v-if="page.authuser.role_id == 1"> 
                                                    <router-link v-if="(learning.courses_id.length > 0)" :to="{ path: '/admin/learning-plan/update/'+learning.learning_plan_id}" class="btn btn-primary btn-sm mb-2">Edit</router-link>
                                                    <button v-else class="btn btn-primary btn-sm mb-2" @click="EditLearningPlan(learning.learning_plan_id)">Edit</button>
                                                </div>
                                                <button v-if="page.authuser.role_id == 1" class="btn btn-danger btn-sm mb-2 ms-1" @click="removeLearningplan(learning.learning_plan_id)">Delete</button>
                                            </div>

                                            <h5>Course Progress</h5>
                                            <div v-if="learning.courses_structure" class="progress course-progress mb-2">
                                                <div class="progress-bar bg-success" role="progressbar" 
                                                :style="learningCourseProgress(learning.courses_related, learning.learning_progress).cssCompleted" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.courses_related, learning.learning_progress).labelCompleted}} 
                                                </div>
                                                <div class="progress-bar bg-warning" role="progressbar" 
                                                :style="learningCourseProgress(learning.courses_related, learning.learning_progress).cssProgress" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.courses_related, learning.learning_progress).labelProgress}} 
                                                </div>
                                            </div>
                                            <div v-else class="progress course-progress mb-2">
                                                <div class="progress-bar bg-success" role="progressbar" 
                                                :style="learningCourseProgress(learning.trainings, learning.learning_progress).cssCompleted" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.trainings, learning.learning_progress).labelCompleted}} 
                                                </div>
                                                <div class="progress-bar bg-warning" role="progressbar" 
                                                :style="learningCourseProgress(learning.trainings, learning.learning_progress).cssProgress" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.trainings, learning.learning_progress).labelProgress}} 
                                                </div>
                                            </div>
                                            <div class="mb-2 text-right"> 
                                                <div class="btn-group">
                                                    <button class="btn btn-outline-primary btn-sm" @click="assignCustomLearningPlan(learning.learning_plan_id)">Assign</button>
                                                
                                                    <router-link v-if="learning.courses_structure && page.currentTab == 'Custom'" :to="{ path: '/employer/learning-plan/update/'+learning.learning_plan_id}" class="btn btn-outline-success btn-sm">Edit</router-link>
                                                    <button v-else-if="page.currentTab == 'Custom'" class="btn btn-outline-success btn-sm" @click="updateCustomLearningPlan(learning.learning_plan_id)">Edit</button>
                                                    
                                                    <button v-if="page.currentTab == 'Custom'" class="btn btn-outline-danger btn-sm" @click="deleteCustomLearningPlan(learning.learning_plan_id)">Remove</button>
                                                </div>
                                            </div>

                                            <!-- No Sections -->
                                            <ul class="list-group" v-if="learning.trainings.length > 0">
                                                <li class="list-group-item" v-for="(training, index) in learning.trainings" :key="index">
                                                    <div class="float-start" style="width: 90%;">
                                                        <h5><a class="text-decoration-none" target="_blank" :href="training.link">{{training.title}}</a>
                                                            <span class="badge bg-success text-white ms-2" v-if="trainingStatus(training.id, learning.learning_progress)?.status == 'completed'">Completed</span>
                                                            <span class="badge bg-warning text-white ms-2" v-if="trainingStatus(training.id, learning.learning_progress)?.status == 'progress'">In progress</span>
                                                        </h5>
                                                        
                                                        <small class="me-2">{{ training.author ?? training.company?.company_name }}</small>
                                                        <small class="text-muted me-2">{{ training.source?.name ?? 'Private Course' }}</small>
                                                        <small class="text-muted me-2">{{training.skill_type}}</small>
                                                    </div>

                                                    <div class="card-btn-actions float-end text-end" style="width: 10%;">
                                                        <div class="dropdown position-relative">
                                                            <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-more-horizontal align-middle"
                                                                >
                                                                    <circle cx="12" cy="12" r="1"></circle>
                                                                    <circle cx="19" cy="12" r="1"></circle>
                                                                    <circle cx="5" cy="12" r="1"></circle>
                                                                </svg>
                                                            </a>

                                                            <div class="dropdown-menu dropdown-menu-end">
                                                                <a class="dropdown-item" href="javascript:;" 
                                                                    @click="updateCourseStatus(learning.learning_plan_id, training.id, 'completed')">
                                                                    Mark as Complete
                                                                </a>
                                                                <a class="dropdown-item" href="javascript:;" 
                                                                    @click="updateCourseStatus(learning.learning_plan_id, training.id, 'progress')">
                                                                    Mark as In progress
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                            <!-- With Sections -->
                                            <div v-if="learning.courses_structure" class=""> 
                                                <div class="">
                                                    <div v-for="(section, i) in learning.courses_structure" :key="i" class="mt-3">
                                                        <div>
                                                            <h4 class="mb-1">{{section.section}}</h4>
                                                        </div>
                                                        <ul class="list-group" v-if="section.courses.length > 0">
                                                            <li class="list-group-item" v-for="(course, i) in section.courses" :key="i">
                                                                <div class="float-start" style="width: 90%;">
                                                                    <h5><a class="text-decoration-none" target="_blank" :href="courseInfo(course.id,learning.courses_related)[0]?.link">{{course.title}}</a>
                                                                        <span class="badge bg-success text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'completed'">Completed</span>
                                                                        <span class="badge bg-warning text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'progress'">In progress</span>
                                                                    </h5>

                                                                    <small class="me-2">{{ course.author ?? course.company?.company_name }}</small>
                                                                    <small class="te`xt-muted me-2">{{ course.source?.name ?? 'Private Course' }}</small>
                                                                    <small class="text-muted me-2">{{course.skill_type}}</small>`
                                                                </div>
                                                                
                                                                <div class="card-btn-actions float-end text-end" style="width: 10%;">
                                                                    <div class="dropdown position-relative">
                                                                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="feather feather-more-horizontal align-middle"
                                                                            >
                                                                                <circle cx="12" cy="12" r="1"></circle>
                                                                                <circle cx="19" cy="12" r="1"></circle>
                                                                                <circle cx="5" cy="12" r="1"></circle>
                                                                            </svg>
                                                                        </a>

                                                                        <div class="dropdown-menu dropdown-menu-end">
                                                                            <a class="dropdown-item" href="javascript:;" 
                                                                                @click="updateCourseStatus(learning.learning_plan_id, course.id, 'completed')">
                                                                                Mark as Complete
                                                                            </a>
                                                                            <a class="dropdown-item" href="javascript:;" 
                                                                                @click="updateCourseStatus(learning.learning_plan_id, course.id, 'progress')">
                                                                                Mark as In progress
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                        <div class="ms-5 mt-2" v-if="section.sub.length > 0"> 
                                                            <div v-for="(subsection, i) in section.sub" :key="i"> 
                                                                <div>
                                                                    <h4 class="mb-1">{{subsection.section}}</h4>
                                                                </div>
                                                                <ul class="list-group" v-if="subsection.courses.length > 0">
                                                                    <li class="list-group-item" v-for="(course, i) in subsection.courses" :key="i">
                                                                        <div class="float-start" style="width: 90%;">
                                                                            <h5 class="mb-1"><a class="text-decoration-none" target="_blank" :href="courseInfo(course.id,learning.courses_related)[0]?.link">{{course.title}}</a>
                                                                                <span class="badge bg-success text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'completed'">Completed</span>
                                                                                <span class="badge bg-warning text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'progress'">In progress</span>
                                                                            </h5>

                                                                            <small class="me-2">{{ course.author ?? course.company?.company_name }}</small>
                                                                            <small class="te`xt-muted me-2">{{ course.source?.name ?? 'Private Course' }}</small>
                                                                            <small class="text-muted me-2">{{course.skill_type}}</small>`
                                                                        </div>

                                                                        <div class="card-btn-actions float-end text-end" style="width: 10%;">
                                                                            <div class="dropdown position-relative">
                                                                                <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        stroke-width="2"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                        class="feather feather-more-horizontal align-middle"
                                                                                    >
                                                                                        <circle cx="12" cy="12" r="1"></circle>
                                                                                        <circle cx="19" cy="12" r="1"></circle>
                                                                                        <circle cx="5" cy="12" r="1"></circle>
                                                                                    </svg>
                                                                                </a>
                                                                                <div class="dropdown-menu dropdown-menu-end">
                                                                                    <a class="dropdown-item" href="javascript:;" 
                                                                                        @click="updateCourseStatus(learning.learning_plan_id, course.id, 'completed')">
                                                                                        Mark as Complete
                                                                                    </a>
                                                                                    <a class="dropdown-item" href="javascript:;" 
                                                                                        @click="updateCourseStatus(learning.learning_plan_id, course.id, 'progress')">
                                                                                        Mark as In progress
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- Block end -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalAssignLearningPlan 
            :modalActive="modalLearningPlanActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            @close="toggleLearningPlanModal"
            @reloadPage="saveLearningPlan"
        />
        <ModalLearningPlan 
            :modalActive="modalActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @reloadPage="save"
        />
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this custom learning plan? All employees enrolled in private courses associated with this learning plan will be unenrolled.`"
            @cancel="toggleConfirmation"
            @isDelete="processDelete"
        />
        <ModalSubscription 
            :modalActive="subscriptionActive"
            :userAccount="subscriptionType"
            :noHeaderBorder="true"
            :noBodyMargin="true"
            @close="toggleModalSubscription"
        />
    </div>
</template>
<script>
import { reactive, onMounted, ref, computed } from 'vue';
import learningPlanRepo from '@/repositories/admin/learningplans';
import trainingRepo from '@/repositories/employer/trainings';
import ModalAssignLearningPlan from '@/views/employer/trainings/modals/ModalAssignLearningPlan.vue';
import ModalLearningPlan from '@/views/employer/trainings/modals/ModalLearningPlan.vue';
import billingRepo from '@/repositories/employer/billing';
import userRepo from '@/repositories/settings/users';
import ModalSubscription from '@/views/employer/ModalSubscription.vue';
import globalRepo from '@/repositories/global';

export default {
    setup() {
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Add Custom Learning Plan',
            idToDelete: '',
            currentTab: 'Basic',
            showPremiumTab: false,
        });
        const { learning_plans, getCustomLearningPlans, getPremiumLearningPlans } = learningPlanRepo();
        const { getCustomLearningPlansEmployer, learning_plans_employer, updateLearningPlanProgress, getCustomLearningPlan, learning_plan, destroyCustomLearningPlan } = trainingRepo();
        const { activeSubscription, subscription } = billingRepo();
        const { subscriptionType, getEmployerSubscriptionType } = userRepo();
        const { course_filters, subscription_type } = globalRepo();
        const learning_plans_list = ref([]);
        const modalLearningPlanActive = ref(false);
        const modalActive = ref(false);
        const isConfirm = ref(false);
        const subscriptionActive = ref(false);
        const upgradedAccount = ref(false); /** True if account is upgraded */

        onMounted( async () => {
            await reloadPlans();

            await activeSubscription(page);
            upgradedAccount.value = subscription.value ? true:false; /** True if has existing subscription else False */
            await getEmployerSubscriptionType();
            if (subscriptionType.value == subscription_type.pro || subscriptionType.value == subscription_type.premiun) {
                page.showPremiumTab = true;
            }
        });

        const learning_plans_filtered = computed(()=> {
            return learning_plans.value.filter(learning => learning.trainings.length > 0 || learning.courses_related.length > 0);
        })

        const courseInfo = (id, courses) => {
            return courses.filter(course => course.id === id)
        }

        const learningCourseProgress = (trainings, progress) => {
            let totalTrainings = trainings.length
            let completedCnt = 0
            let progressCnt = 0
            progress.map( data => {
                if(data.status == 'completed'){
                    completedCnt++
                }else if(data.status == 'progress')(
                    progressCnt++
                )
            })
            const completed = ((completedCnt/totalTrainings) * 100).toFixed(2)
            const inprogress = ((progressCnt/totalTrainings) * 100).toFixed(2)
            return {
                percProgress: ((progressCnt/totalTrainings) * 100).toFixed(2),
                percCompleted: ((completedCnt/totalTrainings) * 100).toFixed(2),
                cssCompleted: 'width:'+`${completed}`+'%;',
                labelCompleted: `${completed}`+'%',
                cssProgress: 'width:'+`${inprogress}`+'%;',
                labelProgress: `${inprogress}`+'%'
            }
        }

        const updateCourseStatus = async (learningID, trainingID, type) => {
            let formData = new FormData();
            formData.append('user_id', page.authuser.id);
            formData.append('learning_id', learningID);
            formData.append('training_id', trainingID);
            formData.append('type', type);
            await updateLearningPlanProgress(formData)

            await reloadPlans()
        }

        const trainingStatus = (trainingID, learning_progress) => {
            const found = learning_progress.filter( data => data.training_id == trainingID)[0]
            return found
        }

        const changeTab = async (tab) => {
            page.isLoading = true;
            page.currentTab = tab;
            await reloadPlans();
        }

        const reloadPlans = async () => {
            if(page.currentTab == 'Basic') {
                await getCustomLearningPlans(page);
                learning_plans_list.value = learning_plans.value;
            }
            if(page.currentTab == 'Premium') {
                await getPremiumLearningPlans(page);
                learning_plans_list.value = learning_plans.value;
            }
            if(page.currentTab == 'Custom') {
                await getCustomLearningPlansEmployer(page);
                learning_plans_list.value = learning_plans_employer.value;
            }
            learning_plans_list.value = learning_plans_list.value.filter(learning => learning.trainings.length > 0 || learning.courses_related.length > 0);
        }

        /** Assign Learning Plan **/
        const assignCustomLearningPlan = async (id) => {
            await getCustomLearningPlan(id);
            page.modalHeader = 'Assign Custom Learning Plan to Employee(s)';
            toggleLearningPlanModal();
        }

        const toggleLearningPlanModal = () => {
            modalLearningPlanActive.value = !modalLearningPlanActive.value;
        }

        const saveLearningPlan = () => {
            toggleLearningPlanModal();
        }

        /** Edit Learning Plan**/
        const updateCustomLearningPlan = async (id) => {
            toggleModal();
            await getCustomLearningPlan(id);
            page.modalHeader = 'Update Custom Learning Plan';
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const save = async () => {
            toggleModal();
            await getCustomLearningPlans(page);
            learning_plan.value = []
            await reloadPlans();
        }

        /** Delete Learning Plan**/
        const deleteCustomLearningPlan = (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const processDelete = async () => {
            await destroyCustomLearningPlan(page);
            await getCustomLearningPlans(page);
            learning_plans_list.value = []
            await reloadPlans();
            toggleConfirmation();
        }

        /** Upgrade Modal **/
        const toggleModalSubscription = () => {
            subscriptionActive.value = !subscriptionActive.value;
        }

        /** Check Account if valid to access **/
        const checkAccountPlan = async (tab) => {
            if(upgradedAccount.value == false){
                subscriptionActive.value = true
            }else{
                changeTab(tab)
            }
        }

        return {
            page,
            getCustomLearningPlans,
            learning_plans,
            courseInfo,
            learning_plans_filtered,
            learningCourseProgress,
            updateCourseStatus,
            trainingStatus,
            changeTab,
            learning_plans_list,
            assignCustomLearningPlan,
            saveLearningPlan,
            toggleLearningPlanModal,
            modalLearningPlanActive,
            learning_plan,
            updateCustomLearningPlan,
            modalActive,
            toggleModal,
            save,
            deleteCustomLearningPlan,
            isConfirm,
            processDelete,
            toggleConfirmation,
            subscriptionActive,
            subscriptionType,
            toggleModalSubscription,
            upgradedAccount,
            checkAccountPlan
        }
    },
    components: {
        ModalAssignLearningPlan,
        ModalLearningPlan,
        ModalSubscription
    }
}
</script>