<template>
  <div>
      <div class="wrapper" v-if="page.currentPage == 'help'">
          <div class="main">
              <router-view />
          </div>
      </div>
      <div class="wrapper" v-else-if="token">
          <Sidebar />
          <div class="main">
              <Navbar />
              <router-view />
              <Footer />
          </div>
      </div>
      <div v-else>
          <router-view />
      </div>
  </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar';
import Sidebar from '@/components/partials/Sidebar';
import Footer from '@/components/partials/Footer';
import store from "@/store";
import { reactive } from 'vue';

export default {
  setup() {

      const page = reactive({
          api_url: process.env.VUE_APP_API_URL,
          currentPage: window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
      });

      const authuser = JSON.parse(localStorage.getItem("authuser"));
      const token = store.getters["auth/authenticated"];
      
      if(token == '') {
          localStorage.removeItem('authuser');
          localStorage.removeItem('token');
          localStorage.removeItem('auth-qrcode');
      }

      if (page.currentPage == 'help') {
          document.getElementById('page-body').setAttribute("data-sidebar-behavior", "sticky");
      }

      return {
          token,
          authuser,
          page
      }
  },
  components: {
      Navbar,
      Sidebar,
      Footer
  }
}
</script>