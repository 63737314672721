<template>
    <div>
        <Modal :modalActive="modalActive" :class="`modal-dialog modal-dialog-centered`" :bodyClass="`modal-scroll`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-12">
                        <BaseInput 
                            v-model="form.name"
                            label="Interview Training Name"
                            type="text"
                            id="name"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group mb-3">
                            <label class="form-label">Interview Training Type</label>
                            <div class="d-flex">
                                <BaseRadio
                                    v-model="form.type"
                                    placeholder="Article"
                                    @click="whatType('Article')"
                                />
                                <BaseRadio
                                    v-model="form.type"
                                    placeholder="Video"
                                    @click="whatType('Video')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">{{ page.interviewTrainingTypeLabel }} <span class="text-danger">*</span></label>
                        <BaseInput 
                            v-model="form.link"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.link }"
                            id="link"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div v-if="form.image && hasNotDeleteImage">
                    <label class="form-label">Interview Training Cover</label>
                    <div class="mb-3">
                        <img class="card-img-top-interview-training w300" :src="`${form.thumbnail}`" alt="Interview Training" />
                        <br />
                        <button class="btn btn-outline-danger" type="button" @click="removeImage">Remove</button>
                    </div>
                </div>
                <div v-else>
                    <BaseInputFile 
                        label="Interview Training Cover"
                        :class="{ 'is-invalid' : errors && errors.image }"
                        id="image"
                        :errors="errors"
                        @changeFile="onFileImageChange"
                        :form-text="`For best results, please use an image that is 720px (width) x 405px (height)`"
                    />
                </div>
                <div class="row">
                    <div class="col-12">
                        <BaseEditor
                            label="Description"
                            v-model="form.description"
                            id="description"
                            placeholder="Enter description"
                            @editorContent="setInterviewTrainingContent"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary btn-savechanges" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this image?`"
            @cancel="toggleConfirmation"
            @isDelete="destroyImage"
        />
    </div>
</template>

<script>
import { defineComponent, reactive, ref, watchEffect } from 'vue';
import interviewTrainingRepo from '@/repositories/admin/interviewTrainings';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {}
        },
        hasImage: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const { unlinkImage, status } = interviewTrainingRepo();
        const page = reactive({
            api_url: process.env.VUE_APP_API_URL,
            interviewTrainingTypeLabel: 'Article',
        });
        
        const isConfirm = ref(false);
        const imageFile = ref('');
        const hasNotDeleteImage = ref(true);


        const whatType = (type) => {
            if (type === undefined) {
                page.interviewTrainingTypeLabel = 'Article Link';
            } else {
                page.interviewTrainingTypeLabel = type +' Link';
                props.form.type = type;
            }
        }

        watchEffect(() => {
            page.interviewTrainingTypeLabel = props.form.type + ' Link';
            props.form.description = props.form.description ?? '';
            if (props.form.type === undefined || props.form.type === '') {
                page.interviewTrainingTypeLabel = 'Article Link';
                whatType('Article')
            } else {
                page.interviewTrainingTypeLabel = props.form.type +' Link';
            }
        });

        const close = () => {
            emit('close', hasNotDeleteImage.value)
        }
        
        const saveChanges = () => {
            props.form.image = imageFile.value ?? '';
            imageFile.value = '';
            emit('saveChanges');
        }

        const setInterviewTrainingContent = (evt) => {
            props.form.description = evt ?? '';
        }

        const onFileImageChange = (file) => {
            props.form.image = null;
            imageFile.value = file;
        }

        const setTrainingContent = (evt) => {
            if(evt) {
                props.form.description = evt ?? '';
            }
        }

        const removeImage = () => {
            isConfirm.value = true;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const destroyImage = async () => {
            await unlinkImage(props.form.id);
            if(status.value === 200) {
                hasNotDeleteImage.value = false;
                toggleConfirmation();
                emit('close', hasNotDeleteImage.value)
            }
        }

        return {
            page,
            status,
            close,
            isConfirm,
            saveChanges,
            onFileImageChange,
            setTrainingContent,
            removeImage,
            toggleConfirmation,
            destroyImage,
            hasNotDeleteImage,
            whatType,
            setInterviewTrainingContent
        }
    }
})
</script>

<style>
.w300 {
    width: 300px !important
}
.modal-dialog.modal-lg,
.modal-dialog.modal-md,
.modal-dialog.modal-sm,
.modal-dialog-scrollable,
.modal-dialog.modal-dialog-scrollable {
    pointer-events: all !important;
}
</style>
