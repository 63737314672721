<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-xl modal-dialog-scrollable`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="position-relative" :class="page.isPreviewApplicationLoading ? 'custom-apply-modal-class' : ''">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="tab in tabs" :key="tab">
                            <button class="nav-link" :class="getActive(tab.id)" :disabled="isDisabled(tab.id)" @click="currentTab(tab.id, tab.component)" type="button" role="tab">{{ tab.id +'. '+ tab.step }}</button>
                        </li>
                    </ul>
                    <div class="tab-btn" v-if="!page.isPreviewApplicationLoading">
                        <button class="btn btn-outline-primary btn-sm" :class="{ 'disabled' : isPrevDisable }" @click="prevTab" v-if="!page.isLastStep" type="button">Previous</button>&nbsp;
                        <button class="btn btn-outline-primary btn-sm" type="button" v-if="!page.isLastStep" @click="nextTab">Next</button>
                        <button class="btn btn-outline-primary btn-sm" type="button" disabled v-else-if="page.isLastStep && form.submitted == true">You already submitted an application</button>
                        <button class="btn btn-outline-primary btn-sm" type="button" @click="submitApplication" v-else v-html="page.buttonValue"></button>
                    </div>
                    <div class="tab-btn" v-else>
                        <button disabled class="btn btn-outline-primary btn-sm">Please wait...</button>
                    </div>

                    <div class="tab-content" id="application-tab-content">

                        <!-- QUESTIONS TAB -->
                        <div class="tab-pane fade pt-3" :class="currentComponent === 'Question' ? 'show active' : ''">
                            <div>
                                <div class="row no-margin questions" v-if="questions.length">
                                    <div class="col-12">
                                        <div class="mb-2 text-bold text-danger custom-question-error-message" v-if="question_errors">Please answer all questions.</div>
                                        <div class="mb-3 mt-1">
                                            <div class="form-group" v-for="question in questions" :key="question.id">
                                                <div class="text-muted">
                                                    {{ question.question }} <span class="text-danger">*</span>
                                                </div>
                                                <div v-if="!question.preferred_answer">
                                                    <BaseTextarea
                                                        v-model="question.candidate_answer"
                                                        :id="`question-${question.id}`"
                                                        placeholder="Insert your answer"
                                                        :class="{ 'invalid': validate_questions && (question.candidate_answer == null || question.candidate_answer == '') }"
                                                    />
                                                    <div class="mb-3" style="margin-top: -15px;">
                                                        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" style="display: inline-block !important;width: auto;" v-if="validate_questions && (question.candidate_answer == null || question.candidate_answer == '')">Please provide your answer.</label>&nbsp;
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <BaseInput 
                                                        v-model="question.candidate_answer"
                                                        type="number"
                                                        :id="`question-${question.id}`"
                                                        placeholder="Place your answer here"
                                                        :class="{ 'invalid': validate_questions && (question.candidate_answer == null || question.candidate_answer == '') }"
                                                    />
                                                    <div class="mb-3" style="margin-top: -15px;">
                                                        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" style="display: inline-block !important;width: auto;" v-if="validate_questions && (question.candidate_answer == null || question.candidate_answer == '')">Please provide your answer.</label>&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3" v-else>
                                    <h4>No available Pre-Screening Questions</h4>
                                </div>
                            </div>
                        </div>

                        <!-- DOCUMENTS TAB -->
                        <div class="tab-pane fade pt-3" :class="currentComponent === 'Documents' ? 'show active' : ''">
                            <div>
                                <div class="document-section">
                                    <div class="row no-margin">
                                        <h4 style="margin-bottom: -15px;">Your Resume <span class="text-danger" v-if="application?.job?.submit_resume === 'Yes'">*</span></h4>
                                        <div class="col-8">
                                            <BaseCheckbox is-array :margin-bottom-on="false">
                                                <label class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="toggleResume" />
                                                    <span class="form-check-label">
                                                        Use my Simphini Profile as Resume
                                                    </span>
                                                </label>
                                            </BaseCheckbox>
                                        </div>
                                    </div>
                                    <div class="row no-margin" v-if="!toggleResume">
                                        <div class="col-8">
                                            <div class="form-group mt-3 own-resume" :class="resumeErrors ? 'is-invalid': ''">
                                                <label for="formFile" class="form-label"><b>Upload My Own Resume</b></label>
                                                <input class="form-control" type="file" id="formFile" @change="onFileResumeChange" enctype="multipart/form-data">
                                                <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" v-if="resumeErrors">Please upload your resume.</label>
                                                <div v-if="application.resume">
                                                    <span class="badge bg-primary text-white">{{ getDocument('resume') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="document-section" :class="document_errors ? 'is-invalid' : ''">
                                    <div class="row no-margin">
                                        <h4 style="margin-bottom: -15px;">Your Cover Letter <span class="text-danger">*</span></h4>
                                        <div class="col-8">
                                            <BaseCheckbox is-array :margin-bottom-on="false">
                                                <label class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="toggleLetter" />
                                                    <span class="form-check-label">
                                                        Manually type a cover letter
                                                    </span>
                                                </label>
                                            </BaseCheckbox>
                                        </div>
                                    </div>
                                    <div class="row no-margin">
                                        <div class="col-12">
                                            <div v-if="toggleLetter">
                                                <BaseEditor
                                                    v-model="application.cover_letter_content"
                                                    id="cover_letter_content"
                                                    @editorContent="setCoverLetterContent"
                                                />
                                            </div>
                                            <div v-else>
                                                <div class="form-group mt-3">
                                                    <label for="letterFile" class="form-label"><b>Upload a Cover Letter</b></label>
                                                    <input class="form-control" type="file" id="letterFile" @change="onFileCoverLetterChange" enctype="multipart/form-data">
                                                    <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" v-if="errors && errors.cover_letter">{{ errors.cover_letter[0] }}</label>
                                                    <div v-if="application.cover_letter">
                                                        <span class="badge bg-primary text-white">{{ getDocument('cover-letter') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="document_errors" class="cover-letter-errors text-danger">Cover Letter is required.</div>
                                </div>
                                <div class="document-section supportting">
                                    <div class="row no-margin">
                                        <div class="col-8">
                                            <div class="form-group mb-3 mt-3">
                                                <label for="documentFile" class="form-label"><b>Upload Supporting Document</b></label>
                                                <input class="form-control" type="file" id="documentFile" @change="onFileDocumentsChange" enctype="multipart/form-data">
                                                <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" v-if="errors && errors.documents">{{ errors.documents[0] }}</label>
                                                <div v-if="application.documents">
                                                    <span class="badge bg-primary text-white">{{ getDocument('supporting-document') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- PREVIEW APPLICATION -->
                        <div class="tab-pane fade pt-3" :class="currentComponent === 'Preview' ? 'show active' : ''">
                            <div>
                                <PageLoader class="pt-4 pb-4" v-if="page.isPreviewApplicationLoading" />
                                <div v-else>
                                    <!-- TODO:: use Google PDF viewer when deployed to production https://docs.google.com/viewer?url=${url}&embedded=true -->
                                    <!-- <iframe :src="`https://docs.google.com/viewer?url=${application.application_file}&embedded=true`" width="98%" height="420px"></iframe> -->
                                    <iframe v-if="application.application_file !== null" :src="`${application.application_file}`" width="98%" height="420px"></iframe>
                                    <div class="text-center text-lg mt-5 mb-5" v-else>Sorry, No preview available, but you can still submit the application.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { onUpdated, reactive, watchEffect, ref } from 'vue';
import Modal from '@/components/modules/Modal.vue';
import Question from '@/views/candidate/jobs/content/Question.vue';
import Documents from '@/views/candidate/jobs/content/Documents.vue';
import Preview from '@/views/candidate/jobs/content/Preview.vue';
import applicationRepo from '@/repositories/candidate/application';
import BaseTextarea from '@/components/modules/form/BaseTextarea.vue';
import { truncate } from 'fs';
export default {
    props: {
        id: {
            type: [Number, String],
            required: true
        },
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Apply to a Job',
            idToDelete: '',
            activeTab: 1,
            isLastStep: false,
            jobId: '',
            buttonValue: 'Submit my Application',
            clickedNext: false,
            isQuestionAnswered: false,
            isPreviewApplicationLoading: false,

        });

        const { application: application_data, generatePdf, getApplication, updateAnswers, updateDocuments, submitJobApplication, errors } = applicationRepo();

        const isPrevDisable = ref(true);
        const isButtonDisabled = ref(false);
        const currentComponent = ref('Question');
        const url = ref('');

        // questions vars
        const questions = ref([]);
        const question_errors = ref(false);
        const validate_questions = ref(false);

        // document vars
        const resumeErrors = ref(false);
        const toggleResume = ref(true);
        const toggleLetter = ref(true);
        const application = ref([]);
        const document_errors = ref(false);
        const validate_documents = ref(false);

        watchEffect( async () => {
            isPrevDisable.value = (page.activeTab == 1);
            page.jobId = props.id;
            questions.value = props.form?.job?.screening ?? [];
            application.value = props.form ?? [];
            toggleResume.value = props.form?.use_profile ?? true;
            toggleLetter.value = props.form?.type_cover_letter ?? true;
            if(page.isLastStep) {
                page.isPreviewApplicationLoading = true;
                await generatePdf(page.authuser.id, application.value?.job_id, url);
                application.value.application_file = application_data.value.application_file;
                page.isPreviewApplicationLoading = false;
            }
        });

        const tabs = [
            {
                id: 1,
                step: 'Pre-Screening',
                component: 'Question'
            },
            {
                id: 2,
                step: 'Important Documents',
                component: 'Documents'
            },
            {
                id: 3,
                step: 'Preview Your Application',
                component: 'Preview'
            }
        ];

        const isDisabled = (step) => {
            if(page.activeTab > 1 && page.activeTab > step || (page.activeTab == 2 && (step == 1 || step == 3)) || (page.activeTab == 1 && step == 2)) {
                return false;
            }
            return (page.activeTab !== step);
        }

        const currentTab = async (step, component) => {

            // Validate Pre Screening before moving to next tab
            if(step === 2) {
                validateTabData(1);
                questionCheckpoint();
                if(question_errors.value) {
                    return false;
                }
                candidateQuestions(questions.value);
            }

            // Validate Documents before moving to next tab
            if(step === 3) {
                validateTabData(2);
                documentCheckpoint();
                if(document_errors.value || resumeErrors.value) {
                    return false;
                }
                await candidateDocuments(application);
            }

            currentComponent.value = component;

            if(page.activeTab > 1 && page.activeTab > step || (page.activeTab == 2 && (step == 1 || step == 3)) || (page.activeTab == 1 && step == 2)) {
                page.activeTab = step;
            }
            page.isLastStep = (tabs.length == page.activeTab);
        }

        const nextTab = async () => {

            // Validate Pre Screening before moving to next tab
            if(page.activeTab+1 === 2) {
                validateTabData(1);
                questionCheckpoint();
                if(question_errors.value) {
                    return false;
                }
                candidateQuestions(questions.value);
            }

            // Validate Documents before moving to next tab
            if(page.activeTab+1 === 3) {
                validateTabData(2);
                documentCheckpoint();
                if(document_errors.value || resumeErrors.value) {
                    return false;
                }
                await candidateDocuments(application);
            }

            if(tabs.length > page.activeTab) {
                page.activeTab = page.activeTab+1;
            }

            currentComponent.value = tabs.find(x => x.id == page.activeTab).component;

            page.clickedNext = false;
            page.isLastStep = (tabs.length == page.activeTab);
        }

        const getActive = (step) => {
            if(page.activeTab > 1 && page.activeTab > step) {
                return 'done';
            }
            return (page.activeTab == step) ? 'active' : '';
        }

        const prevTab = () => {
            if(page.activeTab != 1) {
                page.activeTab = page.activeTab-1;
            }

            currentComponent.value = tabs.find(x => x.id == page.activeTab).component;
            page.isLastStep = (tabs.length == page.activeTab);
        }

        const candidateQuestions = async (data) => {
            let answer = [];
            data.forEach(item => {
                answer.push({
                    candidate_id: page.authuser.id,
                    question: item.question,
                    candidate_answer: item.candidate_answer
                })
            });

            await updateAnswers(page, answer);
            await getApplication(page, page.jobId);
        }

        const candidateDocuments = async (data) => {
            let formData = new FormData();
            formData.append('documents', data.value.documents ?? '');
            formData.append('resume', data.value.resume ?? '');
            formData.append('cover_letter', data.value.cover_letter ?? '');
            formData.append('cover_letter_content', data.value.cover_letter_content ?? '');
            formData.append('use_profile', toggleResume.value ?? data.value.use_profile);
            formData.append('type_cover_letter', toggleLetter.value ?? data.value.type_cover_letter);
            formData.append('candidate_id', page.authuser.id);
            formData.append('job_id', page.jobId);
            isButtonDisabled.value = true;
            await updateDocuments(formData);
            await getApplication(page, page.jobId);
            isButtonDisabled.value = false;
        }

        const errorResumeUpload = () => {
            isButtonDisabled.value = true;
            setTimeout(()=>{
                isButtonDisabled.value = false;
            },500);
        }

        const submitApplication = async () => {
            page.buttonValue = '<i class="fa fa-spinner fa-spin"></i>';
            await submitJobApplication(page);
            setTimeout(() => {
                emit('close');
                page.activeTab = 1;
                currentComponent.value = 'Question';
            }, 1000)
        }

        const close = () => {
            emit('close');
            page.activeTab = 1;
            currentComponent.value = 'Question';
        }

        const saveChanges = () => {
            emit('saveChanges');
        }

        const checkAnswersValue = (data) => {
            if(data.length) {
                data.forEach(item => {
                    if(item?.candidate_answer === null || item?.candidate_answer === ""){
                        page.isQuestionAnswered = false;
                    } else {
                        page.isQuestionAnswered = true;
                    }
                });
            } else {
                page.isQuestionAnswered = true;
            }
        }

        const validateTabData = (tabID) => {
            validate_questions.value = false;
            validate_documents.value = false;
            if(tabID === 1) {
                validate_questions.value = true;
            }
            if(tabID === 2) {
                validate_documents.value = true;
            }
        }

        const questionCheckpoint = () => {
            questions.value.forEach( item => {
                if(item?.candidate_answer === null || item?.candidate_answer === ''){
                    question_errors.value = true;
                } else {
                    question_errors.value = false;
                }
            });
        }

        const documentCheckpoint = () => {
            if(!application.value.cover_letter) {
                if(!application.value.cover_letter_content) {
                    document_errors.value = true;
                } else {
                    document_errors.value = false;
                }
            }
            if(application.value?.job?.submit_resume === 'Yes' && !toggleResume.value) {
                if(!application.value.resume) {
                    resumeErrors.value = true;
                } else {
                    resumeErrors.value = false;
                }
            } else {
                resumeErrors.value = false;
            }
        }

        const getDocument = (type) => {
            let name = page.authuser.name.replace(' ','-');
            return `${name.toLowerCase()}-${type}`;
        }

        const setCoverLetterContent = (evt) => {
            application.value.cover_letter_content = evt ?? '';
        }

        const onFileResumeChange = (e) => {
            let fileResume = e.target.files[0];
            application.value.resume = fileResume;
        }

        const onFileCoverLetterChange = (e) => {
            let fileCoverLetter = e.target.files[0];
            application.value.cover_letter = fileCoverLetter;
        }

        const onFileDocumentsChange = (e) => {
            let fileDocuments = e.target.files[0];
            application.value.documents = fileDocuments;
        }

        return {
            page,
            getApplication,
            updateAnswers,
            updateDocuments,
            submitJobApplication,
            errors,
            tabs,
            currentTab,
            getActive,
            isDisabled,
            prevTab,
            nextTab,
            candidateQuestions,
            candidateDocuments,
            submitApplication,
            isPrevDisable,
            currentComponent,
            close,
            saveChanges,
            isButtonDisabled,
            checkAnswersValue,
            errorResumeUpload,
            questions,
            validate_questions,
            question_errors,

            toggleResume,
            toggleLetter,
            application,
            validate_documents,
            resumeErrors,
            document_errors,
            getDocument,
            setCoverLetterContent,
            onFileResumeChange,
            onFileCoverLetterChange,
            onFileDocumentsChange
        }
    },
    components: {
        Modal,
        Question,
        Documents,
        Preview,
        BaseTextarea
    }
}
</script>
<style scoped>
.custom-question-error-message {
    background: #ffd5d5;
    padding: 5px 12px;
    border-radius: 3px;
}
.document-section {
    padding: 15px 10px;
    background: #f6f6f6;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    margin-bottom: 15px;
}
.document-section.supportting {
    padding: 0 10px;
}
.document-section.supportting label.form-label {
    color: #495057;
}
.document-section.is-invalid {
    border-color: #be1616;
    background: #ffeded;
}
.cover-letter-errors {
    background: #ffd5d5;
    font-weight: bold;
    padding: 6px 10px;
    border-radius: 4px;
    margin: 0 13px;
}
.own-resume {
    border-radius: 5px;
    padding: 15px 20px;
    border: 1px solid transparent;
}
.own-resume.is-invalid {
    background: #ffd5d5;
    border: 1px solid #ff7878;
}
.custom-apply-modal-class:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
}
.tab-btn {
    top: 0;
    right: 0;
}
</style>