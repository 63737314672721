<template>
  <div>
    <div class="tab-pane fade show active" id="certifications" role="tabpanel">
      <div class="card">
        <div class="card-header pb-0">
          <h5 class="card-title mb-0">Work Experience</h5>
        </div>
        <div class="card-header">
          <div class="card-actions float-end">
            <button class="btn btn-primary text-white btn-add-work-exp" @click="addWorkExperience">Add Work Experience</button>
          </div>
        </div>
        <div class="card-body">
          <BaseTable :data="works" :options="headers">
            <template v-slot:action="{ row }">
              <div class="meatballs-menu">
                <div class="btn-group">
                  <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown"
                    aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="javascript:;" @click="editWorkExperience(row.id)">Edit</a></li>
                    <li><a class="dropdown-item text-danger btn-work-exp-delete" href="javascript:;"
                        @click="deleteWorkExperience(row.id)">Remove</a></li>
                  </ul>
                </div>
              </div>
            </template>
          </BaseTable>
        </div>
      </div>
    </div>
    <ModalExperience :modalActive="modalActive" :form="work" :errors="errors" :modalTitle="page.modalTitle"
      @close="toggleModal" @saveChanges="save" />
    <Confirm :is-confirm="isConfirm" :confirm-text="`Are you sure you want to remove this item?`"
      @cancel="toggleConfirmation" @isDelete="processDelete" />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import workRepo from '@/repositories/candidate/works';
import ModalExperience from '@/views/candidate/settings/profile/ModalExperience.vue';

export default defineComponent({
  setup() {
    const { works, work, getWorks, getWork, insertWork, updateWork, deleteWork, errors, status } = workRepo();

    onMounted(async () => {
      await getWorks(page);
    })

    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      modalTitle: 'Add Work Experience',
      idToDelete: ''
    });
    const modalActive = ref(false);
    const isConfirm = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    }
    const toggleConfirmation = () => {
      isConfirm.value = !isConfirm.value;
    }

    const headers = [
      {
        key: 'job_title',
        label: 'Job Title'
      },
      {
        key: 'company',
        label: 'Company'
      },
      {
        key: 'time_period',
        label: 'Time Period'
      },
      {
        key: 'action',
        label: 'Action',
        class: 'text-center w-10'
      }
    ];

    const addWorkExperience = () => {
      toggleModal();
      page.modalTitle = 'Add Work Experience';
      work.value = [];
      errors.value = [];
    }

    const editWorkExperience = (id) => {
      toggleModal();
      page.modalTitle = 'Edit Work Experience';
      errors.value = [];
      getWork(id);
    }

    const processDelete = async () => {
      toggleConfirmation();
      await deleteWork(page.idToDelete);
      await getWorks(page);
    }

    const deleteWorkExperience = (id) => {
      toggleConfirmation();
      page.idToDelete = id;
    }

    const save = async () => {
      let formData = new FormData();
      formData.append('job_title', work.value.job_title ?? '');
      formData.append('company', work.value.company ?? '');
      formData.append('company_address', work.value.company_address ?? '');
      formData.append('description', work.value.description ?? '');
      formData.append('worked_from', work.value.worked_from ?? '');
      formData.append('worked_to', work.value.worked_to ?? '');
      formData.append('user_id', page.authuser.id);

      if (work.value.id) {
        formData.append('_method', 'PUT');
        await updateWork(work.value.id, formData);
        if (status.value === 200) {
          toggleModal();
          getWorks(page);
        }
      } else {
        await insertWork(formData);
        if (status.value === 200) {
          toggleModal();
          getWorks(page);
        }
      }
    }

    return {
      page,
      errors,
      status,
      headers,
      modalActive,
      toggleModal,
      isConfirm,
      toggleConfirmation,
      addWorkExperience,
      editWorkExperience,
      processDelete,
      deleteWorkExperience,
      works,
      work,
      getWorks,
      getWork,
      insertWork,
      updateWork,
      deleteWork,
      save
    }
  },
  components: {
    ModalExperience
  }
})
</script>