<template>
    <div class="tab-pane fade show active" id="billing" role="tabpanel">
        <div class="row">
            <div class="col-12">
                <div class="tab">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="text-lg nav-link" :class="{ 'active' : !page.isActive }" href="#tab-1" data-bs-toggle="tab" role="tab" aria-selected="false" tabindex="-1">
                                Subscriptions and Billing
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="text-lg nav-link" :class="{ 'active' : page.isActive }" href="#tab-2" data-bs-toggle="tab" role="tab" aria-selected="false" tabindex="-1">
                                Account Billings
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content position-relative">
                        <div class="tab-pane" :class="{ 'active show' : !page.isActive }" id="tab-1" role="tabpanel">
                            <div v-if="page.current_view == 'account'">
                                <div class="card section-items">
                                    <div class="card-header pb-0 pt-4">
                                        <div class="d-flex justify-content-between">
                                            <h5 class="card-title mb-0 text-dark">Subscriptions</h5>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="card card-body bg-light subscription-item" :class="(!isSubscribed) ? 'active': ''">
                                                    <div class="package-wrap">
                                                        <h1>Basic <span v-if="!isSubscribed" class="active">Active</span></h1>
                                                        <p class="text-dark text-md package-details">
                                                            <span><i class="fas fa-check text-success bulleted"></i> {{ job_post_count.free }} Active job posts/month</span>
                                                            <span><i class="fas fa-times text-danger bulleted"></i> {{ promoted_job_count.free }} Promoted Job Posts</span>
                                                            <span><i class="fas fa-times text-danger bulleted"></i> Access to Premium Learning Plans</span>
                                                            <span><i class="fas fa-times text-danger bulleted"></i> Access to Custom Learning Plans</span>
                                                        </p>
                                                        <div class="package-price">
                                                            <h3>Free</h3>
                                                            <h2>For a lifetime</h2>
                                                        </div>
                                                        <div class="upgrade-button">
                                                            <button class="btn btn-outline-primary" style="pointer-events: none;" v-if="!isSubscribed">Current Plan</button>
                                                            <button class="btn btn-outline-primary" style="pointer-events: none;" v-else>Free Plan</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="card card-body bg-light subscription-item" :class="(isSubscribed && company.account_type=='Pro') ? 'active': ''">
                                                    <div class="package-wrap">
                                                        <h1>Pro <span class="until" v-if="company.account_type=='Pro' && (page.hasCancellations && cancellations)">Until {{ cancellations[1] }}</span> <span class="active" v-if="isSubscribed && company.account_type=='Pro'">Active</span></h1>
                                                        <p class="text-dark text-md package-details">
                                                            <span><i class="fas fa-check text-success bulleted"></i> {{ job_post_count.pro }} Active job posts/month</span>
                                                            <span><i class="fas fa-check text-success bulleted"></i> {{ promoted_job_count.pro }} Promoted Job Posts</span>
                                                            <span><i class="fas fa-check text-success bulleted"></i> Access to Premium Learning Plans</span>
                                                            <span><i class="fas fa-check text-success bulleted"></i> Access to Custom Learning Plans</span>
                                                        </p>
                                                        <div class="package-price">
                                                            <h3>$2,847/year</h3>
                                                            <h2>$297/month</h2>
                                                        </div>
                                                        <div class="upgrade-button">
                                                            <button class="btn btn-outline-secondary" style="pointer-events:none" v-if="isSubscribed && company.account_type=='Pro'">Current Plan</button>
                                                            <button class="btn btn-outline-primary" style="pointer-events:none" v-else-if="isSubscribed && company.account_type=='Premium'">Pro Plan</button>
                                                            <button class="btn btn-primary" @click="choosePlan('Pro')" v-else>Upgrade Plan</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="card card-body bg-light subscription-item" :class="(isSubscribed && company.account_type=='Premium') ? 'active': ''">
                                                    <div class="package-wrap">
                                                        <h1>Premium <span class="until" v-if="company.account_type=='Premium' && (page.hasCancellations && cancellations)">Until {{ cancellations[1] }}</span> <span v-if="isSubscribed && company.account_type=='Premium'">Active</span></h1>
                                                        <p class="text-dark text-md package-details">
                                                            <span><i class="fas fa-check text-success bulleted"></i> {{ job_post_count.premium }} Active Job Posts</span>
                                                            <span><i class="fas fa-check text-success bulleted"></i> {{ promoted_job_count.premium }} Promoted Job Posts</span>
                                                            <span><i class="fas fa-check text-success bulleted"></i> Access to Premium Learning Plans</span>
                                                            <span><i class="fas fa-check text-success bulleted"></i> Access to Custom Learning Plans</span>
                                                        </p>
                                                        <div class="package-price">
                                                            <h3>$4,047/year</h3>
                                                            <h2>$447/month</h2>
                                                        </div>
                                                        <div class="upgrade-button">
                                                            <button class="btn btn-outline-secondary" style="pointer-events:none" v-if="isSubscribed && company.account_type=='Premium'">Current Plan</button>
                                                            <button class="btn btn-primary" @click="choosePlan('Premium')" v-else>Upgrade Plan</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card section-items mb-0" v-if="isSubscribed">
                                    <div class="card-header pt-4">
                                        <div class="d-flex justify-content-between">
                                            <h5 class="card-title mb-0 text-dark">Payment Methods</h5>
                                        </div>
                                    </div>
                                    <div class="card-body pt-0 pb-1 pb-3">
                                        <p>Modify your payment method for future payments.</p>
                                        <div class="manage-payment-method-wrapper">
                                            <button class="btn btn-success" @click="showPaymentMethods">Manage Payment Methods</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card section-items" v-if="isSubscribed">
                                    <div class="card-header pb-0 pt-4">
                                        <div class="d-flex justify-content-between">
                                            <h5 class="card-title mb-0 text-dark">Billing History</h5>
                                        </div>
                                    </div>
                                    <div class="card-body pt-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <PageLoader v-if="page.isLoading" />
                                                <div v-else>
                                                    <!-- Show if there's unpaid/due invoice -->
                                                    <div class="row" v-if="subscription.stripe_status !== 'active'" v-show="false">
                                                        <div class="col-12">
                                                            <div class="alert alert-danger alert-dismissible" role="alert">
                                                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                                <div class="alert-message">
                                                                    You have unpaid invoice!
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <BaseTable
                                                        :data="invoices"
                                                        :options="headers"
                                                    >
                                                        <template v-slot:number="{ row }">
                                                            <div v-html="row.number" style="width:110px;"></div>
                                                        </template>
                                                        <template v-slot:subscription="{ row }">
                                                            <div v-html="row.lines.data[0].description" style="width:200px;"></div>
                                                        </template>
                                                        <template v-slot:created="{ row }">
                                                            <div v-html="convertDate(row.created)"></div>
                                                        </template>
                                                        <template v-slot:status="{ row }">
                                                            <div v-html="row.status.toUpperCase()"></div>
                                                        </template>
                                                        <template v-slot:action="{ row }">
                                                            <div class="btn-group">
                                                                <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Action
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    <li><a class="dropdown-item" :href="row.invoice_pdf" target="_blank">Download Invoice</a></li>
                                                                    <li><hr class="dropdown-divider"></li>
                                                                    <li><a class="dropdown-item" href="javascript:;" @click="viewPayment(row.payment_intent)">View Payment Details</a></li>
                                                                </ul>
                                                            </div>
                                                        </template>
                                                    </BaseTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card section-items mb-0" v-if="isSubscribed">
                                    <div class="card-header pt-4">
                                        <div class="d-flex justify-content-between">
                                            <h5 class="card-title mb-0 text-dark">Pause or Cancel Subscription</h5>
                                        </div>
                                    </div>
                                    <div class="card-body pt-0 pb-1 pb-3">
                                        <p>Cancelling your active subscriptions will revoke access to some Simphini features.</p>
                                        <div class="manage-payment-method-wrapper">
                                            <button class="btn btn-outline-danger" v-if="!page.hasCancellations" @click="cancelSubscription">Cancel Subscription</button>
                                            <button class="btn btn-outline-danger" disabled v-else>Cancelled</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="page.current_view=='payment_method'">
                                <div class="back-button"><button class="btn btn-secondary" @click="showBillingAccount">Back</button></div>
                                <PaymentMethod />
                            </div>
                        </div>
                        <div class="tab-pane" :class="{ 'active show' : page.isActive }" id="tab-2" role="tabpanel">
                            <table class="table table-striped" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Provider</th>
                                        <th>Amount</th>
                                        <th>Billing Type</th>
                                        <th>Status</th>
                                        <th>Due Date</th>
                                    </tr>
                                </thead>
                                <tbody v-if="billings.length">
                                    <tr v-for="billing in billings" :key="billing.id">
                                        <td>{{ billing.created_at_display }}</td>
                                        <td>{{ billing.provider?.name }}</td>
                                        <td>{{ billing.amount_display }}</td>
                                        <td>{{ billing.bill_type }}</td>
                                        <td>{{ billing.status }}</td>
                                        <td>{{ billing.due_date_display }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <td colspan="6" class="text-center">
                                        <div>No data available</div>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-spinner position-absolute" v-if="page.isTabLoading"><i class="fa fa-spinner fa-spin"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <ModalInvoice
            :modalActive="modalInvoiceActive"
            :modalTitle="page.modalHeader"
            :form="payment"
            @close="toggleInvoiceModal"
            @refreshPage="refreshPage"
        />
        <ModalSubscription
            :modalActive="subscriptionActive"
            :noHeaderBorder="true"
            :noBodyMargin="true"
            :userAccount="subscription_type"
            :isBillingPage="true"
            :planToUpgrade="page.selectedPlan"
            @close="toggleModalSubscription"
            @render-jobposting="toggleModalSubscription"
        />
        <Confirm
            :is-confirm="cancelSubscriptionConfirmation"
            :confirm-text="`Are you sure you want to cancel your current subscription? Please note that changes will be reflected on the next billing cycle. You will be able to continue enjoying your ${company.account_type} account until ${page.subscription_end}.`"
            @cancel="toggleCancelSubcription"
            @isDelete="processCancelSubscription"
        />
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import billingRepo from '@/repositories/employer/billing';
import ModalInvoice from '@/views/employer/settings/config/ModalInvoice.vue';
import PaymentMethod from '@/views/employer/settings/config/billing/PaymentMethod.vue';
import ModalSubscription from '@/views/employer/ModalSubscription.vue';
import globalRepo from '@/repositories/global';
import trainingRepo from '@/repositories/employer/trainings';

export default {
    setup(props, { emit }) {
        const route = useRoute();
        const { promoted_job_count, job_post_count } = globalRepo();
        const { insertCustomer, activeSubscription, getPaymentIntent, listPaymentMethods, unSubscribe, methods, subscription, invoices, payment, company, status, errors, cancellations } = billingRepo();
        const { getBillings, billings } = trainingRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            modalHeader: 'Add Payment Method',
            plan_id: '',
            elements: null,
            errorMessage: '',
            paymentProcessing: false,
            isLoading: true,
            isTabLoading: true,
            subscription_id: 0,
            subscription_end: '',
            current_view: 'account',
            selectedPlan: '',
            hasCancellations: false,
            isActive: false,
            user_id: JSON.parse(localStorage.getItem('authuser')).id
        });
        const subscriptionActive = ref(false);
        const modalInvoiceActive = ref(false);
        const cancelSubscriptionConfirmation = ref(false);
        const billing = ref([]);
        const stripe = ref({});
        const cardElement = ref({});
        const selectPlan = ref(false);
        const isSubscribed = ref(false);
        const free = ref(process.env.VUE_APP_FREE_AMOUNT);
        const pro = ref(297);
        const premium = ref(447);
        const subscription_type = ref('');
        const employer = reactive({
            fname: '',
            lname: '',
            email: '',
            address: '',
            city: '',
            state: '',
            postal_code: ''
        });
        const headers = [
            {
                key: 'number',
                label: 'Invoice Number'
            },
            {
                key: 'subscription',
                label: 'Name'
            },
            {
                key: 'created',
                label: 'Date Created'
            },
            {
                key: 'status',
                label: 'Status',
                class: 'text-center'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const toggleModalSubscription = () => {
            subscriptionActive.value = !subscriptionActive.value;
        }

        const toggleTabLoading = () => {
            page.isTabLoading = !page.isTabLoading;
        }

        const toggleInvoiceModal = () => {
            modalInvoiceActive.value = !modalInvoiceActive.value;
        }

        const toggleCancelSubcription = () => {
            cancelSubscriptionConfirmation.value = !cancelSubscriptionConfirmation.value;
        }

        const subscribe = async () => {
            errors.value = [];
            if(subscription_type.value === 'Free') {
                page.plan_id = free.value;
                selectPlan.value = false;
                payment_method.value = '';
            }

            if(subscription_type.value === 'Pro') {
                page.plan_id = process.env.VUE_APP_PRO_AMOUNT;
                selectPlan.value = true;
            }

            if(subscription_type.value === 'Premium') {
                page.plan_id = process.env.VUE_APP_PREMIUM_AMOUNT;
                selectPlan.value = true;
            }
        }

        const saveDefaultPaymentMethod = async () => {
            let formData = new FormData();

            const { paymentMethod, error } = await stripe.value.createPaymentMethod(
                'card', cardElement.value, {
                    billing_details: {
                        name: `${employer.fname} ${employer.lname}`,
                        email: employer.email,
                        address: {
                            line1: employer.address,
                            city: employer.city,
                            state: employer.state,
                            postal_code: employer.postal_code
                        }
                    }
                }
            );

            if(error === undefined) {
                page.paymentProcessing = true;
            }

            formData.append('payment_method_id', paymentMethod.id);
            formData.append('pm_type', paymentMethod.type);
            formData.append('pm_last_four', paymentMethod.card?.last4);
            formData.append('plan_id', page.plan_id);
            formData.append('account_type', subscription_type.value);
            formData.append('user_id', page.authuser.id);
            formData.append('fname', employer.fname);
            formData.append('lname', employer.lname);
            formData.append('email', employer.email);
            formData.append('address', employer.address);
            formData.append('city', employer.city);
            formData.append('state', employer.state);
            formData.append('postal_code', employer.postal_code);

            await insertCustomer(page, formData);
            if(status.value == 200) {
                await activeSubscription(page);
                isSubscribed.value = true;
                page.paymentProcessing = false;
                emit('refreshPage');
            }
        }

        const convertDate = (timestamp) => {
            let day = moment.unix(timestamp);
            return day.format('DD MMMM YYYY');
        }

        const viewPayment = async (id) => {
            page.isLoading = true;
            await getPaymentIntent(page, id);
            if(status.value == 200) {
                page.modalHeader = `Invoice ${payment.value.invoice?.number}`;
                toggleInvoiceModal();
                page.isLoading = false;
            }
        }

        const refreshPage = async () => {
            await activeSubscription(page);
        }

        const changeSubscription = () => {
            subscription_type.value = company.value.account_type;
        }

        const cancelSubscription = () => {
            toggleCancelSubcription();
        }

        const processCancelSubscription = async () => {
            page.isTabLoading = true;
            toggleCancelSubcription();
            await unSubscribe(page);
            await activeSubscription(page);
            page.subscription_id = subscription.value ? subscription.value?.id : 0;
            page.hasCancellations = cancellations.value ? true : false;
            page.isTabLoading = false;
        }

        const showPaymentMethods = () => {
            page.current_view = 'payment_method';
        }

        const showBillingAccount = () => {
            page.current_view = 'account';
        }

        const choosePlan = (type) => {
            page.selectedPlan = type;
            toggleModalSubscription();
        }

        onMounted( async () => {
            let dt = new Date(); //date today
            let dnm = new Date(dt.setMonth(dt.getMonth()+1)); //date next month
            isSubscribed.value = (page.authuser.stripe_id !== null);

            if(isSubscribed.value) {
                await activeSubscription(page);
                let de = subscription.value ? new Date(subscription.value.ends_at) : dnm; // date end
                page.subscription_id = subscription.value ? subscription.value?.id : 0;
                page.hasCancellations = cancellations.value ? true : false;
                page.subscription_end = de.toLocaleDateString('en-US', { timeZone: 'EST', year: 'numeric', month: 'long', day: 'numeric' });
                //page.subscription_end = de.getDate() + ' ' + de.getMonth() + ', ' + de.getFullYear();
            }
            changeSubscription();
            toggleTabLoading();
            await getBillings(page);

            if(route.query.tab == 'billings') {
                page.isActive = true;
            }
        });

        return {
            page,
            billing,
            selectPlan,
            free,
            pro,
            premium,
            subscription_type,
            subscribe,
            employer,
            stripe,
            cardElement,
            saveDefaultPaymentMethod,
            errors,
            insertCustomer,
            activeSubscription,
            subscription,
            invoices,
            headers,
            convertDate,
            status,
            company,
            isSubscribed,
            modalInvoiceActive,
            toggleInvoiceModal,
            viewPayment,
            getPaymentIntent,
            payment,
            listPaymentMethods,
            methods,
            refreshPage,
            cancelSubscription,
            processCancelSubscription,
            toggleCancelSubcription,
            cancelSubscriptionConfirmation,
            showPaymentMethods,
            showBillingAccount,
            toggleModalSubscription,
            subscriptionActive,
            choosePlan,
            cancellations,
            promoted_job_count,
            job_post_count,
            getBillings,
            billings
        }
    },
    components: {
        ModalInvoice,
        PaymentMethod,
        ModalSubscription
    }
}
</script>

<style>
label {
  width: 100%;
  font-size: 1rem;
}
.tier-height {
    height: 120px;
}
.card-input-element+.card {
  color: #3f80ea;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid transparent;
  border-radius: 4px;
}

.card-input-element+.card:hover {
  cursor: pointer;
}

.card-input-element:checked+.card {
  border: 2px solid #3f80ea;
  -webkit-transition: border .3s;
  -o-transition: border .3s;
  transition: border .3s;
}

.card-input-element:checked+.card::after {
  content: '';
  color: #AFB8EA;
  font-family: "Font Awesome 6 Brands";
  font-size: 24px;
  -webkit-animation-name: fadeInCheckbox;
  animation-name: fadeInCheckbox;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.p-20 {
  padding: 20px !important;
}

@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

.card .overlay, .info-box .overlay, .overlay-wrapper .overlay, .small-box .overlay {
    border-radius: 0.25rem;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255,255,255,.7);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 50;
}

.card>.loading-img, .card>.overlay, .info-box>.loading-img, .info-box>.overlay, .overlay-wrapper>.loading-img, .overlay-wrapper>.overlay, .small-box>.loading-img, .small-box>.overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.tab .nav-tabs .nav-link.active {
    box-shadow: 0px -1px 1px 0px #aaaaaa;
}
.tab .tab-content {
    padding: 0 !important;
}
.tab-spinner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 20%;
    background: #e3e3e3bf;
    text-align: center;
    font-size: 40px;
    color: #3f80ea;
}
.package-details span {
    width: 100%;
    display: inline-block;
    text-align: left;
    margin-bottom: 2px;
}
.bulleted {
    margin-right: 2px;
    width: 15px;
    text-align: center;
}
.package-wrap h1 {
    font-size: 21px;
}
.package-price h2 {
    font-size: 14px;
    color: #2170ff;
}
.package-price h3 {
    font-size: 20px;
    margin-bottom: 3px;
    padding-bottom: 3px;
}
.package-wrap h1 span {
    color: #fff;
    padding: 4px 10px;
    font-size: 14px;
    float: right;
    border-radius: 3px;
}
.package-wrap h1 span.active {
    background: #4bbf73;
    margin-right: 3px;
}
.package-wrap h1 span.until {
    background: #d9534f;
}
.card.subscription-item {
    min-height: 240px;
    border: 1px solid #e4e4e4;
}
.manage-payment-method-wrapper {
    float: right;
    margin-top: -55px;
}
.subscription-item.active {
    border: 1px solid #4bbf73;
}
.card.section-items {
    border-top: 1px solid #cecece;
    border-radius: 0;
}
.upgrade-button button {
    width: 100%;
    margin-top: 12px;
    padding: 8px 0;
    font-size: 16px;
}
.back-button {
    position: absolute;
    z-index: 99;
    right: 25px;
    top: 26px;
}
</style>