<template>
    <div>
        <Modal :modalActive="modalActive" :class="`modal-dialog modal-dialog-centered`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <BaseInput
                            v-model="training_source.name"
                            label="Source Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.name }"
                            id="name"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <BaseInput
                            v-model="training_source.monthly"
                            label="Price Monthly"
                            type="number"
                            :class="{ 'is-invalid' : errors && errors.monthly }"
                            id="monthly"
                            :errors="errors"
                        />
                    </div>
                    <div class="col-6">
                        <BaseInput
                            v-model="training_source.monthly_price"
                            label="Monthly Price ID"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.monthly_price }"
                            id="monthly_price"
                            :errors="errors"
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <BaseInput
                            v-model="training_source.yearly"
                            label="Price Yearly"
                            type="number"
                            :class="{ 'is-invalid' : errors && errors.yearly }"
                            id="yearly"
                            :errors="errors"
                        />
                    </div>
                    <div class="col-6">
                        <BaseInput
                            v-model="training_source.yearly_price"
                            label="Yearly Price ID"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.yearly_price }"
                            id="yearly_price"
                            :errors="errors"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary btn-savechanges" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import trainingRepo from '@/repositories/admin/trainings';
import { watch } from '@vue/runtime-core';

export default {
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: ''
        },
        source_id: {
            type: [Number, String],
            default: ''
        }
    },
    setup(props, {emit}) {
        const { training_source, getTrainingSource, insertTrainingSource, updateTrainingSource, status, errors } = trainingRepo();

        const close = () => {
            training_source.value = [];
            errors.value = [];
            emit('close');
        }

        const saveChanges = async () => {
            let formData = new FormData();
            formData.append('name', training_source.value.name ?? '');
            formData.append('monthly', training_source.value.monthly ?? '');
            formData.append('yearly', training_source.value.yearly ?? '');
            formData.append('monthly_price', training_source.value.monthly_price ?? '');
            formData.append('yearly_price', training_source.value.yearly_price ?? '');

            if(props.source_id) {
                formData.append('id', props.source_id ?? '');
                formData.append('_method', 'PUT');
                await updateTrainingSource(formData, props.source_id);
            } else {
                await insertTrainingSource(formData);
            }

            if(status.value == 200) {
                emit('saveChanges');
            }
        }

        watch(() => props.source_id, async () => {
            errors.value = [];
            await getTrainingSource(props.source_id);
        });

        return {
            training_source,
            getTrainingSource,
            insertTrainingSource,
            updateTrainingSource,
            status,
            errors,
            close,
            saveChanges
        }
    },
}
</script>