<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-xl modal-dialog-scrollable`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12" v-if="url">
                        <!-- TODO:: use Google PDF viewer when deployed to production https://docs.google.com/viewer?url=${url}&embedded=true -->
                        <iframe :src="`https://docs.google.com/viewer?url=${url}&embedded=true`" width="98%" height="420px"></iframe>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { reactive, onUpdated, ref } from 'vue';
import applicationRepo from '@/repositories/candidate/application';

export default {
    props: {
        id: {
            type: [Number, String],
            required: true
        },
        candidate_id: {
            type: [Number, String],
            required: true
        },
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const url = ref('');
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true
        });
        const { generatePdf } = applicationRepo();

        onUpdated( async () => {
            if(props.id) {
                await generatePdf(props.candidate_id, props.id, url);
            }
        });

        const close = () => {
            emit('close');
        }

        return {
            url,
            page,
            close
        }
    }
}
</script>