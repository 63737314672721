import axios from "axios";
import { ref } from "vue";

export default function userRepo() {
    
    const users = ref([]);
    const archived_users = ref([]);
    const admins = ref([]);
    const logs = ref([]);
    const totalData = ref('');
    const message = ref('');

    const getUsers = async (page) => {
        let response = await axios.get(`admin/users?user_id=${page.authuser.id}`);
        users.value = response.data.data;
        page.isLoading = false;
    }

    const getSearchedUsers = async (page) => {
        let response = await axios.get(`admin/users?user_id=${page.authuser.id}&search_query=${page.querySearch}`);
        users.value = response.data.data;
        page.isLoading = false;
    }

    const getPaginateUsers = async (page, filtertype = null) => {
        let formData = {
            user_id: page.authuser.id,
            length: 10,
            start: page.offSet
        }
        if(filtertype) {
            formData.filter_by = filtertype;
        }
        let response = await axios.post(`admin/users/paginate`, formData);
        users.value = response.data.data;
        totalData.value = response.data.recordsTotal;
        page.isLoading = false;
    }

    const getAdmins = async (page) => {
        let response = await axios.get(`admin/users/admin-users`);
        admins.value = response.data.data;
        page.isLoading = false;
    }

    const getAdminUsers = async (page) => {
        let response = await axios.get(`admin/users/get-admin-logs`);
        users.value = response.data;
        page.isLoading = false;
    }

    const showAdminLogs = async (page, id, user_id) => {
        let response = await axios.get(`admin/users/show-admin-logs?admin_id=${id}&user_id=${user_id}`);
        logs.value = response.data;
        page.isLogsLoading = false;
    }

    const userImpersonate = async (page) => {
        try {
            let response = await axios.post(`admin/users/impersonate`, {id: page.idToImpersonate});
            localStorage.setItem('adminauthuser', JSON.stringify(page.authuser));
            localStorage.setItem('admintoken', page.token);

            localStorage.setItem('authuser', JSON.stringify(response.data.user));
            localStorage.setItem('token', response.data.token);

            // reset notification on localstorage
            let res = await axios.get(`employer/notifications/reset-notification?user_id=${response.data.user.id}`);
            let notification_count = {
                unread_conversations: res.data.notification.unread_conversations,
                unread_notifications: res.data.notification.unread_notifications,
                unread_reminders: res.data.notification.unread_reminders
            }

            localStorage.setItem('notification', JSON.stringify(notification_count));

            if(response.data.qrcode) {
                localStorage.setItem('auth-qrcode', response.data.qrcode);
            }
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const logUserActivity = async (logdata) => {
        try {
            await axios.post(`admin/users/log-activity`, logdata);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const getArchivedUsers = async (page) => {
        let response = await axios.get(`admin/users/archived`);
        archived_users.value = response.data.data;
        page.isLoading = false;
    }

    const permanentlyDestroyUser = async (id) => {
        let response = await axios.get(`admin/users/permanent-delete?user_id=${id}`);
        message.value = response.data.message;
    }

    const reactivateUser = async (id) => {
        let response = await axios.get(`admin/users/restore?user_id=${id}`);
        message.value = response.data.message;
    }

    return {
        users,
        logs,
        admins,
        message,
        getUsers,
        userImpersonate,
        logUserActivity,
        getAdminUsers,
        showAdminLogs,
        getAdmins,
        getPaginateUsers,
        getSearchedUsers,
        totalData,
        archived_users,
        getArchivedUsers,
        permanentlyDestroyUser,
        reactivateUser
    }
}