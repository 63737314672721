<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex">
                <h1 class="h3 mb-3">Applicants List</h1>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex">
                                <div class="float-start w-30">
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="filter-by">Filter by Status</span>
                                        </div>
                                        <select id="filter" class="form-select" aria-describedby="filter-by" v-model="filterby" @change="filterChange">
                                            <option v-for="status in availableStatus" :key="status" :value="status.name">{{ status.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <h3 v-if="page.currentStatus && page.currentStatus != ''" v-text="`${page.currentStatus} Status`"></h3>
                            <table id="datatables-responsive" class="table table-striped" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th>Applicant Name</th>
                                        <th>Status</th>
                                        <th style="width: 200px;">Position Applied</th>
                                        <th>Skills</th>
                                        <th>Contact Number</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="existingApplicants && existingApplicants.length > 0">
                                    <tr v-for="(applicant, index) in existingApplicants" :key="index">
                                        <td>
                                            <div class="clickable-element" @click="openCandidateProfile(applicant.candidate_id)">
                                            {{ applicant.candidate?.name }}<br>
                                            <small v-html="applicant.candidate?.email"></small>
                                            </div>
                                        </td>
                                        <td>
                                            <div style="width: 150px;" v-if="applicant.status_date">
                                                <Popper :content="`Status update by ${applicant.user?.name} last ${applicant.status_date}`" placement="right" arrow hover offsetDistance="20">
                                                    <span class="badge badge-primary bg-secondary cursor-pointer">{{ applicant.deleted_at !== null ? 'Archived' : applicant.status }}</span>
                                                </Popper>
                                                <div v-html="`Applied: ${applicant.date_applied_display}`"></div>
                                            </div>
                                            <div style="width: 150px;" v-else>
                                                <span class="badge badge-primary bg-secondary cursor-pointer">{{ applicant.deleted_at !== null ? 'Archived' : applicant.status }}</span>
                                                <div v-html="`Applied: ${applicant.date_applied_display}`"></div>
                                            </div>
                                        </td>
                                        <td><div v-html="applicant.job?.position_title"></div></td>
                                        <td>
                                            <div v-if="applicant.skills?.skills_obj.length > 0">
                                                <div style="position:relative;width:205px;">
                                                    <div :class="{ 'skills-wrap': true, 'overflown': true }" v-if="applicant.skills">
                                                        <span class="unhovered-skill" v-if="HasSearchedSkill(applicant.skills.skills_arr, page.searchValue)">
                                                            <span v-for="(item, index) in applicant.skills.skills_arr" v-bind:key="index"><span class="badge badge-primary bg-primary" :class="{ 'highlight': (page.searchValue != '' && item.title.toLowerCase().includes(page.searchValue.toLowerCase())), 'hide' : (page.searchValue != '' && !item.title.toLowerCase().includes(page.searchValue.toLowerCase())) }">{{ item.title }}</span></span>
                                                        </span>
                                                        <span class="unhovered-skill" v-else>
                                                            <span class="badge badge-primary bg-primary" v-for="(item, index) in applicant.skills.skills_arr" v-bind:key="index">{{ item.title }}</span>
                                                        </span>
                                                        <span class="hovered-skill">
                                                            <span class="badge badge-primary bg-primary" v-for="(item, index) in applicant.skills.skills_arr" v-bind:key="index">{{ item.title }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td v-show="false">
                                            <div v-if="applicant.candidate?.works.length > 0">
                                                <div v-text="applicant.candidate?.works[0]?.job_title"></div>
                                                <div>{{ applicant.candidate?.works[0]?.company }} &bull; {{ applicant.candidate?.works[0]?.time_period }}</div>
                                            </div>
                                        </td>
                                        <td>{{ applicant.candidate?.contact_number }}</td>
                                        <td class="text-center" style="width:120px;">
                                            <div>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width: 36px; height: 36px;"><i class="fa fa-ellipsis-h"></i></button>
                                                    <ul class="dropdown-menu">
                                                        <li><a class="dropdown-item text-info" href="javascript:;" @click="messageCandidate(applicant.candidate_id)">Message</a></li>
                                                        <li v-if="applicant.deleted_at === null"><a class="dropdown-item text-primary" href="javascript:;" @click="viewDetails(applicant.candidate_id, applicant.job_id, applicant.id, index)">View Details</a></li>
                                                        <li v-if="applicant.deleted_at === null"><a class="dropdown-item text-danger" href="javascript:;" @click="deleteApplication(applicant.id)">Archive</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="6" class="text-center">No data available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`${applicant.fname} ${applicant.lname} Applicant Details`">
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin application-fixed pt-0">
                    <div class="col-12">
                        <div>
                            <div v-for="napplicant in applicants" :key="napplicant.id" style="height: 50px;" class="float-end mt-4">
                                <div v-if="selectedApplicant.id == napplicant.id">
                                    <button :disabled="job === null" :class="`${application.status == 'Hired' ? 'btn btn-outline-secondary btn-lg disabled' : 'btn btn-outline-success btn-lg'}`" @click="reviewApplication">{{ page.reviewApplicationLabel }}</button>&nbsp;
                                    <div class="dropdown float-start mr-5">
                                        <button :disabled="job === null" class="btn btn-primary btn-lg dropdown-toggle btn-change-status" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            Change Status
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <div v-for="status in candidate_statuses" :key="status">
                                                <a class="dropdown-item" href="javascript:;" :class="{ 'active' : isActive(status.name) }" @click="changeStatus(status.name)">{{ status.name }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-group">
                                        <button class="btn btn-outline-primary btn-lg" type="button" disabled v-if="page.prevIsDisabled"><i class="fas fa-chevron-left fa-1x"></i></button>
                                        <button class="btn btn-outline-primary btn-lg" type="button" @click="previous(applicants, napplicant.job_id)" v-else><i class="fas fa-chevron-left fa-1x"></i></button>
                                    
                                        <button class="btn btn-outline-primary btn-lg" type="button" disabled v-if="page.nextIsDisabled"><i class="fas fa-chevron-right fa-1x"></i></button>
                                        <button class="btn btn-outline-primary btn-lg" type="button" @click="next(applicants, napplicant.job_id)" v-else><i class="fas fa-chevron-right fa-1x"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-margin mt-7">
                    <div class="col-12">
                        <h2>{{ applicant.fname }} {{ applicant.lname }} - {{ application.status }}</h2>
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Email Address</td>
                                    <td>{{ applicant.email }}</td>
                                </tr>
                                <tr>
                                    <td>Contact Number</td>
                                    <td>{{ applicant.contact_number }}</td>
                                </tr>
                                <tr>
                                    <td>Complete Address</td>
                                    <td>{{ applicant.complete_address }}</td>
                                </tr>
                                <tr>
                                    <td>Birthdate</td>
                                    <td>{{ applicant.birthdate_display }}</td>
                                </tr>
                                <tr>
                                    <td>Gender</td>
                                    <td>{{ applicant.gender }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="cardlet" v-if="applicant?.skills">
                            <h3><i class="fas fa-user-cog"></i> &nbsp;Skills Showcase</h3>
                            <div class="skills">
                                <div class="skill-item" v-for="skill in applicant?.skills?.skills_arr" :key="skill">
                                    <div>{{ skill.title }}</div>
                                </div>
                            </div>
                        </div>
                        <div style="padding: 20px 0" v-if="applicant?.educations?.length">
                            <h3 style="padding-bottom: 15px"><i class="fas fa-user-graduate"></i> &nbsp;Education</h3>
                            <div class="educations-item works-item" v-for="education in applicant?.educations" :key="education">
                                <div class="row gaps">
                                    <div class="col-2 label">Education Level</div>
                                    <div class="col-10 text-secondary">
                                        <div style="text-transform: capitalize;">{{ education.education_level }}</div>
                                    </div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">School Name</div>
                                    <div class="col-10 text-secondary">
                                        <div>{{ education.school_name }}</div>
                                    </div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">School Address</div>
                                    <div class="col-10 text-secondary">
                                        <div>{{ education.school_address }}</div>
                                    </div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">School Year</div>
                                    <div class="col-10 text-secondary" >
                                        <div>{{ education.school_year }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding-bottom: 20px" v-if="applicant?.works?.length">
                            <h3 style="padding-bottom: 15px"><i class="fas fa-briefcase"></i> &nbsp;Work Experience</h3>
                            <div class="works-item" v-for="work in applicant?.works" :key="work">
                                <div class="row gaps">
                                    <div class="col-2 label">Job Position</div>
                                    <div class="col-10 text-secondary"><div>{{ work.job_title }}</div></div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">Company Name</div>
                                    <div class="col-10 text-secondary"><div>{{ work.company }}</div></div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">Company Address</div>
                                    <div class="col-10 text-secondary"><div>{{ work.company_address }}</div></div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">Work Period</div>
                                    <div class="col-10 text-secondary"><div>{{ work.time_period }}</div></div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">Job Description</div>
                                    <div class="col-10 text-secondary"><div v-html="work.description"></div></div>
                                </div>
                            </div>
                        </div>
                        <div style="padding-bottom: 20px" v-if="applicant?.certificates?.length">
                            <h3 style="padding-bottom: 15px"><i class="fas fa-certificate"></i> &nbsp;Certifications</h3>
                            <div class="certificates-item" v-for="certificate in applicant?.certificates" :key="certificate">
                                <div class="row gaps">
                                    <div class="col-2 label">Certificate Title</div>
                                    <div class="col-10 text-capitalize"><div>{{ certificate.title }}</div></div>
                                </div>
                                <div class="row gaps" v-if="certificate.validity">
                                    <div class="col-2 label">Expires on</div>
                                    <div class="col-10"><div>{{ certificate.validity }}</div></div>
                                </div>
                                <div class="row gaps" v-else>
                                    <div class="col-2 label">Expires on</div>
                                    <div class="col-10"><div>No Expiry</div></div>
                                </div>
                                <div class="row gaps">
                                    <div class="col-2 label">Description</div>
                                    <div class="col-10"><div v-html="certificate.description "></div></div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 mb-5" v-if="application?.questions?.length">
                            <h3 style="padding-bottom: 15px"><i class="fa-regular fa-seal-question"></i> &nbsp;Pre-Screening Questions</h3>
                            <ol class="list-group list-group-numbered">
                                <li class="list-group-item d-flex justify-content-between align-items-start" v-for="question in application?.questions" :key="question">
                                    <div class="ms-2 me-auto">
                                        <div class="fw-bold">{{ question.question }}</div>
                                        {{ question.candidate_answer }}
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay position-fixed full-screen-loader text-primary" v-if="page.isfullScreenLoading">
                <i class="fa fa-spin fa-spinner fa-3x"></i>
            </div>
        </slideout>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="page.confirmText"
            @cancel="toggleConfirmation"
            @isDelete="triggerAction"
        />
        <Confirm
            :is-confirm="isArchiveConfirm"
            :confirm-text="`This application will be rejected (the applicant will be notified) and will be moved to Archived. Do you wish to proceed?`"
            @cancel="toggleArchiveConfirmation"
            @isDelete="processDeleteApplication"
        />
        <ModalApplication
            class="application-modal"
            :modalActive="modalActive"
            :id="page.jobId"
            :candidate_id="page.applicantId"
            :form="application"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
        />
    </main>
</template>

<script>
import { ref, reactive, onMounted, watchEffect, computed } from 'vue';
import { useRouter } from 'vue-router'
import applicantRepo from '@/repositories/employer/applicant';
import globalRepo from '@/repositories/global';
import jobRepo from '@/repositories/jobs';
import ModalApplication from '@/views/employer/applicants/ModalApplication.vue';

export default {
    setup() {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Applicant Details',
            idToDelete: '',
            applicantCount: 1,
            nextIsDisabled: false,
            prevIsDisabled: true,
            tableIndex: 0,
            applicantId: '',
            jobId: '',
            confirmText: '',
            selectedStatus: '',
            currentStatus: '',
            searchValue: '',
            reviewApplicationLabel: 'Review Application',
            isfullScreenLoading: false
        });
        const { applicants, applicant, getApplicants, getApplicant, openApplicant, getCandidateStatus, candidate_statuses, application, updateCandidateStatus, filterCandidates, removeApplication, getArchivedApplicants } = applicantRepo();
        const { job, getJob } = jobRepo();
        const { application_types, application_status } = globalRepo();

        const router = useRouter();
        const visible = ref(false);
        const isConfirm = ref(false);
        const isArchiveConfirm = ref(false);
        const modalActive = ref(false);
        const filterby = ref('');
        const app_type = ref('');
        const selectedApplicant = ref([]);
        const availableStatus = ref([]);

        onMounted(async () => {
            getApplicants(page);
            await getCandidateStatus();
            if(router.currentRoute.value && router.currentRoute.value.name == 'employer.active-applicants'){
                filterby.value = 'Active';
                filterChange();
            }

            candidate_statuses.value.push({ name: application_status.archived });
            
            candidate_statuses.value.forEach(item => {
                if(item.name != 'Hired') {
                    availableStatus.value.push({
                        id: item.id,
                        name: item.name
                    });
                }
            });
        });

        watchEffect(() => {
            if(page.tableIndex === 0) {
                page.prevIsDisabled = true;
            } else {
                page.prevIsDisabled = false;
            }

            if(page.tableIndex === (applicants.value.length-1)) {
                page.nextIsDisabled = true;
            } else {
                page.nextIsDisabled = false;
            }
        })

        const previous = async (applicants, job_id) => {
            page.tableIndex = page.tableIndex-1;
            if(page.tableIndex !== -1) {
                let app = applicants[page.tableIndex];
                await getApplicant(app.candidate_id, app.job_id);
                await openApplicant(app.candidate_id, app.job_id);
                await getJob(app.job_id, page);
                page.applicantId = app.candidate_id;
                page.jobId = app.job_id;
                page.selectedStatus = '';
                visible.value = true;
                selectedApplicant.value = app
            }
            page.applicantCount--;

        }

        const next = async (applicants, job_id) => {
            page.tableIndex = page.tableIndex+1;
            if(page.tableIndex !== applicants.length) {
                let app = applicants[page.tableIndex];
                await getApplicant(app.candidate_id, app.job_id);
                await openApplicant(app.candidate_id, app.job_id);
                await getJob(app.job_id, page);
                page.applicantId = app.candidate_id;
                page.jobId = app.job_id;
                page.selectedStatus = '';
                visible.value = true;
                selectedApplicant.value = app
            }

            page.applicantCount++;
        }

        const onClosing = (e) => {
            // close after 3 seconds
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                e.close = true
                getApplicants(page);
            }, 500)
        }

        const viewDetails = async (id, job_id, applicant_id, index) => {
            visible.value = true;
            await getApplicant(id, job_id);
            await openApplicant(id, job_id);
            await getJob(job_id, page);
            page.applicantId = id;
            page.jobId = job_id;
            page.selectedStatus = '';
            selectedApplicant.value = applicants.value.filter(app => app.id == applicant_id)[0]
            page.tableIndex = index
            page.reviewApplicationLabel = application.value.status == 'Hired' ? 'Application Reviewed' : 'Review Application';
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const toggleArchiveConfirmation = () => {
            isArchiveConfirm.value = !isArchiveConfirm.value;
        }

        const changeStatus = (status) => {
            toggleConfirmation();
            page.confirmText = 'Are you sure you want to change status of this applicant?';
            page.selectedStatus = status;
        }

        const isActive = (status) => {
            return application.value.status === status;
        }

        const triggerAction = async () => {
            toggleConfirmation();
            page.isfullScreenLoading = true;
            await updateCandidateStatus(page);
            await getApplicant(page.applicantId, page.jobId);
            await getApplicants(page);
            page.isfullScreenLoading = false;
        }

        const filterChange = () => {
            page.isLoading = true;
            if(filterby.value === 'Archived') {
                getArchivedApplicants(page);
            } else {
                filterCandidates(page, filterby.value);
            }
            page.currentStatus = filterby.value;
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const reviewApplication = () => {
            toggleModal();
        }

        const HasSearchedSkill = (skills_arr, searchValue) => {
            let hasSearchedString = false;
            for(var i=0;i<skills_arr.length;i++){
                if(skills_arr[i].title.toLowerCase().includes(searchValue.toLowerCase())){
                    hasSearchedString = true;
                }
            }
            return hasSearchedString;
        }

        const existingApplicants = computed(() => {
            return applicants.value.filter(item => item.candidate !== null && item.status !== 'Hired');
        })

        const deleteApplication = (id) => {
            page.idToDelete = id;
            toggleArchiveConfirmation();
        }

        const processDeleteApplication = async () => {
            toggleArchiveConfirmation();
            page.isLoading = true;
            await removeApplication(page);
            await getApplicants(page);
        }

        const messageCandidate = (id) => {
            router.push(`/employer/messages/${id}`);
        }

        const openCandidateProfile = (id) => {
            router.push(`/employer/applicant/${id}`);
        }

        return {
            page,
            applicants,
            applicant,
            getApplicants,
            getApplicant,
            previous,
            next,
            onClosing,
            visible,
            filterby,
            viewDetails,
            openApplicant,
            application_types,
            app_type,
            isConfirm,
            toggleConfirmation,
            getCandidateStatus,
            candidate_statuses,
            changeStatus,
            application,
            isActive,
            triggerAction,
            updateCandidateStatus,
            filterChange,
            filterCandidates,
            toggleModal,
            modalActive,
            reviewApplication,
            selectedApplicant,
            HasSearchedSkill,
            job,
            existingApplicants,
            deleteApplication,
            toggleArchiveConfirmation,
            isArchiveConfirm,
            processDeleteApplication,
            messageCandidate,
            openCandidateProfile,
            availableStatus
        }
    },
    components: {
        ModalApplication
    }
}
</script>

<style>
.w-15 {
    width: 15%;
}
.w-70 {
    width: 70%;
}
.pl-20 {
    padding-left: 20px;
}
.tab-btn {
    position: absolute;
    top: 20px;
    right: 15px;
}
.slideout-header--text {
    font-weight: bold;
}
.no-margin {
    margin: 0px !important;
}
.application-fixed {
    position: fixed;
    width: 100%;
    background: #f3f3f3;
    top: 48px;
    right: 15px;
}
.mr-5 {
    margin-right: 5px;
}
.application-modal .modal-backdrop.show {
    z-index: 1999;
    opacity: 0.65;
}
.capitalize {
    text-transform: capitalize;
}
.educations-item, .works-item, .certificates-item {
    padding: 20px;
    background: #ededed;
    border-radius: 7px;
    margin-bottom: 10px;
}
.educations-item .label, .works-item .label, .certificates-item .label {
    font-size: 13px;
    font-weight: bold;
    color: #495057;
}
.educations-item .gaps, .works-item .gaps, .certificates-item .gaps {
    margin: 7px 0px;
}
.skills {
    padding: 20px 20px;
    background: #ededed;
    border-radius: 8px;
    margin-bottom: 15px;
}
.skills h3 {
    margin-bottom: 15px;
}
.skill-item {
    display: inline-block;
    padding: 2px 10px;
    font-size: 14px;
    margin-right: 8px;
    background: #3f80ea;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 5px;
}
.cardlet {
    margin: 35px 0 0;
}
.cardlet h3 {
    margin-bottom: 20px;
}
.hovered-skill {
    display:none;
}
.skill-tag {
    padding: 4px 5px;
    border-radius: 3px;
    background: #3f80ea;
    color: #FFF;
    margin: 0 2px 4px;
    font-size: 12px;
    display: inline-block;
}
.badge-primary {
    margin-right: 3px;
}
.skills-wrap {
    width: 205px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
}
.skills-wrap.overflown:hover {
    white-space: normal;
    position: absolute;
    background: #FFF;
    padding: 10px 10px;
    top: -35px;
    border: 1px solid #888;
    border-radius: 6px;
    box-shadow: 0px -1px 7px 2px #3e3e3e57;
    z-index: 9999;
    cursor: default;
}
.skills-wrap.overflown:hover .unhovered-skill {
    display: none;
}
.skills-wrap.overflown:hover .hovered-skill {
    display: block;
}
.application-modal .modal {
    z-index: 99999;
}
</style>
<style scoped>
.full-screen-loader {
    z-index: 9999999999 !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #4d4d4d40;
    text-align: center;
    padding: 35%;
}
#filter {
    width: auto;
}
</style>
<style scoped>
.clickable-element {
    cursor: pointer;
}
.clickable-element:hover {
    color: #2276ff;
    text-decoration: underline;
}
</style>