<template>
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <h2 class="text-center position-relative"><a href="#" class="position-absolute backbutton" @click="backToPackage"><i class="fa fa-arrow-left"></i></a>You are about to purchase <strong style="color: #005cec;">Job Boost</strong> feature.</h2>
                <p class="text-center">
                    Please select payment method below:
                </p>
                <div class="mt-4 payment-methods-wrapper" v-if="!page.isAddPaymentMethod">
                    <div class="payment-method-item add-method" @click="addPaymentMethod">
                        <div class="row">
                            <div class="col-3 add-icon">
                                <i class="fas fa-plus"></i> 
                            </div>
                            <div class="col-9 card-details addpayment-method">
                                <div class="card-details-name">Add Payment Method</div>
                            </div>
                        </div>
                    </div>
                    <div class="payment-method-item" v-for="item in methods" :key="item" @click="subscribeMe(item)">
                        <div class="row">
                            <div class="col-3 card-icon">
                                <i class="fab fa-cc-stripe"></i>
                            </div>
                            <div class="col-9 card-details">
                                <div class="card-details-name">{{ item.account_name }}</div>
                                <div class="card-details-number">&bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; {{ item.last4 }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay position-absolute" v-if="page.isPaymentMethodsLoading">
                        <i class="fa fa-spin fa-spinner fa-3x"></i>
                    </div>
                </div>
                <div class="mt-4 add-payment-method-wrapper" v-else>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-6">
                                <BaseInput 
                                    v-model="page.paymentMethodDetails.fname"
                                    autocomplete="off"
                                    :marginBottomOn="false"
                                    label="First Name"
                                    placeholder="John"
                                    type="text"
                                    :class="{ 'is-invalid' : errors && errors.fname }"
                                    id="fname"
                                    :errors="errors"
                                    is-required
                                    :disabled="!selectPlan"
                                />
                            </div>
                            <div class="col-6">
                                <BaseInput 
                                    v-model="page.paymentMethodDetails.lname"
                                    autocomplete="off"
                                    :marginBottomOn="false"
                                    placeholder="Smith"
                                    label="Last Name"
                                    type="text"
                                    :class="{ 'is-invalid' : errors && errors.lname }"
                                    id="lname"
                                    :errors="errors"
                                    is-required
                                    :disabled="!selectPlan"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <BaseInput 
                                    v-model="page.paymentMethodDetails.email"
                                    autocomplete="off"
                                    :marginBottomOn="false"
                                    placeholder="johnsmith@example.com"
                                    label="Email Address"
                                    type="email"
                                    :class="{ 'is-invalid' : errors && errors.email }"
                                    id="email"
                                    :errors="errors"
                                    is-required
                                    :disabled="!selectPlan"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <BaseInput 
                                    v-model="page.paymentMethodDetails.address"
                                    autocomplete="off"
                                    :marginBottomOn="false"
                                    placeholder="Street #, Block #, House #"
                                    label="Address"
                                    type="text"
                                    id="address"
                                    :disabled="!selectPlan"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <BaseInput 
                                    v-model="page.paymentMethodDetails.city"
                                    autocomplete="off"
                                    :marginBottomOn="false"
                                    placeholder="Your City"
                                    label="City"
                                    type="text"
                                    id="city"
                                    :disabled="!selectPlan"
                                />
                            </div>
                            <div class="col-6">
                                <BaseInput 
                                    v-model="page.paymentMethodDetails.state"
                                    autocomplete="off"
                                    :marginBottomOn="false"
                                    placeholder="Your State"
                                    label="State"
                                    type="text"
                                    id="state"
                                    :disabled="!selectPlan"
                                />
                            </div>
                            <div class="col-6">
                                <BaseInput 
                                    v-model="page.paymentMethodDetails.postal_code"
                                    autocomplete="off"
                                    :marginBottomOn="false"
                                    placeholder="eg. 1234"
                                    label="Postal Code"
                                    type="text"
                                    id="postal_code"
                                    :disabled="!selectPlan"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-12 mt-3">
                                    <h5>Credit Card Information</h5>
                                    <div id="card-element" class="form-control" style="height: 34px; padding: 8px;"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 mb-2">
                            <div class="col-12">
                                <button class="btn btn-primary btn-lg btn-save" :disabled="!selectPlan" @click="savePaymentMethod">Add Payment Method</button> &nbsp;
                                <button class="btn btn-secondary btn-lg" @click="cancelAddPaymentMethod">Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div class="overlay position-absolute" v-if="page.isAddPaymentMethodLoading">
                        <i class="fa fa-spin fa-spinner fa-3x"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay position-fixed subscriptionloader" v-if="page.isJobPromotionBoostLoading">
            <i class="fa fa-spin fa-spinner fa-3x"></i>
        </div>
        <Confirm 
            :is-confirm="isConfirm"
            :confirmType="`subscription`"
            :confirmTitle="`Confirm Purchase`"
            :confirmText="`You are about to continue purchasing (Job Promotion) for $${page.promotion_amount}/month. Are you sure you want to proceed using the selected payment method?`"
            :confirmButton="`Yes, Confirm my Purchase`"
            @cancel="toggleConfirmation"
            @isDelete="processPurchasingJobPromotionBoost"
        />
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import billingRepo from '@/repositories/employer/billing';

export default defineComponent({
    props: {
        planDetails: {
            type: Object,
            default: {}
        }
    },
    setup(props, { emit }) {
        const { processSavePaymentMethod,
                errors,
                status, 
                listPaymentMethods, 
                methods, 
                purchaseJobPromotionBoost 
        } = billingRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            api_url: process.env.VUE_APP_API_URL,
            stripe_key: process.env.VUE_APP_STRIPE_KEY,
            details: props.planDetails,
            isAddPaymentMethod: false,
            isAddPaymentMethodLoading: false,
            isPaymentMethodsLoading: true,
            isJobPromotionBoostLoading: false,
            elements: null,
            paymentMethodDetails: {
                fname: '',
                lname: '',
                email: '',
                address: '',
                city: '',
                state: '',
                postal_code: '',
            },
            plan_id: '',
            promotion_amount: 47,
            plan_type: '',
            payment_method_id: '',
            payment_method_last_four: '',
            payment_method: {}
        });
        const stripe = ref({});
        const cardElement = ref({});
        const selectPlan = ref(false);
        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        onMounted( async () => {
            if(page.details) {
                selectPlan.value = true;
            }
            await listPaymentMethods(page);
            page.isPaymentMethodsLoading = false;
        });

        const backToPackage = () => {
            cancelAddPaymentMethod();
            emit('backEvent');
        }

        const addPaymentMethod = async () => {
            page.isAddPaymentMethod = true;
            stripe.value = await loadStripe(page.stripe_key);
            page.elements = stripe.value.elements();
            cardElement.value = page.elements.create('card', {
                hidePostalCode: true
            });
            cardElement.value.mount('#card-element');
        }

        const cancelAddPaymentMethod = () => {
            page.isAddPaymentMethod = false;
            stripe.value = {};
            cardElement.value = {};
        }

        const savePaymentMethod = async () => {     
            let formData = new FormData();

            const { paymentMethod, error } = await stripe.value.createPaymentMethod(
                'card', cardElement.value, {
                    billing_details: {
                        name: `${page.paymentMethodDetails.fname} ${page.paymentMethodDetails.lname}`,
                        email: page.paymentMethodDetails.email,
                        address: {
                            line1: page.paymentMethodDetails.address,
                            city: page.paymentMethodDetails.city,
                            state: page.paymentMethodDetails.state,
                            postal_code: page.paymentMethodDetails.postal_code
                        }
                    }
                }
            );

            if(error === undefined) {
                page.isAddPaymentMethodLoading = true;
            }

            formData.append('payment_method_id', paymentMethod.id);
            formData.append('pm_type', paymentMethod.type);
            formData.append('pm_last_four', paymentMethod.card?.last4);
            formData.append('account_type', page.details.plan_type);
            formData.append('user_id', page.authuser.id);
            formData.append('fname', page.paymentMethodDetails.fname);
            formData.append('lname', page.paymentMethodDetails.lname);
            formData.append('email', page.paymentMethodDetails.email);
            formData.append('address', page.paymentMethodDetails.address);
            formData.append('city', page.paymentMethodDetails.city);
            formData.append('state', page.paymentMethodDetails.state);
            formData.append('postal_code', page.paymentMethodDetails.postal_code);

            await processSavePaymentMethod(page, formData);
            if(status.value == 200) {
                page.isAddPaymentMethodLoading = false;
                cancelAddPaymentMethod();
                page.isPaymentMethodsLoading = true;
                await listPaymentMethods(page);
                page.isPaymentMethodsLoading = false;
            }
        }

        const subscribeMe = (payment_method_obj) => {
            page.plan_type = page.details.plan_type;
            page.payment_method_id = payment_method_obj.id;
            page.payment_method_last_four = payment_method_obj.last4;
            page.payment_method = payment_method_obj;
            toggleConfirmation();
        }

        const processPurchasingJobPromotionBoost = async () => {
            toggleConfirmation();
            let formData = new FormData();
            formData.append('user_id', page.authuser.id);
            formData.append('company_id', page.authuser.company_id);
            formData.append('promotion_type', 'Job Promotion');
            formData.append('amount', page.promotion_amount);
            

            page.isJobPromotionBoostLoading = true;
            await purchaseJobPromotionBoost(formData);
            page.isJobPromotionBoostLoading = false;
            emit('subscribeSuccess');
        }

        return {
            page,
            backToPackage,
            addPaymentMethod,
            selectPlan,
            cancelAddPaymentMethod,
            savePaymentMethod,
            methods,
            isConfirm,
            toggleConfirmation,
            subscribeMe,
            processPurchasingJobPromotionBoost,
            errors
        }
    }
});
</script>

<style scoped>
.payment-methods-wrapper, .add-payment-method-wrapper {
    background: #eee;
    border-radius: 4px;
    padding: 20px;
    min-height: 400px;
    position: relative;
}
.payment-method-item {
    width: 100%;
    max-width: 260px;
    background: #fffcfb;
    border-radius: 5px;
    padding: 12px 15px;
    cursor: pointer;
    box-shadow: 0px 0px 4px 4px #d9d9d9;
    display: inline-block;
    margin-right: 15px;
    min-height: 74px;
    margin-bottom: 15px;
}
.card-icon {
    font-size: 30px;
    text-align: center;
}
.addpayment-method {
    margin-top: 14px;
    font-weight: bold;
}
.payment-method-item:hover {
    background: #3f80ea;
    color: #FFF;
}
.backbutton {
    left: 2px;
    top: -48px;
    font-size: 17px;
    color: #808080;
}
.overlay.position-absolute {
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    background: #91919180;
    padding-top: 20%;
}
.invoice-info {
    background: #FFF;
    padding: 20px 20px;
    border-radius: 5px;
}
.subscriptionloader {
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #8c8c8c80;
    text-align: center;
    padding: 19%;
}
</style>