<template>
    <div>
        <AdvanceModal :modalActive="modalActive" :class="`modal-dialog modal-xl modal-dialog-centered`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">File Manager</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div style="height: 400px; overflow: auto;">
                    <div class="row">
                        <div class="col-md-4 mt-3" v-for="(photo, index) in photos" :key="index">
                            <div class="div-training-checkbox">
                                <input type="radio" class="cls-training-checkbox" :id="`radio-${index}`" style="margin: 10px;" v-model="state.selectedImage" :value="photo">
                            </div>
                            <label class="no-decor" :for="`radio-${index}`">
                                <div class="card">
                                    <img class="card-img-top-interview-training" :src="`${state.image_url}/${photo}`" alt="training" />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <div>
                        <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                    </div>
                    <div>
                        <input type="file" style="display: none" class="form-control" ref="fileInput" @change="onFileChange" enctype="multipart/form-data">
                        <button type="button" class="btn btn-primary" @click="$refs.fileInput.click()">
                            <div v-if="imageFilename == ''">
                                <i class="fa-regular fa-cloud-arrow-up"></i> &nbsp;Upload New Image
                            </div>
                            <div v-else><i class="fa-regular fa-cloud-arrow-up"></i> &nbsp;{{ imageFilename }}</div>
                        </button> &nbsp;
                        <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                    </div>
                </div>
            </template>
        </AdvanceModal>
    </div>
</template>

<script>
import trainingRepo from '@/repositories/admin/trainings';
import { onMounted, reactive, ref, watch } from 'vue';

export default {
    props: {
        modalActive: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const state = reactive({
            isLoading: true,
            image_url: process.env.VUE_APP_UPCLOUD_ASSETS,
            selectedImage: ''
        });
        const imageFile = ref('');
        const imageFilename = ref('');
        const { getPhotos, photos } = trainingRepo();

        const onFileChange = (evt) => {
            let fileImage = evt.target.files[0];
            imageFile.value = fileImage;
            imageFilename.value = evt.target.files[0].name;
        }

        const saveChanges = () => {
            if(imageFilename.value != '') {
                state.selectedImage = '';
            }

            let data = {
                imageFile: imageFile.value,
                imageSelected: state.selectedImage,
                imageFilename: imageFilename.value ?? '',
                imageType: imageFile.value ? 1 : 2
            }
            emit('save-photo', data);
        }

        const close = () => {
            imageFile.value = '';
            imageFilename.value = '';
            emit('close-modal');
        }

        watch(() => state.selectedImage, () => {
            if(imageFilename.value != '') {
                imageFile.value = '';
                imageFilename.value = '';
            }
        });

        onMounted( async () => {
            await getPhotos(state);
        });

        return {
            state,
            getPhotos,
            photos,
            saveChanges,
            close,
            onFileChange,
            imageFile,
            imageFilename
        }
    }
}
</script>

<style>
.image-course {
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
}
.image-active {
    opacity: 0.7;
    border: 4px solid #3f80ea;
}
</style>