<template>
    <div>
        <Modal :className="`modal-dialog modal-lg-1000`" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">What plan do you want?</h5>
                    <button type="button" class="btn-close" @click="backToJobDetails" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div>
                    <div class="row text-secondary" id="upgrade-option-container" v-if="!state.isPlanSelected">
                        <div class="col-12">
                            <div class="mt-5">
                                <div class="text-center" :class="{ 'feature-logo' : !fromJobPosting }"><img :src="`${state.apiEndpoint}/simphini-logo.svg`" width="100"></div>
                                <h2 class="text-center mb-4">Select Your Plan</h2>
                            </div>
                            <div id="subscriptions" class="m-3 mt-4 mb-5">
                                <div class="row">
                                    <div class="col-4">
                                        <div style="background: #E5E9F1;">
                                            <div class="border p-3 rounded pro">
                                                <h5>SINGLE USE</h5>
                                                <p class="mb-2 sub-title">Good for 1 application only</p>
                                                <h3>$24/application</h3>
                                                <div class="price-tag-button">
                                                    <button class="btn" @click="singleApplication(application_id)">Select</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div style="background: #E5E9F1;">
                                            <div class="border p-3 rounded pro">
                                                <h5>MONTHLY SUBSCRIPTION</h5>
                                                <p class="mb-2 sub-title">Good for 25 applications</p>
                                                <h3>$7/month</h3>
                                                <div class="price-tag-button">
                                                    <button class="btn" @click="selectPlan('Research Help (Pro)','$7 monthly for 25 applications')">Select</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div style="background: #E5E9F1;">
                                            <div class="border p-3 rounded premium">
                                                <h5>MONTHLY SUBSCRIPTION</h5>
                                                <p class="mb-2 sub-title">Good for 70 applications</p>
                                                <h3>$15/month</h3>
                                                <div class="price-tag-button">
                                                    <button class="btn" @click="selectPlan('Research Help (Premium)','$15 monthly for 70 applications')">Select</button>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row text-secondary" v-else>
                        <component :is="currentComponent" :planDetails="state.planDetails" @backEvent="back" @subscribeSuccess="changeComponent" @triggerAction="backToJobDetails"></component>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="backToJobDetails">Cancel</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import SelectPaymentMethod from '@/components/billing/SelectPaymentMethod.vue';
import UpgradeSuccess from '@/components/billing/UpgradeSuccess.vue';
import globalRepo from '@/repositories/global';
import jobRepo from '@/repositories/candidate/jobs';

export default defineComponent({
    props: ['modalActive','modalTitle','close','application_id','isBillingPage','planToUpgrade','fromJobPosting'],
    setup(props, { emit }) {
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            apiEndpoint: process.env.VUE_APP_API_URL,
            isPlanSelected: false,
            planDetails: {
                plan_type: '',
                subscription_type: '',
                application_id: 0
            },
            displayResearch: false,
            amount: process.env.VUE_APP_RESEARCH_HELP,
            price: 24.00,
            isUserSubscribed: false
        });
        const { promoted_job_count, job_post_count } = globalRepo();
        const { storeSingleApplication, status, urlRedirect } = jobRepo();
        const currentComponent = ref('SelectPaymentMethod');
        
        const saveChanges = () => {
            emit('saveChanges');
        }

        const back = () => {
            state.isPlanSelected = false;
            state.planDetails.plan_type = '';
            state.planDetails.subscription_type = '';
            state.planDetails.application_id = 0;
        }

        const changeComponent = () => {
            currentComponent.value = 'UpgradeSuccess';
            state.isUserSubscribed = true;
        }

        const backToJobDetails = () => {
            state.isPlanSelected = false;
            state.isPlanSelected = false;
            state.planDetails.plan_type = '';
            state.planDetails.subscription_type = '';
            state.planDetails.application_id = 0;
            emit('render-research', state.isUserSubscribed);
        }

        const selectPlan = (plan_type, subscription_type) => {
            state.planDetails.plan_type = plan_type;
            state.planDetails.subscription_type = subscription_type;
            state.planDetails.application_id = props.application_id;
            state.isPlanSelected = true;
        }

        const singleApplication = async (application_id) => {
            let formData = new FormData();
            formData.append('amount', state.amount);
            formData.append('price', state.price);
            formData.append('product', 'Research Help (Single Use)');
            formData.append('application_id', application_id);
            await storeSingleApplication(formData);

            if(status.value == 200) {
                window.open(urlRedirect.value);
            }
        }

        return {
            state,
            close,
            saveChanges,
            currentComponent,
            selectPlan,
            back,
            changeComponent,
            promoted_job_count, 
            job_post_count,
            backToJobDetails,
            singleApplication,
            storeSingleApplication,
            status,
            urlRedirect
        }
    },
    components: {
        SelectPaymentMethod,
        UpgradeSuccess
    }
})
</script>
<style>
.subscription-wrapper {
    min-height: 355px;
}
.benefits span.clp {
    width: 90%;
    color: #333333;
}
.subscription-wrapper {
    margin-bottom: 35px !important;
}
#subscriptions .subcription-item {
    min-height: 355px;
    background:#efefef;
}
/* #subscriptions .front .subcription-item.annually {
    min-height: 385px;
} */
.discount {
    margin-top: -5px;
}
.subcription-item h5 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: -25px;
}
.subcription-item h3 {
    font-size: 30px;
    font-weight: bold;
}
.price-tag {
    border-radius: 5px;
    background: #a5a5a5;
    padding: 11px 13px;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}
.price-tag-button {
    margin-bottom: 15px;
}
.back .price-tag-button {
    margin-top: 15px;
}
.price-tag-button button.btn {
    border-radius: 5px;
    background: #b6b6b6;
    padding: 10px 13px;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    width: 100%;
}
.price-tag-button button.btn a {
    color: #FFF;
    text-decoration: none;
}
.price-tag.active {
    background: #dbf7db;
}
.text-right {
    text-align:right;
}
.subscription-switcher {
    text-align: center;
}
.subscription-switcher-wrapper {
    display: inline-block;
    background: #efefef;
    padding: 10px 20px;
    border-radius: 20px;
}
.switch-field {
    display: inline-block;
    text-align: center;
    vertical-align: top;
}
.subscription-switcher span {
    display: inline-block;
    width: 100px;
}
.monthly {
    text-align: left;
}
.yearly {
    text-align: right;
}
.benefits span {
    color: #005dff;
    display: inline-block;
    width: 11px;
    margin-right: 5px;
}
.back .free .price-tag {
    background: #65d092;
}
.pro .discount {
    color: #3f80ea;
}
.pro .price-tag-button button.btn {
    background: #3f80ea;
}
.premium .discount {
    color: #002057;
}
.premium .price-tag-button button.btn {
    background: #002057;
}
/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
  -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
	-webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
}
/* flip the pane when hovered */
.flip-container.hover .flipper {
	transform: rotateY(180deg);
}

.flip-container, .front, .back {
	width: 100%;
	height: auto;
    margin: 0 10px;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  	background: #E5E9F1;
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.thumbnail{
background: none;
  border: none;
}
.mb{margin-bottom: 20px;}
.feature-logo {
    margin-top: -35px;
}
.package-disabled:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 118%;
    top: 0;
    left: 0;
    background: #ffffffb5;
}
.modal-lg-1000 {
    max-width: 1000px !important;
}
</style>