<template>
    <div>
        <nav class="navbar navbar-expand navbar-light navbar-bg">
            <div class="d-none d-sm-inline-block">
                <a class="sidebar-toggle" @click="toggleSidebar">
                    <i class="hamburger align-self-center"></i>
                </a>
            </div>
            <div class="d-inline-block d-sm-none">
                <a class="sidebar-toggle" @click="mobileToggleSidebar">
                    <i class="hamburger align-self-center"></i>
                </a>
            </div>
            <div class="d-none d-sm-inline-block" v-if="isImpersonateOn">
                <div class="alert alert-success alert-dismissible alert-impersonate" role="alert">
                    <button type="button" class="btn-close" @click="logOutImpersonate"></button>
                    <div class="alert-message">
                        <strong>Hello there!</strong> You are currently logged in as <strong>{{ page.authuser.name }}</strong>
                    </div>
                </div>
            </div>

            <div class="navbar-collapse collapse">
                <ul class="navbar-nav navbar-align">
                    <li class="nav-item dropdown" v-if="page.isMessengerOn">
                        <router-link class="nav-icon d-flex" :to="{ name: page.messengerRouteName }" style="height: 40px;">
                            <div class="position-relative" style="font-size: 16px; align-self: center;">
                                <span class="counter" id="message-counter" v-if="page.unreadConversationsCount">{{ page.unreadConversationsCount }}</span>
                                <i class="fas fa-envelope"></i>
                            </div>
                        </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-icon d-flex" href="javascript:;" style="height: 40px;" @click="openReminders">
                            <div class="position-relative" style="font-size: 16px; align-self: center;">
                                <span class="counter" id="message-counter" v-if="page.unreadRemindersCount">{{ page.unreadRemindersCount }}</span>
                                <i class="fas fa-stopwatch"></i>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-icon d-flex" href="javascript:;" style="height: 40px;" @click="openNotifications">
                            <div class="position-relative" style="font-size: 16px; align-self: center;">
                                <span class="counter" id="message-counter" v-if="page.unreadNotificationsCount">{{ page.unreadNotificationsCount }}</span>
                                <i class="fa fa-bell align-middle"></i>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-bs-toggle="dropdown">
                            <i class="align-middle" data-feather="settings"></i>
                            <img :src="page.authuser.display_photo" class="avatar img-fluid rounded-circle me-1" id="avatar" :alt="page.authuser?.name" />
                        </a>
                        <a class="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-bs-toggle="dropdown">
                            <img :src="page.authuser.display_photo" class="avatar img-fluid rounded-circle me-1" id="avatar" :alt="page.authuser?.name" /> <span class="text-dark">{{ page.authuser.fname }}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a href="#" class="link-userstatus dropdown-item" :class="page.isOnline ? 'active' : ''" @click="setStatus('online', true)"><i class="online"></i>Online</a>
                            <a href="#" class="link-userstatus dropdown-item" :class="!page.isOnline ? 'active' : ''" @click="setStatus('offline', true)"><i class="offline"></i>Appear Offline</a>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" v-if="page.authuser?.role_id == 2 || page.authuser?.role_id == 3" :to="{ name: 'settings.users.edit' }">Settings</router-link>
                            <router-link class="dropdown-item" v-if="page.authuser?.role_id == 4" :to="{ name: 'candidate.settings.profile' }">Settings</router-link>
                            <div v-if="user.associated_companies !== null">
                                <div class="dropdown-divider"></div>
                                <h6 class="dropdown-header">My Associated Companies</h6>
                                <div v-for="assoc in user.associated_companies_info" :key="assoc">
                                    <router-link class="dropdown-item" :to="{ name: 'candidate.company', params: { company_id: assoc.company_id }}">{{ assoc.company_info.company_name }}</router-link>
                                </div>
                            </div>
                            <li v-if="applications.length"><hr class="dropdown-divider"></li>
                            <a class="dropdown-item" href="javascript:;" @click="logout" v-if="!isImpersonateOn">Sign out</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <Notification :is-open="isOpenNotification" @close="closeNotification" />
        <Reminder :is-open="isOpen" @close="closeReminder" />
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watchEffect, watch } from 'vue';
import axios from 'axios';
import Reminder from '@/components/partials/Reminder.vue';
import Notification from '@/components/partials/Notification.vue';
import authRepo from '@/repositories/auth';
import applicationRepo from '@/repositories/candidate/application';
import ConversationsRepo from '@/repositories/conversations';
import notifRepo from '@/repositories/settings/notification';
import userRepo from '@/repositories/admin/users';
import globalRepo from '@/repositories/global';
import { useRouter } from 'vue-router'
import Pusher from 'pusher-js';

export default defineComponent({
    setup() {
        const router = useRouter();
        const { user_log_type, pusher_notification_data } = globalRepo();
        const { logUserActivity } = userRepo();
        const { user, getUser, setUserStatus, signOutUser } = authRepo();
        const { getAssociatedCompany, applications } = applicationRepo();
        const { conversations, getConversations } = ConversationsRepo();
        const { notifications, reminders, getNotifications, getReminders } = notifRepo();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isMessengerOn: false,
            isOnline: true,
            messengerRouteName: '',
            unreadConversationsCount: '',
            unreadNotificationsCount: '',
            unreadRemindersCount: '',
            logs: {
                admin_id: '',
                user_id: '',
                type: '',
                currentPath: ''
            },
            notification: JSON.parse(localStorage.getItem('notification')) ?? {
                unread_conversations: '',
                unread_notifications: '',
                unread_reminders: ''
            }
        });
        const isOpen = ref(false);
        const isOpenNotification = ref(false);
        const isImpersonateOn = ref(false);

        const logout = () => {
            setStatus('offline');
            if(isCandidate()) {
                signOutUser(page.authuser, 'candidate');
            }

            if(isEmployer()) {
                signOutUser(page.authuser, 'employer');
            }

            if(isAdmin()) {
                signOutUser(page.authuser, 'admin');
            }
        }

        watch(() => router.currentRoute.value.fullPath, async newPath => {
            let admin_details = JSON.parse(localStorage.getItem('adminauthuser'));

            // watch everytime if impersonate is on
            if(isImpersonateOn.value && user.value.id) {
                page.logs.admin_id = admin_details.id;
                page.logs.currentPath = newPath; 
                page.logs.user_id = user.value.id;
                page.logs.type = user_log_type.navigation;
                await logUserActivity(page.logs);
            }
        });

        watchEffect(() => {
            getAssociatedCompany(page);
        });

        onMounted( async () => {
            let admintoken = localStorage.getItem('admintoken');
            isImpersonateOn.value = (admintoken !== null);
            await getUser();
            if(user.value.user_status == 'online') {
                setStatus('online');
            } else if (user.value.user_status == 'offline') {
                setStatus('offline');
            }

            if(isCandidate()) {
                page.isMessengerOn = true
                page.messengerRouteName = 'candidate.messages'
            }

            if(isEmployer()) {
                page.isMessengerOn = true
                page.messengerRouteName = 'employer.messages'
            }

            // TODO::Use Pusher actual key when deployed to production
            const pusher = new Pusher( pusher_notification_data.appkey , {
                cluster: pusher_notification_data.cluster
            });
            const channel = pusher.subscribe(pusher_notification_data.channel);
            channel.bind(pusher_notification_data.broadcast, data => {
                let target_data = JSON.parse(data.notification_data);

                // We make sure that we only update the counter if the user ID from Pusher matched the logged in ID
                if(target_data.user_id == page.authuser.id) {
                    update_counter();
                }
            });

            page.unreadConversationsCount = page.notification.unread_conversations ?? '';
            page.unreadNotificationsCount = page.notification.unread_notifications ?? '';
            page.unreadRemindersCount = page.notification.unread_reminders ?? '';
        })

        const toggleReminders = () => {
            isOpen.value = !isOpen.value;
        }

        const toggleNotifications = () => {
            isOpenNotification.value = !isOpenNotification.value;
        }

        const openNotifications = () => {
            toggleNotifications();
        }

        const closeNotification = () =>{
            toggleNotifications();
        }

        const openReminders = () => {
            toggleReminders();
        }

        const closeReminder = () =>{
            toggleReminders();
        }

        const isAdmin = () => {
            return page.authuser?.role?.id == 1
        }

        const isEmployer = () => {
            return (page.authuser?.role?.id == 2 || page.authuser?.role?.id == 3);
        }

        const isCandidate = () => {
            return page.authuser?.role?.id == 4
        }

        const setStatus = async (status, withMessage = false) => {
            await setUserStatus(page.authuser.id, status, withMessage);

            // TODO::More status could be implemented in the future like "Away, Do not Disturb, etc."
            if(status == 'online') {
                page.isOnline = true;
            } else if (status == 'offline') {
                page.isOnline = false;
            }
        }

        const logOutImpersonate = async () => {
            let authuser = localStorage.getItem('adminauthuser');
            let token = localStorage.getItem('admintoken');

            localStorage.setItem('authuser', authuser);
            localStorage.setItem('token', token);
            localStorage.removeItem('adminauthuser');
            localStorage.removeItem('admintoken');
            localStorage.removeItem('auth-qrcode');
            
            // reset notification to localstorage
            let adminuser = JSON.parse(authuser);
            let res = await axios.get(`employer/notifications/reset-notification?user_id=${adminuser.id}`);
            let notification_count = {
                unread_conversations: res.data.notification.unread_conversations,
                unread_notifications: res.data.notification.unread_notifications,
                unread_reminders: res.data.notification.unread_reminders
            }

            localStorage.setItem('notification', JSON.stringify(notification_count));
            window.location.href = '/employer/dashboard';

            let routeData = `${process.env.VUE_APP_BASE_URL}/admin/settings/users`;

            // log activity when impersonation ends
            page.logs.type = user_log_type.stop;
            page.logs.currentPath = '/admin/settings/users';
            await logUserActivity(page.logs);

            setTimeout(() => { window.location.href = routeData }, 350 );
        }

        const mobileToggleSidebar = () => {
            let body = document.getElementsByTagName("body")[0];
            var sidebar = document.getElementById("sidebar");
            let behavior = null;
            if (sidebar.classList.contains("collapsed")){
                sidebar.classList.remove("collapsed");
                behavior = 'compact';
            }else{
                sidebar.classList.add("collapsed");
                behavior = 'sticky';
            }
            body.setAttribute("data-sidebar-behavior", behavior);
        }
        const toggleSidebar = () => {
            let body = document.getElementsByTagName("body")[0];
            let behavior = body.getAttribute("data-sidebar-behavior") ?? 'compact';
            sidebar.classList.remove("collapsed");
            if(behavior == 'sticky') {
                behavior = 'compact';
            } else {
                behavior = 'sticky';
            }
            body.setAttribute("data-sidebar-behavior", behavior);
        }

        const update_counter = async () => {
            let unread_conversations = 0, unread_notifications = 0, unread_reminders = 0;
            let notification_count = {
                unread_conversations: 0,
                unread_notifications: 0,
                unread_reminders: 0
            }

            conversations.value = [];
            notifications.value = [];
            reminders.value = [];
            await getNotifications(page);
            await getReminders(page)

            if(isCandidate()) {
                await getConversations(page,'candidate');
            }
            if(isEmployer()) {
                await getConversations(page,'employer');
            }

            conversations.value.forEach((item, index)=>{
                if(item.hasUnread) {
                    unread_conversations++;
                }
                notification_count.unread_conversations = unread_conversations;
            });

            notifications.value.forEach((item, index)=>{
                if(item.status == 'Unread') {
                    unread_notifications++;
                }
                notification_count.unread_notifications = unread_notifications;
            });

            reminders.value.forEach((item, index)=>{
                if(item.status == 'Unread') {
                    unread_reminders++;
                }
                notification_count.unread_reminders = unread_reminders;
            });

            localStorage.setItem('notification', JSON.stringify(notification_count));

            page.unreadConversationsCount = unread_conversations;
            page.unreadNotificationsCount = unread_notifications;
            page.unreadRemindersCount = unread_reminders;
        }

        return {
            logout,
            page,
            isOpen,
            openReminders,
            toggleReminders,
            closeReminder,
            logOutImpersonate,
            isImpersonateOn,
            isOpenNotification,
            toggleNotifications,
            openNotifications,
            closeNotification,
            getAssociatedCompany,
            applications,
            toggleSidebar,
            mobileToggleSidebar,
            isAdmin,
            isEmployer,
            isCandidate,
            notifications, 
            getNotifications,
            setStatus,
            update_counter,
            user
        }
    },
    components: {
        Reminder,
        Notification
    }
})
</script>

<style>
.alert-impersonate {
    width: 450px;
    margin-bottom: 0 !important;
    color: #2d7345 !important;
}
.alert-impersonate > .alert-message > strong {
    color: #2d7345 !important;
}
.hamburger:before, .hamburger:after {
    width: 24px !important;
}
span.counter {
    position: absolute;
    background: red;
    color: white;
    font-size: 10px;
    width: 14px;
    text-align: center;
    top: -3px;
    right: -7px;
    border-radius: 10px;
}
.link-userstatus i {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    background: #CCC;
    border-radius: 50%;
}
.link-userstatus i.online {
    background: #4bbf73;
}
.link-userstatus i.offline {
    background: #bbb;
}
</style>