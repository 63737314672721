import TrainingIndex from '@/views/employer/trainings/Index';
import TrainingPrivate from '@/views/employer/trainings/Private';
import LearningPlanCreate from '@/views/employer/trainings/LearningPlanCreate';
import ForwadEmailSuccess from '@/views/employer/forwardemail/Success';
import TrainingPrivateView from '@/views/employer/trainings/Show';

const routes = [
    {
        path: '/employer/trainings',
        name: 'employer.trainings',
        component: TrainingIndex
    },
    {
        path: '/employer/trainings/private',
        name: 'employer.trainings.private',
        component: TrainingPrivate
    },
    {
        path: '/employer/trainings/:id',
        name: 'employer.trainings.show',
        component: TrainingPrivateView
    },
    {
        path: '/employer/trainings/provider/success',
        name: 'employer.trainings.success',
        component: ForwadEmailSuccess
    },
    {
        path: '/employer/learning-plan/create',
        name: 'employer.learning-plans.create',
        component: LearningPlanCreate
    },
    {
        path: '/employer/learning-plan/update/:learning_plan_id',
        name: 'employer.learning-plans.update',
        component: LearningPlanCreate
    }
]

export default routes