import InterviewTrainings from '@/views/candidate/interviewTraining/Index';

const routes = [
    {
        path: '/candidate/interviewtrainings',
        name: 'candidate.interviewtrainings',
        component: InterviewTrainings
    }
]

export default routes