<template>
    <div>
        <PageLoader v-if="isLoading" />
        <div v-else>
            <!-- TODO:: use Google PDF viewer when deployed to production https://docs.google.com/viewer?url=${url}&embedded=true -->
            <div class="text-center pt-5 pb-5" v-if="!application.application_file"><span>Generating PDF file. Please wait...</span></div>
            <iframe :src="`https://docs.google.com/viewer?url=${application.application_file}&embedded=true`" width="98%" height="420px" v-else></iframe>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect, reactive } from 'vue';
import PageLoader from '@/components/modules/PageLoader.vue';
import applicationRepo from '@/repositories/candidate/application';

export default defineComponent({
    props: {
        job_id: {
            type: [Number, String],
            required: true
        }
    },
    setup(props) {
        const url = ref('');
        const isLoading = ref(true);
        const { generatePdf, status, application } = applicationRepo();

        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser'))
        });

        watchEffect(() => {
            isLoading.value = true;
            generatePdf(page.authuser.id, props.job_id, url);
            isLoading.value = false;
        })

        return {
            url,
            isLoading,
            generatePdf,
            status,
            page,
            application
        }
    },
    components: {
        PageLoader
    }
})
</script>
