import TrainingIndex from '@/views/candidate/trainings/Index';
import ForwadEmailSuccess from '@/views/candidate/forwardemail/Success';

const routes = [
    {
        path: '/candidate/trainings',
        name: 'candidate.trainings',
        component: TrainingIndex
    },
    {
        path: '/candidate/trainings/provider/success',
        name: 'candidate.trainings.success',
        component: ForwadEmailSuccess
    }
]

export default routes