<template>
    <div class="tab-pane fade show active" id="notification" role="tabpanel">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0">Notification Settings</h5>
            </div>
            <div class="card-body pt-0">
                <div class="row">
                    <div class="col-7">
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.course_updates"
                            placeholder="Notify me when there are course updates or reminders."
                            id="course_updates"
                        />
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.new_message"
                            placeholder="Notify me when there are new messages."
                            id="new_message"
                        />
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.job_application_updates"
                            placeholder="Notify me when there are new job application updates."
                            id="job_application_updates"
                        />
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.new_assigned_courses"
                            placeholder="Notify me when there are new assigned courses."
                            id="new_assigned_courses"
                        />
                        <BaseCheckbox 
                            design="inline"
                            v-model="notification_setting.new_job_invitations"
                            placeholder="Notify me when there are new job invitations."
                            id="new_job_invitations"
                        />
                        <div class="form-group mb-3">
                            <BaseButton :is-saved="status === 200" @btnClicked="saveNotification" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue';
import candidateNotificationRepo from '@/repositories/candidate/notification';


export default defineComponent({
    setup() {
        const { notification_setting, getNotificationSetting, updateNotificationSetting, status } = candidateNotificationRepo();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            notify: {
                option_one: '',
                option_two: ''
            }
        });

        onMounted(() => {
            getNotificationSetting(page);
        })

        const saveNotification = async () => {
            status.value = '';
            let formData = new FormData();
            formData.append('course_updates', notification_setting.value.course_updates ?? false);
            formData.append('new_message', notification_setting.value.new_message ?? false);
            formData.append('job_application_updates', notification_setting.value.job_application_updates ?? false);
            formData.append('new_assigned_courses', notification_setting.value.new_assigned_courses ?? false);
            formData.append('new_job_invitations', notification_setting.value.new_job_invitations ?? false);
            formData.append('user_id', page.authuser.id);
            await updateNotificationSetting(formData);
        }

        return {
            status,
            page,
            notification_setting,
            getNotificationSetting,
            saveNotification,
            updateNotificationSetting
        }
    }
})
</script>