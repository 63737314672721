<template>
    <div>
        <Modal :modalActive="modalActive" 
            :modalTitle="modalTitle" 
            :className="`modal-dialog modal-md modal-dialog-scrollable modal-lg`" 
            :saveButton="false"
            @close="close">
            <div class="row">
                <div class="col-12">
                    <iframe 
                        v-if="selectedInterviewTraining && selectedInterviewTraining.type == 'Video'"
                        width="100%" 
                        height="400" 
                        :src="selectedInterviewTraining.link" 
                        title="YouTube video player" 
                        frameborder="0" 
                        id="modal-interview-training-video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        selectedInterviewTraining: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const no_expiry = ref(false);
        
        const close = () => {
            document.getElementById('modal-interview-training-video').removeAttribute('src');
            emit('closeCandidateInterviewTrainingModal')
        }

        return {
            no_expiry,
            close
        }
    }
})
</script>