<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between mb-3">
                <h1 class="h3">Manage Learning Plans</h1>
            </div>
            <div class="card p-3">
                <div class="col-12">
                    <div class="row"> 
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12  mb-4">
                            <div class="btn-group">
                                <button class="btn btn-outline-primary" @click="changeTab('Premium')" :class="page.currentTab == 'Premium' ? 'active' : ''">Premium Learning Plans</button>
                                <button class="btn btn-outline-primary" @click="changeTab('Custom')" :class="page.currentTab == 'Custom' ? 'active' : ''">Basic Learning Plans</button>
                            </div>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-lg-4 col-sm-6"> 
                            <input type="search" v-model="page.search" class="form-control mb-3 search-learning-plan" placeholder="Search Learning Plan Title"/>
                        </div>
                        <div class="col-lg-8 col-sm-6">
                            <router-link :to="{ path: '/admin/learning-plan/create'}" class="btn btn-primary float-end mb-3">Create Learning Plan</router-link>
                        </div>
                    </div>
                    {{learning_plans_filtered.length}} results found.

                    <div class="accordion" id="accordionExample" v-if="learning_plans_filtered.length > 0">
                        <div class="accordion-item" v-for="(learning, index) in learning_plans_filtered" :key="index">
                            <h2 class="accordion-header" :id="`headingOne${learning.learning_plan_id}`">
                            <button class="accordion-button collapsed btn-learning-plan-collapse" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne${learning.learning_plan_id}`" aria-expanded="true" :aria-controls="`collapseOne${learning.learning_plan_id}`">
                                {{learning.learning_plan_name}}
                            </button>
                            </h2>
                            <div :id="`collapseOne${learning.learning_plan_id}`" class="accordion-collapse collapse" :aria-labelledby="`headingOne${learning.learning_plan_id}`">
                                <div class="accordion-body">
                                    <div class="w-100 text-end">
                                        <router-link v-if="learning.courses_id.length > 0 && page.authuser.role_id == 1" :to="{ name: 'admin.learning-plans.update', params: { learning_plan_id: learning.learning_plan_id } }" class="btn btn-primary btn-sm mb-2">Edit</router-link>
                                        <button v-else class="btn btn-primary btn-sm mb-2" @click="editLearningPlan(learning.learning_plan_id)">Edit</button>
                                        <button v-if="page.authuser.role_id == 1" class="btn btn-danger btn-sm mb-2 ms-1 btn-delete-plan" @click="removeLearningplan(learning.learning_plan_id)">Delete</button>
                                    </div>
                                    <div class="list-group" v-if="learning.trainings.length > 0">
                                        <a v-for="(training, index) in learning.trainings" :key="index" href="#" class="list-group-item list-group-item-action">
                                            <a :href="training.link" class="text-decoration-none">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">{{training.title}}</h5>
                                                    <small class="text-muted">{{training.skill_type}}</small>
                                                </div>
                                                <p class="mb-1">{{ training.author ?? training.company?.company_name }}</p>
                                                <small class="text-muted">{{ training.source?.name ?? 'Private Course' }}</small>
                                            </a>
                                        </a>
                                    </div>
                                    
                                    <div v-if="learning.courses_structure" class="list-group"> 
                                        <div class="list-group-item list-group-item-action">
                                            <div v-for="(section, i) in learning.courses_structure" :key="i">
                                                <div>
                                                    <h5 class="mb-1">{{section.section}}</h5>
                                                </div>
                                                <div class="" v-if="section.courses.length > 0"> 
                                                    <div v-for="(course, i) in section.courses" :key="i"> 
                                                        <h5 class="mb-1"><a class="text-decoration-none" target="_blank" :href="courseInfo(course.id,learning.courses_related)[0]?.link">{{course.title}}</a></h5>
                                                    </div>
                                                </div>

                                                <div class="ms-5" v-if="section.sub.length > 0"> 
                                                    <div v-for="(subsection, i) in section.sub" :key="i"> 
                                                        <div>
                                                            <h5 class="mb-1">{{subsection.section}}</h5>
                                                        </div>
                                                        <div class="" v-if="subsection.courses.length > 0"> 
                                                            <div v-for="(subcourse, i) in subsection.courses" :key="i"> 
                                                                <h5 class="mb-1"><a class="text-decoration-none" target="_blank" :href="courseInfo(subcourse.id,learning.courses_related)[0]?.link">{{subcourse.title}}</a></h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <!-- Body end -->
        </div>
        <!-- Container end -->
        <ModalLearningPlan 
            :modalActive="modalActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            :modalButton="page.modalButtonText"
            :learningPlanType="page.learningPlanType"
            @close="toggleModal"
            @reloadPage="save"
        />
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this learning plan?`"
            @cancel="toggleConfirmation"
            @isDelete="processDeleteLearningPlan"
        />
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, computed } from 'vue';
import learningPlanRepo from '@/repositories/admin/learningplans';
import ModalLearningPlan from '@/views/admin/trainings/ModalLearningPlan.vue';

export default defineComponent({
    setup() {
        const { learning_plans, learning_plan, getCustomLearningPlans, getPremiumLearningPlans, deleteLearningPlan, getLearningPlan } = learningPlanRepo();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            currentTab: 'Premium',
            modalHeader: 'Add Premium Learning Plan',
            modalButtonText: 'Add Learning Plan',
            learningPlanType: 'premium',
            learningPlanId: '',
            search: ''
        });
        const learning_plans_list = ref([]);
        const premium_learning_plans = ref('');
        const custom_learning_plans = ref('');
        const isConfirm =  ref(false);
        const modalActive = ref(false);

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const editLearningPlan = async (id) => {
            page.modalHeader = 'Manage this Learning Plan';
            page.modalButtonText = 'Update Learning Plan';
            await getLearningPlan(id);
            toggleModal();
        }

        const save = async () => {
            toggleModal();
            reloadPlans();
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const removeLearningplan = (id) => {
            page.learningPlanId = id;
            toggleConfirmation();
        }

        const processDeleteLearningPlan = async () => {
            toggleConfirmation();
            await deleteLearningPlan(page.learningPlanId);
            await reloadPlans();
        }

        const changeTab = async (tab) => {
            page.isLoading = true;
            page.currentTab = tab;
            await reloadPlans();
        }

        const reloadPlans = async () => {
            learning_plans_list.value = [];
            if(page.currentTab == 'Premium') {
                await getPremiumLearningPlans(page);
                learning_plans_list.value = learning_plans.value;
            }
            if(page.currentTab == 'Custom') {
                await getCustomLearningPlans(page);
                learning_plans_list.value = learning_plans.value;
            }
            learning_plans_list.value = learning_plans_list.value.filter(learning => learning.trainings.length > 0 || learning.courses_related.length > 0);
        }

        const learning_plans_filtered = computed(()=> {
            return learning_plans_list.value.filter(learning => learning.learning_plan_name.toLowerCase().includes(page.search.toLowerCase()))
        })

        const courseInfo = (id, courses) => {
            return courses.filter(course => course.id === id)
        }

        onMounted( async () => {
            await reloadPlans();
            
        });

        return {
            page,
            premium_learning_plans,
            custom_learning_plans,
            learning_plans_list,
            learning_plans_filtered,
            isConfirm,
            modalActive,
            learning_plan,
            save,
            toggleModal,
            editLearningPlan,
            courseInfo,
            changeTab,
            toggleConfirmation,
            deleteLearningPlan,
            removeLearningplan,
            processDeleteLearningPlan
        }
    },
    components: {
        ModalLearningPlan
    }
})
</script>