import CandidateMessages from '@/views/candidate/messages/Index';
import EmployerMessages from '@/views/employer/messages/Index';
import store from "@/store";

const routes = [
    {
        path: '/candidate/messages',
        name: 'candidate.messages',
        component: CandidateMessages
    },
    {
        path: '/candidate/messages/:user_id',
        name: 'candidate.messages_with_id',
        component: CandidateMessages
    },
    {
        path: '/employer/messages',
        name: 'employer.messages',
        component: EmployerMessages
    },
    {
        path: '/employer/messages/:user_id',
        name: 'employer.messages_with_id',
        component: EmployerMessages
    }
]

export default routes