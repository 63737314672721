<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Manage Users</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <button v-if="users.length > 1" class="btn btn-primary text-white" @click="selectUsers()">{{ formTraining.selectAllLabel }}</button>&nbsp;
                                        <div style="display: inline-block;" class="button-dropdown">
                                            <button class="btn btn-primary text-white standard-btn" data-bs-toggle="dropdown" aria-expanded="false" :disabled="(formTraining.selected.length < 1)">Assign <i class="fas fa-chevron-down"></i></button>
                                            <ul class="dropdown-menu">
                                                <li :class="(formTraining.selected.length < 1) ? 'disabled' : ''"><a href="javascript:;" class="dropdown-item text-primary" @click="assignUsers()">Assign Courses</a></li>
                                                <li :class="(formTraining.selected.length < 1) ? 'disabled' : ''"><a href="javascript:;" class="dropdown-item text-primary" @click="modalLessonActive = true">Assign Learning Plans</a></li>
                                            </ul>
                                        </div>&nbsp;
                                        <div style="display: inline-block;" v-if="page.authuser.company_id">
                                            <button class="btn btn-primary text-white standard-btn" data-bs-toggle="dropdown" aria-expanded="false">Invite <i class="fas fa-chevron-down"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a href="javascript:;" class="dropdown-item text-primary" @click="createUser">Invite New User</a></li>
                                                <li><a href="javascript:;" class="dropdown-item text-primary" @click="importCSV">Invite Users in Bulk</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab mt-3">
								<ul class="nav nav-tabs" role="tablist">
									<li class="nav-item"><a class="nav-link active" href="#tab-1" data-bs-toggle="tab" role="tab" aria-selected="true">Active Users</a></li>
									<li class="nav-item"><a class="nav-link" href="#tab-2" data-bs-toggle="tab" role="tab" aria-selected="false">Invited Users ({{ invited_users.length }})</a></li>
								</ul>
								<div class="tab-content">
									<div class="tab-pane active" id="tab-1" role="tabpanel">
										<BaseTable 
                                            :data="users"
                                            :options="headers"
                                            @navigateSort="triggerSort"
                                            :sortableColumns="page.sortableColumns"
                                            :isSortAscending="page.isSortAscending"
                                        >
                                            <template v-slot:role_display="{ row }">
                                                <div style="width: 200px;" v-if="row.role?.id !== role.employee.id" v-text="row.role.id === role.employer_hr.id ? role.employer_hr.label : row.role.name"></div>
                                                <div style="width: 200px;" v-else v-text="row.applications.length ? row.role?.name : role.employee.label"></div>
                                            </template>
                                            <template v-slot:name="{ row }">
                                                <div style="width: 200px;" v-text="row.name"></div>
                                            </template>
                                            <template v-slot:department_data="{ row }">
                                                <div style="width: 200px;" v-if="row.department_data" v-text="row.department_data.department_name"></div>
                                                <div v-else class="text-danger">No Department</div>
                                            </template>
                                            <template v-slot:checkbox="{ row }">
                                                <div v-if="page.authuser.id !== row.id">
                                                    {{ page.selected }}
                                                    <input type="checkbox" v-model="formTraining.selected" :value="row.id" />
                                                </div>
                                            </template>

                                            <template v-slot:trainingCourses="{ row }">
                                                <div v-if="page.authuser.id !== row.id" class="text-center">
                                                    <a href="javascript:void(0);" @click="previewTraining(row)">
                                                        <b>{{ row.ongoing_training_courses.length }}</b>
                                                    </a>
                                                </div>
                                            </template>

                                            <template v-slot:enrolledPlans="{ row }">
                                                <div v-if="page.authuser.id !== row.id" class="text-center">
                                                    <a href="javascript:void(0);" @click="previewLessons(row)">
                                                        <b>{{ (row.learning_plans.length > 0) ? row.learning_plans.length : 0  }}</b>
                                                    </a>
                                                </div>
                                            </template>

                                            <template v-slot:action="{ row }">
                                                <div>
                                                    <div class="btn-group">
                                                        <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width: 36px; height: 36px;"><i class="fa fa-ellipsis-h"></i></button>
                                                        <ul class="dropdown-menu">
                                                            <li v-if="page.authuser.id !== row.id"><a class="dropdown-item text-primary" href="javascript:;" @click="assignUser(row.id)">Assign Course</a></li>
                                                            <li v-if="page.authuser.id !== row.id"><a class="dropdown-item text-primary" href="javascript:;" @click="assignLesson(row.id)">Assign Lesson Plan</a></li>
                                                            <li v-if="page.authuser.id !== row.id"><a class="dropdown-item text-primary" href="javascript:;" @click="assignDepartment(row.id)">Assign Department</a></li>
                                                            <li v-if="page.authuser.id !== row.id && !row.applications.length"><a class="dropdown-item text-info" href="javascript:;" @click="changeRole(row.id)">Change Role</a></li>
                                                            <li v-if="page.authuser.id === row.id">
                                                                <router-link class="dropdown-item text-success" href="javascript:;" :to="{ name: 'settings.users.edit' }">Edit</router-link>
                                                            </li>
                                                            <li v-if="page.authuser.id === row.id"><a class="dropdown-item text-primary" href="javascript:;" @click="assignDepartment(row.id)">Assign Department</a></li>
                                                            <li v-if="page.authuser.id !== row.id"><a class="dropdown-item text-danger" href="javascript:;" @click="removeUser(row.id, row.role.id)">Remove</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </template>
                                        </BaseTable>
                                        <div class="d-flex justify-content-end">
                                            <div class="dataTables_paginate paging_simple_numbers">
                                                <ul class="pagination">
                                                    <li class="paginate_button page-item previous" :class="{ 'disabled' : page.num === 1 }"><a href="javascript:;" class="page-link" @click="prevPage">Previous</a></li>
                                                    <li class="paginate_button page-item" :class="{ 'active' : page.currentPage === n }" v-for="n in page.pages" :key="n"><a href="javascript:;" class="page-link" @click="changePage(n)">{{ n }}</a></li>
                                                    <li class="paginate_button page-item next" :class="{ 'disabled' : page.num === page.pages || page.pages.length === 0 }"><a href="javascript:;" class="page-link" @click="nextPage">Next</a></li>
                                                </ul>
                                            </div>
                                        </div>
									</div>
									<div class="tab-pane" id="tab-2" role="tabpanel">
										<BaseTable :data="invited_users" :options="invitedHeaders">
                                            <template v-slot:name="{ row }">
                                                <div v-text="row.fullname"></div>
                                            </template>
                                            <template v-slot:email="{ row }">
                                                <div v-text="row.email"></div>
                                            </template>
                                        </BaseTable>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalUser
            :modalActive="modalActive"
            :form="form"
            :errors="errors"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @saveChanges="save"
        />
        <ModalChangeRole
            :modalActive="modalChangeRoleActive"
            :form="user"
            :errors="errors"
            :modalTitle="page.modalHeader"
            @close="toggleModalChangeRole"
            @saveChanges="saveRole"
        />
        <ModalCsv
            :modalActive="modalCsvActive"
            :form="csv"
            :errors="errors"
            :modalTitle="`Invite Users via CSV`"
            @close="toggleModalCsv"
            @saveChanges="uploadCsv"
        />
        <ModalCsvImportResults
            :modalActive="modalCsvResultsActive"
            :data="importResults"
            @close="toggleModalCsvResults"
        />
        <ModalTrainings
            :modalActive="modalTrainingActive"
            :form="formTraining"
            :errors="errors"
            :modalTitle="formLessons.title"
            @close="toggleModalTraining"
            @saveChanges="assign"
        />
        <ModalLessons
            :modalActive="modalLessonActive"
            :form="formLessons"
            :errors="errors"
            :modalTitle="formLessons.title"
            @close="toggleModalLesson"
            :users="selectedUserInfo"
            @saveChanges="assignBulkEmployer"
        />
        <ModalAssignDepartment
            :modalActive="modalAssignDepartmentActive"
            :form="departments"
            :modalTitle="`Assign to Department`"
            @close="toggleAssignDepartment"
            @saveChanges="assignToDepartment"
        />
        <Confirm
            class="remove-confirmation"
            :is-confirm="isConfirm"
            :confirm-text="page.confirmRemoveText"
            @cancel="toggleConfirmation"
            @isDelete="removeUserFromCompany"
        />
    </main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, provide, computed, watchEffect, watch } from 'vue';
import userRepo from '@/repositories/settings/users';
import ModalUser from '@/views/employer/settings/users/ModalUser.vue';
import ModalChangeRole from '@/views/employer/settings/users/ModalChangeRole.vue';
import ModalCsv from '@/views/employer/settings/users/ModalCsv.vue';
import ModalCsvImportResults from '@/views/employer/settings/users/ModalCsvImportResults.vue';
import ModalTrainings from '@/views/employer/settings/users/ModalTrainings.vue';
import ModalLessons from '@/views/employer/settings/users/ModalLessons.vue';
import ModalAssignDepartment from '@/views/employer/settings/users/ModalAssignDepartment.vue';
import roleRepo from '@/repositories/settings/roles';
import departmentRepo from '@/repositories/employer/department';

export default defineComponent({
    setup() {
        const { role } = roleRepo();
        const { departments, getDepartments, assignUserToDepartment } = departmentRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Invite New User',
            idToRemove: '',
            user_id: '',
            pages: [],
            num: 1,
            offSet: 0,
            currentPage: 1,
            confirmRemoveText: '',
            inviteUserCount: 0,
            sortableColumns: ['department_data'],
            isSortAscending: true,
            sortype: 'department_data',
        });

        const form = reactive({
            fname: '',
            lname: '',
            email: '',
            role_id: ''
        });

        const csv = reactive({
            document: ''
        });

        const formTraining = reactive({
            training_ids: [],
            selected: [],
            allSelected: false,
            selectAllLabel: 'Select All Users',
        });

        const formLessons = reactive({
            title: 'Assign Learning Plans',
            fullname: '',
            lessons: []
        });

        const modalActive = ref(false);
        const modalCsvActive = ref(false);
        const modalTrainingActive = ref(false);
        const modalLessonActive = ref(false);
        const modalChangeRoleActive = ref(false);
        const modalCsvResultsActive = ref(false);
        const modalAssignDepartmentActive = ref(false);

        const toggleAssignDepartment = () => {
            modalAssignDepartmentActive.value = !modalAssignDepartmentActive.value;
        }

        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const {
            users,
            user,
            errors,
            status,
            totalData,
            inviteUser,
            getUsers,
            getUser,
            updateUser,
            destroyUser,
            inviteUserViaCsv,
            assignTrainingCourseToUser,
            processRemoveUserFromCompany,
            getPaginateUsers,
            importResults,
            getInviteUsers,
            invited_users
        } = userRepo()

        const headers = [
            {
                key: 'checkbox',
                label: '',
                class: 'text-center'
            },
            {
                key: 'name',
                label: 'Fullname'
            },
            {
                key: 'department_data',
                label: 'Department'
            },
            {
                key: 'role_display',
                label: 'Role'
            },
            {
                key: 'trainingCourses',
                label: 'Assigned Courses',
                class: 'text-center'
            },
            {
                key: 'enrolledPlans',
                label: 'Assigned Lesson Plans',
                class: 'text-center'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const invitedHeaders = [
            {
                key: 'name',
                label: 'Fullname',
                class: 'w-25'
            },
            {
                key: 'email',
                label: 'Email Address',
                class: 'w-25'
            },
            {
                key: 'date_created',
                label: 'Invited Date',
                class: 'w-25'
            }
        ];

        provide('fileInputModel', computed(() => csv.document));

        const createUser = () => {
            modalActive.value = true;
            errors.value = [];
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleModalCsv = () => {
            modalCsvActive.value = !modalCsvActive.value;
        }

        const toggleModalCsvResults = () => {
            modalCsvResultsActive.value = !modalCsvResultsActive.value;
        }

        const toggleModalTraining = () => {
            modalTrainingActive.value = !modalTrainingActive.value;
            if(modalTrainingActive.value == true) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        }

        const toggleModalLesson = () => {
            modalLessonActive.value = !modalLessonActive.value;
            if(modalLessonActive.value == false) {
                page.user_id = '';
            }
        }

        const save = async () => {
            let formData = new FormData();
            formData.append('fname', form.fname);
            formData.append('lname', form.lname);
            formData.append('email', form.email);
            formData.append('role_id', form.role_id);
            formData.append('inviter_email', page.authuser.email);
            formData.append('company_id', page.authuser.company_id);

            await inviteUser(formData);
            if (status.value == 200) {
                toggleModal();
                form.fname = '';
                form.lname = '';
                form.email = '';
                form.role_id = '';
                await getInviteUsers(page.authuser.company_id);
            }
        }

        const importCSV = () => {
            csv.document = '';
            errors.value = [];
            toggleModalCsv();
        }

        const uploadCsv = async () => {
            let formData = new FormData();
            formData.append('document', csv.document);
            formData.append('user_id', page.authuser.id);

            await inviteUserViaCsv(formData);
            if (status.value == 200) {
                toggleModalCsv();
                toggleModalCsvResults();
                await getInviteUsers(page.authuser.company_id);
            }
        }

        const selectUsers = () => {
            if (formTraining.allSelected == false) {
                formTraining.selected = [];
                users.value
                .filter((user) => {
                    return user.id !== page.authuser.id;
                })
                .map(user => {
                    formTraining.selected.push(user.id);
                });

                if (formTraining.selected.length > 0) {
                    formTraining.allSelected = true;
                    formTraining.selectAllLabel = 'Unselect (' + formTraining.selected.length + ') Users';
                } else {
                    formTraining.allSelected = false;
                    formTraining.selectAllLabel = 'Select All Users'
                }
            } else {
                formTraining.selected = [];
                formTraining.allSelected = false;
                formTraining.selectAllLabel = 'Select All Users'
            }
        }

        const assignUsers = () => {
            formTraining.training_ids = [];
            formLessons.title = 'Assign Training Courses to User';
            toggleModalTraining();
        }

        const assignUser = (user_id) => {
            formTraining.selected = [user_id];
            formLessons.title = 'Assign Training Courses to User';
            toggleModalTraining();
        }

        const assign = async () => {
            let formData = new FormData();
            formData.append('user_ids', formTraining.selected);
            formData.append('training_ids', formTraining.training_ids);
            formData.append('company_id', page.authuser.company_id);
            await assignTrainingCourseToUser(formData)

            if (status.value == 200) {
                page.isLoading = true;
                formTraining.training_ids = [];
                formTraining.selected = [];
                toggleModalTraining();
                await getPaginateUsers(page);
                page.pages = (totalData.value) ? Math.ceil(totalData.value / 10) : [];
            }
        }

        const assignBulkEmployer = async () => {
            toggleModalLesson();
            formTraining.selected = [];
            await getPaginateUsers(page);
        }

        const previewLessons = (lessons) => {
            formTraining.selected = [lessons.id];
            formLessons.title = 'Assigned Learning Plans to ' + lessons.name;
            formLessons.lessons = lessons.learning_plans;
            toggleModalLesson();
        }

        const previewTraining = (lessons) => {
            formTraining.selected = [lessons.id];
            formLessons.title = 'Assigned Training Courses to ' + lessons.name;
            lessons.ongoing_training_courses.forEach(item => {
                formTraining.training_ids.push(item.training_id);
            });
            toggleModalTraining();
        }

        const removeUser = (id, role) => {
            page.idToRemove = id;
            if(role == 3){
                page.confirmRemoveText = 'This HR account will be deleted and can no longer be accessed. Are you sure you want to delete this HR account? This process cannot be undone.';
            } else {
                page.confirmRemoveText = 'This user will be marked as “Resigned”. Are you sure you want to remove this user from the company? This process cannot be undone.';
            }

            toggleConfirmation();
        }

        const removeUserFromCompany = async () => {
            page.isLoading = true;
            toggleConfirmation();
            await processRemoveUserFromCompany(page);
            await getPaginateUsers(page);
            page.pages = (totalData.value) ? Math.ceil(totalData.value / 10) : [];
        }

        const toggleModalChangeRole = () => {
            modalChangeRoleActive.value = !modalChangeRoleActive.value;
        }

        const changeRole = async (id) => {
            page.isLoading = true;
            await getUser(page, id);
            page.user_id = id;
            form.role_id = user.value.role_id;
            page.modalHeader = 'Change User Role';
            toggleModalChangeRole();
        }

        const saveRole = async () => {
            page.isLoading = true;
            toggleModalChangeRole();

            await getPaginateUsers(page);
            page.pages = (totalData.value) ? Math.ceil(totalData.value / 10) : [];
        }

        const selectedUserInfo = computed(() => {
            const user = []
            // push individual data
            if(page.user_id){
                user.push({
                    id: page.user_id,
                    fname: 'Assign Lesson Plan'
                });
            } else {
                // else push selected data from checkbox
                formTraining.selected.map((data) => {
                    const info = users.value.filter(u => u.id == data)
                    user.push({
                        id: info[0].id,
                        fname: info[0].fname+' '+info[0].lname
                    });
                });
            }
            return user;
        });

        const changePage = (pageNumber) => {
            page.offSet = pageNumber == 1 ? 0 * 10 : (pageNumber - 1) * 10;
            page.num = pageNumber;
            page.currentPage = pageNumber;
        }

        const prevPage = () => {
            page.num--;
            page.currentPage = page.num;
        }

        const nextPage = () => {
            page.num++;
            page.currentPage = page.num;
        }

        onMounted( async () => {
            await getPaginateUsers(page);
            page.pages = (totalData.value) ? Math.ceil(totalData.value / 10) : [];
            await getInviteUsers(page.authuser.company_id);
            await getDepartments(page);
        });

        watchEffect(() => {
            page.offSet = page.num == 1 ? 0 * 10 : (page.num - 1) * 10;
            getPaginateUsers(page);
        });

        watch(() => formTraining.selected, () => {
            if (formTraining.selected.length > 0) {
                formTraining.allSelected = true;
                formTraining.selectAllLabel = 'Unselect (' + formTraining.selected.length + ') Users';
            } else {
                formTraining.allSelected = false;
                formTraining.selectAllLabel = 'Select All Users'
            }
        });

        const triggerSort = (index) => {
            page.sortype = index;
            toggleSortAscending();
            customSort(users.value, page.sortype, page.isSortAscending);
        }

        const toggleSortAscending = () => {
            page.isSortAscending = !page.isSortAscending;
        }

        const customSort = (data, sortColumn = 'department_data', sortAscending = true) => {
            return (sortAscending) ? data.sort((a, b) => {
                let fa = a[sortColumn].department_name.toLowerCase(),
                fb = b[sortColumn].department_name.toLowerCase();
                if (fa < fb) { return -1; }
                if (fa > fb) { return 1; }
                return 0;
            }) : data.sort((a, b) => {
                let fa = a[sortColumn].department_name.toLowerCase(),
                fb = b[sortColumn].department_name.toLowerCase();
                if (fa > fb) { return -1; }
                if (fa < fb) { return 1; }
                return 0;
            });
        }

        const assignLesson = (user_id) => {
            page.user_id = user_id;
            modalLessonActive.value = true;
        }

        const assignDepartment = (user_id) => {
            page.user_id = user_id;
            toggleAssignDepartment();
        }

        const assignToDepartment = async (data) => {
            let formData = new FormData;
            formData.append('department_id', data.department_id);
            formData.append('user_id', page.user_id);

            page.isLoading = true;
            toggleAssignDepartment();
            await assignUserToDepartment(formData);
            await getPaginateUsers(page);
            page.user_id = '';
        }

        return {
            headers,
            users,
            createUser,
            page,
            form,
            user,
            errors,
            inviteUser,
            getUsers,
            getUser,
            updateUser,
            destroyUser,
            modalActive,
            toggleModal,
            save,
            importCSV,
            modalCsvActive,
            toggleModalCsv,
            uploadCsv,
            csv,
            inviteUserViaCsv,
            selectUsers,
            toggleModalTraining,
            modalTrainingActive,
            assign,
            assignUsers,
            assignUser,
            formTraining,
            modalLessonActive,
            toggleModalLesson,
            formLessons,
            previewLessons,
            removeUser,
            toggleConfirmation,
            isConfirm,
            removeUserFromCompany,
            role,
            changeRole,
            toggleModalChangeRole,
            modalChangeRoleActive,
            saveRole,
            selectedUserInfo,
            assignBulkEmployer,
            changePage,
            prevPage,
            nextPage,
            getPaginateUsers,
            importResults,
            toggleModalCsvResults,
            modalCsvResultsActive,
            previewTraining,
            getInviteUsers,
            invitedHeaders,
            invited_users,
            triggerSort,
            assignLesson,
            modalAssignDepartmentActive,
            toggleAssignDepartment,
            departments,
            assignToDepartment,
            assignDepartment
        }
    },
    components: {
        ModalUser,
        ModalCsv,
        ModalTrainings,
        ModalLessons,
        ModalChangeRole,
        ModalCsvImportResults,
        ModalAssignDepartment
    }
});
</script>

<style>
.w-10 {
    width: 10%;
}
li.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.standard-btn {
    width:100px;
}
.standard-btn i {
    float: right;
    margin-top: 5px;
}
</style>