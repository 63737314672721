<template>
    <div>
        <div class="mt-3" v-for="company in companies" :key="company">
            <div class="row mb-3">
                <div class="col-12">
                    <h3>{{ company.company_name }}</h3>
                </div>
            </div>

            <ul class="nav nav-tabs mb-0" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" :id="`course-tab-${company.id}`" data-bs-toggle="tab" :data-bs-target="`#course-tab-${company.id}-pane`" type="button" role="tab" :aria-controls="`course-tab-${company.id}-pane`" aria-selected="true">Courses ({{company?.courses.length}})</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :id="`plan-${company.id}-tab`" data-bs-toggle="tab" :data-bs-target="`#plan-${company.id}-tab-pane`" type="button" role="tab" :aria-controls="`plan-${company.id}-tab-pane`" aria-selected="false">Learning Plans ({{learning_plans.length}})</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" :id="`course-tab-${company.id}-pane`" role="tabpanel" :aria-labelledby="`course-tab-${company.id}`" tabindex="0">
                    <div v-if="company?.courses.length">
                        <div class="card col-12 mt-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="course-card me-2" v-for="training in company?.courses" :key="training">
                                        <div class="card shadow">
                                            <div class="d-flex justify-content-between card-absolute">
                                                <div class="d-flex align-items-center">
                                                    <span class="badge bg-secondary text-white"
                                                        v-if="training.status == 'Enrolled'">
                                                        Enrolled
                                                    </span>
                                                    <span class="badge bg-warning text-white"
                                                        v-if="training.status == 'In Progress'">
                                                        In Progress
                                                    </span>
                                                    <span class="badge bg-success text-white"
                                                        v-if="training.status == 'Completed'">
                                                        Completed
                                                    </span>
                                                </div>
                                                <div class="card-btn-actions">
                                                    <div class="dropdown position-relative">
                                                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-more-horizontal align-middle"
                                                            >
                                                                <circle cx="12" cy="12" r="1"></circle>
                                                                <circle cx="19" cy="12" r="1"></circle>
                                                                <circle cx="5" cy="12" r="1"></circle>
                                                            </svg>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <a class="dropdown-item" href="javascript:;"
                                                                v-if="training.status == 'Enrolled'" @click="insertTrainingCourse(training.id, training.training_id)">
                                                                Start
                                                            </a>
                                                            <a class="dropdown-item" href="javascript:;"
                                                                v-else @click="updateTrainingCourse(training.id)" :disabled="training.status == 'Completed'">
                                                                Mark as Completed
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a :href="training.link ?? training.video" target="_blank" class="card-link">
                                                <img :src="training.course?.thumbnail" class="card-img-top object-cover course-img">
                                                <div class="card-header card-header-items px-4 pt-4">
                                                    <h5 class="card-title" v-text="training.course?.title"></h5>
                                                </div>
                                            </a>
                                            <div class="card-body training-card">
                                                <div>{{ training.course?.author }}</div>
                                                <p class="card-text carousel-card-text">
                                                    <b>Source: {{ training.course?.source?.name ?? training.course?.name }}</b>
                                                </p>
                                                <div class="mt-3" v-html="training.course?.display_learning_plans"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5" v-else>
                        <div class="text-center">
                            <img src="/assets/images/empty.png" class="img-fluid img-30">
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :id="`plan-${company.id}-tab-pane`" role="tabpanel" :aria-labelledby="`plan-${company.id}-tab`" tabindex="0">
                    <AssignedLearningPlans :learning_plans="learning_plans" @updateProgress="updateProgress"/>
                </div>
            </div>
        </div>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="page.confirmText"
            @cancel="toggleConfirmation"
            @isDelete="processComplete"
        />
    </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import trainingRepo from '@/repositories/candidate/trainings';
import learningPlanRepo from '@/repositories/admin/learningplans';
import AssignedLearningPlans from '@/views/candidate/trainings/components/sub/AssignedLearningPlans';

export default {
    props: {
        companies: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        const { updateCourse, status } = trainingRepo();
        const { learning_plans, getLearningPlansAssigned } = learningPlanRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            idToUpdate: '',
            confirmText: '',
            action: '',
            training_id: ''
        });
        const isConfirm = ref(false);

        const insertTrainingCourse = (id, training_id) => {
            page.action = 2;
            page.idToUpdate = id;
            page.training_id = training_id;
            page.confirmText = 'Are you sure you want to start this course?';
            toggleConfirmation();
        }

        const updateTrainingCourse = (id) => {
            page.action = 3;
            page.idToUpdate = id;
            page.training_id = '';
            page.confirmText = 'Are you sure you want to complete this course?';
            toggleConfirmation();
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const processComplete = async () => {
            let formData = new FormData();
            formData.append('id', page.idToUpdate);
            formData.append('user_id', page.authuser.id);
            formData.append('action', page.action);
            if(page.training_id) {
                formData.append('training_id', page.training_id);
            }

            await updateCourse(formData);
            if(status.value === 200) {
                toggleConfirmation();
                emit('refreshPage');
            }
        }

        onMounted( async () => {
            await getLearningPlansAssigned(page);
            console.log(learning_plans.value);
            document.getElementById("training-title-header").innerHTML = 'Training Courses and Learning Plans';
        });

        const updateProgress = async () => {
            page.isLoading = true
            await getLearningPlansAssigned(page);
        }

        const getPlanLists = (company) => {
            let array = [];
            if(company?.employer?.learning_plans_assigned_by) {
                company?.employer?.learning_plans_assigned_by.forEach(item => {
                    array.push(item);
                });
            }

            return JSON.stringify(array);
        }

        return {
            page,
            insertTrainingCourse,
            updateTrainingCourse,
            isConfirm,
            toggleConfirmation,
            processComplete,
            updateCourse,
            status,
            learning_plans,
            updateProgress,
            getPlanLists
        }
    },
    components:{
        AssignedLearningPlans
    }
}
</script>
<style scoped>
.course-card { display: inline-block; width: 240px; }
.shadow {
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.05)!important;
}
.card-header-items {
    height: 105px;
}
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.feather {
    width: 24px !important;
    height: 24px !important;
    padding: 0 3px;
}
.training-card {
    height: 140px;
}
</style>