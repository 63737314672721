import axios from "axios";
import { ref, inject } from "vue";

export default function planRepo() {

    const errors = ref([]);
    const toast = inject('toast');
    const plansList = ref([]);
    const plansFeature = ref([]);
    const status = ref('');

    const getPlans = async (page) => {
        let response = await axios.get(`admin/plan`);
        plansList.value = response.data;
        page.isLoading = false;
    }
    const insertPlan = async (page, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/plan`, data);
            plansList.value = response.data.plans
            alertMessage(response.data.message);
            page.isLoading = false;
            status.value = response.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }
    const updatePlan = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/plan/${id}`, data);
            plansList.value = response.data.plans;
            alertMessage(response.data.message);
            status.value = response.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }
    const destroyPlan = async (page, id) => {
        try {
            let response = await axios.delete(`admin/plan/${id}`);
            plansFeature.value = response.data;
            alertMessage(response.data.message);
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }
    const destroyPlanFeature = async (page, id) => {
        try {
            let response = await axios.delete(`admin/plan-features/${id}`);
            plansFeature.value = response.data;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }
    const getPlanFeature = async (page) => {
        let response = await axios.get(`admin/plan-features`);
        plansFeature.value = response.data;
        page.isLoading = false;
    }
    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }
    return {
        getPlans,
        insertPlan,
        updatePlan,
        destroyPlan,
        getPlanFeature,
        destroyPlanFeature,
        plansList,
        plansFeature,
        status,
        errors
    }
}