import axios from "axios";
import { ref, inject } from "vue";

export default function learningPlanRepo() {

    const errors = ref([]);
    const learning_plan = ref([]);
    const toast = inject('toast');
    const learning_plans = ref([]);
    const status = ref([]);

    const getCustomLearningPlans = async (page) => {
        let response = await axios.get(`admin/learningplans/custom-learning-plan`);
        learning_plans.value = response.data.data;
        page.isLoading = false;
    }

    const getLearningPlansAssigned = async (page) => {
        await axios.get(`admin/learningplans/get-assigned?user_id=${page.authuser.id}`).then((response) => {
            learning_plans.value = response.data.results;
            page.isLoading = false;
        }).catch( (e) => {
        });
    }

    const getPremiumLearningPlans = async (page) => {
        let response = await axios.get(`admin/learningplans/premium-learning-plan`);
        learning_plans.value = response.data.data;
        page.isLoading = false;
    }

    const getLearningPlan = async (id) => {
        let response = await axios.get(`admin/learningplans/get-learning-plan?plan_id=${id}`);
        learning_plan.value = response.data.learning_plan;
    }

    const insertPremiumLearningPlan = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/learningplans/add-premium-learning-plan`, data);
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const insertCustomLearningPlan = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/learningplans/add-custom-learning-plan`, data);
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const updateLearningPlan = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/learningplans/update-learning-plan`, data);
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    const deleteLearningPlan = async (id) => {
        let response = await axios.get(`admin/learningplans/delete-learning-plan/?plan_id=${id}`);
        alertMessage(response.data.message);
    }

    const createLearningPlan = async (data) => {
        errors.value = '';
        await axios.post(`admin/learningplans/create-learning-plan`, data).then((response) => {
            status.value = response.status;
            alertMessage(response.data.message);
        }).catch((e) => {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        });
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    return {
        errors,
        status,
        learning_plan,
        learning_plans,
        getLearningPlan,
        updateLearningPlan,
        deleteLearningPlan,
        getCustomLearningPlans,
        getPremiumLearningPlans,
        insertCustomLearningPlan,
        insertPremiumLearningPlan,
        getLearningPlansAssigned,
        createLearningPlan
    }

}