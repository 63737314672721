<template>
    <div class="form-group mb-3">
        <label :for="id" class="form-label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
        <select :id="id" class="form-select" v-bind="$attrs" @change="updateInput" :class="{ 'valid' : hasValue[id] }">
            <option v-if="!initialEmptySelect" value="">--</option>
            <option
                v-for="(data, key) in options"
                :key="key"
                :value="data[optionId]"
                :selected="data[optionId] == modelValue"
            >
                {{ data[optionValue] }}
            </option>
        </select>
        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors && errors[id]">{{ errors[id][0] }}</label>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Array, Boolean],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {},
            required: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: []
        },
        optionId: {
            type: String,
            default: 'id'
        },
        optionValue: {
            type: String,
            default: 'name'
        },
        initialEmptySelect: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const hasValue = ref([]);
        const updateInput = (event) => {
            let id = event.target.id;
            if(event.target.value) {
                hasValue.value[id] = true;
                props.errors[id] = null;
            } else {
                hasValue.value[id] = false;
            }
            emit('update:modelValue', event.target.value);
        }

        return {
            updateInput,
            hasValue
        }
    }
})
</script>