<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <h1 class="h3">Job Search</h1>
                </div>
                <div class="d-flex align-items-center">
                    <router-link class="btn btn-primary btn-md" :to="{ name: 'candidate.interviewtrainings'}">Interview Trainings</router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-2">
                    <div class="card shadow">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="mt-3">
                                        <input class="form-control" type="search" v-model="querysearch" placeholder="Search" @input="searching" v-if="filterby == 1">
                                        <input class="form-control" type="search" v-model="separateSearch" @input="searching" v-if="filterby == 2 || filterby == 3" placeholder="Search">
                                    </div>
                                    <div class="mt-3">
                                        <h5 class="lead"><strong>Job Type</strong></h5>
                                        <div v-for="(job_type, i) in job_types" :key="job_type.name" class="d-flex">
                                            <input type="checkbox" :value="job_type.name" :id="`job_type${i}`" class="chk-job-type" @change="filterJobTypeChange(job_type.name, $event)">
                                            <label :for="`job_type${i}`" class="job-type-label">{{ job_type.name }}</label>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <h5 class="lead"><strong>Location</strong></h5>
                                        <div v-for="(location_role, i) in location_roles" :key="location_role.name" class="d-flex">
                                            <input type="checkbox" :value="location_role.id" :id="`location_role_${i}`" class="chk-location" @change="filterLocationRoleChange(location_role.id, $event)">
                                            <label :for="`location_role_${i}`" class="job-type-label">{{ location_role.name }}</label>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <h5 class="lead"><strong>Country</strong></h5>
                                        <select class="form-select" v-model="country_id" style="width: 100%;" @change="filterCountryChange">
                                            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.nicename }}</option>
                                        </select>
                                    </div>
                                    <div>
                                        <button class="btn btn-light btn-sm w-100 mt-4" @click="clearFilter"> Clear Filter </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="card">
                        <PageLoader v-if="page.isLoading" />
                        <div class="card-body" v-else>
                            <table class="table table-striped" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th>Date Posted</th>
                                        <th>Job Title</th>
                                        <th>Company</th>
                                        <th>
                                            <span class="sort-btn">
                                                <i @click="sortByRate()" v-if="enableSort" :class="!sortOrder ? 'fa-long-arrow-alt-up' : 'fa-long-arrow-alt-down'" class="fas"></i>
                                            </span>
                                            <span class="sort-btn" @click="enableSorting">Rates</span>
                                        </th>
                                        <th>Job Location</th>
                                        <th>Address</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="jobs && jobs.length > 0">
                                    <tr v-for="(job, index) in jobs" :key="index">
                                        <td class="job-td">{{ job.created_at_display }}</td>
                                        <td class="job-td">
                                            <div class="job-title">
                                                <a href="javascript:;" @click="applyJob(job.id, index)">{{ job.position_title }}</a>
                                            </div>
                                            <div v-html="job.display_keywords"></div>
                                            <span v-if="job.promote == 1" class="badge promoted-badge">Promoted</span>
                                        </td>
                                        <td class="job-td">
                                            <div v-if="job.is_profile_visible">
                                                <a :href="`/candidate/employer-profile/${job.company_id}`" target="_blank">{{ job.company?.company_name }}</a>
                                            </div>
                                            <div v-else class="view-profile-button-wrapper">
                                                {{ job.company?.company_name }}
                                                <div class="tooltip bg-dark text-light tooltip-style-private" v-if="!job.is_profile_visible">This Profile is Private</div>
                                            </div>
                                        </td>
                                        <td class="job-td"><div>{{ job.compensation }}</div></td>
                                        <td class="job-td"><div v-html="job.location_role"></div></td>
                                        <td class="job-td">{{ job.location+' '+job.country }}</td>
                                        <td class="job-td">
                                            <button class="btn btn-outline-primary" @click="applyJob(job.id, index)">View Details</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="7" class="text-center">No data available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div v-for="job in externaljobs" :key="job.id" class="col-lg-4 col-md-6 col-sm-12 my-2">
                            <a :href="job.ext_url" target="_blank" style="text-decoration: none; color: #333;">
                                <div class="remote-card">
                                    <img :src="`${page.upcloud_link}/assets/img/${job.remotejob_logo}`" style="width: 30px; height: 30px; object-fit: cover;">
                                </div>
                                <div class="remote-content">
                                    <div class="remote-body">
                                        <div>
                                            <h4 class="remote-h4">{{ job.position_title }}</h4>
                                            <span v-if="job.source == 'larajobs'">Larajobs &bull; <i class="fa fa-calendar-alt"></i> {{ formatDate(job.created_at) }}</span>
                                            <span v-else-if="job.source == 'weworkremotely'">We Work Remotely &bull; <i class="fa fa-calendar-alt"></i> {{ formatDate(job.created_at) }}</span>
                                            <span v-else-if="job.source == 'remoteok'">Remote | OK &bull; <i class="fa fa-calendar-alt"></i> {{ formatDate(job.created_at) }}</span>
                                            <span v-else-if="job.source == 'vuejobs'">VueJobs &bull; <i class="fa fa-calendar-alt"></i> {{ formatDate(job.created_at) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`Applying for a Job - ${job.position_title}`">
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin application-fixed">
                    <div class="col-12">
                        <div class="float-end">
                            <div v-for="njob in jobs" :key="njob">
                                <div v-if="job.id == njob.id">
                                    <button class="btn btn-outline-primary btn-lg" @click="copyToClipboard(job.job_url)">
                                        <i class="fa fa-link"></i>
                                    </button>&nbsp;
                                    <button class="btn btn-outline-danger btn-lg" v-if="job.job_save" @click="unsavedListedJob">Unsave</button>&nbsp;
                                    <button class="btn btn-outline-primary btn-lg" v-else @click="savedJob">Save</button>&nbsp;

                                    <button :class="`${ !page.hasApplied ? 'btn btn-primary btn-lg' : 'btn btn-outline-secondary btn-lg disabled' }`" @click="applyToJob(job.id)">{{ page.applyToThisJobLabel }}</button>
                                    &nbsp;
                                    <div class="btn-group">
                                        <button class="btn btn-outline-primary btn-lg" type="button" disabled v-if="page.prevIsDisabled"><i class="fas fa-chevron-left fa-1x"></i></button>
                                        <button class="btn btn-outline-primary btn-lg" type="button" @click="previous(jobs)" v-else><i class="fas fa-chevron-left fa-1x"></i></button>

                                        <button class="btn btn-outline-primary btn-lg" type="button" disabled v-if="page.nextIsDisabled"><i class="fas fa-chevron-right fa-1x"></i></button>
                                        <button class="btn btn-outline-primary btn-lg" type="button" @click="next(jobs)" v-else><i class="fas fa-chevron-right fa-1x"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin mt-7">
                    <div class="col-12">
                        <h3>{{ job.position_title }} &nbsp; ({{ job.company ? job.company.company_name : '' }})</h3>
                        <div v-html="job.display_keywords"></div>
                        <div class="mb-3 mt-3">
                            <div><b>Job Type:</b> {{ job.job_type }}</div>
                            <div><b>Location:</b> {{ job.location }} {{ job.country }}</div>
                            <div v-if="job.language"><b>Language: </b> <span class="badge bg-success" style="margin-right:3px;" v-for="language in job.languages" v-bind:key="language">{{ language?.name ?? '' }}</span></div>
                            <div v-if="job.compensation_type == 1 && job.rate != null"><b>Compensation:</b> {{ `${job.min_amount} - ${job.max_amount}` }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                            <div v-if="job.compensation_type == 2 && job.rate != null"><b>Compensation:</b> {{ job.exact_amount }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                            <div v-if="job.location_role_id"><b>Location Role:</b> {{ location_roles.find(x => x.id === job.location_role_id)?.name }}</div>
                            <div v-if="job.schedules"><b>Schedules:</b> {{ getSchedules() }}</div>
                            <div v-if="job.job_time_frame"><b>Time Frame:</b> {{ time_frames.find(x => x.id === job.job_time_frame)?.name }}</div>
                        </div>
                        <h5>Job Description</h5>
                        <div class="mb-3" v-html="job.job_description"></div>
                        <h5>Job Benefits</h5>
                        <div v-html="job.job_benefits" v-if="job.job_benefits"></div>
                        <div v-else>Not Listed.</div>
                    </div>
                </div>
            </div>
        </slideout>
        <div class="overlay position-fixed full-screen-loader text-primary" v-if="page.isfullScreenLoading">
            <i class="fa fa-spin fa-spinner fa-3x"></i>
        </div>
        <ModalApplication
            :modalActive="modalActive"
            :id="page.jobId"
            :form="application"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
        />
    </main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, inject, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import _debounce from 'lodash/debounce';
import PageLoader from '@/components/modules/PageLoader.vue';
import Confirm from '@/components/modules/Confirmation.vue';
import globalRepo from '@/repositories/global';
import jobRepo from '@/repositories/candidate/jobs';
import applicationRepo from '@/repositories/candidate/application';
import ModalApplication from '@/views/candidate/jobs/ModalApply.vue';
import externalJobsRepo from '@/repositories/candidate/externaljobs';
import jQuery from "jquery";

export default defineComponent({
    setup() {
        const route = useRoute()
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: '',
            idToDelete: '',
            jobId: '',
            jobCount: 1,
            nextIsDisabled: false,
            prevIsDisabled: true,
            tableIndex: 0,
            applyToThisJobLabel: 'Apply',
            hasApplied: false,
            locations: [],
            job_types: [],
            searchquery: '',
            filter: 1,
            country_id: 226,
            isfullScreenLoading: false,
            upcloud_link: process.env.VUE_APP_UPCLOUD_ASSETS
        });

        const toast = inject('toast');
        const querysearch = ref('');
        const country_id = ref(226); // United States
        const job_type = ref('Full-time');
        const filterby = ref(1);
        const modalActive = ref(false);
        const isConfirm = ref(false);
        const visible = ref(false);
        const location_role = ref(3);
        const sortOrder = ref(true);
        const enableSort = ref(false);
        const separateSearch = ref('');

        const { jobs, job, getJobs, getJob, errors, searchJobtile, searchByJobType, searchByCountry, listedJob, unsavedJob, searchByLocation } = jobRepo();
        const { job_types, compensation_types, location_roles, schedules, time_frames, rates, countries, getCountries, hashids } = globalRepo();
        const { application, getApplication, addApplication } = applicationRepo();
        const { externaljobs, getExternalJobs } = externalJobsRepo();

        const getUriParameters = () => {
            let uri = window.location.href.split('?');

            if(uri.length == 2) {
                let vars = uri[1].split('&');
                let getVars = {};
                let tmp = '';
                vars.forEach(function(v) {
                    tmp = v.split('=');
                    if(tmp.length == 2)
                    getVars[tmp[0]] = tmp[1];
                });
                return getVars;
            }
            return false;
        }


        const viewJobByParams = () => {
            if(page.tableIndex === 0) {
                page.prevIsDisabled = true;
            } else {
                page.prevIsDisabled = false;
            }

            if(page.tableIndex === (jobs.value.length-1)) {
                page.nextIsDisabled = true;
            } else {
                page.nextIsDisabled = false;
            }

            const params = getUriParameters();
            if (params) {
                viewJob(hashids.decode(params.jid));
            }
        }

        const viewJob = async (id) => {
            page.isfullScreenLoading = true;
            await getJob(id);
            page.isfullScreenLoading = false;
            page.jobId = id;
            visible.value = true;
        }

        onMounted(async () => {
            if(route.params.job_id){
                const jobId = route.params.job_id;
                viewJob(jobId)
            }

            await getJobs(page);
            await getCountries();
            await getExternalJobs(page);
        });

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const searching = async (event) => {
            await updateValue(event.target.value);
        }

        const updateValue = _debounce(function (event) {
            page.isLoading = true;
            page.searchquery = event ?? '';
            let formData = new FormData();
            formData.append('filter', filterby.value);
            formData.append('searchquery', page.searchquery);
            formData.append('company_id', page.authuser.company_id ?? '');
            formData.append('country_id', country_id.value ?? '');
            formData.append('user_id', page.authuser.id);
            searchJobtile(page, formData);
        }, 800);

        const filterJobTypeChange = async (value, event) => {
            if(event.target.checked) {
                page.job_types.push(value);
            } else {
                page.job_types.splice(page.job_types.indexOf(value), 1);
            }

            await searchByJobType(page, page.job_types);
            viewJobByParams();
        }

        const filterCountryChange = async () => {
            page.country_id = country_id.value;
            await searchByCountry(page, country_id.value);
            viewJobByParams();
        }

        const filterLocationRoleChange = async (value, event) => {
            if(event.target.checked) {
                page.locations.push(value);
            } else {
                page.locations.splice(page.locations.indexOf(value), 1);
            }

            await searchByLocation(page, page.locations);
            viewJobByParams();
        }

        const applyJob = async (id, index) => {
            page.isfullScreenLoading = true;
            await getJob(id, page.authuser.id);
            page.isfullScreenLoading = false;
            setApplicant();
            page.jobId = id;
            page.tableIndex = index;
            visible.value = true;
        }

        const onClosing = (e) => {
            // close after 3 seconds
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                e.close = true
                page.hasApplied = false;
            }, 500)
        }

        const getSchedules = () => {
            if(job.value.schedules) {
                let results = job.value.schedules.split(',');
                let content = '';
                results.filter(result => {
                    content += `${schedules.find(x => x.id == result).name}, `;
                });

                return content.slice(0, -2);
            }
        }

        const savedJob = async () => {
            let formData = new FormData();
            formData.append('job_id', job.value.id);
            formData.append('candidate_id', page.authuser.id);

            listedJob(formData);
            await getJob(job.value.id);
        }

        const unsavedListedJob = async () => {
            unsavedJob(page, job.value.id);
            await getJob(job.value.id);
        }

        const previous = async (jobs) => {
            page.tableIndex = page.tableIndex-1;
            if(page.tableIndex !== -1) {
                let id = jobs[page.tableIndex].id;
                await getJob(id);
                setApplicant();
                page.jobId = id;
                visible.value = true;
            }
            page.jobCount--;

        }

        const next = async (jobs) => {
            page.tableIndex = page.tableIndex+1;
            if(page.tableIndex !== jobs.length) {
                let id = jobs[page.tableIndex].id;

                await getJob(id);
                setApplicant();
                page.jobId = id;
                visible.value = true;
            }
            page.jobCount++;
        }

        const setApplicant = () => {
            let applicant_info = (job.value.candidate_applications && job.value.candidate_applications.length) ? job.value.candidate_applications.find(o => o.candidate_id === page.authuser.id) : {};
            page.hasApplied = applicant_info ? applicant_info.submitted : false;
            page.applyToThisJobLabel = (page.hasApplied == true) ? "Applied" : "Apply";
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const applyToJob = async (id) => {
            visible.value = false;
            await addApplication(page, id);
            await getApplication(page, id);
            page.modalHeader = `Apply to a Job - ${job.value.position_title}`
            setTimeout(() => {
                toggleModal();
            }, 500)
        }

        const copyToClipboard = (jobUrl) => {
            navigator.clipboard.writeText(jobUrl);
            toast.success('Job URL copied!');
        }

        const formatDate = (date) => {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return new Date(date).toLocaleDateString('en', options)
        }

        const clearFilter = async () => {
            filterby.value = 1;
            page.searchquery = '';
            page.locations = [];
            page.job_types = [];
            page.country_id = 226;
            jQuery('.chk-job-type').prop('checked', false);
            jQuery('.chk-location').prop('checked', false);
            await getJobs(page);
        }

        watchEffect(() => {
            viewJobByParams();
        });

        return {
            jobs,
            page,
            job,
            errors,
            getJobs,
            getJob,
            modalActive,
            isConfirm,
            toggleConfirmation,
            querysearch,
            filterby,
            location,
            job_type,
            job_types,
            searching,
            searchByJobType,
            countries,
            getCountries,
            country_id,
            filterJobTypeChange,
            filterCountryChange,
            onClosing,
            visible,
            applyJob,
            compensation_types,
            location_roles,
            getSchedules,
            schedules,
            time_frames,
            rates,
            savedJob,
            listedJob,
            unsavedJob,
            unsavedListedJob,
            previous,
            next,
            applyToJob,
            toggleModal,
            modalActive,
            application,
            getApplication,
            addApplication,
            location_role,
            filterLocationRoleChange,
            searchByLocation,
            sortOrder,
            enableSort,
            separateSearch,
            copyToClipboard,
            formatDate,
            externaljobs,
            viewJobByParams,
            clearFilter
        }
    },
    methods:{
        sortByRate(){
            let sortedjobs = [];
            if(this.sortOrder){
                sortedjobs = this.jobs.sort((i,z) => i.exact_amount - z.exact_amount).map((obj) => obj);
            }else{
                sortedjobs = this.jobs.sort((i,z) => z.exact_amount - i.exact_amount).map((obj) => obj);
            }
            this.sortOrder = !this.sortOrder;
            this.jobs = sortedjobs;
        },

        enableSorting(){
            this.enableSort = true
            this.sortByRate()
        }
    },
    components: {
        PageLoader,
        Confirm,
        ModalApplication
    }
});
</script>

<style>
.w-15 {
    width: 15%;
}
.w-70 {
    width: 70%;
}
.pl-20 {
    padding-left: 20px;
}
.tab-btn {
    position: absolute;
    top: 20px;
    right: 15px;
}
.slideout-header--text {
    font-weight: bold;
}
.no-margin {
    margin: 0px !important;
}
.application-fixed {
    position: fixed;
    width: 95%;
}
.promoted-badge {
    background: #d9ac39!important;
}
input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}
.sort-btn {
    cursor: pointer;
    margin-right: 3px;
}
.filter-box { min-width: 527px; }
.filter-div { width: 49%; }
.external-job-details {
    max-width: 800px;
}
.external-job-body {
    overflow: none;
    /* max-width: 800px; */
}
.job-title {
    font-size: 14px;
    color: #495057;
    font-weight: 700;
}
.ext-content {
    height: 40px;
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: pointer;
}
.ext-content-larajobs {
    height: 30px;
    cursor: pointer;
}
.hide {
    display: none!important;
}
.ext-content > img { width: 100px; }
.card-link { text-decoration: none; }
.source-site { color:#a9a9a9; }
</style>
<style scoped>
#filter {
    width:50%;
}
.job-type-label {
    margin-left: 5px;
    font-size: 14px;
}
.view-profile-button-wrapper:hover .tooltip {
  opacity: 1;
}
.tooltip-style-private {
  width: 140px;
  padding: 2px 6px;
  transition: all 0.3s;
}
.remote-h4 {
    width: 375px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.remote-card {
    border: 1px solid #ccc;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: 15px;
    background: #fafafa;
    align-items: center;
}
.remote-content {
    border: 1px solid #ccc;
    height: 80px;
    background: #fafafa;
    margin-left: 25px;
}
.remote-body {
    display: flex;
    align-items: center;
    height: 80px;
    margin-left: 40px;
    justify-content: space-between;
}
.job-td {
    font-size: 14px;
    padding: 20px 5px;
}
</style>
