<template>
    <ul class="sidebar-nav">
        <li class="sidebar-item" v-if="authuser.role_id === 2 || authuser.role_id === 3">
            <a data-bs-target="#findCandidate" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fab fa-searchengin fa-fw"></i><span class="align-middle">Find Candidates</span>
            </a>
            <ul id="findCandidate" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.applicants.find' }">
                        All Candidates
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.applicants.match' }">
                        Matched Candidates
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.applicants.saved' }">
                        Saved Candidates
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.applicants.invited' }">
                        Invited Candidates
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item" v-if="authuser.role_id === 2 || authuser.role_id === 3">
            <a data-bs-target="#ui" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fa fa-solid fa-gear fa-fw"></i> <span class="align-middle">Settings</span>
            </a>
            <ul id="ui" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.profile' }">
                        View Profile
                    </router-link>
                    <router-link v-if="authuser.role_id === 3" class="sidebar-link" :to="{ name: 'settings.users.edit' }">
                        Profile Settings
                    </router-link>
                    <router-link v-if="authuser.role_id === 2" class="sidebar-link" :to="{ name: 'settings.config' }">
                        Company Settings
                    </router-link>
                    <router-link v-if="authuser.role_id === 2" class="sidebar-link" :to="{ name: 'settings.users' }">
                        Manage Users
                    </router-link>
                    <router-link v-if="authuser.role_id === 2 || authuser.role_id === 3" class="sidebar-link" :to="{ name: 'settings.jobs.questions' }">
                        Manage Job Posting Questions
                    </router-link>
                    <router-link v-if="authuser.role_id === 2" class="sidebar-link" :to="{ name: 'settings.departments' }">
                        Manage Departments
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item" v-if="authuser.company_id && authuser.role_id === 2 || authuser.role_id === 3">
            <router-link class="sidebar-link" :to="{ name: 'employer.jobs' }">
                <i class="fa fas fa-suitcase fa-fw"></i> <span class="align-middle">Job Postings</span>
            </router-link>
            <ul id="jobPosting" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.jobs' }">
                        Job Postings
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.jobs.archive' }">
                        Archive Jobs
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item" v-if="authuser.role_id === 2 || authuser.role_id === 3">
            <router-link class="sidebar-link" :to="{ name: 'employer.new-applicants' }">
                <i class="fa fas fa-users fa-fw"></i> <span class="align-middle">Manage Applicants</span>
            </router-link>
            <ul id="manageCandidate" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.new-applicants' }">
                        Manage Applicants
                    </router-link>
                    <router-link class="sidebar-link" :to="{ name: 'employer.applicants.reports' }">
                        Reports
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item" v-if="authuser.company_id && authuser.role_id === 2 || authuser.role_id === 3">
            <router-link class="sidebar-link" :to="{ name: 'employer.trainings' }">
                <i class="fas fa-book fa-fw"></i> <span class="align-middle">Training Courses</span>
            </router-link>
            <ul id="manageCandidate" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'employer.trainings' }">
                        Training Courses
                    </router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import userRepo from '@/repositories/settings/users';

export default defineComponent({
    props: {
        authuser: {
            type: Object,
            default: {}
        }
    }
})
</script>

<style>
    .fa-searchengin {
        font-size: 18px;
    }
    body[data-sidebar-behavior=compact]:not([data-sidebar-position=right]) .sidebar-nav>.sidebar-item>.sidebar-dropdown {
        left: 65px !important;
    }
    body[data-sidebar-behavior=compact] .sidebar-nav>.sidebar-item>.sidebar-dropdown {
        background: #293042 !important;
    }
    body[data-sidebar-behavior=compact] .sidebar-nav>.sidebar-item>.sidebar-dropdown>.sidebar-item .sidebar-link {
        color: #fff !important;
    }
</style>