import axios from "axios";

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async submitFeedbackReply({state, commit}, data){
            return await axios.post(`email/reply-feedback`,data).then(response => {
                return response.data
            })
        }
    }
}