<template>
    <div class="tab-pane fade active show" id="company" role="tabpanel">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Company Settings</h5>
                <PageLoader v-if="page.companyLoading" />
                <div v-else>
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="row">
                        <div class="col-md-4">
                            <BaseInput 
                                v-if="page.authuser.role_id === 2"
                                v-model="company.company_name"
                                label="Company Name"
                                type="text"
                                :class="{ 'is-invalid' : errors && errors.company_name }"
                                id="company_name"
                                :errors="errors"
                                is-required
                            />
                            <div class="readonly-data" v-else>
                                <div>Company Name</div>
                                <div>{{ company.company_name }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <BaseInput 
                                v-if="page.authuser.role_id === 2"
                                v-model="company.company_email"
                                label="Company Email"
                                type="email"
                                :class="{ 'is-invalid' : errors && errors.company_email }"
                                id="company_email"
                                :errors="errors"
                                is-required
                            />
                            <div class="readonly-data" v-else>
                                <div>Company Email</div>
                                <div>{{ company.company_email }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <BaseInput 
                                v-if="page.authuser.role_id === 2"
                                v-model="company.company_website"
                                label="Company Website"
                                type="url"
                                :class="{ 'is-invalid' : errors && errors.company_website }"
                                id="company_website"
                                :errors="errors"
                            />
                            <div class="readonly-data" v-else>
                                <div>Company Website</div>
                                <div>{{ company.company_website ?? '(Not Set)' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <BaseInput 
                                v-if="page.authuser.role_id === 2"
                                v-model="company.company_address"
                                label="Company Address"
                                type="text"
                                id="company_address"
                            />
                            <div class="readonly-data" v-else>
                                <div>Company Address</div>
                                <div>{{ company.company_address ?? '(Not Set)' }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <BaseInput 
                                v-if="page.authuser.role_id === 2"
                                v-model="company.company_phone"
                                label="Company Contact Number"
                                type="text"
                                :class="{ 'is-invalid' : errors && errors.company_phone }"
                                id="company_phone"
                                :errors="errors"
                                is-required
                            />
                            <div class="readonly-data" v-else>
                                <div>Company Phone</div>
                                <div>{{ company.company_phone ?? '(Not Set)' }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div v-if="hasImage">
                                <label class="form-label">Company Logo</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" :value="company.company_logo" readonly>
                                    <button :disabled="page.authuser.role_id === 3" class="btn btn-outline-danger" type="button" @click="removeImage">Remove</button>
                                </div>
                            </div>
                            <div v-else>
                                <BaseInputFile 
                                    label="Company Logo"
                                    :class="{ 'is-invalid' : errors && errors.company_logo }"
                                    id="image"
                                    :errors="errors"
                                    @changeFile="onFileLogoChange"
                                    :form-text="``"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <BaseInput 
                                v-model="company.company_size"
                                label="Company Size"
                                type="text"
                                id="company_size"
                            />
                        </div>
                        <div class="col-md-4">
                            <BaseSelect
                                v-model="company.hiring_status"
                                label="Hiring Status"
                                :class="{ 'is-invalid' : errors && errors.hiring_status }"
                                id="hiring_status"
                                :errors="errors"
                                is-required
                                :options="hiring_statuses"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-12">
                            <label for="default_benefits" class="form-label">Company-Wide Employee Benefits</label>
                            <Editor :form="company" @saveContent="saveBenefits" />
                        </div>
                    </div>
                    <BaseButton 
                        :is-saved="status === 200" 
                        :default-btn="page.defaultBtn"
                        @btnClicked="saveCompanyInfo" 
                    />
                </div>
            </div>
        </div>
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this logo?`"
            @cancel="toggleConfirmation"
            @isDelete="removeCompanyLogo"
        />
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watchEffect, computed } from 'vue';
import globalRepo from '@/repositories/global';
import userRepo from '@/repositories/settings/users';

export default defineComponent({
    setup() {
        const { user, company, getUser, updateCompany, errors, status } = userRepo();
        const { hiring_statuses, account_types } = globalRepo();

        const photo_url = reactive({ url: '' });
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            companyLoading: true,
            defaultBtn: false
        });
        const isConfirm = ref(false)
        const imageFile = ref('')
        const removeLogo = ref(false)

        onMounted( async() => { 
            await getUser(page);
            setInterval(function () {
                page.companyLoading = false;
            }, 2000);
            page.isTwoFactorEnabled = (page.authuser.two_factor_confirmed_at != null);
        })

        const hasImage = computed(()=> {
            return company.value.company_logo ? true:false
        })

        const onFileLogoChange = (file) => {
            imageFile.value = file
        }

        const saveCompanyInfo = async () => {
            status.value = '';
            page.defaultBtn = true;
            let companyData = new FormData();
            const logo = imageFile.value ?? null
            companyData.append('company_name', company.value.company_name ?? '');
            companyData.append('company_email', company.value.company_email ?? '');
            companyData.append('company_website', company.value.company_website ?? '');
            companyData.append('company_address', company.value.company_address ?? '');
            companyData.append('company_phone', company.value.company_phone ?? '');
            companyData.append('company_logo', logo);
            companyData.append('company_size', company.value.company_size ?? '');
            companyData.append('default_benefits', company.value.default_benefits ?? '');
            companyData.append('hiring_status', company.value.hiring_status ?? '');
            companyData.append('account_type', company.value.account_type ?? 'Basic');
            companyData.append('id', company.value.id ?? '');
            companyData.append('authuser_id', page.authuser.id);
            companyData.append('remove_logo', removeLogo.value);
            await updateCompany(companyData); 
            page.defaultBtn = false;
            if(status.value == 200){
                await getUser(page);
            }
            imageFile.value = null
        }

        const saveBenefits = (benefits) => {
            company.value.default_benefits = benefits;
        }

        const removeImage = () => {
            isConfirm.value = true;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const removeCompanyLogo = async () => {
            removeLogo.value = true
            company.value.company_logo = null
            toggleConfirmation();
        }

        return {
            user,
            getUser,
            onFileLogoChange,
            page,
            photo_url,
            errors,
            status,
            company,
            saveCompanyInfo,
            updateCompany,
            saveBenefits,
            hiring_statuses,
            account_types,
            removeImage,
            isConfirm,
            toggleConfirmation,
            removeCompanyLogo,
            imageFile,
            hasImage,
            removeLogo
        }
    }
})
</script>
<style scoped>
.readonly-data {
    font-size: 1rem;
}
.readonly-data div:nth-child(1){
    margin-top: 4px;
}
.readonly-data div:nth-child(2){
    border: 1px solid #CCC;
    padding: 3px 12px;
    margin-top: 3px;
    border-radius: 3px;
    background: #efefef;
    color: #757575;
    min-height: 34px;
}
</style>