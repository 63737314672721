<template>
  <main class="content">
    <div class="container-fluid p-0">
      <div id="back-button"><span class="w-30 float-end back-button">
          <a href="javascript:;" @click="BackAction"><i class="fa fa-arrow-left"></i></a>
        </span></div>
      <h1 class="h3 mb-3">Candidate Profile</h1>
      <div v-if="page.isLoading">
        <div class="card" style="padding: 10% 0;">
          <Loading />
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-header display-photo">
                <img :src="page.profileData.display_photo" class="rounded-circle">
              </div>
              <div class="card-body display-primary-details">
                <h2>{{ page.profileData.name }}</h2>
                <p>{{ page.profileData.email }}</p>
                <p v-if="page.profileData.contact_number"><i class="fas fa-phone"></i> {{
                    page.profileData.contact_number
                }}</p>
                <p v-if="page.profileData.address"><i class="fas fa-map-marker-alt"></i> {{ page.profileData.address }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="card">
              <div class="card-header">
                <div class="float-start">
                  <h3>Profile Details</h3>
                </div>
                <div class="card-actions float-end">
                  <button
                    v-if="page.profileData.invitations?.length && page.profileData.invitations.find(o => o.company_id === page.authuser.company_id)"
                    disabled class="btn btn-success"><i class="fas fa-user-plus"></i> Invited</button>&nbsp;
                  <button v-else class="btn btn-outline-secondary" @click="ShowInviteOptions(page.profileData.id)"><i
                      class="fas fa-user-plus"></i> Invite</button>&nbsp;
                  <button class="btn btn-outline-secondary" @click="SendMessage(page.profileData.id)"><i
                      class="fas fa-envelope"></i> Message</button>&nbsp;
                  <button v-if="!page.is_saved" class="btn btn-outline-secondary"
                    @click="SaveCandidate(page.profileData.id)"><i class="fas fa-bookmark"></i> Save</button>&nbsp;
                  <button v-else class="btn btn-outline-secondary" @click="UnsaveCandidate(page.profileData.id)"><i
                      class="far fa-bookmark"></i> Unsave</button>&nbsp;
                </div>
              </div>
              <div class="card-body profile-details" v-if="page.profileData.is_profile_visible">

                <div class="companies cardlet" v-if="completedCourse.length || learning_plans_assigned.length">
                  <h3><i class="fad fa-file-certificate"></i> &nbsp;Completed Trainings</h3>
                  <div class="completed-wrap">
                    <div class="accordion" id="completedTrainingAccordion">
                      <div class="accordion-item" v-if="completedCourse.length">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Completed Courses
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#completedTrainingAccordion">
                          <div class="accordion-body">
                            <div class="training-list-wrapper">
                              <ul>
                                <li v-for="item in completedCourse" v-bind:key="item">{{ item.training_details.title }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item" v-if="learning_plans_assigned.length">
                        <h2 class="accordion-header" id="headingTwo">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Completed Learning Plans
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#completedTrainingAccordion">
                          <div class="accordion-body">
                            <div class="training-list-wrapper">
                              <ul>
                                <li v-for="item in learning_plans_assigned" v-bind:key="item">{{ item.section }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="works cardlet" v-if="page.profileData.educations">
                  <h3><i class="fas fa-user-graduate"></i> &nbsp;Education</h3>
                  <div>
                    <div class="works-item" v-for="(item, index) in page.profileData.educations" :key="index">
                      <div class="row gaps">
                        <div class="col-3 label">Education Level</div>
                        <div class="col-9">
                          <div class="capitalize">{{ item.education_level }}</div>
                        </div>
                      </div>
                      <div class="row gaps" v-if="item.field_of_study">
                        <div class="col-3 label">Field of Study</div>
                        <div class="col-9">
                          <div class="capitalize">{{ item.field_of_study }}</div>
                        </div>
                      </div>
                      <div class="row gaps">
                        <div class="col-3 label">School Name</div>
                        <div class="col-9">
                          <div class="capitalize">{{ item.school_name }}</div>
                        </div>
                      </div>
                      <div class="row gaps" v-if="item.school_address">
                        <div class="col-3 label">School Address</div>
                        <div class="col-9">
                          <div class="capitalize">{{ item.school_address }}</div>
                        </div>
                      </div>
                      <div class="row gaps" v-if="item.school_year">
                        <div class="col-3 label">School Year</div>
                        <div class="col-9">
                          <div class="capitalize">{{ item.school_year }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="works cardlet" v-if="page.profileData.works">
                  <h3><i class="fas fa-briefcase"></i> &nbsp;Work Experience</h3>
                  <div>
                    <div class="works-item" v-for="(item, index) in page.profileData.works" :key="index">
                      <div class="row gaps">
                        <div class="col-3 label">Job Position</div>
                        <div class="col-9">
                          <div>{{ item.job_title }}</div>
                        </div>
                      </div>
                      <div class="row gaps">
                        <div class="col-3 label">Company Name</div>
                        <div class="col-9">
                          <div>{{ item.company }}</div>
                        </div>
                      </div>
                      <div class="row gaps">
                        <div class="col-3 label">Company Address</div>
                        <div class="col-9">
                          <div>{{ item.company_address }}</div>
                        </div>
                      </div>
                      <div class="row gaps">
                        <div class="col-3 label">Work Period</div>
                        <div class="col-9">
                          <div>{{ item.time_period }}</div>
                        </div>
                      </div>
                      <div class="row gaps">
                        <div class="col-3 label">Job Description</div>
                        <div class="col-9">
                          <div>{{ item.description }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="skills cardlet" v-if="page.profileData.skills">
                  <h3><i class="fas fa-user-cog"></i> &nbsp;Skills Showcase</h3>
                  <div>
                    <div class="skill-item" v-for="(item, index) in page.profileData.skills.skills_arr" :key="index">
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                </div>

                <div class="certifications cardlet" v-if="page.profileData.certificates">
                  <h3><i class="fas fa-certificate"></i> &nbsp;Certifications</h3>
                  <div class="certificate-wrap">
                    <div class="certificate-item" v-for="(item, index) in page.profileData.certificates" :key="index">
                      <div class="row gaps">
                        <div class="col-3 label">Certificate Title</div>
                        <div class="col-9">
                          <div>{{ item.title }}</div>
                        </div>
                      </div>
                      <div class="row gaps" v-if="item.validity">
                        <div class="col-3 label">Expires on</div>
                        <div class="col-9">
                          <div>{{ item.validity }}</div>
                        </div>
                      </div>
                      <div class="row gaps" v-else>
                        <div class="col-3 label">Expires on</div>
                        <div class="col-9">
                          <div>No Expiry</div>
                        </div>
                      </div>
                      <div class="row gaps">
                        <div class="col-3 label">Description</div>
                        <div class="col-9">
                          <div>{{ item.description }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="card-body" v-else>
                <p class="text-center text-lg p-5 bg-light text-dark"><i class="fas fa-lock mx-2"></i>This profile is private.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalInvite :modalActive="modalActive" :form="page" :errors="errors" @close="toggleModal"
        @saveChanges="ProcessSendInvite" />
    </div>
  </main>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router'
import Loading from '@/components/modules/PageLoader.vue';
import CandidateRepo from '@/repositories/employer/candidates'
import ModalInvite from '@/views/employer/find/ModalInvite.vue';
import JobRepo from '@/repositories/jobs'

export default defineComponent({
  setup() {
    const router = useRouter()
    const { candidate, learning_plans_assigned, current_courses, getCandidate, sendInvite, processSaveCandidate, saved_candidates, getSavedCandidates, processUnSaveCandidate } = CandidateRepo()
    const { jobs, getActiveJobs, errors } = JobRepo()
    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      isLoading: true,
      candidate_id: router.currentRoute.value.params.user_id ?? 0,
      profileData: {},
      job_id: 0,
      jobOptions: [],
      savedCandidates: [],
      is_saved: false
    })

    onMounted(async () => {
      await getCandidate(page);
      await getActiveJobs(page);
      await getSavedCandidates(page);
      page.profileData = candidate;
      page.jobOptions = jobs;
      page.savedCandidates = saved_candidates
      page.savedCandidates.forEach(function (item, index) {
        if (item.id == page.candidate_id) {
          page.is_saved = true;
        }
      });
      window.addEventListener("scroll", function () {
        let back_button_elem = document.getElementById('back-button');
        if(back_button_elem){
          if (window.scrollY > 79) {
            back_button_elem.classList.add('sticky')
          } else {
            back_button_elem.classList.remove('sticky')
          }
        }
      });
    })

    const SendMessage = (id) => {
      router.push(`/employer/messages/${id}`);
    }

    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    }

    const ShowInviteOptions = (id) => {
      modalActive.value = true;
      errors.value = [];
    }

    const ProcessSendInvite = async () => {
      if (page.job_id == 0) {
        errors.value = {
          job: 'Please select a job.'
        }
      } else {
        errors.value = [];

        let userData = new FormData();
        userData.append('user_id', page.authuser.id ?? '');
        userData.append('candidate_id', page.candidate_id ?? '');
        userData.append('job_id', page.job_id ?? '');

        await sendInvite(userData);
        getCandidate(page);
        page.job_id = 0
        toggleModal();
      }
    }

    const SaveCandidate = async (id) => {
      await processSaveCandidate(page, id);
      await getSavedCandidates(page);
      page.savedCandidates = saved_candidates
      page.savedCandidates.forEach(function (item, index) {
        if (item.id == page.candidate_id) {
          page.is_saved = true;
        }
      });
      page.isLoading = true;
      await getCandidate(page);
    }

    const UnsaveCandidate = async (id) => {
      await processUnSaveCandidate(page, id);
      await getSavedCandidates(page);
      page.savedCandidates = saved_candidates
      page.is_saved = false
      page.savedCandidates.forEach(function (item, index) {
        if (item.id == page.candidate_id) {
          page.is_saved = true;
        }
      });
      page.isLoading = true;
      await getCandidate(page);
    }

    const BackAction = () => {
      router.go(-1);
    }

    const completedCourse = computed(() => {
        return current_courses.value.filter(data => data.course_details.course_completed === true);
    })

    return {
      page,
      candidate,
      getCandidate,
      sendInvite,
      SendMessage,
      ShowInviteOptions,
      modalActive,
      toggleModal,
      jobs,
      getActiveJobs,
      errors,
      ProcessSendInvite,
      SaveCandidate,
      processSaveCandidate,
      getSavedCandidates,
      saved_candidates,
      UnsaveCandidate,
      processUnSaveCandidate,
      BackAction,
      learning_plans_assigned, 
      current_courses,
      completedCourse
    }
  },
  components: {
    Loading,
    ModalInvite
  }
})
</script>

<style scoped>
.display-photo {
  text-align: center;
  background: #293042;
}

.display-photo img {
  width: 100%;
  max-width: 175px;
  margin-top: 35px;
  margin-bottom: -90px;
  border: 5px solid #fff;
  height: 175px;
}

.display-primary-details {
  padding-top: 90px;
  text-align: center;
}

.display-primary-details h2 {
  color: #333;
}

.display-primary-details p {
  font-size: 17px;
}

.profile-details {
  padding-top: 0;
  color: #434343;
}

.cardlet {
  padding: 20px 20px;
  background: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 15px;
}

.cardlet h3 {
  margin-bottom: 15px;
  ;
}

.educations-item {
  padding: 10px 13px;
  background: #ededed;
  margin-bottom: 5px;
}

.educations-item div {
  display: inline-block;
  margin-right: 5px;
}

.works .gaps,
.certifications .gaps {
  margin: 7px 0px;
}

.works .label,
.certifications .label {
  font-size: 13px;
  font-weight: bold;
}

.works-item,
.certificate-item {
  padding: 10px 10px;
  background: #ededed;
  border-radius: 7px;
  margin-bottom: 10px;
}

.skill-item {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 8px;
  background: #3f80ea;
  color: #fff;
  border-radius: 5px;
}

.certificate-item {
  padding: 20px 20px;
}

.btn-success.disabled,
.btn-success:disabled {
  background: #aeaeae;
  border-color: #969696;
  color: #fff;
}

.back-button {
  margin-top: -4px;
  margin-bottom: 0px;
  text-align: right;
  font-size: 17px;
  position: initial;
}

.back-button a {
  border-radius: 50%;
  border: 1px solid #fafafa;
  color: #878787;
  padding: 0px 8px;
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: -10px;
}

.back-button a:hover {
  border-color: #878787;
}

.back-button a:active {
  background-color: #878787;
  color: #fff;
}

.sticky {
  position: fixed;
  right: 0;
  z-index: 99;
  width: 100%;
  top: 0px;
  background: #fff;
  padding: 10px 0;
  padding-right: 40px;
  border-bottom: 1px solid #b6b6b6;
}

.capitalize {
  text-transform: capitalize;
}
</style>