<template>
    <div class="col-3 course-item">
        <div class="card">
            <a :href="link" target="_blank">
                <img class="card-img-top" :src="img_url" alt="Unsplash" />
            </a>
            <div class="card-header px-4 pt-4">
                <h5 class="card-title mb-0"><a :href="link" target="_blank">{{ title }}</a></h5>
                <div v-if="course_status=='Enrolled'" class="badge bg-warning my-2">{{ course_status }}</div>
                <div v-else-if="course_status=='In Progress'" class="badge bg-primary my-2">{{ course_status }}</div>
                <div v-else class="badge bg-success my-2">{{ course_status }}</div>
            </div>
            <div class="card-body px-4 pt-2">
                {{ strippedContent(excerpt).length < 50 ? strippedContent(excerpt) : strippedContent(excerpt).substring(0,120) + "..."}}
            </div>
            <ul class="list-group list-group-flush" v-if="progress_percentage">
                <li class="list-group-item link-text px-4 pb-4">
                    <p class="mb-2 fw-bold">Progress <span class="float-end">{{ progress_percentage }}%</span></p>
                    <div class="progress progress-sm">
                        <div class="progress-bar" role="progressbar" :aria-valuenow="progress_percentage" aria-valuemin="0" aria-valuemax="100" :style="{
                            'width': progress_percentage+'%'
                        }"></div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
    props: {
        img_url: {
            type: String,
            default: process.env.VUE_APP_UPCLOUD_ASSETS + '/images/no-photo.png'
        },
        title: {
            type: String,
            default: 'Course Title'
        },
        course_status: {
            type: String,
            default: 'In Progress'
        },
        excerpt: {
            type: String,
            default: 'Lorem ipsum dolor pretium turpis et arcu.'
        },
        progress_percentage: {
            type: Number,
            default: 0
        },
        link: {
            type: String,
            default: ''
        }
    },
    setup() {
        const page = reactive({
            api_url: process.env.VUE_APP_API_URL,
            upcloud_url: process.env.VUE_APP_UPCLOUD_ASSETS
        });
        const strippedContent = (string) => {
            return string.replace(/<\/?[^>]+>/ig, " ");
        }
        return {
            page,
            strippedContent
        }
    }
});
</script>

<style scoped>
    .course-item { display: inline-block; margin-right: 10px; }
    .course-item > .card > .card-header { height: 120px!important; }
    .course-item > .card > .card-body { height: 120px!important; }
    .card-title a { color: #495057; }
</style>