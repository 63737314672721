import axios from "axios";
import { ref, inject } from "vue";

export default function interviewTrainingRepo() {
    
    const interviewTrainings = ref([]);
    const interviewTraining = ref([]);
    const topInterviewTrainings = ref([]);
    const interviewTrainingArticles = ref([]);
    const interviewTrainingVideos = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const toast = inject('toast');


    /**
     *  For Admin
     */

    const getAdminInterviewTrainings = async (page) => {
        let response = await axios.get(`admin/interview-trainings`);
        interviewTrainings.value = response.data.data;
        page.isLoading = false;
    }

    const getAdminInterviewTraining = async (id) => {
        let response = await axios.get(`admin/interview-trainings/${id}`);
        interviewTraining.value = response.data.data;
    }

    const insertInterviewTraining = async (page, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/interview-trainings`, data);
            interviewTraining.value = response.data.interviewTrainings;
            status.value = response.status;
            alertMessage(response.data.message);
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateInterviewTraining = async (page, data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`admin/interview-trainings/${id}`, data);
            status.value = response.status;
            alertMessage(response.data.message);
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const unlinkImage = async (id) => {
        let response = await axios.get(`admin/interview-trainings/${id}/image`);
        interviewTraining.value = response.data.data;
        alertMessage(response.data.message);
        status.value = response.status;
    }

    const destroyInterviewTraining = async (page, id) => {
        try {
            let response = await axios.delete(`admin/interview-trainings/${id}`);
            interviewTrainings.value = response.data.data;
            alertMessage(response.data.message);
            status.value = response.status;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const searchAdminInterviewTrainings = async (page) => {
        let response = await axios.post(`admin/interview-trainings/search`, page);
        interviewTrainings.value = response.data.data;
        page.isLoading = false;
    }


    /**
     *  For Candidate
     */

     const getCandidateInterviewTrainings = async (page) => {
        let response = await axios.get(`candidate/interview-trainings`);
        interviewTrainings.value = response.data.data;
        
        response.data.data.forEach((value, index) => {
            if (value.type == 'Article') {
                interviewTrainingArticles.value.push(value);
            } else if (value.type == 'Video') {
                interviewTrainingVideos.value.push(value);
            }
        });
        page.isLoading = false;
    }

    const getCandidateInterviewTraining = async (id) => {
        let response = await axios.get(`candidate/interview-trainings/${id}`);
        interviewTraining.value = response.data.data;
    }

    const searchCandidateInterviewTrainings = async (page) => {
        let response = await axios.post(`candidate/interview-trainings/search`, page);
        interviewTrainings.value = response.data.data;
        page.isLoading = false;
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    return {
        message,
        errors,
        status,
        interviewTraining,
        interviewTrainings,
        interviewTrainingArticles,
        interviewTrainingVideos,
        getAdminInterviewTraining,
        getAdminInterviewTrainings,
        searchAdminInterviewTrainings,
        getCandidateInterviewTraining,
        getCandidateInterviewTrainings,
        searchCandidateInterviewTrainings,
        insertInterviewTraining,
        updateInterviewTraining,
        destroyInterviewTraining,
        unlinkImage,
        alertMessage
    }
}