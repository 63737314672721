import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";

// Plugins
import Toaster from "@meforma/vue-toaster";
import Datepicker from '@vuepic/vue-datepicker';
import Slideout from '@hyjiacan/vue-slideout';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from 'vue3-apexcharts';
import Nl2br from 'vue3-nl2br';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

import '@vuepic/vue-datepicker/dist/main.css';
import '@hyjiacan/vue-slideout/dist/slideout.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import 'sweetalert2/dist/sweetalert2.min.css';

require('/public/assets/css/light.css');
require('/public/assets/css/style.css');
require('/public/assets/css/theme.css');
require('/public/assets/css/imagehover.min.css');
require("@/store/subscriber");

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

// components
import PageLoader from '@/components/modules/PageLoader.vue';
import BootstrapBaseTable from '@/components/modules/Datatable.vue';
import Confirm from '@/components/modules/Confirmation.vue';
import BaseInput from '@/components/modules/form/BaseInput.vue';
import BaseRadio from '@/components/modules/form/BaseRadio.vue';
import BaseSelect from '@/components/modules/form/BaseSelect.vue';
import BaseCheckbox from '@/components/modules/form/BaseCheckbox.vue';
import BaseInputFile from '@/components/modules/form/BaseInputFile.vue';
import BaseEditor from '@/components/modules/form/BaseEditor.vue';
import BaseButton from '@/components/modules/form/BaseButton.vue';
import Editor from '@/components/modules/Editor.vue';
import Modal from '@/components/modules/Modal.vue';
import AdvanceModal from '@/components/modules/AdvanceModal.vue';
import VueMultiselect from 'vue-multiselect';
import Popper from "vue3-popper";

const app = createApp(App)
    app.use(store)
    app.use(router)
    app.use(Toaster, {
        position: 'top-right'
    }).provide('toast', app.config.globalProperties.$toast);
    app.use(Slideout);
    app.use(VueSweetalert2);
    app.use(VueApexCharts);
    app.use(VuePlyr, {
        plyr: {}
    });
    app.component('Datepicker', Datepicker)
        .component('PageLoader', PageLoader)
        .component('BaseTable', BootstrapBaseTable)
        .component('Confirm', Confirm)
        .component('BaseInput', BaseInput)
        .component('BaseRadio', BaseRadio)
        .component('BaseSelect', BaseSelect)
        .component('BaseInputFile', BaseInputFile)
        .component('Editor', Editor)
        .component('BaseCheckbox', BaseCheckbox)
        .component('BaseEditor', BaseEditor)
        .component('BaseButton', BaseButton)
        .component('Modal', Modal)
        .component('AdvanceModal', AdvanceModal)
        .component('multiselect', VueMultiselect)
        .component("Popper", Popper)
        .component('nl2br', Nl2br)
    app.mount('#app')

import "bootstrap"
