import InterviewTrainingIndex from '@/views/admin/interviewTrainings/Index';

const routes = [
    {
        path: '/admin/interviewtrainings',
        name: 'admin.interviewtrainings',
        component: InterviewTrainingIndex
    }
]

export default routes