<template>
    <div class="display-info-header p-3" v-if="user_id > 0">
        <div class="image is-inline">
            <img :src="image" class="rounded-circle">
        </div>
        <div class="info is-inline p-3 pt-0 pb-0">
            <span class="name is-inline">{{ fullName }}</span>
            <span class="details-name is-inline" v-if="hasSubDetails">{{ subDetails }}</span>
            <span class="details is-inline">
                <i :class="{
                        'chat-status': true,
                        'online': isOnline,
                        'offline': isOffline,
                        'away': isAway,
                        'dont-disturb': isDontDisturb }">
                </i>
                <i class="chat-status-text">{{ chatStatusText }}</i></span>
        </div>
        <div class="options is-inline">
            <div class="other-options is-inline">
                <button class="btn btn-info" @click="toggleOptions" @blur="toggleBlur"><i class="fa fa-ellipsis-h"></i></button>
                <div :class="{ 'other-options-dropdown' : true, 'force-hide' : reactive_data.show_options === false ? true : false }">
                    <ul>
                        <li :class="{ 'inactive': !has_messages }"><a href="#" @click="handleOption"><i class="fa fa-envelope"></i> &nbsp;&nbsp;Mark as unread</a></li>
                        <li class="delete"><a href="#" @click="handleDelete"><i class="fa fa-trash"></i> &nbsp;&nbsp;Delete</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue';
export default defineComponent({
    props: {
        user_id: {
            type: [Number, String],
            default: 0
        },
        hasSubDetails: {
            type: Boolean,
            default: true
        },
        image: {
            type: String,
            default: 'http://multicomp.cs.cmu.edu/wp-content/uploads/2016/08/person-placeholder-300x300.jpg'
        },
        fullName: {
            type: String,
            default: 'No Name'
        },
        subDetails: {
            type: String,
            default: 'No Details'
        },
        isOnline: {
            type: Boolean,
            default: false
        },
        isOffline: {
            type: Boolean,
            default: true
        },
        isAway: {
            type: Boolean,
            default: false
        },
        isDontDisturb: {
            type: Boolean,
            default: false
        },
        chatStatusText: {
            type: String,
            default: 'Offline'
        },
        show_options: {
            type: Boolean,
            default: false
        },
        has_messages: {
            type: Boolean,
            default: true
        }
    },
    emits: [ 'action_headers', 'action_delete' ],
    setup(props, { emit }) {

        const reactive_data = reactive({
            show_options: false
        });

        const data = {}

        const toggleOptions = () => {
            reactive_data.show_options = !reactive_data.show_options
        }

        const toggleBlur = () => {
            setTimeout( ()=>{ reactive_data.show_options = false }, 200 );
        }

        const handleOption = () => {
            emit('action_headers');
        }

        const handleDelete = () => {
            emit('action_delete');
        }

        return {
            data,
            reactive_data,
            toggleOptions,
            toggleBlur,
            handleOption,
            handleDelete
        }
    }
})
</script>

<style>
    .display-info-header {
        border-bottom: 1px solid #e7e7e7;
        box-shadow: 0px 4px 5px 0px #cccccc75;
    }
    .display-info-header .image {
        width: 55px;
    }
    .display-info-header .image img {
        width: 100%;
        height: 55px;
    }
    .display-info-header .info {
        width: 90%;
        max-width: 1160px;
        line-height: 20px;
    }
    .display-info-header .info .name {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        color: #495057;
    }
    .display-info-header .info .details-name {
        width: 100%;
        font-size: 14px;
    }
    .display-info-header .info .details .chat-status {
        width: 13px;
        height: 13px;
        display: inline-block;
        background: #afafaf;
        vertical-align: middle;
        margin-right: 5px;
        border-radius: 50%;
    }
    .display-info-header .info .details .chat-status.online {
        background: #36d251;
    }
    .display-info-header .info .details .chat-status-text {
        font-style: normal;
        line-height: 0;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
    }
    .other-options {
        position: relative;
    }
    .other-options-dropdown {
        position: absolute;
        right: 0;
        top: 37px;
        width: 150px;
        background: #f1f1f1;
        border-radius: 3px;
        text-align: center;
        box-shadow: 1px 1px 4px 0px #a4a4a4;
    }
    .other-options-dropdown ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .other-options-dropdown ul li {
        padding: 10px 16px 10px;
        text-align: left;
    }
    .other-options-dropdown ul li a {
        color: #484848;
        text-decoration: none;
        display: block;
    }
    .other-options-dropdown ul li:hover {
        background:#1f9bcf;
    }
    .other-options-dropdown ul li.delete:hover {
        background:#cf1f1f;
    }
    .other-options-dropdown ul li:hover a {
        color:#fff;
    }
    li.inactive {
        pointer-events: none;
    }
    .other-options-dropdown ul li.inactive a {
        color: #acacac;
    }
    .options.is-inline {
        position: absolute;
        right: 2%;
        margin-top: 10px;
        z-index: 99;
    }
</style>