<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-12">
                        <BaseInput 
                            v-model="form.category_title"
                            label="Category Title"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.category_title }"
                            id="category_title"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" :is-disabled="buttonCallback" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        },
        buttonCallback: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {

        const status = ref('');
        const close = () => {
            emit('close')
        }
        
        const saveChanges = () => {
            status.value = 200;
            let formData = {
                category_title: props.form.category_title ?? ''
            }

            emit('saveChanges', formData);
        }

        return {
            status,
            close,
            saveChanges
        }
    }
})
</script>