<template>
  <div class="tab-pane fade show active" id="account" role="tabpanel">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Public Info</h5>
      </div>
      <div class="card-body">
        <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
        <div class="row">
          <div class="col-md-8">
            <BaseInput v-model="user.fname" label="First Name" type="text"
              :class="{ 'is-invalid': errors && errors.fname }" id="fname" :errors="errors" is-required />
            <BaseInput v-model="user.mname" label="Middle Name" type="text"
              :class="{ 'is-invalid': errors && errors.mname }" id="mname" :errors="errors" />
            <BaseInput v-model="user.lname" label="Last Name" type="text"
              :class="{ 'is-invalid': errors && errors.lname }" id="lname" :errors="errors" is-required />
            <BaseInput v-model="user.email" label="Email Address" type="email"
              :class="{ 'is-invalid': errors && errors.email }" id="email" :errors="errors" is-required />
          </div>
          <div class="col-md-4">
            <div class="text-center">
              <img :alt="page.authuser.name" :src="photo_url.url ? photo_url.url : page.authuser.display_photo"
                class="rounded-circle img-responsive mt-2" width="128" height="128" />
              <div class="mt-2">
                <a href="javascript:;" class="btn btn-primary btn-upload" @click="$refs.fileInput.click()"><i
                    class="fas fa-upload"></i> Upload</a>
              </div>
              <small>For best results, use an image at least 128px by 128px in .jpg format</small>
              <label class="error jquery-validation-error small form-text invalid-feedback is-invalid"
                v-if="errors && errors.photo">{{ errors.photo[0] }}</label>
            </div>
          </div>
        </div>
        <input style="display: none" ref="fileInput" type="file" @change="onFileChange" enctype="multipart/form-data">
        <input type="hidden" v-model="user.id">
        <BaseButton :is-saved="status === 200" @btnClicked="saveUser" />
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Profile Visibility</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-md-12">
            <BaseCheckbox :disabled="page.isUpdateProfileVisibilityProcessing" design="inline" v-model="user.is_profile_visible"
              placeholder="Show my full profile in public" id="is_profile_visible" @update:modelValue="setVisibility" />
              <i v-if="page.isUpdateProfileVisibilityProcessing" class="fas fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Private Info</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-md-3">
            <label class="form-label">Gender</label>
            <div>
              <BaseRadio placeholder="Male" v-model="user.gender" />
              <BaseRadio placeholder="Female" v-model="user.gender" />
            </div>
          </div>
          <div class="col-md-3">
            <BaseInput v-model="user.contact_number" label="Contact Number" type="text" id="contact_number" />
          </div>
          <div class="col-md-3">
            <label for="birthdate" class="form-label">Birth Date</label>
            <Datepicker id="birthdate" v-model="user.birthdate" format="MM/dd/yyyy" :enableTimePicker="false"
              :range="false" placeholder="Select Birthdate (MM/DD/YYYY)" :modelValue="user.birthdate" textInput />
          </div>
        </div>
        <div class="mb-3">
          <BaseInput v-model="user.address" label="Address" type="text" id="address" placeholder="House #, Street #" />
        </div>
        <div class="row">
          <div class="col-md-6">
            <BaseInput v-model="user.city" label="City" type="text" id="city" />
          </div>
          <div class="col-md-4">
            <BaseInput v-model="user.state" label="State" type="text" id="state" />
          </div>
          <div class="col-md-2">
            <BaseInput v-model="user.zip_code" label="Zip" type="text" id="zip_code" />
          </div>
        </div>
        <BaseButton :is-saved="status === 200" @btnClicked="saveUserInfo" />
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Enable Two Factor Authentication</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-10">
            <div v-if="page.isTwoFactorEnabled == false">
              <h5>You have not enabled 2 factor authentication.</h5>
              <BaseButton :is-saved="!modalActive" :btn-value="`Enable 2FA`" @btnClicked="enable2fa" />
            </div>
            <div v-else>
              <h5>Your 2 factor authentication is enabled.</h5>
              <div class="row mb-3">
                <div class="col-9">
                  <p>You have now enabled two factor authentication, please scan the following QR Code into your phones
                    authenticator application.</p>
                  <p>Please store these recovery codes in a secure location.</p>
                  <div v-for="code in codes" :key="code">
                    {{ code }}<br>
                  </div>
                </div>
                <div class="col-3">
                  <div v-html="page.qrcode"></div>
                </div>
              </div>
              <BaseButton :is-saved="!isConfirm" :btn-value="`Disable 2FA`" @btnClicked="disable2fa" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalPassword :modalActive="modalActive" :errors="passwordErrors" :modalTitle="page.modalHeader"
      @close="toggleModal" @saveChanges="twoFactorSavePassword" :disableButton="page.isProcessing" />
    <Confirm :is-confirm="isConfirm" :confirm-text="`Are you sure you want to disable Two Factor Authentication?.`"
      @cancel="toggleConfirmation" @isDelete="confirmPasswordDisableAuth" />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import roleRepo from '@/repositories/settings/roles';
import userRepo from '@/repositories/settings/users';
import authRepo from '@/repositories/auth';
import ModalPassword from '@/views/employer/settings/config/ModalPassword.vue';

export default defineComponent({
  setup() {
    const { user, password, getUser, updateUser, errors, codes, status } = userRepo();
    const { twoFAEnable, twoFADisable, confirmPassword, passwordErrors, twoFAChallenge } = authRepo();
    const { roles, getRoles } = roleRepo();

    const modalActive = ref(false);

    const photo_url = reactive({ url: '' });
    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      qrcode: localStorage.getItem('auth-qrcode'),
      isLoading: true,
      modalHeader: 'Confirm Password',
      isTwoFactorEnabled: false,
      photoValue: '',
      isProcessing: false,
      isActionDisabled: false,
      isUpdateProfileVisibilityProcessing: false
    });

    const validate = reactive({
      password: '',
      password_confirmation: '',
      id: page.authuser.id
    })

    const isConfirm = ref(false);
    const toggleConfirmation = () => {
      isConfirm.value = !isConfirm.value;
      if(isConfirm.value === false) {
          status.value = 200;
      }
    }

    onMounted(async () => {
      await getUser(page);
      getRoles();
      page.isTwoFactorEnabled = (page.authuser.two_factor_confirmed_at != null);
    })

    const saveUser = async () => {
      status.value = '';
      let formData = new FormData();
      formData.append('fname', user.value.fname ?? '');
      formData.append('mname', user.value.mname ?? '');
      formData.append('lname', user.value.lname ?? '');
      formData.append('email', user.value.email ?? '');
      formData.append('photo', page.photoValue ?? '');
      formData.append('id', user.value.id);

      await updateUser(formData, page);
      page.authuser = JSON.parse(localStorage.getItem('authuser'))
      document.getElementById('avatar').src = page.authuser.display_photo;
    }

    const saveUserInfo = async () => {
      status.value = '';
      let userData = new FormData();
      userData.append('gender', user.value.gender ?? '');
      userData.append('contact_number', user.value.contact_number ?? '');
      userData.append('address', user.value.address ?? '');
      userData.append('city', user.value.city ?? '');
      userData.append('state', user.value.state ?? '');
      userData.append('zip_code', user.value.zip_code ?? '');
      userData.append('birthdate', user.value.birthdate ?? '');
      userData.append('role_id', user.value.role_id);
      userData.append('id', user.value.id);
      await updateUser(userData, page)
    }

    const onFileChange = (e) => {
      const file = e.target.files[0];
      photo_url.url = URL.createObjectURL(file);
      page.photoValue = file
    }

    const enable2fa = () => {
      status.value = 200;
      twoFAEnable(page);
      if (!page.authuser.two_factor_confirmed_at) {
        modalActive.value = true;
      }
    }

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
      if(modalActive.value === false) {
          passwordErrors.value = '';
      }
    }

    const twoFactorSavePassword = async (data) => {
        page.isProcessing = true;
        if(!page.isActionDisabled) {
            await confirmPassword(page, data);
            getUser(page);
            if(passwordErrors.value == '') {
                toggleModal();
            }
        } else {
            await confirmPassword(page, data, true);
            getUser(page);
            if(passwordErrors.value == '') {
                await disableAuth();
                modalActive.value = false;
                page.isActionDisabled = false;
            }
        }
        page.isProcessing = false;
    }

    const disable2fa = () => {
      toggleConfirmation();
    }

    const disableAuth = async () => {
      status.value = 200;
      await twoFADisable(page);
      getUser(page);
    }

    const confirmPasswordDisableAuth = () => {
        toggleConfirmation();
        toggleModal();
        page.isActionDisabled = true;
    }

    const setVisibility = async () => {
      page.isUpdateProfileVisibilityProcessing = true;
      let formData = new FormData();
      formData.append('is_profile_visible', user.value.is_profile_visible ?? false);
      formData.append('id', user.value.id);
      await updateUser(formData, page);
      page.isUpdateProfileVisibilityProcessing = false;
    }

    return {
      user,
      getUser,
      onFileChange,
      page,
      photo_url,
      validate,
      errors,
      status,
      saveUser,
      saveUserInfo,
      password,
      enable2fa,
      twoFAEnable,
      modalActive,
      toggleModal,
      twoFactorSavePassword,
      passwordErrors,
      disable2fa,
      twoFADisable,
      twoFAChallenge,
      codes,
      isConfirm,
      toggleConfirmation,
      disableAuth,
      roles,
      getRoles,
      confirmPasswordDisableAuth,
      setVisibility
    }
  },
  components: {
    ModalPassword
  }
})
</script>