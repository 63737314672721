<template>
    <div>
        <Modal :modalActive="modalActive" :class="`modal-dialog modal-sm modal-dialog-centered`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-6">
                        <label for="due_date" class="form-label">Due Date</label>
                        <Datepicker
                            id="due_date"
                            v-model="form.due_date"
                            format="MM/dd/yyyy"
                            placeholder="Select Due Date (MM/DD/YYYY)"
                            :enableTimePicker="false"
                            :range="false"
                            :modelValue="form.due_date"
                            :minDate="new Date()"
                            textInput
                        />
                    </div>
                    <div class="col-6">
                        <BaseSelect
                            v-model="form.status"
                            label="Status"
                            id="status"
                            :options="state.selectOptions"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, reactive, watch, ref } from 'vue';
import trainingRepo from '@/repositories/candidate/trainings';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: ''
        },
        billing_id: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            selectOptions: [
                { id: 'Active', name: 'Active' },
                { id: 'Inactive', name: 'Inactive' }
            ]
        });
        const form = reactive({
            due_date: '',
            status: ''
        });
        const { billing, getBilling, updateOneTimePayment, status, errors } = trainingRepo();

        const saveChanges = async () => {
            let formData = new FormData();
            formData.append('due_date', form.due_date ? new Date(form.due_date).toISOString().split("T")[0] : '');
            formData.append('status', form.status ?? '');
            formData.append('id', props.billing_id);
            formData.append('_method', 'PUT');
            await updateOneTimePayment(formData, props.billing_id);
            if(status.value == 200) {
                emit('refresh-page');
            }
        }

        const close = () => {
            emit('close')
        }

        watch(() => props.billing_id, async () => {
            await getBilling(props.billing_id);
            form.status = billing.value.status;
        });

        return {
            state,
            form,
            saveChanges,
            close,
            updateOneTimePayment,
            status,
            errors,
            billing,
            getBilling
        }
    }
})
</script>