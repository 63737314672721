import axios from "axios";
import { ref, inject } from "vue";

export default function feedbackRepo() {
    const toast = inject('toast');
    const errors = ref([]);
    const status = ref('');
    const feedbacks = ref([]);
    const ticket_id = ref('');
    const sendFeedback = async (data) => {
        errors.value = '';
        try {
            /**
             * Endpoint goes to routes/api feedback apiResource 
             * Controller: Employer/FeedbackController/store
             */
            let response = await axios.post(`employer/feedback`, data);
            alertMessage(response.data.message);
            status.value = response.data.status
            ticket_id.value = response.data.ticket_id
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }
    const getFeedbacks = async (page) => {
        try {
            let response = await axios.get(`admin/feedback`);
            feedbacks.value = response.data;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }
    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }
    return {
        errors,
        status,
        sendFeedback,
        feedbacks,
        getFeedbacks,
        ticket_id
    }
}