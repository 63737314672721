<template>
    <div class="modal-job-boost-container">
        <Modal :className="modalClass" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row text-secondary" id="upgrade-option-container" v-if="!page.isJobPromotionBoostSelected">
                    <div v-if="page.authuser.role_id === 2" class="col-12 mt-5">
                        <div class="text-center feature-logo"><img :src="`${page.apiEndpoint}/simphini-logo.svg`" width="100"></div>
                        <h2 class="text-center">{{ modalHeaderContent }}</h2>
                        <div class="subscription-wrapper m-3 mt-4 mb-5">
                            <div class="row">
                                <div class="col-12">
                                    <div class="flip-container" :class="page.switchMonthly ? 'hover' : 'normal-flip'">
                                        <div class="flipper">
                                            <div class="front">
                                                <div class="subcription-item border p-3 rounded premium">
                                                    <p class="mb-1 text-right discount">Save 25%</p>
                                                    <h5>PROMOTE JOB</h5>
                                                    <p class="mb-2 sub-title">Best Value</p>
                                                    <h3>$47 discounted price</h3>
                                                    <p class="mb-3 pre-subtitle" style="text-decoration: line-through;">$58.75 regular price</p>
                                                    <div class="price-tag" v-if="userAccount == 'Premium'">
                                                        Current Plan
                                                    </div>
                                                    <div class="price-tag-button">
                                                        <button class="btn" @click="selectJobPromotionBoost()">Purchase Boost</button>
                                                    </div>
                                                    <p class="mb-3 fw-bold inclusion-title">$47 for additional 5 promoted jobs and 5 job posts valid for 30 days.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-else>
                        <p class="text-center text-lg mt-4 mb-3 p-4 text-dark bg-light rounded">Please contact the Account Administrator to increase promoted job count.</p>
                    </div>
                </div>
                <div class="row text-secondary" v-else>
                    <component :is="jobPromotionBoostComponent" @backEvent="back" @subscribeSuccess="changeComponent" @triggerAction="close"></component>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, reactive, watchEffect, ref } from 'vue';
import SelectJobPromotionBoostMethod from '@/components/billing/SelectJobPromotionBoostMethod.vue';
import JobPromotionBoostSuccess from '@/components/billing/JobPromotionBoostSuccess.vue';

export default defineComponent({
    components: {
        SelectJobPromotionBoostMethod,
        JobPromotionBoostSuccess
    },
    props: [
        'modalActive',
        'modalTitle',
        'userAccount',
        'modalHeaderContent'
    ],
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            switchMonthly: false,
            apiEndpoint: process.env.VUE_APP_API_URL,
            switchField: true, // model for the switch
            isJobPromotionBoostSelected: false,
            modalHeaderContent: props.modalHeaderContent
        });
        
        const jobPromotionBoostComponent = ref('SelectJobPromotionBoostMethod');
        const modalClass = ref('modal-dialog plan-subscription-modal boost-modal modal-sm');

        const close = (isSubscriptionSuccess = false) => {
            emit('closeBoost')
            setTimeout(()=> { page.isJobPromotionBoostSelected = false; }, 800);
        }
        
        const saveChanges = () => {
            emit('saveChanges');
        }

        const back = () => {
            page.isPlanSelected = false;
            page.isJobPromotionBoostSelected = false;
            page.planDetails.plan_type = '';
            page.planDetails.subscription_type = '';
        }

        const changeComponent = () => {
            jobPromotionBoostComponent.value = 'JobPromotionBoostSuccess';
        }

        watchEffect(() => {
            if(page.switchField) {
                page.switchMonthly = false;
            } else {
                page.switchMonthly = true;
            }

            if(page.isJobPromotionBoostSelected) {
                modalClass.value = 'modal-dialog plan-subscription-modal boost-modal';
            } else {
                modalClass.value = 'modal-dialog plan-subscription-modal boost-modal modal-sm';
            }
        });
        
        const selectJobPromotionBoost = () => {
            page.isJobPromotionBoostSelected = true;
        }

        return {
            page,
            close,
            saveChanges,
            jobPromotionBoostComponent,
            selectJobPromotionBoost,
            back,
            changeComponent,
            modalClass
        }
    }
})
</script>
<style>
.subscription-wrapper {
    margin-bottom: 35px !important;
}
.subcription-item {
    min-height: 325px;
    background:#efefef;
}
.discount {
    margin-top: -5px;
}
.subcription-item h5 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: -25px;
}
.subcription-item h3 {
    font-size: 30px;
    font-weight: bold;
}
.price-tag {
    border-radius: 5px;
    background: #a5a5a5;
    padding: 11px 13px;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}
.price-tag-button {
    margin-bottom: 15px;
}
.back .price-tag-button {
    margin-top: 15px;
}
.price-tag-button button.btn {
    border-radius: 5px;
    background: #b6b6b6;
    padding: 10px 13px;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    width: 100%;
}
.price-tag-button button.btn a {
    color: #FFF;
    text-decoration: none;
}
.price-tag.active {
    background: #dbf7db;
}
.text-right {
    text-align:right;
}
.subscription-switcher {
    text-align: center;
}
.subscription-switcher-wrapper {
    display: inline-block;
    background: #efefef;
    padding: 10px 20px;
    border-radius: 20px;
}
.switch-field {
    display: inline-block;
    text-align: center;
    vertical-align: top;
}
.subscription-switcher span {
    display: inline-block;
    width: 100px;
}
.monthly {
    text-align: left;
}
.yearly {
    text-align: right;
}
.benefits span {
    color: #005dff;
    display: inline-block;
    width: 11px;
}
.back .free .price-tag {
    background: #65d092;
}
.pro .discount {
    color: #3f80ea;
}
.pro .price-tag-button button.btn {
    background: #3f80ea;
}
.premium .discount {
    color: #002057;
}
.premium .price-tag-button button.btn {
    background: #002057;
}
/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
  -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
	-webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
}
/* flip the pane when hovered */
.flip-container.hover .flipper {
	transform: rotateY(180deg);
}

.flip-container, .front, .back {
	width: 100%;
	height: 325px;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  	background: #E5E9F1;
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.thumbnail{
background: none;
  border: none;
}
.mb{margin-bottom: 20px;}
.feature-logo {
    margin-top: -35px;
}
</style>
<style>
.modal-job-boost-container .modal{
    z-index: 1060 !important;
}
.modal-job-boost-container .modal-backdrop {
    z-index: 1056;
}
</style>