import axios from "axios";
import { ref } from "vue";

export default function roleRepo() {
    
    const roles = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const role = {
        employer_hr:  {
            id: 3,
            label: 'Employer-HR'
        },
        employee: {
            id: 4,
            label: 'Employee'
        }
    };

    const getRoles = async () => {
        let response = await axios.get(`admin/role`);
        roles.value = response.data.data;
    }
    
    return {
        roles,
        getRoles,
        errors,
        message,
        status,
        role
    }
}