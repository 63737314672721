<template>
    <div v-if="works.length">
        <div v-for="(work, index) in works" :key="work">
            <div class="row">
                <div class="col-6">
                    <BaseInput 
                        v-model="work.jobTitle"
                        label="Job Title"
                        placeholder="Enter Job Title"
                        type="text"
                        :id="`job_title_${index}`"
                        :class="{ 'is-invalid' : errors.job_title }"
                        :errors="errors"
                        is-required
                    />
                    <BaseInput 
                        v-model="work.organization"
                        label="Company"
                        placeholder="Company Name"
                        type="text"
                        :id="`company_${index}`"
                        :class="{ 'is-invalid' : errors.company }"
                        :errors="errors"
                        is-required
                    />
                </div>
                <div class="col-6">
                    <BaseInput 
                        v-model="page.location[index]"
                        label="Company Address"
                        placeholder="Where is the company located?"
                        type="text"
                        :id="`company_address_${index}`"
                        :class="{ 'is-invalid' : errors.company_address }"
                        :errors="errors"
                        is-required
                    />
                    <label for="worked_from" class="form-label">Time Period</label>
                    <div class="row">
                        <div class="col-6">
                            <Datepicker
                                :id="`worked_from_${index}`"
                                v-model="page.date_from[index]" 
                                format="MM/dd/yyyy"
                                :enableTimePicker="false"
                                :range="false"
                                placeholder="Start Date (MM/DD/YYYY)"
                                :modelValue="work.worked_from"
                                textInput
                            />
                        </div>
                        <div class="col-6" v-if="!currently_work[index]">
                            <Datepicker
                                :id="`worked_to_${index}`"
                                v-model="page.date_to[index]" 
                                format="MM/dd/yyyy"
                                :enableTimePicker="false"
                                :range="false"
                                placeholder="End Date (MM/DD/YYYY)"
                                :modelValue="work.worked_to"
                                textInput
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div>
                        <editor
                            :api-key="tinykey"
                            :init="init"
                            v-model="work.jobDescription"
                            placeholder="Enter description"
                        />
                    </div>
                </div>
            </div>
            <hr v-show="works.length != index+1">
        </div>
        <div class="mt-3">
            <BaseButton :is-saved="status === 200" @btnClicked="saveWork" />
        </div>
    </div>
    <div v-else>
        <div class="text-center">
            <img src="/assets/images/empty.png" class="img-fluid img-30">
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect, reactive } from 'vue';
import moment from 'moment';
import config from '@/config.js';
import workRepo from '@/repositories/candidate/works';

export default defineComponent({
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const tinykey = config.tinykey;
        const init = config.editor_init;
        const works = props.user?.workExperience ?? [];
        const currently_work = ref([]);
        const { addMultipleWork, errors, status } = workRepo();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            date_from: [],
            date_to: [],
            location: []
        });

        watchEffect(() => {
            if(works.length) {
                works.forEach((work, index) => {
                    page.date_from[index] = work.dates ? moment(String(work.dates.startDate)).format('MM/DD/YYYY') : '';
                    page.date_to[index] = work.dates ? moment(String(work.dates.endDate)).format('MM/DD/YYYY') : '';
                    page.location[index] = work.location?.formatted;
                    currently_work[index] = works.dates?.isCurrent;
                });
            }
        });

        const saveWork = () => {
            status.value = '';
            let form = {
                from: page.date_from,
                to: page.date_to,
                location: page.location,
                user_id: page.authuser.id
            }

            addMultipleWork(works, form);
        }

        return {
            page,
            currently_work,
            errors,
            status,
            works,
            tinykey,
            init,
            saveWork,
            addMultipleWork
        }
    }
})
</script>