<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Manage Users</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="float-start">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="filter-by">Show</span>
                                    </div>
                                    <select id="filter" class="form-select user-type" aria-describedby="filter-by" v-model="page.viewOption" @change="ChangeView">
                                        <option value="/admin/settings/users/">All Users</option>
                                        <option value="/admin/settings/users/paid/">Paid Users</option>
                                        <option value="/admin/settings/users/archived/">Archived Users</option>
                                    </select>
                                </div>
                            </div>
                            <div class="float-start" style="margin-left:10px;">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="filter-show">Filter Plan</span>
                                    </div>
                                    <select id="filterby" class="form-select user-plan" aria-describedby="filter-show" v-model="page.filterBy" @change="changeFilter">
                                        <option value="1">All Plans</option>
                                        <option value="2">Pro</option>
                                        <option value="3">Premium</option>
                                    </select>
                                </div>
                            </div>
                            <div class="float-start" style="margin-left:10px;">
                                <div class="form-group">
                                    <input
                                        v-model="page.querySearch"
                                        class="form-control"
                                        id="query_search"
                                        placeholder="Search Users"
                                        @input="searchUser"
                                        :disabled="isSearchDisabled"
                                    />
                                </div>
                            </div>
                            <div class="card-actions float-end mt-2 text-bold">
                                {{ page.rightLabel }}: <span style="color: #6e3cff;">{{ page.paidUsers.length ?? 0 }}</span>
                            </div>
                            <div class="card-actions float-end" v-if="page.authuser.company_id">
								<button class="btn btn-primary text-white" @click="importCSV">Invite Users in Bulk</button> &nbsp;
                                <button class="btn btn-primary text-white" @click="createUser">Invite New User</button>
                            </div>
                        </div>
                        <div class="card-body">
                            <BaseTable
                                :data="page.paidUsers"
                                :options="headers"
                            >
                                <template v-slot:counter="{ row }">
                                    <div class="text-center">
                                        {{ row.id }}
                                    </div>
                                </template>
                                <template v-slot:plan="{ row }">
                                    <div v-html="getPlanLabel(row.subscriptions[0].stripe_price)"></div>
                                </template>
                                <template v-slot:name="{ row }">
                                    <div style="width:200px;">
                                        {{ row.name }}
                                    </div>
                                </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="impersonate(row.id)">Impersonate</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to impersonate this user?.`"
            @cancel="toggleConfirmation"
            @isDelete="impersonateUser"
        />
    </main>
</template>

<script>
import { useRouter } from 'vue-router';
import PageLoader from '@/components/modules/PageLoader.vue';
import { defineComponent, reactive, onMounted, ref } from 'vue';
import BootstrapBaseTable from '@/components/modules/Datatable.vue';
import Confirm from '@/components/modules/Confirmation.vue';
import userRepo from '@/repositories/admin/users';
import globalRepo from '@/repositories/global';
import _debounce from 'lodash/debounce';

export default defineComponent({
    setup() {
        const router = useRouter();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            token: localStorage.getItem('token'),
            isLoading: true,
            idToImpersonate: '',
            userType: '',
            viewOption: '/admin/settings/users/paid/',
            paidUsers: [],
            filterBy: 1,
            querySearch: '',
            rightLabel: 'Total Paid Users'
        });

        const { user_log_type } = globalRepo();
        const { users, user, errors, userImpersonate, logUserActivity, getUsers } = userRepo();
        const isConfirm = ref(false);

        const headers = [
            {
                key: 'counter',
                label: 'ID Number',
                class: 'text-center'
            },
            {
                key: 'name',
                label: 'Fullname'
            },
            {
                key: 'email',
                label: 'Email Address'
            },
            {
                key: 'company',
                label: 'Company'
            },
            {
                key: 'plan',
                label: 'Plan'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const impersonate = (id, type) => {
            page.idToImpersonate = id;
            page.userType = type;
            toggleConfirmation();
        }

        const impersonateUser = async () => {
            await userImpersonate(page);
            let user = JSON.parse(localStorage.getItem('authuser'));
            let adminuser = JSON.parse(localStorage.getItem('adminauthuser'));
            let routeData = user.role_id == 2 ? `${process.env.VUE_APP_BASE_URL}/employer/dashboard` : `${process.env.VUE_APP_BASE_URL}/candidate/dashboard`;

            // log activity when impersonation starts
            let logdata = {
                admin_id: adminuser.id,
                user_id: user.id,
                type: user_log_type.start,
                currentPath: user.role_id == 2 ? `/employer/dashboard` : `/candidate/dashboard`
            }
            await logUserActivity(logdata);

            window.location.href = routeData;
        }

        onMounted( async () => {
            await getUsers(page);
            page.paidUsers = users.value.filter(item => (item.role=='Employer' && item.subscriptions.length > 0));
        });

        const ChangeView = () => {
            router.push(`${page.viewOption}`);
        }

        const getPlanLabel = (price) => {
            if(price == process.env.VUE_APP_SUBSCRIPTION_TYPE_PRO_PRICE_YEAR || price == process.env.VUE_APP_SUBSCRIPTION_TYPE_PRO_PRICE_MONTH) {
                return 'Pro';
            }
            if(price == process.env.VUE_APP_SUBSCRIPTION_TYPE_PREMIUM_PRICE_YEAR || price == process.env.VUE_APP_SUBSCRIPTION_TYPE_PREMIUM_PRICE_MONTH) {
                return 'Premium';
            }
            return null;
        }

        const searchUser = _debounce(function (event) {
            changeFilter();
            page.paidUsers = page.paidUsers.filter(item => (item.name.toLowerCase().includes(event.target.value.toLowerCase())));

            // Try to search by email
            if(!page.paidUsers.length) {
                page.paidUsers = users.value.filter(item => (item.role=='Employer' && item.subscriptions.length > 0));
                page.paidUsers = page.paidUsers.filter(item => (item.email.toLowerCase().includes(event.target.value.toLowerCase())));
            }

            if(event.target.value != '') {
                page.rightLabel = 'Search Count';
            } else {
                if(page.filterBy == 2 || page.filterBy == 3) {
                    page.rightLabel = 'Filter Count';
                } else {
                    page.rightLabel = 'Total Paid Users';
                }
            }
        }, 700);

        const changeFilter = () => {
            if(page.filterBy == 1) {
                page.rightLabel = 'Total Paid Users';
                page.paidUsers = users.value.filter(item => (item.role=='Employer' && item.subscriptions.length > 0));
            } else if(page.filterBy == 2) {
                page.rightLabel = 'Filter Count';
                page.paidUsers = users.value.filter(item => (item.role=='Employer' && item.subscriptions.length > 0 && getPlanLabel(item.subscriptions[0].stripe_price) == 'Pro'));
            } else if(page.filterBy == 3) {
                page.rightLabel = 'Filter Count';
                page.paidUsers = users.value.filter(item => (item.role=='Employer' && item.subscriptions.length > 0 && getPlanLabel(item.subscriptions[0].stripe_price) == 'Premium'));
            }
        }

        return {
            headers,
            users,
            page,
            user,
            errors,
            impersonate,
            userImpersonate,
            toggleConfirmation,
            isConfirm,
            impersonateUser,
            ChangeView,
            getPlanLabel,
            searchUser,
            changeFilter
        }
    },
    components: {
        PageLoader,
        BootstrapBaseTable,
        Confirm
    }
});
</script>

<style scoped>
.w-3 {
    width: 3% !important;
}
.w-10 {
    width: 10% !important;
}
</style>