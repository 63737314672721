<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-label">Assign to Employee(s)</label>
                            <multiselect
                                v-model="user_ids"
                                :options="users"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                placeholder="Select Employee"
                                label="name"
                                track-by="id"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save Chnages</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, onBeforeMount, watch } from 'vue';
import trainingRepo from '@/repositories/employer/trainings';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        }
    },
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true
        });
        const { users, getEmployees, getTrainingAssignedUsers, assigned_users } = trainingRepo();
        const actions = [
            { id: 1, name: 'Start Course' },
            { id: 2, name: 'Assign to Employee' },
            { id: 3, name: 'Complete Course' },
        ];
        const action = ref('');
        const user_ids = ref([]);

        const close = () => {
            action.value = '';
            assigned_users.value = [];
            emit('close')
        }

        const saveChanges = () => {
            let users = user_ids.value;
            let user_arr = [];
            users.forEach(user => {
                user_arr.push(user.id);
            });

            let data = {
                user_ids: user_arr,
                action: action.value
            }
            emit('saveChanges', data);
        }

        onMounted(() => {
            action.value = '';
            getEmployees(page);
        });

        watch(() => props.form.id, async () => {
            if(props.form.id) {
                user_ids.value = [];
                await getTrainingAssignedUsers(page, props.form.id);
                assigned_users.value.forEach(user => {
                    user_ids.value.push({
                        id: user.id,
                        name: `${user.fname} ${user.lname}`
                    });
                });
            }
        });

        return {
            page,
            close,
            action,
            actions,
            user_ids,
            saveChanges,
            users,
            getEmployees,
            getTrainingAssignedUsers,
            assigned_users
        }
    }
})
</script>