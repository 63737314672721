<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1>{{formType}} Learning Plan</h1>
            <div class="row card">
                <div>
                    <button v-if="formType == 'New'" class="btn btn-success float-end mt-2 me-1" @click="save">SAVE</button>
                    <button v-else class="btn btn-success float-end mt-2 me-1" @click="save">UPDATE</button>
                    <router-link :to="{ path: '/employer/trainings'}" class="btn btn-primary float-end mt-2 me-1">Back</router-link>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-lg-4 col-md-6">
                            <BaseInput 
                                v-model="learningPlanSet.title" 
                                :customClass="'input-group-lg'"
                                placeholder="My Learning Plan" 
                                label="Title"
                                id="title"
                                is-required
                            />
                        </div>
                    </div>
                    <div v-if="false" class="row mb-3">
                        <div class="col-lg-4 col-md-6">
                            <BaseSelect
                                v-model="learningPlanSet.type"
                                label="Type"
                                id="type"
                                :options="learning_plan_types"
                                optionId="value"
                                :class="'input-group-lg'"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card shadow section-block" v-for="(section, index) in learningPlanSet.contents" :key="index"> 
                                <div v-if="index > 0"> 
                                    <button class="btn btn-danger btn-sm float-end w-10" @click="remove(1,index)"><i class="fa-solid fa-trash"></i></button>
                                </div>
                                <BaseInput 
                                    v-model="section.section" 
                                    :customClass="'input-group-lg'"
                                    :placeholder="`Section Name ${index+1}`" 
                                    :label="`Section ${index+1}`"
                                    :id="`section-${index}`"
                                />

                                <label>Courses</label>
                                <ul class="list-group mb-2" v-if="section.courses.length > 0">
                                    <li class="list-group-item draggable" v-for="(course, index) in section.courses" :key="index">
                                        {{course.title}}
                                    </li>
                                </ul>
                                <ul class="list-group mb-2" v-else>
                                    <li class="list-group-item" style="color:#999999;">Add a course</li>
                                </ul>

                                <multiselect 
                                    v-model="section.courses" 
                                    :options="coursesOptions" 
                                    :multiple="true" 
                                    :taggable="true"
                                    :close-on-select="false" 
                                    :clear-on-select="false" 
                                    :preserve-search="false" 
                                    :hideSelected="true"
                                    placeholder="Select Courses" 
                                    label="title" 
                                    track-by="id"
                                    @select="selected"
                                    @remove="removeCourse"
                                />
                                <div class="ms-7" v-if="section.sub.length > 0">
                                    <div class="card shadow section-block" v-for="(section, subIndex) in section.sub" :key="subIndex">
                                        <div> 
                                            <button class="btn btn-danger btn-sm float-end w-10" @click="remove(2, index, subIndex)"><i class="fa-solid fa-trash"></i></button>
                                        </div>
                                        <BaseInput 
                                            v-model="section.section" 
                                            :customClass="'input-group-lg'"
                                            :placeholder="`Sub Section Name ${index+1}`" 
                                            :label="`Sub Section ${index+1}`"
                                            :id="`sub-section-${index}`"
                                        />

                                        <label>Courses</label>
                                        <ul class="list-group mb-2" v-if="section.courses.length > 0">
                                            <li class="list-group-item draggable" v-for="(course, index) in section.courses" :key="index">
                                                {{course.title}}
                                            </li>
                                        </ul>
                                        <ul class="list-group mb-2" v-else>
                                            <li class="list-group-item" style="color:#999999;">Add a course</li>
                                        </ul>
                                        <multiselect 
                                            v-model="section.courses" 
                                            :options="coursesOptions" 
                                            :multiple="true" 
                                            :taggable="true"
                                            :close-on-select="false" 
                                            :clear-on-select="false" 
                                            :preserve-search="false" 
                                            :hideSelected="true"
                                            placeholder="Select Courses" 
                                            label="title" 
                                            track-by="id"
                                            @select="selected"
                                            @remove="removeCourse"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button class="btn btn-primary ms-7 mb-2 mt-2 float-end"  @click="addSection(2, index)">
                                        <i class="fa-solid fa-plus"></i> Add Sub Section</button>
                                </div>
                            <!-- Card end -->
                            </div>
                            <button class="btn btn-primary mb-2" @click="addSection(1, null)">
                                <i class="fa-solid fa-plus"></i> Add Section</button>
                        </div>
                        <div class="col-lg-6" style="margin-top: 7px;">
                            <h3>Preview</h3>
                            <div class="card section-block" v-if="learningPlanSet.contents.length > 0"> 
                                <div v-for="(section, i) in learningPlanSet.contents" :key="i">
                                    <div>
                                        <h1 style="font-weight: 700;">{{section.section}}</h1>
                                    </div>
                                    <div class="ms-5" v-if="section.courses.length > 0"> 
                                        <div v-for="(course, i) in section.courses" :key="i"> 
                                            <h2>{{course.title}}</h2>
                                        </div>
                                    </div>

                                    <div class="ms-5" v-if="section.sub.length > 0"> 
                                        <div v-for="(subsection, i) in section.sub" :key="i"> 
                                            <div>
                                                <h2 style="font-weight: 700;">{{subsection.section}}</h2>
                                            </div>
                                            <div class="ms-5" v-if="subsection.courses.length > 0"> 
                                                <div v-for="(subcourse, i) in subsection.courses" :key="i"> 
                                                    <h2>{{subcourse.title}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { defineComponent, reactive, onMounted, ref, computed } from 'vue';
    import trainingRepo from '@/repositories/admin/trainings';
    import learningPlanRepo from '@/repositories/admin/learningplans';
    import globalRepo from '@/repositories/global';
    import { useRoute, useRouter } from 'vue-router'
    
    export default defineComponent({
    setup() {
        const route = useRoute();
        const router = useRouter();
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            type: 'New'
        });
        const learningPlanSet = ref({
            title: "",
            type: null,
            contents: [{
                    section: "",
                    order: 1,
                    courses: [],
                    sub: []
                }]
        });
        const { trainings, getTrainings } = trainingRepo();
        const { createLearningPlan, getLearningPlan, learning_plan } = learningPlanRepo()
        const { learning_plan_types } = globalRepo()
        const selectedCourses = ref([])
        const formType = ref('New')
        const learningPlanId = ref(null)

        onMounted(async () => {
            await getTrainings(page);

            if(route.params.learning_plan_id){
                await getLearningPlan(route.params.learning_plan_id)
                learningPlanId.value = parseInt(route.params.learning_plan_id)
                learningPlanSet.value.title = learning_plan.value.name
                learningPlanSet.value.type = learning_plan.value.type
                learningPlanSet.value.contents = learning_plan.value.contents
                selectedCourses.value = learning_plan.value.courses
                formType.value = 'Edit'
            }
        });

        const coursesOptions = computed(() => {
            return trainings.value.filter(data => selectedCourses.value.includes(data.id)==false)
        })

        const addSection = (type, index) => {

            if(type === 1){
                learningPlanSet.value.contents.push({
                    section: "",
                    order: learningPlanSet.value.contents.length+1,
                    courses: [],
                    sub: []
                })
            }else if(type === 2){
                learningPlanSet.value.contents[index].sub.push({
                    section: "",
                    order: learningPlanSet.value.contents[index].sub.length+1,
                    courses: [],
                    sub: []
                })
            }
        }

        const selected = (selectedOption) => {
            let found = selectedCourses.value.filter(id => id == selectedOption.id) 
            if(found.length === 0){
                selectedCourses.value.push(selectedOption.id)
            }
        }

        const removeCourse = (removedOption) => {
            selectedCourses.value.splice(selectedCourses.value.indexOf(removedOption.id), 1)
        }

        const remove = (type, index, subIndex) => {
            if(type === 1){
                if(learningPlanSet.value.contents[index].courses.length > 0){
                    learningPlanSet.value.contents[index].courses.map((data) => {
                        selectedCourses.value.splice(selectedCourses.value.indexOf(data.id), 1)
                    })
                    learningPlanSet.value.contents[index].sub.map((data) => {
                        if(data.courses.length > 0){
                            data.courses.map((val) => {
                                selectedCourses.value.splice(selectedCourses.value.indexOf(val.id), 1)
                            })
                        }
                    })
                }
                learningPlanSet.value.contents.splice(index, 1)
            }else if(type === 2){
                if(learningPlanSet.value.contents[index].sub[subIndex].courses.length > 0){
                    learningPlanSet.value.contents[index].sub[subIndex].courses.map((data) => {
                        selectedCourses.value.splice(selectedCourses.value.indexOf(data.id), 1)
                    })
                }
                learningPlanSet.value.contents[index].sub.splice(subIndex, 1)
            }
        }

        const save = async () => {
            let formData = new FormData();
            let contents = learningPlanSet.value.contents ?? []
            let courses = selectedCourses.value ?? []
            
            formData.append('title', learningPlanSet.value.title ?? '');
            formData.append('type', 2);
            formData.append('contents', JSON.stringify(contents));
            formData.append('courses', JSON.stringify(courses));
            formData.append('company_id', page.authuser.company_id);
            if(formType.value == 'Edit'){
                formData.append('learning_plan_id', learningPlanId.value)
            }
            await createLearningPlan(formData)

            if(formType.value != 'Edit'){
                learningPlanSet.value = {
                    title: "",
                    type: null,
                    contents: [{
                            section: "",
                            order: 1,
                            courses: [],
                            sub: []
                        }]
                }
            }
        }

        return {
            page,
            trainings,
            learningPlanSet,
            addSection,
            selected,
            selectedCourses,
            coursesOptions,
            remove,
            save,
            learning_plan_types,
            learning_plan,
            formType,
            learningPlanId,
            removeCourse
        }
    }
})
</script>

<style scoped>
.draggable {
    cursor: move;
}
.section-block {
    border: #ced4da solid 1px;
    padding: 15px; 
}
.fa-plus { font-size: 14px; }
</style>