import axios from "axios";
import { ref, inject } from "vue";

export default function candidateRepo() {

    const popmessage = inject('toast');
    const candidates = ref([]);
    const candidate = ref([]);
    const saved_candidates = ref([]);
    const message = ref([]);
    const totalData = ref('');
    const current_courses = ref('');
    const learning_plans_assigned = ref('');
    const has_filters = ref(false);
    const invited_candidates = ref([]);
    const status = ref('');
    const errors = ref([]);

    const getCandidates = async (page, searchValue = false, sortby = 'fname', sortdirection = 'ascending') => {
        let user_id = page.authuser.id ?? 0;
        let response;
        if(searchValue) {
            response = await axios.get(`employer/find-candidates?user_id=${user_id}&sort_by=${sortby}&sort_direction=${sortdirection}&keyword=${searchValue}`);
        } else {
            response = await axios.get(`employer/find-candidates?user_id=${user_id}&sort_by=${sortby}&sort_direction=${sortdirection}`);
        }
        candidates.value = response.data.data;
        totalData.value = candidates.value.length ?? 0;
        page.isLoading = false;
    }

    const getPaginateCandidates = async (page, filter_params = null) => {
        let response;
        let formData = {
            user_id: page.authuser.id ?? 0,
            sort_by: page.sortby,
            sort_direction: page.sortdirection,
            keyword: page.searchValue,
            length: 15,
            start: page.offSet
        }
        if(filter_params !== null) {
            formData.filter_params = filter_params;
            has_filters.value = true;
        } else {
            has_filters.value = false;
        }

        if(page.searchValue) {
            response = await axios.post(`employer/find-candidates/paginate`, formData);
        } else {
            response = await axios.post(`employer/find-candidates/paginate`, formData);
        }
        candidates.value = response.data.data;
        totalData.value = response.data.recordsTotal;
        page.isLoading = false;
    }

    const getCandidate = async (page) => {
        let response = await axios.get(`employer/view-candidate?user_id=${page.candidate_id}`);
        candidate.value = response.data.candidate_info;
        current_courses.value = response.data.current_courses;
        learning_plans_assigned.value = response.data.learning_plans_assigned;
        page.isLoading = false;
    }

    const getMatchedCandidates = async (page, filter_params = null, searchValue = false, sortby = 'fname', sortdirection = 'ascending') => {
        let formData = {
            user_id: page.authuser.id ?? 0,
            sort_by: sortby,
            sort_direction: sortdirection,
            keyword: page.searchValue
        }
        if(filter_params !== null) {
            formData.filter_params = filter_params;
            has_filters.value = true;
        } else {
            has_filters.value = false;
        }
        let response;
        if(searchValue) {
            response = await axios.post(`employer/matched-candidates`, formData);
        } else {
            response = await axios.post(`employer/matched-candidates`, formData);
        }
        candidates.value = response.data;
        page.isLoading = false;
    }

    const getSavedCandidates = async (page) => {
        let user_id = page.authuser.id ?? 0;
        let response = await axios.get(`employer/saved-candidates?user_id=${user_id}`);
        saved_candidates.value = response.data;
        page.isLoading = false;
    }

    const getInvitedCandidates = async (page) => {
        let company_id = page.authuser.company_id ?? 0;
        let user_id = page.authuser.id ?? 0;
        let response = await axios.get(`employer/invited-candidates?company_id=${company_id}&user_id=${user_id}`);
        invited_candidates.value = response.data;
        page.isLoading = false;
    }

    const processSaveCandidate = async (page, saved_id) => {
        let user_id = page.authuser.id ?? 0;
        let response = await axios.get(`employer/save-candidate?user_id=${user_id}&saved_user_id=${saved_id}`);
        message.value = response.data;
        popmessage.success(message.value.message);
    }

    const processUnSaveCandidate = async (page, unsaved_id) => {
        let user_id = page.authuser.id ?? 0;
        let response = await axios.get(`employer/unsave-candidate?user_id=${user_id}&unsaved_user_id=${unsaved_id}`);
        message.value = response.data;
        popmessage.success(message.value.message);
    }

    const sendInvite = async (data) => {
        try {
            let response = await axios.post(`employer/send-job-invite`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            status.value = response.status;
            message.value = response.data;
            popmessage.success(message.value.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }

    return {
        getCandidates,
        getPaginateCandidates,
        getCandidate,
        getMatchedCandidates,
        getSavedCandidates,
        processSaveCandidate,
        processUnSaveCandidate,
        getInvitedCandidates,
        sendInvite,
        candidates,
        candidate,
        saved_candidates,
        invited_candidates,
        message,
        totalData,
        learning_plans_assigned,
        current_courses,
        has_filters,
        status,
        errors
    }
}
