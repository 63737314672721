<template>
  <main class="content">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <h1 class="h3 mb-3">Profile</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-xl-2">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Settings</h5>
            </div>

            <div class="list-group list-group-flush" role="tablist">
              <a class="list-group-item list-group-item-action" :class="{ 'active': currentComponent == 'Account' }"
                data-bs-toggle="list" href="javascript:;" @click="chooseTab('Account')" role="tab">
                Personal Information
              </a>
              <a class="list-group-item list-group-item-action list-education" :class="{ 'active': currentComponent == 'Educations' }"
                data-bs-toggle="list" href="javascript:;" @click="chooseTab('Educations')" role="tab">
                Education
              </a>
              <a class="list-group-item list-group-item-action list-work-exp"
                :class="{ 'active': currentComponent == 'WorkExperience' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('WorkExperience')" role="tab">
                Work Experience
              </a>
              <a class="list-group-item list-group-item-action list-certifications"
                :class="{ 'active': currentComponent == 'Certifications' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('Certifications')" role="tab">
                Certifications
              </a>
              <a class="list-group-item list-group-item-action list-skil-and-tech"
                :class="{ 'active': currentComponent == 'SkillsAndTechnologies' }" data-bs-toggle="list"
                href="javascript:;" @click="chooseTab('SkillsAndTechnologies')" role="tab">
                Skills &amp; Technologies
              </a>
              <a class="list-group-item list-group-item-action list-job-preferences"
                :class="{ 'active': currentComponent == 'JobPreferences' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('JobPreferences')" role="tab">
                Job Preferences
              </a>
              <a class="list-group-item list-group-item-action list-security-settings"
                :class="{ 'active': currentComponent == 'ResumeParser' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('ResumeParser')" role="tab">
                Upload Resume
              </a>
              <a class="list-group-item list-group-item-action list-security-settings"
                :class="{ 'active': currentComponent == 'SecuritySettings' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('SecuritySettings')" role="tab">
                Security Settings
              </a>
              <a class="list-group-item list-group-item-action"
                :class="{ 'active': currentComponent == 'Billings' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('Billings')" role="tab">
                Billings
              </a>
              <button v-show="!page.isImpersonateOn" class="list-group-item list-group-item-action text-danger" style="color: #fff"
                @click="deleteAccount">
                Delete Account
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-9 col-xl-10">
          <div class="tab-content">
            <component :is="currentComponent"></component>
          </div>
        </div>
        <div class="tab-spinner position-fixed" v-if="page.isFullScreenLoading"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <Confirm :is-confirm="isConfirm"
        :confirm-text="`Are you sure you want to deactivate your account? Once deactivated, you will logout and no longer be able to login or retrieve any of your information.`"
        @cancel="toggleConfirmation" @isDelete="destroyAccount" />
      <ModalResume :modalActive="modalActive" :modalTitle="page.modalHeader" @close="toggleModal" />
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import userRepo from '@/repositories/settings/users';
import authRepo from '@/repositories/auth';
import Account from '@/views/candidate/settings/profile/tabs/Account.vue';
import Educations from '@/views/candidate/settings/profile/tabs/Educations.vue';
import SkillsAndTechnologies from '@/views/candidate/settings/profile/tabs/SkillsAndTechnologies.vue';
import Certifications from '@/views/candidate/settings/profile/tabs/Certifications.vue';
import JobPreferences from '@/views/candidate/settings/profile/tabs/JobPreferences.vue';
import WorkExperience from '@/views/candidate/settings/profile/tabs/WorkExperience.vue';
import ResumeParser from '@/views/candidate/settings/profile/tabs/ResumeParser.vue';
import ModalResume from '@/views/candidate/settings/profile/ModalResume.vue';
import SecuritySettings from '@/views/candidate/settings/profile/tabs/SecuritySettings.vue';
import Billings from '@/views/candidate/settings/profile/tabs/Billings.vue';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { destroyUser } = userRepo();
    const { signOutUser } = authRepo();
    const currentComponent = ref('Account');
    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      isLoading: true,
      modalHeader: 'Upload Resume',
      isFullScreenLoading: false,
      isImpersonateOn: false
    });

    const isConfirm = ref(false);
    const modalActive = ref(false);
    const toggleConfirmation = () => {
      isConfirm.value = !isConfirm.value;
    }

    onMounted(() => {
      let admintoken = localStorage.getItem('admintoken');
      page.isImpersonateOn = (admintoken !== null);
      let url = /[^/]*$/.exec(router.currentRoute.value.fullPath)[0];
      if (url == 'profile') {
        currentComponent.value = 'Account';
        page.isLoading = false;
      }
      if (url == 'upload-resume') {
        currentComponent.value = 'ResumeParser';
        page.isLoading = false;
      }

      if(route.query.tab == 'billings') {
        currentComponent.value = 'Billings';
        page.isLoading = false;
      }
    })

    const deleteAccount = () => {
      toggleConfirmation();
    }

    const destroyAccount = async () => {
      toggleConfirmation();
      page.isFullScreenLoading = true;
      await destroyUser(page.authuser.id);
      signOutUser(page.authuser, 'candidate');
    }

    const chooseTab = (tab) => {
      currentComponent.value = tab;
    }

    const openResumeParser = () => {
      toggleModal();
    }

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    }

    return {
      page,
      deleteAccount,
      destroyAccount,
      destroyUser,
      currentComponent,
      chooseTab,
      isConfirm,
      toggleConfirmation,
      openResumeParser,
      toggleModal,
      modalActive
    }
  },
  components: {
    Account,
    Educations,
    SkillsAndTechnologies,
    Certifications,
    JobPreferences,
    WorkExperience,
    ModalResume,
    ResumeParser,
    SecuritySettings,
    Billings
  }
})
</script>
<style scoped>
  .tab-spinner {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 20%;
      background: #e3e3e3bf;
      text-align: center;
      font-size: 40px;
      color: #3f80ea;
      z-index: 100000;
  }
  </style>