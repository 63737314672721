<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-xl modal-dialog-scrollable`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row" v-if="!page.isParseOpen">
                    <div class="col-5">
                        <div class="form-group">
                            <label for="resume-file">Resume File</label>
                            <div class="d-flex">
                                <input type="file" class="form-control" id="resume-file" @change="onFileChange" enctype="multipart/form-data"> &nbsp;&nbsp;
                                <button class="btn btn-primary" @click="uploadResume">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <PageLoader v-if="page.isLoading" />
                <div class="row" v-else>
                    <div class="col-12" v-if="page.isParseOpen">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title mb-0">Personal Information</h5>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <BaseInput 
                                            v-model="user.fname"
                                            label="First Name"
                                            type="text"
                                            :class="{ 'is-invalid' : errors && errors.fname }"
                                            id="fname"
                                            :errors="errors"
                                            is-required
                                        />
                                        <BaseInput 
                                            v-model="user.mname"
                                            label="Middle Name"
                                            type="text"
                                            :class="{ 'is-invalid' : errors && errors.mname }"
                                            id="mname"
                                            :errors="errors"
                                            is-required
                                        />
                                        <BaseInput 
                                            v-model="user.lname"
                                            label="Last Name"
                                            type="text"
                                            :class="{ 'is-invalid' : errors && errors.lname }"
                                            id="lname"
                                            :errors="errors"
                                            is-required
                                        />
                                        <BaseInput 
                                            v-model="user.email"
                                            label="Email Address"
                                            type="email"
                                            :class="{ 'is-invalid' : errors && errors.email }"
                                            id="email"
                                            :errors="errors"
                                            is-required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import resumeRepo from '@/repositories/candidate/resume';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            resumeToUpload: '',
            isLoading: false,
            isParseOpen: false
        })
        const user = reactive({
            fname: null,
            mname: null,
            lname: null,
            email: null
        });

        const { uploadResumeFile, status, errors } = resumeRepo();

        const close = () => {
            emit('close')
        }
        
        const saveChanges = () => {
            emit('saveChanges');
        }

        const onFileChange = (e) => {
            let file = e.target.files[0];
            page.resumeToUpload = file
        }

        const uploadResume = () => {
            page.isLoading = true;

            let formData = new FormData();
            formData.append('resumefile', page.resumeToUpload ?? '');
            formData.append('user_id', page.authuser.id);
            uploadResumeFile(page, formData, user);
        }

        return {
            page,
            close,
            saveChanges,
            uploadResume,
            onFileChange,
            uploadResumeFile,
            status,
            errors,
            user
        }
    }
})
</script>