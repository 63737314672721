<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex">
                <h1 class="h3 mb-3">Candidates Report</h1>
            </div>
            <PageLoader v-if="state.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <LineChart
                                title="Candidate Reports"
                                series-name="Revenue"
                                :series="candidateSeries"
                                :categories="candidateCategories"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { onMounted, reactive } from 'vue';
import LineChart from '@/components/charts/LineChart';
import applicantRepo from '@/repositories/employer/applicant';

export default {
    components: {
        LineChart
    },
    setup() {
        const state = reactive({
            isLoading: true,
            authuser: JSON.parse(localStorage.getItem('authuser'))
        });
        const { candidateSeries, candidateCategories, getCandidateReports } = applicantRepo();

        onMounted( async () => {
            await getCandidateReports(state);
        });

        return {
            state,
            candidateSeries,
            candidateCategories,
            getCandidateReports
        }
    },
}
</script>