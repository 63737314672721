import TrainingIndex from '@/views/admin/trainings/Index';
import LearningPlans from '@/views/admin/trainings/LearningPlans';
import LearningPlanCreate from '@/views/admin/trainings/LearningPlanCreate';
import LearningPlansList from '@/views/admin/trainings/LearningPlansList';
import TrainingSource from '@/views/admin/trainings/TrainingSource'

const routes = [
    {
        path: '/admin/trainings',
        name: 'admin.trainings',
        component: TrainingIndex
    },
    {
        path: '/admin/learning-plans',
        name: 'admin.learning-plans',
        component: LearningPlans
    },
    {
        path: '/admin/learning-plan/create',
        name: 'admin.learning-plans.create',
        component: LearningPlanCreate
    },
    {
        path: '/admin/learning_plans',
        name: 'admin.learning_plans',
        component: LearningPlansList
    },
    {
        path: '/admin/learning-plan/update/:learning_plan_id',
        name: 'admin.learning-plans.update',
        component: LearningPlanCreate
    },
    {
        path: '/admin/trainings/source',
        name: 'admin.trainings.source',
        component: TrainingSource
    }
]

export default routes