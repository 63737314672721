<template>
    <div class="tab-pane fade show active" id="skills" role="tabpanel">
        <div class="card">
            <div class="card-header pb-0">
                <h5 class="card-title mb-0">Skills and Technologies</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <p class="text-muted" style="margin-bottom: 10px;">By adding years of experience for each of these skills, you will receive more accurate targeted job suggestions. Otherwise, you may not receive notices for jobs for which you are qualified.</p>
                    <div class="col-xl-8 col-lg-12 col-md-12">
                        <div class="row">
                            <div class="col-7">
                                <div :class='{ "form-group" : true }'>
                                    <multiselect
                                        v-model="currentSkills"
                                        tag-placeholder="Enter to add as a new skill"
                                        label="name" track-by="id"
                                        :options="skillsList"
                                        :taggable="true"
                                        :class='{ "is-skills-invalid" : errors.skills && errors.skills[0] }'
                                        @tag="addTag"
                                        @select="selectSkill"
                                    />
                                    <label class="error jquery-validation-error small form-text invalid-feedback" v-if="errors.skills">{{ errors.skills[0] }}</label>
                                </div>
                            </div>
                            <div class="col-3">
                                <BaseInput
                                    placeholder="Year of Experience"
                                    v-model="skill.yrs_experience"
                                    type="number"
                                    min="1"
                                    id="yrs_experience"
                                    :class="{ 'is-invalid' : errors.yrs_experience }"
                                    :errors="errors"
                                    is-required
                                />
                            </div>
                            <div class="col-2 d-flex align-items-start">
                                <div class="w-full">
                                    <BaseButton :is-saved="status === 200" @btnClicked="saveUserInfo" :btnValue="'Save'" :className="'btn btn-primary btn-skill'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-stiped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Skill</th>
                                    <th class="text-center">Years of Experience</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="skills.length">
                                <tr v-for="(skill, index) in skills" :key="skill.id">
                                    <td style="width: 5%">{{ index+1 }}</td>
                                    <td style="width: 55%">{{ skill.skills }}</td>
                                    <td class="text-center" style="width: 20%">{{ skill.yrs_experience }}</td>
                                    <td class="text-center" style="width: 20%">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width: 36px; height: 36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><button class="dropdown-item text-success" @click="editSkill(skill.id)">Edit</button></li>
                                                <li><button class="dropdown-item text-danger" @click="deleteSkill(skill.id)">Remove</button></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4" class="text-center">No data available</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this skill?`"
            @cancel="isConfirm = false"
            @isDelete="removeSkill"
        />
    </div>
</template>

<script>

import { defineComponent, onMounted, reactive, computed, ref } from 'vue';
import candidateRepo from '@/repositories/settings/candidate';
import skillsRepo from '@/repositories/admin/skills';
import global from '@/repositories/global';

export default defineComponent({
    setup() {
        const { updateCandidateSkills, getCandidateSkills, getCandidateSkill, destroyCandidateSkill, skills, skill, errors, status } = candidateRepo();
        const { getSkills, selectableSkills } = skillsRepo();
        const { skillsAndTech } = global();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            skills: [],
            idToDelete: ''
        });
        const mySkillsList = ref([])
        const currentSkills = ref([]);
        const isConfirm = ref(false);

        const addTag = (newTag) => {
            const tag = {
                name: newTag,
                id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            const arrayTag = [];
            arrayTag.push(tag);
            currentSkills.value = arrayTag;
            skill.value.skills = newTag;
            errors.value = [];
        }

        const skillsList = computed(function () {
            const data = []
            selectableSkills.value.map((skill, index) => {
                data.push({
                    id: skill.id,
                    name: skill.name
                })
            })
            return data
        });

        const selectSkill = (evt) => {
            skill.value.skills = evt.name;
            errors.value = [];
        }

        const saveUserInfo = async () => {
            const defaultSkill = skill.value.skills ?? '';

            let formData = new FormData();
            formData.append('skills', defaultSkill ?? currentSkills.value.name);
            formData.append('user_id', page.authuser.id);
            formData.append('yrs_experience', skill.value.yrs_experience ?? '');
            await updateCandidateSkills(formData);
            if(status.value == 200) {
                currentSkills.value = [];
                skill.value = [];
                await getCandidateSkills(page);
                status.value = '';
            }
        }

        const editSkill = async (id) => {
            await getCandidateSkill(id);

            const tag = {
                name: skill.value.skills,
                id: id
            }

            const arrayTag = [];
            arrayTag.push(tag);
            currentSkills.value = arrayTag;
        }

        const deleteSkill = (id) => {
            page.idToDelete = id;
            isConfirm.value = true;
        }

        const removeSkill = async () => {
            await destroyCandidateSkill(page.idToDelete);
            if(status.value == 200) {
                isConfirm.value = false;
                await getCandidateSkills(page);
            }
        }

        onMounted( async () => {
            await getCandidateSkills(page);
            await getSkills(page);
        });

        return {
            page,
            errors,
            status,
            saveUserInfo,
            getCandidateSkills,
            updateCandidateSkills,
            skills,
            skillsAndTech,
            selectableSkills,
            skillsList,
            mySkillsList,
            currentSkills,
            addTag,
            selectSkill,
            editSkill,
            getCandidateSkill,
            skill,
            deleteSkill,
            isConfirm,
            removeSkill,
            destroyCandidateSkill
        }
    }
})
</script>

<style>
.invalid-feedback {
    border: none;
}
.multiselect__tags { height: auto!important; }
.btn-skill {
    padding: 5px 24px !important;
}
.is-skills-invalid > .multiselect__tags {
    border: 1px solid #d9534f !important;
}
.multiselect {
    min-height: 30px;
}
</style>