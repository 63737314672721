<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1>Feedback and Support</h1>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <router-link class="nav-link" :class="{'active': inboxLink}"  :to="{ name: 'admin.feedbacks', params: {id:'inbox'}}">Inbox</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :class="{'active': archiveLink}" :to="{ name: 'admin.feedbacks', params: {id:'archive'}}">Archive</router-link>
                </li>
            </ul>
            <PageLoader v-if="page.isLoading" />
            <div v-else class="col-12">
                
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, computed, watchEffect, inject } from 'vue';
import { createStore } from 'vuex'
import { useRoute } from 'vue-router'
import ReplyModal from '@/views/admin/feedback/ReplyModal.vue';

export default defineComponent({
    setup() {
        
    },
    components: {
        ReplyModal
    }
})
</script>
<style>
.fullname { width: 150px; }
.date-header { width: 170px; }
.message-header { width: 570px; }
.status-header { width:200px; }
.actions-header { width: 310px; }
</style>