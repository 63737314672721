<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">Choose Job for the candidate invite</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-0">
                            <label for="job_id" class="form-label">Select Job to invite for the candidate <span class="text-danger">*</span></label>
                            <select id="job_id" class="form-select" v-model="form.job_id"
                            :class="{
                                'form-select': true,
                                'is-invalid': errors.job
                            }">
                                <option selected value="0">-- Please select job</option>
                                <option v-for="(job, index) in form.jobOptions" :key="index" :value="job.id">{{ job.job_order_number }} - {{ job.position_title }}</option>
                            </select>
                            <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors">{{ errors.job }}</label>
                        </div>
                        <input type="hidden" v-model="form.candidate_id">
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Invite Candidate</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/modules/Modal.vue';
import { defineComponent } from 'vue';
import BaseSelect from '@/components/modules/form/BaseSelect.vue';

export default defineComponent({
    props: ['modalActive','form','errors'],
    setup(props, { emit }) {

        const close = () => {
            emit('close')
        }
        
        const saveChanges = () => {
            emit('saveChanges');
        }

        return {
            close,
            saveChanges
        }
    },
    components: {
        Modal,
        BaseSelect
    }
})
</script>