<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-success alert-dismissible" role="alert">
                            <div class="alert-message">
                                If you sign up to this service, Simphini will create a simphini.net email for you which you can use to take advantage of discounts for group packages on premium services offered by the different content providers. Please select the content provider and your preferred billing method and we'll set up your account at a discounted rate:
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="!state.isPayment">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-label">Available Course Provider</label>
                            <multiselect
                                v-model="state.provider_id"
                                :options="providerOptions"
                                placeholder="Select Provider"
                                label="name"
                                track-by="id"
                                @select="selectProvider"
                            />
                        </div>
                    </div>
                </div>
                <div class="row mt-3" v-if="training_source.id && !state.isPayment">
                    <div class="col-6" v-if="training_source.monthly != null">
                        <div class="subcription-item annually border p-3 rounded pro">
                            <div class="mt-3">
                                <h5>Discounted Price per Month</h5>
                                <h3>${{ training_source.monthly }}/month</h3>
                                <div class="price-tag-button">
                                    <button class="btn" @click="registerAccount('monthly')"><span>Register Account</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6" v-if="training_source.yearly != null">
                        <div class="subcription-item annually border p-3 rounded premium">
                            <div class="mt-3">
                                <h5>Discounted Price per Year</h5>
                                <h3>${{ training_source.yearly }}/year</h3>
                                <div class="price-tag-button">
                                    <button class="btn" @click="registerAccount('yearly')"><span>Register Account</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { reactive, computed, onMounted } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import candidateTrainingRepo from '@/repositories/candidate/trainings';

export default {
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            provider_id: '',
            authuser: JSON.parse(localStorage.getItem('authuser')),
            elements: null,
            stripe_key: '',
            isPayment: false,
            amount: '',
            price: '0.00',
            product: ''
        });
        const { training_source, training_sources, getTrainingSources, getTrainingSource } = trainingRepo();
        const { storeOneTimePayment, urlRedirect, status } = candidateTrainingRepo();
        const providerOptions = computed(() => {
            const arr_options = [];
            training_sources.value.forEach(item => {
                if(item.monthly_price || item.yearly_price) {
                    arr_options.push({
                        id: item.id,
                        name: item.name
                    });
                }
            });
            return arr_options;
        });

        const selectProvider = async (value) => {
            state.provider_id = value.id;
            await getTrainingSource(value.id);
        }

        const registerAccount = async (type) => {
            state.isPayment = true;
            state.amount = (type == 'monthly') ? training_source.value.monthly_price : training_source.value.yearly_price;
            state.price = (type == 'monthly') ? training_source.value.monthly : training_source.value.yearly;
            state.product = `${training_source.value.name} (${type})`;

            let formData = new FormData();
            formData.append('amount', state.amount);
            formData.append('price', state.price);
            formData.append('product', state.product);
            formData.append('bill_type', type);
            formData.append('provider_id', training_source.value.id);
            formData.append('user_id', state.authuser.id);

            await storeOneTimePayment(formData);
            if(status.value == 200) {
                emit('close');
                window.open(urlRedirect.value);
            }
        }

        const close = () => {
            emit('close');
        }

        onMounted( async () => {
            getTrainingSources();
        });

        return {
            state,
            providerOptions,
            registerAccount,
            close,
            selectProvider,
            training_source,
            training_sources,
            getTrainingSources,
            getTrainingSource,
            storeOneTimePayment,
            urlRedirect,
            status
        }
    },
}
</script>

<style scoped>
.subcription-item {
    min-height: 0px;
}
</style>