<template>
    <div>
        <div class="row">
            <div class="col-2">
                <div class="card shadow mt-3">
                    <div class="card-body">
                        <h5 class="filter-text-header"><i class="fa fa-sliders pe-2"></i>FILTERS</h5>
                        <hr style="opacity: 1;" />
                        <div v-if="!page.viewSavedFilters">
                            <input class="form-control" type="search" placeholder="Search Course..." v-model="selected.keyword">
                            <button class="btn btn-primary btn-sm w-100 mt-2" :disabled="!notEmptyFilter" @click="toggleSaveFilterModal"> Save Filter </button>
                            <button class="btn btn-danger btn-sm w-100 mt-1" :disabled="!notEmptyFilter" @click="clearFilter"> Clear Filter </button>
                            <button class="btn btn-light btn-sm w-100 mt-1" :disabled="!saved_filters.length" @click="viewSavedFiltersAction('saved-filters')"> Saved Filters </button>
                        </div>

                        <div v-if="page.viewSavedFilters">
                            <h5 class="lead mt-3"><strong>Saved Filters</strong></h5>
                            <select class="form-select" v-model="page.selectedSavedFilter" @change="updateFilter">
                                <option value="0" disabled>Please select</option>
                                <option v-for="i in saved_filters" v-bind:key="i" :value="i.id">{{ i.filter_name }}</option>
                            </select>
                            <button class="btn btn-danger btn-sm w-100 mt-2" v-if="page.selectedSavedFilter !== 0" @click="processDeleteSavedFilter"> Delete Saved Filter zz</button>
                            <button class="btn btn-primary btn-sm w-100 mt-2" @click="viewSavedFiltersAction('custom-filters')"> Custom Filter </button>
                        </div>

                        <div v-if="!page.viewSavedFilters">
                            <h5 class="lead mt-3"><strong>Skill Type</strong></h5>
                            <div v-for="(type, i) in assigned_course_filters" :key="i" class="d-flex">
                                <input type="checkbox" :value="type.name" :id="'skill_type'+i" v-model="selected.skill_type">
                                <label :for="`skill_type${i}`" class="ml-7px text-sm">{{ type.name }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Course Type</strong></h5>
                            <div class="d-flex">
                                <input type="checkbox" value="Basic" id="basic" v-model="selected.course_type">
                                <label for="basic" class="ml-7px text-sm">Basic</label>
                            </div>
                            <div class="d-flex">
                                <input type="checkbox" value="Premium" id="premium" v-model="selected.course_type">
                                <label for="premium" class="ml-7px text-sm">Premium</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Difficulty</strong></h5>
                            <div v-for="(diff, i) in course_difficulty" :key="i" class="d-flex">
                                <input type="checkbox" :value="diff.name" :id="'difficulty'+i" v-model="selected.difficulty">
                                <label :for="`difficulty${i}`" class="ml-7px text-sm">{{ diff.name }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Source</strong></h5>
                            <div v-for="(source, i) in searchFilters.sources" :key="i" class="d-flex">
                                <input type="checkbox" :value="source.id" :id="'source'+i" v-model="selected.source">
                                <label :for="`source${i}`" class="ml-7px text-sm">{{ source.name }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Author</strong></h5>
                            <div v-for="(author, i) in searchFilters.author" :key="i" class="d-flex">
                                <input type="checkbox" :value="author" :id="'author'+i" v-model="selected.author">
                                <label :for="`author${i}`" class="ml-7px text-sm">{{ author }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Tags</strong></h5>
                            <div v-for="(tag, i) in searchFilters.tags" :key="i" class="d-flex">
                                <input type="checkbox" :value="tag" :id="'tag'+i" v-model="selected.tags">
                                <label :for="`tag${i}`" class="ml-7px text-sm">{{ tag }}</label>
                            </div>

                            <div v-if="false">
                                <h5 v-if="false" class="lead mt-3"><strong>Learning Plans</strong></h5>
                                <div v-for="(lp, i) in templates" :key="i">
                                    <input type="checkbox"> {{lp.name}}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-10">
                <div class="mt-3">
                    <div class="">
                        <span v-if="courseFiltered.length > 0" class="me-3">Results found {{courseFiltered.length}}</span>
                        <span v-for="(filter, i) in selected.skill_type" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                        <span v-for="(filter, i) in selected.difficulty" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                        <span v-for="(filter, i) in selected.source" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{sourceFilterData(filter)[0].name}}
                        </span>
                        <span v-for="(filter, i) in selected.author" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                        <span v-for="(filter, i) in selected.tags" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                    </div>
                </div>

                <div class="mt-2" v-if="courseFiltered.length">
                    <div class="course-card me-2" v-for="training in courseFiltered" :key="training">
                        <div class="card">
                            <div class="d-flex justify-content-between card-absolute">
                                <div class="d-flex align-items-center">
                                    <div class="badge my-2" v-bind:class="trainingStatus(training)?.class">{{ training.status }}</div>
                                </div>
                                <div class="card-btn-actions" v-if="training.status != 'Completed'">
                                    <div class="dropdown position-relative">
                                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-more-horizontal align-middle"
                                            >
                                                <circle cx="12" cy="12" r="1"></circle>
                                                <circle cx="19" cy="12" r="1"></circle>
                                                <circle cx="5" cy="12" r="1"></circle>
                                            </svg>
                                        </a>

                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a class="dropdown-item" href="javascript:;" v-if="training.status == 'Enrolled'" @click="unEnrollTraining(training.id)">Unenroll</a>
                                            <a class="dropdown-item" href="javascript:;" v-else-if="training.status == 'In Progress'" @click="completeTraining(training.id)">Mark as Complete</a>
                                            <a class="dropdown-item" href="javascript:;" v-else @click="enrollTraining(training.id)">Enroll</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a :href="training.link ?? training.video" target="_blank" class="card-link">
                                <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                                <div class="card-header px-4 pt-4">
                                    <h5 class="card-title mb-0">{{ training.title_limit }}</h5>
                                </div>
                            </a>
                            <div class="card-body training-card">
                                <div>{{ training.author }}</div>
                                <p class="card-text carousel-card-text">
                                    <b>Source: {{ training.source_name }}</b>
                                </p>
                                <div class="mt-3" v-html="training.display_learning_plans"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" v-else>
                    <div class="text-center">
                        <img src="/assets/images/empty.png" class="img-fluid img-30">
                    </div>
                </div>
            </div>
            <!-- End col 10 -->
        </div>
        <div class="overlay position-fixed full-screen-loader text-primary" v-if="page.isfullScreenLoading">
            <i class="fa fa-spin fa-spinner fa-3x"></i>
        </div>
        <ModalSaveFilter
            :modalActive="modalSaveFilterActive"
            :form="savefilter"
            :modalTitle="`Save Filter`"
            @close="toggleSaveFilterModal"
            @saveChanges="saveFilterAction"
        />

        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to enroll in this course?`"
            @cancel="toggleConfirmation"
            @isDelete="processEnroll"
        />
        <Confirm
            :is-confirm="isUnenrollConfirm"
            :confirm-text="`Are you sure you want to unenroll this course?`"
            @cancel="toggleUnenrollConfirmation"
            @isDelete="processUnEnroll"
        />
        <Confirm
            :is-confirm="isCompleteConfirm"
            :confirm-text="`Are you sure you want to mark this course as complete?`"
            @cancel="toggleCompleteConfirmation"
            @isDelete="processComplete"
        />
        <Confirm 
            :is-confirm="isConfirmDelete"
            :confirm-text="`Are you sure you want to delete this saved filter?`"
            @cancel="toggleConfirmationDelete"
            @isDelete="deleteFilter"
        />
    </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watch } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import candidateTrainingRepo from '@/repositories/candidate/trainings';
import CourseItem from '@/components/dashboard/CourseItem'
import globalRepo from '@/repositories/global';
import ModalSaveFilter from '@/views/employer/trainings/modals/ModalSaveFilter.vue';

export default {
    components: {
        CourseItem
    },
    props: {
        trainings: {
            type: Array,
            default: []
        },
        suggested_trainings: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            idToEnroll: '',
            filter: [],
            viewSavedFilters: false,
            isfullScreenLoading: false,
            selectedSavedFilter: 0
        });
        const { getTraining, training, getCourseFilter, getCourseSelectedFilter, searchFilters, getPrivateLearningPlanTemplates, templates, courseFiltered, saved_filters, getSavedFilters, saveTrainingFilter, deleteSavedFilter } = trainingRepo();
        const { updateCourse, status, trainings, getEnrolledTrainings } = candidateTrainingRepo();
        const { assigned_course_filters, course_difficulty } = globalRepo();
        const modalSaveFilterActive = ref(false);
        const isConfirm = ref(false);
        const isUnenrollConfirm = ref(false);
        const isCompleteConfirm = ref(false);
        const isConfirmDelete = ref(false);
        const results = ref([]);
        const selected = ref({
            skill_type: [],
            course_type: [],
            difficulty: [],
            source: [],
            author: [],
            tags: [],
            keyword: ''
        });

        const savefilter = ref({
            filter_name: ''
        });

        onMounted(async () => {
            getEnrolledTrainings(page);
            document.getElementById("training-title-header").innerHTML = 'Training Courses';
            getCourseSelectedFilter();
            await getCourseFilter({}, page);
            getPrivateLearningPlanTemplates();
            await getSavedFilters(page);
        });

        const toggleSaveFilterModal = () => {
            modalSaveFilterActive.value = !modalSaveFilterActive.value;
        }

        const isCourseEnrolled = (training_id) => {
            const courseFound = trainings.value.filter(data => data.training_id == training_id)
            return courseFound.length > 0 ? true:false
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const enrollTraining = (id) => {
            page.idToEnroll = id;
            toggleConfirmation();
        }

        const toggleUnenrollConfirmation = () => {
            isUnenrollConfirm.value = !isUnenrollConfirm.value;
        }

        const toggleCompleteConfirmation = () => {
            isCompleteConfirm.value = !isCompleteConfirm.value;
        }

        const unEnrollTraining = (id) => {
            page.idToEnroll = id;
            toggleUnenrollConfirmation();
        }

        const processEnroll = async () => {
            let formData = new FormData();
            formData.append('training_id', page.idToEnroll);
            formData.append('user_id', page.authuser.id);
            formData.append('action', 1);

            await updateCourse(formData);
            if(status.value == 200) {
                toggleConfirmation();
                const params = {
                    skill_type: selected.value.skill_type,
                    course_type: selected.value.course_type,
                    difficulty: selected.value.difficulty,
                    source: selected.value.source,
                    author: selected.value.author,
                    tags: selected.value.tags,
                    keyword: selected.value.keyword
                }
                await getCourseFilter(params, page);
            }
        }

        const processUnEnroll = async () => {
            let formData = new FormData();
            formData.append('training_id', page.idToEnroll);
            formData.append('user_id', page.authuser.id);
            formData.append('action', 4);

            await updateCourse(formData);
            if(status.value == 200) {
                toggleUnenrollConfirmation();
                const params = {
                    skill_type: selected.value.skill_type,
                    course_type: selected.value.course_type,
                    difficulty: selected.value.difficulty,
                    source: selected.value.source,
                    author: selected.value.author,
                    tags: selected.value.tags,
                    keyword: selected.value.keyword
                }
                await getCourseFilter(params, page);
            }
        }

        const processComplete = async () => {
            let formData = new FormData();
            formData.append('training_id', page.idToEnroll);
            formData.append('user_id', page.authuser.id);
            formData.append('action', 5);

            await updateCourse(formData);
            if(status.value == 200) {
                toggleConfirmation();
                const params = {
                    skill_type: selected.value.skill_type,
                    course_type: selected.value.course_type,
                    difficulty: selected.value.difficulty,
                    source: selected.value.source,
                    author: selected.value.author,
                    tags: selected.value.tags,
                    keyword: selected.value.keyword
                }
                await getCourseFilter(params, page);
            }
        }

        const completeTraining = (id) => {
            page.idToEnroll = id;
            toggleCompleteConfirmation();
        }

        watch(selected.value,
            async () => {
                var params = {
                    skill_type: selected.value.skill_type,
                    course_type: selected.value.course_type,
                    difficulty: selected.value.difficulty,
                    source: selected.value.source,
                    author: selected.value.author,
                    tags: selected.value.tags,
                    keyword: selected.value.keyword
                }
                await getCourseFilter(params, page)
            },
            { deep: true }
        );

        const clearFilter = () => {
            selected.value.skill_type = [];
            selected.value.course_type = [],
            selected.value.difficulty = [];
            selected.value.source = [];
            selected.value.author = [];
            selected.value.tags = [];
            selected.value.keyword = '';
        }

        const sourceFilterData = (id) => {
            return searchFilters.value.sources.filter(data => data.id == id)
        }

        const allCourse = computed(() => {
            return props.trainings.filter(data => data.skill_type == 'Hard Skills' || data.skill_type == 'Soft Skills')
        })

        const trainingStatus = (data) => {
            if(data.course_started == false && data.course_completed == false) {
                return {
                    label: 'Enrolled',
                    class: 'bg-secondary'
                }
            }

            if(data.course_completed == true) {
                return {
                    label: 'Completed',
                    class: 'bg-success'
                }
            }

            if(data.course_started == true && data.course_completed != true) {
                return {
                    label: 'In progress',
                    class: 'bg-warning'
                }
            }

            return ''
        }

        const notEmptyFilter = computed(() => {
            return (selected.value.skill_type.length || selected.value.course_type.length || selected.value.difficulty.length || selected.value.source.length || selected.value.author.length || selected.value.tags.length || selected.value.keyword != '') ? true:false;
        });

        const viewSavedFiltersAction = (action) => {
            clearFilter();
            if(action == 'saved-filters') {
                if(page.selectedSavedFilter !== 0) {
                    updateFilter();
                }
                page.viewSavedFilters = true;
            }
            if(action == 'custom-filters') {
                page.viewSavedFilters = false;
            }
        }

        const saveFilterAction = async (data) => {
            toggleSaveFilterModal();
            page.isfullScreenLoading = true;
            let formData = new FormData();

            // stuff below
            formData.filter_name = data.filter_name;
            formData.skill_type = selected.value.skill_type;
            formData.course_type = selected.value.course_type;
            formData.difficulty = selected.value.difficulty;
            formData.source = selected.value.source;
            formData.author = selected.value.author;
            formData.tags = selected.value.tags;
            formData.keyword = selected.value.keyword;
            formData.user_id = page.authuser.id;

            await saveTrainingFilter(page, formData);
            await getSavedFilters(page);
            savefilter.value.filter_name = '';
            // clear after saving filter
            clearFilter();
        }

        const updateFilter = () => {
            clearFilter();

            let filtered_data = JSON.stringify(saved_filters.value.filter( f => { return f.id === page.selectedSavedFilter }));
            let filtered_json = JSON.parse(filtered_data)[0];
            let json_data = JSON.parse(filtered_json.json_data);

            selected.value.skill_type = json_data.skill_type;
            selected.value.course_type = json_data.course_type;
            selected.value.difficulty = json_data.difficulty;
            selected.value.source = json_data.source;
            selected.value.author = json_data.author;
            selected.value.tags = json_data.tags;
            selected.value.keyword = json_data.keyword;
        }

        const processDeleteSavedFilter = () => {
            toggleConfirmationDelete();
        }

        const deleteFilter = async () => {
            page.isfullScreenLoading = true;
            await deleteSavedFilter(page, page.selectedSavedFilter);
            page.selectedSavedFilter = 0;
            clearFilter();
            toggleConfirmationDelete();
            await getSavedFilters(page);
        }

        const toggleConfirmationDelete = () => {
            isConfirmDelete.value = !isConfirmDelete.value;
        }

        return {
            page,
            getTraining,
            training,
            updateCourse,
            status,
            results,
            enrollTraining,
            isConfirm,
            toggleConfirmation,
            processEnroll,
            trainingStatus,
            trainings,
            isCourseEnrolled,
            searchFilters,
            templates,
            assigned_course_filters,
            course_difficulty,
            allCourse,
            selected,
            courseFiltered,
            clearFilter,
            sourceFilterData,
            unEnrollTraining,
            toggleUnenrollConfirmation,
            isUnenrollConfirm,
            processUnEnroll,
            processComplete,
            toggleCompleteConfirmation,
            isCompleteConfirm,
            completeTraining,
            notEmptyFilter,
            viewSavedFiltersAction,
            toggleSaveFilterModal,
            modalSaveFilterActive,
            savefilter,
            saveFilterAction,
            saved_filters,
            updateFilter,
            processDeleteSavedFilter,
            deleteFilter,
            isConfirmDelete,
            toggleConfirmationDelete
        }
    },
    components: {
        ModalSaveFilter
    }
}
</script>
<style scoped>
.card-title {
    display: -webkit-box;
    max-width: 100%;
    min-height: 65px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.course-card { display: inline-block; width: 240px; }
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.feather {
    width: 24px !important;
    height: 24px !important;
    padding: 0 3px;
}
.training-card {
    height: 140px;
}
.ml-7px {
    margin-left: 7px;
}
.text-sm {
    font-size: 14px !important;
}
.filter-text-header {
    font-size: 17px;
    font-weight: 500;
}
</style>