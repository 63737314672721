<template>
    <div class="col-12">
        <div class="row">
            <div class="col-12 mt-3" v-if="planDetails">
                <div class="text-center feature-logo"><img :src="`${page.apiEndpoint}/simphini-logo.svg`" width="100"></div>
                <h2 class="text-center mb-4">Congratulations, you have successfully upgraded to <strong style="color:#3f80ea;">{{ page.successData.plan_type }}</strong> plan.</h2>
                <p class="text-center mb-5">
                    <button class="btn btn-success btn-lg" @click="triggerAction">Close</button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
    props: {
        planDetails: {
            type: Object,
            default: {}
        }
    },
    setup(props, { emit }) {

        const page = reactive({
            apiEndpoint: process.env.VUE_APP_API_URL,
            successData: props.planDetails ?? ''
        });

        const triggerAction = () => {
            emit('triggerAction', true);
        }

        return {
            page,
            triggerAction
        }
    },
})
</script>

<style scoped>

</style>
