<template>
    <ul class="sidebar-nav">
        <li class="sidebar-item">
            <a data-bs-target="#manageUsers" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fas fa-solid fa-users fa-fw"></i> <span class="align-middle">Manage Users</span>
            </a>
            <ul id="manageUsers" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.settings.users' }">
                        All Users
                    </router-link>
                    <router-link class="sidebar-link" :to="{ name: 'admin.settings.users.paid' }">
                        Paid Users
                    </router-link>
                    <router-link class="sidebar-link" :to="{ name: 'admin.settings.users.archived' }">
                        Archived Users
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <a data-bs-target="#training-courses" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fas fa-shapes fa-fw"></i> <span class="align-middle">Training Courses</span>
            </a>
            <ul id="training-courses" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.trainings' }">
                        Manage Training Courses
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.learning_plans' }">
                        Manage Learning Plans
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.trainings.source' }">
                        Manage Content Providers
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <a data-bs-target="#job-find" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fa fas fa-suitcase fa-fw"></i> <span class="align-middle">Interview Trainings</span>
            </a>
            <ul id="job-find" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.interviewtrainings' }">
                        Manage Interview Trainings
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <router-link class="sidebar-link" :to="{ name: 'admin.settings.user-logs' }">
                <i class="fas fa-users-cog fa-fw"></i> <span class="align-middle">User logs</span>
            </router-link>
            <ul id="userLogs" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.settings.user-logs' }">
                        User Logs
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <router-link class="sidebar-link" :to="{ name: 'admin.plans' }">
                <i class="fa-solid fa-file-lines fa-fw"></i> <span class="align-middle">Upgrade Plans</span>
            </router-link>
            <ul id="upgradePlans" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.plans' }">
                        Upgrade Plans
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <router-link class="sidebar-link" :to="{ name: 'admin.skills' }">
                <i class="fa-solid fa-list fa-fw"></i> <span class="align-middle">Skill Management</span>
            </router-link>
            <ul id="skillManagement" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.skills' }">
                        Skill Management
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <router-link class="sidebar-link" :to="{ name: 'admin.feedbacks', params: {id:'inbox'}}">
                <i class="fa-solid fa-comment-dots fa-fw"></i> <span class="align-middle">Feedback and Support</span>
            </router-link>
            <ul id="feedbackSupport" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'admin.feedbacks', params: {id:'inbox'}}">
                        Feedback and Support
                    </router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>
