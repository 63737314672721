import axios from "axios";
import { ref, inject } from "vue";

export default function conversationsRepo() {
    
    const toast = inject('toast');
    const conversations = ref([]);

    const getConversations = async (page, usertype) => {
        let user_id = page.authuser.id ?? 0;
        if(user_id > 0) {
            let response
            if(usertype == 'employer') {
                response = await axios.get(`employer/messages/conversations?user_id=${user_id}`);
            } else if( usertype == 'candidate' ) {
                response = await axios.get(`candidate/messages/conversations?user_id=${user_id}`);
            }
            conversations.value = response.data;
        }
        page.isLoading = false;
    }

    const requestDeleteMessage = async (page, usertype) => {
        let user_id = page.authuser.id ?? 0;
        if(user_id > 0) {
            let response
            if(usertype == 'employer') {
                response = await axios.get(`employer/messages/delete?message_id=${page.messageId}`);
            } else if( usertype == 'candidate' ) {
                response = await axios.get(`candidate/messages/delete?message_id=${page.messageId}`);
            }
            toast.success(response.data.message);
        }
        page.isLoading = false;
    }
    
    return {
        conversations,
        getConversations,
        requestDeleteMessage
    }
}