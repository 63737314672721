import axios from "axios";
import { ref, inject } from "vue";

export default function roleRepo() {
    
    const toast = inject('toast');
    const certificates = ref([]);
    const certificate = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');

    const getCertificates = async (page) => {
        let user_id = page.authuser.id;
        let response = await axios.get(`candidate/profile/certificates?user_id=${user_id}`);
        certificates.value = response.data.data;
        page.isLoading = false;
    }

    const getCertificate = async (id, page) => {
        let response = await axios.get(`candidate/profile/certificates/${id}`);
        certificate.value = response.data.data;
        page.isLoading = false;
    }

    const insertCertificate = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/certificates`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateCertificate = async (id, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/certificates/${id}`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const deleteCertificate = async (id) => {
        let response = await axios.delete(`candidate/profile/certificates/${id}`);
        alertMessage(response.data.message);
    }

    const addMultipleCertificate = async (form) => {
        errors.value = '';
        try {
            let data = {
                certificates: form
            }
            let response = await axios.post(`candidate/profile/certificates/add-multi-certificates`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }
    
    return {
        certificates,
        certificate,
        getCertificates,
        getCertificate,
        insertCertificate,
        updateCertificate,
        deleteCertificate,
        addMultipleCertificate,
        alertMessage,
        errors,
        message,
        status
    }
}