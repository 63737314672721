<template>
    <div>
        <Modal :modalActive="modalActive" :class="`modal-dialog modal-dialog-centered modal-dialog-scrollable`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-8">
                        <BaseInput
                            v-model="form.title"
                            label="Course Title"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.title }"
                            id="title"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-4">
                        <div class="form-group mb-3">
                            <label class="form-label">Skill Type</label>
                            <div>
                                <BaseRadio
                                    v-model="form.skill_type"
                                    placeholder="Soft Skills"
                                />
                                <BaseRadio
                                    v-model="form.skill_type"
                                    placeholder="Hard Skills"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group mb-3">
                            <label class="form-label">Learning Plans</label>
                            <multiselect
                                v-model="learning_plan_ids"
                                :options="templates"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                placeholder="Select Learning Plans"
                                label="name"
                                track-by="id"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <BaseSelect
                            v-model="form.difficulty"
                            label="Difficulty"
                            id="difficulty"
                            :options="course_difficulty"
                            optionId="name"
                            is-required
                            :errors="errors"
                            :class="{ 'is-invalid' : errors && errors.difficulty }"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="form-label">Tags</label>
                            <multiselect
                                v-model="tag_id"
                                :options="tag_id"
                                tag-placeholder="Add tags"
                                placeholder="Tags"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                label="name"
                                track-by="id"
                                @tag="addTags"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div v-if="hasImage && hasNotDeleteImage">
                            <label class="form-label">Course Image</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" :value="form.base_thumbnail" readonly>
                                <button class="btn btn-outline-danger" type="button" @click="removeImage">Remove</button>
                            </div>
                        </div>
                        <div v-else>
                            <BaseInputFile
                                label="Course Image"
                                :class="{ 'is-invalid' : errors && errors.image }"
                                id="image"
                                :errors="errors"
                                @changeFile="onFileImageChange"
                                :form-text="`Image must be 720x405 resolution for a better display.`"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div v-if="hasVideo">
                            <label class="form-label">Video</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" :value="form.base_video_link" readonly>
                                <button class="btn btn-outline-danger" type="button" @click="removeVideo">Remove</button>
                            </div>
                        </div>
                        <div v-else>
                            <BaseInputFile
                                label="Video"
                                :class="{ 'is-invalid' : errors && errors.video }"
                                id="video"
                                :errors="errors"
                                @changeFile="onFileVideoChange"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <BaseInput
                            v-model="form.course_link"
                            label="Course Link"
                            type="url"
                            :class="{ 'is-invalid' : errors && errors.course_link }"
                            id="course_link"
                            :errors="errors"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <BaseEditor
                            label="Training Content"
                            v-model="form.content"
                            :class="{ 'is-invalid' : errors && errors.video }"
                            id="content"
                            :errors="errors"
                            @editorContent="setTrainingContent"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="saveStatus === 200" @btnClicked="saveChanges" :default-btn="isBtnPressed" />
                        <button v-if="form.id" class="btn btn-danger ml-5" type="button" @click="onDeleteBtn">Delete</button>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, watchEffect, inject } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import Editor from '@/components/modules/Editor';
import globalRepo from '@/repositories/global';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        },
        hasImage: {
            type: Boolean,
            default: false
        },
        hasVideo: {
            type: Boolean,
            default: false
        },
        modalActionIsCreate: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const swal = inject('$swal');
        const {
            templates,
            getPrivateLearningPlanTemplates,
            unlinkImage,
            unlinkVideo,
            status
        } = trainingRepo();

        const {
            course_difficulty
        } = globalRepo();

        const isConfirm = ref(false);
        const imageFile = ref('');
        const videoFile = ref('');
        const hasNotDeleteImage = ref(true);
        const learning_plan_ids = ref([]);
        const tag_id = ref([]);
        const saveStatus = ref('');
        const isBtnPressed = ref(false);

        onMounted(() => {
            getPrivateLearningPlanTemplates();
        });

        watchEffect(() => {
            props.form.image = imageFile.value;
            learning_plan_ids.value = props.form.learning_plans ?? learning_plan_ids.value;
            tag_id.value = props.form.tags_arr ?? tag_id.value;
        });

        const close = () => {
            learning_plan_ids.value = [];
            tag_id.value = [];
            emit('close', hasNotDeleteImage.value)
        }

        const saveChanges = () => {
            props.form.tags = '';
            let tags = tag_id.value;
            let tags_array = [];
            tags.forEach(tag => {
                tags_array.push(tag.name);
            });

            let plan_ids = learning_plan_ids.value;
            let plan_id_array = [];
            plan_ids.forEach(plan => {
                plan_id_array.push(plan.id)
            });

            props.form.tags = tags_array;
            props.form.learning_plan_ids = plan_id_array;
            props.form.image = imageFile.value ?? '';
            props.form.video = videoFile.value ?? '';
            props.form.hasImage = props.hasImage;
            props.form.hasVideo = props.hasVideo;

            if (props.modalActionIsCreate) {
                learning_plan_ids.value = [];
                tag_id.value = [];
                document.getElementById('image').value = null;
            }

            isBtnPressed.value = true;
            saveStatus.value = 200;
            emit('saveChanges');
        }

        const onDeleteBtn = () => {
            emit('onDeleteBtn');
        }

        const onFileImageChange = (file) => {
            imageFile.value = file;
        }

        const onFileVideoChange = (file) => {
            videoFile.value = file;
        }

        const setTrainingContent = (evt) => {
            if(evt) {
                props.form.content = evt ?? '';
            }
        }

        const removeImage = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to remove this image?",
                icon: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, please'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    await unlinkImage(props.form.id);
                    if(status.value === 200) {
                        hasNotDeleteImage.value = false;
                        props.form.image = null;
                        imageFile.value = null;
                    }
                }
            });
        }

        const removeVideo = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to remove this video?",
                icon: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, please'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    await unlinkVideo(props.form.id);
                }
            });
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const removeLearningPlans = (evt) => {
            if(evt) {
                let finalLearningPlans = props.form.learning_plan_ids.replace(`,${evt.id}`, '');
                props.form.learning_plan_ids = finalLearningPlans;
            }
        }

        const addTags = (newTag) => {
            if(newTag !== undefined) {
                const tag = {
                    name: newTag,
                    id: Math.floor((Math.random() * 10000000))
                }
                tag_id.value.push(tag);
            }
        }

        return {
            templates,
            getPrivateLearningPlanTemplates,
            status,
            close,
            isConfirm,
            saveChanges,
            onDeleteBtn,
            onFileImageChange,
            setTrainingContent,
            removeImage,
            toggleConfirmation,
            hasNotDeleteImage,
            learning_plan_ids,
            removeLearningPlans,
            tag_id,
            addTags,
            course_difficulty,
            saveStatus,
            onFileVideoChange,
            removeVideo,
            unlinkVideo,
            isBtnPressed
        }
    },
    components: {
        Editor
    }
})
</script>

<style scoped>
.ml-5 {
    margin-left: 15px;
}
</style>