import PlanIndex from '@/views/admin/plans/Index';

const routes = [
    {
        path: '/admin/plan',
        name: 'admin.plans',
        component: PlanIndex
    }
]

export default routes