import settingsRoute from '../admin/settings/index';
import trainingsRoute from '../admin/trainings/index';
import plansRoute from '../admin/plan/index';
import interviewTrainingsRoute from './interviewTrainings/index';
import feedbackRoute from '../admin/feedback/index';
import skillsRoute from '../admin/skills/index';

const routes = [
    {
        path: "/admin/login",
        name: "admin.login",
        component: () => import("../../views/admin/auth/Login.vue")
    },
    {
        path: "/admin/dashboard",
        name: "admin.dashboard",
        component: () => import("../../views/admin/Dashboard.vue")
    },
    ...settingsRoute,
    ...trainingsRoute,
    ...plansRoute,
    ...interviewTrainingsRoute,
    ...feedbackRoute,
    ...skillsRoute
]

export default routes