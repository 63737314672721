import axios from "axios";
import { ref, inject, computed } from "vue";

export default function applicationRepo() {

    const toast = inject('toast');
    const application = ref([]);
    const applications = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');

    const getApplication = async (page, job_id) => {
        let response = await axios.get(`candidate/applications/get/${job_id}/${page.authuser.id}`);
        application.value = response.data.data;
    }

    const getApplications = async (page) => {
        let response = await axios.get(`candidate/applications/${page.authuser.id}`);
        applications.value = response.data.data;
    }

    const getApplicationInvites = async (page) => {
        let response = await axios.get(`candidate/applications/invites/${page.authuser.id}`);
        applications.value = response.data;
        page.isLoading = false;
    }

    const addApplication = async (page, job_id) => {
        let response = await axios.post(`candidate/applications`, {
            job_id: job_id,
            candidate_id: page.authuser.id
        });
        application.value = response.data.data;
    }

    const declineApplicationInvite = async (invite_id) => {
        let response = await axios.get(`candidate/applications/invitation/decline?invite_id=${invite_id}`);
        alertMessage(response.data.message);
    }

    const processCancelApplication = async (job_id) => {
        let response = await axios.get(`candidate/applications/application/cancel?application_id=${job_id}`);
        alertMessage(response.data.message);
    }

    const updateAnswers = async (page, questions) => {
        let response = await axios.post(`candidate/applications/answers`, {
            job_id: page.jobId,
            candidate_id: page.authuser.id,
            questions: questions
        });
        application.value = response.data.data;
        status.value = response.data.status;
        //alertMessage(response.data.message);
    }

    const updateDocuments = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/applications/documents`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            application.value = response.data.data;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const generatePdf = async (candidate_id, job_id, url) => {
        try {
            let response = await axios.get(`candidate/applications/generate-pdf/${job_id}/${candidate_id}`);
            status.value = response.data.status;
            application.value = response.data.application;
            url.value = application.value.application_file;
        } catch (e) {
            if(e.response.status === 500) {
                alertMessage(`Error: ${e.response.data.message}`, 500);
                status.value = e.response.status;
            }
        }
    }

    const submitJobApplication = async (page) => {
        let response = await axios.post(`candidate/applications/submit`, {
            job_id: page.jobId,
            candidate_id: page.authuser.id
        });
        application.value = response.data.data;
        alertMessage(response.data.message);
    }

    const getAssociatedCompany = async (page) => {
        let response = await axios.get(`candidate/applications/get-company/${page.authuser.id}`);
        applications.value = response.data.data;
    }

    const destroyApplication = async (id) => {
        let response = await axios.delete(`candidate/applications/${id}`);
        status.value = response.status;
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    const isHired = computed (() => {
        if(applications.value.length > 0){
            const hired = applications.value.filter(data => data.status === 'Hired')
            return hired.length > 0 ? true:false
        } else {
            return false;
        }
    })

    return {
        application,
        getApplication,
        getApplications,
        getApplicationInvites,
        addApplication,
        declineApplicationInvite,
        updateAnswers,
        updateDocuments,
        submitJobApplication,
        generatePdf,
        errors,
        status,
        message,
        alertMessage,
        getAssociatedCompany,
        applications,
        processCancelApplication,
        isHired,
        destroyApplication
    }
}