<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h1 class="h3 mb-3" id="training-title-header"></h1>
                    <div class="d-flex align-items-center">
                        <DiscountedCourse />
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="false" class="col-lg-2 mt-2" :class="page.is_basic_plan ? 'cloak' : ''">
                    <div class="form-group mb-3">
                        <label for="learning_plan_id" class="form-label">Select Learning Plan</label>
                        <select id="learning_plan_id" v-model="learning_plan_id" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                            <option value="">--</option>
                            <option v-for="template in templates" :key="template" :value="template.id">{{ template.name }}</option>
                        </select>
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 mt-2" :class="page.is_basic_plan ? 'cloak' : ''">
                    <div class="form-group mb-3">
                        <label for="filter" class="form-label">Filter by</label>
                        <select v-model="filter" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                            <option value="">--</option>
                            <option v-for="filter in page.course_filter_selection" :key="filter" :value="filter.id">{{ filter.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 mt-2" :class="page.is_basic_plan ? 'cloak' : ''">
                    <div class="form-group" v-if="currentComponent !== 'Courses'">
                        <label for="query_search" class="form-label">Search Courses</label>
                        <input
                            v-model="query_search"
                            class="form-control"
                            id="query_search"
                            placeholder="Search Courses by Title"
                            @input="searchCourse"
                            :disabled="isSearchDisabled"
                        />
                    </div>
                </div>
                <div class="col-lg-9 col-md-9 mb-3">
                    <div class="mtc-4 btn-group">
                        <button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'Courses' }" @click="trainingTabs('Courses')">All</button>
                        <button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'Suggested' }" @click="trainingTabs('Suggested')">Suggested</button>
                        <button class="btn btn-outline-primary" style="border-right:0;border-left:0;" :class="{ 'active' : currentComponent == 'Assigned' }" @click="trainingTabs('Assigned')">Assigned</button>
                        <button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'Enrolled' }" @click="trainingTabs('Enrolled')">Enrolled</button>
                        <button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'LearningPlans' }" @click="trainingTabs('LearningPlans')">Basic Learning Plans</button>
                    </div>
                </div>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div v-else>
                <component :is="currentComponent" v-bind="{ trainings: trainings, suggested_trainings: suggestedCourses, companies: companies, query_search: page.keyword, query_filter: page.filterValue, query_learningplan: page.learningPlanId }" @refresh-page="reloadPage"></component>
            </div>
        </div>
    </main>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import _debounce from 'lodash/debounce';
import trainingRepo from '@/repositories/admin/trainings';
import candidateTrainingRepo from '@/repositories/candidate/trainings';
import globalRepo from '@/repositories/global';
import Courses from '@/views/candidate/trainings/components/Courses.vue';
import Suggested from '@/views/candidate/trainings/components/Suggested.vue';
import Assigned from '@/views/candidate/trainings/components/Assigned.vue';
import Enrolled from '@/views/candidate/trainings/components/Enrolled.vue';
import Basic from '@/views/candidate/trainings/components/Basic.vue';
import LearningPlans from '@/views/candidate/trainings/components/LearningPlans.vue';
import DiscountedCourse from '@/views/candidate/trainings/components/DiscountedCourse.vue';

export default {
    setup() {
        const route = useRoute();
        const { trainings, templates, getCandidateTrainings, getPrivateLearningPlanTemplates, getSelectedTrainings, searchTrainings } = trainingRepo();
        const { trainings : suggested_trainings, companies, getSuggestedTrainings, getSelectedSuggestedTrainings, getAssignedTrainings, searchCandidateTrainings, suggestedCourses } = candidateTrainingRepo();
        const { course_filters, assigned_course_filters, enrolled_course_filters, training_tabs } = globalRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            is_basic_plan: false,
            course_filter_selection: [],
            keyword: '',
            filterValue: '',
            learningPlanId: '',
            routeQuery: route.query.tab ?? ''
        });
        const learning_plan_id = ref('');
        const filter = ref('');
        const currentComponent = ref(training_tabs.courses);
        const isDisabled = ref(false);
        const isSearchDisabled = ref(false);
        const query_search = ref('');

        const filteredTrainings = async () => {
            query_search.value = '';
            let formData = new FormData();
            formData.append('learning_plan_id', learning_plan_id.value);
            formData.append('filter', filter.value);
            formData.append('user_id', page.authuser.id);
            formData.append('component', currentComponent.value);
            if(currentComponent.value == training_tabs.courses) {
                await getSelectedTrainings(formData);
            }

            if(currentComponent.value == training_tabs.suggested) {
                await getSelectedSuggestedTrainings(formData);
            }

            if(currentComponent.value == training_tabs.assigned) {
                await getAssignedTrainings(page);
                filterAssignedCourses();
            }

            if(currentComponent.value == training_tabs.enrolled) {
                if(filter.value) {
                    learning_plan_id.value = '';
                } else if (learning_plan_id.value) {
                    filter.value = '';
                }
                page.filterValue = filter.value;
                page.learningPlanId = learning_plan_id.value;

            }
        }

        const filterAssignedCourses = () => {
            let company_courses = companies.value;
            company_courses.forEach((item) => {
                item.courses.forEach((sub_item, index)=> {
                    if(query_search.value != '') {
                        if(!sub_item.course?.title.toLowerCase().includes(query_search.value.toLowerCase())) {
                            item.courses.splice(index, 1);
                        } else {
                            company_courses = companies.value;
                        }
                    }
                    if(learning_plan_id.value) {
                        if(!sub_item.course?.learning_plan_ids.includes(learning_plan_id.value)) {
                            item.courses.splice(index, 1);
                        }
                    } else {
                        company_courses = companies.value;
                    }
                    if(filter.value) {
                        if(!sub_item.course?.skill_type.includes(filter.value)) {
                            item.courses.splice(index, 1);
                        }
                    } else {
                        company_courses = companies.value;
                    }
                })
            });
            companies.value = company_courses;
        }

        const trainingTabs = async (tab) => {
            currentComponent.value = tab;
            page.isLoading = true;
            if(tab == training_tabs.courses) {
                page.course_filter_selection = course_filters;
                page.is_basic_plan = false;
                learning_plan_id.value = '';
                filter.value = '';
                query_search.value = '';
                await getCandidateTrainings(page);
                isDisabled.value = false;
                isSearchDisabled.value = false;
            }

            if(tab == training_tabs.suggested) {
                page.course_filter_selection = course_filters;
                page.is_basic_plan = false;
                learning_plan_id.value = '';
                filter.value = '';
                query_search.value = '';
                await getSuggestedTrainings(page);
                isDisabled.value = false;
                isSearchDisabled.value = false;
            }

            if(tab == training_tabs.enrolled) {
                page.course_filter_selection = enrolled_course_filters;
                page.is_basic_plan = false;
                isDisabled.value = false;
                isSearchDisabled.value = false;
                query_search.value = '';
                page.isLoading = false;
            }

            if(tab == training_tabs.assigned) {
                page.course_filter_selection = assigned_course_filters;
                page.is_basic_plan = false;
                learning_plan_id.value = '';
                filter.value = '';
                query_search.value = '';
                await getAssignedTrainings(page);
                isDisabled.value = false;
                isSearchDisabled.value = false;
            }
            if(tab == training_tabs.basic) {
                page.is_basic_plan = true;
                isDisabled.value = true;
                isSearchDisabled.value = true;
                query_search.value = '';
                page.isLoading = false;
            }
            if(tab == training_tabs.learning) {
                page.is_basic_plan = true;
                isDisabled.value = true;
                isSearchDisabled.value = true;
                query_search.value = '';
                page.isLoading = false;
            }
        }

        const searchCourse = _debounce( async function (event) {
            page.isLoading = true;
            let formData = new FormData();
            formData.append('query_search', event.target.value);
            formData.append('user_id', page.authuser.id);
            formData.append('learning_plan_id', learning_plan_id.value);
            formData.append('filter', filter.value);
            formData.append('component', currentComponent.value);

            if(currentComponent.value == training_tabs.courses) {
                searchTrainings(page, formData);
            } else {
                searchCandidateTrainings(page, formData);
            }

            if(currentComponent.value == training_tabs.assigned) {
                await getAssignedTrainings(page);
                filterAssignedCourses();
            }

            if(currentComponent.value == training_tabs.enrolled) {
                page.keyword = query_search.value;
                learning_plan_id.value = '';
                filter.value = '';
            }
        }, 750);

        const reloadPage = async () => {
            await getCandidateTrainings(page);
            await getAssignedTrainings(page);
        }

        onMounted(() => {
            page.course_filter_selection = course_filters;
            getCandidateTrainings(page);
            getPrivateLearningPlanTemplates();

            if(page.routeQuery == 'learning-plan-assign') {
                trainingTabs('Assigned');
            }
        });

        return {
            page,
            trainings,
            templates,
            learning_plan_id,
            filter,
            course_filters,
            getCandidateTrainings,
            getPrivateLearningPlanTemplates,
            getSelectedTrainings,
            filteredTrainings,
            trainingTabs,
            currentComponent,
            isDisabled,
            query_search,
            getSuggestedTrainings,
            getSelectedSuggestedTrainings,
            getAssignedTrainings,
            suggested_trainings,
            companies,
            searchCourse,
            searchTrainings,
            searchCandidateTrainings,
            isSearchDisabled,
            reloadPage,
            suggestedCourses
        }
    },
    components: {
        Courses,
        Suggested,
        Assigned,
        Enrolled,
        Basic,
        LearningPlans,
        DiscountedCourse
    }
}
</script>

<style>
.training-card {
    background-color: #fff;
    color: #333;
    border-top: 1px solid #eee;
    text-align: left;
}
.card-link {
    cursor: pointer;
}
.fa-training-icon {
    font-size: 20px;
    color: #fff;
}
.object-cover {
    object-fit: cover;
}
.course-img {
    height: 135px !important;
}
.card {
    box-shadow: none;
}
.card-title {
    font-size: 18px;
}
.btn-margin {
    margin: 0 5px;
}
.mtc-4 {
    margin-top: 42px;
    float: right;
}
.cloak {
    visibility:hidden;
}
</style>