import axios from "axios";
import { ref, inject } from "vue";

export default function trainingRepo() {

    const toast = inject('toast');
    const users = ref([]);
    const training_users = ref([]);
    const premium_learning_plans = ref([]);
    const learning_plans = ref([]);
    const learning_plan = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const totalData = ref('');
    const training_employees = ref([])
    const learning_plans_employees = ref([])
    const learning_plans_employer = ref([]);
    const assigned_users = ref([]);
    const urlRedirect = ref('');
    const billings = ref([]);
    const billing = ref([]);

    const getTrainingUsers = async (page) => {
        let response = await axios.post(`employer/training-users`, page);
        training_users.value = response.data.data;
        page.isLoading = false;
    }

    const getTrainingEmployees = async (page) => {
        await axios.get(`employer/trainings/employees-courses?company_id=${page.authuser.company_id}&user_id=${page.authuser.id}`).then((response)=>{
            training_employees.value = response.data.courses;
            learning_plans_employees.value = response.data.learning_plans_data
        });
        page.isLoading = false;
    }

    const getEmployees = async (page) => {
        if(page.authuser.company_id){
            let response = await axios.get(`employer/trainings/employee?company_id=${page.authuser.company_id}&user_id=${page.authuser.id}`);
            users.value = response.data.data;
        }
        page.isLoading = false;
    }

    const getTrainingAssignedUsers = async (page, training_id) => {
        if(page.authuser.company_id){
            let response = await axios.get(`employer/trainings/assigned?company_id=${page.authuser.company_id}&training_id=${training_id}`);
            assigned_users.value = response.data.data;
        }
        page.isLoading = false;
    }

    const updateCourse = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getCustomLearningPlans = async (page) => {
        let response = await axios.get(`employer/trainings/custom-learning_plan?company_id=${page.authuser.company_id}`);
        learning_plans.value = response.data.data;
        page.isLoading = false;
    }

    const getCustomLearningPlansEmployer = async (page) => {
        let response = await axios.get(`employer/trainings/custom-learning_plan?company_id=${page.authuser.company_id}`);
        learning_plans_employer.value = response.data.data;
        page.isLoading = false;
    }

    const getSelectedPremiumLearningPlans = async (page) => {
        let response = await axios.get(`employer/trainings/premium-learning-plans?company_id=${page.authuser.company_id}`);
        premium_learning_plans.value = response.data.data;
        page.isLoading = false;
    }

    const selectPremiumLearningPlan = async (page, id) => {
        let response = await axios.get(`employer/trainings/select-premium-learning-plan?company_id=${page.authuser.company_id}&plan_id=${id}`);
        alertMessage(response.data.message);
    }

    const removePremiumLearningPlanFromList = async (page) => {
        let response = await axios.get(`employer/trainings/remove-premium-learning-plan-from-list?company_id=${page.authuser.company_id}&plan_id=${page.idToDelete}`);
        alertMessage(response.data.message);
    }

    const getCustomLearningPlan = async (id) => {
        let response = await axios.get(`employer/trainings/custom-learning_plan/${id}`);
        learning_plan.value = response.data.learning_plan;
    }

    const insertCustomLearningPlan = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/custom-learning_plan`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateCustomLearningPlan = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/custom-learning_plan/${id}`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const removeCustomLearningPlan = async (data) => {
        await axios.post(`employer/trainings/learning-plan/remove-training`, data);
    }

    const destroyCustomLearningPlan = async (page) => {
        let response = await axios.delete(`employer/trainings/custom-learning_plan/${page.idToDelete}?company_id=${page.authuser.company_id}`);
        status.value = response.data.status;
        alertMessage(response.data.message);
    }

    const assignCustomLearningPlan = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/learning-plan/assign`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    // Private learning plan
    const private_plans = ref([]);
    const private_plan = ref([]);
    const private_courses = ref([]);

    const getPrivateLearningPlans = async (page) => {
        let response = await axios.get(`employer/trainings/private-learning-plans?company_id=${page.authuser.company_id}`);
        private_plans.value = response.data.data;
        page.isLoading = false;
    }

    const getPrivateLearningPlan = async (id) => {
        let response = await axios.get(`employer/trainings/private-learning-plans/${id}`);
        learning_plan.value = response.data.data;
    }

    const insertPrivateLearningPlan = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/private-learning-plans`, data, {
                progress(e) {
                    if (e.lengthComputable) {
                        console.log(e.loaded / e.total * 100);
                    }
                }
            });
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updatePrivateLearningPlan = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/private-learning-plans/${id}`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getSelectedPrivateLearningPlans = async (data) => {
        let response = await axios.post(`employer/trainings/private-learning-plans/selected`, data);
        private_plans.value = response.data.trainings;
    }

    const destroyTraining = async (page, id) => {
        try {
            let response = await axios.delete(`employer/trainings/${id}`);
            private_plans.value = response.data.data;
            alertMessage(response.data.message);
            status.value = response.data.status;
            page.isLoading = false;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    const getAvailableLearningPlans = async (page) => {
        let formData = {
            user_id: page.authuser.id,
            length: 8,
            start: page.offSet
        }

        let response = await axios.post(`employer/trainings/available-learning-plan`, formData);
        learning_plans.value = response.data.data;
        totalData.value = response.data.recordsTotal;
    }

    const markCompletedCustomLearning = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/custom-learning-plan/update-progress/${id}`, data);
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateLearningPlanProgress = async(data) => {
        errors.value = '';
        await axios.post(`employer/trainings/learning-plan/update-progress`, data).then((response) => {
            alertMessage(response.data.message);
        }).catch((e) => {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        });
    }

    const getCompanyPrivateCourse = async (page) => {
        let response = await axios.post(`employer/trainings/private-company-course`, page);
        private_courses.value = response.data.data;
        page.isLoading = false;
    }

    const assignLearningPlan = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/learning-plan/assign`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const assignLearningPlanBulk = async (data) => {
        await axios.post(`employer/trainings/learning-plan/bulk-assign`, data).then((response) => {
            alertMessage(response.data.message);
            status.value = response.data.status;
        }).catch((e) => {
            status.value = e.response.status;
            errors.value = e.response.data.errors;
        });
    }

    const storeOneTimePayment = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/onetime-payment`, data);
            status.value = response.status;
            urlRedirect.value = response.data.checkout.url;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateOneTimePayment = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/trainings/onetime-payment/${id}`, data);
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getBillings = async (state) => {
        let response = await axios.get(`employer/trainings/billings?user_id=${state.user_id}`);
        billings.value = response.data.data;
        state.isLoading = false;
    }

    const getBilling = async (id) => {
        let response = await axios.get(`employer/trainings/billings/${id}`);
        billing.value = response.data.data;
    }

    const createForwardEmailAccount = async (code) => {
        errors.value = '';
        try {
            let response = await axios.get(`employer/trainings/forward-email/${code}`);
            status.value = response.status;
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    return {
        users,
        training_users,
        learning_plans,
        learning_plan,
        errors,
        message,
        status,
        getTrainingUsers,
        getEmployees,
        updateCourse,
        getCustomLearningPlans,
        getCustomLearningPlan,
        insertCustomLearningPlan,
        updateCustomLearningPlan,
        removeCustomLearningPlan,
        destroyCustomLearningPlan,
        assignCustomLearningPlan,
        private_plans,
        private_plan,
        getPrivateLearningPlans,
        getPrivateLearningPlan,
        insertPrivateLearningPlan,
        updatePrivateLearningPlan,
        getSelectedPrivateLearningPlans,
        destroyTraining,
        alertMessage,
        getAvailableLearningPlans,
        markCompletedCustomLearning,
        getCompanyPrivateCourse,
        private_courses,
        premium_learning_plans,
        getSelectedPremiumLearningPlans,
        selectPremiumLearningPlan,
        removePremiumLearningPlanFromList,
        updateLearningPlanProgress,
        getTrainingEmployees,
        training_employees,
        assignLearningPlan,
        learning_plans_employees,
        assignLearningPlanBulk,
        learning_plans_employer,
        getCustomLearningPlansEmployer,
        totalData,
        getTrainingAssignedUsers,
        assigned_users,
        storeOneTimePayment,
        updateOneTimePayment,
        getBillings,
        getBilling,
        urlRedirect,
        billings,
        billing,
        createForwardEmailAccount
    }

}