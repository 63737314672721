<template>
    <div>
        <Modal :className="`modal-dialog modal-lg modal-traininglist modal-dialog-scrollable`" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row" v-if="learning_plans.length">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-3" v-for="value in learning_plans" :key="value.id">
                                <div class="div-training-checkbox">
                                    <input type="checkbox" class="cls-training-checkbox" v-model="trainingCourses.ids" :value="value.id">
                                </div>
                                <div class="no-decor">
                                    <div class="card">
                                        <img class="card-img-top-interview-training" :src="`${value.thumbnail}`" alt="training" />
                                        <div class="card-header px-4 pt-4">
                                            <h5 class="card-title mb-0 interview-training-title">{{ value.title }}</h5>
                                            <div>
                                                Interview Training Type: {{ value.source_name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                            <div class="dataTables_paginate paging_simple_numbers">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" :class="{ 'disabled' : page.num === 1 }"><a href="javascript:;" class="page-link" @click="prevPage">Previous</a></li>
                                    <li class="paginate_button page-item" :class="{ 'active' : page.currentPage === n }" v-for="n in page.pages" :key="n"><a href="javascript:;" class="page-link" @click="changePage(n)">{{ n }}</a></li>
                                    <li class="paginate_button page-item next" :class="{ 'disabled' : page.num === page.pages || page.pages.length === 0 }"><a href="javascript:;" class="page-link" @click="nextPage">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" v-if="page.noResult">
                        <div class="text-center">
                            <img src="/assets/images/empty.png" class="img-fluid img-30">
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" :btn-value="`Assign`" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, watchEffect, inject, ref } from 'vue';
import trainingRepo from '@/repositories/employer/trainings';
import roleRepo from '@/repositories/settings/roles';

export default defineComponent({
    props: ['modalActive','form','modalTitle','errors'],
    setup(props, { emit }) {

        const toast = inject('toast');
        const status = ref('');
        const page = reactive({
            api_url: process.env.VUE_APP_API_URL,
            authuser: JSON.parse(localStorage.getItem('authuser')),
            noResult: false,
            pages: [],
            num: 1,
            offSet: 0,
            currentPage: 1
        });

        const { learning_plans, getAvailableLearningPlans, totalData } = trainingRepo();
        const { roles, getRoles } = roleRepo();

        const close = () => {
            emit('close')
        }

        const trainingCourses = reactive({
            ids: []
        });

        const saveChanges = () => {
            status.value = 200;
            if (trainingCourses.ids < 1) {
                toast.error('Please select atleast (1) training course');
                return false;
            }

            props.form.training_ids = trainingCourses.ids;
            emit('saveChanges');
        }

        const changePage = (pageNumber) => {
            page.offSet = pageNumber == 1 ? 0 * 8 : (pageNumber - 1) * 8;
            page.num = pageNumber;
            page.currentPage = pageNumber;
        }

        const prevPage = () => {
            page.num--;
            page.currentPage = page.num;
        }

        const nextPage = () => {
            page.num++;
            page.currentPage = page.num;
        }

        onMounted( async () => {
            getRoles();
            await getAvailableLearningPlans(page);
            page.pages = (totalData.value) ? Math.ceil(totalData.value / 8) : [];
        });

        watchEffect(() => {
            trainingCourses.ids = props.form.training_ids;
            page.offSet = page.num == 1 ? 0 * 8 : (page.num - 1) * 8;
            getAvailableLearningPlans(page);
        });

        return {
            page,
            roles,
            close,
            saveChanges,
            getRoles,
            learning_plans,
            trainingCourses,
            changePage,
            prevPage,
            nextPage,
            totalData,
            status
        }
    }
})
</script>
<style>
    .div-training-checkbox {
        position: relative;
        z-index: 1;
    }
    .cls-training-checkbox {
        position: absolute;
        border-radius: 50%;
        padding: 0.5em;
        width: 20px;
        height: 20px;
        border: 2px solid #c80000;
        color: white;
        background-color: red;
        cursor: pointer;
    }
</style>