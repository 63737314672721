<template>
    <div>
        <Modal :className="`modal-dialog modal-md`" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-12">
                        <BaseInput 
                            v-model="form.fname"
                            label="First Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.fname }"
                            id="fname"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-12">
                        <BaseInput 
                            v-model="form.lname"
                            label="Last Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.lname }"
                            id="lname"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <BaseInput 
                            autocomplete="off"
                            v-model="form.email"
                            label="Email Address"
                            type="email"
                            :class="{ 'is-invalid' : errors && errors.email }"
                            id="email"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="col-12">
                    <BaseSelect
                        v-model="form.role_id"
                        label="Roles"
                        :class="{ 'is-invalid' : errors && errors.role_id }"
                        id="role_id"
                        :errors="errors"
                        is-required
                        :options="role_labels"
                    />
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" :btn-value="`Invite User`" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import roleRepo from '@/repositories/settings/roles';

export default defineComponent({
    props: ['modalActive','form','modalTitle','errors'],
    setup(props, { emit }) {

        const { role, roles, getRoles } = roleRepo();
        const role_labels = ref([]);
        const status = ref('');

        const close = () => {
            emit('close')
        }
        
        const saveChanges = () => {
            status.value = 200;
            emit('saveChanges');
        }

        onMounted( async () => {
            await getRoles();
            roles.value.forEach(item=>{
                if(item.id == role.employer_hr.id) {
                    role_labels.value.push({
                        id: item.id,
                        name: role.employer_hr.label
                    });
                }
                if(item.id == role.employee.id) {
                    role_labels.value.push({
                        id: item.id,
                        name: role.employee.label
                    });
                }
            });
        });

        return {
            roles,
            close,
            role_labels,
            saveChanges,
            getRoles,
            status
        }
    }
})
</script>