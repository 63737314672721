<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">User Logs</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex">
                                <div class="float-start w-20">
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="filter-by">Filter by</span>
                                        </div>
                                        <select id="filter" class="form-select" aria-describedby="filter-by" v-model="filterby" @change="changeFilter">
                                            <option value="1">Super Admin</option>
                                            <option value="2">User Type</option>
                                            <option value="3">Search Keyword</option>
                                        </select>
                                    </div>
                                </div>
                                <div style="margin-left: 10px">
                                    <input class="form-control" type="text" v-model="querysearch" style="width: 300px" placeholder="Search Keyword" @input="searchAdmin" v-if="filterby == 3">
                                    <select id="select" class="form-select" v-model="user_type" style="width: 300px" v-if="filterby == 2" @change="filterByType">
                                        <option value="None">- Please Select Type</option>
                                        <option value="Administrator">Employer/Administrator</option>
                                        <option value="Candidate">Candidate</option>
                                        <option value="Normal User">Normal User</option>
                                    </select>
                                    <select class="form-select" v-model="admin_value" style="width: 300px" v-if="filterby == 1" @change="filterByAdmin">
                                        <option value="None">- Select Super Admin</option>
                                        <option v-for="item in admins" :key="item" :value="item.name">{{ item.name }}</option>
                                    </select>
                                </div>
                                <div class="ms-2">
                                    <button class="btn btn-light" @click="clearFilter()">Clear Filter</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <BaseTable
                                :data="page.admins"
                                :options="headers"
                                :sortableColumns="page.sortableColumns"
                                :isSortAscending="page.isSortAscending"
                                @navigateSort="triggerSort"
                            >
                                <template v-slot:date_end="{ row }">
                                    <span :class="row.date_end == 'Active' ? 'text-success fw-bold' : 'text-normal'">{{ row.date_end }}</span>
                                </template>
                                <template v-slot:actions="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis btn-showlog" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="showLog(row.admin_id, row.user_id, row.name, row.impersonated_user)">Show Log</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`User Access Logs`">
            <div class="row mt-3 mb-3 pl-20">
                <div class="col-6 heading"><strong class="text-dark">Administrator:</strong>&nbsp;&nbsp; {{ page.adminName }}</div>
                <div class="col-6 heading"><strong class="text-dark">Impersonated User:</strong>&nbsp;&nbsp;  {{ page.impersonatedName }}</div>
                <PageLoader class="mt-5" v-if="page.isLogsLoading" />
                <div class="m-3 container-custom" v-else>
                    <div class="row row-header">
                        <div class="col-1"><strong class="text-dark">Log ID</strong></div>
                        <div class="col-3"><strong class="text-dark">Date &amp; Time</strong></div>
                        <div class="col-3"><strong class="text-dark">Type</strong></div>
                        <div class="col-5"><strong class="text-dark">Accessed URL</strong></div>
                    </div>
                    <div class="row row-items" v-for="items in page.logs" :key="items">
                        <div class="col-1">{{ items.id }}</div>
                        <div class="col-3">{{ items.display_datetime }}</div>
                        <div class="col-3">{{ items.type }}</div>
                        <div class="col-5">{{ items.current_url }}</div>
                    </div>
                </div>
            </div>
        </slideout>
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue';
import PageLoader from '@/components/modules/PageLoader.vue';
import BootstrapBaseTable from '@/components/modules/Datatable.vue';
import UserRepo from '@/repositories/admin/users';
import _debounce from 'lodash/debounce';

export default defineComponent({
    setup() {
        const { users, logs, admins, getAdminUsers, showAdminLogs, getAdmins } = UserRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            isLogsLoading: true,
            adminName: '',
            impersonatedName: '',
            logs: [],
            admins: [],
            sortType: 'date_start',
            isSortAscending: true,
            sortableColumns: ['date_start','date_end']
        });
        const visible = ref(false);
        const filterby = ref(1);
        const querysearch = ref('');
        const user_type = ref('None');
        const admin_value = ref('None');

        const headers = [
            {
                key: 'name',
                label: 'Super Admin'
            },
            {
                key: 'impersonated_user',
                label: 'Impersonated User'
            },
            {
                key: 'user_type',
                label: 'User Type'
            },
            {
                key: 'date_start',
                label: 'Date Impersonated'
            },
            {
                key: 'date_end',
                label: 'Impersonation Stopped'
            },
            {
                key: 'actions',
                label: 'Actions',
                class: 'text-center'
            }
        ];

        onMounted( async () => {
            refreshAdminUsers();
            await getAdmins(page);
        });

        const onClosing = (e) => {
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                e.close = true;
                page.adminName = '';
                page.impersonatedName = '';
                logs.value = [];
                page.isLogsLoading = e.close;
            }, 500)
        }

        const showLog = async (admin_id, user_id, admin_name, impersonated_name) => {
            visible.value = true;
            page.adminName = admin_name;
            page.impersonatedName = impersonated_name;
            await showAdminLogs(page, admin_id, user_id);
            page.logs = logs.value.logs;
        }

        const searchAdmin = async () => {
            searchAdmins();
        }

        const searchAdmins = _debounce(async function (event) {
            let searched_data = [];
            if (querysearch.value) {
                users.value.forEach(function(item, index){
                    if( item.impersonated_user.toLowerCase().includes(querysearch.value.toLowerCase()) ){
                        searched_data.push(item);
                    }
                });
                page.admins = searched_data;
            } else {
                refreshAdminUsers();
            }
        }, 500);

        const clearFilter = () => {
            filterby.value = 1
            querysearch.value = ''
            user_type.value = 'None'
            admin_value.value = 'None'
            refreshAdminUsers();
        }

        const triggerSort = (index) => {
            page.sortType = index;
            toggleSortAscending();
            customSort( users.value , page.sortType, page.isSortAscending );
        }

        const toggleSortAscending = () => {
            page.isSortAscending = !page.isSortAscending;
        }

        const customSort = (data, sortColumn = 'date_start', sortAscending = true) => {
            return (sortAscending) ? data.sort((a, b) => {
                let fa = a[sortColumn].toLowerCase(),
                    fb = b[sortColumn].toLowerCase();
                if (fa < fb) { return -1; }
                if (fa > fb) { return 1; }
                return 0;
            }) : data.sort((a, b) => {
                let fa = a[sortColumn].toLowerCase(),
                    fb = b[sortColumn].toLowerCase();
                if (fa > fb) { return -1; }
                if (fa < fb) { return 1; }
                return 0;
            });
        }

        const changeFilter = async () => {
            querysearch.value = ''
            user_type.value = 'None'
            admin_value.value = 'None'
            refreshAdminUsers();
        }

        const filterByType = async () => {
            let filtered_data = [];
            if (user_type.value != 'None') {
                users.value.forEach(function(item, index){
                    if( item.user_type.toLowerCase().includes(user_type.value.toLowerCase()) ){
                        filtered_data.push(item);
                    }
                });
                page.admins = filtered_data;
            } else {
                refreshAdminUsers();
            }
        }

        const filterByAdmin = async () => {
            let filtered_data = [];
            if (admin_value.value != 'None') {
                users.value.forEach(function(item, index){
                    if( item.name.toLowerCase().includes(admin_value.value.toLowerCase()) ){
                        filtered_data.push(item);
                    }
                });
                page.admins = filtered_data;
            } else {
                refreshAdminUsers();
            }
        }

        const refreshAdminUsers = async () => {
            await getAdminUsers(page);
            page.admins = users.value;
        }

        return {
            headers,
            page,
            users,
            logs,
            admins,
            getAdmins,
            getAdminUsers,
            showAdminLogs,
            visible,
            onClosing,
            showLog,
            filterby,
            querysearch,
            user_type,
            searchAdmin,
            clearFilter,
            triggerSort,
            changeFilter,
            filterByType,
            filterByAdmin,
            admin_value
        }
    },
    components: {
        PageLoader,
        BootstrapBaseTable
    }
});
</script>
<style scoped>
.heading {
    font-size: 17px;
}
.row-header {
    padding: 5px 0;
    background: #cecece;
}
.row-items {
    padding: 12px 0;
}
.row-items:nth-child(odd) {
    background:#e5e5e5;
}
.row-items:nth-child(even) {
    background:#fbfbfb;
}
.container-custom {
    max-width: 865px;
}
</style>