<template>
  <main class="content">
    <div class="container-fluid p-0">
      <h1 class="h3 mb-3">Settings</h1>
      <div class="row">
        <div class="col-md-3 col-xl-2">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Profile Settings</h5>
            </div>

            <div class="list-group list-group-flush" role="tablist">
              <a class="list-group-item list-group-item-action list-account" :class="{ 'active': currentComponent == 'Account' }"
                data-bs-toggle="list" href="javascript:;" @click="chooseTab('Account')" role="tab">
                Account
              </a>
              <a class="list-group-item list-group-item-action list-password" :class="{ 'active': currentComponent == 'Password' }"
                data-bs-toggle="list" href="javascript:;" @click="chooseTab('Password')" role="tab">
                Password
              </a>
              <a v-if="page.authuser.role_id === 2 || page.authuser.role_id === 3" class="list-group-item list-group-item-action list-company"
                :class="{ 'active': currentComponent == 'Company' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('Company')" role="tab">
                Company Settings
              </a>
              <a v-if="page.authuser.role_id === 2" class="list-group-item list-group-item-action list-billing"
                :class="{ 'active': currentComponent == 'Billing' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('Billing')" role="tab">
                Subscriptions and Billing
              </a>
              <a v-if="page.authuser.role_id === 2 || page.authuser.role_id === 3" class="list-group-item list-group-item-action list-notif"
                :class="{ 'active': currentComponent == 'Notification' }" data-bs-toggle="list" href="javascript:;"
                @click="chooseTab('Notification')" role="tab">
                Notification &amp; Reminders
              </a>
              <button v-show="!page.isImpersonateOn && page.authuser.role_id != 3" class="list-group-item list-group-item-action text-danger" style="color: #fff"
                @click="deleteAccount">
                Deactivate account
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-9 col-xl-10">
          <div class="tab-content">
            <component :is="currentComponent" @refreshPage="refreshPage"></component>
          </div>
        </div>
        <div class="tab-spinner position-fixed" v-if="page.isFullScreenLoading"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <Confirm :is-confirm="isConfirm"
        :confirm-text="`Are you sure you want to deactivate your account? Once deactivated, you will logout and no longer be able to login or retrieve any of your information.`"
        @cancel="toggleConfirmation" @isDelete="destroyAccount" />
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import userRepo from '@/repositories/settings/users';
import authRepo from '@/repositories/auth';
import Account from '@/views/employer/settings/config/tabs/Account.vue';
import Password from '@/views/employer/settings/config/tabs/Password.vue';
import Company from '@/views/employer/settings/config/tabs/Company.vue';
import Billing from '@/views/employer/settings/config/tabs/Billing.vue';
import PaymentMethod from '@/views/employer/settings/config/billing/PaymentMethod.vue';
import Notification from '@/views/employer/settings/config/tabs/Notification.vue';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { signOutUser } = authRepo();
    const { destroyUser } = userRepo();
    const currentComponent = ref('Account');
    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      isFullScreenLoading: false,
      isImpersonateOn: false
    });
    const displayPaymentMethod = ref(false);
    const isConfirm = ref(false);
    const toggleConfirmation = () => {
      isConfirm.value = !isConfirm.value;
    }

    onMounted(() => {
      let admintoken = localStorage.getItem('admintoken');
      page.isImpersonateOn = (admintoken !== null);
      let url = /[^/]*$/.exec(router.currentRoute.value.fullPath)[0];

      if (url == 'company-tab') {
        currentComponent.value = 'Company';
      }

      if (url == 'billing' || route.query.tab == 'billings') {
        currentComponent.value = 'Billing';
      }
    })

    const deleteAccount = () => {
      toggleConfirmation();
    }

    const destroyAccount = async () => {
      toggleConfirmation();
      page.isFullScreenLoading = true;
      await destroyUser(page.authuser.id);
      signOutUser(page.authuser, 'employer');
    }

    const chooseTab = (tab) => {
      if (tab == 'Billing') {
        router.push(`/employer/settings/config/billing`);
      }
      currentComponent.value = tab;
    }

    const switchPassword = () => {
      inputType.value = (inputType.value == 'password') ? 'text' : 'password';
      inputIcon.value = (inputIcon.value == 'fa far fa-eye') ? 'fas fa-eye-slash' : 'fa far fa-eye';
    }

    const refreshPage = () => {
      displayPaymentMethod.value = true;
    }

    watchEffect(() => {
      displayPaymentMethod.value = (page.authuser.default_payment_method !== null);
      if(route.path == '/employer/settings/config/company-tab') {
        currentComponent.value = 'Company';
      }
    });

    return {
      page,
      deleteAccount,
      destroyAccount,
      destroyUser,
      currentComponent,
      chooseTab,
      isConfirm,
      toggleConfirmation,
      refreshPage,
      displayPaymentMethod
    }
  },
  components: {
    Account,
    Password,
    Company,
    Billing,
    PaymentMethod,
    Notification
  }
})
</script>
<style scoped>
.tab-spinner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 20%;
    background: #e3e3e3bf;
    text-align: center;
    font-size: 40px;
    color: #3f80ea;
    z-index: 100000;
}
</style>