<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-header px-0 pb-4">
                            <div class="d-flex justify-content-between">
                                <div><h3>Basic Learning Plans</h3></div>
                            </div>
                        </div>
                        {{learning_plans_filtered.length}} results found.
                        <PageLoader v-if="page.isLoading" />
                        <div v-else>
                            <div class="accordion" id="accordionExample" v-if="learning_plans_filtered.length > 0">
                                <div class="accordion-item" v-for="(learning, index) in learning_plans_filtered" :key="index">
                                    <h2 class="accordion-header" :id="`headingOne${learning.learning_plan_id}`">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne${learning.learning_plan_id}`" aria-expanded="true" :aria-controls="`collapseOne${learning.learning_plan_id}`">
                                        {{learning.learning_plan_name}}
                                    </button>
                                    </h2>
                                    <div :id="`collapseOne${learning.learning_plan_id}`" class="accordion-collapse collapse" :aria-labelledby="`headingOne${learning.learning_plan_id}`">
                                        <div class="accordion-body">
                                            <div class="w-100 text-end">
                                                <div v-if="page.authuser.role_id == 1"> 
                                                    <router-link v-if="(learning.courses_id.length > 0)" :to="{ path: '/admin/learning-plan/update/'+learning.learning_plan_id}" class="btn btn-primary btn-sm mb-2">Edit</router-link>
                                                    <button v-else class="btn btn-primary btn-sm mb-2" @click="EditLearningPlan(learning.learning_plan_id)">Edit</button>
                                                </div>
                                                <button v-if="page.authuser.role_id == 1" class="btn btn-danger btn-sm mb-2 ms-1" @click="removeLearningplan(learning.learning_plan_id)">Delete</button>
                                            </div>

                                            <h5>Course Progress</h5>
                                            <div v-if="learning.courses_structure" class="progress course-progress mb-2">
                                                <div class="progress-bar bg-success" role="progressbar" 
                                                :style="learningCourseProgress(learning.courses_related, learning.learning_progress).cssCompleted" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.courses_related, learning.learning_progress).labelCompleted}} 
                                                </div>
                                                <div class="progress-bar bg-warning" role="progressbar" 
                                                :style="learningCourseProgress(learning.courses_related, learning.learning_progress).cssProgress" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.courses_related, learning.learning_progress).labelProgress}} 
                                                </div>
                                            </div>
                                            <div v-else class="progress course-progress mb-2">
                                                <div class="progress-bar bg-success" role="progressbar" 
                                                :style="learningCourseProgress(learning.trainings, learning.learning_progress).cssCompleted" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.trainings, learning.learning_progress).labelCompleted}} 
                                                </div>
                                                <div class="progress-bar bg-warning" role="progressbar" 
                                                :style="learningCourseProgress(learning.trainings, learning.learning_progress).cssProgress" 
                                                aria-valuenow="30" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                    {{learningCourseProgress(learning.trainings, learning.learning_progress).labelProgress}} 
                                                </div>
                                            </div>

                                            <!-- No Sections -->
                                            <ul class="list-group" v-if="learning.trainings.length > 0">
                                                <li class="list-group-item" v-for="(training, index) in learning.trainings" :key="index">
                                                    <div class="float-start" style="width: 90%;">
                                                        <h5><a class="text-decoration-none" target="_blank" :href="training.link">{{training.title}}</a>
                                                            <span class="badge bg-success text-white ms-2" v-if="trainingStatus(training.id, learning.learning_progress)?.status == 'completed'">Completed</span>
                                                            <span class="badge bg-warning text-white ms-2" v-if="trainingStatus(training.id, learning.learning_progress)?.status == 'progress'">In progress</span>
                                                        </h5>
                                                        
                                                        <small class="me-2">{{ training.author ?? training.company?.company_name }}</small>
                                                        <small class="text-muted me-2">{{ training.source?.name ?? 'Private Course' }}</small>
                                                        <small class="text-muted me-2">{{training.skill_type}}</small>
                                                    </div>

                                                    <div class="card-btn-actions float-end text-end" style="width: auto;">
                                                        <div class="dropdown position-relative">
                                                            <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-more-horizontal align-middle"
                                                                >
                                                                    <circle cx="12" cy="12" r="1"></circle>
                                                                    <circle cx="19" cy="12" r="1"></circle>
                                                                    <circle cx="5" cy="12" r="1"></circle>
                                                                </svg>
                                                            </a>

                                                            <div class="dropdown-menu dropdown-menu-end">
                                                                <a class="dropdown-item" href="javascript:;" 
                                                                    @click="updateCourseStatus(learning.learning_plan_id, training.id, 'completed')">
                                                                    Mark as Complete
                                                                </a>
                                                                <a class="dropdown-item" href="javascript:;" 
                                                                    @click="updateCourseStatus(learning.learning_plan_id, training.id, 'progress')">
                                                                    Mark as In progress
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                            <!-- With Sections -->
                                            <div v-if="learning.courses_structure" class=""> 
                                                <div class="">
                                                    <div v-for="(section, i) in learning.courses_structure" :key="i" class="mt-3">
                                                        <div>
                                                            <h4 class="mb-1">{{section.section}}</h4>
                                                        </div>
                                                        <ul class="list-group" v-if="section.courses.length > 0">
                                                            <li class="list-group-item" v-for="(course, i) in section.courses" :key="i">
                                                                <div class="float-start" style="width: 90%;">
                                                                    <h5><a class="text-decoration-none" target="_blank" :href="courseInfo(course.id,learning.courses_related)[0]?.link">{{course.title}}</a>
                                                                        <span class="badge bg-success text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'completed'">Completed</span>
                                                                        <span class="badge bg-warning text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'progress'">In progress</span>
                                                                    </h5>

                                                                    <small class="me-2">{{ course.author ?? course.company?.company_name }}</small>
                                                                    <small class="te`xt-muted me-2">{{ course.source?.name ?? 'Private Course' }}</small>
                                                                    <small class="text-muted me-2">{{course.skill_type}}</small>`
                                                                </div>
                                                                
                                                                <div class="card-btn-actions float-end text-end" style="width: auto;">
                                                                    <div class="dropdown position-relative">
                                                                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="feather feather-more-horizontal align-middle"
                                                                            >
                                                                                <circle cx="12" cy="12" r="1"></circle>
                                                                                <circle cx="19" cy="12" r="1"></circle>
                                                                                <circle cx="5" cy="12" r="1"></circle>
                                                                            </svg>
                                                                        </a>

                                                                        <div class="dropdown-menu dropdown-menu-end">
                                                                            <a class="dropdown-item" href="javascript:;" 
                                                                                @click="updateCourseStatus(learning.learning_plan_id, course.id, 'completed')">
                                                                                Mark as Complete
                                                                            </a>
                                                                            <a class="dropdown-item" href="javascript:;" 
                                                                                @click="updateCourseStatus(learning.learning_plan_id, course.id, 'progress')">
                                                                                Mark as In progress
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                        <div class="ms-5 mt-2" v-if="section.sub.length > 0"> 
                                                            <div v-for="(subsection, i) in section.sub" :key="i"> 
                                                                <div>
                                                                    <h4 class="mb-1">{{subsection.section}}</h4>
                                                                </div>
                                                                <ul class="list-group" v-if="subsection.courses.length > 0">
                                                                    <li class="list-group-item" v-for="(course, i) in subsection.courses" :key="i">
                                                                        <div class="float-start" style="width: 90%;">
                                                                            <h5 class="mb-1"><a class="text-decoration-none" target="_blank" :href="courseInfo(course.id,learning.courses_related)[0]?.link">{{course.title}}</a>
                                                                                <span class="badge bg-success text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'completed'">Completed</span>
                                                                                <span class="badge bg-warning text-white ms-2" v-if="trainingStatus(course.id, learning.learning_progress)?.status == 'progress'">In progress</span>
                                                                            </h5>

                                                                            <small class="me-2">{{ course.author ?? course.company?.company_name }}</small>
                                                                            <small class="te`xt-muted me-2">{{ course.source?.name ?? 'Private Course' }}</small>
                                                                            <small class="text-muted me-2">{{course.skill_type}}</small>
                                                                        </div>

                                                                        <div class="card-btn-actions float-end text-end" style="width: auto;">
                                                                            <div class="dropdown position-relative">
                                                                                <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        stroke-width="2"
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                        class="feather feather-more-horizontal align-middle"
                                                                                    >
                                                                                        <circle cx="12" cy="12" r="1"></circle>
                                                                                        <circle cx="19" cy="12" r="1"></circle>
                                                                                        <circle cx="5" cy="12" r="1"></circle>
                                                                                    </svg>
                                                                                </a>
                                                                                <div class="dropdown-menu dropdown-menu-end">
                                                                                    <a class="dropdown-item" href="javascript:;" 
                                                                                        @click="updateCourseStatus(learning.learning_plan_id, course.id, 'completed')">
                                                                                        Mark as Complete
                                                                                    </a>
                                                                                    <a class="dropdown-item" href="javascript:;" 
                                                                                        @click="updateCourseStatus(learning.learning_plan_id, course.id, 'progress')">
                                                                                        Mark as In progress
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End of accordion layout -->

                            <div class="row mt-5" v-else>
                                <div class="text-center">
                                    <img src="/assets/images/empty.png" class="img-fluid img-30">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalLearningPlan 
            :modalActive="modalActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @reloadPage="save"
        />
        <ModalAssignLearningPlan 
            :modalActive="modalLearningPlanActive"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            @close="toggleLearningPlanModal"
            @reloadPage="saveLearningPlan"
        />
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this custom learning plan? All employees enrolled in private courses associated with this learning plan will be unenrolled.`"
            @cancel="toggleConfirmation"
            @isDelete="processDelete"
        />
    </div>
</template>

<script>
import { ref, reactive, computed } from '@vue/reactivity';
import trainingRepo from '@/repositories/employer/trainings';
import ModalLearningPlan from '@/views/employer/trainings/modals/ModalLearningPlan.vue';
import ModalAssignLearningPlan from '@/views/employer/trainings/modals/ModalAssignLearningPlan.vue';
import { onMounted } from '@vue/runtime-core';
import learningPlanRepo from '@/repositories/admin/learningplans';

export default {
    setup() {
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Add Custom Learning Plan',
            idToDelete: ''
        });
        const { learning_plan, getCustomLearningPlan, destroyCustomLearningPlan, status, updateLearningPlanProgress } = trainingRepo();
        const { learning_plans, getCustomLearningPlans } = learningPlanRepo();
        const modalActive = ref(false);
        const modalLearningPlanActive = ref(false);
        const isConfirm = ref(false);

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleLearningPlanModal = () => {
            modalLearningPlanActive.value = !modalLearningPlanActive.value;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const save = async () => {
            toggleModal();
            await getCustomLearningPlans(page);
        }

        const saveLearningPlan = () => {
            toggleLearningPlanModal();
        }

        const createCustomLearningPlan = () => {
            toggleModal();
        }

        const updateCustomLearningPlan = async (id) => {
            toggleModal();
            await getCustomLearningPlan(id);
            page.modalHeader = 'Update Custom Learning Plan';
        }

        const deleteCustomLearningPlan = (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const processDelete = async () => {
            await destroyCustomLearningPlan(page);
            await getCustomLearningPlans(page);
            if(status.value == 200) {
                toggleConfirmation();
            }
        }

        const assignCustomLearningPlan = async (id) => {
            await getCustomLearningPlan(id);
            page.modalHeader = 'Assign Custom Learning Plan to Employee(s)';
            toggleLearningPlanModal();
        }

        onMounted( async () => {
            await getCustomLearningPlans(page);
            document.getElementById("training-title-header").innerHTML = 'Learning Plans';
        });

        const updateCourseStatus = async (learningID, trainingID, type) => {
            let formData = new FormData();
            formData.append('user_id', page.authuser.id);
            formData.append('learning_id', learningID);
            formData.append('training_id', trainingID);
            formData.append('type', type);
            await updateLearningPlanProgress(formData)
            await getCustomLearningPlans(page);
        }

        const trainingStatus = (trainingID, learning_progress) => {
            const found = learning_progress.filter( data => data.training_id == trainingID)[0]
            return found
        }

        const courseProgress = (learningID) => {
            let totalCourse = 0
            let totalCompleted = 0
            learning_plans.value.map( data => {
                if (data.learning_plan_id == learningID){
                    let trainingCourse = data.completed_trainings ? JSON.parse(data.completed_trainings):[];
                    totalCompleted = trainingCourse.length
                    totalCourse = data.trainings.length
                }
            })
            const perc = ((totalCompleted/totalCourse) * 100).toFixed()
            return {
                css:'width:'+`${perc}`+'%;',
                label: `${perc}`+'%'
            }
        }

        const learningCourseProgress = (trainings, progress) => {
            let totalTrainings = trainings.length
            let completedCnt = 0
            let progressCnt = 0
            progress.map( data => {
                if(data.status == 'completed'){
                    completedCnt++
                }else if(data.status == 'progress')(
                    progressCnt++
                )
            })
            const completed = ((completedCnt/totalTrainings) * 100).toFixed(2)
            const inprogress = ((progressCnt/totalTrainings) * 100).toFixed(2)
            return {
                percProgress: ((progressCnt/totalTrainings) * 100).toFixed(2),
                percCompleted: ((completedCnt/totalTrainings) * 100).toFixed(2),
                cssCompleted: 'width:'+`${completed}`+'%;',
                labelCompleted: `${completed}`+'%',
                cssProgress: 'width:'+`${inprogress}`+'%;',
                labelProgress: `${inprogress}`+'%'
            }
        }

        const courseInfo = (id, courses) => {
            return courses.filter(course => course.id === id)
        }

        const learning_plans_filtered = computed(()=> {
            return learning_plans.value.filter(learning => learning.trainings.length > 0 || learning.courses_related.length > 0);
        })

        return {
            page,
            modalActive,
            modalLearningPlanActive,
            isConfirm,
            toggleConfirmation,
            toggleModal,
            toggleLearningPlanModal,
            learning_plan,
            save,
            saveLearningPlan,
            createCustomLearningPlan,
            updateCustomLearningPlan,
            deleteCustomLearningPlan,
            learning_plans,
            getCustomLearningPlans,
            getCustomLearningPlan,
            destroyCustomLearningPlan,
            assignCustomLearningPlan,
            processDelete,
            status,
            updateCourseStatus,
            trainingStatus,
            courseProgress,
            learningCourseProgress,
            courseInfo,
            learning_plans_filtered
        }
    },
    components: {
        ModalLearningPlan,
        ModalAssignLearningPlan
    }
}
</script>

<style scoped>
.card-wrap {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
}
.card-border {
    border: 1px solid #ccc !important;
}
.course-progress { 
    width: 100%; 
    border-radius: 10px!important;
    font-size: 11px!important;
    height: 25px;
}
.course-card { display: inline-block; width: 240px; }
.shadow {
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.05)!important;
}
.card-header-items {
    height: 105px;
}
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.feather {
    width: 24px !important;
    height: 24px !important;
    padding: 0 3px;
}
.training-card {
    height: 140px;
}
</style>
