<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <BaseTable
                            :data="trainings"
                            :options="headers"
                            :id="''"
                        >
                            <template v-slot:course="{ row }">
                                <div class="text-bold">
                                    <a :href="row.course_data?.link" target="_blank">{{ row.course }}</a>
                                </div>
                                <div v-html="row.course_data.display_learning_plans"></div>
                            </template>
                            <template v-slot:status="{ row }">
                                <div class="d-grid gap-2 col-8 mx-auto">
                                    <div class="badge bg-secondary my-2" v-if="row.status == 'Enrolled'">{{row.status}}</div>
                                    <div class="badge bg-warning my-2" v-if="row.status == 'In Progress'">{{row.status}}</div>
                                    <div class="badge bg-success my-2" v-if="row.status == 'Completed'">{{row.status}}</div>
                                </div>
                            </template>
                            <template v-slot:action="{ row }">
                                <div class="gap-2 col-8 mx-auto">
                                    <button class="btn btn-outline-primary btn-sm btn-action" v-if="row.status == 'Enrolled'" @click="insertTrainingCourse(row.id, row.training_id)">Start</button>
                                    <button class="btn btn-outline-success btn-sm btn-action" v-else-if="row.status == 'In Progress'" @click="updateTrainingCourse(row.id)">Mark as Completed</button>
                                </div>
                            </template>
                        </BaseTable>
                    </div>
                </div>
            </div>
        </div>
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="page.confirmText"
            @cancel="toggleConfirmation"
            @isDelete="processComplete"
        />
    </div>
</template>

<script>
import { onMounted, reactive, ref, watchEffect } from 'vue';
import trainingRepo from '@/repositories/candidate/trainings.js';

export default {
    props: ['query_search','query_filter','query_learningplan'],
    setup(props) {
        const { trainings, getEnrolledTrainings, updateCourse, status } = trainingRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            idToUpdate: '',
            confirmText: '',
            action: '',
            training_id: ''
        });
        const isConfirm = ref(false);
        const originalData = ref([]);

        const headers = [
            {
                key: 'course',
                label: 'Training Course'
            },
            {
                key: 'date_started_display',
                label: 'Date Started'
            },
            {
                key: 'date_completed_display',
                label: 'Date Completed'
            },
            {
                key: 'updated_at_display',
                label: 'Last Modified'
            },
            {
                key: 'status',
                label: 'Status',
                class: 'text-center'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const insertTrainingCourse = (id, training_id) => {
            page.action = 2;
            page.idToUpdate = id;
            page.training_id = training_id;
            page.confirmText = 'Are you sure you want to start this course?';
            toggleConfirmation();
        }

        const updateTrainingCourse = (id) => {
            page.action = 3;
            page.idToUpdate = id;
            page.training_id = '';
            page.confirmText = 'Are you sure you want to complete this course?';
            toggleConfirmation();
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const processComplete = async () => {
            let formData = new FormData();
            formData.append('id', page.idToUpdate);
            formData.append('user_id', page.authuser.id);
            formData.append('action', page.action);
            if(page.training_id) {
                formData.append('training_id', page.training_id);
            }

            await updateCourse(formData);
            if(status.value === 200) {
                getEnrolledTrainings(page);
                toggleConfirmation();
            }
        }

        watchEffect(async ()=> {
            let filtered_data = [];
            let learningplan_data = [];

            if(props.query_filter && !props.query_search) {
                filtered_data = [];
                originalData.value.forEach((item)=> {
                    if(item.status.toLowerCase().includes(props.query_filter.toLowerCase())) {
                        filtered_data.push(item);
                    }
                });
                trainings.value = filtered_data;
            }

            if(props.query_learningplan && !props.query_search) {
                learningplan_data = [];
                originalData.value.forEach((item)=> {
                    if(item.course_data.learning_plans.find(o => o.id === props.query_learningplan)) {
                        learningplan_data.push(item);
                    }
                });
                trainings.value = learningplan_data;
            }
        });

        onMounted(async () => {
            await getEnrolledTrainings(page);
            originalData.value = trainings.value;
            let searched_data = [];

            if(props.query_search) {
                searched_data = [];
                originalData.value.forEach((item)=> {
                    if(item.course.toLowerCase().includes(props.query_search.toLowerCase())) {
                        searched_data.push(item);
                    }
                });
                trainings.value = searched_data;
            } else {
                trainings.value = originalData.value;
            }
            document.getElementById("training-title-header").innerHTML = 'Training Courses';
        });

        return {
            page,
            headers,
            trainings,
            getEnrolledTrainings,
            insertTrainingCourse,
            updateTrainingCourse,
            isConfirm,
            toggleConfirmation,
            processComplete,
            updateCourse,
            status
        }
    },
}
</script>
<style scoped>
.btn-action { width: 140px; }
</style>