<template>
    <div>
        <div class="row no-margin questions" v-if="questions.length">
            <div class="col-12">
                <div class="mb-3">
                    <div class="form-group" v-for="question in questions" :key="question.id">
                        <div class="text-muted">
                            {{ question.question }} <span class="text-danger">*</span>
                        </div>
                        <div v-if="!question.preferred_answer">
                            <BaseTextarea
                                v-model="question.candidate_answer"
                                :id="`question-${question.id}`"
                                placeholder="Insert your answer"
                                :class="{ 'invalid': !pass && question.candidate_answer == null }"
                            />
                            <div class="mb-3" style="margin-top: -15px;">
                                <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" style="display: inline-block !important;width: auto;" v-if="!pass && question.candidate_answer == null">Please provide your answer.</label>&nbsp;
                            </div>
                        </div>
                        <div v-else>
                            <BaseInput 
                                v-model="question.candidate_answer"
                                type="number"
                                :id="`question-${question.id}`"
                                placeholder="Place your answer here"
                                :class="{ 'invalid': !pass && question.candidate_answer == null }"
                            />
                            <div class="mb-3" style="margin-top: -15px;">
                                <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" style="display: inline-block !important;width: auto;" v-if="!pass && question.candidate_answer == null">Please provide your answer.</label>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3" v-else>
            <h4>No available Pre-Screening Questions</h4>
        </div>
    </div>
</template>

<script>
import { defineComponent, watchEffect, ref } from 'vue';
import BaseTextarea from '@/components/modules/form/BaseTextarea.vue';
import BaseInput from '@/components/modules/form/BaseInput.vue';

export default defineComponent({
    props: {
        job_id: {
            type: [Number, String],
            required: true
        },
        data: {
            type: Object
        },
        clickNext: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const questions = ref([]);
        const status = ref('');
        const pass = ref(true);

        watchEffect(() => {
            questions.value = props.data?.job?.screening ?? [];
        })

        const onUpdate = () => {
            status.value = '';
            questions.value.forEach( item => {
                if(item?.candidate_answer === null){
                    pass.value = false;
                } else {
                    pass.value = true;
                }
            });
            if(pass.value) {
                emit('candidateQuestions', questions.value);
                setTimeout(() => {
                    status.value = 200;
                }, 800);
            } else {
                status.value = 200;
            }
        }

        watchEffect(() => {
            questions.value = props.data?.job?.screening ?? [];
            emit('watchTextfield', questions.value);
            if(props.clickNext && questions.value.length) {
                onUpdate();
            }
        });

        return {
            status,
            questions,
            onUpdate,
            pass
        }
    },
    components: {
        BaseTextarea,
        BaseInput
    }
})
</script>

<style>
.no-margin {
    margin: 0px !important;
}
div.questions .invalid textarea {
    border: 1px solid #d9534f;
}
</style>