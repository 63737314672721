<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between mb-3">
                <h1 class="h3 mb-3">Manage Jobs Postings</h1>
                <div class="d-flex align-items-center" v-if="state.authuser.role_id === 2 || state.authuser.role_id === 3">
                    <button class="btn btn-primary text-white" @click="showPaidOptions" v-if="state.isExceedJobPost">Post New Job</button>
                    <button class="btn btn-primary text-white" @click="createJob" v-else>Post New Job</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex w-100">
                                    <div class="w-35">
                                        <div class="input-group">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="filter-by">Filter by</span>
                                            </div>
                                            <select id="filter" class="form-select" aria-describedby="filter-by" v-model="filterby" @change="filterChange">
                                                <option value="1">Position Title</option>
                                                <option value="2">Job Type</option>
                                                <option value="3">Location</option>
                                                <option value="4">Promoted Jobs</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="w-25" style="margin-left: 10px" v-if="filterby == 1">
                                        <input class="form-control" type="text" v-model="querysearch" style="width: 100%" placeholder="Search Position Title or keywords" @input="searching" v-if="filterby == 1">
                                    </div>
                                    <div class="w-25" style="margin-left: 10px" v-if="filterby == 2">
                                        <select class="form-select" v-model="job_type" v-if="filterby == 2" @change="filterJobTypeChange">
                                            <option v-for="job_type in job_types" :key="job_type.name" :value="job_type.name">{{ job_type.name }}</option>
                                        </select>
                                    </div>
                                    <div class="w-25" style="margin-left: 10px" v-if="filterby == 3">
                                        <select class="form-select" v-model="country_id" v-if="filterby == 3" @change="filterCountryChange">
                                            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.nicename }}</option>
                                        </select>
                                    </div>
                                    <div class="w-25 ms-2" v-if="hasToClear">
                                        <button class="btn btn-light" @click="clearFilter()">Clear Filter</button>
                                    </div>
                                </div>
                                <div class="pt-1 jobs-counter">
                                    {{ jobs ? jobs.length : 0 }} Active Job Post(s)
                                    <span class="promo-credits" v-if="company.account_type == 'Pro' || company.account_type == 'Premium'">
                                        <span class="text-primary">{{ state.promoteCredits }}</span> promote credits
                                    </span>
                                    <div id="monthly-credits" v-if="totalJobPost !== -1">
                                        <span><span class="text-primary">{{ totalJobPost - activeJobPostCount }} out of {{ totalJobPost }}</span> active job posts left</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <BaseTable
                                :data="jobs"
                                :options="headers"
                            >
                                <template v-slot:position_title="{ row }">
                                    <a class="text-secondary" href="javascript:;" @click="showDetails(row.id, row.candidate_applications.length, row.candidate_applications)">{{ row.position_title }}</a>
                                    <div v-html="row.display_keywords"></div>
                                    <span v-if="row.promote == 1" class="badge promoted-badge">Promoted until {{ row.promote_until }}</span>
                                </template>
                                <template v-slot:job_order_number="{ row }">
                                    <div style="width:110px;">{{ row.job_order_number }}</div>
                                </template>
                                <template v-slot:status_display="{ row }">
                                    <div v-html="row.status_display"></div>
                                </template>
                                <template v-slot:action="{ row }" class="text-center">
                                    <div>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="showDetails(row.id, row.candidate_applications.length, row.candidate_applications)">View</a></li>
                                                <li><a class="dropdown-item" href="javascript:;" :class="row.is_expired ? 'disabled' : ''" @click="updateJob(row.id)">Edit</a></li>
                                                <li v-if="!row.is_expired">
                                                    <a class="dropdown-item" href="javascript:;" v-if="row.status == 'Active'" @click="editJobStatus(row.id)">Mark as Inactive</a>
                                                    <a class="dropdown-item" href="javascript:;" v-else @click="editJobStatus(row.id)">Mark as Active</a>
                                                </li>
                                                <li :class="row.candidate_applications.length > 0 ? 'custom_tooltip' : ''" data-tooltip="This Job post cannot be deleted because it has active applicants."><a class="dropdown-item text-danger" :class="row.candidate_applications.length > 0 ? 'disabled' : ''" href="javascript:;" @click="deleteJob(row.id)">Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay position-fixed full-screen-loader text-primary" v-if="state.isfullScreenLoading || !state.isDataLoaded">
            <i class="fa fa-spin fa-spinner fa-3x"></i>
        </div>
        <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`View Job Details`">
            <div class="mt-3 mb-3 px-3">
                <div class="row mt-4 mx-2">
                    <div class="col-12" v-if="job">
                        <span class="text-sm">POSITION TITLE</span>
                        <h3>{{ job.position_title }} <span class="float-end"><button :disabled="state.applicationCount === 0" @click="showApplicants" class="btn btn-primary" style="margin-top: -30px;">Current Applicants ({{ state.applicationCount ?? 0 }})</button></span></h3>
                        <hr />
                        <div class="mb-3 mt-3">
                            <div class="mb-1" v-if="job.language"><b>Language: </b> <span class="badge bg-success" style="margin-right:3px;" v-for="language in job.languages" v-bind:key="language">{{ language.name ?? '' }}</span></div>
                            <div class="mb-1"><b>Job Type:</b> <span class="text-info">{{ job.job_type }}</span></div>
                            <div class="mb-1"><b>Location:</b> <span class="text-info">{{ job.city }} {{ job.state }}</span></div>
                            <div class="mb-1" v-if="job.location_role_id"><b>Location Role:</b> <span class="text-info">{{ location_roles.find(x => x.id === job.location_role_id).name }}</span></div>
                            <div class="mb-1" v-if="job.schedules"><b>Schedules:</b> <span class="text-info">{{ getSchedules() }}</span></div>
                            <div class="mb-1" v-if="job.job_time_frame"><b>Time Frame:</b> <span class="text-info">{{ time_frames.find(x => x.id === job.job_time_frame).name }}</span></div>
                        </div>
                        <h5>Job Description</h5>
                        <div class="mb-3" v-html="job.job_description"></div>
                        <h5>Job Benefits</h5>
                        <div v-html="job.job_benefits" v-if="job.job_benefits"></div>
                        <div v-else>Not Listed.</div>
                    </div>
                </div>
            </div>
        </slideout>
        <ModalApplicants
            :modalActive="modalApplicantsActive"
            :data="state.applicationList"
            @close="toggleModalApplicants"
        />
        <ModalJob
            :modalActive="modalActive"
            :form="job"
            :modalTitle="state.modalHeader"
            :promotedJobAvailable="state.promoteCredits"
            :promoteJobSwitch="jobPromotionBoostActive"
            @close="cancelEditConfirmation"
            @refresh-page="refreshPage"
            @toggleModalSubscription="toggleModalSubscription"
            @emit-handleSwitch="emitHandleSwitch"
        />
        <ModalSubscription
            :modalActive="subscriptionActive"
            :userAccount="state.userAccount"
            :fromJobPosting="true"
            @render-jobposting="renderJobPosting"
        />
        <ModalJobPromotionBoost
            :modalActive="jobPromotionBoostActive"
            :modalHeaderContent="state.modalHeaderContent"
            @closeBoost="refreshJobBoost"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this job posting?.`"
            @cancel="toggleConfirmation"
            @isDelete="removeJob"
        />
        <Confirm
            class="cancel-edit-confirmation"
            :is-confirm="isCancelEditConfirm"
            :confirm-text="`You will lose unsaved changes. Are you sure you want to leave this page?`"
            @cancel="toggleCancelEditConfirmation"
            @isDelete="closeModal"
        />
    </main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch, computed } from 'vue';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import globalRepo from '@/repositories/global';
import jobRepo from '@/repositories/jobs';
import billingRepo from '@/repositories/employer/billing';
import userRepo from '@/repositories/settings/users';
import jobPromotionBoostRepo from '@/repositories/employer/jobPromotionBoost';
import ModalJob from '@/views/employer/jobs/ModalJob.vue';
import ModalApplicants from '@/views/employer/jobs/ModalApplicants.vue';
import ModalSubscription from '@/views/employer/ModalSubscription.vue';
import ModalJobPromotionBoost from '@/views/employer/jobs/ModalJobPromotionBoost.vue';

export default defineComponent({
    components: {
        ModalJob,
        ModalSubscription,
        ModalApplicants,
        ModalJobPromotionBoost
    },
    setup() {
        const state = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Post New Job Openings',
            modalHeaderContent: 'Do you need more promoted job posts? Please avail the boost below:',
            idToDelete: '',
            hasEditedFields: false,
            promotedJobPostCount: 0,
            isExceedJobPost: false,
            isExceedPromotedJob: false,
            isExceedPromotedJobPremiumUser: false,
            isfullScreenLoading: false,
            isActivePromoteButton: false,
            isPremiumUser: false,
            userAccount: '',
            totalPromotedJob: 0,
            isDataLoaded: false,
            postCredits: '',
            applicationCount: 0,
            applicationList: [],
            promoteCredits: 0,
            promoteBoostCredits: 0
        });
        const { activeSubscription, subscription } = billingRepo();
        const modalApplicantsActive = ref(false);
        const jobPromotionBoostActive = ref(false);
        const querysearch = ref('');
        const country_id = ref(226); // United States
        const job_type = ref('Full-time');
        const filterby = ref(1);
        const modalActive = ref(false);
        const subscriptionActive = ref(false);
        const isConfirm = ref(false);
        const isCancelEditConfirm = ref(false);
        const hasToClear = ref(false);
        const visible = ref(false);
        const totalJobPost = ref(0);

        const { jobs, job, getJobs, getJob, saveJob, destroyJob, errors, searchJobtile, searchByJobType, searchByCountry, getPromotedJobs, promotedJobs, updateJobStatus, status } = jobRepo();
        const { job_types, countries, getCountries, rates, location_roles, time_frames, schedules } = globalRepo();
        const { getCurrentJobPromotionBoost, currentJobPromotionBoost } = jobPromotionBoostRepo();
        const { getCompany, company } = userRepo();

        const headers = [
            {
                key: 'job_order_number',
                label: 'Job Posting ID',
                class: 'posting-id-header'
            },
            {
                key: 'position_title',
                label: 'Position Title',
                class: 'posting-title-header'
            },
            {
                key: 'job_type_display',
                label: 'Job Type',
                class: 'posting-type-header'
            },
            {
                key: 'created_at',
                label: 'Date Posted',
                class: 'date-posted-header'
            },
            {
                key: 'num_of_candidates',
                label: 'Positions Open',
                class: 'text-center num-candidates-header'
            },
            {
                key: 'status_display',
                label: 'Status',
                class: 'text-center posting-status-header'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        const activeJobPostCount = computed(() => {
            let count = 0;
            jobs.value.forEach(item => {
                if(item.is_job_unreset == true) {
                    count++;
                }
            });

            return count;
        });

        const refreshPage = async () => {
            await getJobs(state);
            await getPromotedJobs(state);
            modalActive.value = false;
            jobPromotionBoostActive.value = false;

            state.promoteCredits = company.value.promotional_job_count - promotedJobs.value.length;
            // location.reload();
        }

        const refreshJobBoost = () => {
            modalActive.value = true;
            jobPromotionBoostActive.value = !jobPromotionBoostActive.value;
        }

        const emitHandleSwitch = (evt) => {
            jobPromotionBoostActive.value = evt.promotionBoostActive;
            state.modalHeaderContent = evt.modalHeader;
        }

        watch(() => subscriptionActive.value, () => {
            document.addEventListener('keydown', function(e){
                state.hasEditedFields = true;
            });
        });

        const editJobStatus = async (id) => {
            await updateJobStatus(id);
            if(status.value == 200) {
                await getJobs(state);
                await getPromotedJobs(state);
            }
        }

        onMounted( async () => {
            await getJobs(state);
            await getCurrentJobPromotionBoost(state);
            await getCountries();
            await getPromotedJobs(state);
            await getCompany(state.authuser.company_id);
            state.isDataLoaded = true;

            totalJobPost.value = company.value.job_post_count;
            if(activeJobPostCount.value == totalJobPost.value) {
                state.isExceedJobPost = true;
            }
            
            state.promoteCredits = company.value.promotional_job_count - promotedJobs.value.length;
        });

        const toggleModalApplicants = () => {
            modalApplicantsActive.value = !modalApplicantsActive.value;
        }

        const createJob = async () => {
            toggleModal();
            job.value = [];
        }

        const closeModal = () => {
            state.hasEditedFields = false;
            toggleModal();
            toggleCancelEditConfirmation();
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleModalSubscription = () => {
            subscriptionActive.value = !subscriptionActive.value;
            modalActive.value = (subscriptionActive.value == true) ? false : true;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const cancelEditConfirmation = (isBoostTriggered) => {
            if(state.hasEditedFields && !isBoostTriggered) {
                toggleCancelEditConfirmation();
            } else {
                toggleModal();
            }
        }

        const toggleCancelEditConfirmation = () => {
            isCancelEditConfirm.value = !isCancelEditConfirm.value;
        }

        const updateJob = async (id) => {
            state.isLoading = true;
            await getJob(id, state);
            let status = job.isExpired ? '- Expired' : '';
            state.modalHeader = `Update Job Posting ${status}`;
            toggleModal();
        }

        const deleteJob = (id) => {
            toggleConfirmation();
            state.idToDelete = id;
        }

        const removeJob = async () => {
            toggleConfirmation();
            state.isfullScreenLoading = true;
            await destroyJob(state.idToDelete);
            state.isfullScreenLoading = false;
        }

        const searching = async (event) => {
            hasToClear.value = false;
            if(event.target.value !== '') {
                hasToClear.value = true;
            }
            await updateValue(event.target.value);
        }

        const updateValue = _debounce(function (event) {
            state.isLoading = true;
            let formData = new FormData();
            formData.append('filter', filterby.value);
            formData.append('searchquery', event ?? '');
            formData.append('location', event ?? '');
            formData.append('company_id', state.authuser.company_id);
            searchJobtile(state, formData);
        }, 800);

        const filterChange = () => {
            location.value = '';
            querysearch.value = '';
            if(filterby.value == 1) {
                getJobs(state);
                location.value = '';
                querysearch.value = '';
                filterby.value = 1;
                hasToClear.value = false;
            }

            if(filterby.value == 2) {
                searchByJobType(state, job_type.value);
                hasToClear.value = true;
            }

            if(filterby.value == 3) {
                searchByCountry(state, country_id.value);
                hasToClear.value = true;
            }

            if(filterby.value == 4) {
                const joblist = jobs.value
                jobs.value = jobs.value.filter((job) => job.promote == 1)
                joblist.map(function(data){
                    if( jobs.value.filter(e => e.id === data.id).length === 0 ){
                        jobs.value.push(data)
                    }
                });
                hasToClear.value = true;
            }
        }

        const filterJobTypeChange = () => {
            searchByJobType(state, job_type.value);
        }

        const filterCountryChange = () => {
            searchByCountry(state, country_id.value);
        }

        // Clears the filter on job posting search.
        const clearFilter = async () => {
            await activeSubscription(state);
            getJobs(state);
            location.value = '';
            querysearch.value = '';
            filterby.value = 1;
            hasToClear.value = false;
        }

        const showPaidOptions = () => {
            jobPromotionBoostActive.value = true;
        }

        const formatDate = (date) => {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return new Date(date).toLocaleDateString('en', options)
        }

        const getSchedules = () => {
            if(job.value.schedules) {
                let results = job.value.schedules.split(',');
                let content = '';
                results.filter(result => {
                    content += `${schedules.find(x => x.id == result).name}, `;
                });

                return content.slice(0, -2);
            }
        }

        const onClosing = (e) => {
            // close after 3 seconds
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                e.close = true;
            }, 500)
        }

        const showDetails = async (id, applicationsCount, applicationsList) => {
            state.isfullScreenLoading = true;
            await getJob(id, state);
            state.applicationCount = applicationsCount;
            state.applicationList = applicationsList;
            state.isfullScreenLoading = false;
            visible.value = true;
        }

        const showApplicants = () => {
            visible.value = false;
            setTimeout(() => {
                toggleModalApplicants();
            }, 500);
        }

        const renderJobPosting = () => {
            subscriptionActive.value = false;
            location.reload();
        }

        return {
            headers,
            jobs,
            createJob,
            saveJob,
            state,
            job,
            errors,
            getJobs,
            getJob,
            destroyJob,
            modalActive,
            toggleModal,
            updateJob,
            deleteJob,
            isConfirm,
            toggleConfirmation,
            removeJob,
            querysearch,
            filterby,
            location,
            job_type,
            job_types,
            searching,
            filterChange,
            searchByJobType,
            countries,
            getCountries,
            country_id,
            filterJobTypeChange,
            filterCountryChange,
            isCancelEditConfirm,
            toggleCancelEditConfirmation,
            closeModal,
            cancelEditConfirmation,
            clearFilter,
            showPaidOptions,
            toggleModalSubscription,
            subscriptionActive,
            formatDate,
            hasToClear,
            showDetails,
            onClosing,
            visible,
            rates,
            location_roles,
            time_frames,
            getSchedules,
            schedules,
            modalApplicantsActive,
            toggleModalApplicants,
            showApplicants,
            editJobStatus,
            updateJobStatus,
            status,
            refreshPage,
            totalJobPost,
            activeJobPostCount,
            jobPromotionBoostActive,
            renderJobPosting,
            emitHandleSwitch,
            currentJobPromotionBoost,
            refreshJobBoost,
            getCompany,
            company
        }
    }
});
</script>

<style scoped>
.meatballs-menu {
    width: 100px;
    float: none;
    text-align: center;
    padding-right: 10px;
}
.custom_tooltip {
    border:0 !important;
}
</style>

<style>
.dropdown-item.disabled {
    opacity: 0.5;
}
.w-15 {
    width: 15%;
}
.w-35 {
    width: 35% !important;
}
.cancel-edit-confirmation .show.confirm-modal {
    z-index: 2001 !important;
}
.cancel-edit-confirmation .modal-backdrop {
    z-index: 2000;
}
.posting-id-header { width: 10%; }
.posting-actions-header { width: 150px; }
.posting-status-header { width: 100px; }
.num-candidates-header { width: 100px; }
.posting-title-header { width: 25%; }
.posting-type-header { width: 150px; }
.date-posted-header { width: 100px; }
#filter { width: 65%; }
.buttons-container { width:130px;}
.jobs-counter { width: 60%; text-align: right; font-weight: bold;}
.full-screen-loader {
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #4d4d4d40;
    text-align: center;
    padding: 19%;
}
</style>
<style scoped>
.promo-credits {
    display: inline-block;
    padding: 3px 11px;
    border-radius: 3px;
    background: #f1f1f1;
    margin-left: 5px;
    color: #535353;
}
#filter {
    width: 40%;
}
div#monthly-credits {
    display: inline-block;
    padding: 3px 11px;
    background: #f1f1f1;
    margin-left: 5px;
    border-radius: 3px;
    color: #535353;
}
</style>