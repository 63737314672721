<template>
    <div>
        <Modal :modalActive="modalActive" :class-name="`modal-dialog`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>Invoice Number</td>
                                    <td>{{ form.invoice?.number }}</td>
                                </tr>
                                <tr>
                                    <td>Invoice Status</td>
                                    <td>
                                        <div class="text-danger" v-if="form.invoice?.status !== 'paid'"><strong>{{ form.invoice?.status.toUpperCase() }}</strong></div>
                                        <div class="text-success" v-else><strong>{{ form.invoice?.status.toUpperCase() }}</strong></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <td>{{ convertDate(form.invoice?.created) }}</td>
                                </tr>
                                <tr>
                                    <td>Amount Due</td>
                                    <td><strong>{{ convertString(form.invoice?.amount_due) }}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="form.invoice?.status == 'open'" class="text-center">
                            <button class="btn btn-outline-primary" @click="payInvoice">Pay Invoice</button>
                            <p class="text-mute">Please make sure you have a default payment method and the account is valid to avoid failure of payment.</p>
                        </div>
                        <div class="d-grid gap-2 col-6 mx-auto" v-else>
                            <a class="btn btn-primary btn-lg" :href="form.invoice?.hosted_invoice_url" target="_blank">{{ form.payment?.last_payment_error !== null ? 'Pay Now' : 'View Invoice' }}</a>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import moment from 'moment';
import billingRepo from '@/repositories/employer/billing';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        }
    },
    setup(props, { emit }) {
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true
        });
        const { listPaymentMethods, payBillingInvoice, updateSubscription, methods, status } = billingRepo();

        const close = () => {
            emit('close')
        }

        const convertDate = (timestamp) => {
            let day = moment.unix(timestamp);
            return day.format('DD MMMM YYYY');
        }

        const convertString = (data) => {
            if(data) {
                let amount = data.toString();
                return `$${amount.slice(0, -2)}.00`;
            }
        }

        const payInvoice = async () => {
            let formData = new FormData();
            formData.append('invoice_id', props.form.invoice?.id);

            await payBillingInvoice(formData);
            if(status.value == 200) {
                updateSubscription(page);
            }
            emit('refreshPage');
            emit('close');
        }

        onMounted( async () => {
            listPaymentMethods(page);
        });

        return {
            page,
            close,
            convertDate,
            convertString,
            listPaymentMethods,
            methods,
            payInvoice,
            payBillingInvoice,
            status,
            updateSubscription
        }
    }
})
</script>