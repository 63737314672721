<template>
    <div>
        <div class="row no-margin">
            <div class="col-8">
                <BaseCheckbox is-array :margin-bottom-on="false">
                    <label class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="toggleResume" />
                        <span class="form-check-label">
                            Use my Simphini Profile Info
                        </span>
                    </label>
                </BaseCheckbox>
            </div>
        </div>
        <div class="row no-margin" v-if="!toggleResume">
            <div class="col-8">
                <div class="form-group mt-3">
                    <label for="formFile" class="form-label"><b>Upload My Own Resume <span class="text-danger" v-if="application.job.submit_resume === 'Yes'">*</span></b></label>
                    <input class="form-control" type="file" id="formFile" @change="onFileResumeChange" enctype="multipart/form-data">
                    <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" v-if="(errors && errors.resume) || resumeErrors">Please upload your resume.</label>
                    <div v-if="data.resume_document">
                        <span class="badge bg-primary text-white">{{ getDocument('resume') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-margin">
            <div class="col-8">
                <BaseCheckbox is-array :margin-bottom-on="false">
                    <label class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="toggleLetter" />
                        <span class="form-check-label">
                            Manually type a cover letter
                        </span>
                    </label>
                </BaseCheckbox>
            </div>
        </div>
        <PageLoader v-if="toggleLetter && page.isLoading" />
        <div class="row no-margin" v-else>
            <div class="col-8">
                <div v-if="toggleLetter">
                    <BaseEditor
                        v-model="application.cover_letter_content"
                        id="cover_letter_content"
                        @editorContent="setCoverLetterContent"
                    />
                </div>
                <div v-else>
                    <div class="form-group mt-3">
                        <label for="letterFile" class="form-label"><b>Upload a Cover Letter</b></label>
                        <input class="form-control" type="file" id="letterFile" @change="onFileCoverLetterChange" enctype="multipart/form-data">
                        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" v-if="errors && errors.cover_letter">{{ errors.cover_letter[0] }}</label>
                        <div v-if="data.cover_letter_document">
                            <span class="badge bg-primary text-white">{{ getDocument('cover-letter') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-margin">
            <div class="col-8">
                <div class="form-group mb-3 mt-3">
                    <label for="documentFile" class="form-label"><b>Upload Supporting Document</b></label>
                    <input class="form-control" type="file" id="documentFile" @change="onFileDocumentsChange" enctype="multipart/form-data">
                    <label class="error jquery-validation-error small form-text invalid-feedback is-invalid show-error" v-if="errors && errors.documents">{{ errors.documents[0] }}</label>
                    <div v-if="application.documents">
                        <span class="badge bg-primary text-white">{{ getDocument('supporting-document') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-margin">
            <div class="col-12">
                <BaseButton :btnValue="`Save as Draft`" v-if="!is_button_disabled" @btnClicked="onUpdate" />
                <button class="btn btn-primary" disabled v-else><i class="fa fa-spinner fa-spin fa-fw"></i> &nbsp; Saving Application...</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, watchEffect, ref, reactive } from 'vue';

export default defineComponent({
    props: {
        job_id: {
            type: [Number, String],
            required: true
        },
        data: {
            type: Object
        },
        errors: {
            type: [Object, String]
        },
        is_button_disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const resumeErrors = ref(false);
        const toggleResume = ref(true);
        const toggleLetter = ref(true);
        const status = ref('');
        const application = ref([]);
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            resume: props.data?.resume ?? '',
            cover_letter: '',
            cover_letter_content: '',
            document: ''
        });

        watchEffect(() => {
            application.value = props.data ?? [];
            toggleResume.value = props.data?.use_profile ?? true;
            toggleLetter.value = props.data?.type_cover_letter ?? true;
            props.is_button_disabled = props.is_button_disabled;
            setTimeout(() => {
                page.isLoading = false;
            }, 1500)
        });

        const onFileResumeChange = (e) => {
            let fileResume = e.target.files[0];
            page.resume = fileResume;
        }

        const onFileCoverLetterChange = (e) => {
            let fileCoverLetter = e.target.files[0];
            page.cover_letter = fileCoverLetter;
        }

        const onFileDocumentsChange = (e) => {
            let fileDocuments = e.target.files[0];
            page.document = fileDocuments;
        }

        const onUpdate = () => {
            page.resume = page.resume ?? props.data?.resume;
            resumeErrors.value = (toggleResume.value === false && (application.value.job.submit_resume === 'Yes' && !page.resume)) ?? false;
            if(!resumeErrors.value) {
                application.value.use_profile = toggleResume.value;
                application.value.type_cover_letter = toggleLetter.value;
                application.value.resume = page.resume ?? '';
                application.value.cover_letter = page.cover_letter ?? '';
                application.value.cover_letter_content = page.cover_letter_content ?? '';
                application.value.documents = page.document ?? '';
                emit('candidateDocuments', application);
            } else {
                setTimeout(() => {
                    toggleResume.value = false;status.value = 200;
                }, 550);
                emit('errorResumeUpload');
            }
            setTimeout(() => {
                status.value = 200;
            }, 500);
        }

        const setCoverLetterContent = (evt) => {
            page.cover_letter_content = evt ?? '';
        }

        const getDocument = (type) => {
            let name = page.authuser.name.replace(' ','-');
            return `${name.toLowerCase()}-${type}`;
        }

        return {
            resumeErrors,
            toggleResume,
            toggleLetter,
            onUpdate,
            application,
            onFileResumeChange,
            onFileCoverLetterChange,
            onFileDocumentsChange,
            setCoverLetterContent,
            getDocument,
            page,
            status
        }
    }
})
</script>

<style>
.no-margin {
    margin: 0px !important;
}
.show-error {
    display: block !important;
}
</style>