<template>
    <div class="tab-pane fade show active" id="educationalbackground" role="tabpanel">
        <div class="card">
            <div class="card-header pb-0">
                <h5 class="card-title mb-0">Your Employment Status</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <BaseSelect
                            v-model="candidate.employment_status"
                            label="Employment Status"
                            id="employment_status"
                            :options="employment_statuses"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header pb-0">
                <h5 class="card-title mb-0">What kind of job are you interested in?</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <BaseInput
                            v-model="candidate.desired_job_title"
                            label="Desired Job Title"
                            placeholder="Desired Job Title"
                            type="text"
                            id="desired_job_title"
                            :class="{ 'is-invalid' : errors.desired_job_title }"
                            :errors="errors"
                            is-required
                        />
                        <div>
                            <h5 class="card-title mb-2 mt-4">Desired Pay Details</h5>
                            <BaseSelect
                                v-model="candidate.compensation_type"
                                label="Show Pay By"
                                id="compensation_type"
                                :options="compensation_types"
                            />
                            <div v-if="isExact">
                                <BaseInput
                                    v-model="candidate.desired_pay_exact"
                                    label="Exact Amount"
                                    placeholder="Enter Exact Amount"
                                    type="text"
                                    id="desired_pay_exact"
                                />
                            </div>
                            <div v-if="isRange">
                                <BaseInput
                                    v-model="candidate.desired_pay_min"
                                    label="Minimum Amount"
                                    placeholder="Enter Minimum Amount"
                                    type="text"
                                    id="desired_pay_min"
                                />
                            </div>
                            <div v-if="isRange">
                                <BaseInput
                                    v-model="candidate.desired_pay_max"
                                    label="Maximum Amount"
                                    placeholder="Enter Maximum Amount"
                                    type="text"
                                    id="desired_pay_max"
                                />
                            </div>
                            <BaseSelect
                                v-model="candidate.desired_pay_rate"
                                label="Rate"
                                id="rate"
                                :options="rates"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label>Relocation</label>
                            <BaseCheckbox
                                label="I am willing to relocate"
                                placeholder="I am willing to relocate"
                                id="relocation"
                                v-model="candidate.relocate"
                                :modelValue="candidate.relocate"
                                design="inline"
                            />
                        </div>
                        <BaseCheckbox is-array label="Desired Job Type">
                            <label class="form-check form-check-inline" v-for="job_type in job_types" :key="job_type.name">
                                <input class="form-check-input" type="checkbox" v-model="page.form.desired_job_type" :value="job_type.name" :class="{ 'is-invalid' : errors && errors.job_type }" />
                                <span class="form-check-label">
                                    {{ job_type.name }}
                                </span>
                            </label>
                            <label class="error jquery-validation-error small form-text invalid-feedback show-error" v-if="errors && errors.job_type">{{ errors.job_type[0] }}</label>
                        </BaseCheckbox>
                    </div>
                    <div class="col-md-4">
                        <div class="m-6 mt-0 mb-0">
                            <BaseCheckbox is-array label="Desired Shifts">
                                <label class="form-check" v-for="schedule in schedules" :key="schedule.id">
                                    <input class="form-check-input check-shift" type="checkbox" v-model="page.form.desired_shifts" :value="schedule.id" :class="{ 'is-invalid' : errors && errors.schedules }" />
                                    <span class="form-check-label">
                                        {{ schedule.name }}
                                    </span>
                                </label>
                                <label class="error jquery-validation-error small form-text invalid-feedback show-error" v-if="errors && errors.schedules">{{ errors.schedules[0] }}</label>
                            </BaseCheckbox>
                            <BaseCheckbox is-array label="Desired Schedules">
                                <label class="form-check" v-for="shift in schedule_shifts" :key="shift.id">
                                    <input class="form-check-input check-sched" type="checkbox" v-model="page.form.desired_schedule" :value="shift.id" :class="{ 'is-invalid' : errors && errors.shifts }" />
                                    <span class="form-check-label">
                                        {{ shift.name }}
                                    </span>
                                </label>
                                <label class="error jquery-validation-error small form-text invalid-feedback show-error" v-if="errors && errors.shifts">{{ errors.shifts[0] }}</label>
                            </BaseCheckbox>
                            <BaseCheckbox is-array label="Benefits">
                            <label class="form-check form-check-inline" v-for="benefit in benefits" :key="benefit.id">
                                <input class="form-check-input check-benefits" type="checkbox" v-model="page.form.benefits" :value="benefit.id" :class="{ 'is-invalid' : errors && errors.benefits }" />
                                <span class="form-check-label">
                                    {{ benefit.name }}
                                </span>
                            </label>
                            <label class="error jquery-validation-error small form-text invalid-feedback show-error" v-if="errors && errors.benefits">{{ errors.benefits[0] }}</label>
                        </BaseCheckbox>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                </div>
                <BaseButton :is-saved="status === 200 || status === 422" @btnClicked="saveJobPreference" />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';
import candidateRepo from '@/repositories/settings/candidate';
import globalRepo from '@/repositories/global';

export default defineComponent({
    setup() {
        const { updateCandidateProfileJobsearch, getCandidateProfile, candidate, errors, status } = candidateRepo();
        const { schedules, time_frames, job_types, compensation_types, rates, schedule_shifts, benefits, employment_statuses } = globalRepo();
        const isExact = ref(false);
        const isRange = ref(false);

        onMounted( async () => {
            await getCandidateProfile(page);
        });

        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            form: {
                desired_job_type: [],
                desired_schedule: [],
                desired_shifts: [],
                benefits: [],
                employment_status: ''
            }
        });

        watchEffect(() => {
            isRange.value = (candidate.value?.compensation_type == 1);
            isExact.value = (candidate.value?.compensation_type == 2);
            if(candidate.value?.id != undefined) {
                page.form.desired_job_type = candidate.value?.desired_job_type ? candidate.value.job_type_object : page.form.desired_job_type;
                page.form.desired_schedule = candidate.value?.desired_schedule ? candidate.value.schedules_object : page.form.desired_schedule;
                page.form.desired_shifts = candidate.value?.desired_shifts ? candidate.value.shifts_object : page.form.desired_shifts;
                page.form.benefits = candidate.value?.benefits ? candidate.value.benefits_object : page.form.benefits;
                page.form.employment_status = candidate.value?.employment_status ? candidate.value.employment_status : page.form.employment_status;
            }
        });

        const saveJobPreference = async () => {
            status.value = '';
            let userData = new FormData();
            userData.append('desired_job_title', candidate.value.desired_job_title ?? '');
            userData.append('relocation', candidate.value.relocate ?? 1);
            userData.append('desired_pay_exact', candidate.value.desired_pay_exact ?? '');
            userData.append('desired_pay_min', candidate.value.desired_pay_min ?? '');
            userData.append('desired_pay_max', candidate.value.desired_pay_max ?? '');
            userData.append('compensation_type', candidate.value.compensation_type ?? '');
            userData.append('desired_pay_rate', candidate.value.desired_pay_rate ?? '');
            userData.append('desired_job_type', candidate.value.desired_job_type ?? page.form.desired_job_type);
            userData.append('desired_schedule', candidate.value.desired_schedule ?? page.form.desired_schedule);
            userData.append('desired_shifts', candidate.value.desired_shifts ?? page.form.desired_shifts);
            userData.append('benefits', candidate.value.benefits ?? page.form.benefits);
            userData.append('employment_status', candidate.value.employment_status ?? page.form.employment_status);
            userData.append('user_id', page.authuser.id);

            await updateCandidateProfileJobsearch(userData);
        }

        return {
            page,
            errors,
            status,
            saveJobPreference,
            getCandidateProfile,
            updateCandidateProfileJobsearch,
            candidate,
            schedules,
            time_frames,
            job_types,
            schedule_shifts,
            benefits,
            compensation_types,
            rates,
            isExact,
            isRange,
            employment_statuses
        }
    }
})
</script>