<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-center pt-10">
                        <img :src="`${state.root_url}/images/success.png`" style="width: 20%">
                    </div>
                    <div class="pt-10">
                        <p class="text-center success-txt">
                            Please check your email {{ state.authuser.email }} to verify your account. Your payment has been successfully processed. The details of this transaction will be available on your billings section. Thank you!
                            <div class="pt-10">
                                <router-link class="btn btn-primary btn-xl" :to="{ name: 'settings.config', query: { tab: 'billings' } }">Billing</router-link>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import trainingRepo from '@/repositories/employer/trainings';

export default {
    setup() {
        const route = useRoute();
        const state = reactive({
            root_url: process.env.VUE_APP_API_URL,
            authuser: JSON.parse(localStorage.getItem('authuser')),
            code: ''
        });
        const { createForwardEmailAccount, status } = trainingRepo();

        onMounted( async () => {
            await createForwardEmailAccount(route.query.code);
        });

        return {
            state,
            createForwardEmailAccount,
            status
        }
    },
}
</script>

<style>
.pt-10 {
    padding-top: 30px;
}
.success-txt {
    width: 50%;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 500;
}
.btn-xl {
    padding: 8px 45px !important;
    font-size: 22px !important;
}
</style>