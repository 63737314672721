import NewApplicant from '@/views/employer/applicants/NewApplicant';
import FindApplicants from '@/views/employer/find/tabs/ViewCandidates';
import MatchedApplicants from '@/views/employer/find/tabs/MatchedCandidates';
import SavedApplicants from '@/views/employer/find/tabs/SavedCandidates';
import InvitedApplicants from '@/views/employer/find/tabs/InvitedCandidates';
import CandidateProfile from '@/views/employer/find/CandidateProfile';
import CandidateReport from '@/views/employer/applicants/Reports';

const routes = [
    {
        path: '/employer/applicants',
        name: 'employer.new-applicants',
        component: NewApplicant
    },
    {
        path: '/employer/applicants/active',
        name: 'employer.active-applicants',
        component: NewApplicant
    },
    {
        path: '/employer/applicants/find',
        name: 'employer.applicants.find',
        component: FindApplicants
    },
    {
        path: '/employer/applicants/match',
        name: 'employer.applicants.match',
        component: MatchedApplicants
    },
    {
        path: '/employer/applicants/saved',
        name: 'employer.applicants.saved',
        component: SavedApplicants
    },
    {
        path: '/employer/applicants/invited',
        name: 'employer.applicants.invited',
        component: InvitedApplicants
    },
    {
        path: '/employer/applicant/:user_id',
        name: 'employer.applicant.view',
        component: CandidateProfile
    },
    {
        path: '/employer/applicant/reports',
        name: 'employer.applicants.reports',
        component: CandidateReport
    }
]

export default routes