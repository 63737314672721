<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-xl modal-dialog-scrollable`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation" v-for="tab in tabs" :key="tab">
                        <button class="nav-link" :class="getActive(tab.id)" :disabled="isDisabled(tab.id)" @click="currentTab(tab.id, tab.component)" type="button" role="tab">{{ tab.step }}</button>
                    </li>
                </ul>
                <div class="tab-btn">
                    <button class="btn btn-outline-primary btn-sm" :class="{ 'disabled' : isPrevDisable }" @click="prevTab" v-if="!page.isLastStep" type="button">Previous</button>&nbsp;
                    <button class="btn btn-outline-primary btn-sm" :class="{ 'disabled' : (page.activeTab == 1 && !page.isQuestionAnswered) }" type="button" v-if="!page.isLastStep" @click="nextTab">Next</button>
                    <button class="btn btn-outline-primary btn-sm" type="button" disabled v-else-if="page.isLastStep && form.submitted == true">You already submitted an application</button>
                    <button class="btn btn-outline-primary btn-sm" type="button" v-else @click="submitApplication" v-html="page.buttonValue"></button>
                </div>

                <div class="tab-content" id="application-tab-content">
                    <div class="tab-pane fade show active pt-3">
                        <component :is="currentComponent" v-bind="{ clickNext: page.clickedNext, job_id: page.jobId, data: form, errors, is_button_disabled: isButtonDisabled }" @candidateQuestions="candidateQuestions" @candidateDocuments="candidateDocuments" @watchTextfield="checkAnswersValue" @errorResumeUpload="errorResumeUpload"></component>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { onUpdated, reactive, watchEffect, ref } from 'vue';
import Modal from '@/components/modules/Modal.vue';
import Question from '@/views/candidate/jobs/content/Question.vue';
import Documents from '@/views/candidate/jobs/content/Documents.vue';
import Preview from '@/views/candidate/jobs/content/Preview.vue';
import applicationRepo from '@/repositories/candidate/application';
export default {
    props: {
        id: {
            type: [Number, String],
            required: true
        },
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Apply to a Job',
            idToDelete: '',
            activeTab: 1,
            isLastStep: false,
            jobId: '',
            buttonValue: 'Submit my Application',
            clickedNext: false,
            isQuestionAnswered: false
        });

        const { getApplication, updateAnswers, updateDocuments, submitJobApplication, errors } = applicationRepo();

        const isPrevDisable = ref(true);
        const isButtonDisabled = ref(false);
        const currentComponent = ref('Question');

        watchEffect(() => {
            isPrevDisable.value = (page.activeTab == 1);
            page.jobId = props.id;
        });

        onUpdated(async () => {
            page.activeTab = 1;
            page.isLastStep = false;
        });

        const tabs = [
            {
                id: 1,
                step: 'Pre-Screening Questions',
                component: 'Question'
            },
            {
                id: 2,
                step: 'Important Documents',
                component: 'Documents'
            },
            {
                id: 3,
                step: 'Preview Application',
                component: 'Preview'
            }
        ];

        const currentTab = (step, component) => {
            currentComponent.value = component;
            if(page.activeTab > 1 && page.activeTab > step) {
                page.activeTab = step;
            }

            page.isLastStep = (tabs.length == page.activeTab);
        }

        const getActive = (step) => {
            if(page.activeTab > 1 && page.activeTab > step) {
                return 'done';
            }
            return (page.activeTab == step) ? 'active' : '';
        }

        const isDisabled = (step) => {
            if(page.activeTab > 1 && page.activeTab > step) {
                return false;
            }
            return (page.activeTab !== step);
        }

        const prevTab = () => {
            if(page.activeTab != 1) {
                page.activeTab = page.activeTab-1;
            }

            currentComponent.value = tabs.find(x => x.id == page.activeTab).component;
            page.isLastStep = (tabs.length == page.activeTab);
        }

        const nextTab = async () => {
            if(page.activeTab == 1 && currentComponent.value == 'Question') {
                if(page.isQuestionAnswered) {
                    page.clickedNext = true;
                } else {
                    return false;
                }
            }

            if(tabs.length > page.activeTab) {
                page.activeTab = page.activeTab+1;
            }

            // Make some time to process saving changes before switching to different component
            setTimeout( () => {
                currentComponent.value = tabs.find(x => x.id == page.activeTab).component;
                page.clickedNext = false;
            }, 500);

            page.isLastStep = (tabs.length == page.activeTab);
        }

        const candidateQuestions = async (data) => {
            let answer = [];
            data.forEach(item => {
                answer.push({
                    candidate_id: page.authuser.id,
                    question: item.question,
                    candidate_answer: item.candidate_answer
                })
            });

            await updateAnswers(page, answer);
            await getApplication(page, page.jobId);
        }

        const candidateDocuments = async (data) => {
            let formData = new FormData();
            formData.append('documents', data.value.documents ?? '');
            formData.append('resume', data.value.resume ?? '');
            formData.append('cover_letter', data.value.cover_letter ?? '');
            formData.append('cover_letter_content', data.value.cover_letter_content ?? '');
            formData.append('use_profile', data.value.use_profile ?? '');
            formData.append('type_cover_letter', data.value.type_cover_letter ?? '');
            formData.append('candidate_id', page.authuser.id);
            formData.append('job_id', page.jobId);
            isButtonDisabled.value = true;
            await updateDocuments(formData);
            await getApplication(page, page.jobId);
            isButtonDisabled.value = false;
        }

        const errorResumeUpload = () => {
            isButtonDisabled.value = true;
            setTimeout(()=>{
                isButtonDisabled.value = false;
            },500);
        }

        const submitApplication = async () => {
            page.buttonValue = '<i class="fa fa-spinner fa-spin"></i>';
            await submitJobApplication(page);
            setTimeout(() => {
                emit('close');
                page.activeTab = 1;
                currentComponent.value = 'Question';
            }, 1000)
        }

        const close = () => {
            emit('close');
            page.activeTab = 1;
            currentComponent.value = 'Question';
        }

        const saveChanges = () => {
            emit('saveChanges');
        }

        const checkAnswersValue = (data) => {
            if(data.length) {
                data.forEach(item => {
                    if(item?.candidate_answer === null || item?.candidate_answer === ""){
                        page.isQuestionAnswered = false;
                    } else {
                        page.isQuestionAnswered = true;
                    }
                });
            } else {
                page.isQuestionAnswered = true;
            }
        }

        return {
            page,
            getApplication,
            updateAnswers,
            updateDocuments,
            submitJobApplication,
            errors,
            tabs,
            currentTab,
            getActive,
            isDisabled,
            prevTab,
            nextTab,
            candidateQuestions,
            candidateDocuments,
            submitApplication,
            isPrevDisable,
            currentComponent,
            close,
            saveChanges,
            isButtonDisabled,
            checkAnswersValue,
            errorResumeUpload
        }
    },
    components: {
        Modal,
        Question,
        Documents,
        Preview
    }
}
</script>