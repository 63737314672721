<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex justify-content-between">
                <h1 class="h3 mb-3">Interview Trainings </h1>
            </div>
            
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-7 col-6 mt-2">
                    <div class="form-group">
                        <input 
                            v-model="page.searchedKeyword"
                            class="form-control"
                            id="searchedKeyword" 
                            placeholder="Search Interview Training By Name" 
                            @input="searchInterviewTraining"
                        />
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-5 col-6 mb-3">
                    <div v-if="userRole == 'admin'" class="btn-interview-training d-flex align-items-center mtc-9">
                        <button class="btn btn-primary btn-add-new-interview" @click="createInterviewTraining">Add New Interview Training</button>
                    </div>
                    <div class="mtc-9" v-if="userRole != 'admin'">
                        <button class="btn btn-outline-primary" :class="{ 'active' : selectedTab == 'All' }" @click="interviewTrainingFilter('All')">All</button> &nbsp;
                        <button class="btn btn-outline-primary" :class="{ 'active' : selectedTab == 'Article' }" @click="interviewTrainingFilter('Article')">Articles</button> &nbsp;
                        <button class="btn btn-outline-primary" :class="{ 'active' : selectedTab == 'Video' }" @click="interviewTrainingFilter('Video')">Videos</button> &nbsp;
                    </div>
                </div>
                <hr  />
            </div>
            <PageLoader v-if="page.isLoading" />
            <div v-else>
                <div v-if="page.interviewTrainingLists.length">
                    <div class="row mt-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-sm-6" v-for="(value, index) in page.interviewTrainingLists">
                                    
                                        <div class="cls-btn-container" v-if="userRole == 'admin'">
                                            <button class="cls-btn" @click="destroy(value.id)"></button>
                                        </div>
                                        <div class="no-decor" @click="userRole == 'candidate' ? openInterviewTraining(value) : editInterviewTraining(value.id)">
                                            <div class="card">
                                                <img class="card-img-top-interview-training" :src="`${value.thumbnail}`" alt="Interview" />
                                                <div class="card-header px-4 pt-4">
                                                    <h5 class="card-title mb-0 interview-training-title">{{ value.name }}</h5>
                                                    <div>
                                                        Interview Training Type: {{ value.type }}
                                                    </div>
                                                </div>
                                                <div class="card-body px-4 pt-2">
                                                    <div class="interview-training-description" v-html="value.description ?? '<p>No Description</p>'"></div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                            
                                <div class="col-12" v-if="page.searchNoResult">
                                    <div class="text-center">
                                        <img src="/assets/images/empty.png" class="img-fluid img-30">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <div class="text-center">
                            <img src="/assets/images/empty.png" class="img-fluid img-30">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, ref, reactive, watchEffect, onMounted, computed } from 'vue';
import _debounce from 'lodash/debounce';
import interviewTrainingRepo from '@/repositories/admin/interviewTrainings';

export default defineComponent({
    props: {
        interviewTrainingLists: {
            type: Object,
            default: {}
        },
        userRole: {
            type: String,
            default: ''
        },
        reloadData: {
            type: Boolean,
            default: false
        },
        pageLoad: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const page = reactive({
            api_url: process.env.VUE_APP_API_URL,
            selectedInterviewTraining: null,
            modalTitle: '',
            isLoading: true,
            currentTab: 'All',
            searchNoResult: false, 
            searchedKeyword: '',
            interviewTrainingLists: []
        });

        const { 
            message, 
            status, 
            errors, 
            interviewTrainings, 
            interviewTraining, 
            searchCandidateInterviewTrainings,
            getAdminInterviewTraining
        } = interviewTrainingRepo();
        
        const isConfirm = ref(false);
        const modalActive = ref(false);
        const selectedTab = ref('All');

        const interviewTrainingFilter = (type) => {
            selectedTab.value = type
        }

        const interviewTrainingFiltered = computed(() => {
            if(selectedTab.value != 'All'){
                return interviewTrainings.value.filter(data => data.type == selectedTab.value)
            }else{
                return interviewTrainings.value
            }
        })

        watchEffect(async () => {
            if(props.userRole == 'candidate') {
                page.interviewTrainingLists = interviewTrainingFiltered;
            } else {
                if(props.interviewTrainingLists || props.interviewTrainingLists > 0) {
                    page.interviewTrainingLists = props.interviewTrainingLists;
                } else {
                    page.interviewTrainingLists = interviewTrainingFiltered;
                }
            }
        });

        onMounted(async () => {
            await searchCandidateInterviewTrainings(page);
            page.isLoading = false
        });

        const getListByInterviewTrainingTypeTabs = async (tabType) => {
            selectedTab.value = tabType;
            page.isLoading = true;
            page.currentTab = tabType;

            page.interviewTrainingLists = interviewTrainings.value;
            
            if (page.interviewTrainingLists.length < 1) {
                page.searchNoResult = true;
            } else {
                page.searchNoResult = false;
            }
        }

        const user = JSON.parse(localStorage.getItem('authuser'));
        const hidden = ref(false);
        
        const openInterviewTraining = (value) => {
            page.selectedInterviewTraining = value;
            page.modalTitle = value.name;
            emit('openInterviewTrainingModal', page);
        }

        const toggleCandidateInterviewTrainingModal = () => {
            modalActive.value = !modalActive.value;
        }

        const searchInterviewTraining = _debounce(async function (searchedValue) {
            page.isLoading = true;
            page.searchNoResult = false;
            if (searchedValue.target.value !== '') {
                page.searchedKeyword = searchedValue.target.value;
                await searchCandidateInterviewTrainings(page);
                page.interviewTrainingLists = interviewTrainings;
            } else {
                await getListByInterviewTrainingTypeTabs(page.currentTab)
            }
            if (page.interviewTrainingLists.length < 1) {
                page.searchNoResult = true;
            }
            page.isLoading = false;
        }, 750);

        const createInterviewTraining = async () => {
            errors.value = [];
            interviewTraining.value = [];
            emit('createInterviewTraining')
        }


        const editInterviewTraining = async (id) => {
            page.modalTitle = 'Update Interview Training';
            await getAdminInterviewTraining(id);
            page.selectedInterviewTraining = interviewTraining;
            emit('editInterviewTraining', page)
        }

        const destroy = async (id) => {
            await getAdminInterviewTraining(id);
            page.selectedInterviewTraining = interviewTraining;
            emit('deleteInterviewTraining', page)
        }

        return {
            user,
            page,
            hidden, 
            interviewTrainings, 
            openInterviewTraining,
            toggleCandidateInterviewTrainingModal,
            isConfirm,
            modalActive, 
            selectedTab, 
            getListByInterviewTrainingTypeTabs, 
            searchInterviewTraining,
            createInterviewTraining,
            editInterviewTraining,
            destroy,
            interviewTrainingFilter,
            interviewTrainingFiltered
        }
    },
})
</script>
<style>
.interview-training-description p {
    min-height: 27px;
}
</style>