<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Manage Job Posting Questions</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" type="button" role="tab" @click="goTo('/employer/settings/job/questionaire')">Pre Screening Questions</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="categories" type="button" role="tab">Categories</button>
                        </li>
                    </ul>
                    <div class="card pt-3">
                        <div class="card-header">
                            <div class="card-actions float-end">
                                <button class="btn btn-primary text-white" @click="createQuestionCategory">Add Question Category</button>
                            </div>
                        </div>
                        <div class="card-body pt-1">
                            <BaseTable
                                :data="question_categories"
                                :options="headers"
                            >
                                <template v-slot:blank="{ row }">
                                    <div class="blank-space">&nbsp;</div>
                                </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li v-if="row.user_id !== 0"><a class="dropdown-item" href="javascript:;" @click="editQuestion(row.id)">Edit</a></li>
                                                <li :class="row.has_questions ? 'custom_tooltip' : ''" data-tooltip="This category cannot be deleted because there are questions under this category." v-if="row.user_id !== 0" style="border-bottom:0;"><a :disabled="row.has_questions" class="dropdown-item text-danger" href="javascript:;" @click="deleteQuestion(row.id)">Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalQuestionCategory
            :modalActive="modalActive"
            :form="question_category"
            :errors="errors"
            :modalTitle="page.modalHeader"
            :buttonCallback="isSaveButtonDisabled"
            @close="toggleModal"
            @saveChanges="save"
        />
        <Confirm
            :is-button-disabled="isConfirmButtonDisabled"
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this category?.`"
            @cancel="toggleConfirmation"
            @isDelete="removeQuestion"
        />
    </main>
</template>

<script>
import { useRouter } from 'vue-router'
import { defineComponent, ref, reactive, onMounted, inject } from 'vue';
import questionRepo from '@/repositories/settings/question';
import ModalQuestionCategory from '@/views/employer/settings/jobs/ModalQuestionCategory.vue';

export default defineComponent({
    setup() {
        const router = useRouter();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Create Category',
            idToDelete: ''
        });

        const modalActive = ref(false);
        const isConfirm = ref(false);
        const isSaveButtonDisabled = ref(false);
        const isConfirmButtonDisabled = ref(false);

        const { 
            status,
            errors,
            question_categories, 
            question_category, 
            getQuestionCategories, 
            getQuestionCategory,
            addQuestionCategory,
            updateQuestionCategory,
            destroyQuestionCategory 
        } = questionRepo()

        const headers = [
            {
                key: 'category_title',
                label: 'Category Title',
                class: 'w-20'
            },
            {
                key: 'blank',
                label: ''
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        onMounted( async () => {
            await getQuestionCategories(page)
        });

        const createQuestionCategory = () => {
            modalActive.value = true;
            errors.value = [];
        }

        const editQuestion = (id) => {
            page.modalHeader = 'Update Category';
            toggleModal();
            getQuestionCategory(id);
        }

        const deleteQuestion = (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const save = async (data) => {
            let formData = new FormData();
            formData.append('category_title', data.category_title);
            formData.append('company_id', page.authuser.company_id);

            isSaveButtonDisabled.value = true;
            if(question_category.value && question_category.value.id) {
                formData.append('category_id', question_category.value.id);
                formData.append('_method', 'POST');
                await updateQuestionCategory(formData);
            } else {
                await addQuestionCategory(formData);
                question_category.value = [];
            }
            isSaveButtonDisabled.value = false;
            page.isLoading = true;
            toggleModal();
            getQuestionCategories(page);
        }

        const removeQuestion = async () => {
            isConfirmButtonDisabled.value = true;
            await destroyQuestionCategory(page.idToDelete);
            toggleConfirmation();
            isConfirmButtonDisabled.value = false;
            getQuestionCategories(page);
        }

        const goTo = (path) => {
            router.push(`${path}`);
        }

        return {
            createQuestionCategory,
            toggleConfirmation,
            removeQuestion,
            deleteQuestion,
            editQuestion,
            toggleModal,
            save,
            goTo,
            page,
            errors,
            headers,
            isConfirm,
            modalActive,
            question_category,
            question_categories,
            isSaveButtonDisabled,
            isConfirmButtonDisabled
        }
    },
    components: {
        ModalQuestionCategory
    }
});
</script>
<style>
.meatballs-menu {
  float: none;
  text-align: center;
}
.blank-space {
    width: 735px;
}
</style>
<style scoped>
#filter {
    width: 50%;
}

.nav-tabs .nav-link.active {
    background: #fff;
    border-bottom: transparent;
}
.nav-tabs {
    border-bottom: transparent;
}
.nav-tabs .nav-link {
    background: #f4f4f4;
    border-color: #eee;
}
</style>