import axios from "axios";
import { ref, inject } from "vue";

export default function questionRepo() {
    const toast = inject('toast');
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const candidate = ref([]);

    const uploadResumeFile = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/users/upload-resume`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            status.value = response.data.status;
            candidate.value  =response.data.user;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateUser = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/${id}`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
            localStorage.setItem('authuser', JSON.stringify(response.data.user));
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    return {
        errors,
        message,
        status,
        candidate,
        uploadResumeFile,
        updateUser,
        alertMessage
    }
}