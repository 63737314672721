<template>
    <div class="form-group mb-3">
        <label :for="id" class="form-label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
        <textarea 
            :id="id" 
            class="form-control textarea-input" 
            :rows="numRows"
            :placeholder="placeholder" 
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >
        </textarea>
        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors && errors[id]">{{ errors[id][0] }}</label>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Array],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {},
            required: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        numRows: {
            type: Number,
            default: 4
        }
    }
})
</script>

<style>
.textarea-input {
    resize: none;
}
</style>