<template>
    <div>
        <div class="mt-3 mb-3 pl-20">
            <div class="row no-margin mt-7">
                <div class="col-12">
                    <h3>{{ job.position_title }} &nbsp; ({{ job.company ? job.company.company_name : '' }})</h3>
                    <div v-html="job.display_keywords"></div>
                    <div class="mb-3 mt-3">
                        <div><b>Job Type:</b> {{ job.job_type }}</div>
                        <div><b>Location:</b> {{ job.location }} {{ job.country }}</div>
                        <div v-if="job.language"><b>Language: </b> <span class="badge bg-success" style="margin-right:3px;" v-for="language in job.languages" v-bind:key="language">{{ language.name }}</span></div>
                        <div v-if="job.compensation_type == 1 && job.rate != null"><b>Compensation:</b> {{ `${job.min_amount} - ${job.max_amount}` }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                        <div v-if="job.compensation_type == 2 && job.rate != null"><b>Compensation:</b> {{ job.exact_amount }} {{ rates.find(x => x.id === job.rate)?.name ?? '' }}</div>
                        <div v-if="job.location_role_id"><b>Location Role:</b> {{ location_roles.find(x => x.id === job.location_role_id)?.name }}</div>
                        <div v-if="job.schedules"><b>Schedules:</b> {{ getSchedules() }}</div>
                        <div v-if="job.job_time_frame"><b>Time Frame:</b> {{ time_frames.find(x => x.id === job.job_time_frame)?.name }}</div>
                    </div>
                    <h5>Job Description</h5>
                    <div class="mb-3" v-html="job.job_description"></div>
                    <h5>Job Benefits</h5>
                    <div v-html="job.job_benefits" v-if="job.job_benefits"></div>
                    <div v-else>Not Listed.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import globalRepo from '@/repositories/global';

export default {
    props: {
        job: {
            type: Object,
            default: {}
        },
        application_id: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const { location_roles, time_frames, rates, schedules } = globalRepo();

        const getSchedules = () => {
            if(props.job.schedules) {
                let results = props.job.schedules.split(',');
                let content = '';
                results.filter(result => {
                    content += `${schedules.find(x => x.id == result).name}, `;
                });

                return content.slice(0, -2);
            }
        }

        return {
            location_roles,
            time_frames,
            rates,
            schedules,
            getSchedules
        }
    }
}
</script>