<template>
    <div>
        <Modal :className="`modal-dialog modal-md assign-learning-plan-modal`" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row" v-if="previewLessonDetails.lessons.length">
                    <div class="">
                        <div class="row">
                            <h3>Select Learning Plans <span class="badge bg-secondary" v-if="selectedLearningPlans.length > 0">{{selectedLearningPlans.length}}</span></h3>
                            <div v-for="(error, i) in errors" :key="i">
                                <div class="alert alert-danger mt-3" style="padding: 4px;" role="alert">
                                    <div v-for="(e, z) in error" :key="z">
                                        {{e}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-2">
                                <div class="no-decor">
                                    <div class="card shadow">
                                        <ul class="list-group">
                                            <li
                                            v-for="(value, index) in previewLessonDetails.lessons"
                                            :key="index"
                                            v-bind:class="checkLearningPlan(value.id) ? 'text-white bg-primary selected-lp':''"
                                            class="list-group-item"
                                            @click="select(value.id)"
                                            >
                                                <div class="row">
                                                    <div class="col-8">
                                                        <span v-if="checkLearningPlan(value.id)">
                                                            <strong>{{value.name}}</strong>
                                                        </span>
                                                        <span v-else>
                                                            {{value.name}}
                                                        </span>
                                                    </div>
                                                    <div class="col-2">
                                                        <span v-if="value.learning_plan_type == 0" class="badge rounded-pill bg-secondary">Basic</span>
                                                        <span v-if="value.learning_plan_type == 1" class="badge rounded-pill bg-success">Premium</span>
                                                        <span v-if="value.learning_plan_type == 2" class="badge rounded-pill bg-primary">Private</span>
                                                    </div>
                                                    <div class="col-2">
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <!-- <img class="card-img-top-interview-training" :src="`${value.course.thumbnail}`" alt="training" />
                                        <div class="card-header px-4 pt-4">
                                            <h5 class="card-title mb-0 interview-title-training">{{ value.course.title }}</h5>
                                            <div>
                                                Interview Training Type: {{ value.course.source_name }}
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" :btn-value="`Assign Learning Plans`" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, inject, ref } from 'vue';
import userRepo from '@/repositories/settings/users';
import trainingRepo from '@/repositories/employer/trainings';

export default defineComponent({
    props: ['modalActive','form','modalTitle','errors','users'],
    setup(props, { emit }) {

        const {
            getEmployerSubscriptionType,
            subscriptionType,
            getEmployerLearningPlans,
            learningPlans
        } = userRepo()

        const { errors, status, assignLearningPlanBulk } = trainingRepo();
        const selectedLearningPlans = ref([])

        const toast = inject('toast');

        const close = () => {
            selectedLearningPlans.value = []
            emit('close')
        }

        const saveChanges = async () => {
            const userJSON = props.users.length > 0 ? JSON.stringify(props.users): null
            const learningPlansJSON = selectedLearningPlans.value.length > 0 ? JSON.stringify(selectedLearningPlans.value): null
            let formData = new FormData();
            if(userJSON){
                formData.append('users', userJSON);
            }
            if(learningPlansJSON){
                formData.append('learnings', learningPlansJSON)
            }
            await assignLearningPlanBulk(formData)
            if(status.value == 200){
                selectedLearningPlans.value = []
                emit('saveChanges');
            }
        }

        onMounted(async () => {
            await getEmployerSubscriptionType();
            await getEmployerLearningPlans(subscriptionType.value);
            previewLessonDetails.lessons = props.form.training_courses ?? learningPlans.value;
        });

        const previewLessonDetails = reactive({
            lessons: []
        });

        const select = (id) => {
            const found = selectedLearningPlans.value.filter(data => data == id)
            if(found.length > 0){
                const index = selectedLearningPlans.value.indexOf(id)
                selectedLearningPlans.value.splice(index, 1)
            }else{
                selectedLearningPlans.value.push(id)
            }
        }

        const checkLearningPlan = (id) => {
            const found = props.form.lessons.filter(data => data.learning_plan_id == id);
            return found.length > 0 ? true : false;
        }

        return {
            close,
            status,
            errors,
            previewLessonDetails,
            learningPlans,
            subscriptionType,
            select,
            selectedLearningPlans,
            checkLearningPlan,
            saveChanges
        }
    }
})
</script>
<style scoped>
    .div-training-checkbox {
        position: relative;
        z-index: 1;
    }
    .cls-training-checkbox {
        position: absolute;
        border-radius: 50%;
        padding: 0.5em;
        width: 20px;
        height: 20px;
        border: 2px solid #c80000;
        color: white;
        background-color: red;
        cursor: pointer;
    }
    .list-group-item:hover {
        background: #5f84bf;
        color: white;
    }
    .selected-lp:hover {
        background: #d53343!important;
    }
</style>