import SkillsIndex from '@/views/admin/skills/Index';

const routes = [
    {
        path: '/admin/skills',
        name: 'admin.skills',
        component: SkillsIndex
    }
]

export default routes