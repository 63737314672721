<template>
    <div class="form-group" :class="{ 'mb-3' : marginBottomOn }">
        <label :for="id" class="form-label" v-if="label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
        <input 
            type="file" 
            class="form-control" 
            :id="id" 
            @change="onFileChange" 
            enctype="multipart/form-data"
        />
        <p class="form-text text-danger" v-if="formText" v-text="formText"></p>
        <label class="error jquery-validation-error small form-text invalid-feedback show" v-if="errors && errors[id]">{{ errors[id][0] }}</label>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Array],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {},
            required: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        marginBottomOn: {
            type: Boolean,
            default: true
        },
        formText: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const hasValue = ref([]);
        const onFileChange = (evt) => {
            if(evt) {
                let fileImage = evt.target.files[0];
                let id = evt.target.id;
                if(evt.target.value) {
                    hasValue.value[id] = true;
                } else {
                    hasValue.value[id] = false;
                }
                emit('changeFile', fileImage);
            }
        }

        return {
            onFileChange,
            hasValue
        }
    }
})
</script>