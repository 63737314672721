import axios from "axios";
import { ref, inject } from "vue";

export default function candidateMessagesRepo() {
    
    const toast = inject('toast');
    const conversations = ref([]);
    const messages = ref([]);
    const errors = ref([]);
    const status = ref([]);

    const getCandidateConversations = async (page, searchValue = false) => {
        let user_id = page.authuser.id ?? 0;
        if(user_id > 0) {
            let response
            if(searchValue) {
                response = await axios.get(`candidate/messages/conversations?user_id=${user_id}&keyword=${searchValue}`);
            } else {
                response = await axios.get(`candidate/messages/conversations?user_id=${user_id}`);
            }
            conversations.value = response.data;
        }
        page.isLoading = false;
    }

    const getCandidateMessages = async (page) => {
        let conversation_id = page.displayInfo.conversation_id ?? 0;
        let user_id = page.authuser.id ?? 0;
        if(user_id > 0 && conversation_id > 0) {
            let response = await axios.get(`candidate/messages/get?conversation_id=${conversation_id}&user_id=${user_id}`);
            messages.value = response.data;
        }
        page.isMessageLoading = false;
    }

    const assignCandidateConversation = async (page) => {
        let target_user_id = page.targetUserId ?? 0;
        let current_user_id = page.authuser.id ?? 0;
        if(target_user_id > 0 && current_user_id > 0) {
            let response = await axios.get(`candidate/messages/assign-conversation?target_user_id=${target_user_id}&current_user_id=${current_user_id}`);
            status.value = response.data;
        }
    }

    const setReadMessage = async (page) => {
        let conversation_id = page.displayInfo.conversation_id ?? 0;
        let user_id = page.authuser.id ?? 0;
        if(user_id > 0 && conversation_id > 0) {
            let response = await axios.get(`candidate/messages/set-read-message?conversation_id=${conversation_id}&user_id=${user_id}`);
            status.value = response.data;
        }
    }

    const setUnReadMessage = async (page) => {
        let conversation_id = page.displayInfo.conversation_id ?? 0;
        let user_id = page.authuser.id ?? 0;
        if(user_id > 0 && conversation_id > 0) {
            let response = await axios.get(`candidate/messages/set-unread-message?conversation_id=${conversation_id}&user_id=${user_id}`);
            toast.success(response.data.message);
        }
    }

    const deleteConversation = async (page) => {
        let conversation_id = page.displayInfo.conversation_id ?? 0;
        let user_id = page.authuser.id ?? 0;
        if(user_id > 0 && conversation_id > 0) {
            let response = await axios.get(`candidate/messages/delete-conversation?conversation_id=${conversation_id}&user_id=${user_id}`);
            toast.success(response.data.message);
        }
    }

    const postCandidateMessage = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/messages/post`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Message has been sent');
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }
    
    return {
        conversations,
        messages,
        getCandidateConversations,
        getCandidateMessages,
        postCandidateMessage,
        assignCandidateConversation,
        setReadMessage,
        setUnReadMessage,
        deleteConversation
    }
}