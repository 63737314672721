import JobIndex from '@/views/candidate/jobs/Index';
import JobSaved from '@/views/candidate/jobs/applications/Saved';
import JobApplications from '@/views/candidate/jobs/applications/Index';
import JobInvites from '@/views/candidate/jobs/applications/Invitations';
import JobResearchHelp from '@/views/candidate/jobs/applications/ResearchHelp';

const routes = [
    {
        path: '/candidate/jobs',
        name: 'candidate.jobs',
        component: JobIndex
    },
    {
        path: '/candidate/jobs/applications',
        name: 'candidate.applications',
        component: JobApplications
    },
    {
        path: '/candidate/jobs/applications/:job_id',
        name: 'candidate.applications.view',
        component: JobApplications
    },
    {
        path: '/candidate/jobs/applications/saved',
        name: 'candidate.applications.saved',
        component: JobSaved
    },
    {
        path: '/candidate/jobs/applications/invites',
        name: 'candidate.applications.invites',
        component: JobInvites
    },
    {
        path: '/candidate/jobs/:job_id',
        name: 'candidate.job.view',
        component: JobIndex
    },
    {
        path: '/candidate/jobs/research-help/:hashid',
        name: 'candidate.job.research-help',
        component: JobResearchHelp
    }
]

export default routes