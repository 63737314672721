<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Manage Departments</h1>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card pt-3">
                        <div class="card-header">
                            <div class="card-actions float-end">
                                <button class="btn btn-primary text-white btn-add-department" @click="createDepartment">Add Department</button>
                            </div>
                        </div>
                        <div class="card-body pt-1">
                            <BaseTable
                                :data="departments"
                                :options="headers"
                            >
                                <template v-slot:members="{ row }">
                                    <div v-if="row.members.length">
                                        <div class="badge bg-primary mr-2" v-for="i in row.members" v-bind:key="i">{{ i.name }}</div>
                                    </div>
                                    <div v-else>
                                        No members
                                    </div>
                                </template>
                                <template v-slot:description="{ row }">
                                    <div v-if="row.description" v-html="row.description"></div>
                                </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item text-primary" href="javascript:;" :data-id="row.id" @click="assignMembers(row.id, row.members)">Assign Members</a></li>
                                                <li><a class="dropdown-item" href="javascript:;" :data-id="row.id" @click="editDepartment(row.id)">Edit</a></li>
                                                <li :class="row.members.length ? 'custom_tooltip' : ''" data-tooltip="You cannot delete a department with members." style="border:none;"><a class="dropdown-item text-danger" href="javascript:;" :data-id="row.id" :disabled="row.members.length !== 0" @click="deleteDepartment(row.id)">Delete</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalAddDepartment
            :modalActive="modalActive"
            :form="department"
            :errors="errors"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @saveChanges="save"
        />
        <ModalAssignDepartment
            :modalActive="modalAssignActive"
            :form="employees"
            :employees="page.members"
            :department="department"
            :errors="errors"
            :modalTitle="`Assign Employees`"
            @close="toggleAssignModal"
            @saveChanges="assignEmployees"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this department?`"
            @cancel="toggleConfirmation"
            @isDelete="removeDepartment"
        />
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, inject } from 'vue';
import departmentRepo from '@/repositories/employer/department';
import ModalAddDepartment from '@/views/employer/settings/departments/ModalAddDepartment.vue';
import ModalAssignDepartment from '@/views/employer/settings/departments/ModalAssignDepartment.vue';

export default defineComponent({
    setup() {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Add Department',
            idToDelete: '',
            members: []
        });
        const { errors, employees, department, departments, getDepartments, getDepartment, addDepartment, destroyDepartment, updateDepartment, getCompanyEmployees, processAssignEmployees } = departmentRepo();

        const toast = inject('toast');

        const modalActive = ref(false);
        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }
        const modalAssignActive = ref(false);
        const toggleAssignModal = () => {
            modalAssignActive.value = !modalAssignActive.value;
        }
        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const headers = [
            {
                key: 'department_name',
                label: 'Department Name',
                class: 'w-20'
            },
            {
                key: 'description',
                label: 'Description',
                class: 'w-40'
            },
            {
                key: 'members',
                label: 'Members',
                class: 'w-30'
            },
            {
                key: 'action',
                label: 'Action',
                class: 'text-center'
            }
        ];

        onMounted( async () => {
            await getDepartments(page);
        });

        const createDepartment = () => {
            page.modalHeader = 'Add Department';
            toggleModal();
        }

        const editDepartment = async (id) => {
            page.isLoading = true;
            page.modalHeader = 'Edit Department';
            await getDepartment(id);
            toggleModal();
            page.isLoading = false;
        }

        const save = async (data) => {
            let formData = new FormData;
            formData.append("department_name", data.department_name);
            formData.append("description", data.description);
            formData.append("company_id", page.authuser.company_id);

            toggleModal();
            page.isLoading = true;

            if(department.value.id) {
                formData.append("department_id", department.value.id);
                await updateDepartment(formData);
            } else {
                await addDepartment(formData);
            }

            await getDepartments(page);
            department.value.department_name = '';
            department.value.description = '';
        }

        const deleteDepartment = async (id) => {
            page.idToDelete = id;
            toggleConfirmation();
        }

        const removeDepartment = async () => {
            toggleConfirmation();
            page.isLoading = true;
            await destroyDepartment(page);
            await getDepartments(page);
        }

        const assignMembers = async (id, members) => {
            page.isLoading = true;
            await getCompanyEmployees(page, id);
            await getDepartment(id);
            members.forEach((i) => {
                page.members.push({
                    user_id: i.user_id,
                    name: i.name
                });
            });
            page.isLoading = false;
            toggleAssignModal();
        }

        const assignEmployees = async (data) => {
            if(data.member_ids) {
                let formData = new FormData;
                formData.append("department_id", data.department_id);
                formData.append("user_ids", data.member_ids);

                toggleAssignModal();
                page.isLoading = true;
                await processAssignEmployees(formData);
                await getDepartments(page);
                page.members = [];
                page.isLoading = false;
            } else {
                toast.error('No selected employees.');
                toggleAssignModal();
            }
        }

        return {
            page,
            save,
            errors,
            headers,
            isConfirm,
            employees,
            department,
            departments,
            modalActive,
            toggleModal,
            assignMembers,
            editDepartment,
            assignEmployees,
            deleteDepartment,
            removeDepartment,
            createDepartment,
            modalAssignActive,
            toggleAssignModal,
            toggleConfirmation
        }
    },
    components: {
        ModalAddDepartment,
        ModalAssignDepartment
    }
});
</script>
<style>
.w-30 {
    width: 28%;
}
.w-40 {
    width: 36%;
}
.mr-2 {
    margin-right: 2pt;
}
</style>