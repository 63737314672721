import axios from "axios";
import { ref, inject } from "vue";

export default function roleRepo() {
    
    const toast = inject('toast');
    const educations = ref([]);
    const education = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');

    const getEducations = async (page) => {
        let user_id = page.authuser.id;
        let response = await axios.get(`candidate/profile/educations?user_id=${user_id}`);
        educations.value = response.data.data;
        page.isLoading = false;
    }

    const getEducation = async (id, page) => {
        let response = await axios.get(`candidate/profile/educations/${id}`);
        education.value = response.data.data;
        page.isLoading = false;
    }

    const insertEducation = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/educations`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateEducation = async (id, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/educations/${id}`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const deleteEducation = async (id) => {
        let response = await axios.delete(`candidate/profile/educations/${id}`);
        alertMessage(response.data.message);
    }

    const addMultipleEducation = async (educations, dates) => {
        errors.value = '';
        try {
            let data = {
                educations: educations,
                dates: dates
            }
            let response = await axios.post(`candidate/profile/educations/add-multi-educations`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }
    
    return {
        educations,
        education,
        getEducations,
        getEducation,
        insertEducation,
        updateEducation,
        deleteEducation,
        addMultipleEducation,
        alertMessage,
        errors,
        message,
        status
    }
}