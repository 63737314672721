<template>
    <interviewTrainingComponent 
        :userRole="page.userRole"
        @openInterviewTrainingModal="openModal"
    />
    <ModalInterviewTraining 
        :modalActive="modalActive"
        :selectedInterviewTraining="page.selectedInterviewTraining"
        :modalTitle="page.modalTitle"
        @closeCandidateInterviewTrainingModal="close"
    />
</template>

<script>
import interviewTrainingComponent from '@/components/interviewTraining/index';
import ModalInterviewTraining from '@/views/candidate/interviewTraining/ModalInterviewTraining.vue';

import { defineComponent, reactive, onMounted, ref } from 'vue';

export default defineComponent ({
    setup() {
        const page = reactive({
            api_url: process.env.VUE_APP_API_URL,
            userRole: 'candidate',
            selectedInterviewTraining: null,
            modalTitle: '',
        });

        const modalActive = ref(false);

        const openModal = async (data) => {
            page.selectedInterviewTraining = data.selectedInterviewTraining;
            page.modalTitle = data.modalTitle;
            if (data.selectedInterviewTraining.type == "Video") {
                await toggleModal();
            } else {
                window.open(data.selectedInterviewTraining.link, '_blank').focus();
            }
        }

        const toggleModal = async () => {
            modalActive.value = !modalActive.value;
        }

        const close = async () => {
            await toggleModal();
        }

        return {
            page,
            openModal,
            close, 
            modalActive,
            toggleModal
        }
    },
    components: {
        interviewTrainingComponent, 
        ModalInterviewTraining
    }
});
</script>
