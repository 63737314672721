<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div>
                        <h1 class="h3 mb-3">Messages</h1>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card message-card">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-3 border-right message-left-panel">
                                    <div class="search-messages p-3">
                                        <BaseInput 
                                            v-model="page.searchValue"
                                            type="text"
                                            id="search"
                                            placeholder="Search Conversations"
                                            @keyup="processSearch"
                                        />
                                        <i class="fa fa-search"></i>
                                    </div>
                                    <div class="conversations">
                                        <div v-if="page.isLoading" style="padding-top: 10%;"><Loading /></div>
                                        <div v-else>
                                            <div id="conversation-list" class="conversation-list" v-if="conversations.length > 0 && !page.searchValue">
                                                <div v-for="(row, i) in conversations" :key="i">
                                                    <ConversationItem
                                                        :user_id="row.user_id"
                                                        :hasUnread="row.hasUnread"
                                                        :hasSubDetails="row.hasSubDetails"
                                                        :unreadCount="row.unreadCount"
                                                        :isOnline="row.isOnline"
                                                        :isOffline="row.isOffline"
                                                        :isAway="row.isAway"
                                                        :isDontDisturb="row.isDontDisturb"
                                                        :image="row.image"
                                                        :fullName="row.fullName"
                                                        :subDetails="row.subDetails"
                                                        :chatStatusText="row.chatStatusText"
                                                        @trigger_action="setMode( 'conversation-info', 
                                                            row.user_id,
                                                            row.hasSubDetails,
                                                            row.isOnline,
                                                            row.isOffline,
                                                            row.isAway,
                                                            row.isDontDisturb,
                                                            row.image,
                                                            row.fullName,
                                                            row.subDetails,
                                                            row.chatStatusText,
                                                            row.conversation_id
                                                        )"
                                                    />
                                                </div>
                                            </div>
                                            <div class="conversation-list" v-else-if="page.searchValue">
                                                <div v-for="(row, i) in page.searchedData" :key="i">
                                                    <ConversationItem
                                                        :user_id="row.user_id"
                                                        :hasUnread="row.hasUnread"
                                                        :hasSubDetails="row.hasSubDetails"
                                                        :unreadCount="row.unreadCount"
                                                        :isOnline="row.isOnline"
                                                        :isOffline="row.isOffline"
                                                        :isAway="row.isAway"
                                                        :isDontDisturb="row.isDontDisturb"
                                                        :image="row.image"
                                                        :fullName="row.fullName"
                                                        :subDetails="row.subDetails"
                                                        :chatStatusText="row.chatStatusText"
                                                        @trigger_action="setMode( 'conversation-info', 
                                                            row.user_id,
                                                            row.hasSubDetails,
                                                            row.isOnline,
                                                            row.isOffline,
                                                            row.isAway,
                                                            row.isDontDisturb,
                                                            row.image,
                                                            row.fullName,
                                                            row.subDetails,
                                                            row.chatStatusText,
                                                            row.conversation_id
                                                        )"
                                                    />
                                                </div>
                                            </div>
                                            <div class="conversation-list empty" v-else>
                                                <div>
                                                    No conversations yet.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9 message-right-panel">
                                    <div :class="{ 'conversation-info' : true, 'force-hide' : page.modes === 'conversation-info' ? false : true }">
                                        <DisplayInfoHeader
                                            :user_id="page.displayInfo.user_id"
                                            :hasSubDetails="page.displayInfo.hasSubDetails"
                                            :isOnline="page.displayInfo.isOnline"
                                            :isOffline="page.displayInfo.isOffline"
                                            :isAway="page.displayInfo.isAway"
                                            :isDontDisturb="page.displayInfo.isDontDisturb"
                                            :image="page.displayInfo.image"
                                            :fullName="page.displayInfo.fullName"
                                            :subDetails="page.displayInfo.subDetails"
                                            :chatStatusText="page.displayInfo.chatStatusText"
                                            @action_headers="markUnread"
                                            @action_delete="confirmDeleteConversation"
                                        />
                                        <div v-if="page.isMessageLoading" style="padding-top: 15%; height: 350px;"><PageLoader /></div>
                                        <MessageBox :messages="messages" v-else />
                                        <MessageComposer :disabled="page.isMessageLoading" :form="page" @trigger_post="processPostMessage" @watch_textarea="processPostMessage" />
                                    </div>
                                    <div :class="{ 'select-conversation' : true, 'force-hide' : page.modes === 'select-conversation' ? false : true }">
                                        <span><i class="fa fa-envelope"></i>Please select a conversation.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this conversation?`"
            @cancel="toggleConfirmation"
            @isDelete="processDeleteConversation"
        />
    </main>
</template>

<script>
import { defineComponent, ref, reactive, onMounted , watchEffect} from 'vue';
import BaseInput from '@/components/modules/form/BaseInput.vue';
import Loading from '@/components/modules/PageLoader.vue';
import ConversationItem from '@/components/messages/ConversationItem.vue';
import MessageComposer from '@/components/messages/MessageComposer.vue';
import MessageBox from '@/components/messages/MessageBox.vue';
import DisplayInfoHeader from '@/components/messages/DisplayInfoHeader.vue';
import CandidateMessagesRepo from '@/repositories/candidate/messages';
import { useRouter } from 'vue-router'
import Confirm from '@/components/modules/Confirmation.vue';
import _debounce from 'lodash/debounce';
import globalRepo from '@/repositories/global';
import Pusher from 'pusher-js';

export default defineComponent({
    setup() {
        const { pusher_messaging_data } = globalRepo();
        const { messages, conversations, getCandidateConversations, getCandidateMessages, postCandidateMessage, assignCandidateConversation, setReadMessage, setUnReadMessage, deleteConversation } = CandidateMessagesRepo();
        const router = useRouter();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            api_url: process.env.VUE_APP_API_URL,
            modes: 'select-conversation',
            show_options: false,
            displayInfo: {
                conversation_id: '',
                user_id: '',
                hasSubDetails: '',
                isOnline: false,
                isOffline: false,
                isDontDisturb: false,
                isAway: false,
                image: '',
                fullName: '',
                subDetails: '',
                chatStatusText: ''
            },
            message: '',
            isLoading: true,
            isMessageLoading: true,
            targetUserId: router.currentRoute.value.params.user_id ?? false,
            searchValue: '',
            searchedData: []
        });

        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        onMounted( async () => {
            let currentuserid;
            await getCandidateConversations(page);
            if(page.targetUserId && page.targetUserId != page.authuser.id){

                await assignCandidateConversation(page);
                await getCandidateConversations(page);

                for( var i=0; i < conversations.value.length; i++ ) {
                    if(conversations.value[i].user_id == page.targetUserId) {
                        currentuserid = conversations.value[i].user_id;
                        setMode( 'conversation-info', 
                            conversations.value[i].user_id,
                            conversations.value[i].hasSubDetails,
                            conversations.value[i].isOnline,
                            conversations.value[i].isOffline,
                            conversations.value[i].isAway,
                            conversations.value[i].isDontDisturb,
                            conversations.value[i].image,
                            conversations.value[i].fullName,
                            conversations.value[i].subDetails,
                            conversations.value[i].chatStatusText,
                            conversations.value[i].conversation_id
                        )
                    }
                }
            }

            // TODO::Use Pusher actual key when deployed to production
            const pusher = new Pusher( pusher_messaging_data.appkey , {
                cluster: pusher_messaging_data.cluster
            });
            const channel = pusher.subscribe(pusher_messaging_data.channel);
            channel.bind(pusher_messaging_data.broadcast, async data => {
                let target_data = JSON.parse(data.message_data);
                if((target_data.sender_id != page.authuser.id) && (target_data.user_id == page.authuser.id)){
                    let current_obj = {};
                    await getCandidateConversations(page);
                    if(currentuserid) {
                        current_obj = conversations.value.find(o => o.user_id === currentuserid);
                        page.displayInfo.isOnline = current_obj.isOnline;
                        page.displayInfo.isOffline = current_obj.isOffline;
                        page.displayInfo.chatStatusText = current_obj.chatStatusText;
                    }
                }
            });
        });

        const setMode = (actiontype, id, hasSubDetails, isOnline, isOffline, isAway, isDontDisturb, image, fullName, subDetails, chatStatusText, c_id ) => {
            let itemElement = document.getElementById('conversation-item-'+id);
            let lists = [...document.body.querySelectorAll('#conversation-list .conversation-item')]
            page.modes = actiontype
            lists.forEach(el => { [...el.parentElement.children].forEach(sib => sib.classList.remove('active')) })
            itemElement.classList.add('active');
            itemElement.classList.remove('has-unread');
            itemElement.getElementsByClassName("message-count")[0].classList.add('force-hide');

            page.displayInfo.conversation_id = c_id
            page.displayInfo.user_id = id
            page.displayInfo.hasSubDetails = hasSubDetails
            page.displayInfo.isOnline = isOnline
            page.displayInfo.isOffline = isOffline
            page.displayInfo.isDontDisturb = isAway
            page.displayInfo.isAway = isDontDisturb
            page.displayInfo.image = image
            page.displayInfo.fullName = fullName
            page.displayInfo.subDetails = subDetails
            page.displayInfo.chatStatusText = chatStatusText

            router.push(`/candidate/messages/${page.displayInfo.user_id}`)
            page.isMessageLoading = true;
            getCandidateMessages(page);
            setReadMessage(page);
        }

        const processPostMessage = async () => {
            page.isMessageLoading = true;
            let userData = new FormData();
            let objDiv = document.getElementById('message-box');
            userData.append('user_id', page.authuser.id ?? '');
            userData.append('conversation_id', page.displayInfo.conversation_id ?? '');
            userData.append('message', page.message ?? '');
            page.message = '';
            
            await postCandidateMessage(userData)
            await getCandidateMessages(page)
            page.isMessageLoading = false;
            objDiv.scrollTop = objDiv.scrollHeight;
        }

        const processSearch = async () => {
            page.isLoading = true;
            await searchConversation();
            page.searchedData = conversations;
        }

        const searchConversation = _debounce(function (event) {
            getCandidateConversations(page, page.searchValue);
        }, 750);

        const markUnread = async () => {
            page.isLoading = true;
            await setUnReadMessage(page);
            getCandidateConversations(page);
            page.modes = 'select-conversation'
            router.push(`/candidate/messages/`)
        }

        const confirmDeleteConversation = () => {
            toggleConfirmation();
        }

        const processDeleteConversation = async () => {
            toggleConfirmation();
            await deleteConversation(page);
            page.modes = 'select-conversation'
            router.push(`/candidate/messages/`)
            await getCandidateConversations(page)
        }

        return {
            page,
            setMode,
            conversations,
            messages,
            getCandidateConversations,
            getCandidateMessages,
            processPostMessage,
            searchConversation,
            assignCandidateConversation,
            setReadMessage,
            setUnReadMessage,
            markUnread,
            isConfirm,
            toggleConfirmation,
            confirmDeleteConversation,
            processDeleteConversation,
            processSearch
        }
    },
    components: {
        BaseInput,
        ConversationItem,
        MessageComposer,
        MessageBox,
        DisplayInfoHeader,
        Loading,
        Confirm
    }
})
</script>

<style>
    .force-hide {
        display: none !important;
    }
    .message-card {
        margin-bottom: 0 !important;
    }
    main.content {
        padding-top: 20px;
    }
    .message-left-panel {
        padding-right: 0 !important;
    }
    .message-right-panel {
        padding-left: 0 !important;
    }
    .border-right {
        border-right: 1px solid #dddddd;
    }
    .is-inline {
        display: inline-block;
        vertical-align: middle;
    }
    .search-messages .form-group.mb-3 {
        margin-bottom: 0 !important;
    }
    .conversations {
        max-height: 475px;
        overflow-x: auto;
    }
    .select-conversation {
        height: 538px;
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        width: 100%;
    }
    .select-conversation span {
        display: inline-block;
        vertical-align: middle;
        height: 29px;
        text-align: center;
        width: 1025px;
        font-size: 23px;
        color: #a3a3a3;
    }
    .select-conversation span i {
        display: inline-block;
        width: 100%;
        font-size: 50px;
        color: #d2d2d2;
    }
    .conversation-list.empty {
        text-align: center;
        margin-top: 20px;
        color: #b7b7b7;
        font-size: 17px;
    }
    .search-messages.p-3 {
        border-bottom: 1px solid #e6e6e6;
        position: relative;
    }
    .search-messages i {
        position: absolute;
        right: 25px;
        top: 25px;
        color: #ababab;
    }
</style>