import axios from "axios";
import { ref, inject } from "vue";

export default function roleRepo() {
    
    const toast = inject('toast');
    const notification_setting = ref([]);
    const notifications = ref([]);
    const reminders = ref([]);
    const notification = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');

    const getNotificationSetting = async (page) => {
        let response = await axios.get(`employer/notification-settings?user_id=${page.authuser.id}`);
        notification_setting.value = response.data;
        page.isLoading = false;
    }

    const updateNotificationSetting = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/notification-settings`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getNotifications = async (page) => {
        let response = await axios.get(`employer/notifications?user_id=${page.authuser.id}&page=${page.currentPage}`);
        notifications.value.push(...response.data.data);
        page.lastPage = response.data.meta.last_page;
        page.isLoading = false;
    }

    const getReminders = async (page) => {
        let response = await axios.get(`employer/notifications/get-reminders?user_id=${page.authuser.id}&page=${page.currentPage}`);
        reminders.value.push(...response.data.data);
        page.lastPage = response.data.meta.last_page;
        page.isLoading = false;
    }

    const updateNotificationToRead = async (page, type, id = '') => {
        try {
            await axios.post(`employer/notifications`, {
                user_id: page.authuser.id,
                notification_type: type,
                status: 'Read',
                id: id
            });
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const resetNotification = async (page) => {
        let response = await axios.get(`employer/notifications/reset-notification?user_id=${page.authuser.id}`);
        let notification_count = {
            unread_conversations: response.data.unread_conversations,
            unread_notifications: response.data.unread_notifications,
            unread_reminders: response.data.unread_reminders
        }

        localStorage.setItem('notification', JSON.stringify(notification_count));
    }

    const alertMessage = (alert) => {
        toast.success(alert);
    }
    
    return {
        notification_setting,
        getNotificationSetting,
        updateNotificationSetting,
        errors,
        message,
        status,
        alertMessage,
        notifications,
        notification,
        getNotifications,
        getReminders,
        reminders,
        updateNotificationToRead,
        resetNotification
    }
}