import axios from "axios";
import { ref, inject } from "vue";

export default function questionRepo() {
    
    const questions = ref([]);
    const question_categories = ref([]);
    const question_category = ref([]);
    const question = ref([]);
    const screening = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const dealbreaker = ref(false);
    const toast = inject('toast');

    const getQuestions = async (page) => {
        let response = await axios.get(`employer/questions?company_id=${page.authuser.company_id}`);
        questions.value = response.data.data;
        page.isLoading = false;
    }

    const getQuestionCategories = async (page) => {
        let response = await axios.get(`employer/questions/categories?company_id=${page.authuser.company_id}`);
        question_categories.value = response.data;
        page.isLoading = false;
    }

    const getQuestion = async (id) => {
        let response = await axios.get(`employer/questions/${id}`);
        question.value = response.data.data;
    }

    const getQuestionCategory = async (id) => {
        let response = await axios.get(`employer/questions/category?category_id=${id}`);
        question_category.value = response.data;
    }

    const addQuestion = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/questions`, data);
            question.value = response.data.question;
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const addQuestionCategory = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/questions/category/save`, data);
            question_category.value = response.data.question;
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateQuestion = async (data, id) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/questions/${id}`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateQuestionCategory = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`employer/questions/category/change`, data);
            question_category.value = response.data.question;
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const destroyQuestion = async (id) => {
        let response = await axios.delete(`employer/questions/${id}`);
        status.value = response.data.status;
        alertMessage(response.data.message);
    }

    const destroyQuestionCategory = async (id) => {
        let response = await axios.get(`employer/questions/category/delete?category_id=${id}`);
        status.value = response.data.status;
        alertMessage(response.data.message);
    }

    const checkDealBreaker = async (page, id) => {
        let response = await axios.get(`employer/questions/deal-breaker?id=${id}`);
        page.isDealBreaker = response.data.dealbreaker;
    }

    const resetQuestionaire = async (page, data) => {
        let formData = { ids: data, company_id: page.authuser.company_id };
        let response = await axios.post(`employer/questions/reset-questionaire`, formData);
        questions.value = response.data.data;
    }

    const getCurrentScreening = async (data) => {
        let response = await axios.get(`employer/job-screening?job_id=${data.id}`);
        screening.value = response.data.data;
    }

    const removeCurrentQuestions = async (data) => {
        let response = await axios.post(`employer/job-screening/remove-question`, data);
        questions.value = response.data.data;
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }
    
    return {
        questions,
        question,
        getQuestions,
        getQuestion,
        addQuestion,
        updateQuestion,
        destroyQuestion,
        checkDealBreaker,
        resetQuestionaire,
        getCurrentScreening,
        removeCurrentQuestions,
        getQuestionCategories,
        getQuestionCategory,
        destroyQuestionCategory,
        addQuestionCategory,
        updateQuestionCategory,
        errors,
        message,
        status,
        dealbreaker,
        screening,
        question_categories,
        question_category
    }
}