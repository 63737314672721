<template>
    <div>
        <div class="row">
            <div class="col-2">
                <div class="card shadow mt-3">
                    <div class="card-body">
                        <h5 class="filter-text-header"><i class="fa fa-sliders pe-2"></i>FILTERS</h5>
                        <hr style="opacity: 1;" />
                        <div v-if="!page.viewSavedFilters">
                            <input class="form-control" type="search" placeholder="Search Course..." v-model="selected.keyword">
                            <button class="btn btn-primary btn-sm w-100 mt-2" :disabled="!notEmptyFilter" @click="toggleSaveFilterModal"> Save Filter </button>
                            <button class="btn btn-danger btn-sm w-100 mt-1" :disabled="!notEmptyFilter" @click="clearFilter"> Clear Filter </button>
                            <button class="btn btn-light btn-sm w-100 mt-1" :disabled="!saved_filters.length" @click="viewSavedFiltersAction('saved-filters')"> Saved Filters </button>
                        </div>

                        <div v-if="page.viewSavedFilters">
                            <h5 class="lead mt-3"><strong>Saved Filters</strong></h5>
                            <select class="form-select" v-model="page.selectedSavedFilter" @change="updateFilter">
                                <option value="0" disabled>Please select</option>
                                <option v-for="i in saved_filters" v-bind:key="i" :value="i.id">{{ i.filter_name }}</option>
                            </select>
                            <button class="btn btn-danger btn-sm w-100 mt-2" v-if="page.selectedSavedFilter !== 0" @click="processDeleteSavedFilter"> Delete Saved Filter </button>
                            <button class="btn btn-primary btn-sm w-100 mt-2" @click="viewSavedFiltersAction('custom-filters')"> Custom Filter </button>
                        </div>

                        <div v-if="!page.viewSavedFilters">
                            <h5 class="lead mt-3"><strong>Skill Type</strong></h5>
                            <div v-for="(type, i) in assigned_course_filters" :key="i" class="d-flex">
                                <input type="checkbox" :value="type.name" :id="'skill_type'+i" v-model="selected.skill_type">
                                <label :for="`skill_type${i}`" class="ml-7px text-sm">{{ type.name }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Course Type</strong></h5>
                            <div class="d-flex">
                                <input type="checkbox" value="Basic" id="basic" v-model="selected.course_type">
                                <label for="basic" class="ml-7px text-sm">Basic</label>
                            </div>
                            <div class="d-flex">
                                <input type="checkbox" value="Premium" id="premium" v-model="selected.course_type">
                                <label for="premium" class="ml-7px text-sm">Premium</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Difficulty</strong></h5>
                            <div v-for="(diff, i) in course_difficulty" :key="i" class="d-flex">
                                <input type="checkbox" :value="diff.name" :id="'difficulty'+i" v-model="selected.difficulty">
                                <label :for="`difficulty${i}`" class="ml-7px text-sm">{{ diff.name }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Source</strong></h5>
                            <div v-for="(source, i) in searchFilters.sources" :key="i" class="d-flex">
                                <input type="checkbox" :value="source.id" :id="'source'+i" v-model="selected.source">
                                <label :for="`source${i}`" class="ml-7px text-sm">{{ source.name }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Author</strong></h5>
                            <div v-for="(author, i) in searchFilters.author" :key="i" class="d-flex">
                                <input type="checkbox" :value="author" :id="'author'+i" v-model="selected.author">
                                <label :for="`author${i}`" class="ml-7px text-sm">{{ author }}</label>
                            </div>

                            <h5 class="lead mt-3"><strong>Tags</strong></h5>
                            <div v-for="(tag, i) in searchFilters.tags" :key="i" class="d-flex">
                                <input type="checkbox" :value="tag" :id="'tag'+i" v-model="selected.tags">
                                <label :for="`tag${i}`" class="ml-7px text-sm">{{ tag }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-10">
                <div class="mt-3" v-if="private_courses.length">
                    <div class="col-private" v-for="privateCourse in private_courses" :key="privateCourse.id">
                        <div class="card shadow">
                            <div class="d-flex justify-content-between card-absolute">
                                <div class="d-flex align-items-center">
                                    <span class="badge bg-warning text-white" v-if="privateCourse.course_started && privateCourse.course_completed !== true">In Progress</span>
                                    <span class="badge bg-success text-white" v-if="privateCourse.course_completed == true">Completed</span>
                                </div>
                                <div class="card-btn-actions">
                                    <div class="dropdown position-relative">
                                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-more-horizontal align-middle"
                                            >
                                                <circle cx="12" cy="12" r="1"></circle>
                                                <circle cx="19" cy="12" r="1"></circle>
                                                <circle cx="5" cy="12" r="1"></circle>
                                            </svg>
                                        </a>

                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a class="dropdown-item" href="javascript:;" v-if="privateCourse.status == ''" @click="courseAction(1, privateCourse.id)">Mark as In progress</a>
                                            <a class="dropdown-item" href="javascript:;" v-if="privateCourse.status == 'In Progress'" @click="courseAction(3, privateCourse.id)">Mark as completed</a>
                                            <a class="dropdown-item" href="javascript:;" @click="viewTraining(privateCourse.id)">Assign to Employee</a>
                                            <a class="dropdown-item" href="javascript:;" @click="editCourse(privateCourse.id)">Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:;" @click="redirectTrainingCourse(privateCourse.id)" class="card-link">
                                <img :src="privateCourse.thumbnail" class="card-img-top object-cover course-img">
                                <div class="px-2 text-center text-white bg-primary">Private Training Course</div>
                                <div class="card-header card-header-items px-4 pt-4">
                                    <h5 class="card-title" v-text="privateCourse.title_limit"></h5>
                                </div>
                            </a>
                            <div class="card-body training-card">
                                <p class="card-text carousel-card-text">
                                    {{ privateCourse.author ?? privateCourse.company?.company_name }}<br>
                                    <b>{{ privateCourse.source_name ?? 'Private Course' }}</b>
                                </p>
                                <div class="mt-3" v-html="privateCourse.display_learning_plans"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-3">
                    <div class="">
                        <span v-if="courseFiltered.length > 0" class="me-3">Results found {{courseFiltered.length}}</span>
                        <span v-for="(filter, i) in selected.skill_type" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                        <span v-for="(filter, i) in selected.difficulty" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                        <span v-for="(filter, i) in selected.source" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{sourceFilterData(filter)[0].name}}
                        </span>
                        <span v-for="(filter, i) in selected.author" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                        <span v-for="(filter, i) in selected.tags" :key="i" class="badge rounded-pill bg-primary w-10 ms-1" style="width: fit-content;display: inline-block;">
                            {{filter}}
                        </span>
                    </div>
                </div>
                <div class="mt-2" v-if="courseFiltered.length">
                    <div class="col-private" v-for="training in courseFiltered" :key="training">
                        <div class="card shadow w-100">
                            <div class="d-flex justify-content-between card-absolute">
                                <div class="d-flex align-items-center">
                                    <span class="badge bg-warning text-white" v-if="training.course_started && training.course_completed !== true">In Progress</span>
                                    <span class="badge bg-success text-white" v-if="training.course_completed == true">Completed</span>
                                </div>
                                <div class="card-btn-actions">
                                    <div class="dropdown position-relative">
                                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-more-horizontal align-middle"
                                            >
                                                <circle cx="12" cy="12" r="1"></circle>
                                                <circle cx="19" cy="12" r="1"></circle>
                                                <circle cx="5" cy="12" r="1"></circle>
                                            </svg>
                                        </a>

                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a class="dropdown-item" href="javascript:;" v-if="training.status == ''" @click="courseAction(1, training.id)">Mark as In progress</a>
                                            <a class="dropdown-item" href="javascript:;" v-if="training.status == 'In Progress'" @click="courseAction(3, training.id)">Mark as completed</a>
                                            <a class="dropdown-item" href="javascript:;" @click="viewTraining(training.id)">Assign to Employee</a>
                                            <a v-if="page.authuser.company_id == training.company_id" class="dropdown-item" href="javascript:;" @click="editCourse(training.id)">Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a :href="training.link" target="_blank" class="card-link">
                                <img :src="training.thumbnail" class="card-img-top object-cover course-img">
                                <div class="card-header card-header-items px-4 pt-4">
                                    <h5 class="card-title" v-text="training.title_limit"></h5>
                                </div>
                            </a>
                            <div class="card-body training-card">
                                <p class="card-text carousel-card-text">
                                    {{ training.author ?? training.company?.company_name }}<br>
                                    <b>{{ training.source_name ?? 'Private Course' }}</b>
                                </p>
                                <div class="mt-3" v-html="training.display_learning_plans"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" v-else>
                    <div class="text-center">
                        <img src="/assets/images/empty.png" class="img-fluid img-30">
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay position-fixed full-screen-loader text-primary" v-if="page.isfullScreenLoading">
            <i class="fa fa-spin fa-spinner fa-3x"></i>
        </div>
        <ModalTraining
            :modalActive="modalActive"
            :form="training"
            :modalTitle="page.modalHeader"
            @close="toggleModal"
            @saveChanges="save"
        />
        <ModalSaveFilter
            :modalActive="modalSaveFilterActive"
            :form="savefilter"
            :modalTitle="`Save Filter`"
            @close="toggleSaveFilterModal"
            @saveChanges="saveFilterAction"
        />
        <Confirm 
            :is-confirm="isConfirmDelete"
            :confirm-text="`Are you sure you want to delete this saved filter?`"
            @cancel="toggleConfirmationDelete"
            @isDelete="deleteFilter"
        />
    </div>
</template>

<script>
import ModalTraining from '@/views/employer/trainings/modals/ModalTraining.vue';
import ModalSaveFilter from '@/views/employer/trainings/modals/ModalSaveFilter.vue';
import { ref, reactive, onMounted, onBeforeUpdate, watch, inject, computed } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import employerTrainingRepo from '@/repositories/employer/trainings';
import globalRepo from '@/repositories/global';

export default {
    props: {
        trainings: {
            type: Array,
            default: []
        },
        filterDatas: {
            type: [Array, Object],
            default: []
        }
    },
    setup(props, {emit}) {
        const toast = inject('toast');
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            modalHeader: 'Training Action',
            viewSavedFilters: false,
            isfullScreenLoading: false,
            selectedSavedFilter: 0
        });
        const { getTraining, training, getPrivateLearningPlanTemplates, getCourseSelectedFilter, searchFilters, getCourseFilter, courseFiltered, saved_filters, getSavedFilters, saveTrainingFilter, deleteSavedFilter } = trainingRepo();
        const {
            updateCourse,
            status,
            getCompanyPrivateCourse,
            private_courses,
            private_plans,
            errors,
            getPrivateLearningPlans,
            getPrivateLearningPlan,
            learning_plan
        } = employerTrainingRepo();
        const { assigned_course_filters, course_difficulty } = globalRepo();

        const modalActive = ref(false);
        const modalSaveFilterActive = ref(false);
        const isConfirmDelete = ref(false);
        const selected = ref({
            skill_type: [],
            course_type: [],
            difficulty: [],
            source: [],
            author: [],
            tags: [],
            keyword: ''
        })

        const savefilter = ref({
            filter_name: ''
        });

        const clearFilter = () => {
            selected.value.skill_type = [];
            selected.value.course_type = [];
            selected.value.difficulty = [];
            selected.value.source = [];
            selected.value.author = [];
            selected.value.tags = [];
            selected.value.keyword = '';
        }

        const sourceFilterData = (id) => {
            return searchFilters.value.sources.filter(data => data.id == id)
        }

        watch(selected.value,
            async () => {
                var params = {
                    skill_type: selected.value.skill_type,
                    course_type: selected.value.course_type,
                    difficulty: selected.value.difficulty,
                    source: selected.value.source,
                    author: selected.value.author,
                    tags: selected.value.tags,
                    keyword: selected.value.keyword
                }
                await getCourseFilter(params, page)
            },
            { deep: true }
        );

        watch(() => props.filterDatas.isDataLoaded, async () => {
            let postData = new FormData();
            postData.append('company_id', page.authuser.company_id);
            await getCompanyPrivateCourse(postData);
        });

        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        }
        const toggleSaveFilterModal = () => {
            modalSaveFilterActive.value = !modalSaveFilterActive.value;
        }

        const viewTraining = (id) => {
            getTraining(id);
            toggleModal();
        }

        const save = async (event) => {
            let formData = new FormData();
            formData.append('user_ids', event.user_ids);
            formData.append('company_id', page.authuser.company_id);
            formData.append('training_id', training.value.id);
            formData.append('user_id', page.authuser.id);
            formData.append('action', 2);

            if(event.user_ids.length) {
                await updateCourse(formData);
                if(status.value == 200) {
                    toggleModal();
                }
            } else {
                toast.error('Please specify an employee.');
            }
        }

        const courseAction = async (action, training_id) => {
            let formData = new FormData();
            formData.append('company_id', page.authuser.company_id);
            formData.append('training_id', training_id);
            formData.append('user_id', page.authuser.id);
            formData.append('action', action);

            await updateCourse(formData);
            if(status.value == 200) {

                let postData = new FormData();
                postData.append('company_id', page.authuser.company_id);
                await getCompanyPrivateCourse(postData);
                await getPrivateLearningPlans(page);
                await getPrivateLearningPlanTemplates();
                await getCourseSelectedFilter();
                await getCourseFilter({}, page);
                emit('refresh-page');
            }
        }

        onMounted( async () => {
            const company_id = page.authuser.company_id

            let formData = new FormData();
            formData.append('company_id', company_id);
            await getCompanyPrivateCourse(formData);

            await getPrivateLearningPlans(page);
            await getPrivateLearningPlanTemplates();
            await getCourseSelectedFilter();
            await getCourseFilter({}, page);
            await getSavedFilters(page);
        });

        const editCourse = async (id) => {
            await getPrivateLearningPlan(id);
            emit('edit-private-course', learning_plan.value);
        }

        const checkCourseStatus = (courses) => {
            const userid = page.authuser.id
            const course = courses?.filter( c => c.user_id === userid)
            return course?.length > 0 ? course[0]: 0
        }

        const createPrivateCourse = () => {
            learning_plan.value = [];
            page.modalHeader = 'Add Private Course'
        }

        onBeforeUpdate(() => {
            if(props.triggerPrivateCourse == true) {
                learning_plan.value = [];
                page.modalHeader = 'Add Private Course'
            }
        });

        const notEmptyFilter = computed(() => {
            return (selected.value.skill_type.length || selected.value.course_type.length || selected.value.difficulty.length || selected.value.source.length || selected.value.author.length || selected.value.tags.length || selected.value.keyword != '') ? true:false;
        });

        const viewSavedFiltersAction = (action) => {
            clearFilter();
            if(action == 'saved-filters') {
                if(page.selectedSavedFilter !== 0) {
                    updateFilter();
                }
                page.viewSavedFilters = true;
            }
            if(action == 'custom-filters') {
                page.viewSavedFilters = false;
            }
        }

        const saveFilterAction = async (data) => {
            toggleSaveFilterModal();
            page.isfullScreenLoading = true;
            let formData = new FormData();

            // stuff below
            formData.filter_name = data.filter_name;
            formData.skill_type = selected.value.skill_type;
            formData.course_type = selected.value.course_type;
            formData.difficulty = selected.value.difficulty;
            formData.source = selected.value.source;
            formData.author = selected.value.author;
            formData.tags = selected.value.tags;
            formData.keyword = selected.value.keyword;
            formData.user_id = page.authuser.id;

            await saveTrainingFilter(page, formData);
            await getSavedFilters(page);
            savefilter.value.filter_name = '';
            // clear after saving filter
            clearFilter();
        }

        const updateFilter = () => {
            clearFilter();

            let filtered_data = JSON.stringify(saved_filters.value.filter( f => { return f.id === page.selectedSavedFilter }));
            let filtered_json = JSON.parse(filtered_data)[0];
            let json_data = JSON.parse(filtered_json.json_data);

            selected.value.skill_type = json_data.skill_type;
            selected.value.course_type = json_data.course_type;
            selected.value.difficulty = json_data.difficulty;
            selected.value.source = json_data.source;
            selected.value.author = json_data.author;
            selected.value.tags = json_data.tags;
            selected.value.keyword = json_data.keyword;
        }

        const processDeleteSavedFilter = () => {
            toggleConfirmationDelete();
        }

        const deleteFilter = async () => {
            page.isfullScreenLoading = true;
            await deleteSavedFilter(page, page.selectedSavedFilter);
            page.selectedSavedFilter = 0;
            clearFilter();
            toggleConfirmationDelete();
            await getSavedFilters(page);
        }

        const toggleConfirmationDelete = () => {
            isConfirmDelete.value = !isConfirmDelete.value;
        }

        const redirectTrainingCourse = async (id) => {
            page.isfullScreenLoading = true;
            await getTraining(id);
            localStorage.setItem('videoPlayer', JSON.stringify(training.value));
            setTimeout(() => {
                window.open('/employer/trainings/'+id);
                page.isfullScreenLoading = false;
            }, 2000);
        }

        return {
            page,
            modalActive,
            toggleModal,
            viewTraining,
            save,
            getTraining,
            training,
            updateCourse,
            status,
            courseAction,
            private_plans,
            errors,
            learning_plan,
            editCourse,
            private_courses,
            checkCourseStatus,
            createPrivateCourse,
            selected,
            assigned_course_filters,
            course_difficulty,
            clearFilter,
            searchFilters,
            courseFiltered,
            sourceFilterData,
            notEmptyFilter,
            viewSavedFiltersAction,
            toggleSaveFilterModal,
            modalSaveFilterActive,
            savefilter,
            saveFilterAction,
            saved_filters,
            updateFilter,
            processDeleteSavedFilter,
            deleteFilter,
            isConfirmDelete,
            toggleConfirmationDelete,
            redirectTrainingCourse
        }
    },
    components: {
        ModalTraining,
        ModalSaveFilter
    }
}
</script>

<style scoped>
.card-absolute {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    margin-top: 0.5rem;
}
.card-btn-actions {
    background-color: #fafafa;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}
.feather {
    width: 24px !important;
    height: 24px !important;
    padding: 0 3px;
}

.card-title {
    min-height: 35px!important;
}
.col-private {
    width: 240px!important;
    display: inline-block;
    margin-right: 10px;
}
.create-private-btn {
    width:220px;
    float:right;
}
.training-card {
    height: 140px;
}
.card-header-items {
    height: 105px;
}
.ml-7px {
    margin-left: 7px;
}
.text-sm {
    font-size: 14px !important;
}
.filter-text-header {
    font-size: 17px;
    font-weight: 500;
}
</style>