<template>
  <div class="tab-pane fade show active" id="certifications" role="tabpanel">
    <div class="card">
      <div class="card-header pb-0">
        <h5 class="card-title mb-0">Certifications</h5>
      </div>
      <div class="card-header">
        <div class="card-actions float-end">
          <button class="btn btn-primary text-white btn-add-certificate" @click="addCertificate">Add a Certification</button>
        </div>
      </div>
      <div class="card-body">
        <BaseTable :data="certificates" :options="headers">
          <template v-slot:description="{ row }">
            <div v-html="row.description"></div>
          </template>
          <template v-slot:action="{ row }">
            <div class="meatballs-menu">
              <div class="btn-group">
                <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown"
                  aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="javascript:;" @click="editCertificate(row.id)">Edit</a></li>
                  <li><a class="dropdown-item text-danger" href="javascript:;"
                      @click="destroyCertificate(row.id)">Remove</a></li>
                </ul>
              </div>
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
    <ModalCertificate :modalActive="modalActive" :form="certificate" :errors="errors" :modalTitle="page.modalTitle"
      @close="toggleModal" @saveChanges="save" />
    <Confirm :is-confirm="isConfirm" :confirm-text="`Are you sure you want to remove this item?`"
      @cancel="toggleConfirmation" @isDelete="processDelete" />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import ModalCertificate from '@/views/candidate/settings/profile/ModalCertificate.vue';
import certificateRepo from '@/repositories/candidate/certificates';

export default defineComponent({
  setup() {
    onMounted(async () => {
      getCertificates(page);
    })

    const { certificates, certificate, getCertificates, getCertificate, insertCertificate, updateCertificate, deleteCertificate, errors, status } = certificateRepo();
    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      modalTitle: 'Add Certificate',
      idToDelete: '',
      isLoading: false
    });
    const isConfirm = ref(false);
    const modalActive = ref(false);

    const toggleConfirmation = () => {
      isConfirm.value = !isConfirm.value;
    }

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    }

    const headers = [
      {
        key: 'title',
        label: 'Certificate Title'
      },
      {
        key: 'valid_until_display',
        label: 'Validity'
      },
      {
        key: 'description',
        label: 'Description'
      },
      {
        key: 'action',
        label: 'Action',
        class: 'text-center w-10'
      }
    ];

    const addCertificate = () => {
      errors.value = [];
      certificate.value = [];
      toggleModal();
    }

    const editCertificate = (id) => {
      page.modalTitle = 'Edit Certificate';
      errors.value = [];
      toggleModal();
      getCertificate(id, page);
    }

    const save = async () => {
      let formData = new FormData();
      let formatted_date = '';
      if (certificate.value.validity) {
        const validDate = certificate.value.validity
        formatted_date = validDate.getFullYear() + '-' + (validDate.getMonth() + 1) + '-' + validDate.getDate();
      }
      formData.append('title', certificate.value.title ?? '');
      formData.append('validity', formatted_date ?? '');
      formData.append('description', certificate.value.description ?? '');
      formData.append('user_id', page.authuser.id);

      if (certificate.value.id) {
        formData.append('_method', 'PUT');
        await updateCertificate(certificate.value.id, formData);
        if (status.value === 200) {
          toggleModal();
          getCertificates(page);
        }
      } else {
        await insertCertificate(formData);
        if (status.value === 200) {
          toggleModal();
          getCertificates(page);
        }
      }
    }

    const destroyCertificate = (id) => {
      page.idToDelete = id;
      toggleConfirmation();
    }

    const processDelete = async () => {
      await deleteCertificate(page.idToDelete);
      await getCertificates(page);
      toggleConfirmation();
    }

    return {
      certificates,
      certificate,
      getCertificates,
      getCertificate,
      insertCertificate,
      updateCertificate,
      deleteCertificate,
      destroyCertificate,
      processDelete,
      errors,
      status,
      save,
      page,
      headers,
      modalActive,
      toggleModal,
      addCertificate,
      editCertificate,
      isConfirm,
      toggleConfirmation
    }
  },
  components: {
    ModalCertificate
  }
})
</script>