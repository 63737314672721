<template>
    <div>
        <h3>Skills Needed</h3>
        <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
        <div class="row">
            <div class="col-4 mb-3">
                <span class="skill-field">
                    <label for="skill_needed">Skill <span class="text-danger">*</span></label>
                    <multiselect
                        id="skill-tags"
                        class="skill"
                        v-model="currentSkills"
                        tag-placeholder="Enter to add as a new skill"
                        label="name" track-by="id"
                        :options="globalSkillsFiltered"
                        :taggable="true"
                        :class='{ "is-skills-invalid" : errors.skills && errors.skills[0] }'
                        @tag="addTagSkills"
                        @select="focusToYearExpField"
                    />
                </span>
                <div v-if="errors.skills_needed" class="alert alert-danger skill-error" role="alert">
                    <div v-for="(err, i) in errors.skills_needed" :key="i">
                        {{ err }}
                    </div>
                </div>
            </div>
            <div class="col-4 mb-3">
                <div class="d-flex">
                    <div class="skill-field mr-5">
                        <label for="skill_needed_year">Yr(s) of Exp.</label>
                        <input id="skill_needed_year" type="number" class="text-input form-control" v-model="state.skill_years" placeholder="Ex. 3" style="height: 33px;">
                    </div>
                    <div style="margin-top: 26px;">
                        <button class="btn btn-primary add-skill-button" @click="addSkillsNeededData" style="height: 33px;" :disabled="state.isDisabled">{{ state.skillBtnTxt }}</button>
                    </div>
                </div>
            </div>
            <div v-if="state.skillsNeededData && state.skillsNeededData.length">
                <div class="skill-required-item" v-for="(item, index) in state.skillsNeededData" :key="index">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            {{ item.skill_name }} <span v-if="item.years_experience">&nbsp;with {{ item.years_experience }} years of experience</span>
                        </div>
                        <div>
                            <a href="javascript:;" class="text-success" @click="editSkillNeeded(item.skill_name, item.years_experience, index)"><i class="fa fa-pencil"></i></a> &nbsp;&nbsp;
                            <a href="javascript:;" class="text-danger" @click="removeSkillNeeded(index)"><i class="fa fa-times"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                No skills added. Start adding by filling the fields above.
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, computed, onMounted, watchEffect } from 'vue';
import globalRepo from '@/repositories/global';

export default {
    props: {
        form: {
            type: Object,
            default: {}
        },
        errors: {
            type: Object,
            default: {}
        }
    },
    setup(props, {emit}) {
        const state = reactive({
            skillsNeededData: [],
            skill: [],
            skill_years: "",
            selectedSkill: '',
            skillBtnTxt: 'Add Skill',
            isDisabled: true
        });
        const { global_skills, getGlobalSkills } = globalRepo();
        const currentSkills = ref([]);
        const globalSkillsFiltered = computed(() => {
            const skills = [];
            global_skills.value.map((global) => {
                skills.push(global);
            });

            return skills;
        });

        const addTagSkills = (newTag) => {
            const tag = {
                name: newTag,
                id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }

            const arrayTag = [];
            arrayTag.push(tag);
            currentSkills.value = arrayTag;
            errors.value = [];
            focusToYearExpField();
        }

        const removeSkillNeeded = (id) => {
            state.skillsNeededData.splice(id, 1);
            let skills_needed = JSON.stringify(state.skillsNeededData);
            emit('emit-skillsneeded', skills_needed);
        }

        const addSkillsNeededData = () => {
            if(currentSkills.value.length || state.selectedSkill){
                state.skillsNeededData.push({
                    "skill_name": currentSkills.value[0]?.name ?? state.selectedSkill,
                    "years_experience": state.skill_years
                });

                currentSkills.value = [];
                state.skill_years = "";
                state.skillBtnTxt = 'Add Skill';
                state.isDisabled = true;
                
                let skills_needed = JSON.stringify(state.skillsNeededData);
                emit('emit-skillsneeded', skills_needed);
            } else {
                alert('Please enter skill')
            }
        }

        const editSkillNeeded = (skill, exp, id) => {
            state.skillBtnTxt = 'Update Skill';
            state.skill_years = exp;
            state.skillsNeededData.splice(id, 1);
            const tag = {
                name: skill,
                id: skill.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }

            const arrayTag = [];
            arrayTag.push(tag);
            currentSkills.value = arrayTag;
            props.errors.value = [];
        }

        const focusToYearExpField = (evt = []) => {
            if(evt) {
                state.selectedSkill = evt.name;
                props.errors.value = [];
            }
            document.getElementById("skill_needed_year").focus();
            state.isDisabled = false;
        }

        watchEffect(() => {
            if(props.form.id) {
                props.form.skills_needed_object.forEach(item => {
                    state.skillsNeededData.push({
                        skill_name: item.name,
                        years_experience: item.yrs_exp
                    });
                });

                let skills_needed = JSON.stringify(state.skillsNeededData);
                emit('emit-skillsneeded', skills_needed);
            }
        });

        onMounted(() => {
            getGlobalSkills();
        });

        return {
            globalSkillsFiltered,
            global_skills,
            getGlobalSkills,
            removeSkillNeeded,
            addSkillsNeededData,
            editSkillNeeded,
            addTagSkills,
            state,
            currentSkills,
            focusToYearExpField
        }
    }
}
</script>

<style scoped>
.skill-required-item {
    background: #fafafa;
    color: #000;
    padding: 12px 20px;
    margin-bottom: 3px;
    border: 1px solid #b8b8b8;
    border-radius: 3px;
}
</style>