<template>
    <div>
        <Modal :className="`modal-dialog modal-lg-1000`" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">Upgrade Account Subscription</h5>
                    <button type="button" class="btn-close" @click="backToJobPosting" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div>
                    <div class="row text-secondary" id="upgrade-option-container" v-if="!page.isPlanSelected">
                        <div class="col-12" v-if="page.authuser.role_id === 2">
                            <div v-if="!isBillingPage">
                                <div class="text-center" :class="{ 'feature-logo' : !fromJobPosting }"><img :src="`${page.apiEndpoint}/simphini-logo.svg`" width="100"></div>
                                <h2 class="text-center">Upgrade your Simphini account</h2>
                                <p class="text-center">You are currently on a <span class="text-bold text-uppercase text-primary">{{ userAccount }}</span> plan. Please select a plan below to use this Simphini feature:</p>
                            </div>
                            <div v-else>
                                <h2 class="text-center mb-4">Select Your Subscription Period</h2>
                            </div>
                            <div class="subscription-switcher">
                                <div class="text-center subscription-switcher-wrapper">
                                    <span class="monthly">Monthly</span>
                                    <div class="switch-field">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" v-model="page.switchField" checked>
                                        </div>
                                    </div>
                                    <span class="yearly">Annually</span>
                                </div>
                            </div>
                            <div id="subscriptions" class="subscription-wrapper m-3 mt-4 mb-5">
                                <div class="row">
                                    <div class="col-4 position-relative" :class="(isBillingPage && planToUpgrade != 'Free') ? 'package-disabled' : ''">
                                        <div class="flip-container" :class="page.switchMonthly ? 'hover' : 'normal-flip'">
                                            <div class="flipper">
                                                <div class="front">
                                                    <div class="subcription-item border p-3 rounded free">
                                                        <p class="mb-1 text-right discount">&nbsp;</p>
                                                        <h5>BASIC</h5>
                                                        <p class="mb-2 sub-title">Perfect for beginners</p>
                                                        <h3>Free</h3>
                                                        <p class="mb-3 pre-subtitle">For a Lifetime</p>
                                                        <div class="price-tag" v-if="userAccount == 'Free'">
                                                            Current Plan
                                                        </div>
                                                        <div class="price-tag" v-else>
                                                            Free Plan
                                                        </div>
                                                        <p class="mb-3 fw-bold inclusion-title">This plan includes:</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> {{ job_post_count.free }} Active job posts/month</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-times"></i></span> {{ promoted_job_count.free }} Promoted job posts</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-times"></i></span> No Access to Premium Learning Plans</p>
                                                        <p class="mb-1 benefits"><span style="vertical-align: top;"><i class="fas fa-times"></i></span> <span class="clp">No Access to Custom Learning Plans</span></p>
                                                    </div>
                                                </div>
                                                <div class="back">
                                                    <div class="subcription-item border p-3 rounded free">
                                                        <p class="mb-1 text-right discount">&nbsp;</p>
                                                        <h5>BASIC</h5>
                                                        <p class="mb-2 sub-title">Perfect for beginners</p>
                                                        <h3>Free</h3>
                                                        <div class="price-tag" v-if="userAccount == 'Free'">
                                                            Current Plan
                                                        </div>
                                                        <div class="price-tag" v-else>
                                                            Free Plan
                                                        </div>
                                                        <p class="mb-3 fw-bold inclusion-title">This plan includes:</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> {{ job_post_count.free }} Active job posts/month</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-times"></i></span> {{ promoted_job_count.free }} Promoted job posts</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-times"></i></span> No Access to Premium Learning Plans</p>
                                                        <p class="mb-1 benefits"><span style="vertical-align: top;"><i class="fas fa-times"></i></span> <span class="clp">No Access to Custom Learning Plans</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 position-relative" :class="(isBillingPage && planToUpgrade != 'Pro') ? 'package-disabled' : ''">
                                        <div class="flip-container" :class="page.switchMonthly ? 'hover' : 'normal-flip'">
                                            <div class="flipper">
                                                <div class="front">
                                                    <div class="subcription-item annually border p-3 rounded pro">
                                                        <p class="mb-1 text-right discount">Save 20%</p>
                                                        <h5>PRO</h5>
                                                        <p class="mb-2 sub-title">Recommended</p>
                                                        <h3>$2,847/year</h3>
                                                        <p class="mb-3 pre-subtitle" style="text-decoration: line-through;">$3,564 regular price</p>
                                                        <div class="price-tag" v-if="userAccount == 'Pro'">
                                                            Current Plan
                                                        </div>
                                                        <div class="price-tag-button" v-else>
                                                            <button class="btn" @click="selectPlan('Pro','Annually')"><span v-if="isBillingPage">Select</span><span v-else>Upgrade Plan</span></button>
                                                        </div>
                                                        <p class="mb-3 fw-bold inclusion-title">This plan includes:</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> {{ job_post_count.pro }} Active job posts/month</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> {{ promoted_job_count.pro }} Promoted job posts</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> Premium learning plans</p>
                                                        <p class="mb-1 benefits"><span style="vertical-align: top;"><i class="fas fa-check"></i></span> <span class="clp">Custom Learning Plans</span></p>
                                                    </div>
                                                </div>
                                                <div class="back">
                                                    <div class="subcription-item border p-3 rounded pro">
                                                        <p class="mb-1 text-right discount">&nbsp;</p>
                                                        <h5>PRO</h5>
                                                        <p class="mb-2 sub-title">Recommended</p>
                                                        <h3>$297/month</h3>
                                                        <div class="price-tag" v-if="userAccount == 'Pro'">
                                                            Current Plan
                                                        </div>
                                                        <div class="price-tag-button" v-else>
                                                            <button class="btn" @click="selectPlan('Pro','Monthly')"><span v-if="isBillingPage">Select</span><span v-else>Upgrade Plan</span></button>
                                                        </div>
                                                        <p class="mb-3 fw-bold inclusion-title">This plan includes:</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> {{ job_post_count.pro }} Active job posts/month</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> {{ promoted_job_count.pro }} Promoted job posts</p>
                                                        <p class="mb-1 benefits"><span><i class="fas fa-check"></i></span> Premium learning plans</p>
                                                        <p class="mb-1 benefits"><span style="vertical-align: top;"><i class="fas fa-check"></i></span> <span class="clp">Custom Learning Plans</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 position-relative" :class="(isBillingPage && planToUpgrade != 'Premium') ? 'package-disabled' : ''">
                                        <div class="flip-container" :class="page.switchMonthly ? 'hover' : 'normal-flip'">
                                            <div class="flipper">
                                                <div class="front">
                                                    <div class="subcription-item annually border p-3 rounded premium">
                                                        <p class="mb-1 text-right discount">Save 25%</p>
                                                        <h5>PREMIUM</h5>
                                                        <p class="mb-2 sub-title">Best Value</p>
                                                        <h3>$4,047/year</h3>
                                                        <p class="mb-3 pre-subtitle" style="text-decoration: line-through;">$5,364 regular price</p>
                                                        <div class="price-tag" v-if="userAccount == 'Premium'">
                                                            Current Plan
                                                        </div>
                                                        <div class="price-tag-button">
                                                            <button class="btn" @click="selectPlan('Premium','Annually')"><span v-if="isBillingPage">Select</span><span v-else>Upgrade Plan</span></button>
                                                        </div>
                                                        <p class="mb-3 fw-bold inclusion-title">This plan includes:</p>
                                                        <p class="mb-1 text-secondary benefits"><span><i class="fas fa-check"></i></span> {{ job_post_count.premium }} Active job posts/month</p>
                                                        <p class="mb-1 text-secondary benefits"><span><i class="fas fa-check"></i></span> {{ promoted_job_count.premium }} Promoted job posts</p>
                                                        <p class="mb-1 text-secondary benefits"><span><i class="fas fa-check"></i></span> Premium Learning Plans</p>
                                                        <p class="mb-1 text-secondary benefits"><span style="vertical-align: top;"><i class="fas fa-check"></i></span> <span class="clp">Custom Learning Plans</span></p>
                                                    </div>
                                                </div>
                                                <div class="back">
                                                    <div class="subcription-item border p-3 rounded premium">
                                                        <p class="mb-1 text-right discount">&nbsp;</p>
                                                        <h5>PREMIUM</h5>
                                                        <p class="mb-2 sub-title">Best Value</p>
                                                        <h3>$447/month</h3>
                                                        <div class="price-tag" v-if="userAccount == 'Premium'">
                                                            Current Plan
                                                        </div>
                                                        <div class="price-tag-button">
                                                            <button class="btn" @click="selectPlan('Premium','Monthly')"><span v-if="isBillingPage">Select</span><span v-else>Upgrade Plan</span></button>
                                                        </div>
                                                        <p class="mb-3 fw-bold inclusion-title">This plan includes:</p>
                                                        <p class="mb-1 text-secondary benefits"><span><i class="fas fa-check"></i></span> {{ job_post_count.premium }} Active job posts/month</p>
                                                        <p class="mb-1 text-secondary benefits"><span><i class="fas fa-check"></i></span> {{ promoted_job_count.premium }} Promoted job posts</p>
                                                        <p class="mb-1 text-secondary benefits"><span><i class="fas fa-check"></i></span> Premium Learning Plans</p>
                                                        <p class="mb-1 text-secondary benefits"><span style="vertical-align: top;"><i class="fas fa-check"></i></span> <span class="clp">Custom Learning Plans</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-else>
                            <div class="text-center feature-logo"><img :src="`${page.apiEndpoint}/simphini-logo.svg`" width="100"></div>
                            <h2 class="text-center">Upgrade your Simphini account</h2>
                            <p class="text-center text-lg mt-4 mb-3 p-4 text-dark bg-light rounded">Please contact the Account Administrator to upgrade the account.</p>
                        </div>
                    </div>
                    <div class="row text-secondary" v-else>
                        <component :is="currentComponent" :planDetails="page.planDetails" @backEvent="back" @subscribeSuccess="changeComponent" @triggerAction="close"></component>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="backToJobPosting">Cancel</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, reactive, watchEffect, ref } from 'vue';
import SelectPaymentMethod from '@/components/billing/SelectPaymentMethod.vue';
import UpgradeSuccess from '@/components/billing/UpgradeSuccess.vue';
import globalRepo from '@/repositories/global';

export default defineComponent({
    props: ['modalActive','modalTitle','close','noHeaderBorder','noBodyMargin','userAccount','isBillingPage','planToUpgrade','fromJobPosting'],
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            switchMonthly: false,
            apiEndpoint: process.env.VUE_APP_API_URL,
            switchField: true, // model for the switch
            isPlanSelected: false,
            planDetails: {
                plan_type: '',
                subscription_type: ''
            }
        });
        const { promoted_job_count, job_post_count } = globalRepo();
        const currentComponent = ref('SelectPaymentMethod');
        const close = (isSubscriptionSuccess = false) => {
            emit('close', isSubscriptionSuccess)
            setTimeout(()=> { page.isPlanSelected = false; }, 800);
            if(isSubscriptionSuccess) {
                window.location.reload();
            }
        }
        
        const saveChanges = () => {
            emit('saveChanges');
        }

        const back = () => {
            page.isPlanSelected = false;
            page.planDetails.plan_type = '';
            page.planDetails.subscription_type = '';
        }

        const changeComponent = () => {
            currentComponent.value = 'UpgradeSuccess';
        }

        const backToJobPosting = () => {
            page.isPlanSelected = false;
            emit('render-jobposting');
        }

        /*  Plan types : 'Pro', 'Premium' 
         * Subscription types : 'Monthly', 'Annually' */
         const selectPlan = (plan_type, subscription_type) => {
            page.planDetails.plan_type = plan_type;
            page.planDetails.subscription_type = subscription_type;
            page.isPlanSelected = true;
        }

        watchEffect(() => {
            if(page.switchField) {
                page.switchMonthly=false;
            } else {
                page.switchMonthly=true;
            }
        });

        return {
            page,
            close,
            saveChanges,
            currentComponent,
            selectPlan,
            back,
            changeComponent,
            promoted_job_count, 
            job_post_count,
            backToJobPosting
        }
    },
    components: {
        SelectPaymentMethod,
        UpgradeSuccess
    }
})
</script>
<style>
.subscription-wrapper {
    min-height: 355px;
}
.benefits span.clp {
    width: 90%;
    color: #333333;
}
.subscription-wrapper {
    margin-bottom: 35px !important;
}
#subscriptions .subcription-item {
    min-height: 355px;
    background:#efefef;
}
/* #subscriptions .front .subcription-item.annually {
    min-height: 385px;
} */
.discount {
    margin-top: -5px;
}
.subcription-item h5 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: -25px;
}
.subcription-item h3 {
    font-size: 30px;
    font-weight: bold;
}
.price-tag {
    border-radius: 5px;
    background: #a5a5a5;
    padding: 11px 13px;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}
.price-tag-button {
    margin-bottom: 15px;
}
.back .price-tag-button {
    margin-top: 15px;
}
.price-tag-button button.btn {
    border-radius: 5px;
    background: #b6b6b6;
    padding: 10px 13px;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    width: 100%;
}
.price-tag-button button.btn a {
    color: #FFF;
    text-decoration: none;
}
.price-tag.active {
    background: #dbf7db;
}
.text-right {
    text-align:right;
}
.subscription-switcher {
    text-align: center;
}
.subscription-switcher-wrapper {
    display: inline-block;
    background: #efefef;
    padding: 10px 20px;
    border-radius: 20px;
}
.switch-field {
    display: inline-block;
    text-align: center;
    vertical-align: top;
}
.subscription-switcher span {
    display: inline-block;
    width: 100px;
}
.monthly {
    text-align: left;
}
.yearly {
    text-align: right;
}
.benefits span {
    color: #005dff;
    display: inline-block;
    width: 11px;
    margin-right: 5px;
}
.back .free .price-tag {
    background: #65d092;
}
.pro .discount {
    color: #3f80ea;
}
.pro .price-tag-button button.btn {
    background: #3f80ea;
}
.premium .discount {
    color: #002057;
}
.premium .price-tag-button button.btn {
    background: #002057;
}
/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
  -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
	-webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
}
/* flip the pane when hovered */
.flip-container.hover .flipper {
	transform: rotateY(180deg);
}

.flip-container, .front, .back {
	width: 100%;
	height: 325px;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  	background: #E5E9F1;
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.thumbnail{
background: none;
  border: none;
}
.mb{margin-bottom: 20px;}
.feature-logo {
    margin-top: -35px;
}
.package-disabled:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 118%;
    top: 0;
    left: 0;
    background: #ffffffb5;
}
.modal-lg-1000 {
    max-width: 1000px !important;
}
</style>