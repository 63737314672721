<template>
    <nav id="sidebar" class="sidebar">
        <div class="sidebar-content js-simplebar">
            <router-link class="sidebar-brand" :to="{ 'name': page.dashboardUrl }">
                <div class="compact-logo">
                    <img :src="`${page.assetUrl}/assets/simphini-logo.svg`" width="55" height="55" class="logo compact">
                    <span class="align-middle me-3">Simphini</span>
                </div>
                <div class="sticky-logo">
                    <img :src="`${page.assetUrl}/assets/simphini-logo-type-white-v2.svg`" width="55" height="55" class="logo sticky">
                </div>
            </router-link>
            <component v-bind:is="currentRole" :authuser="page.authuser"></component>
        </div>
    </nav>
</template>

<script>
import { defineComponent, reactive, ref, watchEffect } from 'vue';
import Admin from '@/components/partials/roles/Admin.vue';
import Employer from '@/components/partials/roles/Employer.vue';
import Candidate from '@/components/partials/roles/Candidate.vue';

export default defineComponent({
    setup() {
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            apiurl: process.env.VUE_APP_API_ENDPOINT,
            dashboardUrl: '',
            assetUrl: process.env.VUE_APP_UPCLOUD_ASSETS
        });

        const roleId = ref('');

        watchEffect(() => {
            if(page.authuser.role_id == 1) {
                roleId.value = 'Admin';
                page.dashboardUrl = 'admin.dashboard'
            }

            if(page.authuser.role_id == 2 || page.authuser.role_id == 3) {
                roleId.value = 'Employer';
                page.dashboardUrl = 'employer.dashboard'
            } else if(page.authuser.role_id == 4) {
                roleId.value = 'Candidate';
                page.dashboardUrl = 'candidate.dashboard'
            }

            if(page.authuser.role_id == 4) {
                roleId.value = 'Candidate';
                page.dashboardUrl = 'candidate.dashboard'
            }
        })

        const currentRole = roleId.value;

        return {
            page,
            currentRole
        }
    },
    components: {
        Admin,
        Employer,
        Candidate
    }
})
</script>

<style scoped>
img.logo {
    width: 50px;
    height: auto;
}
img.logo.sticky {
    width: 135px;
    height: 40px;
    margin: 5px 0 5px;
}
.sidebar-brand {
    padding: 10px 0px 5px;
}
nav#sidebar {
    transition: none;
}
.sticky-logo {
    display:none;
}
body[data-sidebar-behavior="sticky"] #sidebar {
    min-width: 240px;
}
body[data-sidebar-behavior="sticky"] .compact-logo {
    display:none;
}
body[data-sidebar-behavior="sticky"] .sticky-logo {
    display:block;
    text-align: left;
    margin: 0 7%;
}
</style>