<template>
    <div class="tab-pane fade show active" id="resume-parser" role="tabpanel">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0">Resume Parser</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-outline-primary btn-lg" @click="$refs.fileInput.click()" v-html="page.btnUpload"></button>
                        <input type="file" style="display: none" class="form-control" ref="fileInput" @change="onFileChange" enctype="multipart/form-data">
                    </div>
                </div>
                <div v-if="page.isUploading">
                    <div class="row mt-3">
                        <div class="text-center pt-5">
                            <img src="/assets/images/loader.gif" class="img-fluid">
                            <h4>Processing...</h4>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row mt-5" v-if="user !== null">
                        <div class="col-12">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation" v-for="tab in tabs" :key="tab">
                                    <button class="nav-link" :class="{ 'active' : page.activeTab === tab.id }" @click="currentTab(tab.id, tab.component)" type="button" role="tab">{{ tab.step }}</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="application-tab-content">
                                <div class="tab-pane fade show active pt-3">
                                    <component :is="currentComponent" v-bind="{ user: user }"></component>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" v-else>
                        <div class="text-center">
                            <img src="/assets/images/empty.png" class="img-fluid img-30">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Do you want to upload ${page.resumeToUpload?.name} document?`"
            @cancel="toggleConfirmation"
            @isDelete="uploadResume"
        />
    </div>      
</template>

<script>
import axios from "axios";
import { defineComponent, reactive, ref, onMounted } from 'vue';
import resumeRepo from '@/repositories/candidate/resume';
import Information from '@/views/candidate/settings/profile/tabs/resume/Information.vue';
import Education from '@/views/candidate/settings/profile/tabs/resume/Education.vue';
import Work from '@/views/candidate/settings/profile/tabs/resume/Work.vue';
import Certificate from '@/views/candidate/settings/profile/tabs/resume/Certificate.vue';
import Skill from '@/views/candidate/settings/profile/tabs/resume/Skill.vue';

export default defineComponent({
    props: ['fileData'],
    setup(props) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            resumeToUpload: '',
            isLoading: false,
            isParseOpen: false,
            activeTab: 1,
            isLastStep: false,
            isUploading: false,
            btnUpload: 'Upload Resume (docx or pdf)'
        })
        const { AffindaCredential, AffindaAPI } = require("@affinda/affinda");
        const credential = new AffindaCredential(process.env.VUE_APP_AFFINDA_API_KEY)
        const client = new AffindaAPI(credential)
        const isConfirm = ref(false);
        const user = ref(JSON.parse(page.authuser.resume_content));
        const currentComponent = ref('Information');
        const { uploadResumeFile, status, errors, candidate } = resumeRepo();

        const tabs = [
            {
                id: 1,
                step: 'Personal Information',
                component: 'Information'
            },
            {
                id: 2,
                step: 'Education',
                component: 'Education'
            },
            {
                id: 3,
                step: 'Work Experience',
                component: 'Work'
            },
            {
                id: 4,
                step: 'Certifications',
                component: 'Certificate'
            },
            {
                id: 5,
                step: 'Skills',
                component: 'Skill'
            }
        ];

        const onFileChange = (e) => {
            let file = e.target.files[0];
            page.resumeToUpload = file;
            toggleConfirmation();
        }

        const uploadResume = async () => {
            toggleConfirmation();
            page.isLoading = true;
            page.isUploading = true;
            page.btnUpload = '<i class="fa fa-spin fa-spinner"></i> Uploading...';

            let formData = new FormData();
            formData.append('resumefile', page.resumeToUpload ?? '');
            formData.append('user_id', page.authuser.id);
            await uploadResumeFile(formData);
            
            client.createResume({ url: `${candidate.value.resume}` }).then( async (result) => {
                page.isLoading = false;
                page.isParseOpen = true;
                user.value = result.data;

                let userResponse = await axios.post(`candidate/users/update-resume`, {
                    resume_content: JSON.stringify(result.data),
                    user_id: page.authuser.id
                });

                localStorage.setItem('authuser', JSON.stringify(userResponse.data.user));
                page.isUploading = false;
                page.btnUpload = 'Upload Resume (docx or pdf)';
            }).catch((err) => {
                console.log("An error occurred:");
                console.error(err);
            });
        }

        const currentTab = (step, component) => {
            currentComponent.value = component;
            page.activeTab = step;

            page.isLastStep = (tabs.length == page.activeTab);
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        onMounted(()=>{
            if(props.fileData) {
                page.resumeToUpload = props.fileData;
                toggleConfirmation();
            }

            console.log(process.env.VUE_APP_AFFINDA_API_KEY);
        });

        return {
            page,
            isConfirm,
            toggleConfirmation,
            uploadResume,
            onFileChange,
            uploadResumeFile,
            status,
            errors,
            user,
            tabs,
            currentTab,
            currentComponent,
            candidate
        }
    },
    components: {
        Information,
        Education,
        Work,
        Certificate,
        Skill
    }
})
</script>