<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <label for="categories" class="form-label mt-3 mb-1">Employee(s)</label>
                        <multiselect
                            id="employees"
                            v-model="a"
                            :options="form"
                            placeholder="Enter Employee"
                            label="name"
                            track-by="user_id"
                            tag-placeholder="Click to add employee"
                            :multiple="true"
                            :taggable="false"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hideSelected="true"
                        ></multiselect>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, watchEffect, ref } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        employees: {
            type: [Object, Array],
            default: []
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        },
        department: {
            type: [Object, Array],
            default: {}
        }
    },
    setup(props, { emit }) {

        const a = ref(props.employees ?? []);

        const close = () => {
            emit('close')
            a.value = [];
        }
        
        const saveChanges = () => {
            let formData = {
                department_id: props.department.id ?? '',
                member_ids: (getIds(a.value).join(',')) ?? null
            }
            emit('saveChanges', formData);
            a.value = [];
        }

        const getIds = (arr) => {
            let b = [];
            if(arr) {
                arr.forEach(item => {
                    b.push(item.user_id)
                });
            }
            return b;
        }

        return {
            a,
            close,
            saveChanges
        }
    }
})
</script>