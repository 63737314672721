<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
                    <div class="col-12">
                        <BaseInput 
                            v-model="form.department_name"
                            label="Department Name"
                            type="text"
                            :class="{ 'is-invalid' : errors_department && errors_department.department_name }"
                            id="department"
                            :errors="errors_department"
                            is-required
                        />
                        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors_department">{{ errors_department.department_name }}</label>
                    </div>
                    <div class="col-12">
                        <label class="form-label mb-1">Description</label>
                        <BaseEditor
                            v-model="form.description"
                            id="description"
                            @editorContent="saveDesc"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        }
    },
    setup(props, { emit }) {

        const errors_department = ref([]);
        const status = ref('');
        const close = () => {
            props.form.department_name = '';
            errors_department.value = [];
            emit('close')
        }
        
        const saveChanges = () => {
            status.value = 200;
            if(!props.form.department_name) {
                errors_department.value = {
                    department_name: 'The department name field is required.'
                };
            } else {
                let formData = {
                    department_name: props.form.department_name ?? '',
                    description: props.form.description ?? ''
                }
                emit('saveChanges', formData);
            }
        }

        const saveDesc = (text) => {
            props.form.description = text ?? '';
        }

        return {
            close,
            saveDesc,
            saveChanges,
            errors_department,
            status
        }
    }
})
</script>