import axios from "axios";
import { ref, inject } from "vue";

export default function candidateRepo() {

    const toast = inject('toast');
    const candidate = ref([]);
    const skills = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');
    const skill = ref([]);

    const getCandidateProfile = async (page) => {
        let user_id = page.authuser.id ?? 0;
        let response = await axios.get(`candidate/profile/get-profile?user_id=${user_id}`);
        candidate.value = response.data.profile;
        page.isLoading = false;
    }

    const getCandidateSkills = async (page) => {
        let user_id = page.authuser.id ?? 0;
        let response = await axios.get(`candidate/profile/skills?user_id=${user_id}`);
        skills.value = response.data.data;
        page.isLoading = false;
    }

    const getCandidateSkill = async (id) => {
        let response = await axios.get(`candidate/profile/skills/${id}`);
        skill.value = response.data.data;
    }

    const updateCandidateProfileJobsearch = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/update-jobsearch`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateCandidateSkills = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/update-skill`, data);
            status.value = response.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
                status.value = 200
            }
        }
    }

    const destroyCandidateSkill = async (id) => {
        let response = await axios.delete(`candidate/profile/skill/${id}`);
        status.value = response.status;
        message.value = response.data.message;
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    return {
        updateCandidateSkills,
        getCandidateProfile,
        getCandidateSkills,
        updateCandidateProfileJobsearch,
        errors,
        message,
        status,
        alertMessage,
        candidate,
        skills,
        skill,
        getCandidateSkill,
        destroyCandidateSkill
    }
}