<template>
    <div v-if="certificates.length">
        <div v-for="(certificate, index) in certificates" :key="certificate">
            <div class="row">
                <div class="col-12">
                    <BaseInput 
                        placeholder="Your certificate title"
                        v-model="page.certificate[index]"
                        label="Certificate Title"
                        type="text"
                        id="title"
                        :class="{ 'is-invalid' : errors.title }"
                        :errors="errors"
                        is-required
                    />
                    <div class="mb-3">
                        <label for="time_period" class="form-label">Validity</label>
                        <Datepicker
                            id="time_period"
                            v-model="page.validity[index]" 
                            format="MM/dd/yyyy"
                            :enableTimePicker="false"
                            :range="false"
                            placeholder="Date (MM/DD/YYYY)"
                            :modelValue="page.validity[index]"
                            textInput
                        />
                    </div>
                    <div>
                        <label>Description</label>
                        <editor
                            :api-key="tinykey"
                            :init="init"
                            v-model="page.description[index]"
                            placeholder="Enter description"
                        />
                    </div>
                </div>
            </div>
            <hr v-show="certificates.length != index+1">
        </div>
        <div class="mt-3">
            <BaseButton :is-saved="status === 200" @btnClicked="saveCertificate" />
        </div>
    </div>
    <div v-else>
        <div class="text-center">
            <img src="/assets/images/empty.png" class="img-fluid img-30">
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, watchEffect } from 'vue';
import certificateRepo from '@/repositories/candidate/certificates';
import config from '@/config.js';

export default defineComponent({
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const tinykey = config.tinykey;
        const init = config.editor_init;
        const certificates = props.user?.certifications ?? [];
        const currently_work = ref([]);
        const { addMultipleCertificate, errors, status } = certificateRepo();
    
        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            validity: [],
            description: [],
            certificate: []
        });

        watchEffect(() => {
            if(certificates.length) {
                certificates.forEach((certificate, index) => {
                    page.certificate[index] = certificate;
                });
            }
        });

        const saveCertificate = () => {
            status.value = '';
            let form = {
                validity: page.validity,
                description: page.description,
                title: page.certificate,
                user_id: page.authuser.id
            }

            addMultipleCertificate(form);
        }

        return {
            page,
            currently_work,
            errors,
            status,
            certificates,
            tinykey,
            init,
            saveCertificate,
            addMultipleCertificate
        }
    }
})
</script>