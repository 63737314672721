export default {
    tinykey: process.env.VUE_APP_TINY_MCE_KEY,
    editor_init: {
        height: 250,
        menubar: true,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'link image code preview'
        ],
        toolbar:
            'undo redo| bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
    }
 }