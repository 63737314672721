<template>
    <div>
        <Modal :className="`modal-dialog modal-md`" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <BaseSelect
                            v-model="department_id"
                            label="Assign to Department:"
                            :class="{ 'is-invalid' : department_errors && department_errors.department_id }"
                            id="department_id"
                            :errors="department_errors"
                            is-required
                            :options="department_options"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" :btn-value="`Assign`" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, watchEffect, ref } from 'vue';

export default defineComponent({
    props: ['modalActive','form','modalTitle'],
    setup(props, { emit }) {

        const department_id = ref('');
        const department_errors = ref([]);
        const department_options = ref([]);
        const status = ref('');

        const close = () => {
            emit('close')
        }

        const saveChanges = () => {
            if(department_id.value) {
                let formData = {
                    department_id: department_id.value ?? ''
                }
                emit('saveChanges', formData);
            } else {
                department_errors.value = {
                    department_id: ['Please select department.']
                }
            }

            status.value = 200;
        }

        watchEffect( async () => {
            props.form.forEach(i=>{
                department_options.value.push({
                    id: i.id,
                    name: i.department_name
                });
            });
        });

        return {
            close,
            saveChanges,
            department_id,
            department_errors,
            department_options,
            status
        }
    }
})
</script>