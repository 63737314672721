<template>
    <div>
        <Modal :className="`modal-dialog modal-md`" :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <BaseSelect
                            v-model="form.role_id"
                            label="Select Role to Change:"
                            :class="{ 'is-invalid' : errors && errors.role_id }"
                            id="role_id"
                            :errors="errors"
                            is-required
                            :options="role_labels"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" :btn-value="`Change Role`" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import roleRepo from '@/repositories/settings/roles';
import userRepo from '@/repositories/settings/users';

export default defineComponent({
    props: ['modalActive','form','modalTitle','errors'],
    setup(props, { emit }) {

        const { role, roles, getRoles } = roleRepo();
        const { changeUserRole, status } = userRepo();
        const role_labels = ref([]);

        const close = () => {
            emit('close')
        }

        const saveChanges = () => {
            let formData = new FormData();
            formData.append('user_id', props.form.id);
            formData.append('role_id', props.form.role_id);
            changeUserRole(formData);

            emit('saveChanges');
        }

        onMounted( async () => {
            await getRoles();
            roles.value.forEach(item => {
                if(item.id == role.employer_hr.id) {
                    role_labels.value.push({
                        id: item.id,
                        name: role.employer_hr.label
                    });
                }
                if(item.id == role.employee.id) {
                    role_labels.value.push({
                        id: item.id,
                        name: role.employee.label
                    });
                }
            });
        });

        return {
            roles,
            close,
            role_labels,
            saveChanges,
            getRoles,
            status
        }
    }
})
</script>