<template>
    <div>
        <h3>Compensation</h3>
        <div class="row">
            <div class="col-4">
                <BaseSelect
                    v-model="form.compensation_type"
                    label="Show Pay By"
                    id="compensation_type"
                    :options="compensation_types"
                />
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-4" v-if="payType == 2">
                        <BaseInput
                            v-model="form.exact_amount"
                            label="Exact Amount"
                            type="number"
                            id="exact_amount"
                        />
                    </div>
                    <div class="col-4" v-if="payType == 1">
                        <BaseInput
                            v-model="form.min_amount"
                            label="Minimum"
                            type="number"
                            id="min_amount"
                        />
                    </div>
                    <div class="col-4" v-if="payType == 1">
                        <BaseInput
                            v-model="form.max_amount"
                            label="Maximum"
                            type="number"
                            id="max_amount"
                        />
                    </div>
                    <div class="col-4">
                        <BaseSelect
                            v-model="form.rate"
                            label="Rate"
                            id="rate"
                            :options="rates"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { watch, ref } from 'vue';
import globalRepo from '@/repositories/global';

export default {
    props: {
        form: {
            type: Object,
            default: {}
        },
        errors: {
            type: Object,
            default: {}
        }
    },
    setup(props) {
        const { compensation_types, rates } = globalRepo();
        const payType = ref(props.form.compensation_type);

        watch(() => props.form.compensation_type, () => {
            payType.value = props.form.compensation_type;
        });

        return {
            compensation_types,
            rates,
            payType
        }
    }
}
</script>