<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="d-flex">
                <h1 class="h3 mb-3">Job Applications</h1>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div class="row" v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-header pb-0" style="border-bottom-color: #e5e5e5;">
                            <div class="col-12">
                                <div class="float-start">
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="filter-by">Show</span>
                                        </div>
                                        <select id="filter" class="form-select" aria-describedby="filter-by" v-model="page.viewOption" @change="ChangeView">
                                            <option value="/candidate/jobs/applications">Applied Jobs</option>
                                            <option value="/candidate/jobs/applications/saved">Saved Job Applications</option>
                                            <option value="/candidate/jobs/applications/invites">Job Invitations</option>
                                        </select>
                                    </div>
                                </div>
                                <div style="margin-left: 10px" class="float-end w-20 search-field">
                                    <i class="fa fa-search"></i>
                                    <input class="form-control" type="text" placeholder="Search" v-model="page.searchValue" @input="processSearch">
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <BaseTable
                                :data="jobs"
                                :options="job_headers"
                                :sortableColumns="page.sortableColumns"
                                :isSortAscending="page.isSortAscending"
                                @navigateSort="triggerSort"
                                :id="''"
                            >
                                <template v-slot:position_title="{ row }">
                                    <div style="width:200px;">{{ row.job?.position_title ?? '-' }}</div>
                                </template>
                                <template v-slot:company_name="{ row }">
                                    <div style="width:200px;" v-if="row.company?.employer?.is_profile_visible"><a :href="`/candidate/employer-profile/${row.job?.company_id}`" target="_blank">{{ row.company?.company_name }}</a></div>
                                    <div style="width:200px;" v-else>{{ row.company?.company_name }}</div>
                                </template>
                                <template v-slot:compensation="{ row }">
                                    <div v-if="row.job?.compensation != '0.00 Per Month'" v-html="row.job?.compensation"></div>
                                    <div v-else>Negotiable</div>
                                </template>
                                <template v-slot:location="{ row }">
                                    <div v-html="row.job?.location_role"></div>
                                </template>
                                <template v-slot:address="{ row }">
                                    <span v-html="row.job?.city"></span>&nbsp;
                                    <span v-html="row.job?.state"></span>
                                </template>
                                <template v-slot:status="{ row }">
                                    <div v-html="row.status" :class="{
                                        'text-success': row.status == application_status.active,
                                        'text-info': row.status == application_status.awaiting,
                                        'text-danger': row.status == application_status.cancelled,
                                        'text-primary text-bold': row.status == application_status.hired,
                                        'text-warning text-bold': row.status == application_status.resigned,
                                        'text-dark': row.status == application_status.contacted,
                                    }"></div>
                                </template>
                                <template v-slot:action="{ row }">
                                    <div class="meatballs-menu">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light btn-md rounded-circle btn-ellipsis" data-bs-toggle="dropdown" aria-expanded="false" style="width:36px;height:36px;"><i class="fa fa-ellipsis-h"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="javascript:;" @click="viewJob(row.job.id, row.id, row.status)">View Details</a></li>
                                                <li v-if="row.status == application_status.cancelled || row.status == application_status.active || row.status == application_status.rejected || row.status == application_status.resigned || row.status == application_status.terminated"><a class="dropdown-item text-danger" href="javascript:;" @click="removeApplication(row.id)">Remove</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </BaseTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slideout @closing="onClosing" size="60%" dock="left" v-model="visible" :title="`Applying for a Job - ${job.position_title}`">
            <div class="mt-3 mb-3 pl-20">
                <div class="row no-margin application-fixed">
                    <div class="col-12">
                        <div class="d-flex justify-content-between py-3 pl-3">
                            <h2 style="margin-left: 12px;">Status: <span v-html="page.applicationStatus" :class="{
                                'text-success': page.applicationStatus == application_status.active,
                                'text-danger': page.applicationStatus == application_status.cancelled,
                                'text-primary': page.applicationStatus == application_status.hired
                            }"></span></h2>
                            <div v-if="page.applicationStatus != application_status.hired" class="d-flex align-items-center">
                                <div v-if="!isResearchEnabled(job.id)">
                                    <button class="btn btn-outline-primary btn-lg" v-if="activeComponent == 'JobDetails'" @click="researchHelp">Research Help <i class="fa-regular fa-fw fa-messages-question"></i></button>
                                    <button class="btn btn-outline-primary btn-lg" v-if="activeComponent == 'Research'" @click="viewJobDetails">Back to Job Details</button>
                                </div>
                                <div v-else>
                                    <h4>You've reached your monthly available Research Help</h4>
                                </div>
                                <div v-if="page.applicationStatus != application_status.cancelled">
                                    &nbsp;
                                    <button class="btn btn-static btn-outline-success btn-lg">Applied</button>&nbsp;
                                    <button :disabled="page.applicationStatus==application_status.rejected" class="btn btn-outline-danger btn-lg" @click="promptCancelConfirmation(page.application_id)">Cancel</button>
                                </div>
                                <div v-else>
                                    <button class="btn btn-outline-secondary btn-lg" :disabled="page.applicationStatus == application_status.cancelled">You cancelled this Job application</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <transition name="translate" mode="out-in">
                <component :is='activeComponent' :job="job" :application_id="page.application_id"></component>
            </transition>
        </slideout>
        <ModalSubscription
            :modalActive="subscriptionActive"
            :application_id="page.application_id"
            :isBillingPage="true"
            :planToUpgrade="page.selectedPlan"
            @close="subscriptionActive == false"
            @render-research="renderResearch"
        />
        <Confirm
            class="cancel-confirmation"
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to cancel this job application?`"
            @cancel="toggleConfirmation"
            @isDelete="cancelApplication"
        />
    </main>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, reactive, onMounted, ref, inject } from 'vue';
import _debounce from 'lodash/debounce';
import applicationRepo from '@/repositories/candidate/application';
import jobRepo from '@/repositories/candidate/jobs';
import globalRepo from '@/repositories/global';
import JobDetails from '@/views/candidate/jobs/applications/components/JobDetails.vue';
import ModalSubscription from '@/views/candidate/jobs/applications/modals/ModalSubscription.vue';

export default defineComponent({
    components: {
        JobDetails,
        ModalSubscription
    },
    setup() {
        const swal = inject('$swal');
        const router = useRouter();
        const route = useRoute();
        const { job_types, compensation_types, location_roles, schedules, time_frames, rates, countries, getCountries, application_status } = globalRepo();
        const { applications, getApplications, processCancelApplication, destroyApplication, status } = applicationRepo();
        const { jobs, job, getJob, updateSingleResearchApplication } = jobRepo();
        const { getResearchHelp, research, is_notification } = jobRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            jobId: '',
            applicationStatus: '',
            viewOption: '/candidate/jobs/applications',
            searchValue: '',
            appliedJobs: [],
            appliedJobsSorted: [],
            application_id: 0,
            sortype: 'status',
            isSortAscending: true,
            sortableColumns: ['status','compensation'],
            selectedPlan: 'Premium'
        });

        const visible = ref(false);
        const subscriptionActive = ref(false);
        const subscription_type  = ref('Premium');
        const activeComponent = ref('JobDetails');

        const job_headers = [
            { key: 'position_title', label: 'Position Title' },
            { key: 'company_name', label: 'Company Name' },
            { key: 'date_applied_display', label: 'Date Applied' },
            { key: 'location', label: 'Location' },
            { key: 'compensation', label: 'Rates' },
            { key: 'address', label: 'Address' },
            { key: 'status', label: 'Status' },
            { key: 'action', label: 'Action', class: 'text-center' }
        ];

        const removeApplication = (jobId) => {
            page.isLoading = true;
            swal({
                title: 'Are you sure?',
                text: "You want to delete this job application?",
                icon: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, please'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    await destroyApplication(jobId);
                    if(status.value == 200) {
                        jobs.value = [];
                        await getApplications(page);
                        page.isLoading = false;
                        if(applications.value) {
                            applications.value.forEach((item) => {
                                if(item.date_applied !== null && item.job !== null){
                                    jobs.value.push(item);
                                }
                            });
                        }
                    }
                } else {
                    page.isLoading = false;
                    await getApplications(page);
                }
            });
        }

        const researchHelp = async () => {
            if(!research.value) {
                subscriptionActive.value = true;
                visible.value = false;
            } else if (!is_notification.value && research.value) {
                await getResearchHelp(page);
                visible.value = false;
                researchHelpAlert();
            } else {
                window.open(`${process.env.VUE_APP_BASE_URL}/candidate/jobs/research-help/${job.value.hashid}`)
            }
        }

        const viewJobDetails = () => {
            activeComponent.value = 'JobDetails';
        }

        const renderResearch = (isSubscribe) => {
            subscriptionActive.value = false;
            if(isSubscribe) {
                researchHelpAlert();
            } else {
                visible.value = true;
            }
        }

        const researchHelpAlert = () => {
            swal({
                title: "Gathering information...",
                text: "One moment while we compile this information for you. We'll notify you when it's ready.",
                imageUrl: `${process.env.VUE_APP_UPCLOUD_ASSETS}/images/loading.gif`,
                imageWidth: 100,
                imageHeight: 100
            })
        }

        onMounted( async () => {
            await getApplications(page);
            page.isLoading = false;
            if(applications.value) {
                applications.value.forEach((item) => {
                    if(item.date_applied !== null && item.job !== null){
                        jobs.value.push(item);
                    }
                });
            }

            if(route.params.job_id) {
                let jobId = route.params.job_id;
                let jobApplication = JSON.parse(JSON.stringify(applications.value.filter(item => item.job_id == jobId)));
                viewJob(jobId, jobApplication[0].id, jobApplication[0].status);
            }

            if(route.query.code) {
                let formData = new FormData();
                formData.append('code', route.query.code);
                formData.append('user_id', page.authuser.id);
                await updateSingleResearchApplication(formData);

                researchHelpAlert();
            }
        });

        const isConfirm = ref(false);
        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const promptCancelConfirmation = (id) => {
            page.application_id = id;
            isConfirm.value = true;
        }

        const cancelApplication = async () => {
            toggleConfirmation();
            visible.value = false;
            page.isLoading = true;
            await processCancelApplication(page.application_id);
            await getApplications(page);
            jobs.value = applications.value;
            page.isLoading = false;
        }

        const viewJob = async (id, application_id, status) => {
            await getJob(id);
            page.jobId = id;
            await getResearchHelp(page);
            page.application_id = application_id;
            page.applicationStatus = status;
            visible.value = true;
        }

        const getSchedules = () => {
            if(job.value.schedules) {
                let results = job.value.schedules.split(',');
                let content = '';
                results.filter(result => {
                    content += `${schedules.find(x => x.id == result).name}, `;
                });

                return content.slice(0, -2);
            }
        }

        const onClosing = (e) => {
            // close after 3 seconds
            setTimeout(() => {
                // assign true to close, do nothing or assign false to cancel close.
                activeComponent.value = 'JobDetails';
                e.resume = true;
            }, 500)
        }

        const ChangeView = () => {
            router.push(`${page.viewOption}`);
        }

        const toggleSortAscending = () => {
            page.isSortAscending = !page.isSortAscending;
        }

        const triggerSort = async (index) => {
            page.sortype = index;
            toggleSortAscending();
            customSort( jobs.value, page.sortype, page.isSortAscending );
        }

        const customSort = (data, sortColumn = 'status', sortAscending = true) => {
            return (sortAscending) ? data.sort((a, b) => {
                let fa, fb;
                if(sortColumn != 'status') {
                    fa = a.job[sortColumn].toLowerCase();
                    fb = b.job[sortColumn].toLowerCase();
                } else {
                    fa = a[sortColumn].toLowerCase();
                    fb = b[sortColumn].toLowerCase();
                }
                if (fa < fb) { return -1; }
                if (fa > fb) { return 1; }
                return 0;
            }) : data.sort((a, b) => {
                let fa, fb;
                if(sortColumn != 'status') {
                    fa = a.job[sortColumn].toLowerCase();
                    fb = b.job[sortColumn].toLowerCase();
                } else {
                    fa = a[sortColumn].toLowerCase();
                    fb = b[sortColumn].toLowerCase();
                }
                if (fa > fb) { return -1; }
                if (fa < fb) { return 1; }
                return 0;
            });
        }

        const processSearch = () => {
            page.appliedJobs = [];
            page.sortype = 'status';
            page.sortdirection = 'ascending';
            page.ratesSortOptions.ascending = false;
            page.ratesSortOptions.descending = false;
            page.statusSortOptions.ascending = true;
            page.statusSortOptions.descending = false;
            searchAppliedJobs();
        }

        const searchAppliedJobs = _debounce(function (event) {
            let result = jobs._rawValue;

            if(page.searchValue != ''){

                result.forEach(function(item, index){
                    if(
                        item.company.company_name.toLowerCase().includes(page.searchValue.toLowerCase()) ||
                        item.job.position_title.toLowerCase().includes(page.searchValue.toLowerCase()) ||
                        item.job.location_role.toLowerCase().includes(page.searchValue.toLowerCase()) ||
                        item.job.compensation.toLowerCase().includes(page.searchValue.toLowerCase()) ||
                        item.job.city.toLowerCase().includes(page.searchValue.toLowerCase()) ||
                        item.job.state.toLowerCase().includes(page.searchValue.toLowerCase()) ||
                        item.status.toLowerCase().includes(page.searchValue.toLowerCase())
                    ) {
                        page.appliedJobs.push(item);
                    }
                });

                jobs.value = page.appliedJobs;
            } else {
                jobs.value = applications.value;
            }
        }, 500);

        const isResearchEnabled = (job_id) => {
            let isIdExist = false;
            if(page.authuser.research_application_ids != null) {
                let job_ids = page.authuser.research_application_ids.split(',');
                job_ids.forEach(id => {
                    if(job_id == id) {
                        isIdExist = true;
                    }
                });

                if(!isIdExist) {
                    return (page.authuser.subscription_plan_id != null && page.authuser.research_help_count >= page.authuser.research_count_available);
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        return {
            page,
            jobs,
            job_headers,
            applications,
            application_status,
            getApplications,
            job,
            getJob,
            visible,
            viewJob,
            job_types,
            compensation_types,
            location_roles,
            schedules,
            time_frames,
            rates,
            countries,
            getCountries,
            getSchedules,
            onClosing,
            ChangeView,
            triggerSort,
            processSearch,
            searchAppliedJobs,
            customSort,
            cancelApplication,
            toggleConfirmation,
            isConfirm,
            promptCancelConfirmation,
            removeApplication,
            destroyApplication,
            status,
            activeComponent,
            researchHelp,
            viewJobDetails,
            updateSingleResearchApplication,
            isResearchEnabled,
            getResearchHelp,
            research,
            subscriptionActive,
            subscription_type,
            renderResearch,
            is_notification,
            researchHelpAlert
        }

    }
})
</script>

<style scoped>
.application-fixed {
    background: #f3f3f3;
}
.search-field {
    position:relative;
    margin-bottom: 15px;
}
.search-field i {
    right: 7px;
    top: 8px;
    font-size: 17px;
    position:absolute;
}
.cancel-btn:disabled {
    border-color: #969696;
    color: #969696;
}
.btn-static { cursor: default;pointer-events: none; }
</style>
<style>
.cancel-confirmation > .modal-backdrop.show {
    z-index: 1999;
}
a.dropdown-item[disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
}
.translate-enter-active,
.translate-leave-active {
    transition: all 0.5s ease;
}

.translate-enter-from,
.translate-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
.swal2-popup {
    background: #f1f2f3 !important;
}
</style>