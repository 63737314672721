<template>
    <div>
        <Modal :modalActive="modalActive" :className="`modal-dialog modal-lg modal-dialog-scrollable`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-6">
                        <BaseInput
                            v-model="form.job_title"
                            label="Job Title"
                            placeholder="Enter Job Title"
                            type="text"
                            id="job_title"
                            :class="{ 'is-invalid' : errors.job_title }"
                            :errors="errors"
                            is-required
                        />
                        <BaseInput
                            v-model="form.company"
                            label="Company"
                            placeholder="Company Name"
                            type="text"
                            id="company"
                            :class="{ 'is-invalid' : errors.company }"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-6">
                        <BaseInput
                            v-model="form.company_address"
                            label="Company Address"
                            placeholder="Where is the company located?"
                            type="text"
                            id="company_address"
                            :class="{ 'is-invalid' : errors.company_address }"
                            :errors="errors"
                            is-required
                        />
                        <label for="worked_from" class="form-label">Time Period <span class="text-danger">*</span></label>
                        <div class="row">
                            <div class="col-6">
                                <Datepicker
                                    id="worked_from"
                                    v-model="form.worked_from"
                                    format="MM/dd/yyyy"
                                    :enableTimePicker="false"
                                    :range="false"
                                    placeholder="Start Date (MM/DD/YYYY)"
                                    :modelValue="form.worked_from"
                                    textInput
                                    :disabledDates="(date) => date >= new Date()"
                                    :class="{ 'is_invalid': errors.worked_from }"
                                    @update:modelValue="upDateTimePeriod"
                                />
                                <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors.worked_from">Time period is required.</label>
                            </div>
                            <div class="col-6" v-if="!currently_work">
                                <Datepicker
                                    id="worked_to"
                                    v-model="form.worked_to"
                                    format="MM/dd/yyyy"
                                    :enableTimePicker="false"
                                    :range="false"
                                    placeholder="End Date (MM/DD/YYYY)"
                                    :modelValue="form.worked_to"
                                    textInput
                                    :disabledDates="(date) => date > new Date()"
                                    :class="{ 'is_invalid': errors.worked_from }"
                                    :disabled="!form.worked_from"
                                />
                            </div>
                        </div>
                        <div class="mt-1"></div>
                        <BaseCheckbox
                            label="I currently work here"
                            placeholder="I currently work here"
                            id="currently_work"
                            v-model="currently_work"
                            :modelValue="currently_work ?? true"
                            design="inline"
                        />
                    </div>
                    <div class="col-12">
                        <div :class="{ 'is_invalid': errors.description }">
                            <BaseEditor
                                label="Description"
                                v-model="form.description"
                                id="description"
                                placeholder="Enter description"
                                @editorContent="setWorkExperienceContent"
                                is-required
                            />
                            <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors.description">Description is required.</label>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary" @click="saveChanges">Save Changes</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        errors: {
            type: Object,
            default: {}
        },
    },
    setup(props, { emit }) {
        const currently_work = ref(false);

        watchEffect(() => {
            if(currently_work.value == true) {
                props.form.worked_to = '';
            }
            props.form.description = props.form.description ?? '';
        });

        const close = () => {
            emit('close')
        }

        const saveChanges = () => {
            emit('saveChanges');
        }

        const setWorkExperienceContent = (evt) => {
            props.form.description = evt ?? '';
            props.errors.description = null;
        }

        const upDateTimePeriod = () => {
            props.errors.worked_from = null;
        }

        return {
            currently_work,
            close,
            saveChanges,
            setWorkExperienceContent,
            upDateTimePeriod
        }
    }
})
</script>
<style>
.is_invalid input.dp__input,
.is_invalid .tox.tox-tinymce {
    border-color: #d9534f !important;
}
.valid input.dp__input,
.valid .tox.tox-tinymce {
    border-color: #4bbf73 !important;
}
</style>