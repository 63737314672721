<template>
    <div>
        <h3>Job Description</h3>
        <p style="font-size: 12px">Fields with (<span class="text-danger">*</span>) is required.</p>
        <div class="row">
            <div class="col-12 mb-3">
                <BaseEditor
                    v-model="form.job_description"
                    id="job_description"
                    label="Please Enter Job Description"
                    :class="{ 'editor-field': true, 'is-invalid' : errors && errors.job_description }"
                    :errors="errors"
                    is-required
                    @editorContent="setDescriptionContent"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: {}
        },
        errors: {
            type: Object,
            default: {}
        }
    },
    setup(props, {emit}) {
        const setDescriptionContent = (evt) => {
            props.form.job_description = evt;
            const job_description = evt ?? '';
            emit('set-description', job_description);
        };

        return {
            setDescriptionContent
        }
    }
}
</script>