<template>
    <editor
        :api-key="tinykey"
        :init="init"
        v-model="form.default_benefits"
        @saveContent="saveContent"
    />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { defineComponent, watchEffect, ref } from 'vue';

export default defineComponent({
    props: {
        form: {
            type: Object,
            default: {}
        }
    },
    setup(props, {emit}) {
        const tinykey = ref(process.env.VUE_APP_TINY_MCE_KEY);
        const init = {
            height: 300,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'link image code preview'
            ],
            toolbar:
                'undo redo | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
        }

        const content = ref('');

        watchEffect(() => {
            content.value = props.form;
        })

        const saveContent = () => {
            emit('saveContent', content.value);
        }

        return {
            tinykey,
            init,
            saveContent,
            content
        }
    },
    components: {
        'editor': Editor
    }
})
</script>