<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">Import Results</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12 mb-4 custom-dialog" id="data-result-table">
                        <BaseTable :data="result_data" :options="headers">
                            <template v-slot:email="{ row }">
                                <div :class="(row.invite_status == 'Success') ? 'text-success' : 'text-danger'">{{ row.email }}</div>
                            </template>
                            <template v-slot:invite_status="{ row }">
                                <div class="text-success" v-if="(row.invite_status == 'Success')">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div class="text-danger" v-else>
                                    <i class="fas fa-times"></i>
                                </div>
                            </template>
                            <template v-slot:reason="{ row }">
                                <div class="text-success" v-if="(row.invite_status == 'Success')">
                                    The user has successfully invited.
                                </div>
                                <div class="text-danger" v-else>
                                    {{ row.reason }}
                                </div>
                            </template>
                        </BaseTable>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({
    props: ['modalActive','data'],
    setup(props,{ emit }) {

        const result_data = ref([]);
        const headers = [
            {
                key: 'invite_status',
                label: 'Status',
                class: 'narrow-column-reults text-center'
            },
            {
                key: 'email',
                label: 'Email Address'
            },
            {
                key: 'reason',
                label: 'Reason'
            }
        ];

        const close = () => {
            emit('close');
        }

        watchEffect(() => {
            result_data.value = props.data;
        })

        return {
            close,
            result_data,
            headers
        }
    }
})
</script>
<style>
#data-result-table #datatables-responsive thead tr th.narrow-column-reults {
    width: 1% !important;
}
</style>
<style scoped>
.custom-dialog {
    background: #fafafa;
    padding-bottom: 15px;
    padding-top: 10px;
    border-radius: 5px;
    color: #094006;
}
</style>