<template>
    <div class="message-composer">
        <div class="message-textbox">
            <button @click="trigger_post"><i class="fa fa-paper-plane"></i></button>
            <textarea id="message-textbox-input" placeholder="Type your message here." v-model="form.message" @keydown.enter="watch_textarea"></textarea></div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue';
export default defineComponent({
    props: ['form'],
    emits: ['trigger_post','watch_textarea' ],
    setup(props, { emit }) {

        const data = {}

        const trigger_post = (event) => {
            emit('trigger_post');
        }

        const watch_textarea = (event) => {
            event.preventDefault();
            emit('watch_textarea');
        }

        return {
            data,
            trigger_post,
            watch_textarea
        }
    }
})
</script>

<style>
    .message-composer {
        height: 90px;
        border-top: 2px solid #d8d8d8;
        background: #fbfbfb;
    }
    .message-composer[disabled="true"] {
        pointer-events: none;
        background: #f2f2f2;
    }
    .message-composer > div {
        padding: 13px 18px;
    }
    .message-textbox {
        width: 100%;
        position:relative;
    }
    .message-textbox button {
        position: absolute;
        right: 15px;
        border: 0;
        top: 25px;
        background: none;
        color: #9a9a9a;
        font-size: 20px;
    }
    .message-textbox textarea {
        width: 95%;
        border: none;
        resize: none;
        height: 100%;
        outline: none;
        background: transparent;
    }
</style>