<template>
    <ul class="sidebar-nav">
        <li class="sidebar-item">
            <a data-bs-target="#ui" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fa fa-solid fa-gear fa-fw"></i> <span class="align-middle">Settings</span>
            </a>
            <ul id="ui" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.profile' }">
                        Profile
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.settings.profile' }">
                        Profile Settings
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.settings.privacy' }">
                        Notifications &amp; Reminders
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <a data-bs-target="#job-find" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fa fas fa-suitcase fa-fw"></i> <span class="align-middle">Job Openings</span>
            </a>
            <ul id="job-find" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.jobs' }">
                        Job Search
                    </router-link>
                    <router-link class="sidebar-link" :to="{ name: 'candidate.interviewtrainings' }">
                        Interview Trainings
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <a data-bs-target="#job-applications" data-bs-toggle="collapse" class="sidebar-link collapsed">
                <i class="fa-solid fa-user-tie fa-fw"></i> <span class="align-middle">Job Applications</span>
            </a>
            <ul id="job-applications" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.applications' }">
                        Applied Jobs
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.applications.saved' }">
                        Saved Job Applications
                    </router-link>
                </li>
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.applications.invites' }">
                        Job Invitations
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="sidebar-item">
            <router-link class="sidebar-link" :to="{ name: 'candidate.trainings' }">
                <i class="fas fa-book fa-fw"></i> <span class="align-middle">Training Courses</span>
            </router-link>
            <ul id="trainingCourses" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'candidate.trainings' }">
                        Training Courses
                    </router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        authuser: {
            type: Object,
            default: {}
        }
    }
})
</script>

<style>
    body[data-sidebar-behavior=compact]:not([data-sidebar-position=right]) .sidebar-nav>.sidebar-item>.sidebar-dropdown {
        left: 65px !important;
    }
    body[data-sidebar-behavior=compact] .sidebar-nav>.sidebar-item>.sidebar-dropdown {
        background: #293042 !important;
    }
    body[data-sidebar-behavior=compact] .sidebar-nav>.sidebar-item>.sidebar-dropdown>.sidebar-item .sidebar-link {
        color: #fff !important;
    }
</style>