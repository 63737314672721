<template>
    <div class="settings-panel" :class="{ 'is-open': isOpen }">
        <div class="settings-content">
            <div class="settings-title align-items-center">
                <button type="button" class="btn-close float-right js-settings-toggle" aria-label="Close" @click="closeNotifications(0)"></button>
                <h4 class="mb-0 ms-2 d-inline-block">Notifications</h4>
                <div class="float-end">
                    <div class="dropdown position-relative">
                        <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                            <i class="fas fa-ellipsis-h"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-end">
                            <button class="dropdown-item" @click="readAllNotification">Mark all notifications as read</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings-body settings-body-notification">
                <div class="mb-3" v-if="notifications.length">
                    <div v-for="notification in notifications" :key="notification.id">
                        <router-link :to="routeConfig(notification)" class="list-group-item link-text" style="margin: 5px 0;" :class="{ 'link-unread' : notification.status == 'Unread' }" @click="closeNotifications(notification.id)">
                            <div class="row g-0 align-items-center">
                                <div class="col-3">
                                    <div v-if="notification.type == 'Message'">
                                        <img :src="notification.user_photo" class="notification-photo">
                                    </div>
                                    <div class="pl-10" v-else>
                                        <i :class="notification.icon"></i>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div class="text-dark" :class="{ 'text-bold' : notification.status == 'Unread' }">{{ notification.message_text }}</div>
                                    <div class="text-muted small mt-1" :class="{ 'text-bold' : notification.status == 'Unread' }">{{ notification.message }}</div>
                                    <div class="text-muted small mt-1" :class="{ 'text-bold' : notification.status == 'Unread' }">{{ notification.created_at_display }}</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div v-if="notifications.length" v-observe-visibility="handleScrolledToBottom"></div>
                    <div class="loading-animation" v-if="page.isLoading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-else>
                    <div class="py-5 text-center">
                        <h5>No notifications available</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import notifRepo from '@/repositories/settings/notification';
import Pusher from 'pusher-js';

export default defineComponent({
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const { notifications, getNotifications, updateNotificationToRead } = notifRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            currentPage: 1,
            lastPage: 1,
            isLoading: false
        });

        const handleScrolledToBottom = (isVisible) => {
            if(!isVisible) { return }
            if(page.currentPage >= page.lastPage) { return }

            page.isLoading = true;
            page.currentPage++;
            getNotifications(page);
        }

        const closeNotifications = async (id) => {
            emit('close');
            if(id) {
                notifications.value = [];
                page.currentPage = 1;
                await updateNotificationToRead(page, 'Normal', id);
            }
        }

        const readAllNotification = async () => {
            emit('close');
            notifications.value = [];
            page.currentPage = 1;
            await updateNotificationToRead(page, 'Normal');
        }

        const routeConfig = (notification) => {
            let routeObj = {};
            if(notification.type == 'Application' || notification.type == 'Application Updates') {
                routeObj = { name: notification.link, params: { job_id: notification.job?.id } };
            } else if(notification.type == 'Learning Plan Assign' || notification.type == 'Assigned Courses') {
                routeObj = { name: notification.link, query: { tab: 'learning-plan-assign' } };
            } else if(notification.type == 'Generate Job Information') {
                routeObj = { name: notification.link, params: { hashid: notification.job?.hashid } };
            } else {
                routeObj = { name: notification.link };
            }
            return routeObj;
        }

        watch(() => props.isOpen, async () => {
            notifications.value = [];
            if(props.isOpen == true) {
                await getNotifications(page);
            }
        });

        return {
            closeNotifications,
            page,
            notifications,
            getNotifications,
            handleScrolledToBottom,
            readAllNotification,
            updateNotificationToRead,
            routeConfig
        }
    }
})
</script>

<style>
.is-open {
    right: 0px !important;
}
.loading-animation {
    text-align: center;
    padding: 10px;
}
.notification-photo {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    padding: 0 10px;
}
.settings-body-notification {
    padding: 10px !important;
}
.link-unread {
    background: #e0eafc !important;
}
.link-text {
    text-decoration: none;
}
.text-bold {
    font-weight: bold;
}
</style>