<template>
    <div class="tab-pane fade show active" id="password-tab" role="tabpanel">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Password</h5>
                <BaseInput 
                    v-model="validate.password"
                    label="New Password"
                    type="password"
                    :class="{ 'is-invalid' : errors && errors.empty_password }"
                    id="password1"
                />
                <BaseInput 
                    v-model="validate.password_confirmation"
                    label="Verify Password"
                    type="password"
                    :class="{ 'is-invalid' : errors && errors.password_confirmation }"
                    id="password_confirmation"
                    :errors="errors"
                />
                <BaseButton :btn-value = "`Update Password`" :is-saved="status === 200" @btnClicked="saveUserPassword" v-if="!page.isProcessing" /><button class="btn btn-primary" disabled v-else>Update Password</button>
            </div>
            <ModalPassword 
                :modalActive="modalActive"
                :errors="passwordErrors"
                :modalTitle="page.modalHeader"
                :passwordText="page.passwordText"
                @close="toggleModal"
                @saveChanges="confirmChangePassword"
                :disableButton="page.isProcessing"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import userRepo from '@/repositories/settings/users';
import authRepo from '@/repositories/auth';
import ModalPassword from '@/views/employer/settings/config/ModalPassword.vue';

export default defineComponent({
    setup() {
        const { updatePassword, errors, status } = userRepo();
        const { confirmPassword, passwordErrors } = authRepo();

        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            modalHeader: 'Confirm Password',
            passwordText: 'Type in your password to proceed',
            isProcessing: false,
        });

        const modalActive = ref(false);
        const toggleModal = () => {
            modalActive.value = !modalActive.value;
            if(modalActive.value === false) {
                passwordErrors.value = '';
                status.value = 200;
            }
        }

        const validate = reactive({
            password: '',
            password_confirmation: '',
            id: page.authuser.id
        })
        
        const saveUserPassword = async () => {
            status.value = 200;
            if(validate.password && validate.password_confirmation) {
                errors.value = '';
                page.passwordText = 'Please type your old password to proceed';
                modalActive.value = true;
            } else {
                errors.value = {
                    empty_password : [ true ],
                    password : [ 'Please enter both password fields.' ]
                }
            }
        }

        const confirmChangePassword = async (data) => {
            page.isProcessing = true;
            await confirmPassword(page, data, true);
            if(passwordErrors.value == '') {
                await updatePassword({...validate});
                modalActive.value = false;
                status.value = 200;
            } else {
                passwordErrors.value = "Hmm… That doesn’t seem correct. Please re-enter your old password";
            }
            page.isProcessing = false;
        }

        return {
            page,
            validate,
            errors,
            status,
            saveUserPassword,
            updatePassword,
            passwordErrors,
            modalActive,
            toggleModal,
            confirmChangePassword
        }
    },
    components: {
        ModalPassword
    }
})
</script>