<template>
    <div :class="{
        'conversation-item': true,
        'has-unread': hasUnread
    }" v-if="user_id > 0" :id="`conversation-item-${user_id}`">
        <a href="#" class="p-3 pt-2 pb-2 is-inline" @click="trigger_action">
            <span class="image is-inline">
                <img :src="image" class="rounded-circle">
            </span>
            <span class="name-details is-inline p-3 pt-1 pb-1">
                <span class="name is-inline">{{ fullName }} </span>
                <span class="details-name is-inline" v-if="hasSubDetails">{{ subDetails }}</span>
                <span class="details is-inline">
                    <i :class="{ 
                        'chat-status': true, 
                        'online': isOnline, 
                        'offline': isOffline, 
                        'away': isAway,
                        'dont-disturb': isDontDisturb }">
                    </i>
                    <i class="chat-status-text">{{ chatStatusText }}</i></span>
            </span>
            <span :class="{
                'message-count': true,
                'is-inline': true,
                'force-hide': !hasUnread
            }">
                <span class="count is-inline" v-if="hasUnread">{{ unreadCount }}</span>
            </span>
        </a>
    </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue';
export default defineComponent({
    props: {
        user_id: {
            type: Number,
            default: 0
        },
        hasUnread: {
            type: Boolean,
            default: false
        },
        hasSubDetails: {
            type: Boolean,
            default: true
        },
        unreadCount: {
            type: Number,
            default: 0
        },
        image: {
            type: String,
            default: 'http://multicomp.cs.cmu.edu/wp-content/uploads/2016/08/person-placeholder-300x300.jpg'
        },
        fullName: {
            type: String,
            default: 'No Name'
        },
        subDetails: {
            type: String,
            default: 'No Details'
        },
        isOnline: {
            type: Boolean,
            default: false
        },
        isOffline: {
            type: Boolean,
            default: true
        },
        isAway: {
            type: Boolean,
            default: false
        },
        isDontDisturb: {
            type: Boolean,
            default: false
        },
        chatStatusText: {
            type: String,
            default: 'Offline'
        }
    },
    emits: ['trigger_action' ],
    setup(props, { emit }) {

        const trigger_action = (event) => {
            emit('trigger_action');
        }

        const data = {}

        return {
            data,
            trigger_action
        }
    }
})
</script>

<style>
    .conversation-item {
        border-bottom: 1px solid #eaeaea;
        border-left: 4px solid transparent;
    }
    .conversation-item a {
        width:100%;
    }
    .conversation-item .image img {
        height:41px;
        width:41px;
    }
    .conversation-item.active, .conversation-item.active:hover {
        background: #e3e7ec;
        border-left: 4px solid #9a9a9a;
    }
    .conversation-item:hover {
        background: #eaeaea;
    }
    .conversation-item .image {
        width: 14%;
    }
    .conversation-item .name-details,
    .conversation-item .name-details .name,
    .conversation-item .name-details .details {
        width:70%;
        max-width: 215px;
        line-height: 18px;
    }
    .conversation-item .name-details .name {
        font-size: 14px;
        color: #606060;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 190px;
        white-space: nowrap;
    }
    .conversation-item .name-details .details-name {
        color: #757575;
        font-size: 12px;
        width: 100%;
    }
    .conversation-item .name-details .details .chat-status-text {
        font-style: normal;
        color: #6c757d;
        font-size: 11px;
    }
    .conversation-item .name-details .details .chat-status {
        width: 10px;
        height: 10px;
        display: inline-block;
        background: #afafaf;
        vertical-align: middle;
        margin-right: 5px;
        border-radius: 50%;
    }
    .conversation-item .name-details .details .chat-status.online { 
        background: #36d251;
    }
    .conversation-item .name-details .details .chat-status.away { 
        background: #cfd60d;
    }
    .conversation-item .name-details .details .chat-status.dont-disturb { 
        background: #ff3600;
    }
    .conversation-item .message-count {
        width: 16%;
        text-align: right;
        float: right;
        margin-top: 6%;
    }
    .conversation-item .message-count .count {
        background: #ec0808;
        color: #FFF;
        padding: 2px 4px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 3px;
        min-width: 23.5px;
        text-align: center;
    }
    .has-unread .name {
        font-weight: bold;
    }
    .conversation-item .image img {
        box-shadow: 0px 0px 1px 1px #919191;
    }
</style>