<template>
    <div>
        <transition name="fade">
            <div v-show="modalActive" class="modal fade show" id="sizedModalLg" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;" @keydown.esc="close">
                <div :class="className" role="document">
                    <div class="modal-content">
                        <slot name="header" />
                        <div class="modal-body" :class="bodyClass">
                            <slot name="body" />
                        </div>
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </transition>
        <div v-if="modalActive" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: ''
        },
        className: {
            type: String,
            default: 'modal-dialog modal-lg'
        },
        bodyClass: {
            type: String,
            default: ''
        }
    },
    emits: ['saveChanges','close','deleteBtn'],
    setup(props, { emit }) {
        
        const close = () => {
            emit('close')
        }

        return {
            close
        }

    },
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.modal-index {
    z-index: 2000;
}
.modal-header.no-border {
    border: 0;
}
.assign-learning-plan-modal {
    height: 70vh!important;
    overflow: auto;
}
</style>