import JobIndex from '@/views/employer/jobs/Index';
import JobArchive from '@/views/employer/jobs/Archive';

const routes = [
    {
        path: '/employer/jobs',
        name: 'employer.jobs',
        component: JobIndex
    },
    {
        path: '/employer/jobs/applications',
        name: 'employer.jobs.applications',
        component: JobIndex
    },
    {
        path: '/employer/jobs/applications/saved',
        name: 'employer.jobs.applications.saved',
        component: JobIndex
    },
    {
        path: '/employer/jobs/archive',
        name: 'employer.jobs.archive',
        component: JobArchive
    }
]

export default routes