<template>
    <div>
        <Modal :modalActive="modalActive">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12 mb-3">
                        <strong>Assigned {{ form.name }} to employee</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-label">Employee(s)</label>
                            <multiselect
                                v-model="user_ids"
                                :options="users"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                placeholder="Select Employee"
                                label="name"
                                track-by="id"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <BaseButton :is-saved="status === 200" @btnClicked="saveChanges" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import trainingRepo from '@/repositories/employer/trainings';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        errors: {
            type: [Object, String],
            default: {}
        }
    },
    setup(props, { emit }) {
        const { users, getEmployees, assignCustomLearningPlan, errors, status, assignLearningPlan } = trainingRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true
        });
        const user_ids = ref([]);

        const close = () => {
            emit('close')
        }

        const saveChanges = async () => {
            let users = user_ids.value;
            let user_array_ids = [];
            if(users.length) {
                users.forEach(user => {
                    user_array_ids.push(user.id);
                });
            }

            let formData = new FormData();
            formData.append('learning_plan_id', props.form.learning_plan_id ?? '');
            formData.append('assigned_to', user_array_ids);
            formData.append('assigned_by', page.authuser.id);
            formData.append('company_id', page.authuser.company_id);
            await assignLearningPlan(formData);

            if(status.value == 200) {
                user_ids.value = [];
                emit('reloadPage');
            }
        }

        onMounted(() => {
            getEmployees(page);
        });

        return {
            page,
            users,
            user_ids,
            getEmployees,
            assignCustomLearningPlan,
            errors,
            status,
            close,
            saveChanges
        }
    }
})
</script>