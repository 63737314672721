<template>
    <div>
        <Modal 
        :modalActive="modalActive" 
        :modalTitle="modalTitle" 
        :className="`modal-dialog modal-sm`"  
        :saveButtonText="state.sendButtonLabel" 
        :saveButtonDisabled="state.saveButtonDisabled"
        @close="close" 
        @saveChanges="sendReply" >
            <div class="row">
                <div class="col-12">
                    <BaseInput 
                            v-model="state.email"
                            label="To Email"
                            type="email"
                            :class="{ 'is-invalid' : v$.email.$error && v$.email.$errors[0].$message }"
                            id="email"
                            :errors="errors"
                            is-required
                            placeholder="name@example.com"
                            autofocus
                            :customClass="'input-group-lg'"
                        />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label for="feedback_message" class="form-label">Message <span class="text-danger">*</span></label>
                    <textarea 
                    v-model="state.message" 
                    class="form-control" 
                    id="feedback_message" 
                    rows="5"
                    :class="{ 'is-invalid' : v$.message.$error && v$.message.$errors[0].$message }"
                    ></textarea>
                    <span class="text-danger" v-show="v$.message.$error && v$.message.$errors[0].$message">
                        {{ v$.message.$error && v$.message.$errors[0].$message }}
                    </span>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, reactive, computed, ref, watchEffect, inject } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, email, url, helpers } from '@vuelidate/validators'
import { mapActions } from "vuex";

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: ''
        },
        replyTo: {
            type: String,
            default: ''
        },
        replyToName: {
            type: String,
            default: ''
        },
        feedBackID: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        const toast = inject("toast");
        const errors = ref({});
        const state = reactive({
            email: "",
            message: "",
            fullname: "",
            sendButtonLabel: "Send",
            saveButtonDisabled: false
        });
        const rules = computed(() => {
            return {
                email: {
                    required: helpers.withMessage("Email is required.", required),
                    email
                },
                message: { required: helpers.withMessage("Message is required.", required) }
            };
        });
        const v$ = useVuelidate(rules, state);
        watchEffect(() => {
            state.email = props.replyTo
            state.fullname = props.replyToName
        })
        const close = () => {
            emit('close')
        }
        return {
            state,
            v$,
            close,
            errors,
            toast
        }
    },
    methods: {
        ...mapActions({
            submitFeedbackReply: "email/submitFeedbackReply",
            getFeedbackReply: "feedback/getFeedbackReply"
        }),
        /**
         * This function send/submit feedback reply message from admin if no error
         */
        async sendReply() {
            this.state.sendButtonLabel = 'Sending...'
            this.state.saveButtonDisabled = true
            this.v$.$validate();
            this.errorEvent();

            if (!this.v$.$error) {
                let formData = new FormData();
                formData.append('to', this.state.email ?? '');
                formData.append('toName', this.state.fullname ?? '');
                formData.append('body', this.state.message ?? '');
                formData.append('replied_by', 'admin');
                formData.append('feedback_id', this.feedBackID ?? '');
                let response = await this.submitFeedbackReply(formData)
                if (response.status == 200) {
                    this.toast.success(response.message);
                } else {
                    this.toast.error(response.message);
                }
                this.close()
                this.clearFields()
                this.v$.$reset(); /** Reset the vuelidate state, clearing the current validation error as well. */

                await this.getFeedbackReply(this.feedBackID )
            }
            this.state.sendButtonLabel = 'Send'
            this.state.saveButtonDisabled = false
        },
        /**
         * This function clears current value of the fields
         */
        clearFields() {
            this.state.email = '';
            this.state.message = '';
            this.state.fullname = '';
        },
        errorEvent() {
            if (this.v$.email.$errors[0]) {
                this.errors.email = [this.v$.email.$errors[0].$message];
            }
            if (this.v$.message.$errors[0]) {
                this.errors.message = [this.v$.message.$errors[0].$message];
            }
        }
    }
})
</script>