<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1>Skill Management</h1>
            <div class="row card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-5">
                            <div class="input-group input-group-md">
                                <input 
                                    v-model="state.name" 
                                    type="text" 
                                    class="form-control skill-name" 
                                    id="name"
                                    :class="{ 'is-invalid' : errors && errors.name }"
                                    placeholder="Skill Name" 
                                    aria-describedby="button-addon2"
                                    style="z-index: 0"
                                >
                                <Datepicker
                                    id="release_date"
                                    v-model="state.date_released" 
                                    format="MM/dd/yyyy"
                                    :enableTimePicker="false"
                                    :range="false"
                                    placeholder="Release Date (MM/DD/YYYY)"
                                    :modelValue="state.date_released"
                                    textInput
                                    :maxDate="new Date()"
                                />
                                <button class="btn btn-primary btn-add" type="button" id="button-addon2" @click="addSkill">
                                <i class="fa-solid fa-plus" style="font-size:14px;"></i> Add</button>
                            </div>
                            <!-- Backend Error -->
                            <div v-if="errors">
                                <div class="text-danger" v-for="er in errors" :key="er">
                                    <div v-if="er.length > 0">
                                        <div v-for="error in er" :key="error">
                                            {{error}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <div v-for="(skill) in skills" :key="skill" class="w-20 p-3 float-start list-group-item me-2 mb-2 shadow" >
                                <div>{{skill.name}}
                                <i class="fa-solid fa-trash red float-end mt-1" @click="deleteSkill(skill.id)"></i>
                                <i class="fa-solid fa-pen-to-square float-end mt-1 me-3" @click="editSkill(skill.id)"></i></div>
                                <div class="release-date">
                                    {{skill.date_released_readable}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalEditSkill 
            :modalActive="modalActive"
            :form="skill"
            :modalTitle="state.modalHeader"
            @processed="toggleModal"
            @close="toggleModal"
        />
        <Confirm 
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this skill?`"
            @cancel="toggleConfirmation"
            @isDelete="removeSkill"
            :confirmButton="'Yes, delete skill'"
        />
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, computed } from 'vue';
import ModalEditSkill from '@/views/admin/skills/ModalEditSkill.vue';
import { required, helpers } from '@vuelidate/validators'
import skillsRepo from '@/repositories/admin/skills';

export default defineComponent({
    setup() {
        const state = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            modalHeader: 'Update Skill',
            name: '',
            date_released: ''
        });
        const rules = computed(() => {
            return {
                name: {
                    required: helpers.withMessage("Skill name is required.", required)
                }
            };
        });
        const { insertSkills, errors, getSkills, skills, destroySkill, status } = skillsRepo();
        const modalActive = ref(false);
        const skill = ref([])
        const isConfirm = ref(false);
        const deleteSkillID = ref(null);

        const editSkill = (skill_id) => {
            errors.value = []
            toggleModal()
            skill.value = skills.value.filter(sk => sk.id == skill_id)[0]
        }

        const addSkill = async () => {
            errors.value = [];
            let formData = new FormData();
            formData.append('name', state.name ?? '');
            formData.append('date_released', state.date_released ?? '');
            await insertSkills(state, formData);
            
            if(status.value == 200) {
                state.name = '';
                state.date_released = '';
                await getSkills(state);
            }
        }

        const deleteSkill = (id) => {
            deleteSkillID.value = id
            toggleConfirmation();
        }

        const removeSkill = async () => {
            await destroySkill(deleteSkillID.value);
            await getSkills(state);
            toggleConfirmation();
        }

        const toggleModal = async (evt) => {
            modalActive.value = !modalActive.value;
            if(evt !== undefined) {
                await getSkills(state);
            }
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        onMounted(() => {
            getSkills(state);
        });

        return {
            state,
            errors,
            status,
            skill,
            isConfirm,
            skills,
            modalActive,
            addSkill,
            toggleModal,
            editSkill,
            getSkills,
            removeSkill,
            deleteSkill,
            toggleConfirmation,
        }
    },
    components: {
        ModalEditSkill
    }
})
</script>
<style>
.list-group-item {
    border-top-width: 1px!important;
}
.fa-pen-to-square,.fa-trash:hover { cursor: pointer; }
.w-20 { width: 24%; }
.input-group.input-group-md .dp__input {
    border-radius: 0;
    height: 31.45px;
    border: 1px solid #ced4da;
    width: 285px;
}
.release-date {
    font-size: 10px;
    color: #939393;
    height: 17px;
}
</style>