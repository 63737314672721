import axios from "axios";
import { ref, inject } from "vue";

export default function externalJobsRepo() {
    const errors = ref([]);
    const status = ref('');
    const toast = inject('toast');
    const externaljobs = ref([])

    const getExternalJobs = async (page) => {
        let response = await axios.get(`candidate/external-jobs`);
        externaljobs.value = response.data.data;
        page.isLoading = false;
    }

    return {
        errors,
        status,
        toast,
        getExternalJobs,
        externaljobs
    }
}