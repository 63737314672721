<template>
  <div class="tab-pane fade show active" id="notification" role="tabpanel">
    <div class="card mb-4">
      <div class="card-header">
        <h5 class="card-title mb-0">Notification Settings</h5>
      </div>
      <div class="card-body text-center" v-if="page.isLoading">
        <page-loader />
      </div>
      <div class="card-body pt-0" v-else>
        <div class="row">
          <div class="col-7">
            <BaseCheckbox design="inline" v-model="notification_setting.matched_candidates"
              placeholder="Enable notifications when there are matched candidates." id="matched_candidates" />
            <BaseCheckbox design="inline" v-model="notification_setting.new_message"
              placeholder="Enable notifications when there are new messages." id="new_message" />
            <BaseCheckbox design="inline" v-model="notification_setting.new_applicant"
              placeholder="Enable notifications when there are new applicants." id="new_applicant" />
            <BaseCheckbox design="inline" v-model="notification_setting.completed_course"
              placeholder="Enable notifications if any of your employees completed a training course."
              id="completed_course" />
            <div class="form-group mb-3">
              <BaseButton :btnValue="`Update Notification Settings`" :is-saved="status === 200" @btnClicked="saveNotification" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">Reminder Settings</h5>
      </div>
      <div class="card-body text-center" v-if="page.isLoading">
        <page-loader />
      </div>
      <div class="card-body pt-0" v-else>
        <div class="row">
          <div class="col-12">
            <BaseCheckbox design="inline" v-model="notification_setting.reminder_users_unassigned_courses"
              placeholder="Remind me when there are users with unassigned courses." id="reminder_users_unassigned_courses" />
            <BaseCheckbox design="inline" v-model="notification_setting.reminder_unattended_applications"
              placeholder="Remind me when there are job applications that have not taken action for the last 3 days." id="reminder_unattended_applications" />
            <BaseCheckbox design="inline" v-model="notification_setting.reminder_saved_candidates"
              placeholder="Remind me if there are saved candidates that have not invited yet for the last 5 days." id="reminder_saved_candidates" />
            <div class="form-group mb-3">
              <BaseButton :btnValue="`Update Reminder Settings`" :is-saved="status === 200" @btnClicked="saveNotification" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue';
import notifRepo from '@/repositories/settings/notification';

export default defineComponent({
  setup() {
    const { notification_setting, getNotificationSetting, updateNotificationSetting, status } = notifRepo();
    const page = reactive({
      authuser: JSON.parse(localStorage.getItem('authuser')),
      isLoading: true
    });

    onMounted(async () => {
      await getNotificationSetting(page);
    })

    const saveNotification = async () => {
      status.value = '';
      let formData = new FormData();
      formData.append('new_applicant', notification_setting.value.new_applicant ?? false);
      formData.append('new_message', notification_setting.value.new_message ?? false);
      formData.append('completed_course', notification_setting.value.completed_course ?? false);
      formData.append('matched_candidates', notification_setting.value.matched_candidates ?? false);
      formData.append('reminder_users_unassigned_courses', notification_setting.value.reminder_users_unassigned_courses ?? false);
      formData.append('reminder_unattended_applications', notification_setting.value.reminder_unattended_applications ?? false);
      formData.append('reminder_saved_candidates', notification_setting.value.reminder_saved_candidates ?? false);
      formData.append('user_id', page.authuser.id);
      await updateNotificationSetting(formData);
    }

    return {
      status,
      page,
      notification_setting,
      getNotificationSetting,
      saveNotification,
      updateNotificationSetting
    }
  }
})
</script>