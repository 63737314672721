import axios from "axios";
import { ref, inject } from "vue";

export default function roleRepo() {
    
    const toast = inject('toast');
    const works = ref([]);
    const work = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');

    const getWorks = async (page) => {
        let user_id = page.authuser.id;
        let response = await axios.get(`candidate/profile/works?user_id=${user_id}`);
        works.value = response.data.data;
        page.isLoading = false;
    }

    const getWork = async (id, page) => {
        let response = await axios.get(`candidate/profile/works/${id}`);
        work.value = response.data.data;
        page.isLoading = false;
    }

    const insertWork = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/works`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const updateWork = async (id, data) => {
        errors.value = '';
        try {
            let response = await axios.post(`candidate/profile/works/${id}`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const deleteWork = async (id) => {
        let response = await axios.delete(`candidate/profile/works/${id}`);
        alertMessage(response.data.message);
    }

    const addMultipleWork = async (works, form) => {
        errors.value = '';
        try {
            let data = {
                works: works,
                form: form
            }
            let response = await axios.post(`candidate/profile/works/add-multi-works`, data);
            status.value = response.data.status;
            alertMessage(response.data.message);
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const alertMessage = (alert, type = 200) => {
        if(type == 200) {
            toast.success(alert);
        } else {
            toast.error(alert);
        }
    }

    return {
        works,
        work,
        getWorks,
        getWork,
        insertWork,
        updateWork,
        deleteWork,
        addMultipleWork,
        alertMessage,
        errors,
        message,
        status
    }

}