import axios from "axios";
import { ref, inject } from "vue";

export default function applicantRepo() {

    const applicants = ref([]);
    const applicant = ref([]);
    const candidate_statuses = ref([]);
    const application = ref([]);
    const candidateSeries = ref([]);
    const candidateCategories = ref([]);
    const message = ref('');
    const status = ref([]);
    const toast = inject('toast');

    const getApplicants = async (page) => {
        let response = await axios.get(`employer/applicants?company_id=${page.authuser.company_id}`);
        applicants.value = response.data.data;
        page.isLoading = false;
    }

    const getArchivedApplicants = async (page) => {
        let response = await axios.get(`employer/applicants/applications/archived?company_id=${page.authuser.company_id}`);
        applicants.value = response.data.data;
        page.isLoading = false;
    }

    const getApplicant = async (id, job_id) => {
        let response = await axios.get(`employer/applicants/get-candidate/${id}/${job_id}`);
        applicant.value = response.data.candidate;
        application.value = response.data.application;
    }

    const openApplicant = async (id, job_id) => {
        await axios.post(`employer/applicants`, {
            candidate_id: id,
            job_id: job_id
        });
    }

    const updateCandidateStatus = async (page) => {
        let response = await axios.post(`employer/applicants/update-status`, {
            candidate_id: page.applicantId,
            job_id: page.jobId,
            status: page.selectedStatus,
            user_id: page.authuser.id
        });

        alertMessage(response.data.message);
        status.value = response.data.status;
    }

    const getCandidateStatus = async () => {
        let response = await axios.get(`employer/status`);
        candidate_statuses.value = response.data.statuses;
    }

    const filterCandidates = async (page, status) => {
        let response = await axios.post(`employer/applicants/filter-candidates`, {
            company_id: page.authuser.company_id,
            status: status
        });
        applicants.value = response.data.data;
        page.isLoading = false;
    }

    const removeApplication = async (page) => {
        let response = await axios.get(`employer/applicants/application/archive?application_id=${page.idToDelete}`);
        alertMessage(response.data.message);
    }

    const getCandidateReports = async (page) => {
        let response = await axios.get(`employer/applicants/reports?company_id=${page.authuser.company_id}`);
        candidateCategories.value = response.data.categories;
        candidateSeries.value = response.data.series;
        page.isLoading = false;
    }

    const alertMessage = (alert) => {
        toast.success(alert);
    }

    return {
        applicants,
        applicant,
        getApplicants,
        getApplicant,
        openApplicant,
        getCandidateStatus,
        candidate_statuses,
        application,
        updateCandidateStatus,
        message,
        status,
        alertMessage,
        filterCandidates,
        removeApplication,
        getArchivedApplicants,
        getCandidateReports,
        candidateCategories,
        candidateSeries
    }
}