<template>
    <div>
        <div class="card">
            <div class="card-header pb-0">
                <h5 class="card-title mb-0">Skills and Technologies</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div :class='{ "form-group" : true }'>
                            <multiselect
                                v-model="page.skills"
                                :options="page.skills"
                                tag-placeholder="Add this as new skill"
                                placeholder="Search for Skills and Technologies"
                                label="title"
                                track-by="id"
                                :multiple="true"
                                :taggable="true"
                                :class='{ "has-content" : page.skills.length, "is-multiselect-invalid" : errors.skills }'
                                @tag="addTagSkills"
                            />
                            <label class="error jquery-validation-error small form-text invalid-feedback" :v-if="errors.skills">This field is required.</label>
                        </div>
                    </div>
                </div>
                <div class="mt-3" style="z-index: 2000">
                    <BaseButton :is-saved="status === 200" @btnClicked="saveSkill" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue';
import candidateRepo from '@/repositories/settings/candidate';

export default defineComponent({
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const { updateCandidateSkills, getCandidateSkills, errors, status } = candidateRepo();
        const skills = props.user?.skills ?? [];
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            skills: [],
            mySkills: '',
            customSkills: ''
        });

        onMounted(() => {
            skills.forEach((skill, index) => {
                page.skills.push({
                    id: index+1,
                    title: skill.name
                });
            });
        });

        const saveSkill = async () => {
            status.value = '';
            let skill_title = '';

            page.skills.forEach(skill => {
                skill_title += skill.title.concat(',');
            });

            let formData = new FormData();
            formData.append('skills', skill_title);
            formData.append('json', page.mySkills);
            formData.append('custom_skills', page.customSkills);
            formData.append('type', 'skills');
            formData.append('user_id', page.authuser.id);
            await updateCandidateSkills(formData);
        }

        const addTagSkills = (newTag) => {
            const tag = {
                title: newTag,
                id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            page.skills.push(tag);
            errors.value = [];
        }

        return {
            page,
            updateCandidateSkills,
            getCandidateSkills,
            skills,
            errors,
            status,
            saveSkill,
            addTagSkills
        }
    }
})
</script>