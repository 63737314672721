<template>
    <div v-if="educations.length">
        <div v-for="(education, index) in educations" :key="education">
            <div class="row">
                <div class="col-6">
                    <BaseSelect
                        v-model="page.education_level[index]"
                        label="Level of Education"
                        id="education_level"
                        :options="education_levels"
                        :class="{ 'is-invalid' : errors.education_level }"
                        :errors="errors"
                        is-required
                    />
                </div>
                <div class="col-6">
                    <BaseInput 
                        v-model="page.field_study[index]"
                        label="Field of Study"
                        placeholder="Field of Study"
                        type="text"
                        id="field_of_study"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <BaseInput 
                        v-model="education.organization"
                        label="School Name"
                        placeholder="School Name"
                        type="text"
                        id="school_name"
                        :class="{ 'is-invalid' : errors.school_name }"
                        :errors="errors"
                        is-required
                    />
                </div>
                <div class="col-6">
                    <label :for="`date_from_${index}`" class="form-label">School Year</label>
                    <div class="row">
                        <div class="col-6">
                            <Datepicker
                                :id="`date_from_${index}`"
                                v-model="page.date_from[index]"
                                format="yyyy"
                                :enableTimePicker="false"
                                :yearPicker="true"
                                placeholder="From Year (eg. 2015)"
                                textInput
                            />
                        </div>
                        <div class="col-6">
                            <Datepicker
                                :id="`time_period_to_${index}`"
                                v-model="page.date_to[index]"
                                format="yyyy"
                                :enableTimePicker="false"
                                :yearPicker="true"
                                placeholder="To Year (eg. 2019)"
                                :disabled="!education.dates.completionDate"
                                textInput
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <BaseInput 
                        v-model="page.location[index]"
                        label="School Address"
                        placeholder="School Address"
                        type="text"
                        id="school_address"
                    />
                </div>
            </div>
            <hr v-show="educations.length != index+1">
        </div>
        <BaseButton :is-saved="status === 200" @btnClicked="saveEducation" />
    </div>
    <div v-else>
        <div class="text-center">
            <img src="/assets/images/empty.png" class="img-fluid img-30">
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, watchEffect } from 'vue';
import moment from 'moment';
import globalRepo from '@/repositories/global';
import educationRepo from '@/repositories/candidate/educations';

export default defineComponent({
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const page = reactive({ 
            authuser: JSON.parse(localStorage.getItem('authuser')),
            date_from: [],
            date_to: [],
            location: [],
            education_level: [],
            field_study: []
        });

        const educations = props.user?.education ?? [];
        const { education_levels } = globalRepo();
        const { addMultipleEducation, errors, status } = educationRepo();

        watchEffect(() => {
            if(educations.length) {
                educations.forEach((education, index) => {
                    page.date_from[index] = moment(String(education.dates.startDate)).format('YYYY');
                    page.date_to[index] = moment(String(education.dates.completionDate)).format('YYYY');
                    page.location[index] = education.location?.formatted;
                    page.education_level[index] = education.accreditation?.educationLevel;
                    page.field_study[index] = education.accreditation?.education;
                });
            }
        });

        const saveEducation = () => {
            status.value = '';
            let dates = {
                from: page.date_from,
                to: page.date_to,
                user_id: page.authuser.id
            }
            addMultipleEducation(educations, dates);
        }

        const format = (date) => {
            return moment(String(date)).format('Y');
        }

        const handleDate = (date) => {
            format(date);
        }

        return {
            page,
            education_levels,
            addMultipleEducation,
            errors, 
            status,
            educations,
            saveEducation,
            format,
            handleDate
        }
    }
})
</script>