<template>
    <main class="content">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <h1 class="h3 mb-3">Training Courses</h1>
                        <div class="d-flex align-items-center">
                            <DiscountedCourse />
                            <button class="btn btn-primary create-private-btn ml-3" @click="createPrivateCourse"> <i style="font-size:12px;" class="fa-solid fa-plus"></i> Create Private Course</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="false" class="col-lg-2 mt-3 training-filter" v-show="currentComponent != 'Custom'">
                    <div class="form-group mb-3">
                        <label for="learning_plan_id" class="form-label">Select Learning Plan</label>
                        <select id="learning_plan_id" v-model="learning_plan_id" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                            <option value="">--</option>
                            <option v-for="template in templates" :key="template" :value="template.id">{{ template.name }}</option>
                        </select>
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 mt-3 training-filter" v-show="currentComponent != 'Custom'">
                    <div class="form-group mb-3">
                        <label for="filter" class="form-label">Filter by</label>
                        <select v-model="filter" class="form-select" @change="filteredTrainings" :disabled="isDisabled">
                            <option value="">--</option>
                            <option v-for="filter in course_filters" :key="filter" :value="filter.id">{{ filter.name }}</option>
                        </select>
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 mt-3 training-filter">
                    <div class="form-group">
                        <label for="query_search" class="form-label">Search Courses</label>
                        <input
                            v-model="query_search"
                            class="form-control"
                            id="query_search"
                            placeholder="Search Courses by Title"
                            @input="searchCourse"
                            :disabled="isSearchDisabled"
                        />
                    </div>
                </div>
                <div class="position-relative col-lg-12" :class="{ 'col-lg-12 col-md-12 col-12 mb-1' : currentComponent != 'Custom', 'col-10' : currentComponent == 'Custom' }">
                    <div class="mtc-4 btn-group">
                        <button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'Courses' }" @click="trainingTabs('Courses')">All Courses</button>
                        <button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'Employee' }" @click="trainingTabs('Employee')">Employee Courses</button>
                        <button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'LearningPlanBasic' }" @click="trainingTabs('LearningPlanBasic')">Learning Plans</button>
                        <div v-if="false" class="btn-group main-group">
                            <button type="button" :class="{ '' : currentComponent == 'Basic' || currentComponent == 'Custom' || currentComponent == 'PremiumLearningPlan' }" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Learning Plans </button>
                            <ul class="dropdown-menu">
                                <li><button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'Basic' }" @click="trainingTabs('Basic')">Basic Learning Plans</button></li>
                                <li><button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'LearningPlanBasic' }" @click="trainingTabs('LearningPlanBasic')">Basic Learning Plans v2</button></li>
                                <li><button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'Custom' }" @click="checkAccountPlan('Custom')" @mouseover="activateTooltip()" @mouseleave="customLearningTooltip=false">Custom Learning Plans</button></li>
                                <li><button class="btn btn-outline-primary" :class="{ 'active' : currentComponent == 'PremiumLearningPlan' }" @click="checkAccountPlan('PremiumLearningPlan')" @mouseover="activateTooltip()" @mouseleave="customLearningTooltip=false">Premium Learning Plans</button></li>
                            </ul>
                        </div>
                    </div>
                    <span style="visibility:hidden" class="tooltip-box-training" v-show="customLearningTooltip" @mouseover="activateTooltip()" @mouseleave="customLearningTooltip=false">
                        Only for Pro and Premium users
                    </span>
                </div>
                <div class="loading-screen text-primary" v-if="!page.isDataLoaded">
                    <i class="fa fa-spinner fa-spin fa-3x"></i>
                </div>
            </div>
            <PageLoader v-if="page.isLoading" />
            <div v-else>
                <component
                    :is="currentComponent"
                    v-bind="{ trainings: trainings }"
                    @refresh-page="refresh"
                    @edit-private-course="editPrivateCourse"
                    :filterDatas="formFilter"
                />
            </div>
        </div>
        <ModalSubscription
            :modalActive="subscriptionActive"
            :userAccount="subscriptionType"
            :noHeaderBorder="true"
            :noBodyMargin="true"
            @close="toggleModalSubscription"
        />
        <ModalPrivate
            :modalActive="modalActivePrivate"
            :form="learning_plan"
            :modalTitle="page.modalHeader"
            :errors="errors"
            :has-image="learning_plan.base_thumbnail !== undefined && learning_plan.base_thumbnail !== ''"
            :has-video="page.hasVideo"
            @close="toggleModalPrivate"
            @saveChanges="savePrivate"
            @onDeleteBtn="deleteTraining"
        />
        <Confirm
            :is-confirm="isConfirm"
            :confirm-text="`Are you sure you want to delete this training course?.`"
            @cancel="toggleConfirmation"
            @isDelete="destroy"
        />
    </main>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import trainingRepo from '@/repositories/admin/trainings';
import globalRepo from '@/repositories/global';
import employerTrainingRepo from '@/repositories/employer/trainings';
import Basic from '@/views/employer/trainings/components/Basic.vue';
import LearningPlanBasic from '@/views/employer/trainings/components/LearningPlanBasic.vue';
import Courses from '@/views/employer/trainings/components/Courses.vue';
import Custom from '@/views/employer/trainings/components/Custom.vue';
import Employee from '@/views/employer/trainings/components/Employee.vue';
import PremiumLearningPlan from '@/views/employer/trainings/components/PremiumLearningPlan.vue';
import ModalSubscription from '@/views/employer/ModalSubscription.vue';
import ModalPrivate from '@/views/employer/trainings/modals/ModalPrivateLearningPlan.vue';
import DiscountedCourse from '@/views/employer/trainings/components/DiscountedCourse.vue';
import billingRepo from '@/repositories/employer/billing';
import _debounce from 'lodash/debounce';
import userRepo from '@/repositories/settings/users';

export default {
    setup() {
        const { trainings, templates, getTrainings, getPrivateLearningPlanTemplates, getSelectedTrainings, getSuggestedTrainings, searchTrainings } = trainingRepo();
        const { course_filters, subscription_type } = globalRepo();
        const { activeSubscription, subscription } = billingRepo();
        const { subscriptionType, getEmployerSubscriptionType } = userRepo();
        const {
            status,
            errors,
            learning_plan,
            insertPrivateLearningPlan,
            updatePrivateLearningPlan,
            destroyTraining,
            getCompanyPrivateCourse
        } = employerTrainingRepo();
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            showPremiumTab: false,
            isDataLoaded: false,
            modalHeader: 'Create Private Training Course',
            hasVideo: false
        });
        const learning_plan_id = ref('');
        const filter = ref('');
        const currentComponent = ref('Courses');
        const isDisabled = ref(false);
        const customLearningTooltip = ref(false);
        const upgradedAccount = ref(false); /** True if account is upgraded */
        const currentPlan = ref(''); /** Current Account Plan */
        const subscriptionActive = ref(false);
        const isSearchDisabled = ref(false);
        const query_search = ref('');
        const triggerCreatePrivateCourse = ref(false);
        const modalActivePrivate = ref(false);
        const isConfirm = ref(false);

        const formFilter = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: true,
            learning_plan_id: '',
            filter: '',
            component: '',
            query_search: '',
            visi: 'visible',
            isDataLoaded: false
        });

        const constructFilter = async () => {
            formFilter.learning_plan_id = learning_plan_id.value;
            formFilter.filter = filter.value;
            formFilter.component = currentComponent.value;
        }

        const filteredTrainings = async () => {
            if(learning_plan_id.value){
                let formData = new FormData();
                formData.append('learning_plan_id', learning_plan_id.value);
                formData.append('filter', filter.value);
                formData.append('component', currentComponent.value);
                formData.append('query_search', formFilter.query_search);
                await getSelectedTrainings(formData);
            } else {
                const company_id = page.authuser.company_id
                let formData = new FormData();
                formData.append('company_id', company_id);
                await getTrainings(formData);
            }
            await constructFilter();
        }

        const trainingTabs = async (tab) => {
            currentComponent.value = tab;
            if(tab == 'Courses') {
                learning_plan_id.value = '';
                filter.value = '';

                const company_id = page.authuser.company_id
                let formData = new FormData();
                formData.append('company_id', company_id);
                await getTrainings(formData);
                isDisabled.value = false;
            }

            if(tab == 'Employee') {
                learning_plan_id.value = '';
                filter.value = '';
                await constructFilter();
                isDisabled.value = true;
                formFilter.visi = 'hidden'
            } else {
                formFilter.visi = 'visible'
            }
            const filterSection = document.getElementsByClassName("training-filter");
            for (let i = 0; i < filterSection.length; i++) {
                filterSection[i].style.visibility = formFilter.visi;
            }

            if(tab == 'Custom') {
                trainings.value = [];
            }

            if(tab == 'Basic' || tab == 'LearningPlanBasic') {
                trainings.value = [];
                isDisabled.value = true;
            }

            if(tab == 'PremiumLearningPlan') {
                trainings.value = [];
                isDisabled.value = true;
            }
        }

        const checkAccountPlan = async (tab) => {
            if(upgradedAccount.value == false){
                subscriptionActive.value = true
            }else{
                trainingTabs(tab)
            }
        }

        const toggleModalSubscription = () => {
            subscriptionActive.value = !subscriptionActive.value;
        }

        const toggleModalPrivate = () => {
            modalActivePrivate.value = !modalActivePrivate.value;
            errors.value = [];
        }

        const activateTooltip = () => {
            if(upgradedAccount.value == false){
                customLearningTooltip.value = true
            }
        }

        const refresh = async () => {
            const company_id = page.authuser.company_id
            let formData = new FormData();
            formData.append('company_id', company_id);
            await getTrainings(formData);
            await getPrivateLearningPlanTemplates();
            await getCompanyPrivateCourse(formData);
        }

        const createPrivateCourse = () => {
            toggleModalPrivate();
        }

        const editPrivateCourse = (evt) => {
            learning_plan.value = evt;
            page.modalHeader = 'Update Private Training Course';
            if(learning_plan.value.video !== undefined && learning_plan.value.video !== '') {
                page.hasVideo = true;
            }
            toggleModalPrivate();
        }

        const toggleConfirmation = () => {
            isConfirm.value = !isConfirm.value;
        }

        const deleteTraining = () => {
            toggleConfirmation();
        }

        const savePrivate = async () => {
            formFilter.isDataLoaded = false;
            let formData = new FormData();
            formData.append('title', learning_plan.value.title ?? '');
            formData.append('skill_type', learning_plan.value.skill_type ?? '');
            formData.append('learning_plan_ids', learning_plan.value.learning_plan_ids ?? '');
            formData.append('tags', learning_plan.value.tags ?? '');
            formData.append('image', learning_plan.value.image ?? '');
            formData.append('video', learning_plan.value.video ?? '');
            formData.append('course_link', learning_plan.value.course_link ?? '');
            formData.append('content', learning_plan.value.content ?? '');
            formData.append('company_id', page.authuser.company_id);
            formData.append('user_id', page.authuser.id);
            formData.append('difficulty', learning_plan.value.difficulty ?? '');

            if(learning_plan.value.id) {
                formData.append('_method', 'PUT');
                await updatePrivateLearningPlan(formData, learning_plan.value.id);
            } else {
                await insertPrivateLearningPlan(formData);
            }

            if(status.value == 200) {
                toggleModalPrivate();
                formFilter.isDataLoaded = true;
            }
        }

        const destroy = async () => {
            page.isLoading = true;
            await destroyTraining(page, learning_plan.value.id);
            if(status.value == 200) {
                toggleConfirmation();
                toggleModalPrivate();
                await getPrivateLearningPlans(page);

                let formData = new FormData();
                formData.append('company_id', company_id);
                await getCompanyPrivateCourse(formData);
            }
        }


        onMounted( async () => {
            const company_id = page.authuser.company_id
            let formData = new FormData();
            formData.append('company_id', company_id);
            await getTrainings(formData);

            await getPrivateLearningPlanTemplates();
            await activeSubscription(page);
            upgradedAccount.value = subscription.value ? true:false; /** True if has existing subscription else False */
            await getEmployerSubscriptionType();
            if (subscriptionType.value == subscription_type.pro || subscriptionType.value == subscription_type.premiun) {
                page.showPremiumTab = true;
            }
            page.isDataLoaded = true;
        });

        const searchCourse = _debounce(function (event) {
            page.isLoading = true;
            let formData = new FormData();
            formData.append('query_search', event.target.value);
            formData.append('learning_plan_id', learning_plan_id.value);
            formData.append('filter', event.target.value);
            formData.append('user_id', page.authuser.id);
            formFilter.query_search = event.target.value
            constructFilter();
            searchTrainings(page, formData);
        }, 750);

        return {
            page,
            trainings,
            templates,
            learning_plan_id,
            filter,
            course_filters,
            getTrainings,
            getSuggestedTrainings,
            getPrivateLearningPlanTemplates,
            getSelectedTrainings,
            filteredTrainings,
            trainingTabs,
            currentComponent,
            isDisabled,
            customLearningTooltip,
            upgradedAccount,
            currentPlan,
            subscriptionActive,
            toggleModalSubscription,
            checkAccountPlan,
            subscription,
            activateTooltip,
            refresh,
            searchCourse,
            isSearchDisabled,
            query_search,
            formFilter,
            createPrivateCourse,
            triggerCreatePrivateCourse,
            subscriptionType,
            modalActivePrivate,
            toggleModalPrivate,
            savePrivate,
            isConfirm,
            toggleConfirmation,
            destroy,
            status,
            errors,
            learning_plan,
            deleteTraining,
            editPrivateCourse,
            getCompanyPrivateCourse
        }
    },
    components: {
        Courses,
        Custom,
        Employee,
        ModalSubscription,
        PremiumLearningPlan,
        Basic,
        LearningPlanBasic,
        ModalPrivate,
        DiscountedCourse
    }
}
</script>

<style scoped>
ul.dropdown-menu.show {
    padding: 0;
    width: 170px;
    border:0;
}
.main-group ul li button {
    width:100%;
    border-radius: 0;
}
.training-card {
    background-color: #fff;
    color: #333;
    border-top: 1px solid #eee;
    text-align: left;
}
.card-link {
    cursor: pointer;
}
.fa-training-icon {
    font-size: 20px;
    color: #fff;
}
.object-cover {
    object-fit: cover;
}
.course-img {
    height: 135px !important;
}
.card {
    box-shadow: none;
}
.card-title {
    font-size: 18px;
}
.btn-margin {
    margin: 0 5px;
}
.mtc-4 {
    margin-top: 46px;
    float: right;
}
.tooltip-box-training {
    z-index: 1;
    border-radius: 5px;;
    border: 1px solid var(--bc-medium);
    background-color: hsl(0,0%,100%);
    box-shadow: 0 1px 3px hsla(0,0%,0%,0.06), 0 2px 6px hsla(0, 0%, 0%, 0.06), 0 3px 8px hsla(0, 0%, 0%, 0.09);
    position: absolute;
    right: 50px;
    top: 5px;
    z-index: 100;
    cursor: pointer;
    padding: 7px;
    width: 250px;
    text-align: center;
}
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 20%;
    background: #4d4d4d40;
    z-index: 999;
}
.ml-3 {
    margin-left: 10px;
}
</style>