import axios from "axios";
import { ref, inject } from "vue";

export default function jobPromotionBoostRepo() {
    
    const toast = inject('toast');
    const currentJobPromotionBoost = ref([]);
    const errors = ref([]);
    const message = ref('');
    const status = ref('');

    const getCurrentJobPromotionBoost = async (page) => {
        let response = await axios.get(`employer/active-job-promotion-boost?company_id=${page.authuser.company_id}&user_id=${page.authuser.id}`);
        currentJobPromotionBoost.value = response.data.data;
        page.isLoading = false;
    }

    
    return {
        currentJobPromotionBoost,
        errors,
        message,
        status,
        getCurrentJobPromotionBoost
    }

}