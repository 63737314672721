<template>
    <div>
        <Modal :modalActive="modalActive" :class="`modal-dialog modal-sm modal-dialog-centered`">
            <template #header>
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
                </div>
            </template>
            <template #body>
                <div class="row">
                    <div class="col-12">
                        <h3>Plan</h3>
                        <BaseInput 
                            v-model="form.name"
                            label="Name"
                            type="text"
                            :class="{ 'is-invalid' : errors && errors.name }"
                            id="name"
                            :errors="errors"
                            is-required
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <BaseInput 
                            v-model="form.monthly_price"
                            label="Monthly"
                            type="number"
                            :class="{ 'is-invalid' : errors && errors.monthly_price }"
                            id="monthly_price"
                            :errors="errors"
                            is-required
                        />
                    </div>
                    <div class="col-6">
                        <BaseInput 
                            v-model="form.yearly_price"
                            label="Yearly"
                            type="number"
                            :class="{ 'is-invalid' : errors && errors.yearly_price }"
                            id="yearly_price"
                            :errors="errors"
                            is-required
                        />
                        <BaseInput 
                            v-model="form.yearly_payment_discount"
                            label="Annual Discount (%)"
                            type="number"
                            :class="{ 'is-invalid' : errors && errors.yearly_payment_discount }"
                            id="yearly_payment_discount"
                            :errors="errors"
                        />
                    </div>
                </div>
                <h3>Details</h3>
                <div v-for="(input,index) in features" :key="`plan-feature-${index}`">
                    <div class="row">
                        <div class="col-9">
                            <BaseInput 
                                v-model="input.description"
                                label=""
                                placeholder="Plan details..."
                                type="text"
                                :class="{ 'is-invalid' : errors && errors.details }"
                                id="feature"
                                :errors="errors"
                                is-required
                            />
                        </div>
                        <div class="col-3">
                            <div class="d-grid gap-2">
                                <button class="btn btn-danger" @click="removeFeautureField(index, input.id)">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9">

                    </div>
                    <div class="col-3">
                        <div class="d-grid gap-2">
                            <button class="btn btn-success" @click="addFeatureField(features)">+ Add feature</button>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer">
                    <div class="d-flex justify-content-between w-100">
                        <div class="toolbar toolbar-bottom" role="toolbar">
                            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                        </div>
                        <div class="toolbar toolbar-bottom d-flex" role="toolbar">
                            <button type="button" class="btn btn-primary btn-save" @click="saveChanges">Save</button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, watchEffect } from 'vue';

export default defineComponent({
    props: {
        modalActive: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: {}
        },
        modalTitle: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {}
        },
        features: {
            type: Object,
            default: {}
        }
    },
    setup(props, { emit }) {
        const featureField = ref([{feature:''}]);
        const removedPlanFeatures = ref([]);
        watchEffect(() => {
            // Set amount to 0.
            props.form.yearly_payment_discount = props.form.yearly_payment_discount ?? 0;
            props.form.monthly_price = props.form.monthly_price ?? 0;
            // Auto calculate yearly price.
            props.form.yearly_price = props.form.monthly_price * 12;
            // Calculate yearly discount amount based on the discount perc given.
            const discountAmount = props.form.yearly_price * (props.form.yearly_payment_discount/100);
            // Calculate the discounted yearly price.
            props.form.yearly_price = props.form.yearly_price - discountAmount;
        })
        const saveChanges = () => {
            removedPlanFeatures.value = [];
            emit('saveChanges');
        }
        const addFeatureField = (features) => {
            emit('addFeatureField', features);
        }
        const removeFeautureField = (index, featureid) => {
            props.form.remove_feature = featureid;
            removedPlanFeatures.value.push(featureid)
            const removelist = removedPlanFeatures.value;
            emit('removeFeautureField', index, props.features, removelist);
            
        }
        const close = () => {
            removedPlanFeatures.value = []
            emit('close');
        }
        return {
            saveChanges,
            close,
            featureField,
            addFeatureField,
            removeFeautureField,
            removedPlanFeatures
        }
    }
})
</script>