<template>
    <main class="content">
        <div class="container-fluid p-0">
            <h1>Feedback</h1>
            
        </div>
    </main>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, computed, inject } from 'vue';
import global from '@/repositories/global';
import { useRoute } from 'vue-router'
import ReplyModal from '@/views/admin/feedback/ReplyModal.vue';

export default defineComponent({
    setup() {
        
    },
    components: {
        ReplyModal
    }
})
</script>
<style>
.select-status { margin-top: -12px; }
</style>