<template>
    <div class="row mt-3">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <PageLoader v-if="page.isLoading" />
                    <div v-else>
                        <div class="row" v-if="learning_plans.length">
                            <div class="col-12" v-for="learning in learning_plans" :key="learning">
                                <div class="card-wrap shadow">
                                    <div class="d-flex justify-content-between">
                                        <h3>{{ learning.learning_plan.name }}</h3>
                                    </div>
                                    <h5>Course Progress</h5>
                                    <div class="progress course-progress ">
                                        <div class="progress-bar bg-success" role="progressbar"
                                        :style="learningCourseProgress(learning.learning_plan.training_courses_total, learning.learning_progress).cssCompleted"
                                        aria-valuenow="30"
                                        aria-valuemin="0"
                                        aria-valuemax="100">
                                            {{ learningCourseProgress(learning.learning_plan.training_courses_total, learning.learning_progress).labelCompleted }} Complete
                                        </div>
                                        <div class="progress-bar bg-warning" role="progressbar"
                                        :style="learningCourseProgress(learning.learning_plan.training_courses_total, learning.learning_progress).cssProgress"
                                        aria-valuenow="30"
                                        aria-valuemin="0"
                                        aria-valuemax="100">
                                            {{ learningCourseProgress(learning.learning_plan.training_courses_total, learning.learning_progress).labelProgress }} In Progress
                                        </div>
                                    </div>
                                    <div class="mt-4" v-if="learning.trainings?.length">
                                        <div class="course-card me-2" v-for="training in learning.trainings" :key="training">
                                            <div class="card card-border shadow">
                                                <div class="d-flex justify-content-between card-absolute">
                                                    <div class="d-flex align-items-center">
                                                        <span class="badge bg-success text-white" v-if="trainingStatus(training.id, learning.learning_progress)?.status == 'completed'">Completed</span>
                                                        <span class="badge bg-warning text-white" v-if="trainingStatus(training.id, learning.learning_progress)?.status == 'progress'">In progress</span>
                                                    </div>
                                                    <div class="card-btn-actions">
                                                        <div class="dropdown position-relative">
                                                            <a href="#" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" class="">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-more-horizontal align-middle"
                                                                >
                                                                    <circle cx="12" cy="12" r="1"></circle>
                                                                    <circle cx="19" cy="12" r="1"></circle>
                                                                    <circle cx="5" cy="12" r="1"></circle>
                                                                </svg>
                                                            </a>

                                                            <div class="dropdown-menu dropdown-menu-end">
                                                                <a class="dropdown-item" href="javascript:;"
                                                                    @click="updateCourseStatus(learning.learning_plan?.id, training.id, 'completed')">
                                                                    Mark as Complete
                                                                </a>
                                                                <a class="dropdown-item" href="javascript:;"
                                                                    @click="updateCourseStatus(learning.learning_plan?.id, training.id, 'progress')">
                                                                    Mark as In progress
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a :href="training.link ?? training.video" target="_blank" class="card-link">
                                                    <img :src="`${training.thumbnail}`" class="card-img-top object-cover course-img">
                                                    <div class="card-header card-header-items px-4 pt-4">
                                                        <h5 class="card-title" v-text="training.title_limit"></h5>
                                                    </div>
                                                </a>
                                                <div class="card-body training-card">
                                                    <div>{{ training.author ?? training.company?.company_name }}</div>
                                                    <p class="card-text carousel-card-text">
                                                        <b>Source: {{ training.source?.name ?? 'Private Course' }}</b>
                                                    </p>
                                                    <div class="mt-3" v-html="training.display_learning_plans"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import trainingRepo from '@/repositories/employer/trainings';

export default {
    props: {
        learning_plans: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        const page = reactive({
            authuser: JSON.parse(localStorage.getItem('authuser')),
            isLoading: false,
            file_url: process.env.VUE_APP_UPCLOUD_ASSETS
        });
        const { updateLearningPlanProgress } = trainingRepo();

        const updateCourseStatus = async (learningID, trainingID, type) => {
            let formData = new FormData();
            formData.append('user_id', page.authuser.id);
            formData.append('learning_id', learningID);
            formData.append('training_id', trainingID);
            formData.append('type', type);
            await updateLearningPlanProgress(formData)
            emit('updateProgress')
        }

        const learningCourseProgress = (course_total, progress) => {
            let completedCnt = 0
            let progressCnt = 0
            progress.map( data => {
                if(data.status == 'completed'){
                    completedCnt++
                }else if(data.status == 'progress')(
                    progressCnt++
                )
            })
            const completed = ((completedCnt/course_total) * 100).toFixed(2)
            const inprogress = ((progressCnt/course_total) * 100).toFixed(2)
            return {
                percProgress: ((progressCnt/course_total) * 100).toFixed(2),
                percCompleted: ((completedCnt/course_total) * 100).toFixed(2),
                cssCompleted: 'width:'+`${completed}`+'%;',
                labelCompleted: `${completed}`+'%',
                cssProgress: 'width:'+`${inprogress}`+'%;',
                labelProgress: `${inprogress}`+'%'
            }
        }

        const trainingStatus = (trainingID, learning_progress) => {
            const found = learning_progress.filter( data => data.training_id == trainingID)[0]
            return found
        }

        return {
            page,
            trainingStatus,
            updateCourseStatus,
            learningCourseProgress
        }
    }
}
</script>
<style scoped>
    .card-wrap {
        border: 1px solid #e5e5e5;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 25px;
        background: #fbfbfb;
        box-shadow: 1px 0px 10px 0px #6e6e6e42;
    }
    .course-card { display: inline-block; width: 240px; }
    .shadow {
        box-shadow: 0 .1rem .2rem rgba(0,0,0,.05)!important;
    }
    .card-header-items {
        height: 105px;
    }
    .card-absolute {
        position: absolute;
        width: 100%;
        padding: 0 10px;
        margin-top: 0.5rem;
    }
    .card-btn-actions {
        background-color: #fafafa;
        border-radius: 100px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
    }
    .feather {
        width: 24px !important;
        height: 24px !important;
        padding: 0 3px;
    }
    .training-card {
        height: 140px;
    }
</style>