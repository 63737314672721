<template>
    <div v-if="apexInit">
        <apexchart type="line" :height="height" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
export default {
    props:{
        height: {
            type: Number,
            default: 350
        },
        title: {
            type: String,
            default: ''
        },
        series: {
            type: Array,
            default: []
        },
        categories: {
            type: Array,
            default: []
        }
    },
    setup(props) {
        const series = ref([]);
        const chartOptions = ref([]);
        const apexInit = ref(false);

        watchEffect(() => {
            series.value = props.series;

            chartOptions.value = {
                colors: ['#5F9DF7', '#A8E890', '#FFF9B0', '#F96666', '#937DC2', '#FF6D28', '#F675A8'],
                chart: {
                    height: 400,
                    type: 'line',
                },
                dataLabels: {
                    enabled: false,
                    style: {
                        fontSize: '10px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: props.categories,
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        formatter: function (val) {
                            return val.toFixed(0);
                        }
                    }
                },
                title: {
                    text: props.title,
                    floating: true,
                    offsetY: 0,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            }

            setTimeout(() => {
                apexInit.value = true;
            }, 1000);
        });

        return {
            series,
            chartOptions,
            apexInit
        }
    },
}
</script>