import axios from "axios";
import { ref } from "vue";
import Hashids from 'hashids'

export default function globalRepo() {

    // when making changes to this object, please also change the same array sets in JobOpenings model at the backend
    const location_roles = [
        { id: 1, name: 'One Location' },
        { id: 2, name: 'Multiple Locations' },
        { id: 3, name: 'Remote' },
    ];

    const schedules = [
        { id: 1, name: '8-Hour Shift' },
        { id: 2, name: '10-Hour Shift' },
        { id: 3, name: '12-Hour Shift' },
        { id: 4, name: 'Weekend Availability' },
        { id: 5, name: 'Day Shift' },
        { id: 6, name: 'Night Shift' },
        { id: 7, name: 'Overtime' },
        { id: 8, name: 'Flexible' }
    ];

    const time_frames = [
        { id: 1, name: '1 to 3 days' },
        { id: 2, name: '3 to 7 days' },
        { id: 3, name: '1 to 2 weeks' },
        { id: 4, name: '2 to 4 weeks' },
        { id: 5, name: 'More than 4 weeks' }
    ];

    const compensation_types = [
        { id: 1, name: 'Range' },
        { id: 2, name: 'Exact Amount' }
    ];

    // when making changes to this object, please also change the same array sets in JobOpenings model at the backend
    const rates = [
        { id: 1, name: 'Per Hour' },
        { id: 2, name: 'Per Day' },
        { id: 3, name: 'Per Week' },
        { id: 4, name: 'Per Month' },
        { id: 5, name: 'Per Year' }
    ];

    const job_types = [
        { name: 'Contract' },
        { name: 'Temporary' },
        { name: 'Internship' },
        { name: 'Full-time' },
        { name: 'Part-time' }
    ];

    const account_types = [
        { name: 'Basic' },
        { name: 'Pro' },
        { name: 'Premium' }
    ];

    const course_types = [
        { name: 'Basic', value: false },
        { name: 'Premium', value: true }
    ];

    const learning_plan_types = [
        { name: 'Public', value: 0 },
        { name: 'Premium', value: 1 }
    ];

    const hiring_statuses = [
        { id: 'Urgent', name: 'Hiring - urgently looking for candidates' },
        { id: 'Active', name: 'Hiring - actively looking for candidates' },
        { id: 'Passive', name: 'Hiring - passively waiting for the right candidates' },
        { id: 'None', name: 'Not looking to hire for the time being' }
    ];

    const education_levels = [
        { id: 'high school', name: 'High School' },
        { id: 'some college', name: 'Some College' },
        { id: 'bachelor\'s degree', name: 'Bachelor\'s Degree' },
        { id: 'associate\'s degree', name: 'Associate\'s Degree' },
        { id: 'master\'s degree', name: 'Master\'s Degree' },
        { id: 'doctor\'s', name: 'Doctor\'s Degree' }
    ];

    const schedule_shifts = [
        { id: 1, name: 'Weekends' },
        { id: 2, name: 'Mondays to Fridays' }
    ];

    const benefits = [
        { id: 1, name: 'Health Insurance' },
        { id: 2, name: 'Parental Leave' },
        { id: 3, name: 'Pet Insurance' },
        { id: 4, name: '401K' }
    ];

    const application_types = [
        { id: 'Accept', name: 'Accept' },
        { id: 'Reject', name: 'Reject' }
    ];

    const course_filters = [
        {id: 'Alphabetically', name: 'Alphabetically'},
        {id: 'Soft Skills', name: 'Soft Skills'},
        {id: 'Hard Skills', name: 'Hard Skills'},
        {id: 'Provider', name: 'By Provider'}
    ];

    const assigned_course_filters = [
        {id: 'Soft Skills', name: 'Soft Skills'},
        {id: 'Hard Skills', name: 'Hard Skills'}
    ];

    const course_difficulty = [
        {id: 1, name: 'Beginner'},
        {id: 2, name: 'Intermediate'},
        {id: 3, name: 'Advanced'}
    ];

    const enrolled_course_filters = [
        {id: 'Enrolled', name: 'Enrolled'},
        {id: 'In Progress', name: 'In Progress'},
        {id: 'Completed', name: 'Completed'}
    ];

    const private_course_filters = [
        {id: 'Alphabetically', name: 'Alphabetically'},
        {id: 'Soft Skills', name: 'Soft Skills'},
        {id: 'Hard Skills', name: 'Hard Skills'}
    ];

    const employment_statuses = [
        {id: 'Employed - not interested in being approached', name: 'Employed - not interested in being approached'},
        {id: 'Employed - passively open to new opportunities', name: 'Employed - passively open to new opportunities'},
        {id: 'Employed - actively looking for new opportunities', name: 'Employed - actively looking for new opportunities'},
        {id: 'Employed - urgently looking for new opportunities', name: 'Employed - urgently looking for new opportunities'},
        {id: 'Unemployed', name: 'Unemployed'},
        {id: 'Under-employed', name: 'Under-employed'},
        {id: 'Self-employed', name: 'Self-employed'},
        {id: 'Student', name: 'Student'},
        {id: 'Homemaker', name: 'Homemaker'}
    ];

    const user_log_type = {
        start: 'Impersonation Start',
        stop:  'Impersonation Stop',
        navigation: 'Navigation'
    }

    const application_status = {
        active:     'Active',
        awaiting:   'Awaiting Review',
        cancelled:  'Cancelled',
        contacted:  'Contacted',
        hired:      'Hired',
        rejected:   'Rejected',
        resigned:   'Resigned',
        terminated: 'Terminated',
        archived:   'Archived'
    }

    const pusher_notification_data = {
        appkey: process.env.VUE_APP_PUSHER_KEY,
        cluster: 'us2',
        channel: 'notification_prompt',
        broadcast: 'notification_push'
    }

    const pusher_messaging_data = {
        appkey: process.env.VUE_APP_PUSHER_KEY,
        cluster: 'us2',
        channel: 'chat',
        broadcast: 'message'
    }

    const pusher_research = {
        appkey: process.env.VUE_APP_PUSHER_KEY,
        cluster: 'us2',
        channel: 'research_prt',
        broadcast: 'research_broadcast'
    }

    const subscription_type = {
        free: process.env.VUE_APP_FREE,
        pro: process.env.VUE_APP_PRO,
        premium: process.env.VUE_APP_PREMIUM
    }

    const promoted_job_count = {
        free: process.env.VUE_APP_FREE_TIER_PROMOTED_JOB_MAX,
        pro: process.env.VUE_APP_PRO_TIER_PROMOTED_JOB_MAX,
        premium: process.env.VUE_APP_PREMIUM_TIER_PROMOTED_JOB_MAX
    }

    const job_post_count = {
        free: process.env.VUE_APP_FREE_TIER_JOB_POST_MAX,
        pro: process.env.VUE_APP_PRO_TIER_JOB_POST_MAX,
        premium: process.env.VUE_APP_PREMIUM_TIER_JOB_POST_MAX
    }

    const job_boost = {
        count: process.env.VUE_APP_JOB_BOOST_COUNT
    }

    const candidate_filters = [
        {id: 'Already Invited',     name: 'Already Invited'},
        {id: 'Already Applied',     name: 'Already Applied'},
        {id: 'Already Contacted',   name: 'Already Contacted'}
    ]

    const training_tabs = {
        courses: "Courses",
        suggested: "Suggested",
        assigned: "Assigned",
        enrolled: "Enrolled",
        basic: "Basic",
        learning: "LearningPlans"
    }

    const countries = ref([]);
    const languages = ref([]);
    const global_skills = ref([]);

    const getCountries = async () => {
        // TODO: Uncomment these lines and remove default countries once the app is available worldwide.
        // let response = await axios.get(`employer/countries`);
        // countries.value = response.data.data;
        countries.value = [
            {
                id: 169,
                iso: 'PH',
                name: 'PHILIPPINES',
                nicename: 'Philippines'
            },
            {
                id: 226,
                iso: 'US',
                name: 'UNITED STATES',
                nicename: 'United States'
            }
        ];
    }

    const getLanguages = async () => {
        let response = await axios.get(`employer/countries/language`);
        languages.value = response.data.data;
    }

    const getGlobalSkills = async () => {
        let response = await axios.get(`employer/global/skills`);
        global_skills.value = response.data;
    }

    const downloadCsvFile = async () => {
        let response = await axios.get(`employer/users/downloadcsv`);

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'simphini-userlist.csv');
        document.body.appendChild(fileLink);

        fileLink.click();
    }

    const feedbackStatus = [
        { id: 'read', name: 'read', label: 'Read' },
        { id: 'unread', name: 'unread', label: 'Unread' },
        { id: 'addressed', name: 'addressed', label: 'Awaiting Response' },
        { id: 'resolved', name: 'resolved', label: 'Resolved'}
    ];

    const hashids = new Hashids('crescendo-simphini-for-the-better-future-of-employment', 30, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');

    /** Pre selectable static list of skills for candidate. **/
    const skillsAndTech = [
        { "title": "APIs", "id": "1" },
        { "title": "Git", "id": "2" },
        { "title": "Linux", "id": "3" },
        { "title": "Bootstrap", "id": "4" },
        { "title": "JavaScript", "id": "5" },
        { "title": "Microsoft SQL Server", "id": "6" },
        { "title": "XML", "id": "7" },
        { "title": "User Interface (UI)", "id": "8" },
        { "title": "AWS", "id": "9" },
        { "title": "REST", "id": "10" },
        { "title": "Agile", "id": "11" },
        { "title": "CSS", "id": "12" },
        { "title": "Unit Testing", "id": "13" },
        { "title": "Jenkins", "id": "14" },
        { "title": "Docker", "id": "15" },
        { "title": "SOAP", "id": "16" },
        { "title": "SQL", "id": "17" },
        { "title": "MySQL", "id": "18" },
        { "title": "PHP", "id": "19" },
        { "title": "GitHub", "id": "20" },
        { "title": "Jira", "id": "21" },
        { "title": "Apache", "id": "22" },
        { "title": "Scripting", "id": "23" },
        { "title": "Software Development", "id": "24" },
        { "title": "Node.js", "id": "25" },
        { "title": "Web Services", "id": "26" },
        { "title": "SSL", "id": "27" },
        { "title": "Vuex", "id": "28" },
        { "title": "Vuetify", "id": "29" },
        { "title": "Data entry", "id": "30" },
        { "title": "Inventory Management", "id": "31" },
        { "title": "Email Management", "id": "32" },
        { "title": "Bugzilla", "id": "33" },
        { "title": "Yellow Belt Six Sigma", "id": "34" },
        { "title": "Agile", "id": "35" },
        { "title": "Waterfall", "id": "36" },
        { "title": "UI design", "id": "37" },
        { "title": "UX", "id": "38" },
        { "title": "Web development", "id": "39" },
        { "title": "Marketing automation", "id": "40" },
        { "title": "Full-stack development", "id": "41" },
        { "title": "UI development", "id": "42" },
        { "title": "Database development", "id": "43" },
        { "title": "Project management", "id": "44" },
        { "title": "Content management systems", "id": "45" },
        { "title": "E-commerce", "id": "46" },
        { "title": "UI", "id": "47" },
        { "title": "WCAG", "id": "48" },
        { "title": "B2B", "id": "49" },
        { "title": "Software troubleshooting", "id": "50" },
        { "title": "HTML5", "id": "51" },
        { "title": "Java", "id": "52" },
        { "title": "React.js", "id": "53" },
        { "title": "VueJS", "id": "54" }
    ]

    return {
        location_roles,
        schedules,
        time_frames,
        compensation_types,
        rates,
        getCountries,
        countries,
        getLanguages,
        languages,
        job_types,
        account_types,
        hiring_statuses,
        downloadCsvFile,
        education_levels,
        schedule_shifts,
        benefits,
        application_types,
        course_filters,
        private_course_filters,
        employment_statuses,
        user_log_type,
        feedbackStatus,
        hashids,
        skillsAndTech,
        global_skills,
        getGlobalSkills,
        pusher_notification_data,
        pusher_messaging_data,
        subscription_type,
        assigned_course_filters,
        promoted_job_count,
        job_post_count,
        job_boost,
        enrolled_course_filters,
        training_tabs,
        application_status,
        course_types,
        course_difficulty,
        learning_plan_types,
        candidate_filters,
        pusher_research
    }

}